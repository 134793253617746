

/* const getMatchingParentNode = (node, targetTag) => {
  if (node.tagName === targetTag) {
    return node
  }
  else if (node.parentNode) {
    return getMatchingParentNode(node.parentNode, targetTag)
  }
  return null
} */

const searchParentNodesForClickIgnore = (node) => {
  if (node
      && node.getAttribute
      && node.getAttribute('role') === 'presentation') {
    return true
  }
  if (node
    && typeof node.className === 'string'
    && node.className.includes('ignore')) {
    return true
  }
  else if (node.parentNode) {
    return searchParentNodesForClickIgnore(node.parentNode)
  }
  return false
}

export const createRowSelector = (onClick, options = {}) => (event) => {

  if (searchParentNodesForClickIgnore(event.target)) {
    return
  }

  /* const ignore = options.ignore || 'ignore'
  const tag = options.tag || 'TD'
  const node = getMatchingParentNode(event.target, tag)

  console.log('[node]', node)
  console.log('[ignore]', searchParentNodesForClickIgnore(event.target))

  if (node
    && typeof node.className === 'string'
    && node.className.includes(ignore)) {
    return
  } */

  onClick && onClick(event)
}
