import React from 'react'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import { DashboardGraph } from 'graph'
import { Typography } from '@material-ui/core'
import { useGraphFetch, constants } from '../module'
import { useSelectedFleetIds } from 'features/settings'
import uuid from 'uuid/v4'


const useStyles = makeStyles(theme => ({
  root: {},
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
  data: {
    marginTop: '-60px',
    fontWeight: 'bold',
    fontSize: 75, 
  }
}))

const Graph = () => {

  const classes = useStyles()
  const dispatch = useDispatch()
  const selectedFleetIds = useSelectedFleetIds()

  const { loading, error, data } = useGraphFetch()
  const path = '/reports/extension-rentals'

  const onRefresh = () => {
    dispatch({
      type: constants.REQUEST_DATA,
      payload: null,
      options: { queryParams: { FleetId: selectedFleetIds.join() }, requestId: uuid() }
    })
  }

  return (
    <DashboardGraph
      isInertia
      title="Vehicles on Extension"
      to={path}
      loading={loading}
      error={error}
      minHeight={80}
      onRefresh={onRefresh}
      data={data}>
      <div className={classes.center}>
        {data && data.numberOfVehicles > 0 ? (
          <Typography variant="h1" color="error" className={classes.data}>
            {data.numberOfVehicles}
          </Typography>
        ) : (
          <Typography variant="body1">
            You currently have no vehicles on extension
          </Typography>
        )}
      </div>
    </DashboardGraph>
  )
}

export default Graph