import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'

import {
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'

import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CancelIcon from '@material-ui/icons/Cancel'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2)
  },
  list: {
    margin: theme.spacing(-1, -2)
  },
  success: {
    color: theme.palette.success.main,
  },
}))

const PasswordIndicator = ({
  passwordMatch,
  meetsPasswordLength,
  hasNumber,
  hasLowercase,
  hasUppercase,
  hasSymbol
}) => {

  const classes = useStyles()

  return (
    <div className={classes.root}>
      <ListItem className={classes.list}>
        <ListItemIcon>
          {passwordMatch ? (
            <CheckCircleIcon className={passwordMatch ? classes.success : ''} />
          ) : (
            <CancelIcon />
          )}
        </ListItemIcon>
        <ListItemText>Password Match</ListItemText>
      </ListItem>
      <ListItem className={classes.list}>
        <ListItemIcon>
          {meetsPasswordLength ? (
            <CheckCircleIcon
              className={meetsPasswordLength ? classes.success : ''}
            />
          ) : (
            <CancelIcon />
          )}
        </ListItemIcon>
        <ListItemText>At least 8 or more characters</ListItemText>
      </ListItem>
      <ListItem className={classes.list}>
        <ListItemIcon>
          {hasNumber ? (
            <CheckCircleIcon className={hasNumber ? classes.success : ''} />
          ) : (
            <CancelIcon />
          )}
        </ListItemIcon>
        <ListItemText>Contain at least one number</ListItemText>
      </ListItem>
      <ListItem className={classes.list}>
        <ListItemIcon>
          {hasLowercase ? (
            <CheckCircleIcon className={hasLowercase ? classes.success : ''} />
          ) : (
            <CancelIcon />
          )}
        </ListItemIcon>
        <ListItemText>Contain at least one lowercase character</ListItemText>
      </ListItem>
      <ListItem className={classes.list}>
        <ListItemIcon>
          {hasUppercase ? (
            <CheckCircleIcon className={hasUppercase ? classes.success : ''} />
          ) : (
            <CancelIcon />
          )}
        </ListItemIcon>
        <ListItemText>Contain at least one uppercase character</ListItemText>
      </ListItem>
      <ListItem className={classes.list}>
        <ListItemIcon>
          {hasSymbol ? (
            <CheckCircleIcon className={hasSymbol ? classes.success : ''} />
          ) : (
            <CancelIcon />
          )}
        </ListItemIcon>
        <ListItemText>Contain at least one symbol</ListItemText>
      </ListItem>
    </div>
  )
}

PasswordIndicator.propTypes = {
  passwordMatch: PropTypes.bool.isRequired,
  meetsPasswordLength: PropTypes.bool.isRequired,
  hasNumber: PropTypes.bool.isRequired,
  hasLowercase: PropTypes.bool.isRequired,
  hasUppercase: PropTypes.bool.isRequired,
  hasSymbol: PropTypes.bool.isRequired,
}

export default PasswordIndicator
