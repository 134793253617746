
export const createActions = (moduleTypes) => ({

  resetDefaults: () => ({
    type: moduleTypes.RESET_DEFAULTS,
  }),

  addData: payload => ({
    type: moduleTypes.ADD_DATA,
    payload,
  }),

  removeData: (payload) => ({
    type: moduleTypes.REMOVE_DATA,
    payload,
  }),

  requestData: (payload, options) => ({
    type: moduleTypes.REQUEST_DATA,
    payload,
    options,
  }),

  requestDataFulfilled: (payload, options) => ({
    type: moduleTypes.REQUEST_DATA_FULFILLED,
    payload,
    options,
  }),

  requestDataError: (payload, options) => ({
    type: moduleTypes.REQUEST_DATA_ERROR,
    payload,
    options,
  }),

})
