import React from "react"
import { Box, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles(theme => ({
  actions: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    }
  },
  button: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    minWidth: 180,
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
    }
  },
}))

export const FormCta = ({ onSubmit, onProceed, onSave, onBack }) => {
  const styles = useStyles()

  return (
    <Box className={styles.actions} mt={3}>
      {onSubmit &&
        <Button 
          color="primary"
          type="button"
          onClick={onSubmit}
          size="medium"
          variant="contained"
          className={styles.button}
        >
          Submit
        </Button>}
      {onProceed &&
        <Button 
          color="primary"
          type="button"
          onClick={onProceed}
          size="medium"
          variant="contained"
          className={styles.button}
        >
          Proceed
        </Button>}
      <Button
        color="secondary"
        type="button"
        onClick={onSave}
        size="medium"
        variant="contained"
        className={styles.button}
      >
        Save and Exit
      </Button>
      {onBack &&
        <Button
          color="secondary"
          type="button"
          onClick={onBack}
          size="medium"
          variant="contained"
          className={styles.button}
        >
          Back
        </Button>}
    </Box>
  )
}