import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Spinner from 'react-md-spinner'
import { makeStyles, useTheme } from '@material-ui/styles'
import { Card, CardHeader, CardContent, Divider, Typography, IconButton, Tooltip } from '@material-ui/core'

import DashboardGraphTitle from './dashboard-graph-title'
import CachedIcon from '@material-ui/icons/Cached'


const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    padding: 0
  },
  inner: {
    display: 'flex',
    position: 'relative',
    padding: theme.spacing(2, 2, 0, 2),
    minHeight: props => props.minHeight ? props.minHeight : 265,
  },
  fill: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  error: {
    // color: theme.palette.error.main,
    textAlign: 'center',
  },
  refreshIcon: {
    float: 'right',
    overflow: 'hidden',
    transition: 'all 0.3s ease-out',
    transform: props => props.rotate ? 'rotate(540deg)' : 'rotate(-180deg)',
  },
  inertiaHeadingStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginLeft: '25px',
  },
}))

const DashboardGraph = ({
  children,
  className,
  title,
  subtitle,
  to,
  loading,
  error,
  onRefresh,
  data,
  minHeight,
  isInertia,
}) => {

  const theme = useTheme()
  const [isRefreshing, setIsRefreshing] = useState(false)

  const [rotateIcon, setRotateIcon] = useState(false)
  const classes = useStyles({ rotate: rotateIcon, minHeight })

  const errored = ( ! data && error) || ( ! data && ! loading)

  const onRefreshHandler = () => {
    setIsRefreshing(true)
    setTimeout(() => setIsRefreshing(false), 2000)
    
    setRotateIcon(!rotateIcon)
    onRefresh && onRefresh()
  }
  
  return (
    <Card className={clsx(classes.root, className)}>
      <CardHeader
        title={<DashboardGraphTitle
          title={title}
          subtitle={subtitle}
          to={to}
          className={isInertia ? classes.inertiaHeadingStyle : ''}
        />}
        action={<Tooltip title="refresh">
          <IconButton aria-label="refresh" onClick={() => onRefreshHandler()}>
            <CachedIcon className={classes.refreshIcon} />
          </IconButton>
        </Tooltip>}
      />
      
      {!isInertia && <Divider />}

      <CardContent className={classes.content}>
        <div className={classes.inner}>
          {((! data && loading) || isRefreshing) && (
            <div className={classes.fill}>
              <Spinner
                singleColor={theme.palette.dark}
                size={18} />
            </div>
          )}
          {errored && (
            <div className={classes.fill}>
              <Typography
                className={classes.error}
                variant="body2">
                Whoops!
                <br />Something went wrong
                <br />while getting this data.
              </Typography>
            </div>
          )}
          {data && !isRefreshing && children}
        </div>
      </CardContent>
    </Card>
  )
}

DashboardGraph.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  to: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  onRefresh: PropTypes.func,
  data: PropTypes.any,
  minHeight: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  isInertia: PropTypes.bool,
}

export default DashboardGraph
