import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles'
import { Container, Tabs, Tab } from '@material-ui/core'
import { useRouter } from 'utils'
import SingleSelect from './single-select'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: '#EB0A1E',
  },
})(Tabs)

const AntTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: theme.palette.brand,
      opacity: 1,
    },
    '&$selected': {
      color: theme.palette.brand,
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: theme.palette.brand,
    },
  },
  selected: {},
}))(props => <Tab disableRipple {...props} />)

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(-3),
    // backgroundColor: theme.palette.background.paper,
  },
  mobileMenu: {
    minWidth: '280px',
  }
}))

const CustomTabs = ({ tabsConfig, disabled }) => {

  const theme = useTheme()
  const classes = useStyles()
  const { history } = useRouter()
  const [menuIndex, setMenuIndex] = useState(0)
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'))

  useEffect(() => {
    if(tabsConfig && tabsConfig.length > 0) { // to handle on page load to specific tab
      const currentTabIndex = tabsConfig.findIndex(tab => window.location.pathname.includes(tab.path))
      currentTabIndex && currentTabIndex !== -1 ? setMenuIndex(currentTabIndex) : setMenuIndex(0)
    } // eslint-disable-next-line
  }, [window.location.pathname])

  if(!tabsConfig || (tabsConfig && tabsConfig.length === 0))
    return null

  const handleChange = (event, index) => !disabled && setMenuIndex(index)
  
  const onChangeMobileTab = (path) => {
    const tabIndex = tabsConfig.findIndex(tab => tab.path === path)
    setMenuIndex(tabIndex && tabIndex !== -1 ? tabIndex : 0)
    history.push(path)
  }
    
  const mobileOptions = tabsConfig && tabsConfig.map(tab => ({
    label: tab.label,
    value: tab.path,
  }))

  return (
    <div className={classes.root}>
      {mobileView ? (
        <div className={classes.mobileMenu}>
          <SingleSelect
            noNone
            id="site_mobile_menu"
            options={mobileOptions}
            value={mobileOptions[menuIndex].value}
            onChange={onChangeMobileTab}
            disabled={disabled}
          />
        </div>
      ) : (
        <Container maxWidth="lg">
          <AntTabs value={menuIndex} onChange={handleChange} aria-label="ant-switch">
            {tabsConfig.map(({ label, path, customOnClick }, key) => 
              <AntTab
                key={key}
                label={label}
                onClick={() => {
                  !disabled && history.push(path)
                  customOnClick && customOnClick()
                }}
              />
            )}
          </AntTabs>
        </Container>
      )}
    </div>
  )
}

CustomTabs.propTypes = {
  tabsConfig: PropTypes.array.isRequired,
  loading: PropTypes.bool,
}
export default CustomTabs 