

export const toCurrency = (value) => {
  const negative = value < 0
  value = String(value).replace('-', '')
  const result = Number(value).toLocaleString('en-NZ', {
    style: 'currency',
    currency: 'NZD',
  })

  return negative ? `(${result})` : result
}
