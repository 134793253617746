

export const withOnly = (obj, allowed) => {
  const result = {}
  Object.keys(obj).forEach(key => {
    if ((obj[key] || obj[key] === 0) && allowed.includes(key)) {
      result[key] = obj[key]
    }
  })
  return result
}

export const hasShallowEquality = (a, b, ignorables) => {
  const keys = Object.keys(a)
  for (let i = 0; i < keys.length; i ++) {
    const key = keys[i]
    if (ignorables.includes(key)) {
      continue
    }
    if (a[key] !== b[key]) {
      return false
    }
  }
  return true
}

export const trimObjectStrings = (obj) => {
  const result = obj
  obj && Object.keys(obj).forEach(key => {
    if (typeof obj[key] === 'string') {
      result[key] = String(obj[key]).trim()
    }
    else {
      result[key] = obj[key]
    }
  })
  return result
}
