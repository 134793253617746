export const encodeUserId = (id) =>
  id && id.replace('auth0|', '')

export const decodeUserId = (id) =>
  id ? `auth0|${id}` : ''

// used in list/rows.js in admin users & admin-invitations
export const getUserStatus = (item) =>
  item.hasAccount && item.blocked ? 'Blocked'
    : item.hasAccount && !item.blocked ? 'Active'
      : !item.hasAccount ? 'Invited'
        : '-'

// used in list/rows.js in admin-invitations
export const getUserInvitationStatus = (item) =>
  item.hasAccount ? 'Accepted'
    : !item.hasAccount && item.invitationVerified ? 'Verified'
      : !item.hasAccount && !item.invitationVerified && item.invitationExpired ? 'Expired'
        : !item.hasAccount && !item.invitationVerified && !item.invitationExpired ? 'Unverified'
          : '-'