import React, { Fragment, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'

import {
  ListItemIcon,
  ListItemText,
  Tooltip,
  IconButton,
  Menu,
  MenuItem
} from '@material-ui/core'

import MoreIcon from '@material-ui/icons/MoreVert'
import ViewIcon from '@material-ui/icons/Subject'
import DeleteIcon from '@material-ui/icons/Delete'
import { StyledModal } from 'frame/components'

import { useEnabledFeatureToggleList } from 'features/settings'
import { useIsAlfaDownDisableFeatures } from 'features/drivers'
import { hasRoles } from 'acl'


const useStyles = makeStyles(() => ({}))

const RowActions = ({ onView, onTerminate,userRoles, driverName, ...props }) => {

  const classes = useStyles()
  const moreRef = useRef(null)
  const [openMenu, setOpenMenu] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)

  const featureToggleList = useEnabledFeatureToggleList()
  const disableOutOfHoursFeatureToggle = featureToggleList.includes('OutOfHoursDisabled') // 'ALFA out of hours DISABLE FEATURES' limitations feature is ON
  const isAlfaDownDisableDriverFeatures = useIsAlfaDownDisableFeatures()
  const isAlfaDown = disableOutOfHoursFeatureToggle ? false : isAlfaDownDisableDriverFeatures
  const disableTerminateDriverFeatureToggle = featureToggleList.includes('TFP1169')
  const userPermissionsFeatureToggle = featureToggleList.includes('UserPermissions')
  const disableButtons = userPermissionsFeatureToggle && hasRoles(['fleetcoordinator'], userRoles)
  const handleMenuOpen = () =>
    setOpenMenu(true)

  const handleMenuClose = () =>
    setOpenMenu(false)

  const onViewHandler = () => {
    handleMenuClose()
    onView()
  }

  const onTerminateHandler = () => {
    setOpenMenu(false)
    setOpenDeleteModal(true)
  }

  return (
    <Fragment>
      <Tooltip title="More options">
        <IconButton
          {...props}
          onClick={handleMenuOpen}
          ref={moreRef}
          size="small">
          <MoreIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={moreRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        classes={{ paper: classes.menu }}
        onClose={handleMenuClose}
        open={openMenu}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}>
        <MenuItem onClick={onViewHandler}>
          <ListItemIcon>
            <ViewIcon />
          </ListItemIcon>
          <ListItemText primary="View" />
        </MenuItem>

        {onTerminate && !disableTerminateDriverFeatureToggle && !isAlfaDown && (
          <MenuItem onClick={onTerminateHandler}
          disabled={disableButtons}>
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            <ListItemText primary="Terminate" />
          </MenuItem>
        )}
      </Menu>

      {/* for terminate driver confirmation */}
      <StyledModal
        open={openDeleteModal}
        title={`Terminate Driver`}
        onCancel={() => setOpenDeleteModal(false)}
        onConfirm={onTerminate}>
          <strong>IMPORTANT: Please confirm only if {driverName || 'the driver'} no longer works for your organisation.</strong>
          <br /><br />
          Please <strong>CANCEL</strong> if you are simply changing a driver/vehicle combination and use <strong>CHANGE VEHICLE</strong>.
      </StyledModal>
    </Fragment>
  )
}

RowActions.propTypes = {
  className: PropTypes.string,
  onView: PropTypes.func.isRequired,
  onTerminate: PropTypes.func,
  driverName: PropTypes.string,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,

}

export default RowActions
