

const removeSymbols = (value) => 
  value.replace(/[-!$%^&*()_+|~=`{}[\]:";'<>?,./]/g, '')

export const getInitials = (name = '') => !name ? '' : 
  removeSymbols(name)
    .trim()
    .replace(/\s+/, ' ')
    .split(' ')
    .slice(0, 2)
    .map(v => v && v[0].toUpperCase())
    .join('')

const stringToHslColor = (value, s, l) => {
  let hash = 0
  for (let i = 0; i < value.length; i++) {
    hash = value.charCodeAt(i) + ((hash << 5) - hash)
  }
  const h = hash % 360
  return 'hsl('+h+', '+s+'%, '+l+'%)'
}

export const getInitialsColor = (value) => !value
  ? '#E1E3E5' // light grey
  : stringToHslColor(value, 60, 65)
