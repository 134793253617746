import React from 'react'
import { useEffect } from 'react'
import { useAuth } from 'auth'
import { useDispatch } from 'react-redux'
import Cookies from 'js-cookie'

const Logout = () => {

  const { logout } = useAuth()
  const dispatch = useDispatch()

  useEffect(() => {
    let cookies = Cookies.get()
    console.log('logout cookies', cookies)
    Object.keys(cookies).forEach((cookieName) => {
      console.log('logout removing cookie cookieName', cookieName)
      Cookies.remove(cookieName)
    })
    let cookiesAfter = Cookies.get()
    console.log('logout cookiesAfter', cookiesAfter)
    
    dispatch({ type: 'RESET' })
    setTimeout(() => { logout(); }, 1000)
    // eslint-disable-next-line
  }, [])

  return (<span>Logout</span>)
}

export default Logout
