import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ReactGA from 'react-ga'
import { useRouter } from 'utils'
import { getAppConfig } from 'app-config'
import { useEnabledFeatureToggleList } from 'features/settings'

const GoogleAnalytics = ({ options = {} }) => {
  const config = getAppConfig()
  const [tracking, setTracking] = useState(false)

  const featureToggleList = useEnabledFeatureToggleList()
  const tfp1908FeatureToggle = featureToggleList.includes('TFP1908')

  const {
    location:
    {
      pathname,
      search
    }
  } = useRouter()

  const trackPage = useCallback(() => {
    const page = pathname + search
    const { location } = window
    // custom dimension set? no clue what for
    ReactGA.set({
      page,
      location: `${location.origin}${page}`,
      ...options,
    })

    ReactGA.pageview(page)
    console.log('[GA] pageview tracked: ', page)
  }, [options, pathname, search])

  useEffect(() => {
    if (tracking) {
      trackPage()
    }
  }, [pathname, search, tracking, trackPage])

  useEffect(() => {
    //const isGAEnabled = config.env === 'production'

    //if (isGAEnabled) {
    if (tfp1908FeatureToggle) {
      ReactGA.initialize(config.app.ga4)
    } else {
      ReactGA.initialize(config.app.ga)
    }


    //}

    setTracking(true)
  }, [config.app.ga, config.app.ga4, config.env, setTracking, tfp1908FeatureToggle])

  return null
}

GoogleAnalytics.propTypes = {
  options: PropTypes.object
}

export default GoogleAnalytics