// A simple ACL implementation
// If you're looking for a good API try something like: https://www.npmjs.com/package/acl

const listify = (list) =>
  Array.isArray(list)
    ? list
    : [list]

export const hasRoles = (allowedRoles, userRoles) => {
  const allowed = listify(allowedRoles)
  for (let role of allowed) {
    if (userRoles.includes(role)) {
      return true
    }
  }
  return false
}
