import { getAppConfig } from 'app-config'


const appConfig = getAppConfig()

export const createActions = (moduleTypes, customActions = {}, name) => ({

  ...customActions,
  currentPageModule: name,

  resetDefaults: () => ({
    type: moduleTypes.RESET_DEFAULTS,
  }),

  addItems: payload => ({
    type: moduleTypes.ADD_ITEMS,
    payload,
  }),

  removeItems: (payload) => ({
    type: moduleTypes.REMOVE_ITEMS,
    payload,
  }),

  selectItems: payload => ({
    type: moduleTypes.SELECT_ITEMS,
    payload,
  }),

  deselectItems: payload => ({
    type: moduleTypes.DESELECT_ITEMS,
    payload,
  }),

  clearSearchKey: () => ({
    type: moduleTypes.UPDATE_SEARCH_KEY,
    payload: '',
  }),

  changeSearchKey: payload => ({
    type: moduleTypes.CHANGE_SEARCH_KEY,
    payload,
  }),

  updateSearchKey: payload => ({
    type: moduleTypes.UPDATE_SEARCH_KEY,
    payload,
  }),

  setPaginationOffset: payload => ({
    type: moduleTypes.SET_PAGINATION_OFFSET,
    payload,
  }),

  setPaginationRows: payload => ({
    type: moduleTypes.SET_PAGINATION_ROWS,
    payload,
  }),

  requestFilters: (payload, options) => ({
    type: moduleTypes.REQUEST_FILTERS,
    payload,
    options,
  }),

  requestFiltersFulfilled: (payload, options) => ({
    type: moduleTypes.REQUEST_FILTERS_FULFILLED,
    payload,
    options,
  }),

  requestFiltersError: (payload, options) => ({
    type: moduleTypes.REQUEST_FILTERS_ERROR,
    payload,
    options,
  }),

  addFleetFilters: (payload) => ({
    type: moduleTypes.ADD_FLEET_FILTERS,
    payload,
  }),

  onDownload: (payload) => ({
    type: moduleTypes.REQUEST_DOWNLOAD,
    payload,
  }),

  onDownloadFulfilled: (payload) => ({
    type: moduleTypes.REQUEST_DOWNLOAD_FULFILLED,
    payload,
  }),

  onDownloadError: (payload) => ({
    type: moduleTypes.REQUEST_DOWNLOAD_ERROR,
    payload,
  }),

  onDownloadClearStatus: () => ({
    type: moduleTypes.REQUEST_DOWNLOAD_CLEAR_STATUS
  }),

  showFilters: () => ({
    type: moduleTypes.SHOW_FILTERS,
  }),

  hideFilters: () => ({
    type: moduleTypes.HIDE_FILTERS,
  }),

  changeFilters: payload => ({
    type: moduleTypes.CHANGE_FILTERS,
    payload,
  }),

  updateFilters: (payload, options) => ({
    type: moduleTypes.UPDATE_FILTERS,
    payload,
    options, // bool cleanUpdate - for clearing any prev filters and use new payload filters only
  }),

  clearFilters: () => ({
    type: moduleTypes.CLEAR_FILTERS,
  }),

  toggleShowColumns: () => ({
    type: moduleTypes.TOGGLE_SHOW_COLUMNS,
  }),

  toggleColumn: payload => ({
    type: moduleTypes.TOGGLE_COLUMN,
    payload,
  }),

  toggleSelectAllColumns: payload => ({
    type: moduleTypes.TOGGLE_SELECT_ALL_COLUMNS,
    payload,
  }),

  toggleColumnSort: payload => ({
    type: moduleTypes.TOGGLE_COLUMN_SORT,
    payload,
  }),

  fetchList: () => ({
    type: moduleTypes.FETCH_LIST,
  }),

  requestList: (payload, options) => ({
    type: moduleTypes.REQUEST_LIST,
    payload,
    options,
  }),

  requestListFulfilled: (payload, options, response) => ({
    type: moduleTypes.REQUEST_LIST_FULFILLED,
    payload,
    options,
    response,
  }),

  requestListError: (payload, options) => ({
    type: moduleTypes.REQUEST_LIST_ERROR,
    payload,
    options,
  }),

  requestResource: (payload, options) => ({
    type: moduleTypes.REQUEST_RESOURCE,
    payload,
    options,
  }),

  requestResourceFulfilled: (payload, options) => ({
    type: moduleTypes.REQUEST_RESOURCE_FULFILLED,
    payload,
    options,
  }),

  requestResourceError: (payload, options) => ({
    type: moduleTypes.REQUEST_RESOURCE_ERROR,
    payload,
    options,
  }),

  refetch: (payload, options) => ({
    type: moduleTypes.REFETCH_RESOURCE,
    payload,
    options,
  }),

  createResource: (payload, options) => ({
    type: moduleTypes.CREATE_RESOURCE,
    payload,
    options,
  }),

  createResourceFulfilled: (payload, options) => ({
    type: moduleTypes.CREATE_RESOURCE_FULFILLED,
    payload,
    options,
  }),

  createResourceError: (payload, options) => ({
    type: moduleTypes.CREATE_RESOURCE_ERROR,
    payload,
    options,
  }),

  updateResource: (payload, options) => ({
    type: moduleTypes.UPDATE_RESOURCE,
    payload,
    options,
  }),

  updateResourceFulfilled: (payload, options) => ({
    type: moduleTypes.UPDATE_RESOURCE_FULFILLED,
    payload,
    options,
  }),

  updateResourceError: (payload, options) => ({
    type: moduleTypes.UPDATE_RESOURCE_ERROR,
    payload,
    options,
  }),

  lookupContract: (payload, options) => ({
    type: moduleTypes.LOOKUP_RELATED_CONTRACT,
    payload,
    options,
  }),

  lookupContractFulfilled: (payload, options) => ({
    type: moduleTypes.LOOKUP_RELATED_CONTRACT_FULFILLED,
    payload,
    options,
  }),

  lookupContractError: (payload, options) => ({
    type: moduleTypes.LOOKUP_RELATED_CONTRACT_ERROR,
    payload,
    options,
  }),

  savePreferences: (preferences) => ({
    type: moduleTypes.SAVE_PREFERENCES,
    payload: {
      filterType: `all${appConfig.build.release}`,
      filterJson: preferences,
    },
  }),

  savePreferencesFulfilled: (payload) => ({
    type: moduleTypes.SAVE_PREFERENCES_FULFILLED,
    payload,
  }),

  savePreferencesError: (payload) => ({
    type: moduleTypes.SAVE_PREFERENCES_ERROR,
    payload,
  }), 
 
  setIsDashboardFilter: (payload) => ({
    type: moduleTypes.SET_DASHBOARD_FILTER,
    payload,
  }),

  selectRow: (payload) => ({
    type: moduleTypes.SELECT_ROW,
    payload,
  }),

})
