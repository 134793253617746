import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { ScrollToButton } from 'frame/components'
import { Button, Card, CardActions, CardContent, Grid, Typography, FormControlLabel, Checkbox, TextField } from '@material-ui/core'
import { useEnabledFeatureToggleList } from 'features/settings'
import { useIsWhiteListed } from 'utils'


const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3),
  },
  content: {
    padding: theme.spacing(3, 1.2, 3, 1.2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(1),
    },
  },
  fieldHeader: {
    marginTop: theme.spacing(5),
  },
  fieldGroup: {
    margin: theme.spacing(3, 0, 2, 0),
  },
  fieldGroupOptions: {
    margin: theme.spacing(3, 2, 2, 2),
  },
  actions: {
    flexDirection: 'column',
    paddingBottom: theme.spacing(3),
    '& > *': {
      width: '100%',
    },
    '& > * + *': {
      marginLeft: 0,
      marginTop: 8,
    },
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      '& > *': {
        width: 'auto',
      },
      '& > * + *': {
        marginLeft: 8,
        marginTop: 0,
      },
    },
  },
  selectAllBtn: {
    minWidth: 180,
    [theme.breakpoints.down('xs')]: { minWidth: '100%' }
  },
  formControlLabel: {
    userSelect: 'none'
  },
}))

const Form = ({ fleets, values, onCancel, onSubmit }) => {

  const featureToggleList = useEnabledFeatureToggleList()
  const fbtFeatureToggle = featureToggleList.includes('FBT')
  const isWhiteListedUser = useIsWhiteListed()

  const classes = useStyles()

  const form = useRef()
  const topRef = useRef()
  const bottomRef = useRef()
  const [searchKey, setSearchKey] = useState('')
  const [filteredFleetItems, setFilteredFleetItems] = useState(fleets)

  const [fleetIds, setFleetIds] = useState(values)
  const [disabled, setDisabled] = useState(fleetIds.length > 0 ? false : true)

  const isSelected = fleetId =>
    fleetIds.includes(fleetId)

  const isSelectedAll = filteredFleetItems.filter(node => !isSelected(node.value)).length === 0

  const [selectAll, setSelectAll] = useState(isSelectedAll || false)

  const onSelect = (fleetId) => () => {
    if (isSelected(fleetId)) {
      const next = fleetIds.filter(id => id !== fleetId)
      setFleetIds(next)
      return
    }
    setFleetIds([...fleetIds, fleetId])
  }

  const onSelectAll = () => {
    const allFleets = filteredFleetItems.map(node => node.value)
    setFleetIds(selectAll ? [] : allFleets)
    setSelectAll(!selectAll)
  }

  const onSubmitForm = () =>
    fleetIds.length > 0 && onSubmit(fleetIds)

  useEffect(() => {
    setDisabled(fleetIds.length < 1)
  }, [fleetIds, setDisabled])

  
  const applySearch = (keyword) => {
    let filteredList = fleets
    if (!keyword || keyword === '') {
      filteredList = fleets
      setSearchKey(null)
    } else {
      filteredList = fleets
        .map(item => item.isPremium ? { ...item, label: `${item.label} *`  } : item)
        .filter(item => JSON.stringify(item.label).toLowerCase().includes(keyword.toLowerCase()))
        .map(item => item.isPremium ? { ...item, label: `${item.label}`.replace(' *', '')  } : item)
    }
    setFilteredFleetItems(filteredList)
    setSearchKey(keyword)
  }

  const onSearchChange = ({ target: { value } }) => {
    setSearchKey(value)
    applySearch(value)
  }

  return (
    <ValidatorForm
      autoComplete="off"
      noValidate
      ref={form}
      onSubmit={onSubmitForm}>
      <div ref={topRef} />
      <Card>
        <CardContent className={classes.content}>

          <Grid alignItems="flex-end" container justify="space-between" spacing={2}>
            <Grid item>
              <Typography gutterBottom variant="h4">
                Fleets
              </Typography>
              <Typography variant="body2">
              Please select the fleets you wish to view data for.
              </Typography>
            </Grid>
            <Grid item>
              <Button
                id={selectAll  ? 'unselect-btn' : 'select-btn'}
                variant="contained"
                className={classes.selectAllBtn}
                onClick={() => onSelectAll()}>
                {selectAll ? 'Unselect All' : 'Select All'}
              </Button>
            </Grid>
          </Grid>

          <div className={classes.fieldGroupOptions}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  id="fleet-search"
                  className={classes.fleetSearch}
                  fullWidth
                  label="Fleet Search"
                  value={searchKey}
                  inputProps={{ maxLength: 50 }}
                  variant="outlined"
                  onChange={onSearchChange} />
              </Grid>

              {filteredFleetItems.map((node, index) => (
                <Grid key={index} item sm={6} xs={12}>
                  <FormControlLabel
                    className={classes.formControlLabel}
                    control={
                      <Checkbox
                        checked={isSelected(node.value)}
                        onChange={onSelect(node.value)}
                        value={node.value}
                        color="default" />
                    }
                    label={fbtFeatureToggle && isWhiteListedUser
                      ? `${node.label}${node?.isPremium ? ' *' : ''}`
                      : node.label
                    }
                  />
                </Grid>
              ))}

            </Grid>
          </div>

          {/* scroll to bottom button*/}
          <ScrollToButton scrollDirection="up" scrollToRef={topRef} />
          <ScrollToButton scrollToRef={bottomRef} />
          
        </CardContent>
        <div ref={bottomRef}>
          <CardActions className={classes.actions}>
            <Button
              color="primary"
              disabled={disabled}
              type="submit"
              variant="contained">
              Select ({fleetIds.length})
            </Button>

            <Button
              variant="contained"
              onClick={onCancel}>
              Cancel
            </Button>
          </CardActions>
        </div>
      </Card>
    </ValidatorForm>
  )
}

Form.propTypes = {
  fleets: PropTypes.array.isRequired,
  values: PropTypes.array.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default Form
