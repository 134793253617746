import PropTypes from 'prop-types'


export const model = [
  {
    key: 'id',
    label: 'ID',
    column: {
      visible: false,
      width: 80,
    },
    prop: {
      type: PropTypes.string,
      value: null,
    }
  }, {
    key: 'driverName',
    label: 'Driver Name',
    column: {
      visible: true,
      width: 130,
    },
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }, {
    key: 'costCentre',
    label: 'Cost Centre',
    column: {
      visible: true,
      width: 120,
    },
    prop: {
      type: PropTypes.string,
      value: '',
    }
  },
  // {
  //   key: 'totalDaysVehicleAvailable',
  //   label: 'Total Days Vehicle Available',
  //   column: {
  //     visible: true,
  //     width: 120,
  //   },
  //   prop: {
  //     type: PropTypes.number,
  //     value: null,
  //   }
  // },
  {
    key: 'exemptDays',
    label: 'Exempt Days',
    column: {
      visible: true,
      width: 120,
    },
    prop: {
      type: PropTypes.number,
      value: null,
    }
  }, 
  // {
  //   key: 'daysAvailableForPvtUse',
  //   label: 'Days Available For Pvt Use',
  //   column: {
  //     visible: true,
  //     width: 120,
  //   },
  //   prop: {
  //     type: PropTypes.number,
  //     value: null,
  //   }
  // }, 
  {
    key: 'exemptNotes',
    label: `FBT Exempt Notes`,
    column: {
      visible: true,
      width: 300,
    },
    prop: {
      type: PropTypes.string,
      value: '',
    }
  },
  // {
  //   key: 'fbtValue',
  //   label: 'FBT Value',
  //   column: {
  //     visible: true,
  //     width: 80,
  //   },
  //   prop: {
  //     type: PropTypes.string,
  //     value: '',
  //   }
  // }, {
  // {
  //   key: 'make',
  //   label: 'Make',
  //   column: {
  //     visible: true,
  //     width: 80,
  //   },
  //   prop: {
  //     type: PropTypes.string,
  //     value: '',
  //   }
  // }, {
  //   key: 'model',
  //   label: 'Model',
  //   column: {
  //     visible: true,
  //     width: 80,
  //   },
  //   prop: {
  //     type: PropTypes.string,
  //     value: '',
  //   }
  // },
  {
    key: 'month',
    label: 'Month',
    column: {
      visible: true,
      width: 80,
    },
    prop: {
      type: PropTypes.number,
      value: '',
    }
  },
  // {
  //   key: 'rego',
  //   label: 'Registration',
  //   column: {
  //     visible: true,
  //     width: 80,
  //   },
  //   prop: {
  //     type: PropTypes.string,
  //     value: '',
  //   }
  // }, {
  //   key: 'vehicleYear',
  //   label: 'Vehicle Year',
  //   column: {
  //     visible: true,
  //     width: 100,
  //   },
  //   prop: {
  //     type: PropTypes.number,
  //     value: null,
  //   }
  // }
]

const config = {
  idKey: 'id',
  title: 'FBT Submissions',
  // subtitle: 'Intro copy goes here. Lorem ipsum dolor sit amet.'
  backgroundImage: 'url("/static/images/backgrounds/bg4.png")',
  disclaimerMessage: {
    title: 'Disclaimer',
    message: 'The data in this report has been created from data collected from your drivers. The indicative FBT values are based on the ‘Cost Price’ valuation and not the ‘tax value’ methodology.  Whilst due care has been taken, Toyota Finance New Zealand Ltd (TFNZ) is not responsible for any errors or omissions, or for the results obtained from the use of this information. It is strongly advised independent tax/legal advice is sought.',
  },
  api: {
    filter: '/filteroptions/fbtsubmissions',
    resource: `/fbt/fbtsubmissions`,
    download: `/download/fbtsubmissions`,
  },
  model,
  headers: [
    'driverName', 'costCentre', /*'totalDaysVehicleAvailable',*/ 'exemptDays', /*'daysAvailableForPvtUse',*/ 'exemptNotes', 'month', 
    //'make', 'model', 'rego', 'vehicleYear',
  ],
  filters: [],
  noColumnSort: true,
  // download show/hide is controllerd in listable\components\list.js 
  delayLoading: 2000, // delay report to load after x seconds
}


export default config
