import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, useTheme } from '@material-ui/styles'
import { Grid, Card, CardContent, Typography, Button } from '@material-ui/core'
import { AntSwitch, StyledModal } from 'frame/components'
import { useIsAlfaDownDisableFeatures } from 'features/drivers'
import { useEnabledFeatureToggleList } from 'features/settings'
import { hasRoles } from 'acl'
import moment from 'moment'
import {isMiles, isSourceSystemLoaded} from 'utils/sourceSystem'
import Spinner from 'react-md-spinner'


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 1.2, 3, 1.2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
      minHeight: 'min(80vh, 600px)',
    },
  },
  content: { marginTop: theme.spacing(3) },
  contentContainer: {
    paddingBottom: theme.spacing(3),
  },
  logo: {
    width: 414,
    height: 100,
    [theme.breakpoints.down('xs')]: {
      backgroundSize: '250px auto',
    },
    backgroundImage: 'url("/static/images/logo.png")',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  brandColor: {
    color: theme.palette.brand,
  },
  poolVehicle: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      marginTop: theme.spacing(-2),
    }
  },
  roundedButton: {
    borderRadius: '25px',
  },
}))

const UnassignedView = ({
  driverFlags,
  fleetData,
  driverData,
  onCreateNewDriver,
  onAssignExistingDriver,
  onTogglePoolVehicle,
  userRoles,
  onOpenDateForm
}) => {

  const classes = useStyles()
  const theme = useTheme()
  const featureToggleList = useEnabledFeatureToggleList()
  const disableOutOfHoursFeatureToggle = featureToggleList.includes('OutOfHoursDisabled') // 'ALFA out of hours DISABLE FEATURES' limitations feature is ON
  const isAlfaDownDisableDriverFeatures = useIsAlfaDownDisableFeatures()
  const isAlfaDown = disableOutOfHoursFeatureToggle ? false : isAlfaDownDisableDriverFeatures

  const [isPoolVehicle, setIsPoolVehicle] = useState(fleetData.poolVehicle && fleetData.poolVehicle === 'Yes')

  // modal flags
  const [openConfirmPoolVehicleToggleModal, setConfirmPoolVehicleToggleModal] = useState(false)

    const userPermissionsFeatureToggle = featureToggleList.includes('UserPermissions')
  const showButtons = userPermissionsFeatureToggle ? userPermissionsFeatureToggle && hasRoles(['superadmin','fleetcontroller'], userRoles) : true
  const disableButtons = userPermissionsFeatureToggle && hasRoles(['fleetcoordinator'], userRoles)

  useEffect(() => {
    // reset toggle if togglePoolVehicle fails with error flag 
    if(driverFlags.togglePoolVehicle === 'error') {
      setIsPoolVehicle(!isPoolVehicle)
    }
    
  // eslint-disable-next-line
  }, [driverFlags.togglePoolVehicle])

  useEffect(() => {
    // update pool vehicle toggle when fetch once done
    setIsPoolVehicle(fleetData.poolVehicle && fleetData.poolVehicle === 'Yes')    
  // eslint-disable-next-line
  }, [fleetData.poolVehicle])
  
  const handlePoolVehicleToggle = (checked) => {
    setIsPoolVehicle(checked)
    if (isMiles(fleetData.sourceSystem)) {
      onOpenDateForm(true)
    } else {
      setConfirmPoolVehicleToggleModal(true)
    }
  }

  const onConfirmPoolVehicleChange = () => {
    setConfirmPoolVehicleToggleModal(false)
    onTogglePoolVehicle(fleetData.agreementNo, fleetData.thirdPartyNumber, moment(), isPoolVehicle)
  }
  
  const onCancelHandler = () => {
    handlePoolVehicleToggle(fleetData.poolVehicle && fleetData.poolVehicle === 'Yes') // reset default values
    setConfirmPoolVehicleToggleModal(false)
  }
  
  return (
    <Card>
      <CardContent className={classes.root}>
        <Grid item xs={12} className={classes.center}>
          <div className={classes.logo}/>
        </Grid>

        <div className={classes.content}>
          <Grid container spacing={3} className={classes.contentContainer}>
            {showButtons && isSourceSystemLoaded(fleetData.sourceSystem) &&
              <Grid item xs={12} className={classes.poolVehicle}>
                <Typography gutterBottom variant="body1">
                  <span className={classes.brandColor}>Pool Vehicle</span>: Indicate if you would like to switch this to a pool vehicle
                </Typography>
                <AntSwitch
                  disabled={isAlfaDown}
                  style={{ marginLeft: 10 }}
                  value={isPoolVehicle}
                  onChange={handlePoolVehicleToggle}
                  name="poolVehicleSwitch"
                />
              </Grid>
            }
            {!isSourceSystemLoaded(fleetData.sourceSystem) && <Spinner
                singleColor={theme.palette.dark}
                size={25}
              />}
          
            <Grid item xs={12}>
              <Typography variant="body1" align="center">
                There is no driver currently assigned to this vehicle. Click on one of the options below if you would like to assign a driver to this vehicle.
              </Typography>
            </Grid>

            <Grid item xs={12} className={classes.center}>
              <Button
                disabled={isAlfaDown || disableButtons}
                color="primary"
                id="assign-driver-option"
                data-cy="assign-driver-option"
                className={classes.roundedButton}
                variant="contained"
                onClick={onAssignExistingDriver}>
                Assign Existing Driver
              </Button>
            </Grid>

            <Grid item xs={12} className={classes.center}>
              <Button
                disabled={isAlfaDown || disableButtons}
                color="primary"
                id="create-new-driver-option"
                data-cy="create-new-driver-option"
                className={classes.roundedButton}
                variant="contained"
                onClick={onCreateNewDriver}>
                Create New Driver
              </Button>
            </Grid>            

            {/* for pool vehicle toggle confirmation */}
            <StyledModal
              open={openConfirmPoolVehicleToggleModal}
              title={`Pool Vehicle Change`}
              onCancel={onCancelHandler}
              onConfirm={onConfirmPoolVehicleChange}>
              {isPoolVehicle
                ? driverData.driver
                  ? 'Please confirm that you wish to designate this as a pool vehicle. By doing so, you will be removing the assigned driver from the vehicle. '
                  : 'Please confirm that you wish to designate this as a pool vehicle.'
                : 'You are about to switch this to a non pool vehicle'
              }
            </StyledModal>
          </Grid>
        </div>
      </CardContent>
    </Card>
  )
}

UnassignedView.propTypes = {
  driverFlags: PropTypes.object.isRequired,
  fleetData: PropTypes.object.isRequired,
  driverData: PropTypes.object.isRequired,
  onCreateNewDriver: PropTypes.func.isRequired,
  onAssignExistingDriver: PropTypes.func.isRequired,
  onTogglePoolVehicle: PropTypes.func.isRequired,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,

}

export default UnassignedView

