import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Paper, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, IconButton, Tooltip } from '@material-ui/core'
import { StyledModal, PageLoader } from 'frame/components'
import { fbtReasonOptions } from '../actions/types'

import CreateIcon from '@material-ui/icons/Create'
import DeleteIcon from '@material-ui/icons/Delete'
import { useEnabledFeatureToggleList } from 'features/settings'
import { hasRoles } from 'acl'

const getRanges = (array) => {
  let ranges = [], rstart, rend
  if(array && array.length > 0) {
    for (let i = 0; i < array.length; i++) {
      rstart = array[i]
      rend = rstart
      while (array[i + 1] - array[i] === 1) {
        rend = array[i + 1] // increment the index if the numbers sequential
        i++
      }
      ranges.push(rstart === rend ? rstart + '' : rstart + '-' + rend)
    }
  }
  return ranges
}

const useStyles = makeStyles({
  root: { width: '100%' },
  container: { maxHeight: 530, },
  cell: { verticalAlign: 'top' },
})
 
const FbtSubmissionsTable = ({ data = [], loading = false, onSave, onEdit, onDelete,userRoles }) => {

  const classes = useStyles()
  const [deleteSubmission, setDeleteSubmission] = useState()

  const headers = [
    { title: 'Dates', align: 'center' },
    { title: 'Reasons', align: 'left' },
    { title: 'Actions', align: 'center' },
  ]

  const useLastEntrySavePrompt = data.length === 1 && onSave // only used in edit modal
  const featureToggleList = useEnabledFeatureToggleList()
  const userPermissionsFeatureToggle = featureToggleList.includes('UserPermissions')
  const showButtons = userPermissionsFeatureToggle ? userPermissionsFeatureToggle && hasRoles(['superadmin','fleetcontroller'], userRoles) : true
  const onConfirmDelete = () => {    
    onDelete(deleteSubmission)  // normal entry delete
    setDeleteSubmission(null)
    useLastEntrySavePrompt && onSave(true) // on last entry delete - delete, save, close modal
  }

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="service history table">

          <TableHead>
            <TableRow>
              {headers.map((item, index) => (
                <TableCell key={`table-heading-${index}`} align={item.align} className={classes.heading}>
                  <strong>{item.title}</strong>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {loading ? (
              <TableRow >
                  <TableCell colSpan={3}>
                    <PageLoader minHeight={220}/>
                  </TableCell>
              </TableRow>
            ) : data && data.length > 0 ? data.map((record, rowIndex) => (
              <TableRow hover tabIndex={-1} key={`table-row-${rowIndex}`} style={{ backgroundColor: '#FFF' }}>
                
                <TableCell key={`table-days-column-${rowIndex}`} align="center" className={classes.cell}>
                  {record?.days && record?.days.length > 0 ? getRanges(record?.days).join(', ') : '-'}
                </TableCell>

                <TableCell key={`table-reason-column-${rowIndex}`} align="left" className={classes.cell}>
                  {fbtReasonOptions.includes(record?.reason)
                    ? record?.reason
                    : !record?.reason.includes('Other: ')
                      ? `Other: ${record?.reason}`
                      : record?.reason || '-'}
                </TableCell>

               {showButtons &&
                <TableCell key={`table-edit-column-${rowIndex}`} align="center" className={classes.cell}>
                  <Tooltip title="Edit">
                    <IconButton onClick={() => onEdit(record)} size="small">
                      <CreateIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <IconButton onClick={() => setDeleteSubmission(record)} size="small">
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell> 
                }

              </TableRow>
            )) : (
              <TableRow>
                <TableCell align="center" colSpan={headers.length}>
                  No Records.
                </TableCell>
              </TableRow>
            )}
            
          </TableBody>

        </Table>
      </TableContainer>

      {/* Confirm Delete Modal */}
      <StyledModal
        open={!!deleteSubmission}
        title={useLastEntrySavePrompt ? `Confirm Delete and Save Submission` : `Confirm Delete Submission`}
        onCancel={() => setDeleteSubmission(null)}
        onConfirm={onConfirmDelete}>
        {`Please confirm to delete submission for ${deleteSubmission?.days.length > 1 ? 'dates': 'date'} ${getRanges(deleteSubmission?.days).join(', ')}`}
      </StyledModal>
    </Paper>
  )
}

FbtSubmissionsTable.propTypes = {
  data: PropTypes.array,
  onSave: PropTypes.func,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default FbtSubmissionsTable