import { combineEpics } from 'redux-observable'
import { createListable } from 'listable'
import { FEATURE_TOGGLE_SETTINGS_PATH } from './constants'
import options from './config'
import * as fromActions from './actions'
import * as fromConstants from './constants'
import customReducer from './reducer'
import { createEffects } from './effects'

const listableModule = createListable({
  name: fromConstants.MODULE_NAME,
  config: options,
  customActions: fromActions,
  customConstants: fromConstants,
  customReducer,
})

export const config = listableModule.options
export const actions = listableModule.actions
export const reducer = listableModule.reducer
export const types = listableModule.types

export const effects = combineEpics(
  createEffects(listableModule.actions),
  listableModule.effects,
)

export const constants = {
  ...listableModule.constants,
  PATH_ALL: `${FEATURE_TOGGLE_SETTINGS_PATH}`,
  ROUTE_ALL: `${FEATURE_TOGGLE_SETTINGS_PATH}`
}

