import React from 'react'
import { Skeleton } from '@material-ui/lab'
import { Grid, Card, CardHeader, CardActions, Table, TableHead, TableBody, TableRow, TableCell, Divider } from '@material-ui/core'

const SkeletonTable = () => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <Skeleton animation="wave" variant="rect" style={{ width: 480, height: 42 }} />
    </Grid>
    <Grid item xs={12}>
      <Skeleton animation="wave" variant="rect" style={{ width: 300, height: 15 }} />
    </Grid>
    <Grid item xs={12}>
      <Card>
        <CardHeader title={<div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Skeleton variant="rect" style={{ width: 150, height: 20, marginTop: 10 }} />
          <div style={{ display: 'flex' }}>
            <Skeleton animation="wave" variant="circle" width={30} height={30} style={{ marginRight: 10 }} />
            <Skeleton animation="wave" variant="circle" width={30} height={30} style={{ marginRight: 10 }} />
            <Skeleton animation="wave" variant="circle" width={30} height={30} />
          </div>
        </div>} />
        <Divider />
        <Table>
          <TableHead>
            <TableRow>
              {Array.from(Array(10).keys()).map((_, index) => (
                <TableCell key={`skeleton-table-header-${index}`}>
                  <Skeleton animation="wave" variant="rect" width="50" height="50" />
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.from(Array(5).keys()).map((_, rowIndex) => (
              <TableRow key={`skeleton-table-row-${rowIndex}`}>
                {Array.from(Array(10).keys()).map((_, index) => (
                  <TableCell key={`skeleton-table-cell-${index}`}>
                    <Skeleton animation="wave" variant="rect" width="50" height="50" />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <CardActions style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'right' }}>
          <Skeleton animation="wave" variant="rect" style={{ width: 150, height: 20 }} />
          <Skeleton animation="wave" variant="rect" style={{ width: 40, height: 20 }} />
          <Skeleton animation="wave" variant="rect" style={{ width: 40, height: 20 }} />
        </CardActions>
      </Card>
    </Grid>
  </Grid>
)

export default SkeletonTable
