import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { CardHeader, Divider, Typography } from '@material-ui/core'


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  filtered: {
    paddingLeft: theme.spacing(1),
  },
}))

const Title = ({ title, filtering }) => {

  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Typography variant="h5" id="report_title">
        {title}
      </Typography>
      {filtering && (
        <Typography className={classes.filtered} variant="body2">
          <em>Filtered</em>
        </Typography>
      )}
    </div>
  )
}

Title.propTypes = {
  filtering: PropTypes.bool,
  title: PropTypes.string.isRequired,
}

const PageListHeader = ({ actions, filtering, title, customComponent }) => (
  <>
    <CardHeader
      action={actions}
      title={customComponent ? customComponent : <Title title={title} filtering={filtering} />} />
    <Divider />
  </>
)

PageListHeader.propTypes = {
  actions: PropTypes.node,
  filtering: PropTypes.bool,
  title: PropTypes.string.isRequired,
  customComponent: PropTypes.any
}

export default PageListHeader
