import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { constants } from '../../module'
import { Dialog } from 'frame/components'
import { useNavigate, useRouter } from 'utils'
import AddEditFbtRoutes from './routes'
import { useSelectedPremiumFleets } from 'features/settings'

import FormStep1 from './form-step1'

const Add = ({ flags, filtered, currentFbtCustomerView, onSave, onReset, onLookup, onSetNewSubmissionDetails }) => {

  const navigate = useNavigate()
  const { location, match } = useRouter()
  const fbtFleets = useSelectedPremiumFleets()

  const currentSelectedFbtFleet = currentFbtCustomerView || fbtFleets[0]?.id
  
  const [showAddSubmissionModal, setShowAddSubmissionModal] = useState(false)  
  const [values, setValues] = useState({ driverName: '', costCentre: '', thirdPartyNumber: currentSelectedFbtFleet })

  const onClose = navigate(`${constants.PATH_ALL}${location.search}`)

  useEffect(() => {
    onReset()
  }, []) // eslint-disable-line

  const title = values?.driver ? `${values?.driver} - Fringe Benefit Tax` : `Fringe Benefit Tax`
  const isSaving = flags?.addFbtSubmission === 'processing'

  const tabsConfig = [
    {
      label: 'Add/Edit Submission',
      path: `${match.url}${constants.PATH_ADD_SUBMISSION}`,
      visible: true,
    }, {
      label: 'View Submissions',
      path: `${match.url}${constants.PATH_VIEW_SUBMISSIONS}`,
      visible: true,
    },
  ]

  return (
    <>
      {showAddSubmissionModal ? ( // add submission form
        <Dialog
          open
          size="md"
          minHeight={720}
          headerMinHeight={126}
          aria-labelledby="Edit FBT Submission"
          title={title}
          tabsConfig={tabsConfig}
          loading={isSaving}
          onClose={onClose}>
          <AddEditFbtRoutes />
        </Dialog>
      ) : ( // initial step
        <Dialog
          open autoSize
          size="md"
          headerMinHeight={30}
          aria-labelledby="Add FBT Submission"
          loading={isSaving}
          onClose={onClose}>
          <FormStep1
            values={values}
            filtered={filtered}
            onClose={onClose}
            setValues={setValues}
            onProceed={() => {
              setShowAddSubmissionModal(true)
              onSetNewSubmissionDetails(values)
              navigate(`${match.url}${constants.PATH_ADD_SUBMISSION}`)(0)
            }}
            onLookup={onLookup}
            onSave={onSave}
          />
        </Dialog>
      )}
    </>
  )
}

Add.propTypes = {
  flags: PropTypes.object,
  filtered: PropTypes.object,
  currentFbtCustomerView: PropTypes.string,
  // func
  onSave: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onLookup: PropTypes.func.isRequired,
  onSetNewSubmissionDetails: PropTypes.func.isRequired,
}

export default Add
