import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Divider, Typography } from '@material-ui/core'
import { SingleSelect } from 'frame/components'


const useStyles = makeStyles(theme => ({
  root: {
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 0),
    cursor: 'pointer',
  },
  content: {},
  formGroup: {
    padding: theme.spacing(2, 0),
  },
}))

const FilterAttributes = ({ filters, filtered, onFilter }) => {

  const classes = useStyles()
  const hasList = (list) => list.length > 0
  const { costCentres, effectiveDates, status } = filters

  const onSelectChange = (key) => (value) =>
    onFilter({
      [key]: value,
    })

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h5">Filters</Typography>
      </div>
      <Divider />
      <div className={classes.content}>
        {hasList(costCentres) && (
          <div className={classes.formGroup}>
            <SingleSelect
              id="costCentre"
              label="Cost Centre"
              options={costCentres}
              value={filtered.costCentre}
              tight
              onChange={onSelectChange('costCentre')} />
          </div>
        )}
        {hasList(effectiveDates) && (
          <div className={classes.formGroup}>
            <SingleSelect
              id="effectiveDates"
              label="Effective Date"
              options={effectiveDates}
              value={filtered.effectiveDate}
              tight
              onChange={onSelectChange('effectiveDate')} />
          </div>
        )}
        {hasList(status) && (
          <div className={classes.formGroup}>
            <SingleSelect
              id="status"
              label="Status"
              options={status}
              value={filtered.status}
              tight
              onChange={onSelectChange('status')} />
          </div>
        )}
      </div>
    </div>
  )
}

FilterAttributes.propTypes = {
  filters: PropTypes.object.isRequired,
  filtered: PropTypes.object.isRequired,
  onFilter: PropTypes.func.isRequired,
}

export default FilterAttributes
