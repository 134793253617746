import PropTypes from 'prop-types'


export const model = [{
  key: 'agreementNo',
  label: 'Agreement No.',
  column: {
    visible: false,
    width: 115,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'costCentre',
  label: 'Cost Centre',
  column: {
    visible: true,
    width: 120,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}
// , {
//   key: 'disposalDate',
//   label: 'Disposal Date',
//   premium: true,
//   column: {
//     visible: false,
//     width: 130,
//   },
//   prop: {
//     type: PropTypes.string,
//     value: '',
//   }
// }
, {
  key: 'driver',
  label: 'Driver',
  column: {
    visible: true,
    width: 190,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'excessKmCost',
  label: 'Excess KM Cost (excl)',
  column: {
    visible: true,
    width: 200,
  },
  prop: {
    type: PropTypes.number,
    value: '',
  }
}, {
  key: 'extensions',
  label: `Extension Rentals (excl)`,
  column: {
    visible: true,
    width: 160,
  },
  prop: {
    type: PropTypes.number,
    value: '',
  }
}, {
  key: 'fuelType',
  label: `Fuel Type`,
  column: {
    visible: false,
    width: 90,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'returnDate',
  label: 'Return Date',
  column: {
    visible: false,
    width: 125,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'make',
  label: 'Make',
  column: {
    visible: false,
    width: 100,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'model',
  label: `Model`,
  column: {
    visible: false,
    width: 100,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'refurbCosts',
  label: 'Refurb Costs (excl)',
  column: {
    visible: true,
    width: 135,
  },
  prop: {
    type: PropTypes.number,
    value: '',
  }
}, {
  key: 'registration',
  label: 'Registration',
  column: {
    visible: true,
    width: 110,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'total',
  label: 'Total',
  column: {
    visible: true,
    width: 130,
  },
  prop: {
    type: PropTypes.number,
    value: '',
  }
}, {
  key: 'underKmCredit',
  label: 'Under KM Credit (excl)',
  column: {
    visible: true,
    width: 155,
  },
  prop: {
    type: PropTypes.number,
    value: '',
  }
}, {
  key: 'variant',
  label: 'Variant',
  column: {
    visible: false,
    width: 200,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'year',
  label: 'Year',
  column: {
    visible: false,
    width: 80,
  },
  prop: {
    type: PropTypes.number,
    value: '',
  }
}, {
  key: 'accountName',
  label: 'Fleet',
  column: {
    visible: true,
    width: 80,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}]

const config = {
  title: 'End of Lease',
  breadcrumbs: null,
  backgroundImage: 'url("/static/images/backgrounds/bg4.png")',
  api: {
    filter: '/filteroptions/endoflease',
    resource: `/report/endoflease`,
    download: `/download/endoflease`,
  },
  model,
  headers: [
    'accountName', 'agreementNo', 'registration', 'assetBranch', 'assetFunction', 'driver',
    'year', 'make', 'model', 'variant', 
    'fuelType', 'costCentre', 'extensions',
    'refurbCosts', 'excessKmCost', 'underKmCredit', 'total', 'returnDate',
    //'disposalDate',
  ],
  filters: [{
    key: 'costCentre',
    label: 'Cost Centre',
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }, {
    key: 'returnDate',
    label: 'Return Month',
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }],
}


export default config
