import * as fromActions from './actions/types'
import * as R from 'ramda'
import * as fromDriverActions from 'features/drivers/actions/types'

const reducer = (state, action) => {
  switch (action.type) {
    // Fetch Contract Variation by agreementNo
    case fromActions.FETCH:
      return updateFlags(state, { fetch: 'processing', update: 'none' })
    case fromActions.FETCH_FULFILLED:
      return addFetchedContract(state, action)
    case fromActions.FETCH_ERROR:
      return onError(state, action, { fetch: 'error' })
    
    case fromActions.ON_CHANGE: 
      return onChange(R.clone(state), action)
    case fromDriverActions.FETCH_BY_AGREEMENT_FULFILLED: 
      return addRelatedDriver(R.clone(state), action)

    // Lookup
    case fromActions.LOOKUP:
      return onLookup(state, action)
    case fromActions.LOOKUP_ERROR:
      return onLookupError(state, action)
    case fromActions.LOOKUP_FULFILLED:
      return onLookupSuccess(state, action)
    
    case fromActions.RESET_LOOKUPS:
      return onResetLookup(R.clone(state), action)
    case fromActions.RESET: // resets all flags on page load
      return resetFlags(state)
    default:
      return state
  }
}


// used on tfs modal open to set related driver record from driver list
const addRelatedDriver =  (state, { payload }) => {
  // console.log('[tfs fleet addRelatedDriver] ', payload )
  state.related = payload
  return state
}

const onChange = (state, { payload }) => {
  state.form = payload
  return state
}

const onError = (state, action, flags) => {
  const error = action.payload
  state.errorMessage = typeof error === 'string' ? action.payload : ''
  return updateFlags(state, flags)
}

const updateFlags = (state, flags) => ({
  ...state,
  flags: { ...state.flags, ...flags }
})

const resetFlags = (state, flags) => ({
  ...state,
  errorMessage: null,
  flags: {
    ...state.flags,
    fetch: 'none',
    update: 'none',
    deleteVariation: 'none',
    ...flags,
  },
})

const addFetchedContract = (state, { payload }) => {
  // console.log('addFetchedContract [state]', state)
  // console.log('addFetchedContract [payload]', payload)

  // fix missing values
  const id = payload.agreementNo

  const contract = {
    ...payload,
    accountName: state.data[id].accountName,
    agreementType: state.data[id].agreementType,
    kmUseRate: state.data[id].kmUseRate,
    lastKmReading: state.data[id].lastKmReading
  }
  
  return {
    ...state,
    data: {
      ...state.data,
      [id]: contract,
    },
    flags: {
      ...state.flags,
      fetch: 'processed',
    }
  }
}

const onLookup = (state, { payload }) => {
  return updateFlags(state, { lookup: 'processing' })
}

const onLookupError = (state, { payload }) => {
  console.log('[onLookupError]: ', payload)
  return updateFlags(state, { lookup: 'error' })
}

const onLookupSuccess = (state, { payload }) => {
  let options = []

  if(payload && payload.length > 0) {
    const cleanData = [...(new Set(payload))]
      .reduce((result, element) => {
        const normalize = x => typeof x === 'string' ? x.toLowerCase() : x
  
        if (result.every(otherElement => normalize(otherElement.value) !== normalize(element.value)))
          result.push(element)
  
        return result
      }, [])
  
    options = cleanData
  }
  const lookupResourceName = JSON.parse(localStorage.getItem('lookup_resource_name'))
  localStorage.removeItem('lookup_resource_name')

  state.lookup = {
    ...state.lookup,
    [lookupResourceName]: options
  }
  return updateFlags(state, { lookup: 'processed' })
}

const onResetLookup = (state, { payload }) => {
  state.lookup = {}
  return state
}

export default reducer
