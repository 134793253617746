import * as fromActions from './actions/types'


const reducer = (state, action) => {
  switch (action.type) {
    // to reset all flags on page load
    case fromActions.RESET:
      return resetFlags(state)

    // Fetch Service Booking
    case fromActions.FETCH_SERVICE_BOOKING:
      return updateFlags(state, { fetchServiceBooking: 'processing' })
    case fromActions.FETCH_SERVICE_BOOKING_FULFILLED: {
      state.relatedServiceBooking = action.payload
      return updateFlags(state, { fetchServiceBooking: 'processed' })
    }
    case fromActions.FETCH_SERVICE_BOOKING_ERROR:
      return onUpdateError(state, action, { fetchServiceBooking: 'error' })
    
    // Add Service Booking
    case fromActions.ADD_SERVICE_BOOKING:
      return resetFlags(state, { addServiceBooking: 'processing' })
    case fromActions.ADD_SERVICE_BOOKING_FULFILLED:
      return onUpdateSuccess(state, { addServiceBooking: 'processed' }, 'You have successfully indicated a service has been booked for your vehicle.')
    case fromActions.ADD_SERVICE_BOOKING_ERROR:
      return onUpdateError(state, action, { addServiceBooking: 'error' }, 'Add service booking request failed.')
    
    // Cancel Service Booking
    case fromActions.CANCEL_SERVICE_BOOKING:
      return resetFlags(state, { cancelServiceBooking: 'processing' })
    case fromActions.CANCEL_SERVICE_BOOKING_FULFILLED:
      return onUpdateSuccess(state, { cancelServiceBooking: 'processed' }, 'Your service booking request has been successfully cancelled.')
    case fromActions.CANCEL_SERVICE_BOOKING_ERROR:
      return onUpdateError(state, action, { cancelServiceBooking: 'error' }, 'Cancel service booking request failed.')
  
    // Submit Service Record
    case fromActions.SUBMIT_SERVICE_RECORD:
      return resetFlags(state, { submitServiceRecord: 'processing' })
    case fromActions.SUBMIT_SERVICE_RECORD_FULFILLED:
      return onUpdateSuccess(state, { submitServiceRecord: 'processed' }, 'You have successfully updated your service record.')
    case fromActions.SUBMIT_SERVICE_RECORD_ERROR:
      return onUpdateError(state, action, { submitServiceRecord: 'error' },  'Updating service record failed.')

    // Complete Service Booking
    case fromActions.COMPLETE_SERVICE_BOOKING:
      return resetFlags(state, { completeServiceBooking: 'processing' })
    case fromActions.COMPLETE_SERVICE_BOOKING_FULFILLED:
      return onUpdateSuccess(state, { completeServiceBooking: 'processed' }, 'Your service booking request has been successfully completed.')
    case fromActions.COMPLETE_SERVICE_BOOKING_ERROR:
      return onUpdateError(state, action, { completeServiceBooking: 'error' },  'Updating service booking record failed.')
    default:
      return state
  }
}

const onUpdateError = (state, { payload }, flags, errorMessage) => {
  const error = payload
  state.apiResponse = typeof error === 'string' ? `${errorMessage} ${payload}` : ''
  return updateFlags(state, flags)
}

const onUpdateSuccess = (state, flags, successMessage) => {
  state.apiResponse = successMessage
  return updateFlags(state, flags)
}

const updateFlags = (state, flags) => ({
  ...state,
  flags: { ...state.flags, ...flags }
})

const resetFlags = (state, flags) => ({
  ...state,
  apiResponse: null,
  flags: {
    ...state.flags,
    fetchServiceBooking: 'none',
    addServiceBooking: 'none',
    cancelServiceBooking: 'none',
    completeServiceBooking: 'none',
    submitServiceRecord: 'none',
    ...flags,
  },
})

export default reducer
