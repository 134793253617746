import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, useTheme } from '@material-ui/styles'
import { Alert, SingleSelectValidator, OfflineLookupField, RowValueText, Label, EmailTextField } from 'frame/components'
import { types } from '../../module'
import { useSelectedFleets, useSelectedFleetIds, useEnabledFeatureToggleList } from 'features/settings'
import { Button, Card, CardContent, Grid, Typography, TextField } from '@material-ui/core'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { lookupRequest } from 'utils'
import { getDriverStatusColor } from 'listable'
import ReadOnlyForm from './read-only-form'
import Spinner from 'react-md-spinner'
import { useInView } from "react-intersection-observer"
import { useIsAlfaDownDisableFeatures } from 'features/drivers'
import { hasRoles } from 'acl'


const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3),
  },
  statusMessage: {
    marginBottom: theme.spacing(4),
  },
  content: {
    padding: theme.spacing(3, 1.2, 3, 1.2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
  },
  title: {
    marginTop: theme.spacing(5),
  },
  fieldGroup: {
    margin: theme.spacing(3, 0, 2, 0),
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    }
  },
  button: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    minWidth: 180,
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
    }
  },
  label: {
    paddingBottom: theme.spacing(1),
  },
  fetchVehicleLoader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

const Form = ({
  values,
  setValues,
  disabled,
  onClose,
  onSubmit,
  onChange,
  driverName,
  tryChangingVehicle,
  setTryChangingVehicle,
  onShowAssignForm,
  saveStatus,
  doneFetchingVehicle,
  setDoneFetchingVehicle,
  vehicleDetails,
  setVehicleDetails,
  originalValues,
  setOriginalValues,
  onLookup,
  apiResponse,
  userRoles
}) => {

  const form = useRef()
  const [ref, inView] = useInView({ threshold: 0 }) // for triggering the error message timer when its in view 

  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'))
  const classes = useStyles()
  const userSelectedFleets = useSelectedFleets()
  const fleetIds = useSelectedFleetIds()
  const featureToggleList = useEnabledFeatureToggleList()

  const disableOutOfHoursFeatureToggle = featureToggleList.includes('OutOfHoursDisabled') // 'ALFA out of hours DISABLE FEATURES' limitations feature is ON
  const isAlfaDownDisableDriverFeatures = useIsAlfaDownDisableFeatures()
  const isAlfaDown = disableOutOfHoursFeatureToggle ? false : isAlfaDownDisableDriverFeatures
  
  const userPermissionsFeatureToggle = featureToggleList.includes('UserPermissions')

  const [loaded, setLoaded] = useState(false)
  const [isChangingVehicle, setIsChangingVehicle] = useState(false)
  const [showStatusMessage, setShowStatusMessage] = useState(false)
  const [disableValidation, setDisableValidation] = useState(false)
  const [saveCounter, setSaveCounter] = useState(0)

  const onSubmitForm = () => {
    if(isChangingVehicle) { // for changing vehicle      
      onShowAssignForm()

    } else {
      // for save changes
      onSubmit(values)
      setDisableValidation(true)

      let counter = saveCounter + 1
      setSaveCounter(counter)
    }
  }

  const onSave = () => {
    if(form.current) {
      setIsChangingVehicle(false)
      form.current.submit()
    }
  }

  useEffect(() => { // tryChangingVehicle is set true on Change Vehicle button out side the form to trigger form validation
    if (tryChangingVehicle && form.current) {

      // quick fix for email already exist when u try to assign vehicle straight after editing email
      saveCounter > 0 && originalValues.email === values.email && setDisableValidation(true)

      setIsChangingVehicle(true)
      setTryChangingVehicle(false)

      form.current.submit()
    }// eslint-disable-next-line
  }, [tryChangingVehicle, setTryChangingVehicle])
  
  
  const onLookupChange = (key) => (option) =>
    setValues({ ...values, [key]: option ? option.value : null })

  const onSelectChange = (key) => (option) =>
    setValues({ ...values, [key]: option.value || option })

  const onInputChange = ({ target: { id, name, value } }) =>
    setValues({ ...values, [id || name]: value })


  useEffect(() => {
    async function fetchVehicle() {
      const data = await lookupRequest(fleetIds, '/lookup/driverAgreement', originalValues.registration || '')
      // console.log('[fetchVehicle]: ', data)
      setVehicleDetails(data)
      setDoneFetchingVehicle(true)
    }
    
    !values.terminated && values.status !== 'Unassigned' && !doneFetchingVehicle
      ? fetchVehicle()
      : setDoneFetchingVehicle(true)
  }, []) // eslint-disable-line

  
  useEffect(() => {
    async function fetchAndsetThirdPartyNumber() {
      if(userSelectedFleets.length === 1) {
        const data = await lookupRequest(fleetIds, '/lookup/fleetId', userSelectedFleets[0].label)
        // console.log('[fetchAndsetThirdPartyNumber]: ', data)
        data && setValues({
          ...values,
          thirdPartyNumber: data.value || '-',
          accountName: data.text || '-',
        })
      }
    }
    setLoaded(true)
    fetchAndsetThirdPartyNumber()
  }, []) // eslint-disable-line
  
  useEffect(() => {
    ValidatorForm.addValidationRule('isNotSpaces', (value) => {
      let cleanedValue = value.replace(/ /g, '')
      return cleanedValue && cleanedValue.length > 0
    })
    return () => {
      ValidatorForm.removeValidationRule('isNotSpaces')
    }
  }, [values.driver])

  useEffect(() => {
    if(saveStatus === 'processed' || saveStatus === 'error') {
      loaded && setShowStatusMessage(true)
      if(saveStatus === 'processed') {
        onChange(values)
        setOriginalValues(values)
      }
    }
    // eslint-disable-next-line
  }, [saveStatus])

  useEffect(() => {
    if(inView && showStatusMessage && (saveStatus === 'processed' || saveStatus === 'error')) {
      setTimeout(() => { // clear status message after 3 secs after error message is in view
        setShowStatusMessage(false)
      }, 3000)
    } // eslint-disable-next-line
  }, [inView])
  
  const readonly = isAlfaDown // false

  const isEditable = !readonly && values.status !== 'Terminated'
  const editable = userPermissionsFeatureToggle ? hasRoles(['admin', 'superadmin', 'fleetcontroller'] ,userRoles) && isEditable : isEditable
  
  return (
    <ValidatorForm autoComplete="off" noValidate ref={form} onSubmit={onSubmitForm}>
      <Card>
        <CardContent className={classes.content}>
          {showStatusMessage && (
            <div ref={ref}>
              <Alert className={classes.statusMessage} isSuccess={saveStatus === 'processed'}>
                {saveStatus === 'processed' && 'Details updated successfully'}
                {saveStatus === 'error' && (apiResponse || 'Error occured. Details update failed.')}
              </Alert>
            </div>
          )}

          <Typography gutterBottom variant="h4">
            Driver Details
          </Typography>
          {editable ?(
            <div className={classes.fieldGroup}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <RowValueText label="Fleet">
                    <Typography variant="body1">
                      {values.accountName || '-'}
                    </Typography>
                  </RowValueText>
                </Grid>

                <Grid item xs={12}>
                  <RowValueText label="Name" responsive>
                    <TextValidator
                      id="driver"
                      size="small"
                      className={classes.field}
                      disabled={true}
                      fullWidth
                      label={mobileView ? 'Name' : ''}
                      InputLabelProps={{ shrink: mobileView }} // to show label only on mobile
                      value={values.driver || ''}
                      variant="outlined"
                      inputProps={{ maxLength: 50 }}
                      validators={['required', 'isNotSpaces']}
                      errorMessages={['This field is required', 'Please enter a valid name']}
                      onChange={onInputChange}
                    />
                  </RowValueText>
                </Grid>

                <Grid item xs={12}>
                  <RowValueText label="Email" responsive>
                    <EmailTextField
                      fullWidth
                      id="email"
                      size="small"
                      className={classes.field}
                      label="Email"
                      hideLabelOnMobile={!mobileView} // to show label only on mobile
                      disabled={!!originalValues.email || disabled}
                      inputProps={{ maxLength: 50 }}
                      value={values.email || ''}
                      variant="outlined"
                      onChange={onSelectChange('email')}
                      originalValue={originalValues.email}
                      disableValidation={disableValidation}
                      setDisableValidation={setDisableValidation}
                    />
                  </RowValueText>
                </Grid>

                <Grid item xs={12}>
                  <RowValueText label="Phone" responsive>
                    <TextValidator
                      id="mobile"
                      size="small"
                      className={classes.field}
                      disabled={disabled}
                      fullWidth
                      label={mobileView ? 'Phone' : ''}
                      InputLabelProps={{ shrink: mobileView }}
                      value={values.mobile || ''}
                      inputProps={{ maxLength: 50 }}
                      variant="outlined"
                      validators={['isNumber']}
                      errorMessages={['A valid phone number is required']}
                      onChange={onInputChange}
                    />
                  </RowValueText>
                </Grid>

                <Grid item xs={12}>
                  <RowValueText label="Notification Method" responsive>
                    <SingleSelectValidator
                      noNone
                      id="driverNotification"
                      size="small"
                      disabled={disabled}
                      label={mobileView ? 'Notification Method' : ''}
                      InputLabelProps={{ shrink: mobileView }}
                      options={['Email', 'Text', 'In App', 'N/A']}
                      validators={['required']}
                      errorMessages={['This field is required']}
                      value={values.driverNotification}
                      onChange={onSelectChange('driverNotification')}
                    />
                  </RowValueText>
                </Grid>

                <Grid item xs={12} style={{ marginTop: '15px'}}>
                  <RowValueText label="Status" labelClassName={classes.label}>
                    <div style={{ marginTop: '-5px'}}>
                      <Label
                        color={getDriverStatusColor(values.status)}
                        variant="outlined">
                        {values.status || '-'}
                      </Label>
                    </div>
                  </RowValueText>
                </Grid>

              </Grid>
            </div>
          ) :   (
            <div style={{ paddingTop: '15px' }}>
              <ReadOnlyForm values={values} />
            </div>
          )}
          

          <div className={classes.title}>
            <Typography gutterBottom variant="h4">
              Vehicle Details
            </Typography>
          </div>

          <div className={classes.fieldGroup}>
            {vehicleDetails && doneFetchingVehicle && (
              <Grid container spacing={2}>
            
                <Grid item xs={12}>
                  <RowValueText label="Vehicle">
                    <Typography variant="body1">
                      {vehicleDetails.vehicle || '-'}
                    </Typography>
                  </RowValueText>
                </Grid>

                <Grid item xs={12} style={{ marginTop: '10px'}}>
                  <RowValueText label="Registration">
                    <Typography variant="body1">
                      {vehicleDetails.text || '-'}
                    </Typography>
                  </RowValueText>
                </Grid>

                <Grid item xs={12} style={{ marginTop: '10px'}}>
                  <RowValueText label="Colour">
                    <Typography variant="body1">
                      {vehicleDetails.colour && vehicleDetails.colour.trim() !== ''
                        ? vehicleDetails.colour
                        : '-'
                      }
                    </Typography>
                  </RowValueText>
                </Grid>
                
                <Grid item xs={12}>
                  <RowValueText label="Cost Centre" responsive>
                    {readonly || !editable ? (                    
                      <Typography variant="body1">
                        {vehicleDetails.costCentre || '-'}
                      </Typography>
                    ) : (
                      <OfflineLookupField
                        fullWidth showAllResult allowFreeText
                        id="costCentre"
                        size="small"
                        label="Cost Centre"
                        hideLabelOnMobile={!mobileView} // to show label only on mobile
                        disabled={disabled}
                        customFleetId={values.thirdPartyNumber}
                        lookupPath="drivercostcentre"
                        value={values.costCentre || ''}
                        variant="outlined"
                        // validators={['required']}
                        // errorMessages={['This field is required']}
                        onChange={onLookupChange('costCentre')}
                        defaultOptions={values.costCentre ? [{ text: values.costCentre, value: values.costCentre }] : []}
                        onLookup={onLookup}
                        resourceModel="drivers"
                      />
                    )}
                  </RowValueText>
                </Grid>

                <Grid item xs={12}>
                  <RowValueText label="Location" responsive>
                    {readonly || !editable ? (                    
                      <Typography variant="body1">
                        {vehicleDetails.location || '-'}
                      </Typography>
                    ) : (
                      <OfflineLookupField
                        showAllResult
                        id="location"
                        size="small"
                        disabled={disabled}
                        fullWidth
                        label="Location"
                        hideLabelOnMobile={!mobileView} // to show label only on mobile
                        lookupPath="location"
                        value={values.location || 'Unknown'}
                        variant="outlined"
                        // validators={['required']}
                        // errorMessages={['This field is required']}
                        onChange={onLookupChange('location')}
                        defaultOptions={values.location ? [{ text: values.location, value: values.location }] : []}
                        onLookup={onLookup}
                        resourceModel="drivers"
                      />
                    )}
                  </RowValueText>
                </Grid>

                <Grid item xs={12}>    
                  <RowValueText label="Fleet Controller" responsive>
                    {readonly || !editable ? (                    
                      <Typography variant="body1">
                        {vehicleDetails.controller || '-'}
                      </Typography>
                    ) : (
                      <OfflineLookupField
                        fullWidth showAllResult allowFreeText
                        id="controller"
                        size="small"
                        label="Fleet Controller"
                        hideLabelOnMobile={!mobileView} // to show label only on mobile
                        disabled={disabled}
                        customFleetId={values.thirdPartyNumber}
                        lookupPath="driverfleetcontroller"
                        value={values.controller || ''}
                        variant="outlined"
                        // validators={['required']}
                        // errorMessages={['This field is required']}
                        onChange={onLookupChange('controller')}
                        defaultOptions={values.controller ? [{ text: values.controller, value: values.controller }] : []}
                        onLookup={onLookup}
                        resourceModel="drivers"
                      />
                    )}
                  </RowValueText>
                </Grid>

                <Grid item xs={12}>
                  <RowValueText label="Vehicle Notes" responsive>
                    {readonly || !editable ? (                    
                      <Typography variant="body1">
                        {vehicleDetails.vehicleNotes || '-'}
                      </Typography>
                    ) : (
                      <TextField
                        // multiline
                        // rows={3}
                        size="small"
                        id="vehicleNotes"
                        className={classes.field}
                        disabled={disabled}
                        fullWidth
                        label={mobileView ? 'Vehicle Notes' : ''}
                        InputLabelProps={{ shrink: mobileView }}
                        value={values.vehicleNotes || ''}
                        inputProps={{ maxLength: 50 }}
                        variant="outlined"
                        onChange={onInputChange}
                      />
                    )}
                  </RowValueText>
                </Grid>

              </Grid>
            )}

            {!vehicleDetails && doneFetchingVehicle && (
              <Typography variant="body1">
                No vehicle currently assigned to {driverName}.
              </Typography>
            )}

            {!doneFetchingVehicle && (
              <div className={classes.fetchVehicleLoader}>
                <Spinner
                  singleColor={theme.palette.dark}
                  size={25}
                />
              </div>
            )}
          </div>
        </CardContent>
      </Card>

      <div className={classes.actions}>
        {editable && (
          <Button
            className={classes.button}
            color="primary"
            disabled={disabled}
            variant="contained"
            onClick={onSave}>
            Save Changes
          </Button>
        )}

        <Button
          className={classes.button}
          color={editable ? 'secondary' : 'primary'}
          variant="contained"
          onClick={onClose}>
          Close
        </Button>
      </div>
    </ValidatorForm>
  )
}

Form.propTypes = {
  disabled: PropTypes.bool,
  values: PropTypes.oneOfType([
    PropTypes.object,
    types.ModelType,
  ]).isRequired,
  setValues: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  driverName: PropTypes.string,
  tryChangingVehicle: PropTypes.bool.isRequired,
  setTryChangingVehicle: PropTypes.func.isRequired,
  onShowAssignForm: PropTypes.func.isRequired,
  saveStatus: PropTypes.string,
  doneFetchingVehicle: PropTypes.bool,
  setDoneFetchingVehicle: PropTypes.func.isRequired,
  vehicleDetails: PropTypes.object,
  setVehicleDetails: PropTypes.func.isRequired,
  originalValues: PropTypes.object,
  setOriginalValues: PropTypes.func.isRequired,
  onLookup: PropTypes.func.isRequired,
  apiResponse: PropTypes.string,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,

}

export default Form
