import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import { Layout } from 'frame'
import { Page } from 'frame/components'
import { checkHaveAppUsers } from '../actions'

// section components
import MyFleetSummary from './my-fleet-summary'

import MyNotifications from './my-notfications'
import StatusUpdates from './status-updates'
import IndustryNews from './industry-news'

import Downloads from './downloads/downloads'
import FuelCardPromo from './fuel-card-promo'
import ContactInformation from './contact-information'

import Footer from './footer'
import PromoCard from './promo-card'


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  container: {
    position: 'relative',
    '& > *': { height: '100%' }
  },
}))

const SummaryDashboard = ({ haveAppUsers, onCheckHaveAppUsers}) => {

  const classes = useStyles()
  const showPromoCard = !haveAppUsers 

  useEffect(() => {
    onCheckHaveAppUsers()
  }, [onCheckHaveAppUsers])

  return (
    <Layout backgroundImage="url('/static/images/backgrounds/bg1.png')">
      <Page className={classes.root} title="My Fleet Summary Dashboard">
        {/* <PageHeader title="My Fleet Summary Dashboard" /> */}

        <Grid className={classes.container} container spacing={3}>
          
          <Grid item xs={12}>
            <MyFleetSummary />          
          </Grid>

          {/* 2nd Row */}
          <Grid item xs={12} sm={12} md={6} lg={4}>
            {showPromoCard ? <PromoCard /> : <MyNotifications />}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <StatusUpdates />
          </Grid>              
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <IndustryNews />
          </Grid>
          {/* End of 2nd Row */}
          
          {/* 3rd Row */}
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <Downloads />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <FuelCardPromo />
          </Grid>              
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <ContactInformation />
          </Grid>
          {/* End of 3rd Row */}
        </Grid>
      </Page>

      <Footer />
    </Layout>
  )
}

SummaryDashboard.propTypes = {
  haveAppUsers: PropTypes.bool,
  onCheckHaveAppUsers: PropTypes.func.isRequired,
}

const mapStateToProps = ({ homepage }) => ({
  haveAppUsers: homepage?.haveAppUsers,
})

const mapDispatchToProps = (dispatch) => ({
  onCheckHaveAppUsers: () => dispatch(checkHaveAppUsers()),
})

export default connect(mapStateToProps, mapDispatchToProps)(SummaryDashboard)