import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid, Card, CardContent, Typography } from '@material-ui/core'
import { AnimatedSuccessTick, Alert } from 'frame/components'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'


const useStyles = makeStyles(theme => ({
  heading: { fontWeight: 'bold' },
  card: {
    margin: theme.spacing(5, 0),
    paddingTop: theme.spacing(5),
    paddingBottom: '300px',
    width: 960,
    [theme.breakpoints.down(960)]: {
      width: '100%',
      margin: '20px 10px 0 10px'
    },
  },
}))

const FBTWebFormFinish = ({ fbtWebForm }) => {

  const classes = useStyles()

  return (
    <Card className={classes.card}>
      <CardContent>
        {!fbtWebForm ? (
          <Alert severity="error">
          {'Invalid token. This FBT Submissions link is invalid or has been expired'}
          </Alert>
        ) : (
          <Grid container spacing={3}>
            <Grid xs={12} item>
              <Typography gutterBottom variant="h4" align="center" className={classes.heading}>
                Submission Completed
              </Typography>
              <Typography gutterBottom variant="body1" align="center">
                If changes are required, please contact your Fleet Controller
              </Typography>
            </Grid>
            <Grid xs={12} item>
              <AnimatedSuccessTick size={200} />
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  )
}


FBTWebFormFinish.propTypes = {
  fbtWebForm: PropTypes.object,
}

const mapStateToProps = ({ fbtSubmissions }) => ({
  fbtWebForm: fbtSubmissions.fbtWebForm,
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(FBTWebFormFinish)