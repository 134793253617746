import React, { useMemo, useState } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Card, CardContent, Grid, Typography, Button } from '@material-ui/core'
import { hasRoles } from 'acl'
import { getStatusColor } from 'listable'
import { SelectableValueText, ValueText, StyledModal } from 'frame/components'
import { useEnabledFeatureToggleList } from 'features/settings'


const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3),
  },
  fieldHeader: {
    marginTop: theme.spacing(5),
  },
  fieldGroup: {
    margin: theme.spacing(3, 0, 2, 0),
  },
  actions: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    }
  },
  button: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    minWidth: 180,
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
    }
  },
}))

const Form = ({ data, userRoles, onClose, onSave, onDelete }) => {

  const classes = useStyles()
  const { booking, contract } = data
  const [values, setValues] = useState(booking)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [isEditingKey, setIsEditingKey] = useState()

  const featureToggleList = useEnabledFeatureToggleList()
  const tfp1838FeatureToggle = featureToggleList.includes('TFP1838')

  const onDeleteHandler = () => {
    onDelete(booking.id, booking.thirdPartyNumber)
    onClose()
  }

  const statusOptions = useMemo(() => ([{
    value: 'requested',
    label: 'Requested',
  }, {
    value: 'pending',
    label: 'Pending',
  }, {
    value: 'completed',
    label: 'Completed',
  }, {
    value: 'cancelled',
    label: 'Cancelled',
  }]), [])

  const onEditableChange = (key) => (value) =>
    setValues({
      ...values,
      [key]: value,
    })

  const onInputChange = (key) => ({ target: { value } }) =>
    setValues({
      ...values,
      [key]: value,
    })

  const onCancelChange = (key) => () => {
    setValues({
      ...values,
      [key]: booking[key],
    })
  }

  const onSaveChanges = (key) => (value) =>
    onSave({
      ...booking,
      id: values.id || 0,
      [key]: value,
    })

  const onEdit = (key) => () => { // function passed into valueText component to call on edit mode select
    setValues({
      ...booking,
      [key]: values[key] // reset all values, except current key
    })
    setIsEditingKey(key) // reset other fields out of edit mode
  }

  const editable = contract && values.status &&
    (values.status.toLowerCase() === 'requested' || values.status.toLowerCase() === 'pending')

  const getMaxEffectiveDate = () => {
    if (contract && contract.maturityDate) {
      return moment(contract.maturityDate)
    }
    return null
  }

  return (
    <>
      <Card>
        <CardContent>
          <Typography gutterBottom variant="h4">
            Relocation Request Details
          </Typography>
          <div className={classes.fieldGroup}>
            <Grid container spacing={1}>
              <Grid item sm={6} xs={12}>
                {contract && hasRoles(['superadmin'], userRoles) ? (
                  <SelectableValueText
                    large
                    label="Status"
                    toggleEdit={isEditingKey !== 'status'}
                    onEdit={onEdit('status')}
                    noNone
                    options={statusOptions}
                    outline={getStatusColor(values.status)}
                    value={values.status}
                    onCancel={onCancelChange('status')}
                    onChange={onEditableChange('status')}
                    onSave={onSaveChanges('status')} />
                ) : (
                  <ValueText
                    large
                    label="Status"
                    outline={getStatusColor(values.status)}
                    value={booking.status} />
                )}
              </Grid>
              <Grid item sm={6} xs={12}>
                {editable ? (
                  <ValueText
                    large
                    toggleEdit={isEditingKey !== 'effectiveDate'}
                    onEdit={onEdit('effectiveDate')}
                    label="Effective Date"
                    value={values.effectiveDate}
                    format="Do MMMM YYYY"
                    minDateMessage="Effective date cannot be before today"
                    minDate={moment(values.effectiveDate) < moment() ? moment(values.effectiveDate) : moment()}
                    maxDate={getMaxEffectiveDate()}
                    onCancel={onCancelChange('effectiveDate')}
                    onDateChange={onEditableChange('effectiveDate')}
                    onSave={onSaveChanges('effectiveDate')} />
                ) : (
                  <ValueText
                    large
                    label="Effective Date"
                    format="Do MMMM YYYY"
                    value={values.effectiveDate} />
                )}
              </Grid>
              <Grid item sm={6} xs={12}>
                {editable ? (
                  <ValueText
                    large
                    toggleEdit={isEditingKey !== 'pickup'}
                    onEdit={onEdit('pickup')}
                    label="Pickup Address"
                    value={values.pickup}
                    onSave={onSaveChanges('pickup')}
                    onCancel={onCancelChange('pickup')}
                    onInputChange={onInputChange('pickup')} />
                ) : (
                  <ValueText
                    large
                    label="Pickup Address"
                    value={values.pickup} />
                )}
              </Grid>
              <Grid item sm={6} xs={12}>
                {editable ? (
                  <ValueText
                    large
                    toggleEdit={isEditingKey !== 'destination'}
                    onEdit={onEdit('destination')}
                    label="Destination Address"
                    value={values.destination}
                    onSave={onSaveChanges('destination')}
                    onCancel={onCancelChange('destination')}
                    onInputChange={onInputChange('destination')} />
                ) : (
                  <ValueText
                    large
                    label="Destination Address"
                    value={values.destination} />
                )}
              </Grid>
              <Grid item sm={6} xs={12}>
                {editable ? (
                  <ValueText
                    large
                    label="Contact Person"
                    toggleEdit={isEditingKey !== 'driverName'}
                    onEdit={onEdit('driverName')}
                    value={values.driverName}
                    onSave={onSaveChanges('driverName')}
                    onCancel={onCancelChange('driverName')}
                    onInputChange={onInputChange('driverName')} />
                ) : (
                  <ValueText
                    large
                    label="Contact Person"
                    value={values.driverName} />
                )}
              </Grid>
              <Grid item sm={6} xs={12}>
                {editable ? (
                  <ValueText
                    large
                    numbersOnly
                    label="Mobile"
                    toggleEdit={isEditingKey !== 'driverMobile'}
                    onEdit={onEdit('driverMobile')}
                    value={values.driverMobile}
                    onSave={onSaveChanges('driverMobile')}
                    onCancel={onCancelChange('driverMobile')}
                    onInputChange={onInputChange('driverMobile')} />
                ) : (
                  <ValueText
                    large
                    label="Mobile"
                    value={values.driverMobile} />
                )}
              </Grid>

              {tfp1838FeatureToggle &&
              <Grid item sm={6} xs={12}>
                {editable ? (
                  <ValueText
                    large
                    label="Contact Email"
                    toggleEdit={isEditingKey !== 'contactEmail'}
                    onEdit={onEdit('contactEmail')}
                    value={values.contactEmail}
                    onSave={onSaveChanges('contactEmail')}
                    onCancel={onCancelChange('contactEmail')}
                    onInputChange={onInputChange('contactEmail')} />
                ) : (
                  <ValueText
                    large
                    label="Contact Email"
                    value={values.contactEmail} />
                )}
              </Grid>
              }
              <Grid item sm={6} xs={12}>
                {editable ? (
                  <ValueText
                    large
                    label="Cost Centre"
                    toggleEdit={isEditingKey !== 'costCentre'}
                    onEdit={onEdit('costCentre')}
                    value={values.costCentre}
                    onSave={onSaveChanges('costCentre')}
                    onCancel={onCancelChange('costCentre')}
                    onInputChange={onInputChange('costCentre')} />
                ) : (
                  <ValueText
                    large
                    label="Cost Centre"
                    value={values.costCentre} />
                )}
              </Grid>
              <Grid item sm={6} xs={12}>
                {editable && hasRoles(['superadmin'], userRoles) ? (
                  <ValueText
                    large
                    label="Reference ID"
                    value={values.referenceId}
                    toggleEdit={isEditingKey !== 'referenceId'}
                    onEdit={onEdit('referenceId')}
                    onSave={onSaveChanges('referenceId')}
                    onCancel={onCancelChange('referenceId')}
                    onInputChange={onInputChange('referenceId')} />
                ) : (
                  <ValueText
                    large
                    label="Reference ID"
                    value={values.referenceId} />
                )}
              </Grid>
            </Grid>
          </div>

          <div className={classes.fieldHeader}>
            <Typography gutterBottom variant="h4">
              Vehicle
            </Typography>
          </div>
          {contract ? (
            <div className={classes.fieldGroup}>
              <Grid container spacing={1}>
                <Grid item sm={6} xs={12}>
                  <ValueText
                    large
                    label="Driver"
                    value={contract.driver}/>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <ValueText
                    large
                    label="Location"
                    value={contract.location} />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <ValueText
                    large
                    label="Registration"
                    value={contract.registration} />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <ValueText
                    large
                    label="Cost Centre"
                    value={contract.costCentre} />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <ValueText
                    large
                    label="Make"
                    value={contract.make} />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <ValueText
                    large
                    label="Model"
                    value={contract.model} />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <ValueText
                    large
                    label="Year"
                    value={contract.year} />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <ValueText
                    large
                    label="Colour"
                    value={contract.colour} />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <ValueText
                    large
                    label="Accessories"
                    value={contract.accessories} />
                </Grid>
              </Grid>
            </div>
          ) : (
            <Typography gutterBottom variant="body1">
              Contract not found for {booking.vehicleRegistration}.
            </Typography>
          )}
        </CardContent>

        <StyledModal
          open={openDeleteModal}
          title="Cancel Vehicle Relocation Request"
          onCancel={() => setOpenDeleteModal(false)}
          onConfirm={onDeleteHandler}>
            Please confirm that you wish to cancel this vehicle relocation request.
        </StyledModal>
      </Card>
      <div className={classes.actions}>
        {hasRoles(['superadmin', 'fleetcontroller'], userRoles) && (editable || !contract) && (
          <Button
            color="primary"
            variant="contained"
            className={classes.button}
            onClick={() => setOpenDeleteModal(true)}>
            Cancel Request
          </Button>
        )}
        <Button
          className={classes.button}
          variant="contained"
          onClick={onClose}>
          Close
        </Button>
      </div>
    </>
  )
}

Form.propTypes = {
  data: PropTypes.object.isRequired,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

export default Form
