import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { Button, Card, CardContent, Grid, Typography, FormControlLabel, Checkbox, TextField, IconButton } from '@material-ui/core'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import { useEnabledFeatureToggleList } from 'features/settings'
import { useIsWhiteListed } from 'utils'
// import FleetCard from './fleet-card'


const useStyles = makeStyles(theme => ({
  content: {
    padding: theme.spacing(3, 1.2, 3, 1.2),
    [theme.breakpoints.up('sm')]: { padding: theme.spacing(3) },
    [theme.breakpoints.down('xs')]: { marginLeft: theme.spacing(1) },
  },
  fieldGroup: { marginTop: theme.spacing(3) },
  fleets: {
    height: '50vh',
    maxHeight: '50vh',
    width: '100%',
    overflowY: 'scroll',
    overflowX: 'hidden',
    marginRight: 10,
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: { display: 'block' }
  },  
  button: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    minWidth: 180,
    [theme.breakpoints.down('xs')]: { minWidth: '100%' }
  },
  selectAllBtn: {
    minWidth: 180,
    [theme.breakpoints.down('xs')]: { minWidth: '100%' }
  },
  formControlLabel: { userSelect: 'none' },
  scrollButtons: { marginLeft: 'auto', marginRight: 0, marginTop: 10 },
  scrollButton: {
    backgroundColor: 'transparent',
    '&:hover': { backgroundColor: '#bdbdbd' }
  }
}))

const FleetSelectorForm = ({ fleets, values, onCancel, onSubmit }) => {
  
  const featureToggleList = useEnabledFeatureToggleList()
  const fbtFeatureToggle = featureToggleList.includes('FBT')
  const isWhiteListedUser = useIsWhiteListed()

  const classes = useStyles()

  const form = useRef()
  const topRef = useRef()
  const bottomRef = useRef()

  const [searchKey, setSearchKey] = useState('')
  const [filteredFleetItems, setFilteredFleetItems] = useState(fleets)

  const [fleetIds, setFleetIds] = useState(values)
  const [disabled, setDisabled] = useState(fleetIds.length > 0 ? false : true)

  const isSelected = fleetId => fleetIds.includes(fleetId)
  const isSelectedAll = filteredFleetItems.filter(node => !isSelected(node.value)).length === 0
  // const isSelectedAllFbt = filteredFleetItems.filter(node => node.isPremium).filter(node => !isSelected(node.value)).length === 0

  const [selectAll, setSelectAll] = useState(isSelectedAll || false)

  const onSelect = (fleetId) => () => {
    if (isSelected(fleetId)) {
      const next = fleetIds.filter(id => id !== fleetId)
      setFleetIds(next)
      return
    }
    setFleetIds([...fleetIds, fleetId])
  }

  const onSelectAll = () => {
    const allFleets = filteredFleetItems.map(node => node.value)
    setFleetIds(selectAll ? [] : allFleets)
    setSelectAll(!selectAll)
  }

  const onSelectAllFbtCustomers = () => {
    const allFbtFleets = filteredFleetItems.filter(node => node.isPremium).map(node => node.value)
    setFleetIds(allFbtFleets)
  }

  const onSubmitForm = () =>
    fleetIds.length > 0 && onSubmit(fleetIds)

  useEffect(() => {
    setDisabled(fleetIds.length < 1)
  }, [fleetIds, setDisabled])
  
  const applySearch = (keyword) => {
    let filteredList = fleets
    if (!keyword || keyword === '') {
      filteredList = fleets
      setSearchKey(null)
    } else {
      filteredList = fleets
        .map(item => item.isPremium ? { ...item, label: `${item.label} *`  } : item)
        .filter(item => JSON.stringify(item.label).toLowerCase().includes(keyword.toLowerCase()))
        .map(item => item.isPremium ? { ...item, label: `${item.label}`.replace(' *', '')  } : item)
    }
    setFilteredFleetItems(filteredList)
    setSearchKey(keyword)
  }

  const onSearchChange = ({ target: { value } }) => {
    setSearchKey(value)
    applySearch(value)
  }

  const onScroll = (ref) => () => ref.current && ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })

  return (
    <ValidatorForm autoComplete="off" noValidate ref={form} onSubmit={onSubmitForm}>
      <Card>
        <CardContent className={classes.content}>
          
          <Grid alignItems="flex-end" container justify="space-between" spacing={2}>
            <Grid item>
              <Typography gutterBottom variant="h4">
                Fleets
              </Typography>
              <Typography variant="body2">
              Please select the fleets you wish to view data for.
              </Typography>
            </Grid>
            <Grid item>
              {fbtFeatureToggle && isWhiteListedUser && (
                <Button
                  id={'select-all-fbt-fleets-btn'}
                  variant="contained"
                  className={classes.selectAllBtn}
                  style={{ marginRight: 10 }}
                  onClick={() => onSelectAllFbtCustomers()}>
                  {'Select All FBT Fleets'}
                </Button>
              )}
              <Button
                id={selectAll  ? 'unselect-btn' : 'select-btn'}
                variant="contained"
                className={classes.selectAllBtn}
                onClick={() => onSelectAll()}>
                {selectAll ? 'Unselect All' : 'Select All'}
              </Button>
            </Grid>
          </Grid>

          <Grid container spacing={2} className={classes.fieldGroup}>

            <Grid item xs={12}>
              <TextField
                id="fleet-search"
                fullWidth
                label="Fleet Search"
                value={searchKey}
                inputProps={{ maxLength: 50 }}
                variant="outlined"
                onChange={onSearchChange} />
            </Grid>

            <Grid item xs={12} className={classes.fleets}>
              <Grid container>
                {filteredFleetItems.map((node, index) => (
                  <Grid key={index} item sm={6} xs={12}>

                    {index === 0 && <div ref={topRef} />}

                    {/* <FleetCard
                      label={node.label}
                      value={node.value}
                      checked={isSelected(node.value)}
                      onChange={onSelect(node.value)}
                    /> */}
                    <FormControlLabel
                      className={classes.formControlLabel}
                      control={
                        <Checkbox
                          checked={isSelected(node.value)}
                          onChange={onSelect(node.value)}
                          value={node.value}
                          color="default" />
                      }
                      label={fbtFeatureToggle && isWhiteListedUser
                        ? `${node.label}${node?.isPremium ? ' *' : ''}`
                        : node.label
                      }
                    />

                    {index === filteredFleetItems.length - 1 && <div ref={bottomRef} />}
                    
                  </Grid>
                ))}
              </Grid>
            </Grid>

          </Grid>
          
        </CardContent>
      </Card>
      <div className={classes.actions}>
        <Button color="primary" disabled={disabled} type="submit" variant="contained" className={classes.button}>
          Select ({fleetIds.length})
        </Button>

        <Button className={classes.button} variant="contained" onClick={onCancel}>
          Cancel
        </Button>
      
        <div className={classes.scrollButtons}>
          <IconButton color="secondary" onClick={onScroll(topRef, 'top')} className={classes.scrollButton}>
            <ArrowUpwardIcon fontSize="small" />
          </IconButton>
          <IconButton color="secondary" onClick={onScroll(bottomRef, 'bottom')} className={classes.scrollButton}>
            <ArrowDownwardIcon fontSize="small" />
          </IconButton>
        </div>
        
      </div>
    </ValidatorForm>
  )
}

FleetSelectorForm.propTypes = {
  fleets: PropTypes.array.isRequired,
  values: PropTypes.array.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default FleetSelectorForm