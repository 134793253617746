import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { AntSwitch } from 'frame/components'
import { TableCell, TableRow } from '@material-ui/core'
import { types } from '../../module'
// import { colors } from '@material-ui/core'
import moment from 'moment'
import { useFleetId } from 'features/settings'
import { useDispatch, useSelector } from 'react-redux'
import uuid from 'uuid/v4'
import { useIsUserATfs } from 'utils'
import { getAppConfig } from 'app-config'


const useStyles = makeStyles(theme => ({
  root: {
    // cursor: 'pointer',
    verticalAlign: 'text-top',
  },
  label: theme.typography.bodySmall,
  spacer: {
    margin: 0,
    padding: 0,
  },
  list: {
    marginLeft: theme.spacing(-3),
  }
}))

const Row = ({ item, columns, onEnable, onDisable }) => {

  const classes = useStyles()
  const dispatch = useDispatch()
  const fleedId = useFleetId()
  const isTfsUser = useIsUserATfs()
  const config = getAppConfig()

  const activeFBTNotifyToggle = useSelector(state => state.featureToggles?.activeFBTNotifyToggle)

  const handleChange = (checked) => {
    if(item.feature === 'Non-TFM') {// refetch dashboard data on non-tfm toggle - remove once permenant 
      dispatch({ type: 'graphMaturing_request_data', options: { FleetId: fleedId, requestId: `${uuid()}` } }) // refetch maturing graph
      dispatch({ type: 'graphWofExpiry_request_data', options: { FleetId: fleedId, requestId: `${uuid()}` } }) // refetch wof graph
      dispatch({ type: 'graphServiceExpiry_request_data', options: { FleetId: fleedId, requestId: `${uuid()}` } }) // refetch service tracking graph
      dispatch({ type: 'graphKmTracking_request_data', options: { FleetId: fleedId, requestId: `${uuid()}` } }) // refetch km tracking graph
    }    
    checked && item.feature ? onEnable(item.feature) : onDisable(item.feature)
  }
  
  return (
    <TableRow hover className={classes.root}>
      <TableCell className={classes.spacer} />
      {columns.feature && (
        <TableCell className={classes.label}>
          {item.feature || '-'}
        </TableCell>
      )}
      {columns.description && (
        <TableCell className={classes.label}>
          {item.description && item.description.length > 0
            ? (
              <ul className={classes.list}>
                {item.description.map((desc, index) =>
                  <li key={`${item.feature}-description-${index}`}>
                    {desc}
                  </li>
                )}
              </ul>
            ) : '-'
          }
        </TableCell>
      )}

      {columns.reportsImpacted && (
        <TableCell className={classes.label}>
          {item.reportsImpacted && item.reportsImpacted.length > 0
            ? (
              <ul className={classes.list}>
                {item.reportsImpacted.map((report, index) =>
                  <li key={`${item.feature}-reportsImpacted-${index}`}>
                    {report}
                  </li>
                )}
              </ul>
            ) : '-'
          }
        </TableCell>
      )}

      {columns.lastToggledDate && (
        <TableCell className={classes.label}>
          {item.lastToggledDate ? moment(item.lastToggledDate).format('DD/MM/YY hh:mm A') : '-'}
        </TableCell>
      )}
      {columns.lastToggledBy && (
        <TableCell className={classes.label}>
          {item.lastToggledBy || '-'}
        </TableCell>
      )}

      {columns.featureToggle && (
        <TableCell className={classes.label}>
          {config.env === process.env.REACT_APP_PROD_ENV && !isTfsUser // if env is production, and user is not a tfs user, hide switches
          ? (item.active ? 'ON' : 'OFF')
          : (
            <AntSwitch
            value={(item.feature === 'FBTNotifyOpen' || item.feature === 'FBTNotifyRemind' || item.feature === 'FBTNotifyClosed')
              && activeFBTNotifyToggle && activeFBTNotifyToggle !== item.feature ? false : item.active}
            onChange={handleChange}
            name="featureSwitch"
          />
          )}
        </TableCell>
      )}
      <TableCell className={classes.spacer} />
    </TableRow>

  )
}

Row.propTypes = {
  item: PropTypes.oneOfType([
    PropTypes.object,
    types.ModelType,
  ]).isRequired,
  columns: types.ColumnsType.isRequired,
  onEnable: PropTypes.func.isRequired,
  onDisable: PropTypes.func.isRequired,
}

export default Row
