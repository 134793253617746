import { createGraph, pieGraphFormatter } from 'graph'


const graphModule = createGraph({
  name: 'graphKmTracking',
  api: 'dashboard/kmtracking',
  formatter: pieGraphFormatter,
})

export const actions = graphModule.actions
export const constants = graphModule.constants
export const effects = graphModule.effects
export const reducer = graphModule.reducer

export const useGraphFetch = graphModule.hooks.useGraphFetch
