import * as fromActions from './actions'
import * as R from 'ramda'

const initialState = {
  initialized: false,
  fleetsReceived: false,
  account: '',
  buildId: '',
  fleet: {
    ids: [],
    data: {},
    selected: [],
  },
  roles: [],
  flags: {
    fetchUserSelectedFleets: 'none',
    saveUserSelectedFleets: 'none',
  },
}

export const reducer = (state = initialState, action) => {

  switch (action.type) {
    case fromActions.INITALIZE:
      return initalize(clean(R.clone(state)))
    case fromActions.INITALIZED:
      return initalized(R.clone(state))
    
    case fromActions.RESET:
      return {
        ...state,
        flags: {
          fetchUserSelectedFleets: 'none',
          saveUserSelectedFleets: 'none',
        },
      }

    case fromActions.REQUEST_FLEETS_FULFILLED:
      return initalized(R.clone(state))
    case fromActions.REQUEST_FLEETS_ERROR:
      return initalizedNoFleets(R.clone(state))
    case fromActions.ADD_FLEETS: {
      const next = addFleets(R.clone(state), action)
      // console.log('case fromActions.ADD_FLEETS next', next)
      let result = selectDefaultFleet(next)
      // console.log('case fromActions.ADD_FLEETS result', result)
      return result
    }

    case fromActions.REMOVE_FLEETS:
      return removeFleets(R.clone(state), action)

    case fromActions.SELECT_FLEETS:
      return selectFleets(R.clone(state), action)

    case fromActions.SET_ACCOUNT_TYPE:
      return setAccountType(R.clone(state), action)

    case fromActions.SET_ROLES:
      return setRoles(R.clone(state), action)

    case fromActions.REQUEST_SELECTED_FLEETS_PREFERENCES:
      return updateFlags(R.clone(state), { fetchUserSelectedFleets: 'processing' })
    case fromActions.REQUEST_SELECTED_FLEETS_PREFERENCES_FULFILLED:
      return populateSelectedFleetsPreferences(state, action.payload)
    case fromActions.REQUEST_SELECTED_FLEETS_PREFERENCES_ERROR:
      return updateFlags(R.clone(state), { fetchUserSelectedFleets: 'error' })
    
    // on Save in Fleet Selector form
    case fromActions.SAVE_SELECTED_FLEETS_PREFERENCES:
      return updateFlags(R.clone(state), { saveUserSelectedFleets: 'processing', fetchUserSelectedFleets: 'none' })
    case fromActions.SAVE_SELECTED_FLEETS_PREFERENCES_FULFILLED:
      return updateFlags(R.clone(state), { saveUserSelectedFleets: 'processed' })
    case fromActions.SAVE_SELECTED_FLEETS_PREFERENCES_ERROR:
      return updateFlags(R.clone(state), { saveUserSelectedFleets: 'error' })

    case fromActions.SET_CURRENT_MODULE:
      return { ...state, currentModule: action.payload }
    case fromActions.RESET_FLAGS:
      return { ...state, flags: { fetchUserSelectedFleets: 'none', saveUserSelectedFleets: 'none' } }

    default:
      return state
  }
}

const updateFlags = (state, flags) => ({
  ...state,
  flags: {
    ...state.flags,
    ...flags,
  }
})

const clean = (state) => ({
  ...state,
  fleet: {
    ids: [],
    data: {},
    selected: [],
  },
})

const initalize = (state, action) => ({
  ...state,
  initialized: false,
  fleetsReceived: false,
})

const initalized = (state, action) => {

  const ignoredKeys = ['selected_fleet_ids', '@tfm/release']

  // clean stale persisted states with older build ids
  const localStorageKeys = Object.keys(localStorage);
  // console.log('[localStorageKeys]: ', localStorageKeys)
  localStorageKeys && localStorageKeys.forEach(key => {
    if(!key.includes(process.env.REACT_APP_BUILD_RELEASE) && !ignoredKeys.includes(key)) {
      console.log('[deleted stale state]: ', key)
       localStorage.removeItem(key);
    }
  });
  
  return {
    ...state,
    buildId: process.env.REACT_APP_BUILD_RELEASE,
    initialized: true,
    fleetsReceived: true,
  }
}

const initalizedNoFleets = (state, action) => ({
  ...state,
  initialized: true,
  fleetsReceived: false,
})

const selectDefaultFleet = (state, action) => {

  if (state.fleet.selected.length) {

    const matched = state.fleet.selected
      .some(id => state.fleet.ids.includes(id))

    if (matched) {
      return state
    }
  }

  if (state.fleet.ids.length) {

    const fleetId = state.fleet.ids[0]

    return {
      ...state,
      fleet: {
        ...state.fleet,
        selected: [fleetId],
      }
    }
  }

  return {
    ...state,
    fleet: {
      ...state.fleet,
      selected: [],
    },
  }
}

const selectFleets = (state, action) => {

  const idsToBeAdded = Array.isArray(action.payload)
    ? action.payload
    : [action.payload]

  const selected = Array.from(new Set(idsToBeAdded))

  return {
    ...state,
    fleet: {
      ...state.fleet,
      selected,
    },
  }
}

const addFleets = (state, action) => {

  const itemsToBeAdded = Array.isArray(action.payload.data)
    ? action.payload.data
    : [action.payload.data]

  const idsToBeAdded = itemsToBeAdded
    .map(({ value }) => value)
    .filter(x => x)

  const data = {}
  Object.keys(state.fleet.data)
    .forEach(key => {
      const node = state.fleet.data[key]
      if (node && node.id) {
        data[key] = node
      }
    })

  // const data = { ...state.fleet.data }

  itemsToBeAdded.forEach((node) => {
    data[node.value] = {
      id: node.value,
      name: node.text,
      isPremium: node.isPremium,
    }
  })

  const nextIds = [
    ...state.fleet.ids,
    ...idsToBeAdded,
  ]

  // console.log('reducer addFleets action', action)
  // console.log('reducer addFleets action.selected', action.selected)

  return {
    ...state,
    fleet: {
      ...state.fleet,
      data,
      ids: Array.from(new Set(nextIds)),
    }
  }
}

const removeFleets = (state, action) => {

  const idsToBeRemoved = Array.isArray(action.payload)
    ? action.payload
    : [action.payload]

  const data = {}

  const nextIds = state.ids
    .filter(id => ! idsToBeRemoved.includes(id))

  Object.keys(state.data).forEach(id => {
    if ( ! idsToBeRemoved.includes(id)) {
      data[id] = state.fleet.data[id]
    }
  })

  return {
    ...state,
    fleet: {
      ...state.fleet,
      data,
      ids: Array.from(new Set(nextIds)),
    },
  }
}

const setAccountType = (state, action) => {

  let account = 'standard'

  // for (const fleet of action.payload) {
  //   if (fleet.isPremium) {
  //     account = 'premium'
  //     break
  //   }
  // }

  return {
    ...state,
    account,
  }
}

const setRoles = (state, { payload }) => {

  let roles = []

  Object.keys(payload).forEach(key => {
    if (key.includes('roles')) {
      roles = payload[key].map(role => String(role).toLowerCase())
    }
  })

  return {
    ...state,
    roles,
  }
}

const populateSelectedFleetsPreferences = (state, fleetIds) => {
  // console.log('[populateSelectedFleetsPreferences fleetIds]: ', fleetIds)
  return {
    ...state,
    fleet: {
      ...state.fleet,
      selected: fleetIds
    },
    flags: {
      ...state.flags,
      fetchUserSelectedFleets: 'processed',
    }
  }
}

export default reducer
