import { colors as themeColors } from '@material-ui/core'
import theme from 'theme/palette'


export const pieGraphFormatter = (state, { payload }) => {

  if ( ! payload) {
    return state
  }

  // console.log('[transform graph (data)]', data)

  const getLabelDisplayName = (label) => {
    const possibleLabels = {
      under: 'Under',
      onTrack: 'On Track',
      over: 'Over',
      noData: 'No Data Available',
    }
    return possibleLabels[label] || possibleLabels.noData
  }

  const getLabelColor = (label) => {
    const possibleLabels = {
      under: themeColors.orange[600],
      onTrack: themeColors.green[600],
      over: themeColors.red[600],
      noData: theme.light,
    }
    return possibleLabels[label] || possibleLabels.noData
  }

  const colors = []
  const labels = []
  const values = []

  Object.keys(payload)
    .forEach(key => {
      if (key !== 'total') {
        const value = payload[key]
        colors.push(getLabelColor(key))
        labels.push(getLabelDisplayName(key))
        values.push(value)
      }
    })

  return {
    ...state,
    data: {
      colors,
      labels,
      values,
    },
  }
}
