import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { IconButton } from '@material-ui/core'

import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'

const useStyles = makeStyles(theme => ({
  root: {},
  scrollToButton: {
    position: 'fixed',
    zIndex: 99,
    right: 50,
    color: '#263238',
    cursor: 'pointer',
    [theme.breakpoints.down('lg')]: {
      right: 100,
    },
    [theme.breakpoints.down('xs')]: {
      right: 50,
    },
  },
  scrollToTopPadding: {
    bottom: 120,
  },
  scrollToBottomPadding: {
    bottom: 50,
  },
}))

const ScrollToButton = ({ scrollToRef, scrollDirection = 'down' }) => {

  const classes = useStyles({ scrollDirection })

  const scrollToBottom = () => {
    if (scrollToRef.current) {
      scrollToRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
    }
  }

  const bottomPadding = scrollDirection === 'up' ? classes.scrollToTopPadding : classes.scrollToBottomPadding

  return (
    <>
      <IconButton
        aria-label="scroll-to-bottom-btn"
        className={clsx(classes.scrollToButton, bottomPadding) }
        color="secondary"
        onClick={scrollToBottom}
      >
        {scrollDirection === 'up' ? (
          <ArrowUpwardIcon fontSize="large" />
        ): (
          <ArrowDownwardIcon fontSize="large" />
        )}
      </IconButton>
    </>
  )
}

ScrollToButton.propTypes = {
  scrollToRef: PropTypes.object.isRequired,
  scrollDirection: PropTypes.string
}

export default ScrollToButton
