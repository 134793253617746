import React, { useEffect, useState } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { Button, Card, CardContent, Grid, Typography } from '@material-ui/core'
import { Alert, CustomDatePicker, LookupField, ValueText, RegistrationLookupWrapper } from 'frame/components'
import { createDefaultModel } from '../../module'
import { useEnabledFeatureToggleList } from 'features/settings'
import { hasRoles } from 'acl'


const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3),
  },
  error: {
    marginBottom: theme.spacing(4),
  },
  content: {
    padding: theme.spacing(3, 1.2, 3, 1.2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
  },
  fieldHeader: {
    marginTop: theme.spacing(5),
  },
  fieldGroup: {
    margin: theme.spacing(3, 0, 2, 0),
  },
  actions: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    }
  },
  button: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    minWidth: 180,
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
    }
  },
  info: {
    ...theme.typography.body2,
  },
  disabled: {
    color: theme.palette.text.tertiary,
  },
}))

const Form = ({
  relatedContract,
  disabled,
  error,
  fetchContractStatus,
  fleetIds,
  onClose,
  onFetchContract,
  onSubmit,
  onLookup,
  errorMessage,
  userRoles
}) => {

  const classes = useStyles()
  const [contract, setContract] = useState({})
  const [vehicle, setVehicle] = useState()
  const [values, setValues] = useState(createDefaultModel())
  const featureToggleList = useEnabledFeatureToggleList()
  const userPermissionsFeatureToggle = featureToggleList.includes('UserPermissions')
  const tfp1838ContactEmailFeatureToggle = featureToggleList.includes('TFP1838')
  const disableButtons = userPermissionsFeatureToggle && hasRoles(['fleetcoordinator'], userRoles)
  const lookupContractData = relatedContract?.[vehicle?.agreementNo] || null
  const locked = disabled || ! contract?.agreementNo ? true : false


  const hasContract = !!contract?.agreementNo

  const onRegoChange = (option) => {
    setContract({})
    setVehicle(option)
    if (option?.agreementNo) {
      onFetchContract(option.agreementNo, fleetIds)
    }
  }

  const onInputChange = ({ target: { id, name, value } }) =>
    setValues({ ...values, [id || name]: value })

  const onSelectChange = (id) => (value) =>
    setValues({ ...values, [id]: value })

  const onSubmitForm = () =>
    onSubmit(values)

  // Similar to getDerivedStateFromProps
  // We need to update local state here once we receive
  // the corresponding contract.
  // So we set the contract and add appropriate contract values to the
  // relocation request as is needed.
  useEffect(() => {
    if (lookupContractData?.agreementNo) {
      setContract(lookupContractData)
      setValues({
        ...values,
        costCentre: lookupContractData.costCentre || '',
        agreementNo: lookupContractData.agreementNo || '',
        thirdPartyNumber: lookupContractData.thirdPartyNumber || '',
        vehicleRegistration: lookupContractData.registration || '',
        effectiveDate: moment(lookupContractData.maturityDate) <= moment() ? null : moment().toISOString(),
      })
    }
  }, [lookupContractData]) // eslint-disable-line

  const getMaxEffectiveDate = () => {
    if (hasContract && contract.maturityDate) {
      return moment(contract.maturityDate)
    }
    return null
  }

  const expiredMaturityDate = moment(contract.maturityDate) <= moment()

  return (
    <ValidatorForm
      autoComplete="off"
      noValidate
      onSubmit={onSubmitForm}>
      <Card>
        <CardContent className={classes.content}>
          {error && (
            <Alert className={classes.error}>
              {errorMessage || 'Something went wrong while trying to request this vehicle relocation.' }
            </Alert>
          )}
          <Typography gutterBottom variant="h4">
            Vehicle
          </Typography>
          <div className={classes.info}>
            Please select the vehicle you wish to relocate.
          </div>
          <div className={classes.fieldGroup}>
            <RegistrationLookupWrapper
              lookupContractData={contract}
              fetchContractStatus={fetchContractStatus}>
              <LookupField
                id="registration"
                disabled={disabled || disableButtons}
                fullWidth
                label="Vehicle Registration"
                lookupPath="vehicleregistration"
                value={vehicle}
                variant="outlined"
                validators={['required']}
                errorMessages={['This field is required']}
                onChange={onRegoChange}
                resourceModel="requestVehicleRelocations"
                onLookup={onLookup}
              />
            </RegistrationLookupWrapper>
          </div>

          {hasContract && (
            <div className={classes.fieldGroup}>
              <Grid container spacing={1}>
                <Grid item sm={6} xs={12}>
                  <ValueText
                    large
                    label="Driver"
                    value={contract.driver || '-'} />
                </Grid>
              <Grid item sm={6} xs={12}>
                  <ValueText
                    large
                    label="Location"
                    value={contract.location || '-'} />
                </Grid>
              <Grid item sm={6} xs={12}>
                  <ValueText
                    large
                    label="Cost Centre"
                    value={contract.costCentre || '-'} />
                </Grid>
              <Grid item sm={6} xs={12}>
                  <ValueText
                    large
                    label="Maturity Date"
                    format="Do MMMM YYYY"
                    value={contract.maturityDate || '-'} />
                </Grid>
              </Grid>
            </div>
          )}

          <div className={classes.fieldHeader}>
            <Typography
              className={clsx(locked ? classes.disabled : '')}
              gutterBottom
              variant="h4">
              Relocation details
            </Typography>
            <Typography
              className={clsx(locked ? classes.disabled : '')}
              variant="body2">
              Please complete all required fields.
            </Typography>
          </div>
          <div className={classes.fieldGroup}>
            <Grid container spacing={4}>

              <Grid item sm={6} xs={12}>
                <TextValidator
                  id="pickup"
                  className={classes.field}
                  disabled={locked || expiredMaturityDate}
                  fullWidth
                  label="Pickup Address"
                  value={values.pickup || ''}
                  inputProps={{ maxLength: 50 }}
                  variant="outlined"
                  validators={['required']}
                  errorMessages={['This field is required']}
                  onChange={onInputChange} />
              </Grid>

              <Grid item sm={6} xs={12}>
                <TextValidator
                  id="destination"
                  className={classes.field}
                  disabled={locked || expiredMaturityDate}
                  fullWidth
                  inputProps={{ maxLength: 50 }}
                  label="Destination Address"
                  value={values.destination || ''}
                  variant="outlined"
                  validators={['required']}
                  errorMessages={['This field is required']}
                  onChange={onInputChange} />
              </Grid>

              <Grid item sm={6} xs={12}>
                <TextValidator
                  id="driverName"
                  className={classes.field}
                  disabled={locked || expiredMaturityDate}
                  fullWidth
                  inputProps={{ maxLength: 50 }}
                  label="Contact Person"
                  value={values.driverName || ''}
                  variant="outlined"
                  validators={['required']}
                  errorMessages={['This field is required']}
                  onChange={onInputChange} />
              </Grid>

              <Grid item sm={6} xs={12}>
                <TextValidator
                  id="driverMobile"
                  className={classes.field}
                  disabled={locked || expiredMaturityDate}
                  fullWidth
                  inputProps={{ maxLength: 50 }}
                  label="Mobile"
                  value={values.driverMobile || ''}
                  variant="outlined"
                  validators={['required', 'isNumber']}
                  errorMessages={['This field is required', 'Mobile number must be in numbers']}
                  onChange={onInputChange} />
              </Grid>

              {tfp1838ContactEmailFeatureToggle &&
              <Grid item sm={6} xs={12}>
                <TextValidator
                  id="contactEmail"
                  className={classes.field}
                  disabled={locked || expiredMaturityDate}
                  fullWidth
                  inputProps={{ maxLength: 50 }}
                  label="Contact Email"
                  value={values.contactEmail || ''}
                  variant="outlined"
                  validators={['required', 'isEmail']}
                  errorMessages={['This field is required', 'Contact email is invalid']}
                  onChange={onInputChange} />
              </Grid>
              }

              <Grid item sm={6} xs={12}>
                <TextValidator
                  id="costCentre"
                  className={classes.field}
                  disabled={locked || expiredMaturityDate}
                  fullWidth
                  inputProps={{ maxLength: 50 }}
                  label="Cost Centre"
                  value={values.costCentre || ''}
                  variant="outlined"
                  validators={['required']}
                  errorMessages={['This field is required']}
                  onChange={onInputChange} />
              </Grid>

              <Grid item sm={6} xs={12}>
                <CustomDatePicker
                  fullWidth
                  disabled={locked || expiredMaturityDate}
                  id="effectiveDate"
                  variant="outlined"
                  format="Do MMMM YYYY"
                  label="Effective Date"
                  value={values.effectiveDate}
                  onChange={onSelectChange('effectiveDate')}
                  maxDate={getMaxEffectiveDate()}
                  textFieldClassName={classes.field}
                />
              </Grid>

            </Grid>
          </div>

        </CardContent>
      </Card>

      <div className={classes.actions}>
        <Button
          className={classes.button}
          color="primary"
          disabled={locked || expiredMaturityDate}
          type="submit"
          variant="contained">
          Request Vehicle Relocation
        </Button>
        <Button className={classes.button} variant="contained" onClick={onClose}>
          Cancel
        </Button>
      </div>
    </ValidatorForm>
  )
}

Form.propTypes = {
  contracts: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  fetchContractStatus: PropTypes.string,
  fleetIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClose: PropTypes.func.isRequired,
  onFetchContract: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onLookup: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default Form
