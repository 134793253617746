import PropTypes from 'prop-types'


export const model = [{
  key: 'email',
  label: 'Email',
  column: {
    visible: true,
    width: 200,
  },
  prop: {
    type: PropTypes.email,
    value: '',
  }
}, {
  key: 'nickname',
  label: 'Name',
  column: {
    visible: true,
    width: 200,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'role',
  label: 'Role',
  column: {
    visible: true,
    width: 200,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'fleets',
  label: 'Profile',
  column: {
    visible: true,
    width: 200,
  },
  prop: {
    type: PropTypes.array,
  }
}, {
  key: 'blocked',
  label: 'Status',
  column: {
    visible: true,
    width: 200,
  },
  prop: {
    type: PropTypes.string,
  }
}, {
  key: 'invitationVerified',
  label: 'Invitation',
  column: {
    visible: true,
    width: 200,
  },
  prop: {
    type: PropTypes.string,
  }
}, 
// {
//   key: 'lastLogin',
//   label: 'Last Login',
//   column: {
//     visible: true,
//     width: 200,
//   },
//   prop: {
//     type: PropTypes.string,
//   }
// }
]

const config = {
  title: 'User Invitations',
  breadcrumbs: null,
  backgroundImage: 'url("/static/images/backgrounds/bg4.png")',
  disclaimerMessage: {
    title: 'Invited Users',
    message: "Please note, only users who have been 'invited' and are yet to complete the 'registration' are listed here. If you are looking for 'registered users' who already completed the registration/sign up process, please see 'Users'.",
  },

  // No records message defaults to 'No records found' when null or not specified
  noRecordsMessage: 'No records found. Please select different fleet(s) in your profile or contact your account manager to review access', 
  
  idKey: 'id',
  api: {
    filter: '/filteroptions/listinvitations',
    resource: `/usermanagement/listinvitations`,
  },
  model,
  headers: [
    'nickname', 'email', 'role', 'fleets', 'blocked', 'invitationVerified', //'lastLogin'
  ],
  filters: [{
    key: 'role',
    label: 'Role',
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }, {
    key: 'fleets',
    label: 'Fleet',
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }, {
    key: 'status',
    label: 'Status',
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }, {
    key: 'verifyStatus',
    label: 'Invitation',
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }],
  noDownload: true,
}


export default config
