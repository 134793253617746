import PeopleIcon from '@material-ui/icons/People'
import InviteIcon from '@material-ui/icons/MailOutline'
import EmailIcon from '@material-ui/icons/Email'
import SettingsIcon from '@material-ui/icons/Settings'

import { constants as fromUsers } from 'features/admin-users'
import { constants as fromInvitations } from 'features/admin-invitations'
import { constants as fromRegistrationOfInterests } from 'features/admin-registration-of-interest'
import { constants as fromFeatureToggles } from 'features/feature-toggle'
import { constants as fromMyDriveAppFeatureToggleSettings } from 'features/feature-toggle-settings'

export const createNavigation = (myDriveAppFeatureToggleEnabled = false) => {

  const navigations = [
    {
      title: 'Admin',
      pages: [{
        title: 'Users',
        href: fromUsers.PATH_ALL,
        icon: PeopleIcon,
        enabled: true
      },{
        title: 'User Invitations',
        href: fromInvitations.PATH_ALL,
        icon: InviteIcon,
        enabled: true
      }, {
        title: 'Registration Of Interests',
        href: fromRegistrationOfInterests.PATH_ALL,
        icon: EmailIcon,
        enabled: true
      }, {
        title: 'Feature Toggles',
        href: fromFeatureToggles.PATH_ALL,
        icon: SettingsIcon,
        enabled: true
      },
      {
        title: 'Settings - MyDrive',
        href: fromMyDriveAppFeatureToggleSettings.PATH_ALL,
        icon: SettingsIcon,
        enabled: myDriveAppFeatureToggleEnabled
      }].filter(x => x.enabled)
    },
  ]
  return (navigations)
}
