import React, { useState, useRef, Fragment } from 'react'
import { makeStyles } from '@material-ui/styles'
import { colors, Button, Tooltip, IconButton, Menu, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import FilterListIcon from '@material-ui/icons/FilterList'
import ColumnIcon from '@material-ui/icons/ViewWeek'
import GetAppIcon from '@material-ui/icons/GetApp'
import CachedIcon from '@material-ui/icons/Cached'


const useStyles = makeStyles(theme => ({
  root: { transform: `translateY(3px)` },
  tooltip: { margin: theme.spacing(0, 0.4, 0, 0.8) },
  icon: { color: theme.palette.dark },
  active: {
    color: theme.palette.dark,
    background: colors.grey[200],
    '&:hover': {
      color: theme.palette.white,
      background: theme.palette.dark,
    },
  },
  button: {
    marginRight: theme.spacing(1.4),
    padding: theme.spacing(0.4, 1.5),
    fontSize: 12,
    color: theme.palette.dark,
    background: colors.grey[100],
    transform: `translateY(1px)`,
  },
  downloadMenu: { position: 'relative' },
  downloadButton: { marginRight: '5px' },
  downloadIcon: { color: '#4D4F53' },
  refreshIcon: {
    float: 'left',
    marginRight: theme.spacing(1),
    overflow: 'hidden',
    transition: 'all 0.3s ease-out',
    transform: props => props.rotate ? 'rotate(540deg)' : 'rotate(-180deg)',
  }
}))

const Options = ({
  columns,
  filtering,
  hideFilterLabel,
  // func
  onClearFilters,
  onShowFilters,
  onRefresh,
  onDownload,
  onToggleShowColumns,
  ...props
}) => {

  const downloadMenuRef = useRef(null)
  const [open, setOpen] = useState(false)
  const [rotateIcon, setRotateIcon] = useState(false)
  const classes = useStyles({ rotate: rotateIcon })

  const toggleDownloadMenu = () => setOpen(!open)
  const onDownloadHandler = (selected) => () => {
    onDownload(selected)
    setOpen(false)
  }

  const noneSelected = Object.entries(columns)
    .filter(([key, value]) => value).length === 0
  
  const onRefreshHandler = () => {    
    setRotateIcon(!rotateIcon)
    onRefresh && onRefresh()
  }

  return (
    <div className={clsx(classes.root)}>
      {onClearFilters && filtering && !hideFilterLabel && (
        <Button
          className={classes.button}
          onClick={onClearFilters}>
          Clear Filters
        </Button>
      )}

      {onRefresh && (
        <Tooltip className={classes.icon} title="Refresh">
          <IconButton className={classes.refreshIcon} size="small" onClick={onRefreshHandler}>
            <CachedIcon />
          </IconButton>
        </Tooltip>
      )}
      
      {onDownload && (
        <Fragment>
          <Tooltip title="Download">
            <IconButton
              {...props}
              className={classes.downloadButton}
              size="small"
              ref={downloadMenuRef}
              onClick={toggleDownloadMenu}>
              <GetAppIcon className={classes.downloadIcon} />
            </IconButton>
          </Tooltip>
          <Menu
            id="download-select"
            anchorEl={downloadMenuRef.current}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            classes={{ paper: classes.menu }}
            onClose={toggleDownloadMenu}
            open={open}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
            <MenuItem onClick={onDownloadHandler(false)}>
              <ListItemIcon>
                <GetAppIcon className={classes.downloadIcon} />
              </ListItemIcon>
              <ListItemText primary="Download All" />
            </MenuItem>
            {!noneSelected && <MenuItem onClick={onDownloadHandler(true)}>
              <ListItemIcon>
                <GetAppIcon className={classes.downloadIcon} />
              </ListItemIcon>
              <ListItemText primary="Download Filtered" />
            </MenuItem>}
          </Menu>
        </Fragment>
      )}

      {onShowFilters && (
        <Tooltip
          className={clsx(classes.icon, filtering ? classes.active : '')}
          title="Filters">
          <IconButton
            {...props}
            size="small"
            onClick={onShowFilters}>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}

      {onToggleShowColumns && (
        <Tooltip
          className={clsx(classes.icon, classes.tooltip)}
          title="Columns">
          <IconButton
            {...props}
            size="small"
            onClick={onToggleShowColumns}>
            <ColumnIcon className={classes.icon} />
          </IconButton>
        </Tooltip>
      )}
    </div>
  )
}

Options.propTypes = {
  columns: PropTypes.object.isRequired,
  filtering: PropTypes.bool,
  hideFilterLabel: PropTypes.bool,
  // func
  onClearFilters: PropTypes.func,
  onShowFilters: PropTypes.func,
  onRefresh: PropTypes.func,
  onDownload: PropTypes.func,
  onToggleShowColumns: PropTypes.func,
}

export default Options
