import React, { useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Listable } from 'listable'
import Row from './row'
import Filters from '../filters'

import { actions, config } from '../../module'
import { isOutOfSync } from '../../utils'


const List = ({ model }) => {

  const renderItem = useCallback((item, index, itemProps) => {
    return (
      <Row
        key={index}
        item={item}
        {...itemProps}/>
    )
  }, [])

  // Prevents re-rendering if ids or data updated - no longer needed
  const { ids, filters, ...rest } = model

  useEffect(() => {
    try {
      const outOfSyncDataLog = Object.entries(rest.data)
        .map(([key, value]) => ({
          woFStatusInSync: isOutOfSync(value),
          rego: value.registration,
          wofExpiry: value.wofExpiry,
          wofStatus: value.wofStatus,
        }))
        .filter(item => !item.woFStatusInSync)
      
      // log any out of sync wof status for internal testing purposes
      outOfSyncDataLog.length > 0 && console.log('wof status logs: ', outOfSyncDataLog)
    }
    catch (error) {
      console.log('[outOfSyncDataLog error]: ', error)
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Listable
      actions={actions}
      config={config}
      filters={Filters}
      model={rest}
      renderItem={renderItem}
    />
  )
}

List.propTypes = {
  model: PropTypes.object.isRequired,
}

const mapStateToProps = ({ reportWofRego }) => ({
  model: reportWofRego,
})

const mapDispatchToProps = {}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(List)
