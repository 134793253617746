import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid, Typography, Card, CardContent, Button } from '@material-ui/core'


const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    minHeight: '317px',
    position: 'relative',
    [theme.breakpoints.down('md')]: { minHeight: '360px' },
    backgroundImage: props => props.backgroundImage ? `url("${props?.backgroundImage}")` : 'url("/static/images/marketing/fuelpromo.png")',
    backgroundSize: 'cover',
    backgroundPosition: 'center top',
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#002849',
  },
  card: {
    maxWidth: '50%',
    position: 'absolute',
    left: 20,
    bottom: 20,
    right: 20,
    [theme.breakpoints.down(1500)]: { maxWidth: '60%' },
  },
  content: { padding: theme.spacing(3, 2, 2, 2) },
  heading: { marginBottom: theme.spacing(1), fontWeight: 'bold' },
  promoContent: { fontSize: '14px', lineHeight: '19px' },
  actions: {
    display: 'block',
    marginTop: theme.spacing(1),
  },
  button: {
    minWidth: 180,
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('xs')]: { minWidth: '100%' }
  },
}))

const FuelCardPromo = () => {

  const promoConfig = {
    title: 'Apply for Z Business now',
    content: () => <>Simply enter your details and obtain the correct discount with the TFS promotional code: <strong>TOYOTA</strong></>,
    backgroundImage: '',
  }

  const classes = useStyles({ backgroundImage: promoConfig?.backgroundImage })

  const onApply = () => window.open("https://www.toyota.co.nz/toyota-finance-leasing/fuel-card/", "_blank")
  
  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container spacing={3}>

          <Grid item xs={12} sm={12} md={6}>
            <Card className={classes.card}>
              <CardContent className={classes.content}>
                <Typography variant="h5" className={classes.heading}>
                  {promoConfig?.title}
                </Typography>
                <Typography variant="body1" className={classes.promoContent}>
                  {promoConfig?.content()}
                </Typography>
                <div className={classes.actions}>
                  <Button fullWidth className={classes.button} color="primary" variant="contained" onClick={onApply}>
                    Apply Now
                  </Button>
                </div>
              </CardContent>
            </Card>
          </Grid>
          
        </Grid>
      </CardContent>
    </Card>
  )
}

export default FuelCardPromo
