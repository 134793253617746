
export const MODULE_NAME = `featureToggles`
export const FEATURE_TOGGLE_PATH = '/admin/feature-toggles'

export const REQUEST_FEATURE_TOGGLE_LIST = `${MODULE_NAME}_request_featureToggleList`
export const REQUEST_FEATURE_TOGGLE_LIST_ERROR = `${MODULE_NAME}_request_featureToggleList_error`
export const REQUEST_FEATURE_TOGGLE_LIST_FULFILLED = `${MODULE_NAME}_request_featureToggleList_fulfilled`

export const ENABLE_FEATURE = `${MODULE_NAME}_enable_feature`
export const ENABLE_FEATURE_ERROR = `${MODULE_NAME}_enable_feature_error`
export const ENABLE_FEATURE_FULFILLED = `${MODULE_NAME}_enable_feature_fulfilled`

export const DISABLE_FEATURE = `${MODULE_NAME}_disable_feature`
export const DISABLE_FEATURE_ERROR = `${MODULE_NAME}_disable_feature_error`
export const DISABLE_FEATURE_FULFILLED = `${MODULE_NAME}_disable_feature_fulfilled`
