import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Layout } from 'frame'
import { createNavigation } from './navigation'
import { ADMIN_PATH } from './actions'
import { useIsWhiteListed, useCurrentRole } from 'utils'
import { Page404 } from 'frame/components'
import { useEnabledFeatureToggleList } from '../settings/hooks'

import {
  constants as fromUsers,
  ModuleRoutes as UsersRoute
} from 'features/admin-users'

import {
  constants as fromInvitations,
  ModuleRoutes as InvitationsRoute
} from 'features/admin-invitations'

import {
  constants as fromRegistrationOfInterest,
  ModuleRoutes as RegistrationOfInterestRoute
} from 'features/admin-registration-of-interest'

import {
  constants as fromFeatureToggles,
  ModuleRoutes as FeatureTogglesRoute
} from 'features/feature-toggle'

import {
  constants as fromFeatureTogglesSettings,
  ModuleRoutes as FeatureTogglesSettingsRoute
} from 'features/feature-toggle-settings'

const AdminRoute = () => {

  const myDriveAppFeatureToggleEnabled = useEnabledFeatureToggleList()?.some(x => x === 'MyDriveAppFeatures')

  const currentRole = useCurrentRole()
  const navigation = createNavigation(myDriveAppFeatureToggleEnabled)
  const isWhiteListedUser = useIsWhiteListed()

  const hideRegistrationOfInterestRoute = !currentRole.includes('superadmin')
  const hideFeatureTogglesRoute = !isWhiteListedUser

  let filteredNavigation = navigation

  filteredNavigation[0].pages = hideRegistrationOfInterestRoute
    ? navigation[0].pages.filter(node => node.title !== 'Registration Of Interests')
    : navigation[0].pages

  filteredNavigation[0].pages = hideFeatureTogglesRoute
    ? navigation[0].pages.filter(node => node.title !== 'Feature Toggles')
    : navigation[0].pages

  return (
    <Layout navigation={filteredNavigation}>
      <Switch>
        <Route path={fromUsers.PATH_ALL} component={UsersRoute} />
        <Route path={fromInvitations.PATH_ALL} component={InvitationsRoute} />
        <Route path={fromRegistrationOfInterest.PATH_ALL} component={hideRegistrationOfInterestRoute ? () => <Page404 withoutNav /> : RegistrationOfInterestRoute} />
        <Route path={fromFeatureToggles.PATH_ALL} exact component={hideFeatureTogglesRoute ? () => <Page404 withoutNav /> : FeatureTogglesRoute} />
        <Route path={fromFeatureTogglesSettings.PATH_ALL} component={hideFeatureTogglesRoute ? () => <Page404 withoutNav /> : FeatureTogglesSettingsRoute} />
        <Redirect from={ADMIN_PATH} to={fromUsers.PATH_ALL} />
      </Switch>
    </Layout>
  )
}

export default AdminRoute
