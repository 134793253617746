import React, { useState, useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import createAuth0Client from '@auth0/auth0-spa-js'
import * as fromActions from './actions'
import { setAuthClient } from './auth'
import Cookies from 'js-cookie'
import ReactGA from 'react-ga'

const defaultRedirect = () => {
  let dr = window.history.replaceState(
    {},
    document.title,
    window.location.pathname
  )
  console.log('defaultRedirect dr', dr)
  return dr
}

export const AuthContext = React.createContext()

export const useAuth = () => useContext(AuthContext)

const AuthProvider = ({
  children,
  onAuthRequested,
  onLoggedIn,
  onLoggedOut,
  onRedirectCallback = defaultRedirect,
  history,
  ...initOptions
}) => {

  const [isAuthenticated, setIsAuthenticated] = useState()
  const [user, setUser] = useState()
  const [authClient, setAuth0] = useState()
  const [loading, setLoading] = useState(true)
  const [popupOpen, setPopupOpen] = useState(false)

  const checkSessionValidity = () => {

    if (!authClient) return;
    authClient.getTokenSilently().then(() => {
    }).catch((e) => {

      if (e.error === 'login_required') {
        logout()
      }
    });
  };

  const logout = () => {
    localStorage.clear()
    localStorage.setItem('FORCE_LOGOUT_ALL_SESSIONS', true) // to logout other tab sessions if any
    history.push('/logout')
  }

  useEffect(() => {

    const initAuth = async () => {

      // console.log('useEffect init')

      onAuthRequested()
      const client = await createAuth0Client(initOptions)
      setAuth0(client)
      setAuthClient(client)

      if (window.location.search.includes('code=')) {

        // console.log('useEffect init code=')

        if ( Cookies.get('auth0.cypress') === 'true') {

          // console.log('useEffect init code= cookie')
          const { appState } = { targetUrl: '/' }
          onRedirectCallback(appState)

        } else {
          // console.log('useEffect init code= await client')
          const { appState } = await client.handleRedirectCallback()
          onRedirectCallback(appState)
          console.log('[GA] event tracked', { category: 'User Session', action: 'Login Success' })
          ReactGA.event({ category: 'User Session', action: 'Login Success' })
        }
      }

      const authenticated = await client.isAuthenticated() || Cookies.get('auth0.cypress') === 'true'
      setIsAuthenticated(authenticated)
      //console.log('useEffect init authenticated', authenticated)
      //console.log('useEffect init  Cookies.get(cypress) === true',  Cookies.get('auth0.cypress') === 'true')

      if (authenticated) {
        //console.log('useEffect init if authenticated')

        let profile
        if (Cookies.get('auth0.cypress') === 'true') {
          profile = JSON.parse(Cookies.get('auth0.cypress.profile'))
          //console.log('useEffect init if authenticated auth0.cypress.profile', profile)

        } else {
          profile = await client.getUser()
          //console.log('useEffect init if authenticated profile', profile)
        }

        setUser(profile)
        onLoggedIn(profile)
      }
      else {
        //console.log('useEffect init if else onLoggedOut')

        onLoggedOut()
      }

      //console.log('useEffect init setLoading(false)')

      setLoading(false)
    }

    initAuth()
  }, []) // eslint-disable-line

  useEffect(() => {
    setInterval(checkSessionValidity, 60000)
  }, [authClient]);

  const loginWithPopup = async (params = {}) => { // delete?

    console.log('loginWithPopup')

    setPopupOpen(true)

    try {
      await authClient.loginWithPopup(params)
    }
    catch (error) {
      if (console && console.error) { // eslint-disable-line
        console.error(error) // eslint-disable-line
      }
    }
    finally {
      setPopupOpen(false)
    }

    const profile = await authClient.getUser()
    setUser(profile)
    setIsAuthenticated(true)
  }

  const handleRedirectCallback = async () => {
    setLoading(true)
    await authClient.handleRedirectCallback()
    const profile = await authClient.getUser()
    setLoading(false)
    setIsAuthenticated(true)
    setUser(profile)
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => authClient.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => authClient.loginWithRedirect(...p),
        getTokenSilently: (...p) => authClient.getTokenSilently(...p),
        getTokenWithPopup: (...p) => authClient.getTokenWithPopup(...p),
        logout: (...p) => authClient.logout(...p)
      }}>
      {children}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.node,
  onAuthRequested: PropTypes.func.isRequired,
  onLoggedIn: PropTypes.func.isRequired,
  onLoggedOut: PropTypes.func.isRequired,
  onRedirectCallback: PropTypes.func,
}

const mapDispatchToProps = {
  onAuthRequested: fromActions.authRequested,
  onLoggedIn: fromActions.loggedIn,
  onLoggedOut: fromActions.loggedOut,
}

export default connect(
  null,
  mapDispatchToProps
)(AuthProvider)
