import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { CardActions, TablePagination } from '@material-ui/core'


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    justifyContent: 'flex-end'
  }
}))

const PageListTableFooter = ({
  offset,
  rows,
  total,
  onChangePaginationOffset,
  onChangePaginationRows
}) => {

  const classes = useStyles()

  return (
    <CardActions className={classes.root}>
      <TablePagination
        component="div"
        count={total}
        onChangePage={onChangePaginationOffset}
        onChangeRowsPerPage={onChangePaginationRows}
        page={offset}
        rowsPerPage={rows}
        rowsPerPageOptions={[10, 25, 50, 100]} />
    </CardActions>
  )
}

PageListTableFooter.propTypes = {
  offset: PropTypes.number.isRequired,
  rows: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  onChangePaginationOffset: PropTypes.func.isRequired,
  onChangePaginationRows: PropTypes.func.isRequired,
}

export default PageListTableFooter
