import { combineEpics, ofType } from 'redux-observable'
import { makeActionRequestEffect } from 'effects'
import { map } from 'rxjs/operators'
import join from 'url-join'
import * as fromAuthTypes from 'auth'
import { getAppConfig } from 'app-config'
import * as fromActions from './actions'
import fromConstants from './constants'


export const createEffects = () => {

  const appConfig = getAppConfig()

  const setProfileEffect = action$ =>
    action$.pipe(
      ofType(fromAuthTypes.AUTHORIZATION_LOGIN_FULFILLED),
      map(action => fromActions.setProfile(action?.payload || {})),
    )

  const resetEffect = action$ =>
    action$.pipe(
      ofType(fromAuthTypes.AUTHORIZATION_LOGOUT_FULFILLED),
      map(action => fromActions.resetDefaults()),
    )

  const putPasswordEffect = makeActionRequestEffect({
    type: fromConstants.PUT_PASSWORD,
    url: join(appConfig.app.api, '/authzero/updatedetails'),
    verb: 'post',
    onSuccess: fromActions.putPasswordFulfilled,
    onError: fromActions.putPasswordError,
  })

  const putProfileEffect = makeActionRequestEffect({
    type: fromConstants.PUT_PROFILE,
    url: join(appConfig.app.api, '/authzero/updatedetails'),
    verb: 'post',
    onSuccess: fromActions.putProfileFulfilled,
    onError: fromActions.putProfileError,
  })

  const updateProfileEffect = action$ =>
    action$.pipe(
      ofType(fromConstants.PUT_PROFILE_FULFILLED),
      map(action => fromActions.updateProfile(action.payload)),
    )

  return combineEpics(
    resetEffect,
    setProfileEffect,
    putPasswordEffect,
    putProfileEffect,
    updateProfileEffect,
  )
}
