import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Grid, Typography } from '@material-ui/core'


const useStyles = makeStyles(theme => ({
  content: { maxWidth: 300 },
  heading: { fontWeight: 'bold' },
}))

const SubmissionAlreadyCompleted = ({ errorMessage }) => {

  const classes = useStyles()
  const isAuto = errorMessage && errorMessage.includes('auto')

  return (
    <Grid container spacing={3} className={classes.content}>
      <Grid item xs={12}>
        <Typography gutterBottom variant="h4" align="center" className={classes.heading}>
          {isAuto ? 'Auto Submission Completed' : 'Submission Completed'}
        </Typography>
        <Typography gutterBottom variant="body1" align="center">
          {isAuto
            ? `An automated submission stating that your vehicle was available for private use all month has been recorded. Please contact your fleet controller if changes are required.`
            : `You have already recorded a submission for the month. If you do need to make changes to this please contact your fleet controller.`
          }
        </Typography>
      </Grid>
    </Grid>
  )
}

SubmissionAlreadyCompleted.propTypes = {
  errorMessage: PropTypes.string,
}

export default SubmissionAlreadyCompleted