import { connect } from 'react-redux'
import Add from './add'
import { addFbtSubmission, lookup, reset, setNewSubmissionDetails } from '../../actions'


const mapStateToProps = ({ fbtSubmissions }) => ({
  filtered: fbtSubmissions?.filtered,
  flags: fbtSubmissions.flags,
  currentFbtCustomerView: fbtSubmissions.currentFbtCustomerView,
})

const mapDispatchToProps = (dispatch) => ({
  onSave: (payload) => {
    dispatch(addFbtSubmission(payload))
  },
  onReset: () => {
    dispatch(reset())
  },
  onLookup: (year, month) => (lookupPath, searchKey, customFleetId, size = 500) => {
    dispatch(lookup(null, {
      resourceId: lookupPath,
      queryParams: { FleetId: customFleetId, Search: searchKey, year, month, size }
    }))
  },
  onSetNewSubmissionDetails: (payload) => {
    dispatch(setNewSubmissionDetails(payload))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Add)
