import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { constants } from '../../module'

import AddEditSubmission from '../add-edit-submission'
import ViewSubmissions from '../view-submissions'

const AddEditFbtRoutes = () => (
  <Switch>
    <Route path={constants.ROUTE_ADD_SUBMISSION} exact component={AddEditSubmission} />
    <Route path={constants.ROUTE_EDIT_SUBMISSION} exact component={AddEditSubmission} />
    <Route path={constants.ROUTE_VIEW_SUBMISSIONS} exact component={ViewSubmissions} />
  </Switch>
)

export default AddEditFbtRoutes