import { combineEpics } from 'redux-observable'
import { ofType } from 'redux-observable'
import { map } from 'rxjs/operators'
import * as fromAuthTypes from 'auth'
import { makeActionRequestEffect } from 'effects'
import join from 'url-join'
import { getAppConfig } from 'app-config'
import { actions as fromSettings } from 'features/settings'


export const createEffects = (moduleTypes, moduleActions, api = '') => {

  const appConfig = getAppConfig()

  const onResetEffect = action$ =>
    action$.pipe(
      ofType(fromAuthTypes.AUTHORIZATION_LOGOUT_FULFILLED),
      map(action => moduleActions.resetDefaults()),
    )

  const onFleetChangeEffect = (action$, state$) =>
    action$.pipe(
      ofType(fromSettings.SELECTED_FLEETS_CHANGED),
      map(action => moduleActions.resetDefaults()),
    )

  // Fetches a single resouce using auth headers
  const onRequestDataEffect = makeActionRequestEffect({
    type: moduleTypes.REQUEST_DATA,
    url: join(appConfig.app.api, api),
    onSuccess: moduleActions.requestDataFulfilled,
    onError: moduleActions.requestDataError,
    // debug: true,
    // proxy: true,
  })

  const onRequestDataFulfilledEffect = action$ =>
    action$.pipe(
      ofType(moduleTypes.REQUEST_DATA_FULFILLED),
      map(action => moduleActions.addData(action.payload)),
    )

  return combineEpics(
    onResetEffect,
    onFleetChangeEffect,
    onRequestDataEffect,
    onRequestDataFulfilledEffect,
  )
}

