import * as fromActions from './actions/types'


const reducer = (state, action) => {
  switch (action.type) {

    // Fetch Incident Attachments
    case fromActions.FETCH_INCIDENT_DETAILS:
      return onFetchIncidentDetails(state)
    case fromActions.FETCH_INCIDENT_DETAILS_FULFILLED:
      return addFetchedIncidentDetails(state, action)
    case fromActions.FETCH_INCIDENT_DETAILS_ERROR:
      return onError(state, action, { fetchDetails: 'error' })
    
    // Save Incident Notes
    case fromActions.SAVE_INCIDENT_NOTES:
      return updateFlags(state, { saveNotes: 'processing' })
    case fromActions.SAVE_INCIDENT_NOTES_FULFILLED:
      return saveNotesSuccess(state, action)
    case fromActions.SAVE_INCIDENT_NOTES_ERROR:
      return onError(state, action, { saveNotes: 'error' })

    default:
      return state
  }
}

const onError = (state, action, flags) => {
  const error = action.payload
  state.errorMessage = typeof error === 'string' ? action.payload : ''
  return updateFlags(state, flags)
}

const updateFlags = (state, flags) => ({
  ...state,
  flags: { ...state.flags, ...flags }
})


const onFetchIncidentDetails = (state) => ({
  ...state,
  form: null, // clear any old incident details
  flags: {
    ...state.flags,
    fetchDetails: 'processing',
    saveNotes: 'none'
  }
})

const addFetchedIncidentDetails = (state, { payload }) => ({
  ...state,
  form: {
    ...payload?.incident,    
    attachments: payload?.attachments || [],
  },
  flags: {
    ...state.flags,
    fetchDetails: 'processed',
  }
})

const saveNotesSuccess  = (state, { payload }) => ({
  ...state,
  data: {
    ...state.data,
    [payload.id]: payload,
  },
  flags: {
    ...state.flags,
    saveNotes: 'processed',
  }
})

export default reducer
