import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Divider, Typography } from '@material-ui/core'
import { SingleSelect } from 'frame/components'
import { createOnFilter } from 'filter'


const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 0),
    cursor: 'pointer',
  },
  formGroup: {
    padding: theme.spacing(2, 0),
  },
}))

const FilterAttributes = ({ filters, filtered, onFilter }) => {

  const classes = useStyles()
  const onSelect = createOnFilter(onFilter)
  // const hasList = (list) => list.length > 0
  const { completedStatus } = filters
  console.log('[completedStatus]: ', completedStatus)

  return (
    <div className={classes.root}>
      <div
        className={classes.header}>
        <Typography variant="h5">Filters</Typography>
      </div>
      <Divider />
        <div className={classes.content}>

          {/* {hasList(completedStatus) && ( */}
            <div className={classes.formGroup}>
              <SingleSelect
                id="completedStatus"
                label="Submission Status"
                customNoneLabel="All"
                options={['Completed', 'Not Completed']}
                tight
                value={filtered.completedStatus}
                onChange={onSelect('completedStatus')} />
            </div>
          {/* )} */}
        </div>
    </div>
  )
}

FilterAttributes.propTypes = {
  filters: PropTypes.object.isRequired,
  filtered: PropTypes.object.isRequired,
  onFilter: PropTypes.func.isRequired,
}

export default FilterAttributes
