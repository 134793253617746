import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Table, TableHead, TableBody, TableCell, TableRow, IconButton, Collapse, colors } from '@material-ui/core'
import { createRowSelector, useRouter } from 'utils'
import { FleetIcon, Label } from 'frame/components'
import { constants, types } from '../../module'
import moment from 'moment'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { useEnabledFeatureToggleList } from 'features/settings'


const useStyles = makeStyles(theme => ({
  row: {
    cursor: 'pointer',
    verticalAlign: 'text-top',
  },
  label: theme.typography.bodySmall,
  columnHeadingRow: {
    background: '#F4F6F8',
  },
  column: {
    ...theme.typography.bodySmall,
    whiteSpace: 'nowrap',
    minWidth: 100,
    width: 100,
  },
  endingColumn: {
    ...theme.typography.bodySmall,
    padding: 0,
    width: '100%',
  },
  columnHeading: {
    maxWidth: '120px',
    fontSize: '12px',
  },
  spacer: {
    margin: 0,
    padding: 0,
  },
  tag: {
    marginLeft: theme.spacing(3),
  },
  documentLink: {
    color: theme.palette.text.link,
    textDecoration: 'underline',
  },
}))

const Row = ({ item, columns, openedDocumentId, expanded, onExpandRow }) => {


  const classes = useStyles()
  const { history } = useRouter()
  
  const hasDocuments = item.documentsList && item.documentsList.length > 0
  const isSupportPDFPreview = navigator.mimeTypes && navigator.mimeTypes['application/pdf'] && navigator.mimeTypes['application/pdf'].enabledPlugin

  const onSafeRowClick = createRowSelector(() => hasDocuments ? onExpandRow() : {})

  const onPreviewDocument = (documentId) => () => {
    isSupportPDFPreview && history.push(`${constants.PATH_SINGLE}/${documentId}`)
  }

  const featureToggleList = useEnabledFeatureToggleList()
  const tfp1944FeatureToggle = featureToggleList.includes('TFP1944')
  
  return (
    <>
      <TableRow hover className={classes.row} onClick={onSafeRowClick}>
        <TableCell className={classes.spacer} />
        
        {columns.accountName && (
          <TableCell className={classes.label}>
            <FleetIcon fleetName={item.accountName} />
          </TableCell>
        )}
        {columns.agreementNo && (
          <TableCell className={classes.label}>
            {item.agreementNo || '-'}
          </TableCell>
        )}
        {columns.registration && (
          <TableCell className={classes.label}>
            {item.registration || '-'}
          </TableCell>
        )}
        {columns.driverNote && tfp1944FeatureToggle && (
          <TableCell className={classes.label}>
            {item.driverNote || '-'}
          </TableCell>
        )}
        {columns.driver && (
          <TableCell className={classes.label}>
            {item.driver || '-'}
          </TableCell>
        )}
         <TableCell align="right">
           {hasDocuments && (
              <IconButton aria-label="expand row" size="small">
                {!!expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
           )}
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={6}>
          <Collapse in={!!expanded} timeout="auto" unmountOnExit>
            <Table>
              <TableHead>
                <TableRow className={classes.columnHeadingRow}>
                  <TableCell className={classes.column}>
                    Date
                  </TableCell>
                  <TableCell className={classes.column}>
                    Type
                  </TableCell>
                  <TableCell className={classes.endingColumn}>
                    Document
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="ignore">
                {hasDocuments && item.documentsList.map(({ id, archiveDate, documentName, documentType, documentUri }, index) => (
                  <TableRow key={index} hover className={classes.row} onClick={onPreviewDocument(id)}>
                    <TableCell className={classes.column}>
                      {archiveDate ? moment(archiveDate).format('DD/MM/YYYY') : '-'}
                    </TableCell>
                    <TableCell className={classes.column}>
                      {documentType || '-'}
                    </TableCell>
                    <TableCell className={classes.endingColumn}>
                      {isSupportPDFPreview ? (
                        <span className={classes.documentLink}>
                          {documentName}
                        </span>
                      ) : (
                        <a className={classes.documentLink} href={documentUri} download>
                          {documentName}
                        </a>
                      )}
                      {openedDocumentId === `${id}` && isSupportPDFPreview && (
                        <Label className={classes.tag} color={colors.green[600]} variant="outlined">
                          open
                        </Label>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

Row.propTypes = {
  item: types.ModelType.isRequired,
  columns: types.ColumnsType.isRequired,
  openDocumentId: PropTypes.string,
}

export default Row
