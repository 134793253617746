import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Grid, Typography } from '@material-ui/core'
import { RowValueText, Label } from 'frame/components'
import { getDriverStatusColor } from 'listable'


const useStyles = makeStyles(theme => ({
  label: {
    paddingBottom: theme.spacing(1),
  },
}))

const ReadOnlyForm = ({ values }) => {

  const classes = useStyles()

  return (      
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <RowValueText label="Fleet">
          <Typography variant="body1">
            {values.accountName || '-'}
          </Typography>
        </RowValueText>
      </Grid>

      <Grid item xs={12}>
        <RowValueText label="Name">
          <Typography variant="body1">
            {values.driver || '-'}
          </Typography>
        </RowValueText>
      </Grid>
      
      <Grid item xs={12}>
        <RowValueText label="Email">
          <Typography variant="body1">
            {values.email || '-'}
          </Typography>
        </RowValueText>
      </Grid>
      
      <Grid item xs={12}>
        <RowValueText label="Phone">
          <Typography variant="body1">
            {values.mobile || '-'}
          </Typography>
        </RowValueText>
      </Grid>

      <Grid item xs={12}>
        <RowValueText label="Driver Notification">
          <Typography variant="body1">
            {values.driverNotification || '-'}
          </Typography>
        </RowValueText>
      </Grid>

      <Grid item xs={12} style={{ marginTop: '5px'}}>
        <RowValueText label="Status" labelClassName={classes.label}>
          <Label
            color={getDriverStatusColor(values.status)}
            variant="outlined">
            {values.status || '-'}
          </Label>
        </RowValueText>
      </Grid>
    </Grid>
  )
}

ReadOnlyForm.propTypes = {
  values: PropTypes.object.isRequired,
}

export default ReadOnlyForm
