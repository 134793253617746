import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { TextValidator } from 'react-material-ui-form-validator'
import { Grid, Table, TableBody, TableCell, TableHead, TableRow, Radio, Typography } from '@material-ui/core'
import { PageLoader, EmailTextField } from 'frame/components'
import { useSelectedFleetIds } from 'features/settings'
import { useSelector } from 'react-redux'


const useStyles = makeStyles(theme => ({
  tableContainer: {
    overflowX: 'none',
    [theme.breakpoints.down('xs')]: {
      overflowX: 'scroll',
    }
  },
  tableRow: {
    "&:hover": {
      backgroundColor: '#eeeeee !important',
      cursor: 'pointer',
    }
  },
  greyOut: {
    color: '#BDBDBD !important'
  },
  heading: {
    color: theme.palette.brand,
  },
}))

const DriverLookupField = ({
  id,
  values,
  originalValues,
  customFleetId,
  disabled,
  label,
  lookupPath,
  resourceModel,
  currentDriver,
  searchHistory,
  // func
  setSearchHistory,
  onEmailChange,
  onLookup,
  onChange,
}) => {

  const classes = useStyles()
  const fleetIds = useSelectedFleetIds()

  const [searchKey, setSearchKey] = useState(searchHistory ? searchHistory.input : '')

  const lookupResults = useSelector(state => state[resourceModel]?.lookup && state[resourceModel]?.lookup[lookupPath]
    ? state[resourceModel]?.lookup[lookupPath]
    : [])
  
  const lookupFlag = useSelector(state => state[resourceModel]?.flags?.lookup)
  const isLoading = lookupFlag === 'processing'

  useEffect(() => {
    // load selected vehicle on load from history if exists
    if(searchHistory) {
      searchHistory.selected && onChange({
        ...searchHistory.selected, // only overwrite selected driver details on load - uniqueId, driver, email, phone
        // effectiveDate, cc, location, controller, vehicleNotes only get updated on select new driver
        effectiveDate: values.effectiveDate, 
        costCentre: values.costCentre, 
        location: values.location,
        controller: values.controller,
        vehicleNotes: values.vehicleNotes,
      })
      searchHistory.email && onEmailChange(searchHistory.email)
    }
  }, []) // eslint-disable-line 

  useEffect(() => { // update dropdown options on lookup results value change
    // save search key for re-rendering search when form is load from back btn from confirm from
    setSearchHistory({
      ...searchHistory,
      input: searchKey && searchKey.length > 0 ? searchKey : '',
      options: lookupResults && lookupResults.length > 0 ? lookupResults : []
    })// eslint-disable-next-line
  }, [searchKey])
  
  const onInputChange = ({ target: { value } }) => {
    
    const input = value || ''
    setSearchKey(input)
    console.log('lookup onInputChange ', input)
    
    if(input.length > 0) {
      const fleetIdParam = customFleetId ? customFleetId : fleetIds.join(',')
      onLookup(lookupPath, value.trim(), fleetIdParam, 10)
    } else {
      onChange(null)
      setSearchHistory && setSearchHistory(null)
    }
  }

  const onSelectDriver = (rowData) => () => {
    setSearchHistory && setSearchHistory({ ...searchHistory, selected: rowData }) // store selected driver
    onChange(rowData)
    console.log('[onSelectDriver]: ', rowData)
  }

  const isCurrentDriver = (newDriver) => originalValues.uniqueId === newDriver.value
  
  return (
    <Grid container spacing={2}>

      <Grid item xs={12}>
        <TextValidator
          id={id || 'lookup-search-driver'}
          disabled={disabled}
          fullWidth
          label={label}
          name="searchDriver"
          type="text"
          value={searchKey}
          variant="outlined"
          onChange={onInputChange}          
          onKeyPress={(ev) => {
            // console.log(`Pressed keyCode ${ev.key}`)
            ev.key === 'Enter' && ev.preventDefault()
          }}
        />
      </Grid>

      <Grid item xs={12} className={classes.tableContainer}>
        {searchKey && searchKey.length > 0 && lookupResults && lookupResults.length > 0 && !isLoading && (
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                </TableCell>
                <TableCell className={classes.heading}>
                  Driver
                </TableCell>
                <TableCell className={classes.heading}>
                  Email
                </TableCell>
                <TableCell className={classes.heading}>
                  Status
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {lookupResults.map((rowData, index) => (
                  <TableRow className={classes.tableRow}
                    key={`${index}-${rowData.value}`}
                    onClick={isCurrentDriver(rowData) ? null : onSelectDriver(rowData)}
                  >
                    <TableCell style={{ minWidth: '20px' }}>
                      <Radio
                        disabled={isCurrentDriver(rowData)}
                        style={{ marginLeft: '-10px' }}
                        size="small"
                        color="default"
                        checked={currentDriver === rowData.value}
                        name="select-driver-radio-button"
                        inputProps={{ 'aria-label': `radio-btn-${index}-${rowData.value}` }}
                      />
                    </TableCell>

                    <TableCell className={isCurrentDriver(rowData) ? classes.greyOut : ''} style={{ minWidth: '200px' }}>
                      {rowData.text || '-'} {/* Driver Name */}
                    </TableCell>

                    <TableCell className={isCurrentDriver(rowData) ? classes.greyOut : ''} style={{ minWidth: '250px' }}>
                      {(currentDriver !== rowData.value && !rowData.email) || rowData.email ? rowData.email || '-' : (
                        <EmailTextField
                          fullWidth
                          size="small"
                          id="email"
                          label="Email"
                          // disabled={disabled}
                          inputProps={{ maxLength: 50 }}
                          value={rowData.email
                            ? rowData.email.trim()
                            : searchHistory && searchHistory.email ? searchHistory.email : ''
                          }
                          variant="outlined"
                          onChange={onEmailChange}
                        />
                      )}
                    </TableCell>

                    <TableCell className={isCurrentDriver(rowData) ? classes.greyOut : ''}>
                      {rowData.status || '-'}
                    </TableCell>

                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        )}
        
          
        {isLoading && (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <PageLoader />
          </div>
        )}

        {lookupResults && lookupResults.length === 0 && !isLoading && searchKey && searchKey.length > 0 && (
          <Typography variant="body1">
            No driver found.
          </Typography>
        )}
      </Grid>
    </Grid>
  )
}

DriverLookupField.propTypes = {
  id: PropTypes.string.isRequired,
  values: PropTypes.object,
  originalValues: PropTypes.object,
  customFleetId: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  lookupPath: PropTypes.string.isRequired,
  currentDriver: PropTypes.string,
  searchHistory: PropTypes.object,
  // func
  setSearchHistory: PropTypes.func,
  onEmailChange: PropTypes.func,
  onChange: PropTypes.func,
  onLookup: PropTypes.func,
}

export default DriverLookupField
