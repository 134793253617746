import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Button, Card, CardContent, Grid, Typography } from '@material-ui/core'
import { trimObjectStrings } from 'utils'
import { fetchServiceHistory, submitKmReading, reset, downloadVehicleHistory, fetch } from 'features/fleet/actions'
import { fetchServiceBooking } from 'features/report-vehicle-servicing/actions'
import moment from 'moment'
import { useEnabledFeatureToggleList } from 'features/settings'
import VehicleFormWraper from './form-wrapper'
import { isMiles } from 'utils/sourceSystem'
import { hasRoles } from 'acl'

// components
import WofSection from './wof-section'
import RegoSection from './rego-section'
import VehicleHistoryTable from './vehicle-history-table'
import Odometer from './odometer'
import AddKmReadingForm from './add-km-reading-form'
import AddServiceRecordForm from '../service-bookings/add-service-record-form'
import { StyledModal, SnackbarPopup } from 'frame/components'
import { useIsAlfaDownDisableFeatures } from 'features/drivers'

const viewConstants = {
  VEHICLE_DETAILS: 'VEHICLE_DETAILS', // default
  ADD_KM_READING_FORM: 'ADD_KM_READING_FORM',
  ADD_SERVICE_RECORD_FORM: 'ADD_SERVICE_RECORD_FORM',
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 1.2, 3, 1.2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
  },
  content: {
    margin: theme.spacing(2, 0),
  },
  heading: {
    fontWeight: 'bold',
    color: '#7099DB'
  },
  topSpacing: {
    marginTop: theme.spacing(3),
  },
  vehicleSectionCard: {
    marginRight: '8px',
    position: 'relative',
    minHeight: 184,
    padding: '13px 10px',
    [theme.breakpoints.down('sm')]: {
      minHeight: 100,
    }
  },
  verticalCenter: {
    margin: 0,
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  // Vehicle Rego Styles
  serviceDate: {
    marginLeft: theme.spacing(6),
    fontWeight: 'bold',
  },
  serviceIcon: {
    width: 70,
    height: 70,
    backgroundImage: 'url("/static/images/digital_service_book/wrench.png")',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    [theme.breakpoints.down('xs')]: { width: 75, height: 75 },
  },
  // No of Tyres Styles
  tyres: {
    display: 'inline-flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
  inactive: {
    opacity: '20%',
  },
  tyreIcon: {
    width: 60,
    height: 60,
    marginRight: 10,
    marginBottom: 10,
    backgroundImage: 'url("/static/images/digital_service_book/wheel_active.png")',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  noData: {
    color: theme.palette.error.main,
  },
  addServicebutton: {
    margin: theme.spacing(2, 0, 1, -1),
    padding: '8px 19px',
  },
  button: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    padding: '8px 19px',
    minWidth: 160,
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
    }
  },
  vehicleHistoryButtons:{
    textAlign: 'right'
  },
}))

const VehicleDetails = ({ data, flags, onFetchServiceHistory, onReset, onFetchServiceBooking, onDownloadVehicleHistory, downloadStatus, fleetData, onFetch, userRoles }) => {
  const isMilesAgreement = isMiles(data.sourceSystem);
  const classes = useStyles()
  const featureToggleList = useEnabledFeatureToggleList()
  const kmExceptionsFeatureToggle = featureToggleList.includes('KMExceptions')
  const disableOutOfHoursFeatureToggle = featureToggleList.includes('OutOfHoursDisabled') // 'ALFA out of hours DISABLE FEATURES' limitations feature is ON
  const isAlfaDownDisableDriverFeatures = useIsAlfaDownDisableFeatures()
  const disabled = disableOutOfHoursFeatureToggle ? false : isAlfaDownDisableDriverFeatures //false

  const [addNewKmReadingModal, setAddNewKmReadingModal] = useState(false)
  const [activeView, setActiveView] = useState(viewConstants.SERVICE_BOOKING_MENU)
  const [odoData, setOdoData] = useState(data)
  
  const noOfTyres = data.noOfTyres || 0

  useEffect(() => { // on load
    onReset() // reset flags
    onFetchServiceBooking(data.agreementNo) // fetch service booking data
  }, []) // eslint-disable-line

  useEffect(() => {
    data.registration && data.registration !== '(Unknown)' && onFetchServiceHistory(data.registration, data.thirdPartyNumber)
    data.agreementNo && onFetchServiceBooking(data.agreementNo)
  // eslint-disable-next-line
  }, [onFetchServiceHistory])

  const onAddKmReadingClick = () => setAddNewKmReadingModal(true)
  const onRecentlyCompletedServiceClick = () => {
    setAddNewKmReadingModal(false);
    setActiveView(viewConstants.ADD_SERVICE_RECORD_FORM)
  }
  const addNewKmClick = () => {
    setAddNewKmReadingModal(false);
    setActiveView(viewConstants.ADD_KM_READING_FORM)
  }
  const onDownloadClick = () => {
    onDownloadVehicleHistory(data.thirdPartyNumber,data.registration);
  }
  const updateOdoData = (lastReadingDate, lastReading) => {
    const updatedData = {...odoData,lastKmReading:lastReadingDate,lastOdoReading:lastReading}
    setOdoData(updatedData)
  }

  return (
    <>
      {activeView === viewConstants.ADD_KM_READING_FORM ? (
        <AddKmReadingForm      
          fleetData={fleetData}
          isMiles={isMilesAgreement}  
          onBack={() => {
            setActiveView(viewConstants.SERVICE_BOOKING_MENU)
            onFetchServiceHistory(fleetData.registration, fleetData.thirdPartyNumber)
          }}
        />
      ) : activeView === viewConstants.ADD_SERVICE_RECORD_FORM ? (
        <AddServiceRecordForm
          fleetData={fleetData}
          onBack={() => {
            setActiveView(viewConstants.SERVICE_BOOKING_MENU)
            onFetchServiceHistory(fleetData.registration, fleetData.thirdPartyNumber)
          }}
        />
      ) : (
        <VehicleFormWraper
          onBack={() => {
            setActiveView(viewConstants.SERVICE_BOOKING_MENU)
          }}
        >
          <Card>
            <CardContent className={classes.root}>

              <Typography gutterBottom variant="h4">
                Vehicle
              </Typography>
              <Typography variant="h5" className={classes.heading}>
                Service, WoF and Registration Dates
              </Typography>
              
              <Grid container spacing={3} className={clsx(classes.content, classes.vehicleSection)}>
                
                {/* Vehicle Service Date Section */}
                <Grid item md={3} xs={12}>
                  <Grid container spacing={1}>
                    
                    <Grid item xs={12} className={classes.vehicleSectionCard}>

                      <Grid container spacing={1} className={classes.verticalCenter}>
                        <Grid item sm={2} xs={4}>
                          <div className={classes.serviceIcon} />
                        </Grid>

                        <Grid item sm={9} xs={8}>
                          <Typography variant="h5" className={clsx(classes.verticalCenter, classes.serviceDate)}>
                            {data.estNextServiceDueDate
                              ? moment(data.estNextServiceDueDate).format('DD/MM/YYYY')
                              : (
                                <span className={classes.noData}>
                                  N/A
                                </span>
                            )}
                          </Typography>
                        </Grid>

                      </Grid>

                    </Grid>

                  </Grid>
                </Grid>
                
                {/* Vehicle WOF Section */}
                <Grid item md={4} xs={12}>
                  <WofSection data={data} />
                </Grid>
                
                {/* Vehicle Rego Section */}
                <Grid item md={5} xs={12}>
                  <RegoSection data={data} />
                </Grid>
              </Grid>
              
              {/* Vehicle History Section */}
              <Grid container alignItems="flex-end">
                <Grid item sm={6} xs={12}>
                  <Typography gutterBottom variant="h5" className={clsx(classes.heading, classes.topSpacing)}>
                    Vehicle History
                  </Typography>
                </Grid>
                {kmExceptionsFeatureToggle  && (
                  <Grid item sm={6} xs={12} className={classes.vehicleHistoryButtons}>
                    <Button
                      className={classes.button}
                      color="default"
                      type="submit"
                      variant="contained"
                      onClick={onDownloadClick}>
                      Download Report
                    </Button>
                    {(hasRoles(['superadmin'], userRoles) || hasRoles(['fleetcontroller'], userRoles)) &&
                    <Button
                      disabled={disabled}
                      className={classes.button}
                      color="primary"
                      type="submit"
                      variant="contained"
                      onClick={onAddKmReadingClick}>
                      Add KM Reading
                    </Button>
                        }
                  </Grid>
                )}
              </Grid>
              

              {/* {indicateStaleData && 
                <Typography gutterBottom variant="body2" className={classes.topSpacing}>
                  <strong>NOTE</strong>: latest 'Vehicle History' is currently being verified and should be confirmed within {hoursToProcess}hrs.
                </Typography>
              } */}

              <div className={classes.content}>
                <VehicleHistoryTable agreementNo={data.agreementNo} updateOdoData={updateOdoData} />
              </div>
              {/* End of Vehicle History Section */}
              
              {/* Contract Tyres Section */}
              <Typography  gutterBottom variant="h5" className={clsx(classes.heading, classes.topSpacing)}>
                Contract Tyres
              </Typography>

              <div className={clsx(classes.content, classes.tyres)}>
                {noOfTyres ? Array.from(Array(noOfTyres).keys()).map((tyreNo) => (
                  <div
                    key={`tyre-${tyreNo}`}
                    className={clsx(classes.tyreIcon, data.remainingTyres >= tyreNo + 1 || data.remainingTyres == null ? '' : classes.inactive)}
                  />
                )) : (
                  'No tyres are included in this contract.'
                )}
              </div>
              {/* End of Contract Tyres Section */}
              
              <Typography  gutterBottom variant="h5" className={classes.heading}>
                Odometer Reading
              </Typography>

              <Odometer data={odoData} />
            </CardContent>
          </Card>
        </VehicleFormWraper>
      )}

      <StyledModal
        open={addNewKmReadingModal}
        title={`ADD NEW KM READING`}
        titleVariant="h6"
        onCancelLabel={`No`}
        onConfirmLabel={`Yes`}
        options={['boldHeading']}
        
        onCancel={addNewKmClick}
        onConfirm={onRecentlyCompletedServiceClick}>
        Are you adding a new KM reading from a recently completed service?
      </StyledModal>
    
      {downloadStatus && (
        <SnackbarPopup
          coloured
          success={downloadStatus.toLowerCase().includes('success')}
          error={downloadStatus.toLowerCase().includes('error')}
          message={downloadStatus}
        />
      )}
    </>
  )
}

VehicleDetails.propTypes = {
  data: PropTypes.object,
  flags: PropTypes.object.isRequired,
  onFetchServiceHistory: PropTypes.func.isRequired,
  onFetchServiceBooking: PropTypes.func.isRequired,
  onDownloadVehicleHistory: PropTypes.func.isRequired,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  onReset: PropTypes.func.isRequired,
  onAddKmReading: PropTypes.func.isRequired,
  fleetData: PropTypes.object.isRequired,
}

const mapStateToProps = ({ fleet, reportVehicleServicing, settings }, ownProps) => {
  const agreementNo = ownProps.match.params.id
  let data = fleet.data?.[agreementNo] || null
  
  const vehicleHistory = fleet.relatedVehicleHistory
  if(vehicleHistory && vehicleHistory.length > 0) {
    data.remainingTyres = vehicleHistory[0].remainingTyres
  }

  return {
    data: trimObjectStrings(data), // Because the API does not clean data for us
    flags: reportVehicleServicing.flags,
    fleetFlags: fleet.flags,
    downloadStatus: reportVehicleServicing?.downloadStatus,
    fleetData: fleet.data?.[ownProps.match.params.id],
    userRoles: settings.roles,
}
}

const mapDispatchToProps = (dispatch) => ({
  onFetchServiceHistory: (rego, fleetId) => {
    dispatch(fetchServiceHistory(null, {
      resourceId: rego,
      queryParams: { fleetId }
    }))
  },
  onFetchServiceBooking: (agreementNo) => {
    dispatch(fetchServiceBooking(null, { resourceId: agreementNo }))
  },  
  onDownloadVehicleHistory: (selectedThirdPartyNumber, selectedRegistration) => {
    dispatch(downloadVehicleHistory(null,{ title:'Vehicle History', queryParams: { selectedThirdPartyNumber, selectedRegistration } }))
  },  
  onAddKmReading: (payload) => {
    dispatch(submitKmReading(payload))
  },
  onReset: () => {
    dispatch(reset())
  },
  onFetch: (agreementNo, fleetIds) => {
    const FleetId = fleetIds.join(',')
    dispatch(fetch(null, {
      resourceId: agreementNo,
      queryParams: { FleetId }
    }))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(VehicleDetails)
