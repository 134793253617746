

export const createConstants = (moduleName, customConstants) => ({

  ...customConstants,

  RESET_DEFAULTS: `${moduleName}_reset_defaults`,

  ADD_ITEMS: `${moduleName}_add_items`,
  REMOVE_ITEMS: `${moduleName}_remove_items`,

  SELECT_ITEMS: `${moduleName}_select_items`,
  DESELECT_ITEMS: `${moduleName}_deselect_items`,

  CHANGE_SEARCH_KEY: `${moduleName}_change_search_key`,
  UPDATE_SEARCH_KEY: `${moduleName}_update_search_key`,

  SET_PAGINATION_OFFSET: `${moduleName}_set_pagination_offset`,
  SET_PAGINATION_ROWS: `${moduleName}_set_pagination_rows`,

  REQUEST_DOWNLOAD: `${moduleName}_request_download`,
  REQUEST_DOWNLOAD_FULFILLED: `${moduleName}_request_download_fulfilled`,
  REQUEST_DOWNLOAD_ERROR: `${moduleName}_request_download_error`,
  REQUEST_DOWNLOAD_CLEAR_STATUS: `request_download_clear_status`,

  SHOW_FILTERS: `${moduleName}_show_filters`,
  HIDE_FILTERS: `${moduleName}_hide_filters`,

  CHANGE_FILTERS: `${moduleName}_change_filters`,
  UPDATE_FILTERS: `${moduleName}_update_filters`,
  CLEAR_FILTERS: `${moduleName}_clear_filters`,

  TOGGLE_SHOW_COLUMNS: `${moduleName}_toggle_show_columns`,
  TOGGLE_COLUMN: `${moduleName}_toggle_column`,
  TOGGLE_SELECT_ALL_COLUMNS: `${moduleName}_toggle_select_all_columns`,
  TOGGLE_COLUMN_SORT: `${moduleName}_toggle_column_sort`,

  REQUEST_FILTERS: `${moduleName}_request_filters`,
  REQUEST_FILTERS_FULFILLED: `${moduleName}_request_filters_fulfilled`,
  REQUEST_FILTERS_ERROR: `${moduleName}_request_filters_error`,

  ADD_FLEET_FILTERS: `${moduleName}_add_fleet_filters`,

  FETCH_LIST: `${moduleName}_fetch_list`,
  REQUEST_LIST: `${moduleName}_request_list`,
  REQUEST_LIST_FULFILLED: `${moduleName}_request_list_fulfilled`,
  REQUEST_LIST_ERROR: `${moduleName}_request_list_error`,

  REQUEST_RESOURCE: `${moduleName}_request_resource`,
  REQUEST_RESOURCE_FULFILLED: `${moduleName}_request_resource_fulfilled`,
  REQUEST_RESOURCE_ERROR: `${moduleName}_request_resource_error`,

  REFETCH_RESOURCE: `${moduleName}_refetch_resource`,

  CREATE_RESOURCE: `${moduleName}_create_resource`,
  CREATE_RESOURCE_FULFILLED: `${moduleName}_create_resource_fulfilled`,
  CREATE_RESOURCE_ERROR: `${moduleName}_create_resource_error`,

  UPDATE_RESOURCE: `${moduleName}_update_resource`,
  UPDATE_RESOURCE_FULFILLED: `${moduleName}_update_resource_fulfilled`,
  UPDATE_RESOURCE_ERROR: `${moduleName}_update_resource_error`,

  LOOKUP_RELATED_CONTRACT: `${moduleName}_lookup_related_contract`,
  LOOKUP_RELATED_CONTRACT_FULFILLED: `${moduleName}_lookup_related_contract_fulfilled`,
  LOOKUP_RELATED_CONTRACT_ERROR: `${moduleName}_lookup_related_contract_error`,

  SAVE_PREFERENCES: `${moduleName}_save_preferences`,
  SAVE_PREFERENCES_FULFILLED: `${moduleName}_save_preferences_fulfilled`,
  SAVE_PREFERENCES_ERROR: `${moduleName}_save_preferences_error`,
 
  SET_DASHBOARD_FILTER: `${moduleName}_set_dashboard_filter`,
  
  SELECT_ROW: `${moduleName}_select_row`,
})
