import { createListable } from 'listable'
import { REPORTS_PATH } from 'features/report'
import options from './config'


const listableModule = createListable({
  name: 'reportVehiclesOnOrder',
  config: options,
})

export const config = listableModule.options
export const actions = listableModule.actions
export const effects = listableModule.effects
export const reducer = listableModule.reducer
export const types = listableModule.types

export const constants = {
  ...listableModule.constants,
  PATH_ALL: `${REPORTS_PATH}/vehicles-on-order`,
}

