import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Tooltip, Avatar } from '@material-ui/core'
import { getInitials, getInitialsColor } from 'utils'


const useStyles = makeStyles(theme => ({
  avatars: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  avatar: {
    marginRight: 4,
    marginBottom: 2,
    width: 26,
    height: 26,
    fontSize: 14,
  },
}))

const FleetIcon = ({ fleetName }) => {

  const classes = useStyles()
  return (
    <>
      {fleetName ? (
        <div className={classes.avatars}>
          <Tooltip title={fleetName}>
            <Avatar
              className={classes.avatar}
              style={{ backgroundColor: getInitialsColor(getInitials(fleetName))}}>
              {getInitials(fleetName)}
            </Avatar>
          </Tooltip>
        </div>
      ) : '-'}
    </>
  )
}

FleetIcon.propTypes = {
  fleetName: PropTypes.string,
}

export default FleetIcon
