
import { useSelector } from 'react-redux'


const whiteList = ['michael.fourie@tfs.co.nz', 'matthew.cross@tfs.co.nz', 'ly-ann.darby@tfs.co.nz']

export const useIsWhiteListed = () => {  
  let userEmail = useSelector(({ account }) => account.profile?.email)
  return userEmail && (whiteList.includes(userEmail) || isBornUser(userEmail))
}

// function for validating is born super admin user
export const useIsBornAccount = () => {
  let userEmail = useSelector(({ account }) => account.profile?.email)
  return userEmail && isBornUser(userEmail)
}

// Check if user is a born user
export const isBornUser = (email) => {
  return email.toLowerCase().includes('borndigital.co.nz');
}

// Check if user is a TFS user
export const useIsUserATfs = () => {
  let userEmail = useSelector(({ account }) => account.profile?.email)
  return userEmail.toLowerCase().includes('tfs.co.nz');
}

// Check if user is a whitelisted user
export const isWhiteListed = (email) => email && (whiteList.includes(email) || isBornUser(email))

// function for returning current user role
export const useCurrentRole = () => useSelector(({ settings }) => settings?.roles)