import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'utils'
import { Dialog, StyledModal } from 'frame/components'
import { constants } from '../../module'
import { useSelectedFleetIds, useEnabledFeatureToggleList } from 'features/settings'
import { useIsAlfaDownDisableFeatures } from 'features/drivers'
import Form from './form'

import AssignForm from '../assign-form'
import ConfirmForm from '../confirm-form'
import { hasRoles } from 'acl'


const Edit = ({
  id,
  data,
  flags,
  apiResponse,
  onFetch,
  onFetchContract,
  onUpdate,
  onRemove,
  onChange,
  onLookup,
  onSaveTemporary,
  userRoles
}) => {

  const navigate = useNavigate()
  const fleetIds = useSelectedFleetIds()
  const featureToggleList = useEnabledFeatureToggleList()
  const disableOutOfHoursFeatureToggle = featureToggleList.includes('OutOfHoursDisabled') // 'ALFA out of hours DISABLE FEATURES' limitations feature is ON
  const isAlfaDownDisableDriverFeatures = useIsAlfaDownDisableFeatures()
  const isAlfaDown = disableOutOfHoursFeatureToggle ? false : isAlfaDownDisableDriverFeatures
  const disableTerminateDriverFeatureToggle = featureToggleList.includes('TFP1169')
  const userPermissionsFeatureToggle = featureToggleList.includes('UserPermissions')
  const disableButtons = userPermissionsFeatureToggle && hasRoles(['fleetcoordinator'], userRoles)

  const [saved, setSaved] = useState(false) 
  const [originalValues, setOriginalValues] = useState(data)
  const [values, setValues] = useState(data)

  // show form states
  const [showAssignForm, setShowAssignForm] = useState(false)
  const [showConfirmForm, setShowConfirmForm] = useState(false)

  // modals
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [openUnsavedChangesModal, setOpenUnsavedChangesModal] = useState(false)

  // change vehicle form states
  const [selectedVehicle, setSelectedVehicle] = useState(null)
  const [searchHistory, setSearchHistory] = useState(null)

  // edit details form states
  const [tryChangingVehicle, setTryChangingVehicle] = useState(false)
  const [doneFetchingVehicle, setDoneFetchingVehicle] = useState(false)
  const [vehicleDetails, setVehicleDetails] = useState(null)

  useEffect(() => {
    // fetch driver record by id on load
    onFetch(id, fleetIds)
  }, [id, fleetIds]) // eslint-disable-line

  useEffect(() => {
    // triggers when form is saved when confirm form is showing to keep modal opened for driver details save changes
    if (saved && showConfirmForm && (savingError || savingSuccess)) { // close after assign vehicle
      onClose()
    }
  }, [flags.update]) // eslint-disable-line

  const driverName = data.driver ? ` - ${data.driver}` : ''
  const title = showAssignForm
    ? `${data?.driver?.agreementNo ? 'Change' : 'Assign'} Vehicle to Driver${driverName}`
    : showConfirmForm && selectedVehicle
      ? selectedVehicle.text
      :  `Driver${driverName}`
 
  const isLoading = flags.fetch === 'processing' && ! data
  const isSaving = flags.update === 'processing'
  const savingError = flags.update === 'error'
  const savingSuccess = flags.update === 'processed'

  // const loadingErrorMessage = hasErrored
  //   ? `Something wen't wrong while trying to find the driver you were looking for.`
  //   : null

  const onSave = (values) => {

    const uniqueId = values.uniqueId || ''

    // store selected Vehicle details temporary for the confirmation message
    onSaveTemporary(selectedVehicle)

    onUpdate(uniqueId, {
      ...values,
      driverNotification: values.driverNotification || 'n/a', // initialize to avoid null param
    })
    setSaved(true)
  }

  const onShowAssignForm = () => { // edit driver form - assign button
    !searchHistory && setValues({ ...values, agreementNo: null })
    setShowAssignForm(true)
    setShowConfirmForm(false)
  }

  const onChangeVehicle = () =>
    setTryChangingVehicle(true)

  const onShowConfirmForm = () => { // assign form - proceed button 
    setShowAssignForm(false)
    setShowConfirmForm(true)
  }
  const onShowEditDriverForm = () => { // assign form - back button 
    setShowAssignForm(false)
    setShowConfirmForm(false) 
  }
  
  const onBackConfirmForm = () => { // confirm form - back button 
    setShowAssignForm(true)
    setShowConfirmForm(false) 
  }

  const onTerminateDriver = () => setOpenDeleteModal(true)

  const onClose = navigate(constants.PATH_ALL)
  
  const onDelete = () =>
    onRemove(id, fleetIds)
  
  const hideButtons = showAssignForm || showConfirmForm || data.status === 'Terminated' || isAlfaDown
  
  const onModalClose = () => { // unsaved changes prompt if any values has changed
    let val = JSON.stringify({
      thirdPartyNumber: null,
      accountName: null,
      agreementNo: null,
      ...values,
    })
    let originalVal = JSON.stringify({
      thirdPartyNumber: null,
      accountName: null,
      agreementNo: null,
      ...originalValues,
    })

    return data.status !== 'Terminated' && val !== originalVal   
      ? setOpenUnsavedChangesModal(true)
      : onClose()
  }

  useEffect(() => {
    flags.delete === 'processed' && onClose()
  }, [flags.delete, onClose])

  return (
    <Dialog
      open autoSize
      size="md"
      aria-labelledby="Driver"
      title={title}
      action={data.agreementNo ? 'Change Vehicle' : 'Assign Vehicle'}
      onAction={onChangeVehicle}
      hideAction={hideButtons}
      disableButtons={disableButtons}
      secondaryAction="Terminate Driver"
      onSecondaryAction={onTerminateDriver}
      hideSecondaryAction={hideButtons}
      disablePrimaryButtonAction={disableButtons}
      disableSecondaryAction={disableTerminateDriverFeatureToggle || disableButtons}
      // errorMessage={loadingErrorMessage}
      loading={isLoading || isSaving}
      onClose={onModalClose}>
      {data && (
        <>
          {showAssignForm ? (    
            <AssignForm
              values={values}
              setValues={setValues}
              disabled={isLoading}
              selectedVehicle={selectedVehicle}
              setSelectedVehicle={setSelectedVehicle}
              searchHistory={searchHistory}
              setSearchHistory={setSearchHistory}
              originalValues={originalValues}
              onBack={onShowEditDriverForm}
              onLookup={onLookup}
              onProceed={onShowConfirmForm}
              onFetchContract={onFetchContract}
            />
          ) : showConfirmForm ? (
            <ConfirmForm
              values={values}
              setValues={setValues}
              onChange={onChange}
              selectedVehicle={selectedVehicle}
              disabled={isLoading}
              onSubmit={onSave}
              onBack={onBackConfirmForm}
              onClose={onModalClose}
              hasVehicleAlready={!!data.registration}
              onLookup={onLookup}
            />
          ) : (
            <Form
              values={values}
              setValues={setValues}
              disabled={isLoading}
              onClose={onModalClose}
              onSubmit={onSave}
              onChange={onChange}
              driverName={driverName}
              tryChangingVehicle={tryChangingVehicle}
              setTryChangingVehicle={setTryChangingVehicle}
              onShowAssignForm={onShowAssignForm}
              saveStatus={flags.update}
              doneFetchingVehicle={doneFetchingVehicle}
              setDoneFetchingVehicle={setDoneFetchingVehicle}
              vehicleDetails={vehicleDetails}
              setVehicleDetails={setVehicleDetails}
              originalValues={originalValues}
              setOriginalValues={setOriginalValues}
              onLookup={onLookup}
              apiResponse={apiResponse}
              userRoles={userRoles}
            />
          )}
        </>
      )}

      {/* for terminate driver confirmation */}
      <StyledModal
        flag={flags.delete}
        open={openDeleteModal}
        title={`Terminate Driver`}
        onCancel={() => setOpenDeleteModal(false)}
        onConfirm={onDelete}>
          <strong>IMPORTANT: Please confirm only if {data?.driver || 'the driver'} no longer works for your organisation.</strong>
          <br /><br />
          Please <strong>CANCEL</strong> if you are simply changing a driver/vehicle combination and use <strong>CHANGE VEHICLE</strong>.
      </StyledModal>

      {/* unsave changes prompt */}
      <StyledModal
        open={openUnsavedChangesModal}
        title={`Confirm Close`}
        onCancel={() => setOpenUnsavedChangesModal(false)}
        onConfirm={onClose}>
        Any changes will not be saved. Are you sure you want to continue?
      </StyledModal>
    </Dialog>
  )
}

Edit.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  data: PropTypes.object,
  flags: PropTypes.object.isRequired,
  apiResponse: PropTypes.string,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,

  // func
  onFetch: PropTypes.func.isRequired,
  onFetchContract: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSaveTemporary: PropTypes.func.isRequired,

}

export default Edit
