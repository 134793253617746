import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ReactGA from 'react-ga4'
import { useRouter } from 'utils'
import { getAppConfig } from 'app-config'
import { useEnabledFeatureToggleList } from 'features/settings'


const GoogleAnalytics4 = ({ options = {} }) => {
  const config = getAppConfig()
  const [tracking, setTracking] = useState(false)

  const featureToggleList = useEnabledFeatureToggleList()
  const tfp1908FeatureToggle = featureToggleList.includes('TFP1908')

  const {
    location:
    {
      pathname,
      search
    }
  } = useRouter()

  const trackPage = useCallback(() => {
    const page = pathname + search
    const { location } = window

    if (tfp1908FeatureToggle)
    {
      // custom dimension set? no clue what for
      ReactGA.set({
        page,
        location: `${location.origin}${page}`,
        ...options,
      })

      ReactGA.send({ hitType: "pageview", page: page });
      console.log('[GA4] pageview tracked: ', page)
    }    
  }, [options, pathname, search, tfp1908FeatureToggle])

  useEffect(() => {
    if (tfp1908FeatureToggle && tracking) {
      trackPage()
    }
  }, [pathname, search, tracking, trackPage, tfp1908FeatureToggle])

  useEffect(() => {
    if (tfp1908FeatureToggle)
    {
      ReactGA.initialize(config.app.ga4)
      console.log('[GA4] initalized: ', config.app.ga4)
      
      setTracking(true)
    }

  }, [config.app.ga, config.app.ga4, config.env, setTracking, tfp1908FeatureToggle])

  return null
}

GoogleAnalytics4.propTypes = {
  options: PropTypes.object
}

export default GoogleAnalytics4
