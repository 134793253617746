import { capitalize } from 'utils'


export const makeListRequest = (state, fleetIds) => {

  const options = {
    requestId: Date.now(),
  }

  if ( ! state || ! fleetIds) {
    return options
  }

  let queryParams = {
    FleetId: fleetIds.join(','),
  }

  if (state.pagination) {
    queryParams.Page = state.pagination.offset + 1
    queryParams.PageSize = state.pagination.rows
  }

  if (state.columnsSort && state.columnsSort.column) {
    queryParams.OrderByColumn = state.columnsSort.column
    queryParams.OrderByDirection = state.columnsSort.direction === 'asc' ? 1 : 0
  }

  if (state.searchKey && state.searchKey.length > 1) {
    queryParams.Search = state.searchKey
  }

  if (state.filtered) {
    let filters = {}
    Object
      .keys(state.filtered)
      .filter(key => state.filtered[key])
      .forEach(key => {

        switch(key) {
          case 'matured':
          case 'expired':
          case 'overdue':
            filters[key] = state.filtered[key] // for dashboard column filters
            break;
          default:
            filters[`Selected${capitalize(key)}`] = state.filtered[key]
            break;
        }
        
      })

    queryParams = {
      ...queryParams,
      ...filters,
    }
  }

  /*
    To differentiate current month filter from dashboard and report page
    dashboard - current month filter requires records from today onwards
    report page - current month filter requires all records from that month
  */
   if(state.isDashboardFilter) {
     queryParams.showTodayOnwards = true
   }

  return {
    ...options,
    queryParams,
  }
}
