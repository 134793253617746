import { combineEpics, ofType } from 'redux-observable'
import { makeActionRequestEffect } from 'effects'
import { of } from 'rxjs'
import { mergeMap, filter } from 'rxjs/operators'
import * as fromFleetTypes from 'features/fleet/actions/types'
import * as fromDriverTypes from 'features/drivers/actions/types'
import * as fromModuleTypes from './actions/types'
import { getAppConfig } from 'app-config'
import { constants } from './module'
import join from 'url-join'


export const createEffects = (moduleActions) => {

  const appConfig = getAppConfig()

  // to refresh service report on update/request variation from shared tfs fleet modal component
  const refetchEffect = (action$, state$) =>
    action$.pipe(
      ofType( 
        fromFleetTypes.UPDATE_FULFILLED,
        fromFleetTypes.DELETE_VARIATION_FULFILLED,
        fromDriverTypes.CREATE_FULFILLED,
        fromDriverTypes.UPDATE_FULFILLED,
        fromDriverTypes.DELETE_FULFILLED,
        fromDriverTypes.TOGGLE_POOL_VEHICLE_FULFILLED,
        fromDriverTypes.UNASSIGN_VEHICLE_FULFILLED,
        fromModuleTypes.ADD_SERVICE_BOOKING_FULFILLED,
        fromModuleTypes.COMPLETE_SERVICE_BOOKING_FULFILLED,
        fromModuleTypes.CANCEL_SERVICE_BOOKING_FULFILLED,
      ),
      filter(() => window.location.pathname.includes(constants.PATH_ALL)), // to trigger on km tracking report page only
      mergeMap(() => of(
        moduleActions.refetch(),
      ))
    )
  
  const fetchServiceBookingEffect = makeActionRequestEffect({
    type: fromModuleTypes.FETCH_SERVICE_BOOKING,
    url: join(appConfig.app.api, 'serviceBooking/serviceBooking'),
    onSuccess: moduleActions.fetchServiceBookingFulfilled,
    onError: moduleActions.fetchServiceBookingError,
  })
  
  const addServiceBookingEffect = makeActionRequestEffect({
    type: fromModuleTypes.ADD_SERVICE_BOOKING,
    url: join(appConfig.app.api, 'serviceBooking/bookAService'),
    verb: 'post',
    onSuccess: moduleActions.addServiceBookingFulfilled,
    onError: moduleActions.addServiceBookingError,
  })
  
  const cancelServiceBookingEffect = makeActionRequestEffect({
    type: fromModuleTypes.CANCEL_SERVICE_BOOKING,
    url: join(appConfig.app.api, 'serviceBooking/cancelServicebooking'),
    verb: 'post',
    onSuccess: moduleActions.cancelServiceBookingFulfilled,
    onError: moduleActions.cancelServiceBookingError,
  })
  
  const submitServiceRecordEffect = makeActionRequestEffect({
    type: fromModuleTypes.SUBMIT_SERVICE_RECORD,
    url: join(appConfig.app.api, 'serviceBooking/completeServiceBooking'),
    verb: 'post',
    onSuccess: moduleActions.submitServiceRecordFulfilled,
    onError:  moduleActions.submitServiceRecordError,
  })

  const completeServiceBookingEffect = makeActionRequestEffect({
    type: fromModuleTypes.COMPLETE_SERVICE_BOOKING,
    url: join(appConfig.app.api, 'serviceBooking/completeServiceBooking'),
    verb: 'post',
    onSuccess: moduleActions.completeServiceBookingFulfilled,
    onError:  moduleActions.completeServiceBookingError,
  })

  return combineEpics(
    refetchEffect,
    fetchServiceBookingEffect,
    addServiceBookingEffect,
    cancelServiceBookingEffect,
    completeServiceBookingEffect,
    submitServiceRecordEffect,
  )
}
