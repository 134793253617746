import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { colors, Typography, Tooltip, IconButton } from '@material-ui/core'
import { AntSwitch } from 'frame/components'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'relative',
    margin: 0,
    padding: 0,
    width: '100%',
    height: '100%',
    border: 'none',
    background: 'none',
    textAlign: 'inherit',
    boxShadow: 'none',
    outline: 0,
    cursor: props => props?.noSort ? 'auto' : 'pointer',
    '&:focus': {
      border: 'none',
      boxShadow: 'none',
      outline: 0,
    },
    '&:hover > p': {
      color: theme.palette.text.primary,
    },
  },
  center: {
    textAlign: 'center',
  },
  label: {
    flex: 1,
    fontWeight: 500,
    transition: 'all 0.3s ease-in-out',
  },
  labelActive: {
    color: theme.palette.text.primary,
  },
  arrows: {
    display: 'inline-block',
    position: 'absolute',
    marginRight: 2,
    width: '8px',
    height: '10px',
    transform: 'translateX(-12px)',
    // border: '1px solid green',
    '&::before': {
      content: '" "',
      display: 'block',
      position: 'absolute',
      top: 1,
      left: 0,
      padding: 2,
      border: 'solid',
      borderColor: colors.blueGrey[300],
      borderWidth: '0 2px 2px 0',
      transform: 'rotate(-135deg) scale(0.7)',
    },
    '&::after': {
      content: '" "',
      display: 'block',
      position: 'absolute',
      bottom: 0,
      left: 0,
      padding: 2,
      border: 'solid',
      borderColor: colors.blueGrey[300],
      borderWidth: '0 2px 2px 0',
      transform: 'rotate(45deg) scale(0.7)',
    },
  },
  arrowUpActive: {
    '&::before': {
      borderColor: theme.palette.text.primary,
    }
  },
  arrowDownActive: {
    '&::after': {
      borderColor: theme.palette.text.primary,
    }
  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(3)
  },
  toggleContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  iconButton: {
    margin: 0,
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  infoIcon: {
    width: 18,
    height: 18,
    marginLeft: '5px',
    verticalAlign: 'middle',
  },
  // tooltip styles
  arrow: {
    color: theme.palette.common.white,
    '&::before': {
      border: '1px solid #dfdfdf',
      backgroundColor: theme.palette.common.white,
      boxSizing: 'border-box',
    }
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    border: '1px solid #dfdfdf',
    borderRadius: '5px',
    padding: theme.spacing(2),
    minWidth: '400px',
  },
}))

const TableHeaderSortableLabel = ({ noSort, active, center, children, direction, onClick, showToggle, toolTip, toggleAction, toggleValue }) => {
  const classes = useStyles({ noSort })
  
  const isUp = active && direction === 'asc'
  const isDown = active && direction === 'desc'

  const handleChange = (e) => {
    toggleAction(true)
  }

  return (
    <div className={showToggle ? classes.toggleContainer : {}}>
    
      {showToggle && (
        <AntSwitch value={toggleValue} onChange={handleChange} name="sw" />
      )}
      <button className={clsx(classes.root, center ? classes.center : '')} onClick={noSort ? () => {} : onClick}>
        {!noSort && (// sorting arrow icons
          <span className={clsx(
            classes.arrows,
            isUp ? classes.arrowUpActive : '',
            isDown ? classes.arrowDownActive : '',
          )} />
        )}
        <Typography
          className={clsx(
            classes.label,
            active ? classes.labelActive : '',
          )}
          variant="body2">
          {children}

          {toolTip && (
            <Tooltip
              arrow
              placement="top"
              classes={{
                tooltip: classes.tooltip,
                arrow: classes.arrow
              }}
              title={<>
                <h3>{toolTip.title}</h3>
                <Typography>{toolTip.description}</Typography>
              </>}>
              <IconButton className={classes.iconButton} aria-label="info">
                <InfoOutlinedIcon className={classes.infoIcon} />
              </IconButton>
            </Tooltip>
          )}
        </Typography>
      </button>
    </div>
  )
}

TableHeaderSortableLabel.propTypes = {
  noSort: PropTypes.bool,
  active: PropTypes.bool,
  center: PropTypes.bool,
  showToggle: PropTypes.bool,
  toolTip: PropTypes.any,
  children: PropTypes.string,
  direction: PropTypes.string,
  onClick: PropTypes.func.isRequired,
}

export default TableHeaderSortableLabel
