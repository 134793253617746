import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Listable } from 'listable'
import { useNavigate } from 'utils'
import Row from './row'
import Filters from '../filters'
import { ChildRoutes } from '../../routes'
import { hasRoles } from 'acl'
import { useRouter } from 'utils'

import {
  actions,
  config,
  constants
} from '../../module'
import { useEnabledFeatureToggleList } from 'features/settings'


const List = ({ model, userRoles }) => {

  const {history} = useRouter()
  const linker = useNavigate()
  const featureToggleList = useEnabledFeatureToggleList()
  const userPermissionsFeatureToggle = featureToggleList.includes('UserPermissions')
  const vehicleRequestFeatureToggle = featureToggleList.includes('Vehicle request form and report (TFP-2162)')
  const renderItem = useCallback((item, index, itemProps) => {

    const onView = linker(constants.PATH_SINGLE, item.id)

    return (
      <Row
        key={index}
        item={item}
        onView={onView}
        {...itemProps}/>
    )
  }, [linker])

  // Prevents re-rendering if ids or data updated - no longer needed
  const { ids, filters, ...rest } = model
  if (!vehicleRequestFeatureToggle) {
    history.push("/");
    return null;
  }
  return (
    <Listable
      actions={actions}
      config={config}
      filters={Filters}
      model={rest}
      renderItem={renderItem}
      routes={ChildRoutes}
      
      primaryButton={{
        label: 'Request Vehicle Order',
        action: linker(constants.PATH_ADD),
        hide : userPermissionsFeatureToggle && hasRoles(['fleetcoordinator'], userRoles)
      }}
    />
  )
}

List.propTypes = {
  model: PropTypes.object.isRequired,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
}

const mapStateToProps = ({ requestVehicleSummary, settings }) => ({
  model: requestVehicleSummary,
  userRoles: settings.roles
})

const mapDispatchToProps = {}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(List)
