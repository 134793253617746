import React, { useEffect, useState, useRef } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { capitalize } from 'utils'
import { Button, Card, CardContent, Grid, TextField, Typography } from '@material-ui/core'
import { Alert, DateRangePicker, LookupField, SingleSelectValidator, ValueText, RegistrationLookupWrapper } from 'frame/components'
import { createDefaultModel } from '../../module'
import { hasRoles } from 'acl'
import { useEnabledFeatureToggleList } from 'features/settings'
import { getReliefVehicleTypes } from 'utils/reliefVehicleTypes'


const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3),
  },
  error: {
    marginBottom: theme.spacing(4),
  },
  content: {
    padding: theme.spacing(3, 1.2, 3, 1.2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
  },
  fieldHeader: {
    marginTop: theme.spacing(5),
  },
  fieldGroup: {
    margin: theme.spacing(3, 0, 2, 0),
  },
  actions: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    }
  },
  button: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    minWidth: 180,
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
    }
  },
  info: {
    ...theme.typography.body2,
  },
  disabled: {
    color: theme.palette.text.tertiary,
  },
  textArea: {
    '&::placeholder': { color: theme.palette.text.tertiary }
  },
}))

const Form = ({
  relatedContract,
  disabled,
  error,
  fetchContractStatus,
  fleetIds,
  onClose,
  onFetchContract,
  onSubmit,
  onLookup,
  errorMessage,
  userRoles
}) => {

  const form = useRef()
  const classes = useStyles()
  const [contract, setContract] = useState({})
  const [vehicle, setVehicle] = useState()
  const [values, setValues] = useState(createDefaultModel())
  const featureToggleList = useEnabledFeatureToggleList()
  const userPermissionsFeatureToggle = featureToggleList.includes('UserPermissions')
  const disableButtons = userPermissionsFeatureToggle && hasRoles(['fleetcoordinator'], userRoles)

  const [showStartDateError, setShowStartDateError] = useState(false)
  const [showEndDateError, setShowEndDateError] = useState(false)

  const lookupContractData = relatedContract?.[vehicle?.agreementNo] || null
  const locked = disabled || ! contract?.agreementNo ? true : false

  const hasContract = !!contract?.agreementNo

  const onRegoChange = (option) => {
    setContract({})
    setVehicle(option)
    if (option?.agreementNo) {
      onFetchContract(option.agreementNo, fleetIds)
    }
  }

  const onInputChange = ({ target: { id, name, value } }) =>
    setValues({ ...values, [id || name]: value })

  const onSelectChange = (id) => (value) =>
    setValues({ ...values, [id]: value })

  const onDateRangeChange = (startDate, endDate) => {
    // console.log('onDateRangeChange', { startDate, endDate })
    setShowStartDateError(false)
    setShowEndDateError(false)
    setValues({
      ...values,
      collectionDate: startDate || null,
      bookedReturnDate: endDate || null,
    })
  }

  const onSubmitValidation = () => {
    if (form.current) {
      values.collectionDate === null && setShowStartDateError(true)
      values.bookedReturnDate === null && setShowEndDateError(true)
      form.current.submit()
    }
  }

  const onSubmitForm = () => {
    if (values.collectionDate && values.bookedReturnDate) {
      onSubmit(values)
    }
  }

  const tfp1865FeatureToggle = featureToggleList.includes('TFP1865');
  let vehicleTypes = getReliefVehicleTypes(tfp1865FeatureToggle);

  const getMaxReturnDate = () => {
    if (hasContract && contract.maturityDate) {
      return moment(contract.maturityDate)
    }
    return null
  }

  // Similar to getDerivedStateFromProps
  // We need to update local state here once we receive
  // the corresponding contract.
  // So we set the contract and add appropriate contract values to the
  // relief vehicle request as is needed.
  useEffect(() => {
    if (lookupContractData?.agreementNo) {
      console.log('[lookupContractData]', { vehicle, lookupContractData })
      setContract(lookupContractData)
      setValues({
        ...values,
        driver: lookupContractData.driver || '',
        driverMobile: lookupContractData.driverMobile || '',
        driverEmail: lookupContractData.driverEmail || '',
        agreementNo: lookupContractData.agreementNo || '',
        thirdPartyNumber: lookupContractData.thirdPartyNumber || '',
        registration: lookupContractData.registration || '',
      })
    }
  }, [lookupContractData]) // eslint-disable-line

  const expiredMaturityDate = moment(contract.maturityDate) <= moment()

  // to ensure description are in same cases
  const correctCases = (str) =>
    capitalize(str.trim().toLowerCase())

  return (
    <ValidatorForm autoComplete="off" noValidate ref={form} onSubmit={onSubmitForm}>
      <Card>
        <CardContent className={classes.content}>
          {error && (
            <Alert className={classes.error}>
              {errorMessage || 'Something went wrong while trying to submit relief vehicle request.' }
            </Alert>
          )}

          <Typography gutterBottom variant="h4">
            Vehicle
          </Typography>

          <div className={classes.info}>
            Please select the vehicle you wish to book a relief vehicle for.
          </div>

          <div className={classes.fieldGroup}>
            <RegistrationLookupWrapper
              lookupContractData={contract}
              fetchContractStatus={fetchContractStatus}>
              <LookupField
                id="registration"
                disabled={disabled || disableButtons}
                fullWidth
                label="Vehicle Registration"
                lookupPath="vehicleregistration"
                value={vehicle}
                variant="outlined"
                validators={['required']}
                errorMessages={['This field is required']}
                onChange={onRegoChange}
                onLookup={onLookup}
                resourceModel="requestReliefVehicles"/>
            </RegistrationLookupWrapper>
          </div>

          {hasContract && (
            <>
              <div className={classes.fieldGroup}>
                <Grid container spacing={1}>

                  <Grid item sm={6} xs={12}>
                    <ValueText
                      large
                      label="Registration"
                      value={contract.registration || '-'} />
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <ValueText
                      large
                      label="Description"
                      value={contract.make && contract.model ?
                        correctCases(contract.make) + ' ' + correctCases(contract.model)
                        : '-'}/>
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <ValueText
                      large
                      label="Cost Centre"
                      value={contract.costCentre || '-'} />
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <ValueText
                      large
                      label="Maturity Date"
                      format="Do MMMM YYYY"
                      value={contract.maturityDate || '-'} />
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item sm={6} xs={12}>

                    <ValueText
                      large
                      label="Driver"
                      value={contract.driver || '-'} />
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <ValueText
                      large
                      label="Controller"
                      value={contract.controller || '-'} />
                  </Grid>

                </Grid>
              </div>
            </>
          )}

          <div className={classes.fieldHeader}>
            <Typography
              className={clsx(locked ? classes.disabled : '')}
              gutterBottom
              variant="h4">
              Booking details
            </Typography>
            <Typography
              className={clsx(locked ? classes.disabled : '')}
              variant="body2">
              Please complete all required fields.
            </Typography>
          </div>
          <div className={classes.fieldGroup}>
            <Grid container spacing={4}>

              <Grid item sm={6} xs={12}>
                <SingleSelectValidator
                  noNone
                  id="vehicleType"
                  disabled={locked || expiredMaturityDate}
                  label="Vehicle Type"
                  options={vehicleTypes}
                  value={values.vehicleType || ''}
                  validators={['required']}
                  errorMessages={['This field is required']}
                  onChange={onSelectChange('vehicleType')} />
              </Grid>

              {/* <DatePicker
                  id="collectionDate"
                  className={classes.field}
                  disabled={locked || expiredMaturityDate}
                  disablePast
                  format="Do MMMM YYYY"
                  fullWidth
                  inputVariant="outlined"
                  maxDate={getMaxCollectionDate()}
                  label="Start Date"
                  value={values.collectionDate}
                  variant="outlined"
                  validators={['required']}
                  errorMessages={['Date is required']}
                  onChange={onSelectChange('collectionDate')}
                /> */}

              {/* <DatePicker
                id="bookedReturnDate"
                className={classes.field}
                disabled={locked || expiredMaturityDate}
                disablePast
                format="Do MMMM YYYY"
                fullWidth
                inputVariant="outlined"
                label="Return Date"
                minDate={getDefaultReturnMinDate()}
                maxDate={getMaxReturnDate()}
                value={values.bookedReturnDate}
                variant="outlined"
                validators={['required']}
                errorMessages={['Date is required']}
                onChange={onSelectChange('bookedReturnDate')}
              /> */}

              <Grid item sm={6} xs={12}>
                <TextValidator
                  id="pickupAddress"
                  className={classes.field}
                  disabled={locked || expiredMaturityDate}
                  fullWidth
                  label="Pickup Address"
                  value={values.pickupAddress || ''}
                  variant="outlined"
                  validators={['required']}
                  errorMessages={['This field is required']}
                  onChange={onInputChange} />
              </Grid>

              <Grid item sm={12} xs={12}>
                <DateRangePicker
                  allowSameDateSelection
                  disabled={locked || expiredMaturityDate}
                  format="Do MMMM YYYY"
                  values={[values.collectionDate, values.bookedReturnDate]}
                  startMinDate={moment()}
                  endMaxDate={getMaxReturnDate()}
                  onDateRangeChange={onDateRangeChange}
                  showStartDateRequiredError={showStartDateError}
                  showEndDateRequiredError={showEndDateError}
                  placeholderText="DD/MM/YYYY"/>
              </Grid>

              <Grid item sm={12} xs={12}>
                <TextField
                  id="comments"
                  className={classes.field}
                  InputProps={{ classes: { input: locked ? classes.textArea : '' } }}
                  disabled={locked || expiredMaturityDate}
                  fullWidth
                  // label="Comments"
                  placeholder="Please include contact name, email address and mobile number"
                  multiline
                  rows={5}
                  value={values.comments || ''}
                  variant="outlined"
                  onChange={onInputChange} />
              </Grid>
            </Grid>
          </div>
        </CardContent>
      </Card>

      <div className={classes.actions}>
        <Button
          className={classes.button}
          color="primary"
          variant="contained"
          disabled={locked || expiredMaturityDate}
          onClick={onSubmitValidation}>
          Request Relief Vehicle
        </Button>
        <Button
          className={classes.button}
          disabled={locked}
          variant="contained"
          onClick={onClose}>
          Cancel
        </Button>
      </div>
    </ValidatorForm>
  )
}

Form.propTypes = {
  relatedContract: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  fetchContractStatus: PropTypes.string,
  fleetIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClose: PropTypes.func.isRequired,
  onFetchContract: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onLookup: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,

}

export default Form
