import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, useTheme } from '@material-ui/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Grid, Button } from '@material-ui/core'
import { Dialog } from 'frame/components'
import { useRouter, useDrillDownModalOnClose } from 'utils'
import DrillDownRoutes from './routes'
import NoDataView from './no-data-view'

import { constants } from '../../module'
import { useEnabledFeatureToggleList } from 'features/settings'

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(6),
  },
  button: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    minWidth: 180,
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
    }
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    }
  }
}))

const DrillDownModal = ({
  agreementNo,
  data,
  fleetIds,
  fleetFlags,
  serviceBookingFlags,
  errorMessage,

  // func
  onFetch,
  onFetchDriver,
  onReset,
  onResetDriverFlags
}) => {

  const featureToggleList = useEnabledFeatureToggleList()
  const drillDownFbtTabFeatureToggle = featureToggleList.includes('DrillDownFbtTab')

  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'))

  const { match, location } = useRouter()
  const classes = useStyles()
  
  const isContractTab = location.pathname.replace(match.url, '') === '' || location.pathname.replace(match.url, '') === constants.PATH_CONTACT
  const isServiceBookingTab = location.pathname.includes(constants.PATH_SERVICE_BOOKINGS)
  const isVehicleTab = location.pathname.includes(constants.PATH_VEHICLE)
  const isFbtTab = location.pathname.includes(constants.PATH_FBT)

  const onClose = useDrillDownModalOnClose()

  const onCloseHandler = () => {
    onClose()
    onResetDriverFlags() // clear driver flags
  }

  useEffect(() => {
    onReset() // reset flags
    onFetch(agreementNo, fleetIds)
    onFetchDriver(agreementNo, fleetIds)
  }, [agreementNo, fleetIds]) // eslint-disable-line

  const isLoading = fleetFlags.fetch === 'processing' && ! data

  const isSaving = fleetFlags.update === 'processing' || fleetFlags.deleteVariation === 'processing'
                  || serviceBookingFlags.addServiceBooking === 'processing' || serviceBookingFlags.cancelServiceBooking === 'processing' || serviceBookingFlags.completeServiceBooking === 'processing'
   
  const hasErrored = fleetFlags.fetch === 'error' || ( ! isLoading && ! data)

  const isNonTfmVehicle = agreementNo.includes('UNF')

  const title = data?.accountName && data?.registration
    ? `${data?.accountName} - ${data?.registration}`
    : ``

  const tabsConfig = [
    {
      label: 'Contract',
      path: `${match.url}${constants.PATH_CONTACT}`,
      visible: true,
    }, {
      label: 'Driver',
      path: `${match.url}${constants.PATH_DRIVER}`,
      visible: true,
    }, {
      label: 'Vehicle',
      path: `${match.url}${constants.PATH_VEHICLE}`,
      visible: true,
    }, {
      label: 'Variations',
      path: `${match.url}${constants.PATH_VARIATIONS}`,
      visible: !isNonTfmVehicle,
    }, {
      label: 'Service Bookings',
      path: `${match.url}${constants.PATH_SERVICE_BOOKINGS}`,
      visible: !isNonTfmVehicle,
    }, {
      label: 'FBT',
      path: `${match.url}${constants.PATH_FBT}`,
      visible: drillDownFbtTabFeatureToggle
    }
  ].filter(x => x.visible)

  return (
    <Dialog
      open
      size="md"
      minHeight={720}
      headerMinHeight={126}
      aria-labelledby="TFS Fleet"
      title={title}
      loading={isLoading || isSaving}
      onClose={onCloseHandler}
      tabsConfig={tabsConfig}>
      {data && (
        <Grid className={classes.root} container>

          <Grid item xs={12}>
            <DrillDownRoutes />
          </Grid>
          {!isContractTab && !isServiceBookingTab && !isFbtTab && !isVehicleTab && (
            <Grid item xs={12} className={classes.center}>
              <Button fullWidth={mobileView} className={classes.button} variant="contained" onClick={onCloseHandler}>
                Close
              </Button>
            </Grid>
          )}
        </Grid>
      )} 
      
      {hasErrored && (
        <Grid className={classes.root} container>
          <Grid item xs={12}>
            <NoDataView errorMessage={errorMessage} agreementNo={agreementNo}/>
          </Grid>
          <Grid item xs={12} className={classes.center}>
            <Button fullWidth={mobileView} className={classes.button} variant="contained" onClick={onCloseHandler}>
              Close
            </Button>
          </Grid>
        </Grid>
      )}
    </Dialog>
  )
}

DrillDownModal.propTypes = {  
  agreementNo: PropTypes.string,
  data: PropTypes.object,
  fleetIds: PropTypes.array,
  fleetFlags: PropTypes.object,
  serviceBookingFlags: PropTypes.object,
  errorMessage: PropTypes.string,
  // func
  onFetch: PropTypes.func,
  onFetchDriver: PropTypes.func,
  onReset: PropTypes.func,
  onResetDriverFlags: PropTypes.func, 
}

export default DrillDownModal
