import { combineEpics } from 'redux-observable'
import { mergeMap } from 'rxjs/operators'
// import { of } from 'rxjs'
import { makeActionRequestEffect } from 'effects'
import join from 'url-join'
import { getAppConfig } from 'app-config'
import * as actions from './actions'
import * as types from './constants'
// import { actions as fromSettingsActions } from 'features/settings'


export const createEffects = (moduleActions) => {

  const appConfig = getAppConfig()

  const requestFeatureTogglesEffect = makeActionRequestEffect({
    type: types.REQUEST_FEATURE_TOGGLE_LIST,
    url: join(appConfig.app.api, '/features'),
    onSuccess: actions.requestFeatureToggleListFulfilled,
    onError: actions.requestFeatureToggleListError,
  })

  const enableFeatureEffect = makeActionRequestEffect({
    type: types.ENABLE_FEATURE,
    url: join(appConfig.app.api, '/features'),
    verb: 'put',
    onSuccess: actions.enableFeatureFulfilled,
    onError: actions.enableFeatureError,
  })

  const disableFeatureEffect = makeActionRequestEffect({
    type: types.DISABLE_FEATURE,
    url: join(appConfig.app.api, '/features'),
    verb: 'delete',
    onSuccess: actions.disableFeatureFulfilled,
    onError: actions.disableFeatureError,
    mapOperator: mergeMap, // to avoid consecutive disabled feature toggle requests getting cancelled
  })

  // const refetchFeatureTogglesEffect = makeActionRequestEffect({
  //   type: fromSettingsActions.REQUEST_ENABLED_FEATURE_TOGGLES_FULFILLED,
  //   url: join(appConfig.app.api, '/features/available'),
  //   onSuccess: actions.requestFeatureToggleListFulfilled,
  //   onError: actions.requestFeatureToggleListError,
  // })


  return combineEpics(
    requestFeatureTogglesEffect,
    enableFeatureEffect,
    disableFeatureEffect,
    // refetchFeatureTogglesEffect,
  )
}
