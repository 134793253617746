import React from 'react'
import PropTypes from 'prop-types'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, useTheme } from '@material-ui/styles'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { Card, CardContent, Typography, Button } from '@material-ui/core'
import { useDrillDownModalOnClose } from 'utils'
import { useEnabledFeatureToggleList } from 'features/settings'
import { useIsAlfaDownDisableFeatures } from 'features/drivers'


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 1.2, 3, 1.2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
      minHeight: 400,
    },
  },
  button: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    minWidth: 180,
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
    }
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    }
  }
}))

const ServiceBookingFormWarper = ({
  title,
  titleStyle,
  subtitle,
  children,
  disabled,
  onSubmit,
  onSubmitLabel,
  onCancel,
  onCancelLabel,
  onBack,
  centered,
}) => {

  const theme = useTheme()
  const classes = useStyles()
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'))
  const onClose = useDrillDownModalOnClose()
  const featureToggleList = useEnabledFeatureToggleList()

  const disableOutOfHoursFeatureToggle = featureToggleList.includes('OutOfHoursDisabled') // 'ALFA out of hours DISABLE FEATURES' limitations feature is ON
  const isAlfaDownDisableDriverFeatures = useIsAlfaDownDisableFeatures()
  const isAlfaDown = disableOutOfHoursFeatureToggle ? false : isAlfaDownDisableDriverFeatures

  return (
    <ValidatorForm autoComplete="off" noValidate onSubmit={onSubmit ? onSubmit : () => {}}>
      <Card>
        <CardContent className={classes.root}>
          <Typography  gutterBottom variant="h4" align={centered ? 'center' : 'left'} className={titleStyle}>
            {title}
          </Typography>
          <Typography variant="body1" align={centered ? 'center' : 'left'}>
            {subtitle}
          </Typography>
          
          {children}

        </CardContent>
      </Card>
      
      <div className={classes.actions}>
        {onSubmit && (
          <Button fullWidth={mobileView} color="primary" className={classes.button} variant="contained" type="submit" disabled={disabled || isAlfaDown}>
            {onSubmitLabel ? onSubmitLabel : 'Save'}
          </Button>
        )}

        {onCancel && (
          <Button fullWidth={mobileView} className={classes.button} variant="contained" onClick={onCancel} disabled={disabled || isAlfaDown}>
            {onCancelLabel ? onCancelLabel : 'Cancel'}
          </Button>
        )}

        {onBack && (
          <Button fullWidth={mobileView} className={classes.button} variant="contained" onClick={onBack} disabled={disabled}>
            Back
          </Button>
        )}

        <Button fullWidth={mobileView} className={classes.button} variant="contained" onClick={onClose}>
          Close
        </Button>
      </div>
    </ValidatorForm>
  )
}

ServiceBookingFormWarper.propTypes = {
  title: PropTypes.string.isRequired,
  titleStyle: PropTypes.string,
  subtitle: PropTypes.string.isRequired,
  children: PropTypes.any,
  disabled: PropTypes.bool,
  centered: PropTypes.bool,

  onSubmit: PropTypes.func,
  onSubmitLabel: PropTypes.string,

  onCancel: PropTypes.func,
  onCancelLabel: PropTypes.string,

  onBack: PropTypes.func,
}

export default ServiceBookingFormWarper
