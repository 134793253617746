import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { Layout } from 'frame'
import { Item, List, New } from './components'
import { constants } from './module'


export const ModuleRoutes = () => (
  <Layout>
    <Switch>
      <Route path={constants.ROUTE_ALL} component={List} />
    </Switch>
  </Layout>
)

export const ChildRoutes = () => (
  <Switch>
    <Route path={constants.ROUTE_ADD} component={New} />
    <Route path={constants.ROUTE_SINGLE} component={Item} />
  </Switch>
)

export default ModuleRoutes
