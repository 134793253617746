import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { makeStyles, useTheme } from '@material-ui/styles'
import { fetchDownloadGuides } from '../../actions'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Grid, Typography, Fade, Button } from '@material-ui/core'
import PdfPreview from './pdf-preview'
import clsx from 'clsx'

// icons
import GetAppIcon from '@material-ui/icons/GetApp'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 'bold',
    margin: theme.spacing(3, 0, 1, 0),
  },
  tabs: { margin: theme.spacing(2, 0, 1, 0) },
  tabSeparator: { color: '#BABABA', margin: theme.spacing(0, 1) },
  activeTab: { color: `${theme.palette.black} !important` },
  tab: {
    color: '#BABABA',
    fontSize: '14px',
    fontWeight: 'bold',
    letterSpacing: 0,
    lineHeight: '19px',
    '&:hover': { color: theme.palette.black, cursor: 'pointer' }
  },
  guideImage: {
    zIndex: 999,
    height: '150px',
    maxWidth: '100%',
    width: 'auto',
  },
  guideTitle: {
    textAlign: 'left',
    margin: '10px 0 10px 10px',
    color: '#01B6B1',
    fontWeight: 'bold',
    [theme.breakpoints.down(1680)]: { margin: '10px 0 10px 0' },
    [theme.breakpoints.down(1450)]: { textAlign: 'center' },
    [theme.breakpoints.down('sm')]: { textAlign: 'left' },
  },
  guideButtonContainer: {
    textAlign: 'left',
    [theme.breakpoints.down(1450)]: { textAlign: 'center' },
    [theme.breakpoints.down('sm')]: { textAlign: 'left' },
  },
  guideButton: {
    fontWeight: 'bold',
    textTransform: 'none',
    color: theme.palette.brand,
  },
  navigationButtons: {
    borderRadius: '5px',
    border: '2px solid #e1e3e5',
    padding: theme.spacing(2),
    height: '100%',
  },
  navButton: {
    fontSize: 30,
    cursor: 'pointer',
    color: theme.palette.black,
  },
}))

const DownloadCarousel = ({ downloadGuides, onFetchDownloadGuides }) => {

  const theme = useTheme()
  const classes = useStyles()
  const down1450View = useMediaQuery(theme.breakpoints.down(1450))
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'))

  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [activeIndex, setActiveIndex] = useState(0)
  const [useFallbackCover, setUseFallbackCover] = useState(false)

  const tabs = downloadGuides.length > 0
    ? Array.from(new Set([ ...downloadGuides.map(x => x.group) ]))
    : [] 

  const carouselItems = downloadGuides.filter(x => x.group === tabs[activeTabIndex])

  useEffect(() => {
    onFetchDownloadGuides()
  }, [onFetchDownloadGuides])

  const onTabSelect = (index) => () => {
    setActiveTabIndex(index)
    setActiveIndex(0) // reset carousel
  }

  // carousel navigation
  const onNext = () => setActiveIndex(activeIndex + 1 < carouselItems.length ? activeIndex + 1 : 0)
  const onPrevious = () => setActiveIndex(activeIndex - 2 > -1 ? activeIndex - 1 : carouselItems.length - 1)

  return (
    <>
      {carouselItems && carouselItems.length > 0 ? (
        <>
          <Typography variant="body2" className={classes.tabs}>
          {tabs.map((tabName, index) => (
            <span key={`download-tab-${index}`}>

              <span
                role="button"
                className={clsx(index === activeTabIndex ? classes.activeTab : '', classes.tab)}
                onClick={onTabSelect(index)}>
                {tabName}
              </span>

              {index !== tabs.length - 1 && (
                <span className={classes.tabSeparator}>|</span>
              )}
              {mobileView && tabName.length > 20 && <br />}
            </span>
          ))}
        </Typography>

        <Grid container className={classes.navigationButtons}>

          <Grid item xs={1} style={{ margin: 'auto 0' }}>
            {carouselItems.length > 1 && (
              <ArrowBackIosIcon onClick={onPrevious} className={classes.navButton} />
            )}
          </Grid>

          <Grid item xs={10}>
            
            {carouselItems.map(({ title, uri }, index) => activeIndex === index && (
              <Fade in={true} key={`guide-${index}`}>          
                <Grid container spacing={3}>
          
                    <Grid item xs={12}>
                      <Grid container spacing={1}>

                        <Grid item xs={12} sm={6} md={12} lg={down1450View ? 12 : 6} style={{ textAlign: 'center' }}>
                          {useFallbackCover ? ( // fallback if invalid src - reads & displays pdf page 1 as cover
                            <PdfPreview file={uri} />
                          ) : (
                            <img
                              className={classes.guideImage}
                              src={`/static/images/guides/${encodeURIComponent(title)}.png`}
                              alt={`${title} cover`}
                              onError={() => setUseFallbackCover(true)}
                            />
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={12} lg={down1450View ? 12 : 6}>
                          <Typography variant="h5" className={classes.guideTitle}>
                            {title}
                          </Typography>
                          <div className={classes.guideButtonContainer}>
                            <a href={uri} target="_blank" rel="noopener noreferrer" download style={{ textDecoration: 'none' }}>
                              <Button className={classes.guideButton} endIcon={<GetAppIcon>Download</GetAppIcon>}>
                                Download
                              </Button>
                            </a>
                          </div>
                        </Grid>

                      </Grid>
                    </Grid>

                  </Grid>
              </Fade>
            ))}
          </Grid>

          <Grid item xs={1} style={{ textAlign: 'right', margin: 'auto 0' }}>
            {carouselItems.length > 1 && (
              <ArrowForwardIosIcon onClick={onNext} className={classes.navButton} />
            )}
          </Grid>

        </Grid>
        </>
      ) : (
        <Typography variant="body2" className={classes.tabs}>
          No download guides available.
        </Typography>
      )}
    </>
  );
}

DownloadCarousel.propTypes = {
  downloadGuides: PropTypes.array,
  onFetchDownloadGuides: PropTypes.func.isRequired,
}

const mapStateToProps = ({ homepage }) => ({
  downloadGuides: homepage?.downloadGuides,
})

const mapDispatchToProps = (dispatch) => ({
  onFetchDownloadGuides: () => dispatch(fetchDownloadGuides()),
})

export default connect(mapStateToProps, mapDispatchToProps)(DownloadCarousel)
