import React, { Fragment, useContext } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { TextValidator } from 'react-material-ui-form-validator'
import { SingleSelect, RowValueText } from 'frame/components'
import { FormContext } from '../../form'
import { FormCta } from '../form-cta'
import { NewVehicleSummaryContext } from '../../new'
import { DriverDetailsContext } from '.'

export const ViewFinal = () => {
  const parentContext = useContext(NewVehicleSummaryContext)
  const formContext = useContext(FormContext)
  const driverDetailsContext = useContext(DriverDetailsContext)
  const {
    onSelectChange,
    onInputChange,
    values,
    selectedDriver
  } = formContext

  const {
    setView
  } = driverDetailsContext

  return (
    <Fragment>
      <Fragment>
        <Grid item xs={12}>
          <Typography gutterBottom variant="h4">
            Driver Details
          </Typography>
        </Grid> 
        <Grid item xs={12}>
          <RowValueText label="Name">
            <Typography variant="body1">
              {selectedDriver?.text || '-'}
            </Typography>
          </RowValueText>
        </Grid>

        <Grid item xs={12}>
          <RowValueText label="Phone">
            <Typography variant="body1">
            {selectedDriver?.mobile || '-'}
            </Typography>
          </RowValueText>
        </Grid>

        <Grid item xs={12}>
          <RowValueText label="Email">
            <Typography variant="body1">
              {selectedDriver?.email || '-'}
            </Typography>
          </RowValueText>
        </Grid>

        <Grid item xs={12}>
          <RowValueText label="Driver Location">
            <SingleSelect
              noNone
              id="drivetrain"
              options={['Option 1', 'Option 2', 'Option 3']}
              value={values.drivetrain ?? ""}
              onChange={onSelectChange('drivetrain')}
              size='small'
            />
          </RowValueText>
        </Grid>

        <Grid item xs={12}>
          <RowValueText label="Delivery Location">
            <SingleSelect
              noNone
              id="deliveryLocation"
              options={['Option 1', 'Option 2', 'Option 3']}
              value={values.deliveryLocation ?? ""}
              onChange={onSelectChange('deliveryLocation')}
              size='small'
            />
          </RowValueText>
        </Grid>
      </Fragment>

      <Fragment>
        <Grid item xs={12}>
          <Typography gutterBottom variant="h4">
            Fleet Controller Details
          </Typography>
        </Grid> 
        <Grid item xs={12}>
          <RowValueText label="Name">
            <TextValidator
              id="fleetControllerName"
              fullWidth
              value={values.fleetControllerName || ''}
              inputProps={{ maxLength: 50 }}
              variant="outlined"
              validators={['required']}
              errorMessages={['This field is required']}
              onChange={onInputChange}
              size='small'
            />
          </RowValueText>
        </Grid>
        <Grid item xs={12}>
          <RowValueText label="Name">
            <TextValidator
              id="fleetControllerEmail"
              fullWidth
              value={values.fleetControllerEmail || ''}
              inputProps={{ maxLength: 50 }}
              variant="outlined"
              validators={['required']}
              errorMessages={['This field is required']}
              onChange={onInputChange}
              size='small'
            />
          </RowValueText>
        </Grid>

        <Grid item xs={12}>
          <RowValueText label="Cost Centre">
            <SingleSelect
              noNone
              id="costCentre"
              options={['Option 1', 'Option 2', 'Option 3']}
              value={values.drivetrain ?? ""}
              onChange={onSelectChange('costCentre')}
              size='small'
            />
          </RowValueText>
        </Grid>
      </Fragment>

      <Fragment>
        <Grid item xs={12}>
          <FormCta
            onProceed={() => parentContext.setActiveTab("vehicle_information")}
            onBack={() => setView("default")}
          />
        </Grid>
      </Fragment>
    </Fragment>
  )
}