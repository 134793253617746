import React, { memo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Chip from '@material-ui/core/Chip'


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    width: '100%',
    borderRadius: 4,
    border: '1px solid #ced4da',
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
    '& label': {
      background: theme.palette.background.paper,
    },
    '& .MuiInput-formControl': {
      margin: '3px 0',
    },
    '& .MuiInputBase-input': {
      background: 'none !important',
    },
    '& .MuiInput-underline:before': {
      display: 'none !important',
    },
    '& .MuiInput-underline:after': {
      display: 'none !important',
    },
    '& .MuiSelect-icon': {
      // transform: 'translateY(-4px)',
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 12px) scale(1)',
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}))

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 4

const MenuProps = {
  PaperProps: {
    style: {
      width: 250,
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
}

const getStyles = (name, values, theme) => ({
  fontWeight:
    values.indexOf(name) === -1
      ? theme.typography.fontWeightRegular
      : theme.typography.fontWeightMedium,
})

const MultiSelect = ({ id, label, options, value = [], onChange }) => {

  const classes = useStyles()
  const theme = useTheme()
  const [values, setValues] = useState(value)

  const onChangeSelection = event =>
    onChange(event.target.value)

  useEffect(() => {
    setValues(value)
  }, [value.length]) // eslint-disable-line

  return (
    <div className={classes.root}>
      <FormControl
        className={classes.formControl}
        variant="outlined">
        <InputLabel htmlFor={`select-multiple-${id}`}>{label}</InputLabel>
        <Select
          multiple
          value={values}
          onChange={onChangeSelection}
          input={<Input id={`select-multiple-${id}`} />}
          renderValue={selected => (
            <div className={classes.chips}>
              {selected.map(option => (
                <Chip
                  key={option}
                  label={option}
                  className={classes.chip} />
              ))}
            </div>
          )}
          MenuProps={MenuProps}>
          {options.map(option => (
            <MenuItem
              key={option}
              value={option}
              style={getStyles(option, values, theme)}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}

MultiSelect.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.array,
  onChange: PropTypes.func,
}

export default memo(MultiSelect)
