import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/styles'

import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid
} from '@material-ui/core'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { Brand, IEWarningPopup, Alert } from 'frame/components'
import { PasswordIndicator } from 'features/account/components'
// import { useRouter } from 'utils'
import * as fromAuth from 'auth'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(6, 2),
  },
  card: {
    width: theme.breakpoints.values.md,
    maxWidth: '100%',
    overflow: 'unset',
    display: 'flex',
    position: 'relative',
    '& > *': {
      flexGrow: 1,
      flexBasis: '50%',
      width: '50%'
    },
    '@media(max-width: 760px)': {
    // [theme.breakpoints.down('sm')]: {
      maxWidth: '400px',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: theme.spacing(3, 4, 3, 4),
    minHeight: 300,
  },
  media: {
    position: 'relative',
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    padding: theme.spacing(3),
    color: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    '@media(max-width: 760px)': {
    // [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
  },
  icon: {
    display: 'flex',
    paddingBottom: theme.spacing(3),
    color: theme.palette.white,
  },
  submitButton: {
    marginTop: theme.spacing(3),
    width: '100%'
  },
}))

const Verification = ({ token = '', onVerify, onClear, verification }) => {

  const classes = useStyles()
  const form = useRef()
  // const { history } = useRouter()
  const disabled = false

  const verifiedSuccess = verification && verification.success && verification.success
  const responseMessage = verification && verification.responseMessage

  // if(verifiedSuccess) {
  //   history.push('/dashboard')
  // }

  useEffect(() => {
    onClear() // clear any previous verification messages onload
  }, [onClear])

  // redirect from page if verification url expired
  if (token === '') {
    // return <></>
    // history.push(AUTH_LOGIN_PATH)
  } else {
    token = token.split('=')[1]
    // console.log('token', token)
    if (token.startsWith('=3D') && window.location.href.startsWith('http://localhost:')) {
      token = token.slice(2)
    }
    // console.log('token sliced2', token)
  }

  const isIE = /*@cc_on!@*/false || !!document.documentMode

  const [values, setValues] = useState({
    password: '',
    confirmPassword: '',
  })

  const hasNumber = (value) =>  /(.*\d.*)/.test(value)
  const hasLowercase = (value) => /(.*[a-z].*)/.test(value)
  const hasUppercase = (value) => /(.*[A-Z].*)/.test(value)
  const hasSymbol = (value) => /[-!$%^&@#*()_+|~=`{}[\]:";'<>?,./]/.test(value)

  useEffect(() => {

    ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
      return value !== values.password ? false : true
    })

    ValidatorForm.addValidationRule('hasNumber', (value) => hasNumber(value))
    ValidatorForm.addValidationRule('hasLowercase', (value) => hasLowercase(value))
    ValidatorForm.addValidationRule('hasUppercase', (value) => hasUppercase(value))
    ValidatorForm.addValidationRule('hasSymbol', (value) => hasSymbol(value))

    return () => {
      ValidatorForm.removeValidationRule('isPasswordMatch')
      ValidatorForm.removeValidationRule('hasNumber')
      ValidatorForm.removeValidationRule('hasLowercase')
      ValidatorForm.removeValidationRule('hasUppercase')
      ValidatorForm.removeValidationRule('hasSymbol')
    }

  }, [values.password])

  const onChange = (key) => ({ target: { value } }) =>
    setValues({
      ...values,
      [key]: value
    })

  const valid = value => value && value !== ''
  const disableSave = ! (valid(values.password)
    && valid(values.confirmPassword)
    && values.password === values.confirmPassword
    && values.password.length > 7
    && hasNumber(values.password)
    && hasLowercase(values.password)
    && hasUppercase(values.password)
    && hasSymbol(values.password))

  const onVerifyHandler = () => {
    if(valid(token) && valid(values.confirmPassword))
    onVerify({
      password: values.confirmPassword,
      token: token
    })
  }

  return (
    <div
      className={classes.root}
      title="Login">
      {isIE && <IEWarningPopup />}
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <div className={classes.header}>
            <div className={classes.icon}>
              <Brand />
            </div>
            {/* {JSON.stringify(values)} */}

            <Typography gutterBottom variant="body1">
              Please enter your password and click verify account to complete your account verification.
            </Typography>

            {/* token: {JSON.stringify(token)} */}

            <ValidatorForm
              autoComplete="off"
              noValidate
              ref={form}
              onSubmit={() => console.log('ValidatorFormonSubmit clicked')}
            >
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <br />
                <TextValidator
                  id="password"
                  name="password"
                  className={classes.field}
                  type="password"
                  disabled={disabled}
                  fullWidth
                  label="Password"
                  placeholder="Enter password"
                  value={values.password}
                  onChange={onChange('password')}
                  variant="outlined" />
              </Grid>
              <Grid item xs={12}>
                <TextValidator
                  id="confirmPassword"
                  name="confirmPassword"
                  className={classes.field}
                  type="password"
                  disabled={disabled}
                  fullWidth
                  label="Confirm Password"
                  placeholder="Enter password"
                  value={values.confirmPassword}
                  onChange={onChange('confirmPassword')}
                  variant="outlined" />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">Password Policy:</Typography>
                <PasswordIndicator
                  passwordMatch={values.password !== '' && values.password === values.confirmPassword}
                  meetsPasswordLength={values.password.length > 7}
                  hasNumber={hasNumber(values.password)}
                  hasLowercase={hasLowercase(values.password)}
                  hasUppercase={hasUppercase(values.password)}
                  hasSymbol={hasSymbol(values.password)}
                />
              </Grid>
            </Grid>
            </ValidatorForm>

          </div>
          <Button
            className={classes.submitButton}
            color="primary"
            disabled={disableSave}
            size="large"
            type="submit"
            variant="contained"
            onClick={onVerifyHandler}
          >
            Verify Account
          </Button>
          <br />
          {!verifiedSuccess && responseMessage &&  (
            <Alert className={classes.error}>
              {responseMessage}
              {
                responseMessage.toLowerCase().includes('already verified') &&
                <a href="/dashboard">Click here to login or reset your password</a>
              }
            </Alert>
          )}
        </CardContent>
        <CardMedia
          className={classes.media}
          image="/static/images/login-image.jpg"
          title="Toyota">
        </CardMedia>
      </Card>
    </div>
  )
}

Verification.propTypes = {
  token: PropTypes.string.isRequired,
  onVerify: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  verification: PropTypes.object.isRequired,
}

const mapStateToProps = ({ router, account }) => ({
  token: router.location.search,
  verification: account.verification,
})

const mapDispatchToProps = (dispatch) => ({
  onVerify: (payload) =>
    dispatch(fromAuth.verifyUser(payload)),
  onClear: () =>
    dispatch(fromAuth.verifyUserClearMessage())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Verification)
