import React from 'react'
import { makeStyles, Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: '-1px'
  }
}))

const IEWarningPopup = () => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(true)

  const handleClose = (reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }

  const link = (text, url) => (
    <a href={url} rel="noopener noreferrer" target="_blank">
      {text}
    </a>
  )

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      key="top,center"
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} variant="filled" severity="warning">
        <p className={classes.content}>
          The Toyota Fleet Portal application does not support this browser - IE11.<br />
          For the best experience please update your browser to{' '}
          {link('Google Chrome', 'https://www.google.com/chrome/')},{' '}
          {link('Mozilla Firefox', 'https://www.mozilla.org/en-US/firefox/new/')}{' or '} 
          {link('Microsoft Edge', 'https://www.microsoft.com/en-us/edge')} .
        </p>
      </Alert>
    </Snackbar>
  )
}

export default IEWarningPopup