import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { List } from './components'
import { Page404 } from 'frame/components'
import { constants } from './module'
import { useEnabledFeatureToggleList } from 'features/settings'

export const Routes = () => {
  const featureToggleList = useEnabledFeatureToggleList()
  const documentsReportFeatureToggle = featureToggleList.includes('DocumentsViewing')

  return (
    <Switch>
      <Route path={`${constants.PATH_ALL}/:id`} component={documentsReportFeatureToggle ? List : () => <Page404 withoutNav />} />
      <Route path={constants.PATH_ALL} component={documentsReportFeatureToggle ? List : () => <Page404 withoutNav />} />
    </Switch>
  )
}