import { getAppConfig } from 'app-config'
import { getAuthClient } from 'auth'
import join from 'url-join'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import axios from 'axios'

export const lookupRequest = async (fleetIds, lookupPath, searchInput, returnAll) => {
    
  const config = getAppConfig()
  const authClient = getAuthClient()
  const accessToken = await authClient.getTokenSilently()

  const headers = { Authorization: `Bearer ${accessToken}` }

  const queryParms = searchInput
    ? `?FleetId=${fleetIds.join(',')}&Search=${encodeURIComponent(searchInput)}`
    : ''

  const url = join(config.app.api, lookupPath, queryParms)
  const response = await fetch(url, { headers })
  const { data } = await response.json()

  if (returnAll) {
    return data
  }

  const result = data && (data.length === 1 || data.length < 3) ? data[0] : null
  return searchInput ? result : data
}

export const getEnabledFeatureToggleListRequest = async () => {
  const config = getAppConfig()
  const url = join(config.app.api, 'features/enabled')
  const response = await fetch(url)
  return response.json()
}

export const getBuildIdRequest = () => {
  const config = getAppConfig()
  const url = join(config.app.api, 'account/buildId')
  return fetch(url)
}

// used in registration of interest admin page
export const useUploadCsvRequest = () => {
  
  const dispatch = useDispatch()
  const config = getAppConfig()
  const authClient = getAuthClient()

  const [flag, setFlag] = useState('none')
  const [token, setToken] = useState()
  const [error, setError] = useState()
  const [response, setResponse] = useState()
  
  useEffect(() => {
    const getToken = async () => {
      const accessToken = await authClient.getTokenSilently()
      setToken(accessToken)
    }
    getToken()
  }, []) // eslint-disable-line

  const reset = () => {
    setFlag('none')
    setError(null)
    setResponse(null)
  }

  const onUploadCsv = (file) => {
    setFlag('processing')
    const formData = new FormData()
    formData.append('csvFile', file)

    axios({
      method: 'post',
      url: join(config.app.api, '/registrationOfInterest/uploadCsv'),
      data: formData,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data; application/json',
      },
    })
    .then(response => {
      const responseData = response?.data?.data || response?.data || response
      console.log(`[uploadCsvRequest] success: `, responseData)
      setFlag('processed')
      setResponse(responseData)
      dispatch({ type: 'registrationOfInterest_upload_csv_fulfilled', payload: responseData })
    })
    .catch((error) => {
      const errorMessage = error?.response?.data || error?.response || error
      console.log('[uploadCsvRequest] error: ', errorMessage)
      setFlag('error')
      setError(errorMessage)
      dispatch({ type: 'registrationOfInterest_upload_csv_error', payload: errorMessage })
    })
  }

  return { flag, onUploadCsv, response, error, reset }
}

const onMakeRequest = ({ method, url, values, onSuccessAction, onErrorAction }) => {
  const config = getAppConfig()
  console.log(`[onMakeRequest]: `, { method, url, values })
  axios({
    method: method || 'get',
    url: join(config.app.api, url),
    data: { ...values },
  })
  .then(response => {
    console.log(`[onMakeRequest] ${url} response: `, response)
    const { apiResponse, data } = response

    data?.success || data?.apiResponse?.success
      ? onSuccessAction(apiResponse?.message || data?.message || response)
      : onErrorAction(response?.data?.apiResponse?.message || response?.data || response)
  })
  .catch((error) => {
    console.log(`[onMakeRequest] ${url} error: `, error?.message || error)
    onErrorAction(error?.message || error)
  })
}

export const useFbtWebForm = () => {
  
  const dispatch = useDispatch()

  const onSubmitFbtWebForm = (values) => onMakeRequest({
    method: 'post',
    url: '/fbt/webform',
    values,
    onSuccessAction: payload => dispatch({ type: 'fbtSubmissions_fbt_webform_submit_fulfilled', payload }),
    onErrorAction: payload => dispatch({ type: 'fbtSubmissions_fbt_webform_submit_error', payload }),
  })

  const onVerifyEmail = ({ email, token }) => onMakeRequest({
    url: `/fbt/webformverifyemail?email=${encodeURI(email)}&token=${encodeURI(token)}`,
    values: {},
    onSuccessAction: payload => dispatch({ type: 'fbtSubmissions_fbt_webform_verify_email_fulfilled', payload }),
    onErrorAction: payload => dispatch({ type: 'fbtSubmissions_fbt_webform_verify_email_error', payload }),
  })

  return { onSubmitFbtWebForm, onVerifyEmail }
}