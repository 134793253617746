import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, useTheme } from '@material-ui/styles'
import { Grid, Typography } from '@material-ui/core'
import DoneIcon from '@material-ui/icons/Done'
import ErrorIcon from '@material-ui/icons/Close'
import Spinner from 'react-md-spinner'
import moment from 'moment'


const useStyles = makeStyles(theme => ({
  root: {},
  lookupResponse: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '100%',
  },
  lookupContractError: {
    marginRight: theme.spacing(1),
    color: theme.palette.error.main,
  },
  lookupSuccess: {
    marginRight: theme.spacing(1),
    color: theme.palette.success.main,
  },
  lookupContractLoading: {
    marginRight: theme.spacing(1),
  },
}))

// used in Vehicle Relocations Summary, Vehicle Relief Summary
const RegistrationLookupWrapper = ({ children, lookupContractData, fetchContractStatus }) => {

  const theme = useTheme()
  const classes = useStyles()

  const lookupContractError = fetchContractStatus === 'error'
  const lookupContractLoading = fetchContractStatus === 'processing'

  const hasContract = !!lookupContractData?.agreementNo
  const expiredMaturityDate = lookupContractData?.maturityDate && moment(lookupContractData?.maturityDate) <= moment()
  
  return (
    <Grid container spacing={4}>

      <Grid item sm={6} xs={12}>
        {children}
      </Grid>
        
      <Grid item sm={6} xs={12}>
        <div className={classes.lookupResponse}>
          {lookupContractError && (
            <>
              <ErrorIcon className={classes.lookupContractError} size={30} />
              <Typography className={classes.lookupContractError} variant="body2">
                We could not find a matching contract for this registration
              </Typography>
            </>
          )}

          {lookupContractLoading && (
            <>
              <Spinner
                className={classes.lookupContractLoading}
                singleColor={theme.palette.dark}
                size={18} />
              <Typography className={classes.lookupContractLoading} variant="body2">
                Loading contract
              </Typography>
            </>
          )}

          {hasContract && ! lookupContractLoading && ! expiredMaturityDate && (
            <DoneIcon className={classes.lookupSuccess} size={30} />
          )}
          {hasContract && ! lookupContractLoading && expiredMaturityDate && (
            <>
              <ErrorIcon
                className={classes.lookupContractError}
                size={30} />
              <Typography
                className={classes.lookupContractError}
                variant="body2">
                Sorry, the maturity date for this vehicle has expired. 
                You cannot make any bookings for this vehicle. Please try a different registration.
              </Typography>
            </>
          )}
        </div>
      </Grid>
    </Grid>
  )
}

RegistrationLookupWrapper.propTypes = {
  children: PropTypes.any,
  lookupContractData: PropTypes.object.isRequired,
  fetchContractStatus: PropTypes.string.isRequired,
}

export default RegistrationLookupWrapper
