import React from 'react'
import moment from 'moment'
import { makeStyles } from '@material-ui/styles'
import { TableCell, TableRow, Typography, Tooltip, IconButton } from '@material-ui/core'
import { Label, FleetIcon } from 'frame/components'
import { useRouter } from 'utils'
import { getDateColor, obfuscateAgreementNumber } from 'listable'
import { types } from '../../module'
import { useEnabledFeatureToggleList } from 'features/settings'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'

const useStyles = makeStyles(theme => ({
  root: {
    verticalAlign: 'text-top',
  },
  label: theme.typography.bodySmall,
  spacer: {
    margin: 0,
    padding: 0,
  },
  documentIcon: { color: '#00B6B0' },
}))

const Row = ({ item, columns }) => {

  const classes = useStyles()
  const { history, location } = useRouter()

  const featureToggleList = useEnabledFeatureToggleList()
  const documentsReportFeatureToggle = featureToggleList.includes('DocumentsViewing')
  const tfp1944FeatureToggle = featureToggleList.includes('TFP1944')
  const tfp1938FeatureToggle = featureToggleList.includes('TFP1938')
  const tfp1971FeatureToggle = featureToggleList.includes('TFP1971')

  const onViewDocumentsReport = () => { 
    history.push(`/reports/documents?registration=${item.registration}`, { label: 'Upcoming Maturities',  path: location.pathname })
  }

  return (
    <TableRow
      hover
      className={classes.root}>
      <TableCell className={classes.spacer} />
      {columns.accountName && (
        <TableCell className={classes.label}>
          <FleetIcon fleetName={item.accountName} />
        </TableCell>
      )}
      {columns.agreementNo && (
        <TableCell className={classes.label}>
          {obfuscateAgreementNumber(item.agreementNo || '-', true)}
        </TableCell>
      )}
      {columns.registration && (
        <TableCell className={classes.label}>
          {item.registration || '-'}
        </TableCell>
      )}
      {columns.assetBranch && tfp1938FeatureToggle && (
        <TableCell className={classes.label}>
          {item.assetBranch || '-'}
        </TableCell>
      )}
      {columns.assetFunction && tfp1938FeatureToggle && (
        <TableCell className={classes.label}>
            {item.assetFunction || '-'}
        </TableCell>
      )}       
      {columns.year && (
        <TableCell className={classes.label}>
          {item.year || '-'}
        </TableCell>
      )}
      {columns.make && (
        <TableCell className={classes.label}>
          {item.make || '-'}
        </TableCell>
      )}
      {columns.model && (
        <TableCell className={classes.label}>
          {item.model || '-'}
        </TableCell>
      )}
      {columns.variant && (
        <TableCell className={classes.label}>
          {item.variant || '-'}
        </TableCell>
      )}
      {columns.fuelType && (
        <TableCell className={classes.label}>
          {item.fuelType || '-'}
        </TableCell>
      )}
      {columns.driverNote && tfp1944FeatureToggle && (
        <TableCell className={classes.label}>
          <Typography color="inherit" variant="body2">
            {item.driverNote || '-'}
          </Typography>
        </TableCell>
      )}
      {columns.driver && (
        <TableCell className={classes.label}>
          <Typography
            color="inherit"
            variant="body2">
            {item.driver || '-'}
          </Typography>
        </TableCell>
      )}
      {columns.costCentre && (
        <TableCell className={classes.label}>
          {item.costCentre || '-'}
        </TableCell>
      )}
      {columns.maturityDate && (
        <TableCell className={classes.label}>
          {item.maturityDate ? (
            <Label
              color={getDateColor(item.maturityDate)}
              variant="outlined">
              {moment(item.maturityDate).format('DD/MM/YY')}
            </Label>
          ) : '-'}
        </TableCell>
      )}
      {columns.originalMaturityDate && tfp1971FeatureToggle && (
        <TableCell className={classes.label}>
          {moment(item.originalMaturityDate).format('DD/MM/YY') || '-'}
        </TableCell>
      )}      
      {columns.term && (
        <TableCell className={classes.label}>
          {item.term || '-'}
        </TableCell>
      )}
      {columns.currentTerm && (
        <TableCell className={classes.label}>
          {item.currentTerm || '-'}
        </TableCell>
      )}
      {columns.monthsOnVerbal && (
        <TableCell className={classes.label}>
          {item.monthsOnVerbal || '-'}
        </TableCell>
      )}
      {columns.status && (
        <TableCell className={classes.label}>
          {item.status || '-'}
        </TableCell>
      )}
      {columns.documentsCount && documentsReportFeatureToggle && (
        <TableCell className={classes.label}>
          {( item.documentsCount > 0 ? (            
            <Tooltip title="View documents">
              <IconButton className="ignore" onClick={onViewDocumentsReport}>
                <InsertDriveFileIcon className={classes.documentIcon} />
              </IconButton>
            </Tooltip>
          ) : '-')}
        </TableCell>
      )}
      <TableCell className={classes.spacer} />
    </TableRow>
  )
}

Row.propTypes = {
  item: types.ModelType.isRequired,
  columns: types.ColumnsType.isRequired,
}

export default Row
