import { ofType } from 'redux-observable'
import { tap, /*mapTo,*/ mergeMap} from 'rxjs/operators'
// import { push } from 'connected-react-router'
import fromTypes from '../actions/types'
import { EMPTY } from 'rxjs'


const initializeEffect = action$ =>
  action$.pipe(
    ofType(fromTypes.FRAME_INITIALIZE_REQUESTED),
    tap(action => {

      console.log('initialiseEffect action', action) // eslint-disable-line
    }),
    // mapTo(push('/login'))
    mergeMap(() => EMPTY)
  )

export default initializeEffect
