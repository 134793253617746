import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import FormGroup from '@material-ui/core/FormGroup'
import Switch from '@material-ui/core/Switch'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'


const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.success.main, //theme.palette.primary.main,
        borderColor: theme.palette.success.main, //theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch)

const CustomSwitch = ({ disabled, style, showLabel, value, onChange }) => {
  
  const [checked, setChecked] = useState(value ? value : false)

  const handleChange = () => {
    setChecked(!checked)
    onChange(!checked)
  }

  useEffect(() => {
    setChecked(value ? value : false)
  }, [value])

  return (
    <FormGroup style={style}>
      <Typography component="div">
        <Grid component="label" container alignItems="center" spacing={1}>
          {showLabel && <Grid item>Off</Grid>}
          <Grid item>
            <AntSwitch
              disabled={disabled}
              checked={value ? value : checked}
              onChange={handleChange}
              name="switch"
            />
          </Grid>
          {showLabel && <Grid item>On</Grid>}
        </Grid>
      </Typography>
    </FormGroup>
  )
}

CustomSwitch.propTypes = {
  disabled: PropTypes.bool,
  style: PropTypes.object,
  showLabel: PropTypes.bool,
  value: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}

export default CustomSwitch