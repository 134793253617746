import { configureOptions } from './helpers/configureOptions'
import { createActions } from './helpers/createActions'
import { createConstants } from './helpers/createConstants'
import { createEffects } from './helpers/createEffects'
import { createHooks } from './helpers/createHooks'
import { createPropTypes } from './helpers/createPropTypes'
import { createReducer } from './helpers/createReducer'
import { createStateModel } from './helpers/createStateModel'


export { default as Listable } from './components/list-wrapper'

export {
  calculateKmUseRate,
  deserializePreferences,
  getDateColor,
  getDateStatus,
  getWoFStatusColor,
  getForecastVariationColor,
  getKmUseRateColor,
  getStatusColor,
  getReliefStatusColor,
  getTrackingColor,
  getTrackingText,
  getYesNoColor,
  reduceRequest,
  getRole,
  getDateColorByMonth,
  getDriverStatusColor,
  obfuscateAgreementNumber,
  fbtMonthName,
  isUserIdAuth0,
  getUserStatusColor,
  getUserInvitedColor,
} from './utils'


/*
 * Creates a Listable module by constructing constants, actions, effects and a reducer
 * from a configuration object.
 */
export const createListable = ({
  config,
  name,
  customActions,
  customConstants,
  customReducer,
}) => {

  const options = configureOptions(config, name)
  const constants = createConstants(name, customConstants)
  const actions = createActions(constants, customActions, name)
  const effects = createEffects(name, constants, actions, config)
  const { item, state } = createStateModel(config)
  const reducer = createReducer(name, constants, state, config.hooks, customReducer)
  const propTypes = createPropTypes(config)
  // Depreciated
  const hooks = createHooks({ actions, constants, name })

  return {
    actions,
    constants,
    effects,
    hooks, // Depreciated
    name,
    options,
    reducer,
    types: propTypes,
    defaultItemState: item,
  }
}

