import { combineEpics } from 'redux-observable'
import { createListable } from 'listable'
import options from './config'
import { createEffects } from './effects'
import * as customActions from './actions'
import { moduleName } from './actions/types'
import customReducer from './reducer'


const listableModule = createListable({
  name: moduleName,
  config: options,
  customActions,
  customReducer,
})

export const config = listableModule.options
export const actions = listableModule.actions

export const effects = combineEpics(
  createEffects(listableModule.actions),
  listableModule.effects,
)

export const name = listableModule.name
export const reducer = listableModule.reducer
export const types = listableModule.types

export const constants = {
  ...listableModule.constants,
  PATH_ALL: `/vehicle-relocations`,
  PATH_ADD: `/vehicle-relocations/new`,
  PATH_SINGLE: `/vehicle-relocations/booking`,
  ROUTE_ALL: `/vehicle-relocations`,
  ROUTE_ADD: `/vehicle-relocations/new`,
  ROUTE_SINGLE: `/vehicle-relocations/booking/:id`,
}


export const createDefaultModel = () => ({
  effectiveDate: null,//moment().toISOString(),
  status: 'requested',
})
