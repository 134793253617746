import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import WarningIcon from '@material-ui/icons/Warning'
import { Typography, MuiThemeProvider } from '@material-ui/core'
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'


const useStyles = makeStyles(theme => ({
  cornerRibbon: {
    width: '200px',
    background: '#2f2f2f',
    color: theme.palette.white,
    position: 'fixed',
    textAlign: 'center',
    top: '25px',
    lineHeight: '40px',
    zIndex: '999',
    left: '-50px',
    '-ms-transform': 'rotate(-45deg)',
    '-webkit-transform': 'rotate(-45deg)',
    'transform': 'rotate(-45deg)',
  },
  horizontalRibbon: {
    fontSize: '1em',
    letterSpacing: 0.1,
    padding: theme.spacing(1, 3),
    width: '100%',
    background: props => props?.backgroundColor ? props?.backgroundColor : theme.palette.error.main,// '#2f2f2f', dark grey
    color: theme.palette.white,
    textAlign: 'center',
    top: '0',
    lineHeight: '40px',
  },
  link: {
    color: theme.palette.white,
    textDecoration: 'underline'
  },
  icon: {
    paddingTop: theme.spacing(0.8),
    marginRight: '2px',
    marginBottom: '-4px',
  },
  text: {
    paddingTop: theme.spacing(1)
  }
}))

const Ribbon = ({ text, backgroundColor, customChildren }) => {
  const classes = useStyles({ backgroundColor })
  
  let theme = createMuiTheme()
  theme = responsiveFontSizes(theme)

  return (
    <>
      <div className={classes.horizontalRibbon}>
        <MuiThemeProvider theme={theme}>
          {customChildren
            ? customChildren
            : (
              <Typography variant="body2">
                <WarningIcon className={classes.icon}/>
                {text}
              </Typography>
          )}
        </MuiThemeProvider>           
      </div>
    </>
  )
}

Ribbon.propTypes = {
  text: PropTypes.string,
  backgroundColor: PropTypes.string,
  customChildren: PropTypes.any,
}

export default Ribbon
