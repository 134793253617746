import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Menu, MenuItem } from '@material-ui/core'
import { useLatestFbtSubmission, useLatestFbtSummary } from 'features/fbt/navigation-config'
// import { constants as fromFbtSubmissions } from 'features/fbt-submissions'
// import { constants as fromFbtSummary } from 'features/fbt-summary'

const useStyles = makeStyles(theme => ({
  root: {
    width: 250,
    maxWidth: '100%',
  },
}))

const FBTPopover = ({ open, anchor, linker, fleetIds, onClose, onRequestFbtSubmissionsFilters, onRequestFbtSummaryFilters }) => {

  const classes = useStyles()
  
  const latestFbtSubmission = useLatestFbtSubmission()
  const latestFbtSummary = useLatestFbtSummary()

  useEffect(() => {
    console.log('[FBTPopover] Request Fbt Filters ') // fetch fbt filters
    onRequestFbtSubmissionsFilters(fleetIds)
    onRequestFbtSummaryFilters(fleetIds)
  }, [open]) // eslint-disable-line

  if ( ! anchor.current) {
    return null
  }

  const isValidFbtLink = (path) => path.includes('month') || path.includes('quarter')

  return (
    <Menu
      keepMounted
      id="menu-actions"
      anchorEl={anchor.current}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open}
      onClose={onClose}>
      <div className={classes.root}>
        {[
          { label: 'FBT Submissions', link: latestFbtSubmission.link },
          { label: 'FBT Summary', link: latestFbtSummary.link }
        ].map(({ label, link }, index) => (
          <MenuItem
            key={`fbt-menu-item-${index}`}
            disabled={!isValidFbtLink(link)}
            onClick={isValidFbtLink(link) ? linker(link) : null}>
            {label}
          </MenuItem>
        ))}
      </div>
    </Menu>
  )
}

FBTPopover.propTypes = {
  open: PropTypes.bool,
  anchor: PropTypes.object,
  linker: PropTypes.func.isRequired,
  fleetIds: PropTypes.array.isRequired,
  // func
  onClose: PropTypes.func.isRequired,
  onRequestFbtSubmissionsFilters: PropTypes.func.isRequired,
  onRequestFbtSummaryFilters: PropTypes.func.isRequired,
}

const mapStateToProps = ({ settings }) => ({
  fleetIds: settings.fleet.selected,
})

const mapDispatchToProps = (dispatch) => ({
  onRequestFbtSubmissionsFilters: (fleetIds) => {
    const FleetId = fleetIds.join(',')
    dispatch({
      type: `fbtSubmissions_request_filters`,
      payload: null,
      options: { queryParams: { FleetId } }
    })
  },
  onRequestFbtSummaryFilters: (fleetIds) => {
    const FleetId = fleetIds.join(',')
    dispatch({
      type: `fbtSummary_request_filters`,
      payload: null,
      options: { queryParams: { FleetId } }
    })
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(FBTPopover)

