
import { ReactComponent as ServiceRecordIcon } from 'assets/icons/servicerecord.svg'
import { ReactComponent as DriverIcon } from 'assets/icons/driver.svg'
import { ReactComponent as IncidentIcon } from 'assets/icons/incident.svg'
import { ReactComponent as OdometerIcon } from 'assets/icons/odometer.svg'
import { ReactComponent as VehicleIcon } from 'assets/icons/vehicle.svg'
import { ReactComponent as VehicleReturnIcon } from 'assets/icons/vehiclereturn.svg'


export const MODULE_NAME = `homepage`
export const HOMEPAGE_PATH = `/home`

export const RESET = `${MODULE_NAME}_reset`

// Is App User
export const CHECK_HAVE_APP_USERS = `${MODULE_NAME}_check_have_app_users`
export const CHECK_HAVE_APP_USERS_ERROR = `${MODULE_NAME}_check_have_app_users_error`
export const CHECK_HAVE_APP_USERS_FULFILLED = `${MODULE_NAME}_check_have_app_users_fulfilled`

// My Fleet Summary - Service Overdue
export const FETCH_SUMMARY_SERVICE_OVERDUE = `${MODULE_NAME}_fetch_summary_service_overdue`
export const FETCH_SUMMARY_SERVICE_OVERDUE_ERROR = `${MODULE_NAME}_fetch_summary_service_overdue_error`
export const FETCH_SUMMARY_SERVICE_OVERDUE_FULFILLED = `${MODULE_NAME}_fetch_summary_service_overdue_fulfilled`

// My Fleet Summary - Wof Overdue
export const FETCH_SUMMARY_WOF_OVERDUE = `${MODULE_NAME}_fetch_summary_wof_overdue`
export const FETCH_SUMMARY_WOF_OVERDUE_ERROR = `${MODULE_NAME}_fetch_summary_wof_overdue_error`
export const FETCH_SUMMARY_WOF_OVERDUE_FULFILLED = `${MODULE_NAME}_fetch_summary_wof_overdue_fulfilled`

// My Fleet Summary - Km Tracking
export const FETCH_SUMMARY_KM_TRACKING = `${MODULE_NAME}_fetch_summary_km_tracking`
export const FETCH_SUMMARY_KM_TRACKING_ERROR = `${MODULE_NAME}_fetch_summary_km_tracking_error`
export const FETCH_SUMMARY_KM_TRACKING_FULFILLED = `${MODULE_NAME}_fetch_summary_km_tracking_fulfilled`

// My Fleet Summary - Rego Overdue
export const FETCH_SUMMARY_REGO_OVERDUE = `${MODULE_NAME}_fetch_summary_rego_overdue`
export const FETCH_SUMMARY_REGO_OVERDUE_ERROR = `${MODULE_NAME}_fetch_summary_rego_overdue_error`
export const FETCH_SUMMARY_REGO_OVERDUE_FULFILLED = `${MODULE_NAME}_fetch_summary_rego_overdue_fulfilled`

// My Fleet Summary - FBT Outstanding
export const FETCH_SUMMARY_FBT_OUTSTANDING = `${MODULE_NAME}_fetch_summary_fbt_outstanding`
export const FETCH_SUMMARY_FBT_OUTSTANDING_ERROR = `${MODULE_NAME}_fetch_summary_fbt_outstanding_error`
export const FETCH_SUMMARY_FBT_OUTSTANDING_FULFILLED = `${MODULE_NAME}_fetch_summary_fbt_outstanding_fulfilled`

// Fetch notifications
export const FETCH_NOTIFICATIONS = `${MODULE_NAME}_fetch_notifications`
export const FETCH_NOTIFICATIONS_ERROR = `${MODULE_NAME}_fetch_notifications_error`
export const FETCH_NOTIFICATIONS_FULFILLED = `${MODULE_NAME}_fetch_notifications_fulfilled`

// Fetch status updates
export const FETCH_STATUS_UPDATES = `${MODULE_NAME}_fetch_status_updates`
export const FETCH_STATUS_UPDATES_ERROR = `${MODULE_NAME}_fetch_status_updates_error`
export const FETCH_STATUS_UPDATES_FULFILLED = `${MODULE_NAME}_fetch_status_updates_fulfilled`

// Fetch industry news
export const FETCH_INDUSTRY_NEWS = `${MODULE_NAME}_fetch_industry_news`
export const FETCH_INDUSTRY_NEWS_ERROR = `${MODULE_NAME}_fetch_industry_news_error`
export const FETCH_INDUSTRY_NEWS_FULFILLED = `${MODULE_NAME}_fetch_industry_news_fulfilled`

// Fetch download guides
export const FETCH_DOWNLOAD_GUIDES = `${MODULE_NAME}_fetch_download_guides`
export const FETCH_DOWNLOAD_GUIDES_ERROR = `${MODULE_NAME}_fetch_download_guides_error`
export const FETCH_DOWNLOAD_GUIDES_FULFILLED = `${MODULE_NAME}_fetch_download_guides_fulfilled`

// Fetch contact details
export const FETCH_CONTACT_DETAILS = `${MODULE_NAME}_fetch_contact_details`
export const FETCH_CONTACT_DETAILS_ERROR = `${MODULE_NAME}_fetch_contact_details_error`
export const FETCH_CONTACT_DETAILS_FULFILLED = `${MODULE_NAME}_fetch_contact_details_fulfilled`

// Fetch footer ticker
export const FETCH_FOOTER_TICKER = `${MODULE_NAME}_fetch_footer_ticker`
export const FETCH_FOOTER_TICKER_ERROR = `${MODULE_NAME}_fetch_footer_ticker_error`
export const FETCH_FOOTER_TICKER_FULFILLED = `${MODULE_NAME}_fetch_footer_ticker_fulfilled`


// for my notifications card
export const notificationEnums = {
  RELIEF_VELIEF_REQUEST: 0,
  VEHICLE_INCIDENT: 1,
  SERVICE_RECORD: 2,
}

export const notificationTypes = {
  [notificationEnums.RELIEF_VELIEF_REQUEST]: {
    Icon: VehicleIcon,
    uri: `/relief-vehicles/booking/{id}`
  },
  [notificationEnums.VEHICLE_INCIDENT]: {
    Icon: IncidentIcon,
    uri: `/reports/incident-management/{id}`
  },
  [notificationEnums.SERVICE_RECORD]: {
    Icon: ServiceRecordIcon,
    uri: `/reports/vehicle-servicing/{id}/service-bookings`
  },
}

// for status updates card
export const statusUpdateEnums = {
    RELIEF_VEHICLE: 0,
    VEHICLE_INCIDENT: 1,
    VEHICLE_RETURN: 2,
    SERVICE_RECORD: 3,
    SERVICE_BOOKING: 4,
    SERVICE_BOOKING_CANCEL: 5,
    SERVICE_BOOKING_EDIT: 6,
    ODOMETER_UPDATE: 7,
    DRIVER_CHANGE_TO_ASSIGN: 8,
    DRIVER_CHANGE_TO_UNASSIGNED: 9,
    DRIVER_CHANGE: 10,
    FBT_COMPLETED: 11,
    SERVICE_RECORD_ADDED: 20,
    SERVICE_BOOKING_AMENDED: 21,
  }
  
  export const statusUpdateTypes = {
    [statusUpdateEnums.RELIEF_VEHICLE]: {
      Icon: VehicleReturnIcon,
      uri: `/relief-vehicles/booking/{id}`
    },
    [statusUpdateEnums.VEHICLE_INCIDENT]: {
      Icon: IncidentIcon,
      uri: `/reports/vehicle-servicing/{id}/service-bookings`
    },
    [statusUpdateEnums.VEHICLE_RETURN]: {
      Icon: VehicleReturnIcon,
      // no need to link up
    },
    [statusUpdateEnums.SERVICE_RECORD]: {
      Icon: ServiceRecordIcon,
      uri: `/fleet/{id}/service-history`
    },
    [statusUpdateEnums.SERVICE_BOOKING]: {
      Icon: ServiceRecordIcon,
      // no need to link up
    },
    [statusUpdateEnums.SERVICE_BOOKING_CANCEL]: {
      Icon: ServiceRecordIcon,
      // no need to link up
    },
    [statusUpdateEnums.SERVICE_BOOKING_EDIT]: {
      Icon: ServiceRecordIcon,
      uri: `/fleet/{id}/service-history`
    },
    [statusUpdateEnums.SERVICE_RECORD_ADDED]: {
      Icon: ServiceRecordIcon,
      uri: `/fleet/{id}/service-history`
    },
    [statusUpdateEnums.SERVICE_BOOKING_AMENDED]: {
      Icon: ServiceRecordIcon,
      uri: `/fleet/{id}/service-history`
    },
    [statusUpdateEnums.ODOMETER_UPDATE]: {
      Icon: OdometerIcon,
      // no need to link up
    },
    [statusUpdateEnums.DRIVER_CHANGE_TO_ASSIGN]: {
      Icon: DriverIcon,
      // no need to link up uri: `/drivers/{id}`
    },
    [statusUpdateEnums.DRIVER_CHANGE_TO_UNASSIGNED]: {
      Icon: DriverIcon,
      // no need to link up uri: `/drivers/{id}`
    },
    [statusUpdateEnums.DRIVER_CHANGE]: {
      Icon: DriverIcon,
      // no need to link up uri: `/drivers/{id}`
    },
    [statusUpdateEnums.FBT_COMPLETED]: {
      uri: `/fbt/submissions/{id}/view-submissions`
       // no need to define icon, will fallback on InfoIcon
    },
  
  }