import moment from 'moment'

export const createGraphNodeClicker = (data = [], onClick) => (_, node) => {

  console.log('createGraphNodeClicker {node}: ', { node })
  if (!data) {
    console.log(`Warning no data supplied to graph node clicker`) // eslint-disable-line
    return
  }

  if (node[0] && node[0]['_model'] && node[0]['_model'].label && data[node[0]._index]) {

    const index = node[0]._index
    const date = data[index]
    const label = node[0]['_model'].label
    const key = 'range' //index === 0 ? 'status' : 'range'
    const value = index === 0
      ? label
      : moment(date)
        .utc()
        .date(1)
        .format('MM-DD-YYYY')

    try {
      let valueAsMoment = moment(value, 'MM-DD-YYYY')
      if (valueAsMoment.isValid()) {
        let formattedValue = valueAsMoment.format('MMMM YYYY')
        console.log('createGraphNodeClicker formattedValue', formattedValue)
        onClick(key, formattedValue)
      } else {
        // to do: move logic to reducer and just dispatch the raw value (not sure if possible because of the generic way this thing works)
        switch(value) {
          case 'Matured':
          case 'Expired':
          case 'Overdue':
            onClick(null, null) // for first columns
            break;
          default: // Other. Eg Under, Over, No Data Available, Within Buffer
            console.log('createGraphNodeClicker default value', value)
            //console.log('createGraphNodeClicker {node}: ', { node })
            onClick(key, value)
            break;
        }
      }
    }
    catch (err) {
      console.log('Error parsing date', err.message) // eslint-disable-line
    }
  }
}

export const createBasicGraphNodeClicker = (onClick) => (_, node) => {

  if (node[0] && node[0]['_model'] && node[0]['_model'].label) {

    // const index = node[0]._index
    const label = node[0]['_model'].label
    // let labelLower = label.replace(/ /g, '_').toLowerCase()
    //console.log('createBasicGraphNodeClicker onclick labelLower', labelLower)
    console.log('createBasicGraphNodeClicker onclick label', label)
    onClick(label)// labelLower)
  }
}

export const createStackedBarGraphNodeClicker = (data = [], onClick) => (_, node) => {

  console.log('createGraphNodeClicker: ', { node })
  if (!data) {
    console.log(`Warning no data supplied to graph node clicker`) // eslint-disable-line
    return
  }

  if (node[0] && node[0]['_model'] && node[0]['_model'].label && data[node[0]._index]) {

    const index = node[0]._index
    const date = data[index]
    const label = node[0]['_model'].label
    const key = 'range' //index === 0 ? 'status' : 'range'
    const value = index === 0
      ? label
      : moment(date)
        .utc()
        .date(1)
        .format('MM-DD-YYYY')

    // const node[0]
    console.log('createGraphNodeClicker value', { value, label, date, index })


    try {
      let valueAsMoment = moment(value, 'MM-DD-YYYY')
      if (valueAsMoment.isValid()) {
        let formattedValue = valueAsMoment.format('MMMM YYYY')
        console.log('createGraphNodeClicker formattedValue', formattedValue)
        onClick(key, formattedValue)
      } else {
        // to do: move logic to reducer and just dispatch the raw value (not sure if possible because of the generic way this thing works)
        switch(value) {
          case 'Matured':
          case 'Expired':
          case 'Overdue':
            onClick(null, null) // for first columns
            break;
          default: // Other. Eg Under, Over, No Data Available, Within Buffer
            // console.log('createGraphNodeClicker default value', value)
            onClick(key, value)
            break;
        }
      }
    }
    catch (err) {
      console.log('Error parsing date', err.message) // eslint-disable-line
    }
  }
}