import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import CloseIcon from '@material-ui/icons/Close'
import { useDispatch, useSelector } from 'react-redux'
import { Typography, Button, IconButton, Dialog, DialogContent } from '@material-ui/core'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { registerInterest, reset } from 'features/admin-registration-of-interest/actions'
import { PageLoader } from 'frame/components'
import ReactGA from 'react-ga'


const useStyles = makeStyles(theme => ({
  content: {
    padding: theme.spacing(10),
    [theme.breakpoints.down('xs')]: { paddingTop: theme.spacing(4) }
  },
  form: {
    minHeight: 600,
    minWidth: 575,
    padding: theme.spacing(10),
    [theme.breakpoints.down('xs')]: { paddingTop: theme.spacing(4) }
  },
  close: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2.4),
  },
  icon: {
    margin: 0,
    width: 30,
    height: 30,
    color: '#FFF',
  },
  actions: { padding: theme.spacing(2, 0) },
  input: { color: "#FFF" },
  field: {
    '& label.Mui-focused': { color: '#FFF' },
    '& .MuiInput-underline:after': { borderBottomColor: '#FFF' },
    '& .MuiOutlinedInput-root': {
      '& fieldset': { borderColor: '#FFF' },
      '&:hover fieldset': { borderColor: '#FFF' },
      '&.Mui-focused fieldset': { borderColor: '#FFF' },
    },
  },
}))

const RegistrationModal = ({ open, onClose, onHideRegisterButton }) => {

  const classes = useStyles()
  const dispatch = useDispatch()
  
  const flags = useSelector(state => state?.registrationOfInterest?.flags)

  const [showConfirmatonMessage, setShowConfirmatonMessage] = useState(false)
  const [values, setValues] = useState({
    firstName: '',
    lastName: '',
    contactEmail: '',
    contactNumber: '',
    organisation: ''
  })

  useEffect(() => { reset() }, []) // reset flags onload

  useEffect(() => {
    flags.registerInterest === 'processed' && setShowConfirmatonMessage(true)
  }, [flags.registerInterest])

  const onInputChange = ({ target: { name, value } }) =>
    setValues({ ...values, [name]: value, })

  const onRegister = () => {
    dispatch(registerInterest(values))
    console.log('[onRegisterInterest] submit', values)
    ReactGA.event({ category: 'RegistrationOfInterestPage', action: 'Submit Registration Of Interest', label: 'Registration Of Interest Page' })
  }

  const onCloseHandler = () => {
    onClose()
    showConfirmatonMessage && onHideRegisterButton(true)
  }

  return (
    <Dialog
      open={open}
      onClose={onCloseHandler}
      PaperProps={{
        style: { backgroundColor: '#012a46', boxShadow: 'none' },
      }}
    >
      <IconButton aria-label="close" onClick={onCloseHandler} className={classes.close}>
        <CloseIcon className={classes.icon} />
      </IconButton>

      <DialogContent>
        {showConfirmatonMessage ? (
          <div className={classes.content}>
            <Typography variant="h3" align="center" style={{ padding: 20, color: '#FFF' }}>
              THANK YOU
            </Typography>
            <Typography variant="body1" align="center" style={{ padding: 20, marginBottom: 20, color: '#FFF' }}>
              You have successfully registered to be signed up to the ToyotaFleet Portal. Our team will take care of the rest from here.<br /><br />
              Once you have been signed up, you will receive an email to complete your account setup and access your ToyotaFleet account.<br /><br />
              If you have any questions feel free to contact us fleet.operations@tfs.co.nz.<br /><br />
              Toyota Fleet Management
            </Typography>
          </div>
        ) : (
          <div className={classes.form}>            
            {flags?.registerInterest === 'processing' ? (
              <PageLoader color={'#FFF'} />
            ) : (<>
              <Typography variant="h3" align="center" style={{ padding: 20, marginTop: 30, color: '#FFF' }}>
                REGISTER YOUR INTEREST
              </Typography>

              <ValidatorForm autoComplete="off" noValidate onSubmit={onRegister}>
                {[
                  {
                    label: 'FIRST NAME',
                    key: 'firstName',
                    validators: ['required'],
                    error: ['This field is required'],
                    maxLength: 50,
                  },
                  {
                    label: 'LAST NAME',
                    key: 'lastName',
                    validators: ['required'],
                    error: ['This field is required'],
                    maxLength: 50,
                  },
                  {
                    label: 'EMAIL',
                    key: 'contactEmail', validators: ['required', 'isEmail'],
                    error: ['This field is required', 'Must be a valid email'],
                    maxLength: 100,
                  },
                  {
                    label: 'MOBILE',
                    key: 'contactNumber',
                    validators: ['required', 'isNumber'],
                    error: ['This field is required', 'Must be a valid mobile number'],
                    maxLength: 50,
                  },
                  {
                    label: 'ORGANISATION',
                    key: 'organisation',
                    validators: ['required'],
                    error: ['This field is required'],
                    maxLength: 60,
                  },
                ].map(({ label, key, validators, error, maxLength }) => (
                  <TextValidator
                    key={`${key}-field`}
                    // disabled={loading}
                    InputProps={{ className: classes.input }}
                    inputProps={{ maxLength }}
                    InputLabelProps={{ style: { color: '#FFF' } }}
                    style={{ marginTop: 20 }}
                    className={classes.field}
                    fullWidth
                    size="small"
                    label={label}
                    name={key}
                    required
                    value={values?.[key] || ''}
                    variant="outlined"
                    validators={validators}
                    errorMessages={error}
                    onChange={onInputChange}
                  />
                ))}  

                <div className={classes.actions}>
                  <Button
                    fullWidth
                    type="submit"
                    style={{ marginBottom: 10 }}
                    color="primary"
                    variant="contained"
                  >
                    REGISTER YOUR INTEREST
                  </Button>
                </div>
              </ValidatorForm>
            </>)}
          </div>
        )}

      </DialogContent>
    </Dialog>
  )
}

RegistrationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onHideRegisterButton: PropTypes.func.isRequired,
}

export default RegistrationModal