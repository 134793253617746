import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { makeStyles, useTheme } from '@material-ui/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Grid, Typography } from '@material-ui/core'
import { RowValueText, CustomDatePicker, StyledModal, AnimatedSuccessTick, AnimatedErrorCross } from 'frame/components'
import { TextValidator } from 'react-material-ui-form-validator'
import ServiceBookingFormWarper from './form-wrapper'
import { addServiceBooking, cancelServiceBooking, reset } from 'features/report-vehicle-servicing/actions'
import moment from 'moment'
import { useProfile } from 'features/account/hooks'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4, 0, 2, 0),
  },
  label: {
    paddingTop: theme.spacing(3),
  },
  dateField: {
    margin: 0,
    padding: 0,
    '& > div': {
      margin: 0,
      padding: '11px 8px',
      borderRadius: '4px',
      '&::before': { display: 'none' },
      '&::after': { display: 'none' },
    },
    '& input': { margin: 0, padding: 0, border: 'none' }
  },
}))

const AddBookingDetailsForm = ({
  flags,
  readOnly,
  fleetData,
  serviceBookingData,
  apiResponse,
  // func
  onAddServiceBooking,
  onCancelServiceBooking,
  onReset,
  onBack,
  
}) => {

  const profile = useProfile()
  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'))
  const classes = useStyles()

  const [values, setValues] = useState({
    bookedDate: moment(),
    bookedLocation: ''
  })
  
  const [showConfirmCancelModal, setShowConfirmCancelModal] = useState(false)

  const onInputChange = ({ target: { id, name, value } }) =>
    setValues({ ...values, [id || name]: value })
  
  const onSelectChange = (id) => (value) =>
    setValues({ ...values, [id]: value })

  const onSave = () => {
    const payload = {
      driverName: profile.nickname,
      agreementNumber: fleetData.agreementNo,
      vehicleRegistration: fleetData.registration,
      ...values
    }
    console.log('[onAddServiceBooking] payload: ', payload)
    onAddServiceBooking(payload)
  }

  const onCancel = () => onCancelServiceBooking({ agreementNumber: fleetData?.agreementNo })

  return (
    <>
      <ServiceBookingFormWarper
        title="Service Bookings"
        subtitle={readOnly ? 'View or cancel your saved booking' : 'Add booking details or confirm a completed booking'}
        onSubmit={readOnly ? () => setShowConfirmCancelModal(true) : onSave}
        onSubmitLabel={readOnly ? 'Cancel' : 'Save'}
        disabled={flags.addServiceBooking === 'processing'}
        onBack={onBack}
      >
        <Grid container spacing={2} className={classes.root}>
          
          <Grid item xs={12}>
            <RowValueText label="Booked Date" responsive={!readOnly}>
              {readOnly ? (
                <Typography variant="body1">
                  {serviceBookingData.bookedDate ? moment(serviceBookingData.bookedDate).format('DD/MM/YYYY') : '-'}
                </Typography>
              ) : (
              <CustomDatePicker
                fullWidth tight
                id="bookedDate"
                variant="outlined"
                format="Do MMMM YYYY"
                label={mobileView ? 'Booked Date' : ''}
                value={values.bookedDate}
                onChange={onSelectChange('bookedDate')}
                minDate={moment()}
                textFieldClassName={classes.dateField}
              />
            )}
            </RowValueText>
          </Grid>

          <Grid item xs={12}>
            <RowValueText label="Booked Dealership" responsive={!readOnly}>
              {readOnly ? (
                <Typography variant="body1">
                  {serviceBookingData.bookedLocation || '-'}
                </Typography>
              ) : (
                <TextValidator
                  id="bookedLocation"
                  size="small"
                  fullWidth
                  label={mobileView ? 'Booked Dealership' : ''}
                  InputLabelProps={{ shrink: mobileView }} // to show label only on mobile
                  value={values.bookedLocation || ''}
                  variant="outlined"
                  inputProps={{ maxLength: 50 }}
                  validators={['required']}
                  errorMessages={['This field is required']}
                  onChange={onInputChange}
                />
              )}
            </RowValueText>
          </Grid>
        </Grid>
      </ServiceBookingFormWarper>
        
      {/* for cancel confirmation */}
      <StyledModal
        flag={flags.cancelServiceBooking}
        open={showConfirmCancelModal}
        title={`Service Booking`}
        onCancel={() => setShowConfirmCancelModal(false)}
        onConfirm={onCancel}>
        By clicking on confirm you are indicating that the service booking you have recorded for this vehicle is not proceeding.
        <br/><br/>
        NOTE: cancelling your service booking will only update the vehicle record within the Portal. You may need to contact the servicing dealership to confirm the cancellation.
      </StyledModal>

      
      {/* Success/Error modal */}
      <StyledModal
        open={flags.addServiceBooking === 'processed' || flags.addServiceBooking === 'error'}
        onCancel={() => onReset()}
        options={['noButtons']}
        animatedIcon={<>{flags.addServiceBooking === 'processed' ? <AnimatedSuccessTick size={50} /> : <AnimatedErrorCross size={50} />}</>}
      >
        {apiResponse}
      </StyledModal>
    </>
  )
}

AddBookingDetailsForm.propTypes = {
  readOnly: PropTypes.bool,
  flags: PropTypes.object.isRequired,
  fleetData: PropTypes.object.isRequired,
  errorMessage: PropTypes.string.isRequired,
  apiResponse: PropTypes.string.isRequired,
  // func
  onAddServiceBooking: PropTypes.func.isRequired,
  onCancelServiceBooking: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onBack: PropTypes.func,
}

const mapStateToProps = ({ reportVehicleServicing }) => ({
  flags: reportVehicleServicing.flags,
  errorMessage: reportVehicleServicing.errorMessage || '',
  apiResponse: reportVehicleServicing.apiResponse || '',
})

const mapDispatchToProps = (dispatch) => ({
  onAddServiceBooking: (payload) => {
    dispatch(addServiceBooking(payload))
  },
  onCancelServiceBooking: (payload) => {
    dispatch(cancelServiceBooking(payload))
  },
  onReset: () => {
    dispatch(reset())
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(AddBookingDetailsForm)