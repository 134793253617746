import { combineEpics, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { mergeMap, filter } from 'rxjs/operators'
import { makeActionRequestEffect, makeSimpleDownloadRequestEffect } from 'effects'
import { getAppConfig } from 'app-config'
import join from 'url-join'
import * as fromModuleTypes from './actions/types'
import * as fromDriverTypes from 'features/drivers/actions/types'


export const createEffects = (moduleActions) => {

  const appConfig = getAppConfig()

  const fetchEffect = makeActionRequestEffect({
    type: fromModuleTypes.FETCH,
    url: join(appConfig.app.api, 'contract/contract'),
    onSuccess: moduleActions.fetchFulfilled,
    onError: moduleActions.fetchError,
  })

  const fetchServiceHistoryEffect = makeActionRequestEffect({
    type: fromModuleTypes.FETCH_SERVICE_HISTORY,
    url: join(appConfig.app.api, 'serviceHistory/serviceHistory'),
    onSuccess: moduleActions.fetchServiceHistoryFulfilled,
    onError: moduleActions.fetchServiceHistoryError,
  })

  const fetchFbtEffect = makeActionRequestEffect({
    type: fromModuleTypes.FETCH_RELATED_FBT,
    url: join(appConfig.app.api, 'fbt/fbtbyrego'),
    onSuccess: moduleActions.fetchRelatedFbtFulfilled,
    onError: moduleActions.fetchRelatedFbtError,
  })

  const updateEffect = makeActionRequestEffect({
    type: fromModuleTypes.UPDATE,
    url: join(appConfig.app.api, 'Contract/Contract'),
    verb: 'post',
    onSuccess: moduleActions.updateFulfilled,
    onError: moduleActions.updateError,
  })
  const updateVariationEffect = makeActionRequestEffect({
    type: fromModuleTypes.UPDATE_VARIATIONS,
    url: join(appConfig.app.api, 'ContractVariation/Variation'),
    verb: 'post',
    onSuccess: moduleActions.updateFulfilled,
    onError: moduleActions.updateError,
  })

  const updateServiceHistoryReliableFlagEffect = makeActionRequestEffect({
    type: fromModuleTypes.UPDATE_SERVICE_HISTORY_RELIABLE_FLAG,
    url: join(appConfig.app.api, 'ServiceHistory/ServiceHistoryReliableFlag'),
    verb: 'post',
    onSuccess: moduleActions.updateServiceHistoryReliableFlagFulfilled,
    onError: moduleActions.updateServiceHistoryReliableFlagError,
  })

  const deleteVariationEffect = makeActionRequestEffect({
    type: fromModuleTypes.DELETE_VARIATION,
    url: join(appConfig.app.api, 'contractVariation/variation'),
    verb: 'delete',
    onSuccess: moduleActions.deleteVariationFulfilled,
    onError: moduleActions.deleteVariationError,
  })

  const submitKmReadingEffect = makeActionRequestEffect({
    type: fromModuleTypes.SUBMIT_KM_READING,
    url: join(appConfig.app.api, 'ServiceHistory/ServiceHistory'),
    verb: 'post',
    onSuccess: moduleActions.submitKmReadingFulfilled,
    onError:  moduleActions.submitKmReadingError,
  })
  
  const downloadVehicleHistory = (action$) =>
    action$.pipe(
      ofType(fromModuleTypes.DOWNLOAD_VEHICLE_HISTORY),
      ...makeSimpleDownloadRequestEffect({
        url: join(appConfig.app.api, 'download/vehiclehistory'),
        onSuccess: moduleActions.onDownloadFulfilled,
        onError: moduleActions.onDownloadError,
      }),
    )

  const refetchEffect = (action$, state$) =>
    action$.pipe(
      ofType(
        fromModuleTypes.UPDATE_FULFILLED,
        fromModuleTypes.DELETE_VARIATION_FULFILLED,
        fromDriverTypes.CREATE_FULFILLED,
        fromDriverTypes.UPDATE_FULFILLED,
        fromDriverTypes.DELETE_FULFILLED,
        fromDriverTypes.TOGGLE_POOL_VEHICLE_FULFILLED,
        fromDriverTypes.UNASSIGN_VEHICLE_FULFILLED,

      ),
      filter(() => window.location.pathname.includes('/fleet')), // to trigger on tfs fleet page only
      mergeMap(() => of(
        moduleActions.refetch(),
        )
      )
    )

  const refetchContractEffect = (action$, state$) =>
    action$.pipe(
      ofType(
        fromModuleTypes.UPDATE_FULFILLED
      ),
      mergeMap(({ payload }) => {
          const agreementNo = payload.agreementNo
          const FleetId = payload.thirdPartyNumber

          return of(
            moduleActions.fetch(null, {
              resourceId: agreementNo,
              queryParams: { FleetId }
            })
          )
        }
      )
    )

  const lookupEffect = makeActionRequestEffect({
    type: fromModuleTypes.LOOKUP,
    url: join(appConfig.app.api, `lookup`),
    onSuccess: moduleActions.lookupFulfilled,
    onError: moduleActions.lookupError,
  })

  return combineEpics(
    fetchEffect,
    fetchServiceHistoryEffect,
    fetchFbtEffect,
    updateEffect,
    updateVariationEffect,
    deleteVariationEffect,
    refetchEffect,
    refetchContractEffect,
    lookupEffect,
    updateServiceHistoryReliableFlagEffect,
    submitKmReadingEffect,
    downloadVehicleHistory
  )
}
