import * as fromActions from './actions/types'
import uuid from 'uuid/v4'


const reducer = (state, action) => {
  switch (action.type) {

    case fromActions.RESET:
      return {
        ...state,
        flags: { list: state?.flags?.list },
        drafts: [],
        form: {},
        daysToDisable: [],
        fbtDetails: null,
        originalEditRecord: null,
        fbtWebForm: null,
      }
    case fromActions.RESET_FLAGS:
      return { ...state, flags: { list: state?.flags?.list } }
    
    // Fetch Fbt Details on Edit Fbt modal open
    case fromActions.FETCH:
      return updateFlags(state, { fetch: 'processing' })
    case fromActions.FETCH_FULFILLED:
      return fetchFbtSuccess(state, action)
    case fromActions.FETCH_ERROR:
      return onError(state, action, { fetch: 'error' })

    // Add Fbt Submit
    case fromActions.ADD_FBT_SUBMISSIONS:
      return updateFlags(state, { addFbtSubmission: 'processing' })
    case fromActions.ADD_FBT_SUBMISSIONS_FULFILLED:
      return updateFlags(state, { addFbtSubmission: 'processed' })
    case fromActions.ADD_FBT_SUBMISSIONS_ERROR:
      return onError(state, action, { addFbtSubmission: 'error' })

    // Edit Fbt Submit
     case fromActions.EDIT_FBT_SUBMISSION_SAVE:
      return updateFlags(state, { update: 'processing' })
    case fromActions.EDIT_FBT_SUBMISSION_SAVE_FULFILLED:
      return updateFlags(state, { update: 'processed' })
    case fromActions.EDIT_FBT_SUBMISSION_SAVE_ERROR:
      return onError(state, action, { update: 'error' })

    // Lookup
    case fromActions.LOOKUP:
      return updateFlags(state, { lookup: 'processing' })
    case fromActions.LOOKUP_ERROR:
      return onError(state, action, { lookup: 'error' })
    case fromActions.LOOKUP_FULFILLED:
      return onLookupSuccess(state, action)
    
    // For storing FBT customer dropdown value
    case fromActions.SET_FBT_CUSTOMER_VIEW:
      return { ...state, currentFbtCustomerView: action?.payload || '' }
    
    // reset currentFbtCustomerView on fleet change
    case fromActions.RESET_FBT_CUSTOMER_VIEW:
      return { ...state, currentFbtCustomerView: null }

    // for temp storing new submission details for add form
    case fromActions.SET_NEW_SUBMISSION_DETAILS:
      return { ...state, form: null, newSubmissionDetails: action?.payload }

    // For add/edit fbt form submit
    case fromActions.ADD:
      return addFbtDate(state, action)
    case fromActions.EDIT_SAVE:
      return editSaveFbtDate(state, action)

    // on edit icon click
    case fromActions.EDIT_DRAFT:
      return editDraft(state, action)
    // on delete icon click
    case fromActions.DELETE_DRAFT:
      return deleteDraft(state, action)
    // on add fbt
    case fromActions.NEW_DRAFT:
      return newDraft(state)

    // Register Interest
    case fromActions.REGISTER_INTEREST:
      return updateFlags(state, { registerInterest: 'processing' })
    case fromActions.REGISTER_INTEREST_FULFILLED:
      return updateFlags(state, { registerInterest: 'processed' })
    case fromActions.REGISTER_INTEREST_ERROR:
      return onError(state, action, { registerInterest: 'error' })
    
    // Fbt Web Form Verify Token
    case fromActions.FBT_WEBFORM_VERIFY_TOKEN_VALUE:
      return updateFlags(state, { verifyToken: 'processing' })
    case fromActions.FBT_WEBFORM_VERIFY_TOKEN_VALUE_FULFILLED:
      return updateFlags(state, { verifyToken: 'processed' })
    case fromActions.FBT_WEBFORM_VERIFY_TOKEN_VALUE_ERROR:
      return onError(state, action, { verifyToken: 'error' })
    
    // Fbt Web Form Verify Email
    case fromActions.FBT_WEBFORM_VERIFY_EMAIL:
      return updateFlags(state, { verifyEmail: 'processing' })
    case fromActions.FBT_WEBFORM_VERIFY_EMAIL_FULFILLED:
      return updateFlags(state, { verifyEmail: 'processed' })
    case fromActions.FBT_WEBFORM_VERIFY_EMAIL_ERROR:
      return onError(state, action, { verifyEmail: 'error' })

    // Fbt Web Form Submit
    case fromActions.FBT_WEBFORM_SUBMIT:
      return updateFlags(state, { fbtWebFormSubmit: 'processing' })
    case fromActions.FBT_WEBFORM_SUBMIT_FULFILLED:
      return updateFlags({
          ...state,
          fbtWebForm: { ...state.fbtWebForm, token: null }
        },
        { fbtWebFormSubmit: 'processed' }
      )
    case fromActions.FBT_WEBFORM_SUBMIT_ERROR:
      return onError(state, action, { fbtWebFormSubmit: 'error' })

    case fromActions.FBT_WEBFORM_SET_VALUES:
      return { ...state, fbtWebForm: action?.payload }

    default:
      return state
  }
}


const fetchFbtSuccess = (state, { payload }) => {
  const entries = payload?.entries || []
  const drafts = entries.map(item => ({
    id: item?.id, 
    days: item?.daysOfMonth.split(',').filter(x => x.trim().length && !isNaN(x)).map(Number), // string to int array
    previouslySelectedDays: [],
    reason: fromActions.fbtReasonOptions.includes(item?.reason) ? item?.reason : item?.reason.replace('Other: ', ''),
    reasonType: fromActions.fbtReasonOptions.includes(item?.reason) ? item?.reason : `Other`,
  }))
  state = {
    ...state,
    fbtDetails: payload,
    drafts,
    originalDrafts: drafts,
  }
  return updateFlags(state, { fetch: 'processed' })
}

const addFbtDate = (state, action) => {
  let previouslySelectedDays = action.payload?.previouslySelectedDays || []
  let selectedDays = action.payload?.days || []
  let drafts = state?.drafts || []
  drafts.push({
    ...action?.payload,
    id: uuid(),
    previouslySelectedDays: [],
    days: [ ...new Set(selectedDays.concat(previouslySelectedDays))].sort((a, b) => a - b),
  })
  return { ...state, drafts, originalDrafts: null }
}

const editSaveFbtDate = (state, action) => {
  
  let drafts = state?.drafts || []
  const updatedSubmission = action.payload
  const updatedSelectedDays = action.payload?.days || []
  const updatedPreviouslySelectedDays = action.payload?.previouslySelectedDays || []

  const existingDraftIndex = drafts.findIndex(item => item.id === updatedSubmission.id)
  if(existingDraftIndex > -1) {
    drafts[existingDraftIndex] = {
      ...updatedSubmission,
      previouslySelectedDays: [],
      days: [ ...new Set(updatedSelectedDays.concat(updatedPreviouslySelectedDays))].sort((a, b) => a - b),
    }
  }
  return { ...state, drafts, originalEditRecord: null, originalDrafts: null }
}

const editDraft = (state, { payload }) => {

  const drafts = state?.drafts || []

  // move selectedDays to previousSelectedDays
  console.log('[editDraft] payload', payload)
  let previouslySelectedDays = payload?.days

  // combine draft days as disable days in the calender
  let daysToDisable = []
  let draftsWithoutCurrent = drafts.filter(x => x.id !== payload.id)
  
  draftsWithoutCurrent && draftsWithoutCurrent.length > 0 && draftsWithoutCurrent.forEach(item => {
    const days = item?.days || []
    daysToDisable = [ ...new Set(daysToDisable.concat(days) )]
  })
  console.log('[editDraft] daysToDisable: ', daysToDisable)

  const reasonType = fromActions.fbtReasonOptions.includes(payload?.reason) ? payload?.reasonType : 'Other'
  const reason = `${payload.reason}`.replace('Other: ', '')

  return {
    ...state,
    daysToDisable,
    form: { ...payload, previouslySelectedDays, days: [], reasonType, reason },
    originalEditRecord: { ...payload, previouslySelectedDays, days: [], reasonType, reason },
  }
}

const deleteDraft = (state, { payload }) => {
  
  const drafts = state?.drafts || []
  const updatedDrafts = drafts.filter(item => item.id !== payload.id)

  return { ...state, drafts: updatedDrafts }
}

const newDraft = (state) => {

  const drafts = state?.drafts || []
  const newDraft = { days: [], previouslySelectedDays: [], reasonType: '', reason: '' }
  
  // combine draft days as disable days in the calender
  let daysToDisable = []
  drafts.length > 0 && drafts.forEach(item => {
    const days = item?.days || []
    daysToDisable = [ ...new Set(daysToDisable.concat(days) )]
  })
  console.log('[newDraft] daysToDisable: ', daysToDisable)

  return { ...state, daysToDisable, form: newDraft }
}


const onError = (state, { payload }, flags) => {
  const error = payload?.message || payload
  state.errorMessage = typeof error === 'string' ? error : ''
  return updateFlags(state, flags)
}

const updateFlags = (state, flags) => ({
  ...state,
  flags: { ...state.flags, ...flags }
})

const onLookupSuccess = (state, { payload, options }) => {
  let lookupOptions = []

  if(payload && payload.length > 0) {
    const cleanData = [...(new Set(payload))]
      .reduce((result, element) => {
        const normalize = x => typeof x === 'string' ? x.toLowerCase() : x
  
        if (result.every(otherElement => normalize(otherElement.value) !== normalize(element.value)))
          result.push(element)
  
        return result
      }, [])
  
    lookupOptions = cleanData
  }

  state.lookup = {
    ...state.lookup,
    [options.resourceId]: lookupOptions
  }
  return updateFlags(state, { lookup: 'processed' })
}

export default reducer
