import PropTypes from 'prop-types'


export const model = [{
  key: 'agreementNo',
  label: 'Agreement No.',
  column: {
    visible: true,
    width: 115,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'accountName',
  label: 'Fleet',
  column: {
    visible: true,
    width: 80,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'driverNote',
  label: 'Driver Note',
  column: {
    visible: false,
    width: 190,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'driver',
  label: 'Driver',
  column: {
    visible: true,
    width: 190,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'fuelType',
  label: `Fuel Type`,
  column: {
    visible: false,
    width: 110,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'location',
  label: `Location`,
  column: {
    visible: false,
    width: 150,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'costCentre',
  label: `Cost Centre`,
  column: {
    visible: false,
    width: 150,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'make',
  label: 'Make',
  column: {
    visible: false,
    width: 100,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'maturityDate',
  label: 'Maturity Date',
  column: {
    visible: true,
    width: 110,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'model',
  label: `Model`,
  column: {
    visible: false,
    width: 100,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'oldMaturityDate',
  label: `Original Maturity Date`,
  column: {
    visible: false,
    width: 140,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'registration',
  label: 'Registration',
  column: {
    visible: true,
    width: 95,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'variant',
  label: 'Variant',
  column: {
    visible: false,
    width: 250,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'year',
  label: 'Year',
  column: {
    visible: false,
    width: 80,
  },
  prop: {
    type: PropTypes.number,
    value: '',
  }
}, {
  key: 'onOrder',
  label: 'On Order',
  column: {
    visible: true,
    width: 80,
  },
  prop: {
    type: PropTypes.number,
    value: '',
  }
}, {
  key: 'documentsCount',
  label: 'Documents',
  column: {
    visible: false,
    width: 100,
  },
  prop: {
    type: PropTypes.number,
    value: '',
  }
}]

const config = {
  title: 'Extension Rentals',
  breadcrumbs: null,
  backgroundImage: 'url("/static/images/backgrounds/bg4.png")',
  api: {
    filter: '/filteroptions/inertiarental',
    resource: `/report/inertiarental`,
    download: `/download/inertiarental`,
  },
  model,
  headers: [
    'accountName', 'agreementNo', 'registration', 'driverNote', 'driver',
    'year', 'make', 'model', 'variant', 'fuelType', 'costCentre',
    'location', 'maturityDate', 'oldMaturityDate', 'onOrder','documentsCount'
  ],
  filters: [{
    key: 'accountName',
    label: 'Customer',
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }, {
    key: 'costCentre',
    label: 'Cost Centre',
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }],
}


export default config
