import PropTypes from 'prop-types'


export const model = [{
  key: 'id',
  label: 'Id',
  column: {
    visible: false,
    width: 200,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'feature',
  label: 'Feature',
  column: {
    visible: true,
    width: 160,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'description',
  label: 'Description',
  column: {
    visible: true,
    width: 320,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'reportsImpacted',
  label: 'Reports Impacted',
  column: {
    visible: true,
    width: 180,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'lastToggledDate',
  label: 'Last Toggled Date',
  column: {
    visible: true,
    width: 200,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'lastToggledBy',
  label: 'Last Toggled By',
  column: {
    visible: true,
    width: 200,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'featureToggle',
  label: 'Feature Toggle',
  column: {
    visible: true,
    width: 200,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}]

const config = {
  title: 'Feature Toggle',
  breadcrumbs: null,
  backgroundImage: 'url("/static/images/backgrounds/bg4.png")',

  idKey: 'id',
  api: {
    filter: '/',
    resource: `/features`,
  },
  model,
  headers: [
    'feature', 'description', 'reportsImpacted', 'lastToggledDate', 'lastToggledBy','featureToggle'
  ],
  filters: [],
  noPagination: true,
  noColumnSort: true,
  noDownload: true,
}


export default config
