import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
}))

const Image = ({ className, src }) => {

  const classes = useStyles()

  const style = {
    backgroundImage: `url(${src})`
  }

  return (
    <div
      className={clsx(classes.root, className)}
      style={style} />
  )
}

Image.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string.isRequired,
}

export default Image
