import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, useTheme } from '@material-ui/styles'
import { Bar } from 'react-chartjs-2'
import { Typography } from '@material-ui/core'
import { Modal } from 'frame/components'
import moment from 'moment'


const useStyles = makeStyles(theme => ({
  root: {},
  link: {
    color: theme.palette.text.link,
    textDecoration: 'none',
    cursor: 'pointer'
  },
}))

const StackedBarChart = ({ data, onClick, noDataMessage, showHealthAndSafetyDisclaimer }) => {

  const classes = useStyles()
  const theme = useTheme()
  const [openDisclaimerMessage, setOpenDisclaimerMessage] = useState(false) // for disclaimerMessage modal
  
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    offsetGridLines: true,
    drawTicks: true,
    legend: {
      display: false,
    },
    layout: {
      padding: 0
    },
    scales: {
      xAxes: [
        {
          stacked: true,
          barThickness: 22,
          maxBarThickness: 26,
          barPercentage: 0.5,
          categoryPercentage: 0.5,
          gridLines: {
            display: false,
            drawBorder: false
          },
          ticks: {
            padding: 20,
            fontColor: theme.palette.text.secondary
          }
        }
      ],
      yAxes: [
        {
          stacked: true,
          gridLines: {
            drawBorder: false,
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider
          },
          ticks: {
            padding: 20,
            fontColor: theme.palette.text.secondary,
            beginAtZero: true,
            min: 0,
            maxTicksLimit: 5,
            precision: 0
          }
        }
      ]
    },
    // onClick,
    onClick: (event, elements) => {      
      try {
        const chart = elements[0]._chart
        const element = chart.getElementAtEvent(event)[0]
        const dataset = chart.data.datasets[element._datasetIndex]
        const xLabel = chart.data.labels[element._index] // x-axis labels
        const value = dataset.data[element._index]

        const dateRange = data.ranges[element._index]
        const parseDateRange = dateRange.utc().date(1).format('MM-DD-YYYY')
        const formattedDateRange = moment(parseDateRange, 'MM-DD-YYYY').format('MMMM YYYY')
        // const formattedDateRangeAndBooked = moment(parseDateRange, 'MM-DD-YYYY').format('MMMM YYYY')

        console.log(dataset.label + " at " + xLabel + ": " + value)
        console.log({
          elements,
          chart,
          element,
          dataset,
          data,
          dateRange,
          formattedDateRange,
        })

        if(element._index === 0) { // first column
          // console.log('first column inside')
          onClick(null, null, dataset.label.includes('booked'))
        } else {
          onClick('range', formattedDateRange, dataset.label.includes('booked'))
        }
      }
      catch (err) {
        console.log('Error parsing stacked bar graph data: ', err.message) // eslint-disable-line
      }
      // end of onClick function
    }
  }

  const dataAvailable = data.values.length > 1

  return (
    <>
      {dataAvailable ? (
        <Bar
          data={{
            labels: data.labels,
            responsive: true,
            offset: true,
            datasets: [
              { // Bottom Part of Column
                label: 'without booking',
                backgroundColor: data.colors,
                data: data.values,
              },
              { // Top Part of Column
                label: 'booked',
                backgroundColor: ['#b71c1c', '#ef6c00', '#ef6c00', '#2e7d32', '#2e7d32', '#2e7d32', '#2e7d32', '#2e7d32'],
                data: data.valuesStacked ? data.valuesStacked : [0, 0, 0, 0, 0, 0, 0, 0]
              }
            ]
          }}
          options={options}
        />
      ) : (
        <Typography variant="body1">
          {noDataMessage ? noDataMessage : 'No data available'}
          
          {showHealthAndSafetyDisclaimer && (
            <>
              <br />
              <span className={classes.link} onClick={() => setOpenDisclaimerMessage(true)}>
                View Health and Safety Disclaimer
              </span>{'.'}
            </>
          )} 
        </Typography>
      )}

      {openDisclaimerMessage && (
        <Modal
          open={openDisclaimerMessage}
          title="Health and Safety Disclaimer"
          onClose={() => setOpenDisclaimerMessage(false)}
          children="Please note there are possible implications to your obligations both under your finance contract/lease and the Health 
          and Safety at Work Act 2015 if the servicing requirements are not completed when due."
        />
      )}
    </>
  )
}

StackedBarChart.propTypes = {
  data: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  noDataMessage: PropTypes.string,
  showHealthAndSafetyDisclaimer: PropTypes.bool,
}

export default StackedBarChart
