import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import DriverDetailsForm from './form'
import { create,
  update as updateDriver,
  remove as terminateDriver,
  reset as resetDriverFlags,
  fetchByAgreement as fetchDriver,
  togglePoolVehicle,
  unassignVehicle,
  saveTemporary
} from 'features/drivers/actions'

import {
  resetLookups,
  reset as resetFleetFlags
} from '../../../actions'

import { trimObjectStrings } from 'utils'


const mapStateToProps = ({ fleet, drivers, settings }, ownProps) => ({
  driverData: fleet.related,
  fleetData: trimObjectStrings(fleet.data?.[ownProps.match.params?.id]),
  driverFlags: drivers.flags,
  fleetFlags: fleet.flags,
  fleetIds: settings.fleet.selected,
  driverConfirmationMessage: drivers.apiResponse,
  userRoles: settings.roles
})

const mapDispatchToProps = (dispatch) => ({
  onSaveNewDriver: (payload) => {
    dispatch(create(payload))
  },
  onUpdateDriver: (uniqueId, payload) => {
    dispatch(updateDriver(payload, { resourceId: uniqueId }))
  },
  onTerminateDriver: (uniqueId) => {
    dispatch(terminateDriver(null, { resourceId: uniqueId }))
  },
  onFetchDriver: (agreementNo, fleetIds) => {
    const FleetId = fleetIds.join(',')
    dispatch(fetchDriver(null, { resourceId: agreementNo, queryParams: { FleetId } }))
  },
  onResetFleetFlags: () => {
    dispatch(resetFleetFlags())
  },
  onResetDriverFlags: () => {
    dispatch(resetDriverFlags())
  },
  onTogglePoolVehicle: (agreementNo, fleetId, changeDate, isPoolVehicle) => {
    dispatch(togglePoolVehicle(null, { resourceId: `${agreementNo}/${fleetId}/${changeDate.format("YYYY-MM-DD")}/${isPoolVehicle}` }))
  },
  onUnassignVehicle: (agreementNo, fleetId) => {
    dispatch(unassignVehicle(null, { resourceId: `${agreementNo}/${fleetId}` }))
  },
  onResetLookups: () => {
    dispatch(resetLookups())
  },
  onSaveTemporary: (payload) => {
    dispatch(saveTemporary(payload))
  } 
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(DriverDetailsForm))
