import { connect } from 'react-redux'
import Edit from './edit'
import { reset, fetch, newDraft } from '../../actions'


const mapStateToProps = ({ fbtSubmissions, settings }, ownProps) => ({
  id: ownProps.match.params?.id,
  data: fbtSubmissions?.data[ownProps.match.params?.id],
  flags: {
    ...fbtSubmissions.flags,
    unsavedChanges: JSON.stringify(fbtSubmissions?.originalDrafts) !== JSON.stringify(fbtSubmissions?.drafts),
  },
  filtered: fbtSubmissions?.filtered,
  userRoles :settings.roles
})

const mapDispatchToProps = (dispatch) => ({
  onReset: () => {
    dispatch(reset())
  },
  onFetch: (id, fleetIds) => {
    const FleetId = fleetIds.join(',')
    dispatch(fetch(null, {
      resourceId: id,
      queryParams: { FleetId }
    }))
  },
  onNewDraft: () => {
    dispatch(newDraft())
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Edit)
