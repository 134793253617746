import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, useTheme } from '@material-ui/styles'
import { Button, Card, CardActions, CardContent, Grid, Typography } from '@material-ui/core'

import { Alert, SingleSelect, EmailTextField, RowValueText } from 'frame/components'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'


const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3),
  },
  error: {
    marginBottom: theme.spacing(4),
  },
  content: {
    padding: theme.spacing(3, 1.2, 3, 1.2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
  },
  fieldHeader: {
    marginTop: theme.spacing(5),
  },
  fieldGroup: {
    margin: theme.spacing(3, 0, 2, 0),
  },
  actions: {
    flexDirection: 'column',
    paddingBottom: theme.spacing(3),
    '& > *': { width: '100%' },
    '& > * + *': { marginLeft: 0, marginTop: 8 },
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      '& > *': { width: 'auto' },
      '& > * + *': { marginLeft: 8, marginTop: 0 },
    },
  },
}))

const NewDriverForm = ({
  disabled,
  driverFlags,
  values,
  // functions
  onChange,
  onProceed,
  onBack,
}) => {

  const form = useRef()
  const classes = useStyles()

  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'))

  const onInputChange = ({ target: { id, name, value } }) =>
    onChange({ ...values, [id || name]: value })

  const onSelectChange = (key) => (value) =>
    onChange({ ...values, [key]: value })
  
    
  const onCreateAndAssignValidation = () => {
    if (form.current) {
      form.current.submit()
    }
  }
  
  const onSubmitForm = () => onProceed()
  
  useEffect(() => {
    ValidatorForm.addValidationRule('isNotSpaces', (value) => {
      let cleanedValue = value.replace(/ /g, '')
      return cleanedValue && cleanedValue.length > 0
    })
    return () => {
      ValidatorForm.removeValidationRule('isNotSpaces')
    }
  }, [values.driver])

  return (
    <ValidatorForm autoComplete="off" noValidate ref={form} onSubmit={onSubmitForm}>
      <Card>
        <CardContent className={classes.content}>
          <Grid container spacing={2}>
            
            {driverFlags.create === 'error' && (
              <Grid item xs={12}>
                <Alert>
                  Error occured. Create driver failed. Please try again or contact support.
                </Alert>
                <br />
              </Grid>
            )}

            <Grid item xs={12}>
              <Typography gutterBottom variant="h4">
                New Driver Details
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <RowValueText label="Name" responsive>
                <TextValidator
                  fullWidth
                  size="small"
                  id="driver"
                  label={mobileView ? 'Name' : ''}
                  InputLabelProps={{ shrink: mobileView }} // to show label only on mobile
                  disabled={disabled}
                  value={values.driver || ''}
                  inputProps={{ maxLength: 50 }}
                  variant="outlined"
                  validators={['required', 'isNotSpaces']}
                  errorMessages={['This field is required', 'Please enter a valid name']}
                  onChange={onInputChange} />
              </RowValueText>
            </Grid>

            <Grid item xs={12}>
              <RowValueText label="Email" responsive>
                <EmailTextField
                  fullWidth
                  size="small"
                  id="email"
                  label={mobileView ? 'Email' : ''}
                  InputLabelProps={{ shrink: mobileView }} // to show label only on mobile
                  disabled={disabled}
                  inputProps={{ maxLength: 50 }}
                  value={values.email || ''}
                  variant="outlined"
                  onChange={onSelectChange('email')}
                />
              </RowValueText>
            </Grid>

            <Grid item xs={12}>
              <RowValueText label="Mobile" responsive>
                <TextValidator
                  fullWidth
                  id="mobile"
                  size="small"
                  label={mobileView ? 'Mobile' : ''}
                  InputLabelProps={{ shrink: mobileView }} // to show label only on mobile
                  disabled={disabled}
                  value={values.mobile || ''}
                  inputProps={{ maxLength: 50 }}
                  validators={['isNumber']}
                  errorMessages={['A valid phone number is required']}
                  variant="outlined"
                  onChange={onInputChange}
                />
              </RowValueText>
            </Grid>

            <Grid item xs={12}>
              <RowValueText label="Notification Method" responsive>
                <SingleSelect
                  noNone
                  size="small"
                  id="driverNotification"
                  disabled={disabled}
                  label="Notification Method"
                  hideLabelOnMobile={!mobileView} // to show label only on mobile
                  options={['Email', 'Text', 'In App', 'N/A']}
                  value={values.driverNotification || 'In App'}
                  onChange={onSelectChange('driverNotification')}
                />
              </RowValueText>
            </Grid>

          </Grid>
        </CardContent>

        <CardActions className={classes.actions}>
          <Button color="primary" disabled={disabled} variant="contained" onClick={onCreateAndAssignValidation}>
            Create & Assign Vehicle
          </Button>
          <Button disabled={disabled} variant="contained" onClick={onBack}>
            Back
          </Button>
        </CardActions>
      </Card>
    </ValidatorForm>
  )
}

NewDriverForm.propTypes = {
  disabled: PropTypes.bool,
  driverFlags: PropTypes.object,
  values: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onProceed: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
}

export default NewDriverForm
