import React, { useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Listable } from 'listable'
import { useNavigate } from 'utils'
import { useSettingsFleetValues } from 'features/settings'
import Row from './row'
import Filters from '../filters'
import { ChildRoutes } from '../../routes'
import { encodeUserId } from 'features/admin-users/utils'
import { actions, config, constants } from '../../module'


const List = ({ model, userRoles, onRequestRoles, onReset, onDelete }) => {

  const linker = useNavigate()
  const fleets = useSettingsFleetValues()

  const renderItem = useCallback((item, index, itemProps) => {

    const onView = linker(constants.PATH_SINGLE, encodeUserId(item.id))

    return (
      <Row
        key={index}
        item={item}
        fleets={fleets}
        onView={onView}
        userRoles={userRoles}
        onDelete={onDelete}
        {...itemProps}/>
    )
  }, [fleets, linker, userRoles, onDelete])

  // Prevents re-rendering if ids or data updated - no longer needed
  const { ids, filters, ...rest } = model
  
  useEffect(() => {
    onReset()
    onRequestRoles()
  }, [onReset, onRequestRoles])

  return (
    <Listable
      actions={actions}
      config={config}
      filters={Filters}
      model={rest}
      renderItem={renderItem}
      routes={ChildRoutes}
      primaryButton={{
        label: 'Add User',
        action: linker(constants.PATH_ADD),
      }}
    />
  )
}

List.propTypes = {
  model: PropTypes.object.isRequired,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  onRequestRoles: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

const mapStateToProps = ({ adminInvitations, settings, account }) => ({
  model: adminInvitations,
  userRoles: settings.roles,
})

const mapDispatchToProps = (dispatch) => ({
  onRequestRoles: () => {
    dispatch(actions.requestRoles())
  },
  onReset: () => {
    dispatch(actions.reset())
  },
  onDelete: (userId) => {
    console.log('Delete userId ', userId)
    dispatch(actions.requestRemoveUser(null, {
      resourceId: userId
    }))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(List)
