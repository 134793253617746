import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Listable } from 'listable'
import { useNavigate, trimObjectStrings } from 'utils'
import Row from './row'
import Filters from '../filters'
import { ChildRoutes } from '../../routes'
import { actions, config, constants } from '../../module'


const List = ({ account, model }) => {

  const linker = useNavigate()

  const renderItem = useCallback((item, index, itemProps) => {

    const onView = linker(constants.PATH_DRILLDOWN, item.agreementNo, 'contract-details')

    return (
      <Row key={index} account={account} item={trimObjectStrings(item)} onView={onView} {...itemProps}/>
    )
  }, [account, linker])

  // Prevents re-rendering if ids or data updated - no longer needed
  const { ids, filters, ...rest } = model

  return (
    <Listable
      actions={actions}
      config={{ ...config, title: 'Fleet' }}
      filters={Filters}
      model={rest}
      renderItem={renderItem}
      routes={ChildRoutes}/>
  )
}

List.propTypes = {
  account: PropTypes.string,
  model: PropTypes.object.isRequired,
}

const mapStateToProps = ({ fleet, settings }) => ({
  account: settings?.account,
  model: fleet,
})

const mapDispatchToProps = {}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(List)
