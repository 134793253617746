import React, { Fragment, useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { /* Drawer, */ Hidden, Paper } from '@material-ui/core'
import { useRouter } from 'utils'
import Navigation from './navigation'
import { NavigationType } from '../types'


const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    overflowY: 'auto',
  },
  content: {
    padding: theme.spacing(2)
  },
  navigation: {
    marginTop: theme.spacing(2)
  }
}))

const NavBar = ({ className, navigation, openMobile, onMobileClose, ...rest }) => {

  const classes = useStyles()
  const router = useRouter()

  useEffect(() => {
    if (openMobile) {
      onMobileClose && onMobileClose()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.location.pathname])

  const navbarContent = (
    <div className={classes.content}>
      <nav className={classes.navigation}>
        {navigation.map(list => (
          <Navigation
            component="div"
            key={list.title}
            pages={list.pages}
            title={list.title} />
        ))}
      </nav>
    </div>
  )

  return (
    <Fragment>
      {/* <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary">
          <div
            {...rest}
            className={clsx(classes.root, className)}>
            {navbarContent}
          </div>
        </Drawer>
      </Hidden>*/}
      <Hidden xsDown>
        <Paper
          {...rest}
          className={clsx(classes.root, className)}
          elevation={1}
          square>
          {navbarContent}
        </Paper>
      </Hidden>
    </Fragment>
  )
}

NavBar.propTypes = {
  className: PropTypes.string,
  navigation: NavigationType,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
}

export default NavBar
