import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { Dialog } from 'frame/components'
import { useNavigate } from 'utils'
import { constants } from '../../module'
import Form from './form'


const Item = ({ id, data, flags, errorMessage, onFetchIncidentDetails, onSaveIncidentNotes }) => {

  const navigate = useNavigate()
  const onClose = navigate(constants.PATH_ALL)

  useEffect(() => {
    onFetchIncidentDetails(id)
  }, []) // eslint-disable-line

  const title = data?.registration
    ? `Incident - ${data?.registration}`
    : `Incident`

  const isLoading = flags?.fetchDetails === 'processing' && ! data
  const hasErrored = !data

  const loadingErrorMessage = hasErrored
    ? `We were unable to find the incident you were looking for.`
    : ''
  
  return (
    <Dialog
      open autoSize
      size="md"
      aria-labelledby="Incident Management"
      title={title}
      errorMessage={loadingErrorMessage}
      loading={isLoading}
      onClose={onClose}
    >
      <Grid container>
        <Grid item md={12} xs={12}>
          <Form
            data={{ ...data }}
            flags={flags}
            errorMessage={errorMessage}
            onClose={onClose}
            onSaveIncidentNotes={onSaveIncidentNotes}
          />
        </Grid>
      </Grid>
    </Dialog>
  )
}

Item.propTypes = {
  id: PropTypes.number.isRequired,
  data: PropTypes.object,
  flags: PropTypes.object,
  attachments: PropTypes.array.isRequired,
  errorMessage: PropTypes.string,
  // func
  onFetchIncidentDetails: PropTypes.func.isRequired,
  onSaveIncidentNotes: PropTypes.func.isRequired,
}

export default Item
