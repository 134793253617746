import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@material-ui/styles'
import { useMediaQuery } from '@material-ui/core'
import TopBarDesktop from './top-bar-desktop'
import TopBarMobile from './top-bar-mobile'
import { useIsAlfaDown } from 'features/drivers'
import { connect } from 'react-redux'
import { useEnabledFeatureToggleList } from 'features/settings'



const TopBar = ( { userRoles , ...props }) => {
  
  const featureToggleList = useEnabledFeatureToggleList()
  const theme = useTheme()
  const isAlfaDown = useIsAlfaDown()
  const isReadonlyFeatureToggleEnabled = featureToggleList.includes('TFP2063')
  
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
  
  return isSmall ? (
    <TopBarMobile
      {...props}
      showAlfaOutageMessage={isAlfaDown}
      showIsPortalReadOnlyMessage={isReadonlyFeatureToggleEnabled}
    />
  ) : (
    <TopBarDesktop
      {...props}
      userRoles={userRoles}
      showAlfaOutageMessage={isAlfaDown}
      showIsPortalReadOnlyMessage={isReadonlyFeatureToggleEnabled}
    />
  )
}

TopBar.propTypes = {
  notifications: PropTypes.any,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,

}

const mapStateToProps = ({ settings }) => ({
  userRoles: settings.roles,
})


export default connect(
  mapStateToProps
)(TopBar)

