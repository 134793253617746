import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { Layout } from 'frame'
import { Add, List } from './components'
import { constants } from './module'


export const ModuleRoutes = () => (
  <Layout>
    <Switch>
      <Route path={constants.ROUTE_ALL} component={List} />
    </Switch>
  </Layout>
)

export const ChildRoutes = () => (
  <Switch>
    <Route path={constants.ROUTE_ADD} component={Add} />
  </Switch>
)
