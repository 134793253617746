import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Grid, Typography } from '@material-ui/core'
import moment from 'moment'


const useStyles = makeStyles(theme => ({
  content: {
    margin: theme.spacing(2, 0),
  },
  odometer: {
    width: '22px',
    height: '44px',
    backgroundImage: 'url("/static/images/digital_service_book/odometer_bg.png")',
    background: 'cover',
    backgroundRepeat: 'no-repeat',
    marginRight: 2,
    textAlign: 'center',
    position: 'relative',
  },
  odometerNumber: {
    fontWeight: 'bold',
    fontSize: '22px',
    margin: 0,
    position: 'absolute',
    top: '50%',
    left: '25%',
    transform: 'translateY(-50%)',
  },
  odometerSuffix: {
    padding: '11px',
    fontWeight: 'bold',
    fontSize: '18px',
  },
  odometerLastReadingDate: {
    textAlign: 'right',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
    },
  }
}))

const Odometer = ({ data }) => {

  const classes = useStyles()

  const generateOdometerValues = () => {
    const odometerReadingString = data.lastOdoReading ? String(data.lastOdoReading) : ''
    let odometerReading = data.lastOdoReading ? Array.from(odometerReadingString, Number) : []

    if(odometerReadingString.length < 6) {
      const diff = 6 - odometerReadingString.length
      for(let i = 0; i < diff; i++) {
        odometerReading.unshift(0) // add 0s in front of array
      }  
    }
    return odometerReading
  }

  return (
    <div className={classes.content}>
      <Grid container spacing={1}>
        <Grid item md={6} xs={12}>

          <div style={{ display: 'flex' }}>
            {generateOdometerValues().map((number, index) => (
              <div className={classes.odometer} key={`odometerNo-${index}`}>
                <span className={classes.odometerNumber}>
                  {number}
                </span>
              </div>
            ))}

            <Typography variant="h6" className={classes.odometerSuffix}>
              km
            </Typography>
          </div>
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography variant="h5" className={classes.odometerLastReadingDate}>
            <strong>Date of Last KM Reading:</strong> {data.lastKmReading ? moment(data.lastKmReading).format('DD/MM/YYYY') : 'N/A'}
          </Typography>
        </Grid>
      </Grid>
    </div>
  )
}

Odometer.propTypes = {
  data: PropTypes.object,
}

export default Odometer
