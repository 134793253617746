import PropTypes from 'prop-types'


export const model = [{
  key: 'agreementNo',
  label: 'Agreement No.',
  column: {
    visible: true,
    width: 115,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'costCentre',
  label: 'Cost Centre',
  column: {
    visible: true,
    width: 120,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'driver',
  label: 'Driver',
  column: {
    visible: false,
    width: 190,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'expectedDeliveryDate',
  label: 'Expected Delivery Date',
  column: {
    visible: true,
    width: 160,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'fuelType',
  label: 'Fuel Type',
  column: {
    visible: false,
    width: 90,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'make',
  label: 'Make',
  column: {
    visible: true,
    width: 90,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'model',
  label: `Model`,
  column: {
    visible: true,
    width: 90,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'poRaisedDate',
  label: `P.O. Raised Date`,
  column: {
    visible: true,
    width: 120,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'previousAgreement',
  label: `Previous Agreement`,
  column: {
    visible: false,
    width: 140,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'previousRegistration',
  label: `Previous Registration`,
  column: {
    visible: false,
    width: 160,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'quoteBundle',
  label: `Quote Bundle`,
  column: {
    visible: true,
    width: 110,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'requestedDeliveryDate',
  label: 'Requested Delivery Date',
  column: {
    visible: true,
    width: 170,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}
// , {
//   key: 'sslvNumber',
//   label: 'SSLV Number',
//   premium: true,
//   column: {
//     visible: false,
//     width: 160,
//   },
//   prop: {
//     type: PropTypes.string,
//     value: '',
//   }
// }
, {
  key: 'variant',
  label: 'Variant',
  column: {
    visible: false,
    width: 200,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'year',
  label: 'Year',
  column: {
    visible: false,
    width: 80,
  },
  prop: {
    type: PropTypes.number,
    value: '',
  }
}, {
  key: 'location',
  label: 'Location',
  column: {
    visible: false,
    width: 220,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'accountName',
  label: 'Fleet',
  column: {
    visible: true,
    width: 80,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'orderId',
  label: 'Order Id',
  column: {
    visible: true,
    width: 80,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}]

const config = {
  title: 'Vehicle On-Order',
  breadcrumbs: null,
  backgroundImage: 'url("/static/images/backgrounds/bg4.png")',
  api: {
    filter: '/filteroptions/vehicleonorder',
    resource: `/report/vehicleonorder`,
    download: `/download/vehicleonorder`,
  },
  model,
  headers: [
    'accountName','orderId','quoteBundle', 'agreementNo', 'previousAgreement', 'assetBranch', 'assetFunction', 'driver', 'costCentre',
    'year', 'make', 'model', 'variant', 'fuelType','poRaisedDate',
    'requestedDeliveryDate', 'expectedDeliveryDate', 'previousRegistration', 'location',
    //'sslvNumber',
  ],
  filters: [{
    key: 'costCentre',
    label: 'Cost Centre',
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }, {
    key: 'make',
    label: 'Make',
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }, {
    key: 'model',
    label: 'Model',
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }, {
    key: 'expectedDeliveryDate',
    label: 'Expected Delivery Month',
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }, {
    key: 'poRaisedDate',
    label: 'P.O Raised Month',
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }],
}

export default config