import React from 'react'
import { makeStyles } from '@material-ui/styles'

import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography
} from '@material-ui/core'

import { useAuth } from 'auth'
import { Brand, IEWarningPopup } from 'frame/components'


const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(6, 2),
  },
  card: {
    width: theme.breakpoints.values.md,
    maxWidth: '100%',
    overflow: 'unset',
    display: 'flex',
    position: 'relative',
    '& > *': {
      flexGrow: 1,
      flexBasis: '50%',
      width: '50%'
    },
    '@media(max-width: 760px)': {
    // [theme.breakpoints.down('sm')]: {
      maxWidth: '400px',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: theme.spacing(3, 4, 3, 4),
    minHeight: 300,
  },
  media: {
    position: 'relative',
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    padding: theme.spacing(3),
    color: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    '@media(max-width: 760px)': {
    // [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
  },
  icon: {
    display: 'flex',
    paddingBottom: theme.spacing(3),
    color: theme.palette.white,
  },
  submitButton: {
    marginTop: theme.spacing(3),
    width: '100%'
  },
}))

const Login = () => {

  const classes = useStyles()
  const { loginWithRedirect } = useAuth()

  const isIE = /*@cc_on!@*/false || !!document.documentMode

  const onLogin = () =>
    loginWithRedirect({})

  return (
    <div className={classes.root} title="Login">

      {isIE && <IEWarningPopup />}

      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <div className={classes.header}>
            <div className={classes.icon}>
              <Brand />
            </div>
            <Typography gutterBottom variant="body1">
              <strong>Disclaimer:</strong>
              <br />
              <br />
              Please note there are possible implications to your obligations both under your finance contract/lease and the Health 
              and Safety at Work Act 2015 if the servicing requirements are not completed when due.
            </Typography>
          </div>

          <Button
            className={classes.submitButton}
            color="primary"
            size="large"
            type="submit"
            variant="contained"
            onClick={onLogin}>
            Sign in
          </Button>
          
        </CardContent>
        <CardMedia
          className={classes.media}
          image="/static/images/login-image.jpg"
          title="Toyota">
        </CardMedia>
      </Card>
    </div>
  )
}

export default Login
