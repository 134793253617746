import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { makeStyles } from '@material-ui/styles'
import { TableCell, TableRow, Typography, Tooltip, IconButton } from '@material-ui/core'
import { Label, FleetIcon } from 'frame/components'
import { getDateColor, obfuscateAgreementNumber } from 'listable'
import { types } from '../../module'
import { createRowSelector, useRouter } from 'utils'
import { useEnabledFeatureToggleList } from 'features/settings'
import ErrorIcon from '@material-ui/icons/Error'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'

const useStyles = makeStyles(theme => ({
  root: {
    cursor: 'pointer',
    verticalAlign: 'text-top',
  },
  centerCell: {
    display: 'flex',
    justifyContent: 'center',
  },
  label: theme.typography.bodySmall,
  unReliableLabel: {
    ...theme.typography.bodySmall,
    color: theme.palette.brand,
  },
  unReliableLabelIcon: {
    height: 18,
    marginBottom: -4,
    position: 'absolute',
    marginLeft: '-24px',
    marginTop: '-1px',
  },
  documentIcon: { color: '#00B6B0' },
  spacer: { margin: 0, padding: 0 },
  unReliableTooltip: {
    backgroundColor: '#FFF',
    color: theme.palette.brand,
    filter: 'drop-shadow(0px 1.5px 1.5px gray)',
  },
  unReliableTooltipArrow: {
    color: '#FFF',
    top: '21px',
  }
}))

const Row = ({ item, columns, onView }) => {

  const classes = useStyles()
  const { history, location } = useRouter()
  const onSafeRowClick = createRowSelector(onView)

  const featureToggleList = useEnabledFeatureToggleList()
  const documentsReportFeatureToggle = featureToggleList.includes('DocumentsViewing')
  const tfp1839FeatureToggle = featureToggleList.includes('TFP1839')
  const kmExceptionsFeatureToggle = featureToggleList.includes('KMExceptions')
  const tfp1944FeatureToggle = featureToggleList.includes('TFP1944')
  const tfp2005FeatureToggle = featureToggleList.includes('TFP2005')

  const onViewDocumentsReport = () => {
    history.push(`/reports/documents?registration=${item.registration}`, { label: 'Vehicle Servicing',  path: location.pathname })
  }

  return (
    <TableRow hover className={classes.root} onClick={onSafeRowClick}>
      <TableCell className={classes.spacer} />
      {columns.accountName && (
        <TableCell className={classes.label}>
          <FleetIcon fleetName={item.accountName} />
        </TableCell>
      )}
      {columns.agreementNo && (
        <TableCell className={classes.label}>
          {obfuscateAgreementNumber(item.agreementNo || '-', true)}
        </TableCell>
      )}
      {columns.registration && (
        <TableCell className={classes.label}>
          {item.registration || '-'}
        </TableCell>
      )}
      {columns.driverNote && tfp1944FeatureToggle && (
        <TableCell className={classes.label}>
          <Typography color="inherit" variant="body2">
            {item.driverNote || '-'}
          </Typography>
        </TableCell>
      )}
      {columns.driver && (
        <TableCell className={classes.label}>
          <Typography color="inherit" variant="body2">
            {item.driver || '-'}
          </Typography>
        </TableCell>
      )}
      {columns.year && (
        <TableCell className={classes.label}>
          {item.year || '-'}
        </TableCell>
      )}
      {columns.make && (
        <TableCell className={classes.label}>
          {item.make || '-'}
        </TableCell>
      )}
      {columns.model && (
        <TableCell className={classes.label}>
          {item.model || '-'}
        </TableCell>
      )}
      {columns.variant && (
        <TableCell className={classes.label}>
          {item.variant || '-'}
        </TableCell>
      )}
      {columns.fuelType && (
        <TableCell className={classes.label}>
          {item.fuelType || '-'}
        </TableCell>
      )}
      {columns.costCentre && (
        <TableCell className={classes.label}>
          {item.costCentre || '-'}
        </TableCell>
      )}
      {columns.controller && tfp1839FeatureToggle && (
        <TableCell className={classes.label}>
          {item.controller || '-'}
        </TableCell>
      )}      
      {columns.startDate && (
        <TableCell className={classes.label}>
          <div className={classes.centerCell}>
            {item.startDate ? moment(item.startDate).format('DD/MM/YY') : '-'}
          </div>
        </TableCell>
      )}
      {columns.dateOfLastService && (
        <TableCell className={classes.label}>
          <div className={classes.centerCell}>
            {item.dateOfLastService ? moment(item.dateOfLastService).format('DD/MM/YY') : '-'}
          </div>
        </TableCell>
      )}
      {columns.serviceOdometer && (
        <TableCell className={item.isReliable===false && kmExceptionsFeatureToggle ? classes.unReliableLabel : classes.label}>
          {item.isReliable===false && kmExceptionsFeatureToggle &&
            <Tooltip title="NOT RELIABLE" arrow placement="top" classes={{ tooltip: classes.unReliableTooltip, arrow: classes.unReliableTooltipArrow }}>
              <ErrorIcon className={classes.unReliableLabelIcon} />
            </Tooltip>
          }
          {item.serviceOdometer ? parseInt(item.serviceOdometer, 10).toLocaleString('en-NZ') : '-'}
        </TableCell>
      )}
      {columns.dateOfLastKmReading && (
        <TableCell className={classes.label}>
          {item.dateOfLastKmReading ? moment(item.dateOfLastKmReading).format('DD/MM/YY') : '-'}
        </TableCell>
      )}
      {columns.sourceOfKmReading && (
        <TableCell className={classes.label}>
          {item.sourceOfKmReading || '-'}
        </TableCell>
      )}
      {columns.odometer && (
        <TableCell className={classes.label}>
          {item.odometer ? parseInt(item.odometer, 10).toLocaleString('en-NZ') : '-'}
        </TableCell>
      )}
      {columns.estNextServiceDueDate && (
        <TableCell className={classes.label}>
          {item.estNextServiceDueDate ? (
            <Label
              color={getDateColor(item.estNextServiceDueDate)}
              variant="outlined">
              {moment(item.estNextServiceDueDate).format('DD/MM/YY')}
            </Label>
          ) : '-'}
        </TableCell>
      )}
      {columns.estServiceDateLogic && (
        <TableCell className={classes.label}>
          {item.estServiceDateLogic || '-'}
        </TableCell>
      )}
    
      {tfp2005FeatureToggle && columns.maturityDate && (
        <TableCell className={classes.label}>
          {item.maturityDate ? (
            <Label
              color={getDateColor(item.maturityDate)}
              variant="outlined">
              {moment(item.maturityDate).format('DD/MM/YY')}
            </Label>
          ) : '-'}
        </TableCell>
      )}
      {tfp2005FeatureToggle && columns.originalMaturityDate && (
        <TableCell className={classes.label}>
          <div className={classes.centerCell}>
            {item.originalMaturityDate ? moment(item.originalMaturityDate).format('DD/MM/YY') : '-'}
          </div>
        </TableCell>
      )}
      {columns.agreementType && (
        <TableCell className={classes.label}>
          {item.agreementType || '-'}
        </TableCell>
      )}
      {columns.serviceBooked && (
        <TableCell className={classes.label}>
          {item.serviceBooked ? 'Yes' : 'No'}
        </TableCell>
      )}
      {columns.serviceStatus && (
        <TableCell className={classes.label}>
          {item.serviceStatus || '-'}
        </TableCell>
      )}
      {columns.bookedDate && (
        <TableCell className={classes.label}>
          {item.bookedDate ? (
            <Label
              variant="outlined">
              {moment(item.bookedDate).format('DD/MM/YY')}
            </Label>
          ) : '-'}
        </TableCell>
      )}
      {columns.bookedLocation && (
        <TableCell className={classes.label}>
          {item.bookedLocation || '-'}
        </TableCell>
      )}
      {columns.completedLocation && (
        <TableCell className={classes.label}>
          {item.completedLocation || '-'}
        </TableCell>
      )}
      
      {columns.documentsCount && documentsReportFeatureToggle && (
        <TableCell className={classes.label}>
          {( item.documentsCount > 0 ? (            
            <Tooltip title="View documents">
              <IconButton className="ignore" onClick={onViewDocumentsReport}>
                <InsertDriveFileIcon className={classes.documentIcon} />
              </IconButton>
            </Tooltip>
          ) : '-')}
        </TableCell>
      )}
      <TableCell className={classes.spacer} />
    </TableRow>
  )
}

Row.propTypes = {
  item: types.ModelType.isRequired,
  columns: types.ColumnsType.isRequired,
  onView: PropTypes.func.isRequired,
}

export default Row
