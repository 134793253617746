import { reduceRequest } from 'listable'
import * as types from './constants'
import * as R from 'ramda'


const resetFlags = (state, flags) => ({
  ...state,
  editUserData: null,
  errorMessage: null,
  flags: {
    ...state.flags,
    fetch: 'none',
    fetchRoles: 'none',
    create: 'none',
    update: 'none',
    delete: 'none',
    ...flags,
  },
})

const updateFlags = (state, flags) => ({
  ...state,
  flags: {
    ...state.flags,
    ...flags,
  }
})


const reducer = (state, action) => {
  switch (action.type) {
    case types.RESET:
      return resetFlags(state)

    // TODO: refactor out use of reduceRequest and unused actions
    case types.REQUEST_USER_INVITATIONS:
    case types.REQUEST_USER_INVITATIONS_ERROR:
    case types.REQUEST_USER_INVITATIONS_FULFILLED:
    case types.REQUEST_USER_UPDATE:
    case types.REQUEST_USER_UPDATE_ERROR:
    case types.REQUEST_USER_UPDATE_FULFILLED:
    case types.REQUEST_REMOVE_USER:
    case types.REQUEST_REMOVE_USER_ERROR:
    case types.REQUEST_REMOVE_USER_FULFILLED:
    case types.REQUEST_ASSIGN_ROLE:
    case types.REQUEST_ASSIGN_ROLE_ERROR:
    case types.REQUEST_ASSIGN_ROLE_FULFILLED:
    case types.REQUEST_UNASSIGN_ROLE:
    case types.REQUEST_UNASSIGN_ROLE_ERROR:
    case types.REQUEST_UNASSIGN_ROLE_FULFILLED:
    case types.REQUEST_ASSIGN_FLEET:
    case types.REQUEST_ASSIGN_FLEET_ERROR:
    case types.REQUEST_ASSIGN_FLEET_FULFILLED:
    case types.REQUEST_UNASSIGN_FLEET:
    case types.REQUEST_UNASSIGN_FLEET_ERROR:
    case types.REQUEST_UNASSIGN_FLEET_FULFILLED:
      return reduceRequest(state, action)
    case types.ADD_ROLES:
      return addRoles(state, action)
    case types.ADD_USERS:
      return addUsers(state, action)
    case types.ON_CHANGE_USER:
      return onChangeUser(R.clone(state), action)

    // Create User
    case types.REQUEST_ADD_USER:
      return resetFlags(state, { create: 'processing' })
    case types.REQUEST_ADD_USER_ERROR:
      return addUserError(R.clone(state), action)
    case types.REQUEST_ADD_USER_FULFILLED:
      return updateFlags(state, { create: 'processed' })
    
    // Request Filters
    case types.REQUEST_ROLES:
      return resetFlags(state, { fetchRoles: 'processing' })
    case types.REQUEST_ROLES_ERROR:
      return updateFlags(state, { fetchRoles: 'error' })
    case types.REQUEST_ROLES_FULFILLED:
      return updateFlags(state, { fetchRoles: 'processed' })
    
    default:
      return state
  }
}

export default reducer 

const onChangeUser = (state, action) => {
  const id = action.payload.id
  state.users[id] = action.payload
  return state
}

const addRoles = (state, action) => {

  const sanitizeRoleName = (value = '') =>
    value
      .match(/[A-Z][a-z]+/g)
      .join(' ')

  const filters = action.payload
    .map(node => ({
      label: sanitizeRoleName(node.name),
      value: node.id,
    }))

  return {
    ...state,
    filters: {
      ...state.filters,
      roles: filters,
    },
    roles: action.payload,
  }
}

const addUsers = (state, action) => {

  const list = Array.isArray(action.payload)
    ? action.payload
    : [action.payload]

  const users = {}

  list.forEach(node => {
    users[node.id] = node
  })

  const listIds = list
    .map(node => node.id)
    .filter(x => x)

  const userIds = state.userIds && Array.from(new Set([
    ...state?.userIds,
    ...listIds,
  ]))

  return {
    ...state,
    users,
    userIds,
  }
}

const addUserError = (state, action) => {
  state.errorMessage = action.payload
  return updateFlags(state, {
    create: 'error',
  })
}
