import * as fromActions from './actions/types'
import * as R from 'ramda'
import * as fromDriverActions from 'features/drivers/actions/types'

const reducer = (state, action) => {
  switch (action.type) {
    // Fetch Contract by agreementNo
    case fromActions.FETCH:
      return updateFlags(state, { fetch: 'processing' })
    case fromActions.FETCH_FULFILLED:
      return addFetchedContract(state, action)
    case fromActions.FETCH_ERROR:
      return onUpdateError(state, action, { fetch: 'error' })

    // Fetch Service History Data
    case fromActions.FETCH_SERVICE_HISTORY: {
      state.downloadStatus = null
      return updateFlags(state, { fetchServiceHistory: 'processing', downloadServiceHistoryReport: null })
    }
    case fromActions.FETCH_SERVICE_HISTORY_FULFILLED:
      return addFetchedServiceHistory(state, action)
    case fromActions.FETCH_SERVICE_HISTORY_ERROR:
      return onUpdateError(state, action, { fetchServiceHistory: 'error' })

    // Fetch Service History Data
    case fromActions.UPDATE_SERVICE_HISTORY_RELIABLE_FLAG: {
      state.downloadStatus = null
      return updateFlags(state, { fetchServiceHistory: 'processing' })
    }
    case fromActions.UPDATE_SERVICE_HISTORY_RELIABLE_FLAG_FULFILLED:
      return addFetchedServiceHistory(state, action)
    case fromActions.UPDATE_SERVICE_HISTORY_RELIABLE_FLAG_ERROR:
      return onUpdateError(state, action, { fetchServiceHistory: 'error' })

    // Fetch Service History Data
    case fromActions.FETCH_RELATED_FBT:
      return updateFlags(state, { fetchFbt: 'processing' })
    case fromActions.FETCH_RELATED_FBT_FULFILLED:
      return addFetchedFbt(state, action)
    case fromActions.FETCH_RELATED_FBT_ERROR:
      return onUpdateError(state, action, { fetchFbt: 'error' })

    // Update Fleets/Contract Variation
    case fromActions.UPDATE:
      return resetFlags(state, { update: 'processing' })
    case fromActions.UPDATE_FULFILLED:
      return addContractVariation(state, action, { update: 'processed' })
    case fromActions.UPDATE_ERROR:
      return onUpdateError(state, action, { update: 'error' })
    
    // Delete Contract Variation
    case fromActions.DELETE_VARIATION:
      return resetFlags(state, { deleteVariation: 'processing' })
    case fromActions.DELETE_VARIATION_FULFILLED:
      return addContractVariation(state, action, { deleteVariation: 'processed' })
    case fromActions.DELETE_VARIATION_ERROR: 
      return onUpdateError(state, action, { deleteVariation: 'error' })
    
    case fromActions.ON_CHANGE: 
      return onChange(R.clone(state), action)
    case fromDriverActions.FETCH_BY_AGREEMENT_FULFILLED: 
      return addRelatedDriver(R.clone(state), action)

    // Lookup
    case fromActions.LOOKUP:
      return onLookup(R.clone(state), action)
    case fromActions.LOOKUP_ERROR:
      return onLookupError(R.clone(state), action)
    case fromActions.LOOKUP_FULFILLED:
      return onLookupSuccess(R.clone(state), action)

    // Submit KM Reading
    case fromActions.SUBMIT_KM_READING:
      return updateFlags(state, { submitKmReading: 'processing' })
    case fromActions.SUBMIT_KM_READING_FULFILLED:
      return addKmReading(state, { submitKmReading: 'processed' }, 'You have successfully added a KM reading.')
    case fromActions.SUBMIT_KM_READING_ERROR:
      return onUpdateErrorMessage(state, action, { submitKmReading: 'error' },  'Adding KM reading failed.')

    // Download Service History Report
    case fromActions.DOWNLOAD_VEHICLE_HISTORY: {
      state.downloadStatus = 'Preparing report for download...'
      return resetFlags(state, { downloadServiceHistoryReport: 'processing' })
    }
    case fromActions.DOWNLOAD_VEHICLE_HISTORY_FULFILLED: {
      state.downloadStatus = 'Download completed successfully'
      return updateFlags(state, { downloadServiceHistoryReport: 'processed' })
    }
    case fromActions.DOWNLOAD_VEHICLE_HISTORY_ERROR: {
      console.log('[download error]: ', action.payload)
      state.downloadStatus = 'Error occured, download failed'
      return onUpdateError(state, action, { downloadServiceHistoryReport: 'error' }) 
    }
    
    case fromActions.RESET_LOOKUPS:
      return onResetLookup(R.clone(state), action)
    case fromActions.RESET: // resets all flags on page load
      return resetFlags(state)
    default:
      return state
  }
}

// used on tfs modal open to set related driver record from driver list
const addRelatedDriver =  (state, { payload }) => {
  // console.log('[tfs fleet addRelatedDriver] ', payload )
  state.related = payload
  return state
}

const onChange = (state, { payload }) => {
  state.form = payload
  return state
}

const onUpdateError = (state, { payload }, flags) => {
  const error = payload
  state.errorMessage = typeof error === 'string' ? payload : ''
  return updateFlags(state, flags)
}

const onUpdateErrorMessage = (state, { payload }, flags, errorMessage) => {
  const error = payload
  state.apiResponse = typeof error === 'string' ? `${errorMessage} ${payload}` : ''
  return updateFlags(state, flags)
}

const updateFlags = (state, flags) => ({
  ...state,
  flags: { ...state.flags, ...flags }
})

const resetFlags = (state, flags) => ({
  ...state,
  errorMessage: null,
  flags: {
    ...state.flags,
    fetch: 'none',
    update: 'none',
    deleteVariation: 'none',
    submitKmReading: 'none',
    fetchServiceHistory: 'none',
    ...flags,
  },
})

const addFetchedContract = (state, { payload }) => ({
  ...state,
  form: payload,
  data: {
    ...state.data,
    [payload.agreementNo]: payload,
  },
  flags: {
    ...state.flags,
    fetch: 'processed',
  }
})

const addFetchedServiceHistory = (state, { payload }) => {
  // console.log('addFetchedServiceHistory [payload]', payload)
  state.relatedVehicleHistory = payload
  return updateFlags(state, { fetchServiceHistory: 'processed' })
}

const addKmReading = (state, flags, message) => {
  state.apiResponse = `${message}`;
  return updateFlags(state, { submitKmReading: 'processed' })
}
const addFetchedFbt = (state, { payload }) => {
  state.relatedFbt = payload
  return updateFlags(state, { fetchServiceHistory: 'processed' })
}

const addContractVariation = (state, action, flags) => {
  const id = action.payload.agreementNo
  // const prevData = state.data[id]
  return {
    ...state,
    form: action.payload,
    data: {
      ...state.data,
      [id]: action.payload,
    },
    flags: {
      ...state.flags,
      ...flags,
    }
  }
}

const onLookup = (state, { payload }) => {
  return updateFlags(state, { lookup: 'processing' })
}

const onLookupError = (state, { payload }) => {
  console.log('[onLookupError]: ', payload)
  return updateFlags(state, { lookup: 'error' })
}

const onLookupSuccess = (state, { payload, options }) => {

  console.log('[onLookupSuccess]: ', { payload, options })
  let lookupOptions = []

  if(payload && payload.length > 0) {
    const cleanData = [...(new Set(payload))]
      .reduce((result, element) => {
        const normalize = x => typeof x === 'string' ? x.toLowerCase() : x  
        if (result.every(otherElement => normalize(otherElement.value) !== normalize(element.value)))
          result.push(element)
  
        return result
      }, [])
  
    lookupOptions = cleanData
  }

  state.lookup = {
    ...state.lookup,
    [options.resourceId]: lookupOptions
  }
  return updateFlags(state, { lookup: 'processed' })
}

const onResetLookup = (state, { payload }) => {
  state.lookup = {}
  return state
}

export default reducer
