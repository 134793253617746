import { colors } from '@material-ui/core'
import palette from '../palette'


export default {
  root: {
    color: palette.icon,
    background: 'transparent',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.03)'
    }
  },
  colorPrimary: {
    color: palette.white,
    backgroundColor: palette.brand,
    '&:hover': {
      backgroundColor: palette.brandDarker,
    },
  },
  colorSecondary: {
    color: palette.dark,
    backgroundColor: colors.grey[200],
    '&:hover': {
      backgroundColor: colors.grey[400],
    },
  },
}
