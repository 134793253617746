import { combineEpics } from 'redux-observable'

import { effects as adminUsers } from 'features/admin-users'
import { effects as adminInvitations } from 'features/admin-invitations'
import { effects as registrationOfInterest } from 'features/admin-registration-of-interest'

import { effects as account } from 'features/account'
import { effects as drivers } from 'features/drivers'
import { effects as fleet } from 'features/fleet'
import { effects as settings } from 'features/settings'
import { effects as featureToggle } from 'features/feature-toggle'
import { effects as myDriveAppFeatureToggleSettings } from 'features/feature-toggle-settings'

import { effects as homepage } from 'features/homepage'

import { effects as graphEndOfLease } from 'features/graph-end-of-lease'
import { effects as graphKmTracking } from 'features/graph-km-tracking'
import { effects as graphMaturing } from 'features/graph-maturing'
import { effects as graphOnOrder } from 'features/graph-on-order'
import { effects as graphServiceExpiry } from 'features/graph-service-expiry'
import { effects as graphWofExpiry } from 'features/graph-wof-expiry'
import { effects as graphVehicleOnExtension } from 'features/graph-vehicle-on-extension'

import { effects as report } from 'features/report'
import { effects as fbt } from 'features/fbt'

import { effects as requestReliefVehicles } from 'features/request-relief-vehicles'
import { effects as requestVehicleRelocations } from 'features/request-vehicle-relocations'
import { effects as requestContractVariations } from 'features/request-contract-variations'
import { effects as requestVehicleSummary } from 'features/request-vehicle-summary'

import init from './initialize-effect'


const rootEffect = combineEpics(
  init,
  adminUsers,
  adminInvitations,
  registrationOfInterest,

  account,
  drivers,
  fleet,
  settings,
  featureToggle,
  myDriveAppFeatureToggleSettings,
  homepage,

  graphEndOfLease,
  graphKmTracking,
  graphMaturing,
  graphOnOrder,
  graphServiceExpiry,
  graphWofExpiry,
  graphVehicleOnExtension,
  
  report,
  fbt,

  requestReliefVehicles,
  requestVehicleRelocations,
  requestContractVariations,
  requestVehicleSummary
)

export default rootEffect
