import React, { memo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
// import { makeStyles } from '@material-ui/styles'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import join from 'url-join'
import { getAppConfig } from 'app-config'
import { getAuthClient } from 'auth'
// import CheckCircleIcon from '@material-ui/icons/CheckCircle'
// import CancelIcon from '@material-ui/icons/Cancel'
// import CircularProgress from '@material-ui/core/CircularProgress'

// const useStyles = makeStyles(theme => ({
//   root: {},
// }))


const EmailTextField = ({
  id,
  size,
  defaultOptions = [],
  onChange,
  value,
  label,
  disabled,
  originalValue,
  disableValidation,
  setDisableValidation,
  hideLabelOnMobile,
  ...props
}) => {

  // const classes = useStyles()
  const config = getAppConfig()
  
  // const [loading, setLoading] = useState(false) 
  // const [isAvailableEmail, setIsAvailableEmail] = useState(false)
  const [textValue, setTextValue] = useState(value)

  const checkIfEmailIsAvailable = async (input) => {

    if(originalValue === input) // to prevent validation race condition when original value is not updated on time
      return true

    try {
      // setLoading(true)
      const authClient = getAuthClient()
      const accessToken = await authClient.getTokenSilently()
      const headers = { Authorization: `Bearer ${accessToken}` }

      const queryParms = `?Search=${input}`
      const url = join(config.app.api, '/lookup/EmailExists', queryParms)
      const response = await fetch(url, { headers })
      const responseData = await response.json()

      // setLoading(false)
      // setIsAvailableEmail(responseData.apiResponse?.success || false)
      // console.log('email validation checkIfEmailIsAvailable ', responseData.apiResponse?.success)
      return responseData.apiResponse?.success || false
    }
    catch (err) {
      // setLoading(false)
      console.log('[EmailTextField error]', err.message)
      return false
    }
  }

  const onInputChange = ({ target: { value } }) => {
    setDisableValidation && setDisableValidation(false)
    setTextValue(value || '')
    onChange(value || '')
  }

  // const validateEmail = (email) => /\S+@\S+\.\S+/.test(email)
  
  useEffect(() => {
    ValidatorForm.addValidationRule('isAvailable', (value) => {
      // console.log('email validation ', { disableValidation, sameAsPrev: (originalValue && originalValue === value)})
      return value === '' || (originalValue && originalValue === value) || checkIfEmailIsAvailable(value)
    })
    return () => {
      ValidatorForm.removeValidationRule('isAvailable')
    }// eslint-disable-next-line
  }, [textValue])

  
  useEffect(() => {
    ValidatorForm.addValidationRule('isValidEmail', (value) => {

      if(value === '') return true
      
      let domain = value.split(/@/)[1]
      let ext = domain && domain.split(/\./)[1]
      let isValidDomain = ext && ext.length > 1 // to avoid single character email extension 
      let isEmailFormat = /^[^\s@]{2,}@[^\s@]{2,}\.[^\s@]{2,}$/.test(value)

      return isEmailFormat && isValidDomain
    })
    return () => {
      ValidatorForm.removeValidationRule('isValidEmail')
    }
  }, [value])

  const labelParams = hideLabelOnMobile ? null : { label }
  const errorParams = disableValidation ? null : null

  return (
    <>
      <TextValidator
        {...props}
        {...labelParams}
        {...errorParams}
        size={size}
        value={textValue || ''}
        inputProps={{ maxLength: 50 }}
        onChange={onInputChange}
        disabled={disabled}
        validators={disableValidation
          ? ['required', 'isValidEmail']
          : ['required', 'isValidEmail', 'isAvailable']}
        errorMessages={disableValidation
          ? ['This field is required', 'Must be a valid email']
          : ['This field is required', 'Must be a valid email', 'Email already exists']}
        // InputProps={{
        //   endAdornment: loading
        //     ? <CircularProgress />
        //     : textValue !== '' && isAvailableEmail && validateEmail(textValue)
        //       ? <CheckCircleIcon className={classes.success} />
        //       : <CancelIcon />
        // }}
      />
    </>
  )
}

EmailTextField.propTypes = {
  id: PropTypes.string.isRequired,
  size: PropTypes.string,
  defaultOptions: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  originalValue: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  disableValidation: PropTypes.bool,
  setDisableValidation: PropTypes.func,
  hideLabelOnMobile: PropTypes.bool,
}

export default memo(EmailTextField)
