import { reduceRequest } from 'listable'


const initialState = {
  data: null,
  requests: {},
}

export const createReducer = (types, addData) =>
  (state = initialState, action) => {
    switch (action.type) {
      case types.RESET_DEFAULTS:
        return reset(state)
      case types.REQUEST_DATA:
      case types.REQUEST_DATA_FULFILLED:
      case types.REQUEST_DATA_ERROR:
        return reduceRequest(state, action)
      case types.ADD_DATA: {
        if (addData) {
          return addData(state, action)
        }
        return mapData(state, action)
      }
      default:
        return state
    }
  }

const reset = (state) => ({
  ...initialState,
  requests: state.requests || {},
})

const mapData = (state, { payload }) => {

  if ( ! payload) {
    return state
  }

  return {
    ...state,
    data: payload,
  }
}
