import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Grid, Tooltip } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useRouter } from 'utils'

const useStyles = makeStyles(theme => ({
  closeIcon: {
    position: 'relative',
    top: -8,
    right: 34,
    '&:hover': {
      cursor: 'pointer',
      opacity: '80%',
    }
  }
}))

const DocumentPanel = ({ fileUri, height, width }) => {

  const classes = useStyles()
  const { history } = useRouter()

  const onClose = () => {
    history.push('/reports/documents')
  }

  const openPdfConfig = `#navpane=0&page=1&view=FitH`
  const src = `${fileUri}${openPdfConfig}`

  return (
    <div className={classes.root}>
      <Grid container justify="flex-end">
        <Tooltip title={'Close document preview'}>
          <CloseIcon className={classes.closeIcon} onClick={onClose} />
        </Tooltip>
      </Grid>
      <div>
        <embed
          src={src}
          className={classes.iframe}
          title="previewDocument"
          type="application/pdf"
          frameBorder="0"
          height={`${height}px`}
          width={`${width}px`} 
        ></embed>
      </div>
    </div>
  )
}

DocumentPanel.propTypes = {
  fileUri: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
}

export default DocumentPanel
