import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { TextValidator } from 'react-material-ui-form-validator'

const AutoCompleteField = ({ id, defaultOptions = [], disabled, label, value, onChange, props }) => {

  const onSelect = useCallback((_, input) => {
    if (onChange && input) {
      onChange(input.value || input)
    } else {
      onChange('')
    }
  }, [onChange])

  return (
    <div>
      <Autocomplete
        id={id}
        options={defaultOptions}
        disabled={disabled}
        onChange={onSelect}
        getOptionLabel={option => (option && option.label) || option || ''}
        // value={(defaultOptions && defaultOptions.find(o => o.value === value)) || ''}
        renderInput={params => (
          <TextValidator
            id={id}
            {...props}
            {...params}
            label={label}
            variant="outlined"
            fullWidth
            />
        )}
      />
    </div>
  )
}

AutoCompleteField.propTypes = {
  id: PropTypes.string,
  defaultOptions: PropTypes.array,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  label: PropTypes.string,
}

export default AutoCompleteField
