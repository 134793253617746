import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { makeStyles, useTheme } from '@material-ui/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Grid, Typography, Card, CardContent, ButtonGroup, Button } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import {
  fetchMyFleetSummaryServiceOverdue,
  fetchMyFleetSummaryWofOverdue,
  fetchMyFleetSummaryKmTracking,
  fetchMyFleetSummaryRegoOverdue,
  fetchMyFleetSummaryFbtOutstanding
} from '../actions'
import { useLatestFbtSubmission } from 'features/fbt/navigation-config'
import { setFbtCustomerView } from 'features/fbt-submissions/actions'
import { useSelectedPremiumFleets } from 'features/settings'
import { useNavigate } from 'utils'
import CountUp from 'react-countup'


const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(-2),
  },
  title: {
    color: '#263238',
    fontWeight: 'bold',
    fontSize: '18px',
  },
  center: {
    display:'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  loadingSkeleton: {
    width: '340px',
    height: '40px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: '40px',
    },
  },
  label: {
    width: '180px',
    textTransform: 'none',
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '16px',
    borderColor: theme.palette.brand,
    [theme.breakpoints.down('md')]: {
      width: '65%',
    },
  },
  value: {
    width: '160px',
    fontSize: '18px',
    fontWeight: 'bold',
    lineHeight: '24px',
    [theme.breakpoints.down('md')]: {
      width: '35%',
    },
  }
}))

const MyFleetSummary = ({ flags, myFleetSummary, onFetchMyFleetSummary, onSelectFbtCustomer }) => {

  const theme = useTheme()
  const classes = useStyles()
  const mobileView = useMediaQuery(theme.breakpoints.down('md'))

  const navigate = useNavigate()
  const latestFbtSubmission = useLatestFbtSubmission()
  const fleetOptions = useSelectedPremiumFleets()

  const hasFbtFleets = fleetOptions.length > 0
  const isLoading = (flag) => flag && flag === 'processing'

  const [delayLoading, setDelayLoading] = useState(true)

  useEffect(() => {
    onFetchMyFleetSummary()
    setTimeout(() => { setDelayLoading(false) }, 1500)
  }, [onFetchMyFleetSummary])

  const onNavigate = (path) => () => {
    path.includes('/fbt') && onSelectFbtCustomer(fleetOptions[0])
    navigate(path)()
  }

  return (
    <Card>
      <CardContent>
        <Grid container spacing={3} className={classes.container}>

          <Grid item xs={12}>
            <Typography variant="h4" className={classes.title}>
              My Fleet Summary
            </Typography>
          </Grid>

          {[
            {
              label: 'Service Overdue',
              value: myFleetSummary?.serviceOverdue,
              path: `/reports/vehicle-servicing?overdue=true&booked=No`,
              flag: flags?.fetchMyFleetSummaryServiceOverdue
            },
            {
              label: 'WoF Overdue',
              value: myFleetSummary?.wofOverdue,
              path: `/reports/wof-registration?expired=true`,
              flag: flags?.fetchMyFleetSummaryWofOverdue
            },
            {
              label: 'KM Tracking (over)',
              value: myFleetSummary?.kmTrackingOver,
              path: `/reports/km-tracking?status=Over`,
              flag: flags?.fetchMyFleetSummaryKmTracking
            },
            {
              label: 'Registration Overdue',
              value: myFleetSummary?.registrationOverdue,
              path: `/reports/wof-registration?registrationStatus=Overdue`,
              flag: flags?.fetchMyFleetSummaryRegoOverdue
            },
            {
              label: 'FBT Outstanding',
              value: myFleetSummary?.fbtOutstanding,
              path: `${latestFbtSubmission.link}&completedStatus=Not%20Completed`,
              hide: !hasFbtFleets,
              flag: flags?.fetchMyFleetSummaryFBTOutstanding
            },
          ].filter(x => !x.hide).map(({ label, value, path, flag }, index) => (
            <Grid item xs={12} sm={6} md={6} lg key={`my-fleet-summary-item-${index}`}>
              {delayLoading || isLoading(flag) ? (
                <Skeleton animation="wave" variant="rect" className={classes.loadingSkeleton} />
              ) : (
                <ButtonGroup
                  className={classes.group}
                  size="large"
                  color="primary"
                  aria-label="large outlined primary button group"
                  fullWidth={mobileView}
                  onClick={navigate(path)}
                >
                  <Button className={classes.label}>
                    {label}
                  </Button>
                  <Button variant="contained" color="primary" className={classes.value} onClick={onNavigate(path)}>
                    <CountUp start={0} end={value} duration={0.75} />
                  </Button>
                </ButtonGroup>
              )}
            </Grid>
          ))}

          {/* to balance out 5x grid structure */}
          {hasFbtFleets && (
            <Grid item xs={12} sm={6} md={6} lg key={`my-fleet-summary-item-extra`}></Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  )
}

MyFleetSummary.propTypes = {
  flags: PropTypes.object,
  myFleetSummary: PropTypes.object,
  onFetchMyFleetSummary: PropTypes.func.isRequired,
  onSelectFbtCustomer: PropTypes.func.isRequired,
}

const mapStateToProps = ({ homepage }) => ({
  flags: homepage?.flags,
  myFleetSummary: homepage?.myFleetSummary,
})

const mapDispatchToProps = (dispatch) => ({
  onFetchMyFleetSummary: () => {
    dispatch(fetchMyFleetSummaryServiceOverdue())
    dispatch(fetchMyFleetSummaryWofOverdue())
    dispatch(fetchMyFleetSummaryKmTracking())
    dispatch(fetchMyFleetSummaryRegoOverdue())
    dispatch(fetchMyFleetSummaryFbtOutstanding())
  },
  onSelectFbtCustomer: (payload) => dispatch(setFbtCustomerView(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MyFleetSummary)
