import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Divider, Typography } from '@material-ui/core'
import { SingleSelect } from 'frame/components'
import { createOnFilter } from 'filter'


const useStyles = makeStyles(theme => ({
  root: {},
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 0),
    cursor: 'pointer',
  },
  formGroup: {
    padding: theme.spacing(2, 0),
  },
}))

const FilterAttributes = ({ filters, filtered, onFilter }) => {

  const classes = useStyles()
  const onSelect = createOnFilter(onFilter)
  const hasList = (list) => list.length > 0
  console.log(filters)
  const { costCentres, makes, models, maturityStatuses, wofStatuses, registrationStatuses, fleetContollers, agreementTypes, fmoTypes } = filters

  return (
    <div className={classes.root}>
      <div
        className={classes.header}>
        <Typography variant="h5">Filters</Typography>
      </div>
      <Divider />
        <div className={classes.content}>

          {hasList(costCentres) && (
            <div className={classes.formGroup}>
              <SingleSelect
                id="costCentre"
                label="Cost Centre"
                options={costCentres}
                tight
                value={filtered.costCentre}
                onChange={onSelect('costCentre')} />
            </div>
          )}
          {hasList(makes) && (
            <div className={classes.formGroup}>
              <SingleSelect
                id="make"
                label="Make"
                options={makes}
                tight
                value={filtered.make}
                onChange={onSelect('make')} />
            </div>
          )}
          {hasList(models) && (
            <div className={classes.formGroup}>
              <SingleSelect
                id="model"
                label="Model"
                options={models}
                tight
                value={filtered.model}
                onChange={onSelect('model')} />
            </div>
          )}
          {hasList(maturityStatuses) && (
            <div className={classes.formGroup}>
              <SingleSelect
                id="maturityStatus"
                label="Maturity Status"
                options={maturityStatuses}
                tight
                value={filtered.maturityStatus}
                onChange={onSelect('maturityStatus')} />
            </div>
          )}
          {hasList(wofStatuses) && (
            <div className={classes.formGroup}>
              <SingleSelect
                id="wofStatus"
                label="WoF Status"
                options={wofStatuses}
                tight
                value={filtered.wofStatus}
                onChange={onSelect('wofStatus')} />
            </div>
          )}
          {hasList(registrationStatuses) && (
            <div className={classes.formGroup}>
              <SingleSelect
                id="registrationStatus"
                label="Registration Status"
                options={registrationStatuses}
                tight
                value={filtered.registrationStatus}
                onChange={onSelect('registrationStatus')} />
            </div>
          )}
          {hasList(fleetContollers) && (
            <div className={classes.formGroup}>
              <SingleSelect
                id="fleetContoller"
                label="Controller"
                options={fleetContollers}
                tight
                value={filtered.fleetContoller}
                onChange={onSelect('fleetContoller')} />
            </div>
          )}
          {hasList(agreementTypes) && (
            <div className={classes.formGroup}>
              <SingleSelect
                id="agreementType"
                label="Agreement Type"
                options={agreementTypes}
                tight
                value={filtered.agreementType}
                onChange={onSelect('agreementType')} />
            </div>
          )}    
          {hasList(fmoTypes) && (
            <div className={classes.formGroup}>
              <SingleSelect
                id="fmoType"
                label="FMO"
                options={fmoTypes}
                tight
                value={filtered.fmoType}
                onChange={onSelect('fmoType')} />
            </div>
          )}
        </div>
    </div>
  )
}

FilterAttributes.propTypes = {
  filters: PropTypes.object.isRequired,
  filtered: PropTypes.object.isRequired,
  onFilter: PropTypes.func.isRequired,
}

export default FilterAttributes
