import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Toolbar } from '@material-ui/core'
import { Brand } from 'frame/components'
import clsx from 'clsx'


const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    height: '100%',
    width: '100%',
    overflowX: 'hidden', // hide horizontal scrollbar
  },
  backgroundImage: {
    minHeight: '100vh',
    position: 'relative',
    '&::before': {
      content: '" "',
      position: 'absolute',
      zIndex: -99,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundImage: "url('/static/images/backgrounds/bg1.png')",
      backgroundSize: '100vw 100vh',
      backgroundPosition: 'left top',
      backgroundRepeat: 'no-repeat',
      backgroundAttachment: 'fixed',
    },
  },
  navbar: {
    flex: 1,
    minHeight: 74,
    background: 'linear-gradient(0.25turn, #022946, #01B6B1)',
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

const FBTWebFormLayout = ({ children }) => {

  const classes = useStyles()

  return (
    <div className={clsx(classes.root, classes.backgroundImage)}>
      <Toolbar className={clsx(classes.navbar, classes.center)} position="static">
        <Brand variant="light" />
      </Toolbar>
        
      <div className={classes.center}>
        {children}        
      </div>
    </div>
  )
}

FBTWebFormLayout.propTypes = {
  children: PropTypes.any,

}
export default FBTWebFormLayout