import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import uuid from 'uuid/v4'
import { useFleetId } from 'features/settings'


export const createHooks = ({ actions, constants, name }) => {

  /*
   * Use Request
   *
   * This includes request tracking enabling each
   * request to be individually tracked and thus the UI
   * can reflect the requests status without accidently being
   * confused for a similar request.
   *
   * Why?
   * We do this because the global state tracked does
   * not by default allow us to track individual requests
   * without adding huge boilerplate code for every single instance.abs
   * This aims to generalise the process.
   *
   * Returned
   *  - updater: a function that takes a payload to udpate
   *  - done: a boolean indicating if the request was completed
   *  - loading: a boolean indicating if the request is being processed
   *  - error: a boolean indicating if there was an error during the request
   */
  const useRequest = (action, { idKey } = {}) => {

    const dispatch = useDispatch()

    /*
     * Request ID
     * The Request ID is used to track
     * the state of this request in the global store.
     * That is it makes it possible to know if this single
     * request is in process, completed or has errored.
     * */
    const [requestId, setRequestId] = useState(null)

    /*
     * TODO :: Refactor :: Remove
     * Remove this, we should not need a fleet id in conjunction
     * with a resource id to lookup a resource. The client has
     * also asked than in the future multiple fleets be merged in
     * which case we may not know ahead of time the corresponding
     * fleet id for a resource.
     */
    const FleetId = useFleetId()
    const queryParams = { FleetId }

    /*
     * General State
     * Used for determining the request state.
     */
    const [done, setDone] = useState(false)
    const [error, setError] = useState(false)
    const [processing, setProcessing] = useState(false)
    const [dataId, setDataId] = useState(null)

    /*
     * Data
     * The data is selected from the corresponding list reducer.
     * If there is data already in the store it will be returned
     * immediately, regardless if the request is processing.
     */
    const data = useSelector(state => {
      if (dataId) {
        return state[name].data?.[dataId] || null
      }
      return null
    })

    /*
     * Request State
     * The request state is returned from the reducer once available.
     * It has a 'status' property indicating either:
     * 'processing', 'done', 'error'
     */
    const request = useSelector(state => state[name]?.requests?.[requestId])

    /*
     * Request Status
     * This effect is run everytime the 'request' property
     * is updated in the reducer. It sets the corresponding
     * state for the call that is returned to the caller.
     */
    useEffect(() => {
      if (request && request.status === 'done') {
        setDone(true)
        setProcessing(false)
        setError(false)
      }
      else if (request && request.status === 'error') {
        setDone(false)
        setProcessing(false)
        setError(true)
      }
    }, [request, setProcessing])

    /*
     * Update
     * The function that performs the update.
     * It takes a payload that will be sent as the body of the request.
     * It sets the state for the request and assigns itself a new
     * request ID.
     * It dispatches an action that is then handled by
     * the corresponding 'epic'.
     */
    const execute = (payload) => {

      // If updating, prevent a second update from occuring.
      // Otherwise we will lose track of the current request
      // and not be able to track its progress.
      if (processing) {
        return
      }

      const newRequestId = uuid()

      const resourceId = (idKey && payload?.[idKey]) ?? null

      const requsetOptions = {
        queryParams,
        requestId: newRequestId,
        resourceId,
      }

      if ( ! resourceId) {
        console.log('[Listable ERROR] no idKey provided for request')
      }

      setRequestId(newRequestId)
      setDataId(resourceId)
      // setDataId((idKey && payload?.[idKey]) || payload?.id || payload?.agreementNo)
      setDone(false)
      setProcessing(true)
      setError(false)
      dispatch(action(payload, requsetOptions))
    }

    /*
     * We return a simple API useful in the caller
     * that enables UX indicators for each individual request.
     *
     *  - execute: a function that takes a payload to process
     *  - done: a boolean indicating if the request was completed
     *  - data: state data if available
     *  - processing: a boolean indicating if the request is being processed
     *  - error: a boolean indicating if there was an error during the request
     */
    return { done, data, error, processing, execute }
  }

  const useFetch = (id, options = {}) => {

    if ( ! options.idKey) {
      throw Error(`Cannot invoke 'useFetch' without providing an 'idKey' identifier.`)
    }

    const {
      done: fetched,
      data,
      error,
      processing: fetching,
      execute,
    } = useRequest(actions.requestResource, options)

    const fetch = useCallback((value) => {
      execute({
        [options.idKey]: value,
      })
    }, [execute, options.idKey])


    useEffect(() => {
      if (id) {
        fetch(id)
      }
    }, [id]) // eslint-disable-line

    return {
      data,
      error,
      fetch,
      fetched,
      fetching,
    }
  }

  const useSave = (options = {}) => {

    const {
      done: saved,
      data,
      error,
      processing: saving,
      execute: save,
    } = useRequest(actions.createResource, options)

    return { data, error, save, saved, saving }
  }

  const useUpdate = (options = {}) => {

    if ( ! options.idKey) {
      throw Error(`Cannot invoke 'useUpdate' without providing an 'idKey' identifier.`)
    }

    const {
      done: updated,
      data,
      error,
      processing: updating,
      execute: update,
    } = useRequest(actions.updateResource, options)

    return { data, error, update, updated, updating }
  }

  const useFetchRelatedContract = (options = {}) => {

    /*
     * TODO - Refactor
     * This is awful as it will make this a singleton which is kinda
     * what I'm trying to avoid. This absolutely needs re-working.
     * The only reason this is passable is because it's likely it's
     * a single use case per expression.
     */
    const [lookupId, setLookupId] = useState(null)

    const data = useSelector(state => {
      return state?.[name]?.related?.contracts?.[lookupId]
    })

    const {
      done,
      error,
      processing: loading,
      execute,
    } = useRequest(actions.lookupContract, { idKey: 'agreementNo' })

    /*
     * Because 99.99% of records use AgreementNo
     * in place of a standard ID
     */
    const fetch = useCallback((agreementNo) => {
      setLookupId(agreementNo)
      execute({ agreementNo })
    }, []) // eslint-disable-line

    return { data, error, loading, fetch, done }
  }

  return {
    useFetch,
    useSave,
    useUpdate,
    useFetchRelatedContract,
  }
}
