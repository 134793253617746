

export const MODULE_NAME = `myDriveAppFeatureToggleSettings`

export const FEATURE_TOGGLE_SETTINGS_PATH = '/admin/mydriveapp-feature-toggles'
export const FEATURES = {
    DRIVER_ASSIGNMENT: 'Driver Assignment',
    // FEATURE2: 'FEATURE2',
    // FEATURE3: 'FEATURE3',
}

export const REQUEST_MYDRIVEAPP_TOGGLE = `${MODULE_NAME}_request_toggle`
export const REQUEST_MYDRIVEAPP_TOGGLE_FULFILLED = `${MODULE_NAME}_toggleFulfilled`
export const REQUEST_MYDRIVEAPP_TOGGLE_ERROR = `${MODULE_NAME}_request_toggleError`

export const REQUEST_MYDRIVEAPP_VIEWALL = `${MODULE_NAME}_request_toggle_viewall`
export const REQUEST_MYDRIVEAPP_TOGGLE_ALL = `${MODULE_NAME}_request_toggle_all`
export const TOGGLE_CONFIRMATION_MODAL = `${MODULE_NAME}_toggle_confirmation_modal`
