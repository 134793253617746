import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { addDraft, editSaveDraft } from '../actions'
import { fbtReasonOptions } from '../actions/types'
import { Button, Card, CardContent, Grid, Typography } from '@material-ui/core'
import { RowValueText, SingleSelect } from 'frame/components'
import { useNavigate } from 'utils'
import { constants } from '../module'
import moment from 'moment'
import CustomCalender from './CustomCalender'
import { useProfile } from 'features/account'

const useStyles = makeStyles(theme => ({
  content: {
    padding: theme.spacing(3, 1.2, 3, 1.2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: { display: 'block' }
  },
  button: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    minWidth: 180,
    [theme.breakpoints.down('xs')]: { minWidth: '100%' }
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  contentContainer: {
    paddingBottom: theme.spacing(3),
  },
  emphasize: {
    fontWeight: 'bold',
    textDecoration: 'underline',
  }
}))

// used in add new fbt driver record modal, edit fbt row entry, fbt web form
const AddEditSubmission = ({ id = 0, form, fbtWebForm, filtered, daysToDisable, originalEditRecord, onAddDraft, onEditSaveDraft }) => {

  const newFbtRecord = { days: [], previouslySelectedDays: [], reasonType: '', reason: '' }

  const profile = useProfile()
  const classes = useStyles()
  const navigate = useNavigate()

  const isAddForm = window.location.pathname.includes('add/add-submission')
  const isWebForm = window.location.pathname.includes('fbt-web-form')
  const isEditing = window.location.pathname.includes('edit')

  const [values, setValues] = useState(isAddForm ? newFbtRecord : form)

  const formChangesMade = JSON.stringify(values) !== JSON.stringify(originalEditRecord)

  const activeMonth = filtered && filtered?.month && filtered?.year
    ? moment(`${filtered?.year}-${moment().month(filtered?.month).format('M')}`, 'YYYY-MM').toDate() // parse filtered monthName/Year to date
    : moment().add(-1, 'month').toDate() // fallback - last month

  const disableReasonField = isEditing
    ? (!values?.days || values?.days.length === 0) && (!values?.previouslySelectedDays || values?.previouslySelectedDays.length === 0)
    : !values?.days || values?.days.length === 0
  
  const disableAddOrEditSave = isEditing
    ? ((!values?.days || values?.days.length === 0) && (!values?.previouslySelectedDays || values?.previouslySelectedDays.length === 0)) || (values?.reasonType === 'Other' && !values?.reason)
    : !values?.days || values?.days.length === 0 || !values?.reason  || (values?.reasonType === 'Other' && !values?.reason)

  useEffect(() => {
    isAddForm && setValues(newFbtRecord)
  }, [daysToDisable])  // eslint-disable-line
  
  const onCancel = navigate(`/fbt/submissions/${id}/${constants.PATH_VIEW_SUBMISSIONS}`)
  
  const onSubmitForm = () => {
    if(isEditing) {
      onEditSaveDraft(values)
    } else {
      onAddDraft({
        ...values,
        reason: fbtReasonOptions.includes(values?.reason) ? values?.reason : `Other: ${values?.reason}`
      })
    }
    
    navigate(isWebForm ? `/fbt-web-form/form/view-submissions` : `${constants.PATH_ALL}/${id}${constants.PATH_VIEW_SUBMISSIONS}`)()
  }
  
  const onSelectDates = (selectedDay) => {
    let updatedDays = values?.days || []
    let previouslySelectedDays = values?.previouslySelectedDays || []

    if(previouslySelectedDays.includes(selectedDay)) { // when selecting previouslySelectedDays - for edit form only
      previouslySelectedDays = previouslySelectedDays.filter(day => day !== selectedDay) // remove day from previouslySelectedDays
    } else {
      // handle add/remove days
      if(!updatedDays.includes(selectedDay)) {
        updatedDays.push(selectedDay)
      } else {
        updatedDays = updatedDays.filter(x => x !== selectedDay)
      }
    }

    setValues({
      ...values,
      days: updatedDays.sort((a, b) => a - b),
      previouslySelectedDays: previouslySelectedDays.sort((a, b) => a - b),
    })
  }

  return (
    <ValidatorForm autoComplete="off" noValidate onSubmit={onSubmitForm}>
      <Card elevation={isWebForm ? 0 : 1}>
        <CardContent className={classes.content}>
          <Grid container spacing={3} className={classes.contentContainer}>

            <Grid item xs={12}>
              <Typography variant="body1">
                Please enter the dates on which the vehicle was <span className={classes.emphasize}>not</span> available for private use.
              </Typography>
            </Grid>
        
            {/* Calender Section */}
            <Grid item xs={12}>
              <CustomCalender
                activeMonth={activeMonth}
                selectedDays={values?.days}
                onSelectDates={onSelectDates}
                previouslySelectedDays={values?.previouslySelectedDays}
                disabledDates={daysToDisable}
                // disabledDatesAfter={13}
              />
            </Grid>
            {/* End of Calender Section */}

            <Grid item xs={12}>
              <RowValueText label="Reason" responsive>
                <SingleSelect
                  noNone tight
                  id="fbtReason"
                  label={!values?.reasonType ? 'Select a Reason' : ''}
                  options={fbtReasonOptions}
                  disabled={disableReasonField}
                  value={values?.reasonType}
                  onChange={value => setValues({
                    ...values,
                    reasonType: value,
                    reason: value !== 'Other' ? value : ''
                  })}
                />
              </RowValueText>
            </Grid>

            {values?.reasonType === 'Other' && (
              <Grid item xs={12}>
                <RowValueText label="Specify" responsive>
                  <TextValidator
                    fullWidth
                    size="small"
                    variant="outlined"
                    label={values?.reason ? '' : 'Add Details'}
                    name="specify"
                    value={values?.reason}
                    disabled={disableReasonField}
                    onChange={event => setValues({
                      ...values,
                      reason: event?.target?.value || '',
                    })}
                  />
                </RowValueText>
              </Grid>
            )}
        
          </Grid>

        </CardContent>
      </Card>

      <div className={classes.actions}>
        <Button
          className={classes.button}
          type="submit"
          color="primary"
          variant="contained"
          disabled={!formChangesMade || disableAddOrEditSave}
        >
          {isEditing ? 'Save' : 'Add'}
        </Button>

        {isWebForm ? (
          <Button className={classes.button} variant="contained" onClick={navigate(`/fbt-web-form/availability`)}>
            Back
          </Button>
        ) : (
          <Button className={classes.button} variant="contained" onClick={onCancel}>
            Cancel
          </Button>
        )}
      </div>
    </ValidatorForm>
  )
}

AddEditSubmission.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  form: PropTypes.object,
  fbtWebForm: PropTypes.object,
  filtered: PropTypes.object,
  originalEditRecord: PropTypes.object,
  daysToDisable: PropTypes.array.isRequired,
  // func
  onAddDraft: PropTypes.func.isRequired,
  onEditSaveDraft: PropTypes.func.isRequired,
}

const mapStateToProps = ({ fbtSubmissions }, ownProps) => ({
  id: ownProps.match.params?.id,
  form: fbtSubmissions?.form,
  fbtWebForm: fbtSubmissions?.fbtWebForm,
  filtered: fbtSubmissions?.filtered,
  originalEditRecord: fbtSubmissions?.originalEditRecord,
  daysToDisable: fbtSubmissions?.daysToDisable || [],
})

const mapDispatchToProps = (dispatch) => ({
  onAddDraft: (payload) => {
    dispatch(addDraft(payload))
  },
  onEditSaveDraft: (payload) => {
    dispatch(editSaveDraft(payload))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(AddEditSubmission)
