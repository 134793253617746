import fromConstants from './constants'


export const resetDefaults = () => ({
  type: fromConstants.RESET,
})

export const setProfile = (payload) => ({
  type: fromConstants.SET_PROFILE,
  payload,
})

export const updateProfile = (payload) => ({
  type: fromConstants.UPDATE_PROFILE,
  payload,
})

export const putPassword = (payload, options) => ({
  type: fromConstants.PUT_PASSWORD,
  payload,
  options,
})

export const putPasswordFulfilled = (payload, options) => ({
  type: fromConstants.PUT_PASSWORD_FULFILLED,
  payload,
  options,
})

export const putPasswordError = (payload, options) => ({
  type: fromConstants.PUT_PASSWORD_ERROR,
  payload,
  options,
})

export const putProfile = (payload, options) => ({
  type: fromConstants.PUT_PROFILE,
  payload,
  options,
})

export const putProfileFulfilled = (payload, options) => ({
  type: fromConstants.PUT_PROFILE_FULFILLED,
  payload,
  options,
})

export const putProfileError = (payload, options) => ({
  type: fromConstants.PUT_PROFILE_ERROR,
  payload,
  options,
})
