import * as fromActions from './actions/types'


const reducer = (state, action) => {
  switch (action.type) {
    case fromActions.RESET:
      return resetFlags(state) // resets all flags

    // Create Relief Vehicle
    case fromActions.CREATE:
      return resetFlags(state, { create: 'processing' })
    case fromActions.CREATE_ERROR: {
      return onError(state, action, { create: 'error' })
    }
    case fromActions.CREATE_FULFILLED:
      return addResource(state, action, { create: 'processed' })
      
    // Fetch Relief Vehicle List Data
    case fromActions.FETCH:
      return resetFlags(state, { fetch: 'processing' })
    case fromActions.FETCH_ERROR:
      return onError(state, action, { fetch: 'error' })
    case fromActions.FETCH_FULFILLED:
      return addResource(state, action, { fetch: 'processed' })

    // Fetch Corresponding Relief Vehicle Data
    /* case fromActions.FETCH_CORRESPONDING_CONTRACT:
      return resetFlags(state, { fetchContract: 'processing' }) */
    case fromActions.FETCH_CORRESPONDING_CONTRACT_ERROR:
      return onError(state, action, { fetch: 'error' })
    case fromActions.FETCH_CORRESPONDING_CONTRACT_FULFILLED:
      return addRelatedResource(state, action, { fetch: 'processed' })
      
    // Fetch Contact Data
    case fromActions.FETCH_CONTRACT:
      return resetFlags(state, { fetchContract: 'processing' })
    case fromActions.FETCH_CONTRACT_ERROR:
      return onError(state, action, { fetchContract: 'error' })    
    case fromActions.FETCH_CONTRACT_FULFILLED:
      return addRelatedResource(state, action)

    // Update Relief Vehicle Data
    case fromActions.UPDATE:
      return resetFlags(state, { update: 'processing' })
    case fromActions.UPDATE_ERROR:
      return onError(state, action, { update: 'error' })
    case fromActions.UPDATE_FULFILLED:
      return updateResource(state, action)

    // Lookup
    case fromActions.LOOKUP:
      return updateFlags(state, { lookup: 'processing' })
    case fromActions.LOOKUP_ERROR:
      return onError(state, action, { lookup: 'error' })
    case fromActions.LOOKUP_FULFILLED:
      return onLookupSuccess(state, action)
    
    default:
      return state
  }
}


const onError = (state, action, flags) => {
  const error = action.payload
  state.errorMessage = typeof error === 'string' ? action.payload : ''
  return updateFlags(state, flags)
}

const resetFlags = (state, flags) => ({
  ...state,
  errorMessage: null,
  flags: {
    ...state.flags,
    create: 'none',
    fetch: 'none',
    fetchContract: 'none',
    update: 'none',
    lookup: 'none',
    ...flags,
  },
})

const updateFlags = (state, flags) => ({
  ...state,
  flags: { ...state.flags, ...flags }
})

const addResource = (state, { payload }, flags) => ({
  ...state,
  data: { ...state.data, [payload.id]: payload },
  flags: { ...state.flags, ...flags },
})

const addRelatedResource = (state, { payload }) => ({
  ...state,
  flags: { ...state.flags, fetchContract: 'processed' },
  related: {
    ...state.related,
    [payload.agreementNo]: payload,
  },
})

const updateResource = (state, action) => {

  const next = { ...state }

  const id = action.payload.id
  const prevState = next?.data?.[id]
  const nextState = action.payload

  if (prevState) {
    next.data[id] = { ...prevState, ...nextState }
  }

  return updateFlags(next, { update: 'processed' })
}

const onLookupSuccess = (state, { payload, options }) => {
  let lookupOptions = []
  console.log('[onLookupSuccess]: ', { payload, options })

  if(payload && payload.length > 0) {
    const cleanData = [...(new Set(payload))]
      .reduce((result, element) => {
        const normalize = x => typeof x === 'string' ? x.toLowerCase() : x
  
        if (result.every(otherElement => normalize(otherElement.value) !== normalize(element.value)))
          result.push(element)
  
        return result
      }, [])
  
    lookupOptions = cleanData
  }

  state.lookup = {
    ...state.lookup,
    [options.resourceId]: lookupOptions
  }
  return updateFlags(state, { lookup: 'processed' })
}

export default reducer
