import React, { useCallback, useState, useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Listable } from 'listable'
import { useNavigate } from 'utils'
import Row from './row'
import Filters from '../filters'
import { ChildRoutes } from '../../routes'
import { StyledModal, AnimatedSuccessTick, AnimatedErrorCross, Modal } from 'frame/components'
import { remove, reset, inviteDriver } from '../../actions'
import { actions, config, constants } from '../../module'
import { Typography } from '@material-ui/core'
// import { useInView } from "react-intersection-observer"
import { useIsAlfaDownDisableFeatures } from 'features/drivers'
import { useEnabledFeatureToggleList } from 'features/settings'
import { hasRoles } from 'acl'


const List = ({ model, fleetIds, selectedDrivers, onRemove, onReset, onSelectRow, onInviteDrivers, onSelectAll,userRoles }) => {

  const linker = useNavigate()
  const featureToggleList = useEnabledFeatureToggleList()

  const disableOutOfHoursFeatureToggle = featureToggleList.includes('OutOfHoursDisabled') // 'ALFA out of hours DISABLE FEATURES' limitations feature is ON
  const isAlfaDownDisableDriverFeatures = useIsAlfaDownDisableFeatures()
  const isAlfaDown = disableOutOfHoursFeatureToggle ? false : isAlfaDownDisableDriverFeatures
  // const [ref, inView] = useInView({ threshold: 0 }) // for triggering the confirmation modal timer when its in view 

  const [showConfirmation, setShowConfirmation] = useState(false)
  const [showError, setShowError] = useState(false)
  const [showDriverInviteConfirmationModal, setShowDriverInviteConfirmationModal] = useState(false)
  const [showDriverInviteConfirmationMessage, setShowDriverInviteConfirmationMessage] = useState(false)
  
  const userPermissionsFeatureToggle = featureToggleList.includes('UserPermissions')
  const disableButtons = userPermissionsFeatureToggle && hasRoles(['fleetcoordinator'], userRoles)

  const renderItem = useCallback((item, index, itemProps) => {

    const onView = linker(constants.PATH_SINGLE, item.uniqueId)
    const onTerminate = (id) => () => onRemove(id, fleetIds)

    return (
      <Row
        key={index}
        item={item}
        onView={onView}
        onTerminate={onTerminate(item.uniqueId)}
        userRoles={userRoles}
        onSelectRow={onSelectRow}
        {...itemProps}
      />
    )
  }, [linker, fleetIds, onRemove, onSelectRow]) // eslint-disable-line

  // Prevents re-rendering if ids or data updated - no longer needed
  const { flags, apiResponse } = model

  useEffect(() => {
    if(flags.create === 'processed' || (flags.update === 'processed' && flags.isAssigned) || flags.delete === 'processed') {
      setShowConfirmation(true)
    }
    if(flags.create === 'error' || (flags.update === 'error' && flags.isAssigned) || flags.delete === 'error') {
      setShowError(true)
    }
    // eslint-disable-next-line
  }, [flags.create, flags.update, flags.delete])

  const inviteDriverToAppButtonLabel = selectedDrivers.length > 1
    ? "Invite Driver(s) To App"
    : "Invite Driver To App"

  return (
    <>
      <Listable
        actions={actions}
        config={config}
        filters={Filters}
        model={model}
        renderItem={renderItem}
        routes={ChildRoutes}

        primaryButton={{
          label: "Add Driver",
          action: linker(constants.PATH_ADD),
          disable: isAlfaDown || disableButtons,
        }}

        secondaryButton={{
          label: inviteDriverToAppButtonLabel,
          action: () => setShowDriverInviteConfirmationModal(true),
          color: 'primary',
          disable: model.selected.length === 0 || disableButtons,
        }}
      />
      
      {/* Confirmation Button */}
      <StyledModal
        open={showConfirmation || showError || showDriverInviteConfirmationMessage}
        onCancel={() => {
          onReset() // reset flags on modal close
          setShowConfirmation(false)
          setShowError(false)
          setShowDriverInviteConfirmationMessage(false)
        }}
        options={['noButtons']}
        animatedIcon={<div>
          {showConfirmation || showDriverInviteConfirmationMessage
            ? <AnimatedSuccessTick size={50} />
            : <AnimatedErrorCross size={50} />}
          </div>}>
        {showDriverInviteConfirmationMessage ? 'Invitation email has been sent' : apiResponse}
      </StyledModal>

      {/* Driver Invite To App Confirmation Modal */}
      <Modal
        open={showDriverInviteConfirmationModal}
        variant="contained"
        onActionLabel="Confirm"
        onAction={() => {
          onInviteDrivers(selectedDrivers)
          setShowDriverInviteConfirmationModal(false)
          setShowDriverInviteConfirmationMessage(true)
          onSelectAll([]) // resets all checkboxes
        }}
        onCloseLabel="Cancel"
        onClose={() => setShowDriverInviteConfirmationModal(false)}
        options={['fullWidthButtons']}
      >
        <Typography variant="body1" align="center" style={{ padding: 20, marginTop: 30 }}>
          Please confirm that you wish to invite the following user/s to the TFM MyDrive Mobile App.
          <br /><br />
          {selectedDrivers.map((driverId, index) => model.data[driverId] && (
            <Fragment key={`invite-driver-${index}`}>
              <strong>
                {model.data[driverId].driver}
              </strong>
              <br />
            </Fragment>
          ))}
        </Typography>
      </Modal>
    </>
  )
}

List.propTypes = {
  model: PropTypes.object.isRequired,
  fleetIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedDrivers: PropTypes.array.isRequired,
  // func
  onRemove: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onSelectRow: PropTypes.func.isRequired,
  onInviteDrivers: PropTypes.func.isRequired,
  onSelectAll: PropTypes.func.isRequired,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
}

const mapStateToProps = ({ drivers, settings }) => ({
  model: drivers,
  selectedDrivers: drivers.selected,
  fleetIds: settings.fleet.selected,
  userRoles: settings.roles
})

const mapDispatchToProps = (dispatch) => ({
  onRemove: (uniqueId) => {
    dispatch(remove(null, { resourceId: uniqueId }))
  },
  onReset: () => {
    dispatch(reset())
  },
  onSelectRow: (payload) => {
    dispatch(actions.selectRow(payload))
  },
  onInviteDrivers: (uniqueIds) => {
    dispatch(inviteDriver(uniqueIds))
  },
  onSelectAll: (selectedIds) => {
    dispatch({      
      type: 'drivers_select_row',
      payload: selectedIds,
    })
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(List)
