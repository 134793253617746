import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Collapse, Divider, Typography } from '@material-ui/core'
import { SingleSelect } from 'frame/components'
import { useCollapsible } from 'utils'
import { createOnFilter } from 'filter'


const useStyles = makeStyles(theme => ({
  root: {
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 0),
    cursor: 'pointer',
  },
  content: {},
  formGroup: {
    padding: theme.spacing(2, 0),
  },
}))

const FilterAttributes = ({ filters, filtered, onFilter }) => {

  const classes = useStyles()
  const { collapsed, onToggleCollapsible } = useCollapsible(true)
  const onSelect = createOnFilter(onFilter)
  const hasList = (list) => list.length > 0
  const { costCentres, trackings, agreementTypes, fmoTypes } = filters

  return (
    <div className={classes.root}>
      <div
        className={classes.header}
        onClick={onToggleCollapsible}>
        <Typography variant="h5">Filters</Typography>
        {collapsed ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </div>
      <Divider />
      <Collapse in={collapsed}>
        <div className={classes.content}>
          {hasList(costCentres) && (
            <div className={classes.formGroup}>
              <SingleSelect
                tight
                id="costCentre"
                label="Cost Centre"
                options={costCentres}
                value={filtered.costCentre}
                onChange={onSelect('costCentre')} />
            </div>
          )}
          {hasList(trackings) && (
            <div className={classes.formGroup}>
              <SingleSelect
                id="tracking"
                label="Tracking"
                options={trackings}
                tight
                value={filtered.tracking}
                onChange={onSelect('tracking')} />
            </div>
          )}
          {hasList(agreementTypes) && (
            <div className={classes.formGroup}>
              <SingleSelect
                tight
                id="agreementType"
                label="Agreement Type"
                options={agreementTypes}
                value={filtered.agreementType}
                onChange={onSelect('agreementType')} />
            </div>
          )}
          {hasList(fmoTypes) && (
            <div className={classes.formGroup}>
              <SingleSelect
                tight
                id="fmoType"
                label="FMO"
                options={fmoTypes}
                value={filtered.fmoType}
                onChange={onSelect('fmoType')} />
            </div>
          )}      
        </div>
      </Collapse>
    </div>
  )
}

FilterAttributes.propTypes = {
  filters: PropTypes.object.isRequired,
  filtered: PropTypes.object.isRequired,
  onFilter: PropTypes.func.isRequired,
}

export default FilterAttributes
