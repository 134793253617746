import React, { memo, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, useTheme } from '@material-ui/styles'
import { colors, Dialog, DialogContent, Typography, useMediaQuery } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import PageHeader from './page-header'
import PageLoader from './page-loader'


const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiDialog-paper': {
      background: theme.palette.background.default,
    },
  },
  header: {
    margin: 0,
    padding: theme.spacing(4, 1, 0, 1),
    minHeight: props => props.headerMinHeight ? props.headerMinHeight : 92,
    background: theme.palette.background.default,
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4, 3, 0, 3),
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(4),
      marginLeft: theme.spacing(1),
    },
  },
  titles: {
    margin: 0,
    padding: 0,
  },
  content: {
    position: 'relative',
    margin: 0,
    padding: theme.spacing(0, 1, 1, 1),
    background: theme.palette.background.default,
    pointerEvents: 'all',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 3, 1, 3),
    },
  },
  close: {
    display: 'flex',
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    margin: 0,
    padding: 0,
    width: 30,
    height: 30,
    background: 'transparent',
    borderRadius: 15,
    border: 'none',
    transition: '0.3s all ease-out',
    cursor: 'pointer',
    '&:hover': {
      background: colors.grey[100],
      border: 'none',
      outline: 'none',
    },
    '&:focus': {
      border: 'none',
      outline: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      top: theme.spacing(1.4),
      right: theme.spacing(2.4),
    },
  },
  icon: {
    margin: 0,
    width: 30,
    height: 30,
    color: colors.grey[600],
  },
  noScroll: {
    overflowY: 'hidden !important',
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(244, 246, 248, 0.8)',
    zIndex: 9999,
  }
}))

const PageDialog = ({
  children,
  errorMessage,
  open,
  title,
  breadcrumbs,
  loading,
  onClose,
  
  /*
    TODO: refactor out to use primaryButton Object like in PageHeader component after new tfs fleet record view modal is permanent
    Only used in tfs fleet old view modal and contract variation view modal
  */
  action, // button label
  onAction,
  hideAction,
  disablePrimaryButtonAction,
  secondaryAction, // button label
  onSecondaryAction,
  hideSecondaryAction,
  disableSecondaryAction,

  size,
  minHeight,
  autoSize, // used auto height for scroll
  tabsConfig, // use for modal tabs
  headerMinHeight, // tfs modal only
  ...props
}) => {

  const classes = useStyles({ headerMinHeight })
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const scroll = fullScreen ? 'body' : 'paper'

  const modalMinHeight = minHeight ? minHeight : window.innerHeight - 64
  const [headerHeight, setHeaderHeight] = useState(headerMinHeight ? headerMinHeight + 1 : 93)

  const elHeader = useRef()
  const elBody = useRef()

  const loaderStyle = {
    top: headerHeight,
    height: `calc(100% - ${headerHeight}px)`,
  }

  useEffect(() => {
    if (elHeader.current) {
      // setHeaderHeight(elHeader.current.clientHeight + 1)
    }
  }, [elHeader.current, setHeaderHeight]) // eslint-disable-line

  useEffect(() => {
    if (elBody.current) {
      elBody.current.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }, [loading])

  return (
    <Dialog
      {...props}
      className={classes.root}
      fullScreen={fullScreen}
      fullWidth
      maxWidth={size ? size : 'lg'}
      open={open}
      scroll={scroll}
      onClose={onClose}>
      <div className={classes.header} ref={elHeader}>
        <PageHeader
          className={classes.titles}
          breadcrumbs={breadcrumbs}
          title={title}
          tabsConfig={tabsConfig}
          loading={loading}
          options={['titleWordWrap']}

          primaryButton={{
            label: action,
            action: onAction,
            hide: hideAction,
            disable: disablePrimaryButtonAction,
          }}
  
          secondaryButton={{
            label: secondaryAction,
            action: onSecondaryAction,
            hide: hideSecondaryAction,
            disable: disableSecondaryAction,
          }}
        />

        <button className={classes.close} onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </button>
      </div>

      <DialogContent className={classes.content} ref={elBody}>
        <div style={{ minHeight: autoSize ? 'auto' : modalMinHeight, marginBottom: autoSize ? '20px' : 0 }}>
          {children}
        </div>
      </DialogContent>

      {loading && (
        <div className={classes.loader} style={loaderStyle}>
          <PageLoader />
        </div>
      )}

      { ! loading && errorMessage && (
        <div className={classes.loader} style={loaderStyle}>
          <Typography variant="body1">
            {errorMessage}
          </Typography>
        </div>
      )}
    </Dialog>
  )
}

PageDialog.propTypes = {
  children: PropTypes.node,
  errorMessage: PropTypes.string,
  title: PropTypes.string,
  breadcrumbs: PropTypes.string,
  open: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  onClose: PropTypes.func.isRequired,

  action: PropTypes.string,
  onAction: PropTypes.func,
  hideAction: PropTypes.bool,
  disablePrimaryButtonAction: PropTypes.bool,
  secondaryAction: PropTypes.string,
  onSecondaryAction: PropTypes.func,
  hideSecondaryAction: PropTypes.bool,
  disableSecondaryAction: PropTypes.bool,

  size: PropTypes.string,
  minHeight: PropTypes.number,
  autoSize: PropTypes.bool,
  tabsConfig: PropTypes.array,
}

export default memo(PageDialog)
