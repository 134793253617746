import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'

import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography
} from '@material-ui/core'

import Icon from '@material-ui/icons/ErrorOutline'
import { Page } from 'frame/components'
import { getAppConfig } from 'app-config'


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 'calc(100vh - 90px)',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    position: 'relative',
  },
  graphic: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 90,
    height: 90,
    position: 'absolute',
    top: -24,
    left: -80,
  },
  icon: {
    color: theme.palette.text.primary,
    fontSize: 60,
  },
}))

const FleetNotFound = ({ title }) => {

  const config = getAppConfig()
  const classes = useStyles()

  const onEmailSupport = () => {
    window.open(config.email.help)
    // window.location = config.email.help
  }

  return (
    <Page
      className={classes.root}
      title={title}>
      <Card>
        <CardContent className={classes.content}>
          <div className={classes.graphic}>
            <Icon className={classes.icon} />
          </div>
          <Typography
            gutterBottom
            className={classes.title}
            variant="h1">
            No fleets available
          </Typography>
          <Typography variant="body1">
            It looks like you&apos;re not assigned to any fleets.
            <br />
            Please contact your administrator or Toyota Fleet Management New Zealand for support.
          </Typography>
        </CardContent>
        <CardActions className={classes.actions}>
          <Button
            aria-label="email support"
            color="primary"
            variant="contained"
            onClick={onEmailSupport}>
            Email Support
          </Button>
        </CardActions>
      </Card>
    </Page>
  )
}

FleetNotFound.propTypes = {
  title: PropTypes.string.isRequired,
}

export default FleetNotFound
