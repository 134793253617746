import * as types from './constants'


export const reset = () => ({
  type: types.RESET
})

export const requestAddUser = (payload, options) => ({
  type: types.REQUEST_ADD_USER,
  payload,
  options,
})

export const requestAddUserError = (payload, options) => ({
  type: types.REQUEST_ADD_USER_ERROR,
  payload,
  options,
})

export const requestAddUserFulfilled = (payload, options) => ({
  type: types.REQUEST_ADD_USER_FULFILLED,
  payload,
  options,
})

export const requestRemoveUser = (payload, options) => ({
  type: types.REQUEST_REMOVE_USER,
  payload,
  options,
})

export const requestRemoveUserError = (payload, options) => ({
  type: types.REQUEST_REMOVE_USER_ERROR,
  payload,
  options,
})

export const requestRemoveUserFulfilled = (payload, options) => ({
  type: types.REQUEST_REMOVE_USER_FULFILLED,
  payload,
  options,
})

export const requestBlockUser = (payload, options) => ({
  type: types.REQUEST_BLOCK_USER,
  payload,
  options,
})

export const requestBlockUserError = (payload, options) => ({
  type: types.REQUEST_BLOCK_USER_ERROR,
  payload,
  options,
})

export const requestBlockUserFulfilled = (payload, options) => ({
  type: types.REQUEST_BLOCK_USER_FULFILLED,
  payload,
  options,
})

export const requestUserInvitations = (payload, options) => ({
  type: types.REQUEST_USER_INVITATIONS,
  payload,
  options,
})

export const requestUserInvitationsError = (payload, options) => ({
  type: types.REQUEST_USER_INVITATIONS_ERROR,
  payload,
  options,
})

export const requestUserInvitationsFulfilled = (payload, options) => ({
  type: types.REQUEST_USER_INVITATIONS_FULFILLED,
  payload,
  options,
})

export const requestUserUpdate = (payload, options) => ({
  type: types.REQUEST_USER_UPDATE,
  payload,
  options,
})

export const requestUserUpdateError = (payload, options) => ({
  type: types.REQUEST_USER_UPDATE_ERROR,
  payload,
  options,
})

export const requestUserUpdateFulfilled = (payload, options) => ({
  type: types.REQUEST_USER_UPDATE_FULFILLED,
  payload,
  options,
})

export const addUsers = (payload) => ({
  type: types.ADD_USERS,
  payload,
})

export const removeUsers = (payload) => ({
  type: types.REMOVE_USERS,
  payload,
})

export const requestRoles = (payload, options) => ({
  type: types.REQUEST_ROLES,
  payload,
  options,
})

export const requestRolesError = (payload, options) => ({
  type: types.REQUEST_ROLES_ERROR,
  payload,
  options,
})

export const requestRolesFulfilled = (payload, options) => ({
  type: types.REQUEST_ROLES_FULFILLED,
  payload,
  options,
})

export const addRoles = (payload) => ({
  type: types.ADD_ROLES,
  payload,
})

export const removeRoles = (payload) => ({
  type: types.REMOVE_ROLES,
  payload,
})

export const assignRole = (payload, options) => ({
  type: types.REQUEST_ASSIGN_ROLE,
  payload,
  options,
})

export const assignRoleError = (payload, options) => ({
  type: types.REQUEST_ASSIGN_ROLE_ERROR,
  payload,
  options,
})

export const assignRoleFulfilled = (payload, options) => ({
  type: types.REQUEST_ASSIGN_ROLE_FULFILLED,
  payload,
  options,
})

export const unassignRole = (payload, options) => ({
  type: types.REQUEST_UNASSIGN_ROLE,
  payload,
  options,
})

export const unassignRoleError = (payload, options) => ({
  type: types.REQUEST_UNASSIGN_ROLE_ERROR,
  payload,
  options,
})

export const unassignRoleFulfilled = (payload, options) => ({
  type: types.REQUEST_UNASSIGN_ROLE_FULFILLED,
  payload,
  options,
})

export const assignFleet = (payload, options) => ({
  type: types.REQUEST_ASSIGN_FLEET,
  payload,
  options,
})

export const assignFleetError = (payload, options) => ({
  type: types.REQUEST_ASSIGN_FLEET_ERROR,
  payload,
  options,
})

export const assignFleetFulfilled = (payload, options) => ({
  type: types.REQUEST_ASSIGN_FLEET_FULFILLED,
  payload,
  options,
})

export const unassignFleet = (payload, options) => ({
  type: types.REQUEST_UNASSIGN_FLEET,
  payload,
  options,
})

export const unassignFleetError = (payload, options) => ({
  type: types.REQUEST_UNASSIGN_FLEET_ERROR,
  payload,
  options,
})

export const unassignFleetFulfilled = (payload, options) => ({
  type: types.REQUEST_UNASSIGN_FLEET_FULFILLED,
  payload,
  options,
})

export const onChangeUser = (payload, options) => ({
  type: types.ON_CHANGE_USER,
  payload,
  options,
})