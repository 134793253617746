import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Button, Paper, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, IconButton } from '@material-ui/core'
import { SingleSelectValidator, CustomDatePicker, Label, StyledModal, AnimatedSuccessTick, AnimatedErrorCross, SelectableValueText } from 'frame/components'
import { capitalize } from 'utils'
import { getStatusColor } from 'listable'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import moment from 'moment'
import ReactGA from 'react-ga'
import { hasRoles } from 'acl'
import ClearIcon from '@material-ui/icons/Clear'
import DeleteIcon from '@material-ui/icons/Delete'
import { useEnabledFeatureToggleList } from 'features/settings'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  bold: {
    fontWeight: 'bold',
  },
  deleteIcon: {
    color: theme.palette.brand,
  },
  roundedButton: {
    borderRadius: '10px',
    padding: theme.spacing(0.4, 1.5),
    fontSize: '12px',
    textTransform: 'none',
    color: '#FFF',
    background: '#8bc34a',
    "&:hover": {
      backgroundColor: '#618833',
    }
  },
  textField: {
    width: '100%',
    '& .MuiOutlinedInput-input': {
      padding: theme.spacing(1.6, 2),
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 14px) scale(1)',
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
  },
  statusArea: {
    width: '180px',
  }
}))

const newVariationState = {
  id: 0,
  agreementNo: null,
  thirdPartyNumber: null,
  ordNumber: 1,
  maturityDate: moment().add(1, 'months'),
  contractKm: 0,
  noOfTyres: 0,
  status: 'requested',
  isNew: true,
}

const VariationRequestsTable = ({ readOnly, data, flags, errorMessage, userRoles, onUpdate, onDeleteVariation, setUpdatingStatus }) => {

  const form = useRef()
  const classes = useStyles()
  const featureToggleList = useEnabledFeatureToggleList()
  const userPermissionsFeatureToggle = featureToggleList.includes('UserPermissions')
  const [fleetId] = useState(data.thirdPartyNumber)
  const [originalVariations, setOriginalVariations] = useState(data.variations)
  const [isEditingKey, setIsEditingKey] = useState()

  const [requestVariations, setRequestVariations] = useState(data.variations ? data.variations : [])
  const [deleteVariationValue, setDeleteVariationValue] = useState(null)
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false)

  const [values, setValues] = useState({ // for new variation entry row
    agreementNo: data.agreementNo,
    thirdPartyNumber: data.thirdPartyNumber,
    ...newVariationState
  })

  
  useEffect(() => { // update local state when data changes after fetch
    if(data.variations && JSON.stringify(data.variations) !== JSON.stringify(requestVariations)) {
      setRequestVariations(data.variations)
    }// eslint-disable-next-line
  }, [data.variations])
  
  useEffect(() => { // open confirmation modal on success/error flag
    if(flags.update === 'processed' || flags.update === 'error' || flags.deleteVariation === 'processed' || flags.deleteVariation === 'error') {
      setOpenConfirmationModal(true)
    }
  }, [flags.update, flags.deleteVariation])

  useEffect(() => { // custom validation for contractKm field
    ValidatorForm.addValidationRule('isValidKilometres', (value) => value > -1)
    return () => {
      ValidatorForm.removeValidationRule('isValidKilometres')
    }
  }, [values.contractKm])

  const onSubmitForm = () => {
    let newVariations = requestVariations
    newVariations.push(values) // push new variation row to existing variations array
    setOriginalVariations(newVariations) // update original values

    const payload = {
      id: data.id,
      agreementNo: data?.agreementNo,
      thirdPartyNumber: data?.thirdPartyNumber,
      variations: newVariations,
      vehicleNotes: values.vehicleNotes,
      driverNotification: values.driverNotification
    }

    if (payload.variations.length > 0)
    {
      console.log('[GA] event tracked', { category: 'Request', action: 'Submit New', label: 'Contract Variations' })
      ReactGA.event({ category: 'Request', action: 'Submit New', label: 'Contract Variations' })
    }
    console.log('[Variations onSubmitForm]: ', payload)
    onUpdate(payload)
    setIsEditingKey(null) // reset any editable status that is active
    onClear() // reset values for new entry
  }

  const onSelectChange = (key) => (value) =>
    setValues({ ...values, [key]: value })

  const onInputChange = (key) => ({ target: { value } }) =>
    setValues({ ...values, [key]: value })

  const onSubmitNewRequestVariation = () =>
    form.current && form.current.submit()

  const onDeleteVariationHandler = () => {
    if(deleteVariationValue && deleteVariationValue.id !== 0) {
      console.log('[onDeleteVariationHandler]: ', { deleteVariationValue, fleetId })
      onDeleteVariation(deleteVariationValue.id, fleetId)
      setIsEditingKey(null) // reset any editable status that is active
      setDeleteVariationValue(null)
    } else {
      console.log('[onDeleteVariationHandler]: error ', { deleteVariationValue, fleetId })
    }
  }

  const onClear = () =>
    setValues({
      ...values,
      maturityDate: moment().add(1, 'months').format('YYYY-MM-DD'),
      contractKm: 0,
      noOfTyres: 0,
    })
  
  // request variation button validation 
  const invalidVariationDate = moment(values.maturityDate).isBefore(moment().add(1, 'months'), 'month')
    
  const level1StatusOptions = [
    { value: 'requested', label: 'Requested' },
    { value: 'accepted', label: 'Accepted' },
    { value: 'cancelled', label: 'Cancelled' }
  ]

  const level2StatusOtions = [
    { value: 'accepted', label: 'Accepted' },
    { value: 'cancelled', label: 'Cancelled' },
    { value: 'completed', label: 'Completed' }
  ]

  const onSaveStatus = () => {
    setUpdatingStatus(true) // to customize confirmation message
    const payload = {
      id: data.id,
      agreementNo: data?.agreementNo,
      thirdPartyNumber: data?.thirdPartyNumber,
      variations: requestVariations,
      vehicleNotes: values.vehicleNotes,
      driverNotification: values.driverNotification
    }
    console.log('[Variations onSaveStatus]: ', payload)
    onUpdate(payload)
  }

  const onStatusChange = (id) => (option) => {
    const variations = requestVariations.map((item) => id === item.id 
      ? { ...item, status: option.value || option } 
      : item
    )
    console.log('onStatusChange', { id, option, variations, requestVariations, originalVariations })
    setRequestVariations(variations)
  }

  const onCancelChange = (id) => () => {
    const variationList = requestVariations.map((item, index) => id === item.id 
      ? { ...item, status: originalVariations[index].status } 
      : item
    )
    console.log('onCancelChange', { id, requestVariations, originalVariations })
    setRequestVariations(variationList)
  }
  
  const onEdit = (key, id) => () => { // function passed into SelectableValueText component to call on edit mode select
    // reset all values, except current key
    const variationList = requestVariations.map((item, index) => id === item.id ? item : originalVariations[index])
    console.log('onCancelChange', { id, requestVariations, originalVariations })
    setRequestVariations(variationList)

    setIsEditingKey(key)  // reset other fields out of edit mode
  }
  
  const newVariationRow = userPermissionsFeatureToggle ? hasRoles(['superadmin', 'fleetcontroller'], userRoles) && requestVariations && requestVariations.length < 3 : requestVariations && requestVariations.length < 3

  return (
    <ValidatorForm autoComplete="off" noValidate ref={form} onSubmit={onSubmitForm}>
      <Paper className={classes.root}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {[
                  { heading: 'New Maturity Date', minWidth: 160 },
                  { heading: 'Total Kilometers', minWidth: 140 },
                  { heading: 'Additional Tyres', minWidth: 130 },
                  { heading: 'Status', minWidth: 140, align: 'left' },
                  { heading: 'Action', minWidth: 0 },
                  { heading: '', minWidth: 0 },
                ]
                .map(({ heading, minWidth, align }, index) => (
                  <TableCell
                    className={classes.bold}
                    key={`variation-table-heading-${index}`}
                    style={{ minWidth }}
                    align={align ? align : 'center'}>
                    {heading}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {/* Existing Variations Rows */}
              {requestVariations.length > 0 && requestVariations.map((row, index) => (  
                  <TableRow key={`variation-row-${index}`}>
                    <TableCell align="center"> {/* New Maturity Date */}
                      {row.maturityDate ? moment(row.maturityDate).format('MMMM YYYY') : '-'}
                    </TableCell>

                    <TableCell align="center"> {/* Total Kilometres */}
                      {row.contractKm ? parseInt(row.contractKm, 10).toLocaleString('en-NZ') : '-'}
                    </TableCell>

                    <TableCell align="center"> {/* Additinal Tyres */}
                      {row.noOfTyres ? row.noOfTyres : '-'}
                    </TableCell>

                    <TableCell align="center"> {/* Status */}
                      {row.status && hasRoles(['superadmin'], userRoles) && !readOnly ? (
                        <div className={classes.statusArea}>
                          <SelectableValueText
                            large
                            noLabel
                            id={`variantionStatus${index}`}
                            label="Variantion Status"
                            noNone
                            options={row.status && row.status.toLowerCase() === 'requested' ? level1StatusOptions : level2StatusOtions}
                            outline={getStatusColor(row.status)}
                            value={row.status ? row.status.toLowerCase() : ''}
                            onCancel={onCancelChange(row.id)}
                            onChange={onStatusChange(row.id)}
                            onSave={onSaveStatus}
                            toggleEdit={isEditingKey !== `variantionStatus${index}`}
                            onEdit={onEdit(`variantionStatus${index}`, row.id)}
                          />
                        </div>
                      ) : (
                        row.status ? (
                          <Label
                            color={getStatusColor(row.status)}
                            variant="outlined">
                            {capitalize(row.status)}
                          </Label> 
                        ) : '-'
                      )}
                    </TableCell>

                    <TableCell align="center"> {/* Delete Action */}
                    {userPermissionsFeatureToggle && hasRoles(['superadmin', 'fleetcontroller'], userRoles) &&
                      <Tooltip title="Delete">
                        <span>
                          <IconButton size="small" onClick={() => setDeleteVariationValue(row)} disabled={readOnly}>
                            <DeleteIcon className={readOnly ? '' : classes.deleteIcon} />
                          </IconButton>
                        </span>
                      </Tooltip>
                    }
                    </TableCell>

                    <TableCell align="center"> {/* Buttons */}
                      <Button
                        size="small"
                        disabled
                        className={classes.roundedButton}
                        variant="contained"
                        onClick={null}>
                        Submitted
                      </Button>
                    </TableCell>
                  </TableRow>
                )
              )}

              {/* New Variation Request Row */}
              { newVariationRow && (
                <TableRow>
                  <TableCell align="center">
                    <CustomDatePicker
                      disabledPastDates
                      variant="outlined"
                      disabled={readOnly}
                      label="New Maturity"
                      views={["year", "month"]}
                      value={values.maturityDate}
                      onChange={onSelectChange('maturityDate')}
                      format="MMMM YYYY"
                      minDate={moment().add(1, 'months').format('YYYY-MM-DD')}
                      maxDate={moment().add(10, 'years').endOf('year').format('YYYY-MM-DD')}
                      textFieldClassName={classes.textField}
                    />
                  </TableCell>
  
                  <TableCell align="center">
                    <TextValidator
                      fullWidth
                      variant="outlined"
                      className={classes.textField}
                      disabled={readOnly}
                      id="contractKm"
                      label="Total Kilometres"
                      value={values.contractKm}
                      inputProps={{ maxLength: 6 }}
                      validators={['required', 'isNumber', 'isValidKilometres']}
                      errorMessages={['This field is required', 'This must be in numbers', 'This must be a positive numbers']}
                      onChange={onInputChange('contractKm')}
                    />
                  </TableCell>

                  <TableCell className={classes.cell}>
                    <SingleSelectValidator
                      tight
                      numberOnly
                      disabled={readOnly}
                      id="noOfTyres"
                      label="Tyres"
                      options={[2, 4, 6, 8, 10, 12]}
                      value={values.noOfTyres || 0}
                      validators={['required']}
                      errorMessages={['This field is required']}
                      onChange={onSelectChange('noOfTyres')}
                    />
                  </TableCell>
                  <TableCell align="center">
                    -
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title="Clear">
                      <span>
                        <IconButton size="small" onClick={onClear} disabled={readOnly}>
                          <ClearIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      id="request-variation-button"
                      data-cy="request-variation-button"
                      className={classes.roundedButton}
                      variant="contained"
                      disabled={(userPermissionsFeatureToggle && hasRoles(['fleetcoordinator'], userRoles)) || invalidVariationDate || readOnly }
                      onClick={onSubmitNewRequestVariation}>
                      Request
                    </Button>
                  </TableCell>
  
                </TableRow>

              )}

            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      {/* Delete Variation Confirmation Modal */}
      <StyledModal
        open={deleteVariationValue ? true : false}
        title={`Delete Variation`}
        onCancel={() => setDeleteVariationValue(null)}
        onConfirm={onDeleteVariationHandler}>
        Please confirm that you wish to delete this variation request.
      </StyledModal>

      {/* Request/Delete Variation success/error confirmation messages */}
      <StyledModal
        open={openConfirmationModal}
        onCancel={() => {
          setOpenConfirmationModal(false)
          setUpdatingStatus(false)
        }}
        animatedIcon={flags.update === 'processed' || flags.deleteVariation === 'processed'
          ? <AnimatedSuccessTick size={50} />
          : <AnimatedErrorCross size={50} />}
        options={['noButtons']}>
        {flags.update === 'processed'
          ? flags.updatingStatus
            ? 'Contract Variation status updated successfully.'
            : 'Contract Variation requested successfully.'
          : flags.deleteVariation === 'processed'
            ? 'Contract Variation deleted successfully.'
            : flags.deleteVariation === 'error'
                ? `Contract Variation delete failed. ${errorMessage}`.trim()
                : `Contract Variation request failed. ${errorMessage}`.trim()}
      </StyledModal>
    </ValidatorForm>
  )
}

VariationRequestsTable.propTypes = {
  readOnly: PropTypes.bool,
  data: PropTypes.object.isRequired,
  flags: PropTypes.object.isRequired,
  errorMessage: PropTypes.string.isRequired,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  // func
  onUpdate: PropTypes.func.isRequired,
  onDeleteVariation: PropTypes.func.isRequired,
  setUpdatingStatus: PropTypes.func.isRequired,
}

export default VariationRequestsTable