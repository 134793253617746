import React, { forwardRef } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { Link as UILink } from '@material-ui/core'


const useStyles = makeStyles(() => ({
  root: {},
}))

const RouteLink = forwardRef((props, ref) => ( // eslint-disable-line
  <RouterLink innerRef={ref} {...props} />
))

const Link = ({ children, className, ...props }) => {

  const classes = useStyles()

  return (
    <UILink
      className={clsx(classes.root, className)}
      component={RouteLink}
      {...props}>
      {children}
    </UILink>
  )
}

Link.propTypes = {
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
  to: PropTypes.string.isRequired,
}

export default Link
