import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'
import { makeStyles } from '@material-ui/styles'
import { Divider, Typography, Tooltip, IconButton, Grid } from '@material-ui/core'
import { DateRangePicker } from 'frame/components'
import { hasRoles } from 'acl'
import clsx from 'clsx'

import EditIcon from '@material-ui/icons/Edit'
import SaveIcon from '@material-ui/icons/Save'
import ClearIcon from '@material-ui/icons/Clear'


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  info: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    position: 'relative',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(-2),
  },
  label: {
    paddingRight: theme.spacing(1),
    minWidth: '150px',
    fontWeight: '500',
  },
  options: {
    marginBottom: '0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    position: 'absolute',
    top: props => props.showIsRequiredError ? -23 : -10,
    right: 0,
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      top: -30,
    },
  },
  tooltip: {
    marginLeft: theme.spacing(1),
  },
  icon: {
    width: 20,
    height: 20,
  },
  spacer: {
    display: 'flex',
    width: '80px',
    height: '20px',
  },
  topPadding: {
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(-5),
    }
  },
}))

const DateRangeValueText = ({
  disabled,
  userRoles,
  format,
  label,
  startValue,
  endValue,
  startMinDate,
  endMaxDate,
  onCancel,
  onDateRangeChange,
  onDateRangeSaveChanges,
  toggleEdit, // to toggle edit mode from outside this valueText component
  onEdit, // custom function passed to call on editmode select
}) => {

  const [editMode, setEditMode] = useState(false)
  const [showIsRequiredError, setShowIsRequiredError] = useState(false)
  const [inputValue, setInputValue] = useState([startValue || null , endValue || null]) // to keep track of value change locally for required field validation
  const classes = useStyles({ showIsRequiredError })

  useEffect(() => {
    if(toggleEdit) {
      setEditMode(false)
      setShowIsRequiredError(false)
    }
  }, [toggleEdit])
  
  useEffect(() => {
    const requiredRule = inputValue && ((inputValue[0] === null || inputValue[0] === '') || (inputValue[1] === null || inputValue[1] === ''))
    setShowIsRequiredError(requiredRule)
    // eslint-disable-next-line
  }, [inputValue])

  const hasAccess = ! hasRoles(['fleetcoordinator'], userRoles)
  const editable = !disabled && hasAccess && onDateRangeChange

  const onSaveHandler = () => {
    !editMode && setEditMode(true)
    if(editMode && moment(startValue) < moment(endValue)) {
      
      onDateRangeSaveChanges && onDateRangeSaveChanges(startValue, endValue)
      // console.log('value text onSaveHandler ', { startDate: moment(startValue).format('DD/MM/YY'), endDate: moment(endValue).format('DD/MM/YY') })
      setEditMode(! editMode)    
    }
    onEdit && onEdit() // call custom onEdit() function if onEdit prop exists
  }

  const onClearHandler = () => {
    setShowIsRequiredError(false)
    onCancel()
    setEditMode(! editMode)
  }

  return (
    <div className={classes.root}>
      <div className={classes.info}>
        <Grid container spacing={1}>
          <Grid item md={2} sm={3} xs={12}>
            <Typography className={classes.label} variant="body1">
              {label}
            </Typography>
          </Grid>

          <Grid item md={9} sm={8} xs={12}>

            {editMode ? (
              <div className={classes.editable}>
                <DateRangePicker
                  tight allowSameDateSelection
                  format={format}
                  values={[startValue, endValue]}
                  startDateInputSize={280}
                  startMinDate={startMinDate}
                  endMaxDate={endMaxDate}
                  endDateInputSize={280}
                  onDateRangeChange={(startDate, endDate) => {
                    onDateRangeChange(startDate, endDate)
                    setInputValue([startDate, endDate])
                  }}
                  showStartDateRequiredError={showIsRequiredError && inputValue && (inputValue[0] === null || inputValue[0] === '')}
                  showEndDateRequiredError={showIsRequiredError && inputValue && (inputValue[1] === null || inputValue[1] === '')}
                  placeholderText="DD/MM/YYYY"
                />
              </div> 
            ) : (          
              <Typography variant="body1" align="left">
                {moment(startValue).format('Do MMMM YYYY') || '-'}
                <span style={{ padding: '0 10px'}}>
                  {' to '}
                </span>
                {moment(endValue).format('Do MMMM YYYY') || '-'}
              </Typography>
            )}

            {editable && (
              <div className={classes.spacer} />
            )}

          </Grid>
        </Grid>

        <div className={classes.options}>
          {editable && (
            <Tooltip className={classes.tooltip} title={editMode ? 'Save' : 'Edit'}>
              <IconButton size="small" onClick={onSaveHandler} disabled={showIsRequiredError}>
                {editMode ? (
                  <SaveIcon className={clsx(classes.icon, classes.topPadding)} />
                ) : (
                  <EditIcon className={classes.icon} />
                )}
              </IconButton>
            </Tooltip>
          )}
          {editMode && (
            <Tooltip className={classes.tooltip} title="Cancel">
              <IconButton className={classes.topPadding} size="small" onClick={onClearHandler}>
                <ClearIcon className={classes.icon} />
              </IconButton>
            </Tooltip>
          )}
        </div>

      </div>
      <Divider />
    </div>
  )
}

DateRangeValueText.propTypes = {
  disabled: PropTypes.bool,
  userRoles: PropTypes.array.isRequired,
  format: PropTypes.string,
  label: PropTypes.string.isRequired,
  startValue: PropTypes.string,
  startMinDate: PropTypes.any,
  endValue: PropTypes.string,
  endMaxDate: PropTypes.any,
  onCancel: PropTypes.func,
  onDateRangeChange: PropTypes.func,
  onDateRangeSaveChanges: PropTypes.func,
  toggleEdit: PropTypes.bool, // to toggle edit mode from outside this valueText component
  onEdit: PropTypes.func, // custom function passed to call on editmode select
}

const mapStateToProps = ({ settings }) => ({
  userRoles: settings.roles,
})

const mapDispatchToProps = {}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DateRangeValueText)