import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { List, ListItem, ListItemIcon, ListItemText, Typography, Collapse, Divider } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'

 // Icons
 import ExpandLess from '@material-ui/icons/ExpandLess'
 import ExpandMore from '@material-ui/icons/ExpandMore'

const useStyles = makeStyles(theme => ({
  divider: {
    margin: theme.spacing(0, 2, 0, -2),
  },
  navItem: {
    minHeight: '44px',
    padding: '5px 0 5px 0',
    '&:hover': { backgroundColor: 'transparent' },
  },
  navItemLabel: {
    fontWeight: 400,
    color: 'rgba(233,236,239,.5)',
    '&:hover': {
      color: '#ced4da',
    },
    '& span, & svg': {
      fontSize: '14px',
    }
  },
  navItemLabelText: {
    // color: '#4D4F53',
    fontSize: '14px',
    letterSpacing: '0',
    lineHeight: '19px',
    '&:hover': { opacity: '0.8' },
  },
  selectedIcon: {
    color: '#ced4da',
    width: '18px',
    height: '18px',
  },
  selected: {
    // color: '#4D4F53',
    color: '#4D4F53',
    textDecoration: 'underline',
    textDecorationThickness: '2px',
    textDecorationColor: '#4D4F53',
    textUnderlinePosition: 'under',
  },
  nestedItem: {
    marginLeft: theme.spacing(-2),
    '&:hover': { backgroundColor: 'transparent' },
    '-webkit-font-smoothing': 'antialiased',
    'transition': '.3s'
  },
}))

const NavBarAccordionNestedItem = ({ index, title, Icon, nestedItems, isSelected, openMostRecentOnLoad }) => {

  const classes = useStyles()
  const [openNested, setOpenNested] = useState(openMostRecentOnLoad ? index === 0 : false)

  return (
    <>
      <Divider className={classes.divider} />
      <ListItem button onClick={() => setOpenNested(!openNested)} className={classes.navItem}>
        {Icon && (
          <ListItemIcon>
            <Icon className={classes.navIcon} />
          </ListItemIcon>
        )}
        <ListItemText
          className={classes.navItemLabel}
          primary={<Typography type="body1" className={classes.navItemLabelText}>
            {title}
          </Typography>}
        />
        <div style={{ marginRight: 20, marginTop: 5 }}>
          {nestedItems.length > 0 && (<>
            {openNested ? <ExpandLess className={classes.navIcon} /> : <ExpandMore className={classes.navIcon} />}
          </>)}
        </div>
      </ListItem>

      <Collapse in={openNested} timeout="auto" unmountOnExit>
        <List component="div" disablePadding style={{ paddingLeft: 30 }}>
          {nestedItems.map((nestedItem, nestedIndex) => (
            <ListItem
              button
              className={clsx(classes.navItem, classes.nestedItem)}
              style={{
                marginBottom: nestedItems.length - 1 === nestedIndex ? 15 : 0,
              }}
              key={`nav-bar-accordion-level-3-items-${nestedIndex}`}
              component={RouterLink}
              to={nestedItem.link}
            >
              {nestedItem?.Icon && ( // Icon
                <ListItemIcon>
                  <nestedItem.Icon className={isSelected(nestedItem.link) ? classes.selectedIcon : classes.navIcon} />
                </ListItemIcon>
              )}
              <ListItemText
                  className={classes.navItemLabel}
                  primary={<Typography type="body1" className={clsx(classes.navItemLabelText, isSelected(nestedItem.link) ? classes.selected : '')}>
                  {nestedItem.title}
                </Typography>}
              />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  )
}

NavBarAccordionNestedItem.propTypes = {
  index: PropTypes.number,
  title: PropTypes.string,
  Icon: PropTypes.array,
  nestedItems: PropTypes.array,
  isSelected: PropTypes.func,
  openMostRecentOnLoad: PropTypes.bool,
}

export default NavBarAccordionNestedItem