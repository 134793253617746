import { colors as themeColors } from '@material-ui/core'
import moment from 'moment'
import { capitalize } from 'utils'


export const barGraphFormatter = (state, { payload }) => {

  if ( ! payload?.dataPoints) {
    return state
  }

  let name = 'Overdue'
  let data = payload.dataPoints
  //console.log('raw data', data)

  Object.keys(payload)
    .forEach(key => {
      if (key !== 'dataPoints') {
        name = capitalize(key)
        data = [payload[key], ...data]
      }
    })

  // console.log('[transform graph (data)]', data)

  const colors = []
  const labels = []
  const ranges = []
  const values = []
  const valuesStacked = []

  const getMonth = (date) =>
    moment(date)
      .format('MMM')

  const createRange = (date) =>
    moment(date)
      .add(1, 'months')
      .date(1)

  const createRangeHistory = (date) =>
    moment()
      .subtract(20, 'years')
      .date(1)

  // console.log('[bar chart data]: ', { data, name })
  
  data.forEach((node, index) => {
    if (index === 0) { // first bar chart column
      colors.push(themeColors.red[600])
      labels.push(name)
      ranges.push(createRangeHistory())
    
    }
    else if (index === 1 || index === 2 ) { // 2nd & 3rd bar chart columns
      colors.push(themeColors.orange[600])
      labels.push(getMonth(node.date))
      ranges.push(createRange(node.date))
    }
    else {
      colors.push(themeColors.green[600])
      labels.push(getMonth(node.date))
      ranges.push(createRange(node.date))
    }

    values.push(node.count)
    // only add "stacked" if relevant, API will return -1 by default
    if (node.countStacked > -1 ) valuesStacked.push(node.countStacked)
  })

  return {
    ...state,
    data: {
      colors,
      labels,
      ranges,
      values,
      valuesStacked,
    },
  }
}
