import { combineEpics, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { filter, mergeMap } from 'rxjs/operators'
import { makeActionRequestEffect } from 'effects'
import { getAppConfig } from 'app-config'
import join from 'url-join'
import * as fromModuleTypes from './actions/types'
import * as fromFleetTypes from 'features/fleet/actions/types'
import * as fromDriverTypes from 'features/drivers/actions/types'


export const createEffects = (moduleActions) => {

  const appConfig = getAppConfig()

  const fetchEffect = makeActionRequestEffect({
    type: fromModuleTypes.FETCH,
    url: join(appConfig.app.api, 'contractvariation/variation'),
    onSuccess: moduleActions.fetchFulfilled,
    onError: moduleActions.fetchError,
  })

  const refetchEffect = (action$, state$) =>
    action$.pipe(
      ofType(
        fromFleetTypes.UPDATE_FULFILLED,
        fromFleetTypes.DELETE_VARIATION_FULFILLED,
        fromDriverTypes.CREATE_FULFILLED,
        fromDriverTypes.UPDATE_FULFILLED,
        fromDriverTypes.DELETE_FULFILLED,
        fromDriverTypes.TOGGLE_POOL_VEHICLE_FULFILLED,
        fromDriverTypes.UNASSIGN_VEHICLE_FULFILLED,
      ),
      filter(() => window.location.pathname.includes('/contract-variations')), // to trigger on request variation summary page only
      mergeMap(() => of(
        moduleActions.refetch(),
      )
      )
    )

  const lookupEffect = makeActionRequestEffect({
    type: fromModuleTypes.LOOKUP,
    url: join(appConfig.app.api, `lookup`),
    onSuccess: moduleActions.lookupFulfilled,
    onError: moduleActions.lookupError,
  })

  return combineEpics(
    fetchEffect,
    refetchEffect,
    lookupEffect
  )
}
