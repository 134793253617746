import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Button, Grid, Typography } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import CustomTabs from './custom-tabs'
import SingleSelect from './single-select'
import { useRouter } from 'utils'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'

const useStyles = makeStyles(theme => ({
  root: { padding: theme.spacing(2, 0, 4, 0) },
  center: { display: 'flex', justifyContent: 'center' },
  title: {
    maxWidth: 450,
  },
  wordWrap: {
    textOverflow: 'ellipsis', 
    whiteSpace: 'nowrap',
    overflow: 'hidden', 
  },
  dropdown: {
    backgroundColor: '#FFF',
    borderRadius: 10,
    width: 300,
    [theme.breakpoints.down('sm')]: { width: '100%', margin: theme.spacing(5, 0, 2, 0) },
    [theme.breakpoints.down('xs')]: { marginBottom: 0 }
  },
  button: {
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('xs')]: { margin: theme.spacing(2, 0, 0, 0) }
  },
  buttonArea: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: { width: '100%', margin: theme.spacing(-3, 0, -1, 0) },
  },
  backLink: {
    color: '#546e7a',
    fontSize: '11px',
    fontWeight: '500',
    lineHeight: '13px',
    letterSpacing: '0.33px',
    '&:hover': {
      background: 'none',
      textDecoration: 'underline',
    }
  }
}))

const PageHeader = ({
  subTitleStyles,
  config,
  className,
  breadcrumbs,
  breadcrumbsLink,
  title,
  subtitle,
  tabsConfig,
  loading,
  options = [],
  disabledButtons,

  primaryButton,
  secondaryButton,
  dropdown,
  ...rest
}) => {

  const theme = useTheme()
  const classes = useStyles()
  const { history } = useRouter()

  const mobileView = useMediaQuery(theme.breakpoints.down('xs'))

  // custom options
  const titleWordWrap = options.includes('titleWordWrap')

  const onNavigate = (link) => link && history.push(link)

  return (
    <div {...rest} className={clsx(classes.root, className)}>

      {tabsConfig ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography component="h1" variant="h3" align="center">
              {title || ''}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.center}>
            {!loading && (
              <CustomTabs tabsConfig={tabsConfig} disabled={loading} />
            )}
          </Grid>
        </Grid>
      ) : (
        <Grid alignItems="flex-end" container justify="space-between" spacing={3} style={!primaryButton && !secondaryButton ? { paddingTop: 18 } : {}}>
          <Grid item>
            {breadcrumbs && breadcrumbsLink && (
              <Button className={classes.backLink} size="small" startIcon={<KeyboardBackspaceIcon />} onClick={() => onNavigate(breadcrumbsLink)}>
                Back to {breadcrumbs}
              </Button>
            )}
            <Typography component="h1" variant="h3" className={clsx(classes.title, titleWordWrap ? classes.wordWrap : '')}>
              {title}
            </Typography> 
            {subtitle && (
              <Typography component="h6" variant="h6" align="left" style={ subTitleStyles ? subTitleStyles : { margin: '5px 0 -30px 0' }}>
                {subtitle}
              </Typography>
            )}
          </Grid>
          
          <Grid item className={classes.buttonArea}>

            {/* Dropdown */}
            {dropdown && !dropdown.hide && (
              <div className={classes.dropdown}>
                <SingleSelect
                  noNone tight
                  id="pageHeaderDropdown"
                  label={dropdown.label}
                  options={dropdown.options}
                  value={dropdown.value}
                  onChange={dropdown.onChange}
                  disabled={disabledButtons || dropdown.disable}
                />
              </div>
            )}

            {/* Secondary Button (Left) */}
            {secondaryButton && secondaryButton.action && !secondaryButton.hide && (
              <Button
                fullWidth={mobileView}
                disabled={disabledButtons || secondaryButton.disable}
                id="secondary-action"
                data-cy="secondary-action"
                className={classes.button}
                color={secondaryButton.color || 'secondary'}
                variant="contained"
                onClick={secondaryButton.action}>
                {secondaryButton.label}
              </Button>
            )}
            
            {/* Primary Button (Right) */}
            {primaryButton && primaryButton.action && !primaryButton.hide && (
              <Button
                fullWidth={mobileView}
                disabled={disabledButtons || primaryButton.disable}
                id="primary-action"
                data-cy="primary-action"
                className={classes.button}
                color={primaryButton.color || 'primary'}
                variant="contained"
                onClick={primaryButton.action}>
                {primaryButton.label}
              </Button>
            )}
          </Grid>
        </Grid>
      )}
      
    </div>
  )
}

PageHeader.propTypes = {
  subTitleStyles: PropTypes.any,
  config: PropTypes.any,
  className: PropTypes.string,
  breadcrumbs: PropTypes.string,
  breadcrumbsLink: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  tabsConfig: PropTypes.array,
  loading: PropTypes.bool,
  options: PropTypes.array,
  disabledButtons: PropTypes.bool,

  primaryButton: PropTypes.shape({
    label: PropTypes.string,
    action: PropTypes.func,
    hide: PropTypes.bool,
    disable: PropTypes.bool,
  }),

  secondaryButton: PropTypes.shape({
    label: PropTypes.string,
    action: PropTypes.func,
    color: PropTypes.string,
    hide: PropTypes.bool,
    disable: PropTypes.bool,
  }),

  dropdown: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    options: PropTypes.array,
    onChange: PropTypes.func,
    hide: PropTypes.bool,
    disable: PropTypes.bool,
  }),
}

export default PageHeader
