import * as types from './constants'


export const reset = () => ({
  type: types.RESET
})

// Upload Csv
export const uploadCsv = (payload) => ({
  type: types.UPLOAD_CSV,
  payload,
})

export const uploadCsvError = (payload) => ({
  type: types.UPLOAD_CSV_ERROR,
  payload,
})

export const uploadCsvFulfilled = (payload) => ({
  type: types.UPLOAD_CSV_FULFILLED,
  payload,
})

// Send Invite Emails
export const sendInviteEmails = (payload) => ({
  type: types.SEND_INVITE_EMAILS,
  payload,
})

export const sendInviteEmailsError = (payload) => ({
  type: types.SEND_INVITE_EMAILS_ERROR,
  payload,
})

export const sendInviteEmailsFulfilled = (payload) => ({
  type: types.SEND_INVITE_EMAILS_FULFILLED,
  payload,
})


// Register Interest Form Submit Action for registerOfInterest landing page
export const registerInterest = (payload) => ({
  type: types.REGISTER_INTEREST,
  payload,
})

export const registerInterestError = (payload) => ({
  type: types.REGISTER_INTEREST_ERROR,
  payload,
})

export const registerInterestFulfilled = (payload) => ({
  type: types.REGISTER_INTEREST_FULFILLED,
  payload,
})