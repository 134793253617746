import React from 'react'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/styles'
import { BehaviorSubject } from 'rxjs'
import { switchMap } from 'rxjs/operators'
import { AuthProvider } from 'auth'
import theme from 'theme'
import { getAppConfig } from 'app-config'
import rootEffect from '../effects'
import Routes from '../routes'
import { configureStore, epicMiddleware, history } from '../store'
import GoogleAnalytics from '../components/google-analytics'
import GoogleAnalytics4 from '../components/google-analytics4'
import SimpleReactLightbox from "simple-react-lightbox"

import { LocalizationProvider } from '@material-ui/pickers'
import MomentUtils from '@material-ui/pickers/adapter/moment'

const { store } = configureStore()

const epic$ = new BehaviorSubject(rootEffect)

const hotReloadingEpic = (...args) =>
  epic$.pipe(
    switchMap(epic => epic(...args))
  )

epicMiddleware.run(hotReloadingEpic)

if (module.hot) {
  module.hot.accept('../effects', () => {
    const nextRootEpic = require('../effects').default
    epic$.next(nextRootEpic)
  })
}

const onRedirectCallback = appState => {

  console.log('onRedirectCallback appState', appState)
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  )
}

const App = () => {

  const config = getAppConfig()

  return (
    <Provider store={store}>
      <AuthProvider
        domain={config.auth.domain}
        client_id={config.auth.clientId}
        audience={config.auth.audience}
        redirect_uri={window.location.origin}
        onRedirectCallback={onRedirectCallback}
        history={history}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={MomentUtils}>
            <ConnectedRouter history={history}>
              <GoogleAnalytics />
              <GoogleAnalytics4 />
              <SimpleReactLightbox>
                <Routes />
              </SimpleReactLightbox>
              <CssBaseline />
            </ConnectedRouter>
          </LocalizationProvider>
        </ThemeProvider>
      </AuthProvider>
    </Provider>
  )
}

export default App
