import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { types } from '../../module'
import { TableCell, TableRow } from '@material-ui/core'
import { createRowSelector } from 'utils'
import { fbtMonthName } from 'listable'

const useStyles = makeStyles(theme => ({
  root: { verticalAlign: 'text-top', cursor: 'pointer' },
  label: theme.typography.bodySmall,
  spacer: { margin: 0, padding: 0, backgroundColor: 'transparent' },
}))

const Row = ({ item, columns, onEdit }) => {
  
  const classes = useStyles()

  const onSafeRowClick = createRowSelector(onEdit)

  return (
    <TableRow hover className={classes.root} onClick={onSafeRowClick}>
      <TableCell className={classes.spacer} />

      {columns.driverName && (
        <TableCell className={classes.label}>
          {item.driverName || '-'}
        </TableCell>
      )}

      {columns.costCentre && (
        <TableCell className={classes.label}>
          {item.costCentre || '-'}
        </TableCell>
      )}

      {/* {columns.totalDaysVehicleAvailable && (
        <TableCell className={classes.label}>
          {item.totalDaysVehicleAvailable || '-'}
        </TableCell>
      )}       */}
      {columns.exemptDays && (
        <TableCell className={classes.label}>
        {item.exemptDays === null ? '-' : item.exemptDays}
        </TableCell>
      )}
      {/* {columns.daysAvailableForPvtUse && (
        <TableCell className={classes.label}>
          {item.daysAvailableForPvtUse || '-'}
        </TableCell>
      )} */}
      {columns.exemptNotes && (
        <TableCell className={classes.label}>
          {item.exemptNotes || '-'}
        </TableCell>
      )}
      {/* {columns.make && (
        <TableCell className={classes.label}>
          {item.make || '-'}
        </TableCell>
      )}
      {columns.model && (
        <TableCell className={classes.label}>
          {item.model || '-'}
        </TableCell>
      )} */}
      {columns.month && (
        <TableCell className={classes.label}>
          {fbtMonthName(item.month) || '-'} 
        </TableCell>
      )}
      {/* {columns.rego && (
        <TableCell className={classes.label}>
          {item.rego || '-'}
        </TableCell>
      )}
      {columns.vehicleYear && (
        <TableCell className={classes.label}>
          {item.vehicleYear || '-'}
        </TableCell>
      )} */}

      <TableCell className={classes.spacer} />      
    </TableRow>
  )
}

Row.propTypes = {
  item: types.ModelType.isRequired,
  columns: types.ColumnsType.isRequired,
  onEdit: PropTypes.func.isRequired,
}

export default Row
