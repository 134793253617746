import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, useTheme } from '@material-ui/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Grid, Typography, Button, Card, CardContent } from '@material-ui/core'
import { RowValueText, AntSwitch, StyledModal } from 'frame/components'
import { useIsAlfaDownDisableFeatures } from 'features/drivers'
import { useEnabledFeatureToggleList } from 'features/settings'
import { hasRoles } from 'acl'
import moment from 'moment'
import {isMiles, isSourceSystemLoaded} from 'utils/sourceSystem'
import Spinner from 'react-md-spinner'



const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 1.2, 3, 1.2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
  },
  driverDetails: {
    paddingBottom: theme.spacing(3),
  },
  content: { marginTop: theme.spacing(3) },
  heading: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(-1),
    }
  },
  brandColor: {
    color: theme.palette.brand,
  },
  poolVehicle: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      marginTop: theme.spacing(-2),
    }
  },
  button: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1),
    }
  },
}))

const AssignedView = ({ 
  driverFlags, 
  fleetData, 
  driverData, 
  userRoles, 
  onUnassignVehicle, 
  onTerminateDriver, 
  onChange, 
  onTogglePoolVehicle, 
  onAssignExistingDriver,
  onSubmit,
  onOpenDateForm 
  }) => {

  const theme = useTheme()
  const classes = useStyles()
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'))

  const featureToggleList = useEnabledFeatureToggleList()
  const disableOutOfHoursFeatureToggle = featureToggleList.includes('OutOfHoursDisabled') // 'ALFA out of hours DISABLE FEATURES' limitations feature is ON
  const isAlfaDownDisableDriverFeatures = useIsAlfaDownDisableFeatures()
  const isAlfaDown = disableOutOfHoursFeatureToggle ? false : isAlfaDownDisableDriverFeatures
  const disableTerminateDriverFeatureToggle = featureToggleList.includes('TFP1169')
  const userPermissionsFeatureToggle = featureToggleList.includes('UserPermissions')
  const showButtons = userPermissionsFeatureToggle ? userPermissionsFeatureToggle && hasRoles(['superadmin','fleetcontroller'], userRoles) : true
  const disableButtons = userPermissionsFeatureToggle && hasRoles(['fleetcoordinator'], userRoles)
  const [isPoolVehicle, setIsPoolVehicle] = useState(fleetData.poolVehicle && fleetData.poolVehicle === 'Yes')

  // modal flags
  const [openConfirmPoolVehicleToggleModal, setConfirmPoolVehicleToggleModal] = useState(false)
  const [openMakeUnassignedVehicleToggleModal, setMakeUnassignedVehicleToggleModal] = useState(false)
  const [openTerminateDriverModal, setOpenTerminateDriverModal] = useState(false)

  useEffect(() => {
    // reset toggle if togglePoolVehicle fails with error flag 
    if(driverFlags.togglePoolVehicle === 'error') {
      setIsPoolVehicle(!isPoolVehicle)
    }
    
  // eslint-disable-next-line
  }, [driverFlags.togglePoolVehicle])

  useEffect(() => {
    // update pool vehicle toggle when fetch once done
    setIsPoolVehicle(fleetData.poolVehicle && fleetData.poolVehicle === 'Yes')
  // eslint-disable-next-line
  }, [fleetData.poolVehicle])
  
  const handlePoolVehicleToggle = (checked) => {
    setIsPoolVehicle(checked)
    if (isMiles(fleetData.sourceSystem)) {
      onOpenDateForm()
    } else {
      setConfirmPoolVehicleToggleModal(true)
    }
  }

  const onConfirmPoolVehicleChange = () => {
    setConfirmPoolVehicleToggleModal(false)
    onTogglePoolVehicle(fleetData.agreementNo, fleetData.thirdPartyNumber, moment(), isPoolVehicle)
  }
  
  const onCancelHandler = () => {
    handlePoolVehicleToggle(fleetData.poolVehicle && fleetData.poolVehicle === 'Yes') // reset default values
    setConfirmPoolVehicleToggleModal(false)
  }

  const onMakeUnassignedVehicle = () => {
    onUnassignVehicle(fleetData.agreementNo, fleetData.thirdPartyNumber)
    setMakeUnassignedVehicleToggleModal(false)
  }
  
  return (
    <Card>
      <CardContent className={classes.root}>
        <Grid alignItems="flex-end" container justify="space-between" spacing={3}>
          <Grid item>
            <Typography gutterBottom variant="h4" className={classes.heading}>
              Driver
            </Typography>
          </Grid>

          <Grid item>
            <Button
              fullWidth={mobileView}
              variant="contained"
              id="secondary-action"
              data-cy="unassign-driver"
              className={classes.button}
              disabled={isAlfaDown || disableButtons}
              onClick={() => setMakeUnassignedVehicleToggleModal(true)}>
              Make Unassigned Vehicle
            </Button>

            <Button
              fullWidth={mobileView}
              variant="contained"
              id="secondary-action"
              data-cy="terminate-driver"
              className={classes.button}
              disabled={disableTerminateDriverFeatureToggle || isAlfaDown || disableButtons}
              onClick={() => setOpenTerminateDriverModal(true)}>
              Terminate Driver
            </Button>
            <Button
              fullWidth={mobileView}
              color="primary"
              variant="contained"
              id="primary-action"
              data-cy="change-driver"
              disabled={isAlfaDown || disableButtons}
              className={classes.button}
              onClick={onAssignExistingDriver}>
              {!driverData.driver ? 'Assign' : 'Change'} Driver
            </Button>
          </Grid>
        </Grid>
     
        <div className={classes.content}><Grid container spacing={3} className={classes.driverDetails}>
        {showButtons && isSourceSystemLoaded(fleetData.sourceSystem) &&
          <Grid item xs={12} className={classes.poolVehicle}>
            <Typography  gutterBottom variant="body1">
              <span className={classes.brandColor}>Pool Vehicle</span>: Indicate if you would like to switch this to a pool vehicle
            </Typography>
            <AntSwitch
              disabled={isAlfaDown}
              style={{ marginLeft: 10 }}
              value={isPoolVehicle}
              onChange={handlePoolVehicleToggle}
              name="poolVehicleSwitch"
            />
          </Grid>
        }
        {!isSourceSystemLoaded(fleetData.sourceSystem) && <Spinner
                  singleColor={theme.palette.dark}
                  size={25}
                />}
        <>
          <Grid item xs={12}>
            <Typography gutterBottom variant="h5">
              Existing Driver Details
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <RowValueText label="Name">
              <Typography variant="body1">
                {driverData.driver || '-'}
              </Typography>
            </RowValueText>
          </Grid>

          <Grid item xs={12}>
            <RowValueText label="Email">
              <Typography variant="body1">
                {driverData.email || '-'}
              </Typography>
            </RowValueText>
          </Grid>

          <Grid item xs={12}>
            <RowValueText label="Phone">
              <Typography variant="body1">
                {driverData.mobile || '-'}
              </Typography>
            </RowValueText>
          </Grid>

          <Grid item xs={12}>
            <RowValueText label="Notification Method">
              <Typography variant="body1">
                {driverData.driverNotification || '-'}
              </Typography>
            </RowValueText>
          </Grid>
          {/* for make unassigned vehicle confirmation */}
          <StyledModal
            open={openMakeUnassignedVehicleToggleModal}
            title={`Removal of Assigned Driver`}
            onCancel={() => setMakeUnassignedVehicleToggleModal(false)}
            onConfirm={onMakeUnassignedVehicle}>
            Please confirm that you wish to remove {driverData.driver} from this vehicle, and make it unassigned with no specified driver.
          </StyledModal>          

          {/* for terminate driver confirmation */}
          <StyledModal
            flag={driverFlags.delete}
            open={openTerminateDriverModal}
            title={`Terminate Driver`}
            onCancel={() => setOpenTerminateDriverModal(false)}
            onConfirm={() => onTerminateDriver(driverData.uniqueId, driverData.thirdPartyNumber)}>
              <strong>IMPORTANT: Please confirm only if {driverData?.driver || 'the driver'} no longer works for your organisation.</strong> 
              <br /><br />
              Please <strong>CANCEL</strong> if you are simply changing a driver/vehicle combination and use <strong>CHANGE DRIVER</strong>.
          </StyledModal>
          {/* for pool vehicle toggle confirmation */}
          <StyledModal
              open={openConfirmPoolVehicleToggleModal}
              title={`Pool Vehicle Change`}
              onCancel={onCancelHandler}
              onConfirm={onConfirmPoolVehicleChange}>
              {isPoolVehicle
                ? driverData.driver
                  ? 'Please confirm that you wish to designate this as a pool vehicle. By doing so, you will be removing the assigned driver from the vehicle. '
                  : 'Please confirm that you wish to designate this as a pool vehicle.'
                : 'You are about to switch this to a non pool vehicle'
              }
            </StyledModal>
        </>
        </Grid>
        </div>
      </CardContent>
    </Card>
  )
}

AssignedView.propTypes = {
  driverFlags: PropTypes.object.isRequired,
  fleetData: PropTypes.object.isRequired,
  driverData: PropTypes.object.isRequired,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  // func
  onUnassignVehicle: PropTypes.func.isRequired,
  onTerminateDriver: PropTypes.func.isRequired,
  onTogglePoolVehicle: PropTypes.func.isRequired,
  onAssignExistingDriver: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onOpenDateForm: PropTypes.func.isRequired
}

export default AssignedView

