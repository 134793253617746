import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { constants } from '../../module'
import { useEnabledFeatureToggleList, useSelectedFleetIds } from 'features/settings'
import { Dialog, StyledModal } from 'frame/components'
import { useNavigate, useRouter } from 'utils'
import AddEditFbtRoutes from './routes'
import { hasRoles } from 'acl'


const Edit = ({ id, data, flags,userRoles, filtered, onFetch, onReset, onNewDraft }) => {

  const navigate = useNavigate()
  const { match } = useRouter()
  const fleetIds = useSelectedFleetIds()

  const [openUnsavedChangesModal, setOpenUnsavedChangesModal] = useState(false)
  const featureToggleList = useEnabledFeatureToggleList()
  const userPermissionsFeatureToggle = featureToggleList.includes('UserPermissions')
  const showTabs = userPermissionsFeatureToggle && hasRoles(['superadmin','fleetcontroller'], userRoles)
  useEffect(() => {
    onReset()
    onFetch(id, fleetIds)
  }, []) // eslint-disable-line

  const onClose = () => {
    const isFiltered = filtered?.year && filtered?.month
    navigate(`${constants.PATH_ALL}${isFiltered ? `?year=${filtered?.year}&month=${filtered?.month}`: ''}`)()
  }

  useEffect(() => {
    // triggers when form is saved, save flags will update and close the form automatically
    if (flags?.update === 'processed' || flags?.addFbtSubmission === 'processed') {
      onClose()
    }
  }, [flags?.update, flags?.addFbtSubmission]) // eslint-disable-line

  const isSaving = flags?.update === 'processing'
  const title = data?.driver ? `${data?.driver} - Fringe Benefit Tax` : `Fringe Benefit Tax`

  const tabsConfig = [
    {
      label: 'Add/Edit Submission',
      path: `${match.url}${constants.PATH_ADD_SUBMISSION}`,
      visible: showTabs,
      customOnClick: onNewDraft,
    }, {
      label: 'View Submissions',
      path: `${match.url}${constants.PATH_VIEW_SUBMISSIONS}`,
      visible: true,
    },
  ].filter(tab => tab.visible)

  return (
    <>
      <Dialog
        open
        size="md"
        minHeight={720}
        headerMinHeight={126}
        aria-labelledby="Edit FBT Submission"
        title={title}
        tabsConfig={tabsConfig}
        loading={isSaving}
        onClose={() => flags?.unsavedChanges ? setOpenUnsavedChangesModal(true) : onClose()}
      >
        <AddEditFbtRoutes />
      </Dialog>

      {/* unsave changes prompt */}
      <StyledModal
        open={openUnsavedChangesModal}
        title={`Confirm Close`}
        onCancel={() => setOpenUnsavedChangesModal(false)}
        onConfirm={onClose}>
        Any changes will not be saved. Are you sure you want to continue?
      </StyledModal>
    </>
  )
}

Edit.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.object,
  flags: PropTypes.object,
  filtered: PropTypes.object,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  // func
  onFetch: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onNewDraft: PropTypes.func.isRequired,
}

export default Edit
