import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { fetchContactDetails } from '../actions'
import { Grid, Typography, Card, CardContent, Divider, Avatar } from '@material-ui/core'
import { getInitials, getInitialsColor } from 'utils'
import { useSelectedFleetIds } from 'features/settings'
import { Skeleton } from '@material-ui/lab'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    minHeight: '317px',
    [theme.breakpoints.down('md')]: { minHeight: '100%' },
  },
  title: {
    color: '#263238',
    fontWeight: 'bold',
    fontSize: '18px',
  },
  divider: {
    margin: theme.spacing(1.5, 0, 2, 0),
  },
  name: {    
    fontSize: '16px',
    fontWeight: 'bold',
    letterSpacing: 0,
    lineHeight: '20px',
  },
  role: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    paddingBottom: 10,
  },
  contactDetails: {
    padding: theme.spacing(1, 0),
  },
  label: { fontSize: '12px', color: '#666666' },
  value: { fontSize: '16px' },
  email: { fontSize: '16px', color: '#7099DB' },
  imageContainer: {
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
  image: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'cover',
    backgroundSize: '100%',
    width: '100%',
    height: 'auto',
    minHeight: '160px',
    [theme.breakpoints.down('md')]: {
      width: '160px',
      minHeight: '160px',
      height: 'auto',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: 'auto',
    }
  },
  spacing: { margin: theme.spacing(1, 0) }
}))

const ContactInformation = ({ flags, contactDetails, onFetchContactDetails }) => {

  const classes = useStyles()
  const selectedFleets = useSelectedFleetIds()

  const [delayLoading, setDelayLoading] = useState(true)
  const isLoading = flags?.fetchNotifications === 'processing'

  useEffect(() => {
    onFetchContactDetails()
    setTimeout(() => { setDelayLoading(false) }, 1500)
  }, [onFetchContactDetails])
 
  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography variant="h4" className={classes.title}>
          Contact Information
        </Typography>
        <Divider className={classes.divider} />
        
        {delayLoading || isLoading ? (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6} lg={4} className={classes.imageContainer}>
              <Skeleton className={classes.image} animation="wave" variant="rect" />              
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={8}>
              <Skeleton animation="wave" variant="rect" height={15} width={120} className={classes.spacing} />
              <Skeleton animation="wave" variant="rect" height={15} width={150} className={classes.spacing} />
              <Skeleton animation="wave" variant="rect" height={15} width={50} className={classes.spacing} />
              <Skeleton animation="wave" variant="rect" height={15} width={120} className={classes.spacing} />
              <Skeleton animation="wave" variant="rect" height={15} width={50} className={classes.spacing} />
              <Skeleton animation="wave" variant="rect" height={15} width={170} className={classes.spacing} />
            </Grid>
          </Grid>
        ) : selectedFleets.length > 1 ? (
          <Typography variant="body1">
            Contact information is only available with one fleet selected.
          </Typography>
        ) : (
          <>          
            {contactDetails && contactDetails?.name ? (
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={6} lg={4} className={classes.imageContainer}>
                  {contactDetails?.uri ? (
                    <img className={classes.image} src={contactDetails?.uri} alt="Relationship Manager" />
                  ) : (
                    <Avatar
                      variant="square"
                      className={classes.image}
                      style={{ backgroundColor: getInitialsColor(getInitials(contactDetails?.name)) }}>
                      {getInitials(contactDetails?.name)}
                    </Avatar>
                  )}
                  
                </Grid>
    
                <Grid item xs={12} sm={6} md={6} lg={8}>
                  <Typography className={classes.name} variant="h5">
                    {contactDetails?.name}
                  </Typography>
                  <Typography variant="body1" className={classes.role}>
                    Relationship Manager
                  </Typography>
                  <Typography variant="body1" className={classes.contactDetails}>
                    <span className={classes.label}>
                      Phone:
                    </span>
                    <br />
                    <strong className={classes.value}>
                      {contactDetails?.mobile || '-'}
                    </strong>
                    <br /><br />
                    <span className={classes.label}>
                      Email:
                    </span>
                    <br />
                    {contactDetails?.email ? (
                      <a className={classes.email} href={`mailto:${contactDetails?.email}`}>
                        {contactDetails?.email}
                      </a>
                    ) : '-'}
                  </Typography>
                </Grid>
              </Grid>
            ): (
              <Typography variant="body1">
                No contact details available for current fleet.
              </Typography>
            )}
          </>
        )}
      </CardContent>
    </Card>
  )
}

ContactInformation.propTypes = {
  flags: PropTypes.object,
  contactDetails: PropTypes.object,
  onFetchContactDetails: PropTypes.func.isRequired,
}

const mapStateToProps = ({ homepage }) => ({
  contactDetails: homepage?.contactDetails,
  flags: homepage?.flags,
})

const mapDispatchToProps = (dispatch) => ({
  onFetchContactDetails: () => dispatch(fetchContactDetails()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ContactInformation)