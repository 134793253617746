import { createActions } from './helpers/createActions'
import { createConstants } from './helpers/createConstants'
import { createEffects } from './helpers/createEffects'
import { createHooks } from './helpers/createHooks'
import { createReducer } from './helpers/createReducer'


export {
  BarChart,
  DashboardGraph,
  PieChart,
  StackedBarChart
} from './components'

export {
  barGraphFormatter,
  pieGraphFormatter
} from './formatters'

export {
  createGraphNodeClicker,
  createBasicGraphNodeClicker,
  createStackedBarGraphNodeClicker
} from './utils'

export const createGraph = ({ name, api, formatter }) => {

  const constants = createConstants(name)
  const actions = createActions(constants)
  const effects = createEffects(constants, actions, api)
  const reducer = createReducer(constants, formatter)
  const hooks = createHooks({ actions, constants, name })

  return {
    actions,
    constants,
    effects,
    hooks,
    reducer,
  }
}
