
export const MODULE_NAME = `registrationOfInterest`

export const RESET = `${MODULE_NAME}_reset`

export const UPLOAD_CSV = `${MODULE_NAME}_upload_csv`
export const UPLOAD_CSV_ERROR = `${MODULE_NAME}_upload_csv_error`
export const UPLOAD_CSV_FULFILLED = `${MODULE_NAME}_upload_csv_fulfilled`

export const SEND_INVITE_EMAILS = `${MODULE_NAME}_send_invite_emails`
export const SEND_INVITE_EMAILS_ERROR = `${MODULE_NAME}_send_invite_emails_error`
export const SEND_INVITE_EMAILS_FULFILLED = `${MODULE_NAME}_send_invite_emails_fulfilled`

export const REGISTER_INTEREST = `${MODULE_NAME}_register_interest`
export const REGISTER_INTEREST_ERROR = `${MODULE_NAME}_register_interest_error`
export const REGISTER_INTEREST_FULFILLED = `${MODULE_NAME}_register_interest_fulfilled`
