import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { Button as UIButton } from '@material-ui/core'
import { useRouter } from 'utils'


const useStyles = makeStyles(() => ({
  root: {},
}))

const Button = ({ children, className, to, ...props }) => {

  const classes = useStyles()
  const { history } = useRouter()

  const onClick = () =>
    history.push(to)

  return (
    <UIButton
      className={clsx(classes.root, className)}
      onClick={onClick}
      {...props}>
      {children}
    </UIButton>
  )
}

Button.propTypes = {
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
  to: PropTypes.string.isRequired,
}

export default Button
