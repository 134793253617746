import * as fromActions from './actions'
import * as fromConstants from './constants'
import * as fromHooks from './hooks'
import { createEffects } from './effects'


export {
  default as reducer
} from './reducer'

export const actions = fromActions
export const constants = fromConstants.constants
export const effects = createEffects()

export const useProfile = fromHooks.useProfile
