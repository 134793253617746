import 'core-js/stable'
import React from 'react'
import ReactDOM from 'react-dom'
// import 'font-awesome/css/font-awesome.css'
import './index.css'
import { App } from 'frame'
import * as serviceWorker from './serviceWorker'

// fonts
import './assets/fonts/ProximaNova-Bold.otf'
import './assets/fonts/ProximaNova-SemiBold.otf'
import './assets/fonts/ProximaNova-Medium.ttf'
import './assets/fonts/ProximaNova-Regular.ttf'


const render = Component => {
  ReactDOM.render(
    <Component />,
    document.getElementById('root')
  )
}

render(App)

if (module.hot) {
  module.hot.accept('./frame/containers/app', () => {
    const NextApp = require('./frame/containers/app').default
    render(NextApp)
  })
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
