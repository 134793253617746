
export const moduleName = `requestContractVariations`

export const FETCH = `${moduleName}_fetch_contract`
export const FETCH_ERROR = `${moduleName}_fetch_contract_error`
export const FETCH_FULFILLED = `${moduleName}_fetch_contract_fulfilled`

export const ON_CHANGE = `${moduleName}_on_change`

export const LOOKUP = `${moduleName}_lookup`
export const LOOKUP_ERROR = `${moduleName}_lookup_error`
export const LOOKUP_FULFILLED = `${moduleName}_lookup_fulfilled`

export const RESET_LOOKUPS = `${moduleName}_reset_lookups`
export const RESET = `${moduleName}_reset`
