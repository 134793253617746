import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import {  isUserIdAuth0 } from 'listable'
import { Label, StyledModal } from 'frame/components'
import { Avatar, TableCell, TableRow, Tooltip, Button } from '@material-ui/core'
import { createRowSelector, getInitials, getInitialsColor } from 'utils'
import { getRole, getUserStatusColor, getUserInvitedColor } from 'listable'
import { types } from '../../module'
import { hasRoles } from 'acl'
import { getUserStatus, getUserInvitationStatus } from 'features/admin-users/utils'
import { useIsWhiteListed } from 'utils'


const useStyles = makeStyles(theme => ({
  root: {
    cursor: 'pointer',
    verticalAlign: 'text-top',
  },
  label: theme.typography.bodySmall,
  spacer: {
    margin: 0,
    padding: 0,
  },
  avatars: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    maxWidth: 200,
  },
  avatar: {
    marginTop: 4,
    marginRight: 4,
    width: 22,
    height: 22,
    fontSize: 12,
  },
}))

const Row = ({ item, columns, fleets, onView, userRoles, onDelete }) => {

  const classes = useStyles()
  const isWhiteListedUser = useIsWhiteListed()
  const onSafeRowClick = createRowSelector(onView)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)

  return (
    <TableRow
      hover
      className={classes.root}
      onClick={item.invitationVerified || isUserIdAuth0(item.id) ? onSafeRowClick : undefined}>
      <TableCell className={classes.spacer} />
      {columns.nickname && (
        <TableCell className={classes.label}>
          {item.nickname || '-'}
        </TableCell>
      )}
      
      {columns.email && (
        <TableCell className={classes.label}>
          {item.email || '-'}
        </TableCell>
      )}

      {columns.role && (
        <TableCell className={classes.label}>
          {item.role ? getRole(item.role.name) : '-'}
        </TableCell>
      )}

      {columns.fleets && (
        <TableCell className={classes.label}>
          {item.fleets && item.fleets.length > 0 ? (
            <div className={classes.avatars}>
              {item.fleets.map((node, index) => {
                const initals = getInitials(node.name)
                return (
                  <Tooltip title={node.name} key={index}>
                    <Avatar
                      className={classes.avatar}
                      style={{ backgroundColor: getInitialsColor(initals) }}>
                      {initals}
                    </Avatar>
                  </Tooltip>
                )
              })}
            </div>
          ) : (
            item.role && item.hasAccount && item.role.name.toLowerCase() === 'superadmin' ?  'Unrestricted Access' : ''
          )
        }
        </TableCell>
      )}

      {columns.blocked && (
        <TableCell className={classes.label}>
          <Label
            color={getUserStatusColor(item.hasAccount, item.blocked)}
            variant="outlined">
            {getUserStatus(item)}
          </Label>
        </TableCell>
      )}
      
      {columns.invitationVerified && (
        <TableCell className={classes.label}>
          <Label
            color={getUserInvitedColor(item.hasAccount, item.invitationVerified, item.invitationExpired)}
            variant="outlined">
            {getUserInvitationStatus(item)}
          </Label>
        </TableCell>
      )}

      <TableCell className={classes.label}>
        { (!item.hasAccount || item.blocked) && hasRoles(['superadmin', 'fleetcontroller'], userRoles) && isWhiteListedUser &&
          <Button
            color="primary"
            variant="contained"
            onClick={() => setOpenDeleteModal(true)}>
            Delete
          </Button>

        }
      </TableCell>
      <TableCell className={classes.spacer} />
      
      {/* for confirm delete user invitation */}
      <StyledModal
        open={openDeleteModal}
        title={`Delete User Invitation`}
        onCancel={() => setOpenDeleteModal(false)}
        onConfirm={() => onDelete(item.id)}>
        {`Please confirm that you wish to delete this user invitation.`}
      </StyledModal>
    </TableRow>
  )
}

Row.propTypes = {
  item: PropTypes.oneOfType([
    PropTypes.object,
    types.ModelType,
  ]).isRequired,
  columns: types.ColumnsType.isRequired,
  fleets: PropTypes.array.isRequired,
  onView: PropTypes.func.isRequired,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  onDelete: PropTypes.func.isRequired,
}

export default Row
