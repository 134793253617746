import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Layout } from 'frame'
import { FBT_PATH } from './index'
import { constants as fromFbtSubmissions, ModuleRoutes as FbtSubmissionsRoutes } from 'features/fbt-submissions'
import { constants as fromFbtSummary, ModuleRoutes as FbtSummaryRoutes } from 'features/fbt-summary'

import useFbtNavigation from './navigation-config'
import { useEnabledFeatureToggleList } from 'features/settings'
import { Page404 } from 'frame/components'

const FBTRoutes = () => {
  
  const navigation = useFbtNavigation()
  const featureToggleList = useEnabledFeatureToggleList()

  const fbtFeatureToggle = featureToggleList.includes('FBT')
  return !fbtFeatureToggle ? (
    <Layout>
      <Page404 withoutNav />
    </Layout>
  ) : (
    <Layout accordionNavigation={navigation}>
      <Switch>
        <Route path={fromFbtSummary.ROUTE_ALL} component={FbtSummaryRoutes} />
        <Route path={fromFbtSubmissions.ROUTE_ALL} component={FbtSubmissionsRoutes} />

        <Redirect from={FBT_PATH} to={fromFbtSubmissions.PATH_ALL} />
      </Switch>
    </Layout>
  )
}

export default FBTRoutes
