import React from 'react'
import PropTypes from 'prop-types'
import { formTabs } from "../../config"

const { Tabs, Tab } = require("@material-ui/core")

const FormTabs = ({ setTab, value }) => {
  const handleChange = (tabValue) => {
    if (typeof tabValue !== "string") {
      return null
    }

    return setTab(tabValue)
  }

  return (
    <Tabs
      onChange={(_, tabValue) => handleChange(tabValue)}
      scrollButtons="auto"
      value={value}
      variant="scrollable"
    >
      {formTabs?.map((tab) => (
        <Tab value={tab?.value} label={tab?.label} />
      ))}
    </Tabs>
  )
}

FormTabs.propTypes = {
  setTab: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
}


export default FormTabs