import React, { useEffect, useState, useRef } from 'react'
import { ListItem } from './list-item-skeleton'

const DEFAULT_LOAD_ITEMS = 10

/*
  used in myNotifications & statusUpdates Sections
  load data in sets of 10 at a time for an infinite scroll 
*/
const InfiniteScroll = ({ data, renderListData }) => {

  const loader = useRef(null) // loader reference

  const [listData, setListData] = useState(data.slice(0, DEFAULT_LOAD_ITEMS)) // load list with first 10 entries only
  const [hydrateList, setHydrateList] = useState(data.slice(DEFAULT_LOAD_ITEMS)) // remaining list data yet to load

  // tracking on which page we currently are
  const [page, setPage] = useState(data.length % DEFAULT_LOAD_ITEMS === 0
    ? data.length / DEFAULT_LOAD_ITEMS 
    : (data.length / DEFAULT_LOAD_ITEMS) + 1)    

  // here we handle what happens when user scrolls to Load More div
  // in this case we just update page variable
  const handleObserver = (entities) => {
    const target = entities[0]
    if(target?.isIntersecting) { // add delay to show loading animation 
      setTimeout(() => setPage(_page => _page + 1), 1000)      
    }
  }

  useEffect(() => { // initialize IntersectionObserver and attaching to Load More div
    const observer = new IntersectionObserver(handleObserver)
    if (loader.current) {
      observer.observe(loader.current)
    }
  }, [])

  useEffect(() => { // here we simulate adding new posts to List

    const itemsToLoad = hydrateList.slice(0, DEFAULT_LOAD_ITEMS) // take first 10 from remaining data list
    setHydrateList(hydrateList.slice(DEFAULT_LOAD_ITEMS)) // remove first 10 from remaining data list

    setListData(listData.concat(itemsToLoad)) // update list data
  }, [page]) // eslint-disable-line

  return (
    <>
      {renderListData(listData)}

      <div ref={loader}>
        {listData.length !== data.length && (
          <ListItem />
        )}
      </div>
    </>
  )
}

export default InfiniteScroll
