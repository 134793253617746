import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { StyledModal, AnimatedErrorCross, AnimatedSuccessTick } from 'frame/components'
import { resetFlags } from '../actions'
import { useNavigate } from 'utils'


const ConfirmationPopups = ({ flags, errorMessage, fbtWebFormSuccessRedirect, onResetFlags }) => {
  
  const navigate = useNavigate()

  const [showErrorModal, setShowErrorModal] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [message, setMessage] = useState()

  useEffect(() => {
    onResetFlags()
  }, []) // eslint-disable-line

  useEffect(() => {
    if(flags?.addFbtSubmission === 'processed') {
      setShowSuccessModal(true)
      setMessage(`FBT Submission has been submitted successfully`)
    }
    if(flags?.update === 'processed') {
      setShowSuccessModal(true)
      setMessage(`FBT Submission has been updated successfully`)
    }
    if(flags?.fbtWebFormSubmit === 'processed' && fbtWebFormSuccessRedirect) {
      navigate(fbtWebFormSuccessRedirect)()
    }

    if(flags?.addFbtSubmission === 'error' || flags?.update === 'error' || flags?.fbtWebFormSubmit === 'error') {
      setShowErrorModal(true)
      setMessage(`Error occured. Error: ${errorMessage}`)
    }
    // for fbt web form only
    if(flags?.verifyEmail === 'error') {
      setShowErrorModal(true)
      setMessage(errorMessage)
    }
  }, [flags?.addFbtSubmission, flags?.update, flags?.fbtWebFormSubmit, flags?.verifyEmail]) // eslint-disable-line

  return (
    <>
      {/* success/error confirmation */}
      <StyledModal
        open={showSuccessModal || showErrorModal}
        onCancel={() => {
          setShowErrorModal(false)
          setShowSuccessModal(false)
          onResetFlags()
        }}
        options={['noButtons']}
        animatedIcon={showSuccessModal ? <AnimatedSuccessTick size={50} /> : <AnimatedErrorCross size={50} />}
      >
        {message}
      </StyledModal>
    </>
  )
}

ConfirmationPopups.propTypes = {
  flags: PropTypes.object,
  errorMessage: PropTypes.string,
}

const mapStateToProps = ({ fbtSubmissions }) => ({
  flags: fbtSubmissions.flags,
  errorMessage: fbtSubmissions.errorMessage,
})

const mapDispatchToProps = (dispatch) => ({  
  onResetFlags: (payload) => {
    dispatch(resetFlags(payload))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationPopups)

