

export const toTitleCase = (value) => {

  let result = value.toLowerCase().split(' ')

  for (let i = 0; i < result.length; i ++) {
    result[i] = result[i].charAt(0).toUpperCase() + result[i].substring(1)
  }

  return result.join(' ')
}

export const capitalize = (value = '') =>
  String(value).replace(/^\w/, c => c.toUpperCase()).replace(' tfs', ' TFS')
