import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid, Typography } from '@material-ui/core'


const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(3, 0, 5, 0),
  },
  text: { color: '#FFF', width: 400, },
  center: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  carImage: {
    marginTop: theme.spacing(-7),
    height: 350,
    width: 'auto',
    [theme.breakpoints.down('md')]: { height: 225 },
    [theme.breakpoints.down('sm')]: { height: 200, marginTop: 0 },
    [theme.breakpoints.down('xs')]: { height: 180, marginTop: 0 },
  },
  button: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    minWidth: 180,
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
    }
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    }
  },
  promoImage: {
    width: 400,
    height: 'auto',
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(3),
    },
    [theme.breakpoints.down('sm')]: { width: 350 },
    [theme.breakpoints.down('xs')]: { width: 250 },
  }
}))

const PromotionView = () => {

  const classes = useStyles()
  
  return (
    <Grid container className={classes.root}>
      
      <Grid item xs={12} sm={12} md={12} lg={6}>

        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.center}>
            <Typography variant="h4" className={classes.text}>
              Thank you
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.center}>
            <Typography variant="body1" className={classes.text}>
              You have successfully registered your interest in TFM’s FBT Reporting. One of our team members will be in contact to discuss this with you.<br /><br />
              If you have any questions feel free to contact us at fleet.operations@tfs.co.nz<br /><br />
              Toyota Fleet Management
            </Typography>
          </Grid>
        </Grid>

      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={6} className={classes.center}>
        <img className={classes.promoImage} src="/static/images/marketing/dashboard-app-promo.png" alt="car"/>
      </Grid>

    </Grid>
  )
}

export default PromotionView