import * as moduleTypes from './types'

// Reset flags
export const reset = () => ({
  type: moduleTypes.RESET,
})

// Fetch Service Booking
export const fetchServiceBooking = (payload, options) => ({
  type: moduleTypes.FETCH_SERVICE_BOOKING,
  payload,
  options
})

export const fetchServiceBookingError = (payload) => ({
  type: moduleTypes.FETCH_SERVICE_BOOKING_ERROR,
  payload,
})

export const fetchServiceBookingFulfilled = (payload) => ({
  type: moduleTypes.FETCH_SERVICE_BOOKING_FULFILLED,
  payload,
})

// Add Service Booking
export const addServiceBooking = (payload) => ({
  type: moduleTypes.ADD_SERVICE_BOOKING,
  payload,
})

export const addServiceBookingError = (payload) => ({
  type: moduleTypes.ADD_SERVICE_BOOKING_ERROR,
  payload,
})

export const addServiceBookingFulfilled = (payload) => ({
  type: moduleTypes.ADD_SERVICE_BOOKING_FULFILLED,
  payload,
})

// Cancel Service Booking
export const cancelServiceBooking = (payload) => ({
  type: moduleTypes.CANCEL_SERVICE_BOOKING,
  payload,
})

export const cancelServiceBookingError = (payload) => ({
  type: moduleTypes.CANCEL_SERVICE_BOOKING_ERROR,
  payload,
})

export const cancelServiceBookingFulfilled = (payload) => ({
  type: moduleTypes.CANCEL_SERVICE_BOOKING_FULFILLED,
  payload,
})

// Submit service record 
export const submitServiceRecord = (payload) => ({
  type: moduleTypes.SUBMIT_SERVICE_RECORD,
  payload,
})

export const submitServiceRecordError = (payload) => ({
  type: moduleTypes.SUBMIT_SERVICE_RECORD_ERROR,
  payload,
})

export const submitServiceRecordFulfilled = (payload) => ({
  type: moduleTypes.SUBMIT_SERVICE_RECORD_FULFILLED,
  payload,
})

// Complete Service Booking
export const completeServiceBooking = (payload) => ({
  type: moduleTypes.COMPLETE_SERVICE_BOOKING,
  payload,
})

export const completeServiceBookingError = (payload) => ({
  type: moduleTypes.COMPLETE_SERVICE_BOOKING_ERROR,
  payload,
})

export const completeServiceBookingFulfilled = (payload) => ({
  type: moduleTypes.COMPLETE_SERVICE_BOOKING_FULFILLED,
  payload,
})

