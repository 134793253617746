import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'


const useStyles = makeStyles(theme => ({
  default: {
    padding: theme.spacing(2),
    ...theme.typography.body1,
    color: theme.palette.error.dark,
    background: theme.palette.error.light,
    borderRadius: '4px',
  },
  success: {
    padding: theme.spacing(2),
    ...theme.typography.body1,
    color: theme.palette.success.dark,
    background: theme.palette.success.light,
    borderRadius: '4px',
  },
}))

const Alert = ({ children, className, isSuccess, ...props }) => {

  const classes = useStyles()
  const style = isSuccess ? classes.success : classes.default

  return (
    <div
      className={clsx(style, className)}
      {...props}>
      {children}
    </div>
  )
}

Alert.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  severity: PropTypes.string,
  isSuccess: PropTypes.bool,
}

export default Alert
