import * as types from './constants'


export const requestFeatureToggleList = (payload, options) => ({
  type: types.REQUEST_FEATURE_TOGGLE_LIST,
  payload,
  options,
})

export const requestFeatureToggleListError = (payload, options) => ({
  type: types.REQUEST_FEATURE_TOGGLE_LIST_ERROR,
  payload,
  options,
})

export const requestFeatureToggleListFulfilled = (payload, options) => ({
  type: types.REQUEST_FEATURE_TOGGLE_LIST_FULFILLED,
  payload,
  options,
})

export const enableFeature = (payload, options) => ({
  type: types.ENABLE_FEATURE,
  payload,
  options,
})

export const enableFeatureError = (payload, options) => ({
  type: types.ENABLE_FEATURE_ERROR,
  payload,
  options,
})

export const enableFeatureFulfilled = (payload, options) => ({
  type: types.ENABLE_FEATURE_FULFILLED,
  payload,
  options,
})


export const disableFeature = (payload, options) => ({
  type: types.DISABLE_FEATURE,
  payload,
  options,
})

export const disableFeatureError = (payload, options) => ({
  type: types.DISABLE_FEATURE_ERROR,
  payload,
  options,
})

export const disableFeatureFulfilled = (payload, options) => ({
  type: types.DISABLE_FEATURE_FULFILLED,
  payload,
  options,
})