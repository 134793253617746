import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { Button, Drawer } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import FilterAttributes from './filter-attributes'
import { actions, types } from '../../module'


const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  drawer: {
    width: 420,
    maxWidth: '100%',
  },
  header: {
    padding: theme.spacing(2, 1),
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
  },
  content: {
    padding: theme.spacing(0, 3),
    flexGrow: 1,
  },
  actions: {
    padding: theme.spacing(3),
    '& > * + *': {
      marginTop: theme.spacing(2),
    }
  },
}))

const Filters = ({
  className,
  model,
  filters,
  filtered,
  visible,
  onClear,
  onFilter,
  onHide,
}) => {

  const classes = useStyles()
  
  const isEmptyList = list => list && list.length === 0

  const noFilters = isEmptyList(Object.entries(filters)
    .filter(([key, value]) => !isEmptyList(value)))

  return (
    <Drawer
      anchor="right"
      classes={{ paper: classes.drawer }}
      onClose={onHide}
      open={visible}
      variant="temporary">
      <div className={clsx(classes.root, className)}>
        <div className={classes.header}>
          <Button onClick={onHide} size="small">
            <CloseIcon className={classes.buttonIcon} />
            Close
          </Button>
        </div>
        <div className={classes.content}>
          {noFilters
            ? 'No Filters Avaliable'
            : <FilterAttributes
                filters={filters}
                filtered={filtered}
                onFilter={(event) => {
                  onFilter(event)
                }} />
          }
        </div>
        <div className={classes.actions}>
          <Button
            fullWidth
            onClick={onClear}
            variant="contained">
            <DeleteIcon className={classes.buttonIcon} />
            Clear
          </Button>
        </div>
      </div>
    </Drawer>
  )
}

Filters.propTypes = {
  className: PropTypes.string,
  model: types.ModuleType.isRequired,
  visible: PropTypes.bool,
  filters: types.FiltersType.isRequired,
  filtered: types.FilteredType.isRequired,
  onClear: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
}

const mapStateToProps = ({ reportIncident }) => ({
  model: reportIncident,
  visible: reportIncident.showFilters,
  filters: reportIncident.filters,
  filtered: reportIncident.filtered,
})

const mapDispatchToProps = {
  onClear: actions.clearFilters,
  onFilter: actions.updateFilters,
  onHide: actions.hideFilters,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Filters)
