

const createHeaders = (model, order, name) =>
  order
    .map(key => {
      const data = model.filter(node => node.key === key)[0]

      if ( ! data) {
        if (process.env.NODE_ENV === 'development') {
          console.error(`Error - cannot find matching model data with key '${key}' in '${name}' config.`) // eslint-disable-line
        }
        return null
      }

      return {
        key: data.key,
        label: data.label,
        sortKey: data.key,
        width: data.column && data.column.width
          ? data.column.width
          : 110,
      }
    })
    .filter(node => node)

export const configureOptions = (config, name) => ({
  ...config,
  headers: createHeaders(config.model, config.headers || [], name),
})
