import React, { useRef, useEffect } from 'react'

const ScrollToTop = () => {

  const topRef = useRef()
  const scrollTo = (ref) => ref?.current && ref?.current.scrollIntoView({ behavior: 'smooth', block: 'start' })

  useEffect(() => {
    topRef && scrollTo(topRef)
  }, []) // eslint-disable-line

  return (
    <div ref={topRef} />
  )
}

export default ScrollToTop
