import { HOMEPAGE_PATH } from './actions/types'
import { createEffects } from './effects'


export const constants = {
  PATH_ALL: HOMEPAGE_PATH,
}

export {
  HOMEPAGE_PATH
} from './actions/types'

export {
  SummaryDashboard as HomePageRoutes
} from './components'

export { reducer } from './reducer'
export const effects = createEffects()
