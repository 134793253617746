import * as types from './constants'

const reducer = (state, action) => {
  switch (action.type) {
    case types.REQUEST_MYDRIVEAPP_TOGGLE:
      return { ...state }
    case types.REQUEST_MYDRIVEAPP_TOGGLE_FULFILLED: {
      const driverAssignmentFeatureRow = Object.entries(action.payload)
        .map(([key, value]) => value)
        .find(item => item.feature === types.FEATURES.DRIVER_ASSIGNMENT)
      
      const updatedDataToInject = driverAssignmentFeatureRow ? {
        [driverAssignmentFeatureRow.id]: driverAssignmentFeatureRow
      } : null

      return {
        ...state,
        data: { ...state.data, ...updatedDataToInject }
      }
    }
    case types.REQUEST_MYDRIVEAPP_TOGGLE_ERROR: {
      return state
    }
    case types.REQUEST_MYDRIVEAPP_VIEWALL:
      return { ...state, viewAll: action.payload.isViewedAll }
    case types.REQUEST_MYDRIVEAPP_TOGGLE_ALL:
      return { ...state, toggleAll: action.payload.enable }
    case types.TOGGLE_CONFIRMATION_MODAL:
      return { ...state, showtoggleAllConfirmationModal: action.payload }
    default:
      return state
  }
}

export default reducer
