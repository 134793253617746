
export const moduleName = `requestVehicleRelocations`

export const RESET_FLAGS = `${moduleName}_reset_flags`

export const CREATE = `${moduleName}_create`
export const CREATE_ERROR = `${moduleName}_create_error`
export const CREATE_FULFILLED = `${moduleName}_create_fulfilled`

export const FETCH = `${moduleName}_fetch`
export const FETCH_ERROR = `${moduleName}_fetch_error`
export const FETCH_FULFILLED = `${moduleName}_fetch_fulfilled`

export const FETCH_CORRESPONDING_CONTRACT = `${moduleName}_fetch_corresponding_contract`
export const FETCH_CORRESPONDING_CONTRACT_ERROR = `${moduleName}_fetch_corresponding_contract_error`
export const FETCH_CORRESPONDING_CONTRACT_FULFILLED = `${moduleName}_fetch_corresponding_contract_fulfilled`

export const FETCH_CONTRACT = `${moduleName}_fetch_contract`
export const FETCH_CONTRACT_ERROR = `${moduleName}_fetch_contract_error`
export const FETCH_CONTRACT_FULFILLED = `${moduleName}_fetch_contract_fulfilled`

export const UPDATE = `${moduleName}_update`
export const UPDATE_ERROR = `${moduleName}_update_error`
export const UPDATE_FULFILLED = `${moduleName}_update_fulfilled`

export const DELETE = `${moduleName}_delete`
export const DELETE_ERROR = `${moduleName}_delete_error`
export const DELETE_FULFILLED = `${moduleName}_delete_fulfilled`

export const LOOKUP = `${moduleName}_lookup`
export const LOOKUP_ERROR = `${moduleName}_lookup_error`
export const LOOKUP_FULFILLED = `${moduleName}_lookup_fulfilled`