import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Layout } from 'frame'
import { Account, Profile, Security } from './components'
import { constants } from './module'


export const ModuleRoutes = () => (
  <Layout>
    <Switch>
      <Route path={constants.ROUTE_ALL} component={Account} />
    </Switch>
  </Layout>
)

export const ChildRoutes = () => (
  <Switch>
    <Route path={constants.ROUTE_PROFILE} component={Profile} />
    <Route path={constants.ROUTE_SECURITY} component={Security} />
    <Redirect to={constants.ROUTE_PROFILE} />
  </Switch>
)
