import PropTypes from 'prop-types'


export const createPropTypes = (config) => {

  const IDType = PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])

  const IDListType = PropTypes.arrayOf(IDType)

  const ColumnsType = createColumnTypes(config)
  const ColumnsSortType = PropTypes.shape({
    column: PropTypes.string,
    direction: PropTypes.string,
  })

  const FiltersType = createFilterTypes(config)
  const FilteredType = createFilteredTypes(config)

  const ModelType = createModelTypes(config)
  const ModelListType = PropTypes.arrayOf(ModelType)

  const ModuleType = PropTypes.shape({
    ids: IDListType.isRequired,
    data: PropTypes.object.isRequired,
    columns: ColumnsType.isRequired,
    columnsSort: ColumnsSortType.isRequired,
    filters: FiltersType.isRequired,
    filtered: FilteredType.isRequired,
    filtering: PropTypes.bool,
    pagination: PropTypes.shape({
      offset: PropTypes.number.isRequired,
      rows: PropTypes.number.isRequired,
    }),
    searchKey: PropTypes.string,
    selected: IDListType.isRequired,
    showFilters: PropTypes.boolean,
  })

  return {
    IDType,
    IDListType,
    ColumnsType,
    ColumnsSortType,
    FiltersType,
    FilteredType,
    ModelType,
    ModelListType,
    ModuleType,
  }
}

const createModelTypes = (options) => {
  const result = {}
  options.model.forEach(node => {
    if (node.prop && node.prop.type) {
      result[node.key] = node.prop.type
    }
  })
  return PropTypes.shape(result)
}

const createColumnTypes = (options) => {
  const result = {}
  options.model.forEach(node => {
    if (node.column) {
      result[node.key] = PropTypes.bool.isRequired
    }
  })
  return PropTypes.shape(result)
}

const createFilterTypes = (options) => {
  const result = {}
  options.filters.forEach(node => {
    if (node.prop && node.prop.type) {
      result[node.key] = PropTypes.arrayOf(PropTypes.string)
    }
  })
  return PropTypes.shape(result)
}

const createFilteredTypes = (options) => {
  const result = {}
  options.filters.forEach(node => {
    if (node.prop && node.prop.type) {
      result[node.key] = node.prop.type
    }
  })
  return PropTypes.shape(result)
}
