import { colors } from '@material-ui/core'
import palette from '../palette'


export default {
  contained: {
    padding: '8px 32px',
    fontSize: '13px',
    fontWeight: 600,
    lineHeight: '19px',
    textAlign: 'center',
    boxShadow:
      '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)',
    backgroundColor: '#FFFFFF'
  },
  containedPrimary: {
    backgroundColor: palette.brand,
    '&:hover': {
      backgroundColor: palette.brandDarker,
    },
  },
  containedSecondary: {
    color: palette.dark,
    backgroundColor: colors.grey[200],
    '&:hover': {
      backgroundColor: colors.grey[400],
    },
  },
}
