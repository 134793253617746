import { useSelector } from 'react-redux'


export const useFleetId = () => {
  const fleetIds = useSelector(state => state?.settings?.fleet?.selected || [])
  return fleetIds.join(',')
}

export const useSelectedFleetIds = () => {
  const fleetIds = useSelector(state => state?.settings?.fleet?.selected || [])
  return fleetIds
}

export const useSelectedFleets = () => {
  const fleet = useSelector(state => state?.settings?.fleet)

  const result = []
  fleet.selected.forEach(id => {
    const node = fleet.data[id]
    if (node) {
      result.push({
        label: node.name,
        value: node.id,
        isPremium: node.isPremium
      })
    }
  })

  return result
}

// only return Premium (FBT Customer) fleets from selected user's fleets
export const useSelectedPremiumFleets = () => {
  const fleet = useSelector(state => state?.settings?.fleet)

  const result = []
  fleet.selected.forEach(id => {
    const node = fleet.data[id]
    if (node && node.isPremium) {
      result.push({
        label: node.name,
        value: node.id,
        isPremium: node.isPremium
      })
    }
  })

  return result
}

export const useSettings = () => {

  const settings = useSelector(state =>  state.settings)

  return {
    ...settings,
    loading: ! settings.initialized,
  }
}

// returns all fleets for fleet filter downdown options 
export const useSettingsFleetValues = () => {
  const model = useSelector(state => state?.settings?.fleet || {})
  return model.ids
    .map(id => ({
      label: model.data[id].name,
      value: model.data[id].id,
      isPremium: model.data[id].isPremium
    }))
    .sort((a, b) => (a.label > b.label) ? 1 : -1)
}


export const useEnabledFeatureToggleList = () => useSelector(state => state?.featureToggles?.enabledFeatures || [])

