import { FBT_PATH } from './index'
import { useSelector } from 'react-redux'
import { useSelectedPremiumFleets } from 'features/settings'

const fromFbtSubmissions = {
  PATH_ALL: `${FBT_PATH}/submissions`,
}
const fromFbtSummary = {
  PATH_ALL: `${FBT_PATH}/summary`,
}

export const useLatestFbtSubmission = () => {
  const fbtSubmissionsYearsAndMonths = useSelector(({ fbtSubmissions }) => fbtSubmissions.filters.yearsMonths || [])
  const month = fbtSubmissionsYearsAndMonths.length > 0 ? fbtSubmissionsYearsAndMonths[0].split(' - ')[1] : ''
  const year = fbtSubmissionsYearsAndMonths.length > 0 ? fbtSubmissionsYearsAndMonths[0].split(' - ')[0] : ''

  const link = fbtSubmissionsYearsAndMonths && year && month
    ? `${fromFbtSubmissions.PATH_ALL}?year=${year}&month=${month}`
    : fromFbtSubmissions.PATH_ALL
  // console.log('LatestFbtSubmission link ', link)
  return { month, year, link }
}

export const useLatestFbtSummary = () => {
  const fbtSummaryYearsAndQuarters = useSelector(({ fbtSummary }) => fbtSummary.filters.yearsQuarters || [])
  const quarter = fbtSummaryYearsAndQuarters.length > 0 ? fbtSummaryYearsAndQuarters[0].split(' - ')[1] : ''
  const year = fbtSummaryYearsAndQuarters.length > 0 ? fbtSummaryYearsAndQuarters[0].split(' - ')[0] : ''

  const link = fbtSummaryYearsAndQuarters && quarter && year
    ? `${fromFbtSummary.PATH_ALL}?year=${year}&quarter=${quarter}`
    : fromFbtSummary.PATH_ALL
  // console.log('LatestFbtSummary link ', link)
  return { quarter, year, link }
}

const useFbtNavigation = () => {

  const selectedFbtFleets = useSelectedPremiumFleets()
  const fbtSubmissionsYearsAndMonths = useSelector(({ fbtSubmissions }) => fbtSubmissions.filters.yearsMonths || [])
  const fbtSummaryYearsAndQuarters = useSelector(({ fbtSummary }) => fbtSummary.filters.yearsQuarters || [])

  const noFbtFleetSelected = selectedFbtFleets.length === 0
  if(noFbtFleetSelected) {
    return [
      { title: 'FBT Submissions', nestedItems: [], disabled: true },
      { title: 'FBT Summary', nestedItems: [], disabled: true },
    ]
  }

  const fbtSubmissionYears = fbtSubmissionsYearsAndMonths.map(yearsMonths => yearsMonths.split(" - ")[0])

  const formatedSubmissionsNavItems = Array.from(new Set(fbtSubmissionYears)) // ensure unique fbt years
    .map(year => ({
      title: year, // eg 2021
      nestedItems: fbtSubmissionsYearsAndMonths
                    .filter(f=> f.includes(year))  // filter based on current year iteration value eg. return all 2021 months
                    .map(yearsMonths => ({  // eg. 2021 - January
                      title: yearsMonths.split(" - ")[1], // month name
                      link: `${fromFbtSubmissions.PATH_ALL}?year=${year}&month=${yearsMonths.split(" - ")[1]}` // clickable link of year / month
                    }))
  }))

  const fbtSummaryYears = fbtSummaryYearsAndQuarters.map(yearsQuarters => yearsQuarters.split(" - ")[0])

  const formatedSummaryNavItems = Array.from(new Set(fbtSummaryYears)) // ensure unique fbt years
    .map(year => ({
      title: year, // eg 2021
      nestedItems: fbtSummaryYearsAndQuarters
                  .filter(f=> f.includes(year))  // filter based on current year iteration value eg. return all 2021 months
                  .map(yearsQuarters => ({  // eg. 2021 - January
                              title: yearsQuarters.split(" - ")[1], // quarter
                              link: `${fromFbtSummary.PATH_ALL}?year=${year}&quarter=${yearsQuarters.split(" - ")[1]}` // clickable link of year / quarter
                            }))
  }))

  return [
    {
      title: 'FBT Submissions',
      nestedItems: formatedSubmissionsNavItems
    },
    {
      title: 'FBT Summary', 
      nestedItems: formatedSummaryNavItems
    },
  ]

}

export default useFbtNavigation


