import PropTypes from 'prop-types'


export const model = [{
  key: 'accountName',
  label: 'Fleet',
  column: {
    visible: true,
    width: 80,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'agreementNo',
  label: 'Agreement No.',
  column: {
    visible: false,
    width: 115,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
},  {
  key: 'agreementType',
  label: 'Agreement Type',
  column: {
    visible: false,
    width: 115,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'registration',
  label: 'Registration',
  column: {
    visible: true,
    width: 110,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'driverNote',
  label: 'Driver Note',
  column: {
    visible: false,
    width: 190,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'driver',
  label: 'Driver',
  column: {
    visible: true,
    width: 190,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'year',
  label: `Year`,
  column: {
    visible: false,
    width: 80,
  },
  prop: {
    type: PropTypes.number,
    value: '',
  }
}, {
  key: 'make',
  label: 'Make',
  column: {
    visible: true,
    width: 90,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'model',
  label: `Model`,
  column: {
    visible: true,
    width: 90,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'colour',
  label: 'Colour',
  column: {
    visible: false,
    width: 90,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'noOfTyres',
  label: 'Contract Tyres',
  column: {
    visible: true,
    width: 95,
  },
  prop: {
    type: PropTypes.number,
    value: '',
  }
}, {
  key: 'tyresRemaining',
  label: 'Tyres Remaining',
  column: {
    visible: false,
    width: 95,
  },
  prop: {
    type: PropTypes.number,
    value: '',
  }
}, {
  key: 'costCentre',
  label: 'Cost Centre',
  column: {
    visible: true,
    width: 120,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'startDate',
  label: 'Start Date',
  column: {
    visible: false,
    width: 90,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'maturityDate',
  label: 'Maturity Date',
  column: {
    visible: true,
    width: 110,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'originalMaturityDate',
  label: 'Original Maturity Date',
  column: {
    visible: true,
    width: 110,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'term',
  label: 'Term',
  column: {
    visible: true,
    width: 70,
  },
  prop: {
    type: PropTypes.number,
    value: '',
  }
}, {
  key: 'variationsCount',
  label: 'Variations',
  column: {
    visible: true,
    width: 90,
  },
  prop: {
    type: PropTypes.number,
  }
}, {
  key: 'contractKm',
  label: 'Contract KM',
  column: {
    visible: false,
    width: 100,
  },
  prop: {
    type: PropTypes.number,
    value: '',
  }
}, {
  key: 'kmUseRate',
  label: 'Avg KM (Monthly)',
  column: {
    visible: true,
    width: 130,
  },
  prop: {
    type: PropTypes.number,
    value: '',
  }
}, {
  key: 'contractEndKm',
  label: 'Contract End KM',
  column: {
    visible: false,
    width: 130,
  },
  prop: {
    type: PropTypes.number,
    value: '',
  }
}, {
  key: 'lastOdoReading',
  label: 'Last Odo Reading',
  column: {
    visible: true,
    width: 130,
  },
  prop: {
    type: PropTypes.number,
    value: '',
  }
}, {
  key: 'lastKmReading',
  label: 'Last KM Reading',
  column: {
    visible: true,
    width: 130,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'location',
  label: 'Location',
  column: {
    visible: false,
    width: 220,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'vehicleNotes',
  label: 'Vehicle Notes',
  column: {
    visible: false,
    width: 220,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'controller',
  label: 'Controller',
  column: {
    visible: false,
    width: 180,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'budgetedKmYear',
  label: 'Budgeted KM Year',
  column: {
    visible: false,
    width: 130,
  },
  prop: {
    type: PropTypes.number,
    value: '',
  }
}, {
  key: 'wofExpiry',
  label: 'WoF Expiry',
  column: {
    visible: true,
    width: 100,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'wofStatus',
  label: 'WoF Status',
  column: {
    visible: false,
    width: 200,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'registrationExpiry',
  label: 'Registration Expiry',
  column: {
    visible: true,
    width: 140,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'registrationStatus',
  label: 'Registration Status',
  column: {
    visible: false,
    width: 200,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'regularInstalment',
  label: `Regular Instalment`,
  column: {
    visible: false,
    width: 80,
  },
  prop: {
    type: PropTypes.number,
    value: '',
  }
}, {
  key: 'documentsCount',
  label: 'Documents',
  column: {
    visible: false,
    width: 100,
  },
  prop: {
    type: PropTypes.number,
    value: null,
  },
}
]

const config = {
  title: 'TFS Fleet',
  breadcrumbs: null,
  backgroundImage: 'url("/static/images/backgrounds/bg3.png")',
  api: {
    filter: '/filteroptions/contract',
    resource: `/contract/contract`,
    download: `/download/tfsfleet`,
  },
  model,
  headers: [
    'accountName', 'agreementNo', 'agreementType', 'registration', 'assetBranch', 'assetFunction', 'driverNote', 'driver',
    'year', 'make', 'model', 'colour', 'noOfTyres', 'tyresRemaining',
    'costCentre', 'startDate', 'maturityDate', 'originalMaturityDate', 'term',
    'variationsCount', 'contractKm', 'kmUseRate', 'contractEndKm', 'location','vehicleNotes',
    'controller', 'lastOdoReading', 'lastKmReading', 'budgetedKmYear',
    'wofExpiry', 'wofStatus', 'registrationExpiry', 'registrationStatus', 'regularInstalment', 'documentsCount'
  ],
  filters: [{
    key: 'costCentre',
    label: 'Cost Centre',
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }, {
    key: 'make',
    label: 'Make',
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }, {
    key: 'model',
    label: 'Model',
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }, {
    key: 'maturityExpiry',
    label: 'Maturity Expiry Month',
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }, {
    key: 'wofExpiry',
    label: 'WoF Expiry Month',
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }, {
    key: 'registrationExpiry',
    label: 'Registration Expiry Month',
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }],
}


export default config
