import { combineEpics, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { mergeMap } from 'rxjs/operators'
import { makeActionRequestEffect } from 'effects'
import join from 'url-join'
import { getAppConfig } from 'app-config'
import * as actions from './actions'
import * as types from './actions/types'
import { actions as fromSettings } from 'features/settings'


export const createEffects = () => {

  const appConfig = getAppConfig()

  const checkHaveAppUsersEffect = makeActionRequestEffect({
    type: types.CHECK_HAVE_APP_USERS,
    url: join(appConfig.app.api, '/homepage/haveappusers'),
    onSuccess: actions.checkHaveAppUsersFulfilled,
    onError: actions.checkHaveAppUsersError,
  })

  // fetch my fleet summary - numbers
  const fetchMyFleetSummaryServiceOverdueEffect = makeActionRequestEffect({
    type: types.FETCH_SUMMARY_SERVICE_OVERDUE,
    url: join(appConfig.app.api, '/homepage/myfleetsummaryserviceoverdue'),
    onSuccess: actions.fetchMyFleetSummaryServiceOverdueFulfilled,
    onError: actions.fetchMyFleetSummaryServiceOverdueError,
  })

  
  const fetchMyFleetSummaryWofOverdueEffect = makeActionRequestEffect({
    type: types.FETCH_SUMMARY_WOF_OVERDUE,
    url: join(appConfig.app.api, '/homepage/myfleetsummarywofoverdue'),
    onSuccess: actions.fetchMyFleetSummaryWofOverdueFulfilled,
    onError: actions.fetchMyFleetSummaryWofOverdueError,
  })
  
  const fetchMyFleetSummaryKmTrackingEffect = makeActionRequestEffect({
    type: types.FETCH_SUMMARY_KM_TRACKING,
    url: join(appConfig.app.api, '/homepage/myfleetsummarykmtracking'),
    onSuccess: actions.fetchMyFleetSummaryKmTrackingFulfilled,
    onError: actions.fetchMyFleetSummaryKmTrackingError,
  })
  
  const fetchMyFleetSummaryRegoOverdueEffect = makeActionRequestEffect({
    type: types.FETCH_SUMMARY_REGO_OVERDUE,
    url: join(appConfig.app.api, '/homepage/myfleetsummaryregooverdue'),
    onSuccess: actions.fetchMyFleetSummaryRegoOverdueFulfilled,
    onError: actions.fetchMyFleetSummaryRegoOverdueError,
  })
  
  const fetchMyFleetSummaryFbtOutstandingEffect = makeActionRequestEffect({
    type: types.FETCH_SUMMARY_FBT_OUTSTANDING,
    url: join(appConfig.app.api, '/homepage/myfleetsummaryfbtoutstanding'),
    onSuccess: actions.fetchMyFleetSummaryFbtOutstandingFulfilled,
    onError: actions.fetchMyFleetSummaryFbtOutstandingError,
  })

  const fetchNotificationsEffect = makeActionRequestEffect({
    type: types.FETCH_NOTIFICATIONS,
    url: join(appConfig.app.api, '/homepage/mynotifications'),
    onSuccess: actions.fetchNotificationsFulfilled,
    onError: actions.fetchNotificationsError,
  })

  const fetchStatusUpdatesEffect = makeActionRequestEffect({
    type: types.FETCH_STATUS_UPDATES,
    url: join(appConfig.app.api, '/homepage/statusupdates'),
    onSuccess: actions.fetchStatusUpdatesFulfilled,
    onError: actions.fetchStatusUpdatesError,
  })
  
  const fetchIndustryNewsEffect = makeActionRequestEffect({
    type: types.FETCH_INDUSTRY_NEWS,
    url: join(appConfig.app.api, '/homepage/industrynews'),
    onSuccess: actions.fetchIndustryNewsFulfilled,
    onError: actions.fetchIndustryNewsError,
  })
  
  const fetchDownloadGuidesEffect = makeActionRequestEffect({
    type: types.FETCH_DOWNLOAD_GUIDES,
    url: join(appConfig.app.api, '/homepage/downloads'),
    onSuccess: actions.fetchDownloadGuidesFulfilled,
    onError: actions.fetchDownloadGuidesError,
  })
  
  const fetchContactDetailsEffect = makeActionRequestEffect({
    type: types.FETCH_CONTACT_DETAILS,
    url: join(appConfig.app.api, '/homepage/relationshipmanager'),
    onSuccess: actions.fetchContactDetailsFulfilled,
    onError: actions.fetchContactDetailsError,
  })
  
  const fetchFooterTickerEffect = makeActionRequestEffect({
    type: types.FETCH_FOOTER_TICKER,
    url: join(appConfig.app.api, '/homepage/ticker'),
    onSuccess: actions.fetchFooterTickerFulfilled,
    onError: actions.fetchFooterTickerError,
  })  

  const refetchEffect = (action$, state$) =>
    action$.pipe(
      ofType(fromSettings.SELECTED_FLEETS_CHANGED),
      mergeMap(() =>
        of(
          actions.checkHaveAppUsers(),
          actions.fetchMyFleetSummaryServiceOverdue(),
          actions.fetchMyFleetSummaryWofOverdue(),
          actions.fetchMyFleetSummaryKmTracking(),
          actions.fetchMyFleetSummaryRegoOverdue(),
          actions.fetchMyFleetSummaryFbtOutstanding(),
          actions.fetchNotifications(),
          actions.fetchStatusUpdates(),
          actions.fetchIndustryNews(),
          actions.fetchDownloadGuides(),
          actions.fetchContactDetails(),
          actions.fetchFooterTicker(),
        )
      )
    )

  return combineEpics(
    checkHaveAppUsersEffect,
    fetchMyFleetSummaryServiceOverdueEffect,
    fetchMyFleetSummaryWofOverdueEffect,
    fetchMyFleetSummaryKmTrackingEffect,
    fetchMyFleetSummaryRegoOverdueEffect,
    fetchMyFleetSummaryFbtOutstandingEffect,
    fetchNotificationsEffect,
    fetchStatusUpdatesEffect,
    fetchIndustryNewsEffect,
    fetchDownloadGuidesEffect,
    fetchContactDetailsEffect,
    fetchFooterTickerEffect,
    refetchEffect,
  )
}
