import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'


const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: theme.breakpoints.values.lg,
  },
  center: {
    margin: '0 auto',
  }
}))


const BodyContent = ({ children, variant, ...rest }) => {

  const classes = useStyles()
  const themeClassName = variant === 'center'
    ? classes.center
    : ''

  return (
    <div
      className={clsx(classes.root, themeClassName)}
      {...rest}>
      {children}
    </div>
  )
}

BodyContent.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.oneOf(['default', 'center'])
}

export default BodyContent
