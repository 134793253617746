import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Button, Divider, Drawer, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import { PageLoader } from 'frame/components'
import FilterAttributes from './filter-attributes'
import { actions, types } from '../../module'


const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  drawer: {
    width: 420,
    maxWidth: '100%',
  },
  header: {
    padding: theme.spacing(2, 1),
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 0),
    cursor: 'pointer',
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
  },
  content: {
    padding: theme.spacing(0, 3),
    flexGrow: 1,
  },
  empty: {
    padding: theme.spacing(1, 0),
  },
  actions: {
    padding: theme.spacing(3),
    '& > * + *': {
      marginTop: theme.spacing(2),
    }
  },
}))

const Filters = ({ model, onClear, onFilter, onHide }) => {

  const classes = useStyles()
  const isEmptyList = list => list && list.length === 0

  const noFilters = isEmptyList(Object.entries(model.filters)
    .filter(([key, value]) => !isEmptyList(value)))
  
  const flags = model.flags
  const loading = flags.fetch && flags.fetch === 'processing'

  return (
    <Drawer
      anchor="right"
      classes={{ paper: classes.drawer }}
      onClose={onHide}
      open={model.showFilters}
      variant="temporary">
      <div className={classes.root}>
        <div className={classes.header}>
          <Button
            onClick={onHide}
            size="small">
            <CloseIcon className={classes.buttonIcon} />
            Close
          </Button>
        </div>
        <div className={classes.content}>
          <div
            className={classes.title}>
            <Typography variant="h5">Filters</Typography>
          </div>
          <Divider />
          {loading && (
            <PageLoader />
          )}
          { ! loading && noFilters && (
            <Typography
              className={classes.empty}
              variant="body2">
              No Filters Avaliable
            </Typography>
          )}
          { ! loading && !noFilters && (
            <FilterAttributes
              roleList={model.roles}
              filters={model.filters}
              filtered={model.filtered}
              onFilter={onFilter} />
          )}
        </div>
        <div className={classes.actions}>
          <Button
            fullWidth
            onClick={onClear}
            variant="contained">
            <DeleteIcon className={classes.buttonIcon} />
            Clear
          </Button>
        </div>
      </div>
    </Drawer>
  )
}

Filters.propTypes = {
  model: types.ModuleType.isRequired,
  onClear: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
}

const mapStateToProps = ({ adminUsers }) => ({
  model: adminUsers,
})

const mapDispatchToProps = {
  onClear: actions.clearFilters,
  onFilter: actions.updateFilters,
  onHide: actions.hideFilters,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Filters)
