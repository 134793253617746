import React from 'react'
import PropTyes from 'prop-types'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import { useSettingsFleetValues } from 'features/settings'
import Form from './form'


const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(3, 0),
  },
}))

const Profile = ({ profile, errorMessage }) => {

  const classes = useStyles()
  const fleets = useSettingsFleetValues()

  return (
    <div className={classes.root}>
      <Form
        fleets={fleets}
        profile={profile}
        errorMessage={errorMessage}
      />
    </div>
  )
}

Profile.propTypes = {
  profile: PropTyes.object.isRequired,
  errorMessage: PropTyes.string,
}

const mapStateToProps = ({ account }) => ({
  profile: account.profile,
  errorMessage: account.errorMessage,
})

export default connect(
  mapStateToProps,
)(Profile)
