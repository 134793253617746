import * as moduleTypes from './types'

// Fetch Incident Details
export const fetchIncidentDetails = (payload, options) => ({
  type: moduleTypes.FETCH_INCIDENT_DETAILS,
  payload,
  options,
})

export const fetchIncidentDetailsError = (payload, options) => ({
  type: moduleTypes.FETCH_INCIDENT_DETAILS_ERROR,
  payload,
  options,
})

export const fetchIncidentDetailsFulfilled = (payload, options) => ({
  type: moduleTypes.FETCH_INCIDENT_DETAILS_FULFILLED,
  payload,
  options,
})

// Save Incident Notes
export const saveIncidentNotes = (payload, options) => ({
  type: moduleTypes.SAVE_INCIDENT_NOTES,
  payload,
  options,
})

export const saveIncidentNotesError = (payload, options) => ({
  type: moduleTypes.SAVE_INCIDENT_NOTES_ERROR,
  payload,
  options,
})

export const saveIncidentNotesFulfilled = (payload, options) => ({
  type: moduleTypes.SAVE_INCIDENT_NOTES_FULFILLED,
  payload,
  options,
})

