import { reduceRequest } from '../utils'
import * as R from 'ramda'


const defaultState = {
  ids: [],
  data: {},
  columns: {},
  columnsSort: {
    column: '',
    direction: 'desc',
  },
  filters: {},
  filtered: {},
  filtering: false,
  flags: {},
  idKey: 'agreementNo',
  list: {
    ids: [],
    size: 0,
  },
  pagination: {
    offset: 0,
    rows: 25,
  },
  refetch: 0,
  related: {},
  requests: {},
  searchKey: '',
  selected: [],
  showFilters: false,
}

export const createReducer = (name, types, customState, customHooks = {}, customReducer) => {

  const initialState = {
    ...defaultState,
    ...customState,
  }

  return (state = initialState, action) => {

    switch (action.type) {
      case 'settings_request_preferences_fulfilled':
        return populatePreferences(state, action.payload[name])
      
      // request list data
      case types.REQUEST_LIST:
        return requestList(state, action)
      case types.REQUEST_LIST_FULFILLED:
        return requestListFulfilled(state, action)
      case types.REQUEST_LIST_ERROR:
        return requestListError(state, action)

      // request filters
      case types.REQUEST_FILTERS:
        return updateFlags(state, { requestFilters: 'processing' })
      case types.REQUEST_FILTERS_FULFILLED:
        return updateFlags(state, { requestFilters: 'processed' })
      case types.REQUEST_FILTERS_ERROR:
        return updateFlags(state, { requestFilters: 'error' })
      
      case types.LOOKUP_RELATED_CONTRACT:
      case types.LOOKUP_RELATED_CONTRACT_ERROR:
      case types.REQUEST_RESOURCE:
      case types.REQUEST_RESOURCE_FULFILLED:
      case types.REQUEST_RESOURCE_ERROR:
      case types.CREATE_RESOURCE:
      case types.CREATE_RESOURCE_FULFILLED:
      case types.CREATE_RESOURCE_ERROR:
      case types.UPDATE_RESOURCE:
      case types.UPDATE_RESOURCE_FULFILLED:
      case types.UPDATE_RESOURCE_ERROR:
        return reduceRequest(state, action)
      
      case types.REFETCH_RESOURCE:
        return refetchRequest(state, action)
      
      case types.ADD_ITEMS: {
        const next = addItems(state, action)
        return customHooks['add_item']
          ? customHooks['add_item'](next, action)
          : next
      }
      
      case types.REMOVE_ITEMS: {
        const next = removeItems(state, action)
        return customHooks['remove_item']
          ? customHooks['remove_item'](next, action)
          : next
      }

      case types.LOOKUP_RELATED_CONTRACT_FULFILLED:
        return addRelatedContract(state, action)

      // for checkboxes in drivers page
      case types.SELECT_ITEMS:
        return selectItems(state, action)
      case types.DESELECT_ITEMS:
        return deselectItems(state, action)

      case types.UPDATE_SEARCH_KEY:
        return updateSearchKey(state, action)
      
      case types.SET_PAGINATION_OFFSET:
        return setPaginationOffset(state, action)
      case types.SET_PAGINATION_ROWS:
        return setPaginationRows(state, action)
      
      // download actions
      case types.REQUEST_DOWNLOAD_CLEAR_STATUS:
        return clearDownloadStatus(R.clone(state), action)
      case types.REQUEST_DOWNLOAD:
        return requestDownload(R.clone(state), action)
      case types.REQUEST_DOWNLOAD_ERROR:
        return requestDownloadError(R.clone(state), action)
      case types.REQUEST_DOWNLOAD_FULFILLED:
        return requestDownloadFulfilled(R.clone(state), action)
      
      case types.ADD_FLEET_FILTERS:
        return addFleetFilters(state, action)
      case types.SHOW_FILTERS:
        return showFilters(state, action)
      case types.HIDE_FILTERS:
        return hideFilters(state, action)
      case types.CLEAR_FILTERS:
        return clearFilters(state, action, initialState.filtered)
      case types.UPDATE_FILTERS:
        return updateFilters(state, action)
      
      case types.TOGGLE_SHOW_COLUMNS:
        return toggleShowColumns(state, action)
      case types.TOGGLE_COLUMN:
        return toggleColumn(state, action)
      case types.TOGGLE_SELECT_ALL_COLUMNS:
        return toggleSelectAllColumns(state, action)
      case types.TOGGLE_COLUMN_SORT:
        return toggleColumnSort(state, action)
      
      case types.SELECT_ROW:
        return selectRow(R.clone(state), action)
      
      case types.SET_DASHBOARD_FILTER:
        return setIsDashboardFilter(R.clone(state), action)
      
      case types.RESET_DEFAULTS:
        return initialState
      default: {
        if (customReducer) {
          return customReducer(state, action)
        }
        return state
      }
    }
  }
}

const updateFlags = (state, flags) => ({
  ...state,
  flags: { ...state.flags, ...flags }
})

const refetchRequest = (state, { payload }) => {
  
  // clears filters/search
  const resetStates = payload?.cleanRefetch ? {
    columnsSort: defaultState.columnsSort,
    filtered: {},
    filtering: false,
    pagination: { ...state.pagination, offset: 0 },
    searchKey: '',
  } : {}

  console.log('[refetchRequest] payload: ', { payload, resetStates })
  return ({ // keeps previous filters/search
    ...state,
    refetch: state.refetch + 1,
    flags: { ...state.flags, create: 'none' },
    ...resetStates
  })
}

const addRelatedContract = (state, action) => {

  if ( ! action.payload
      || ! action.payload.agreementNo) {
    return reduceRequest(state, action)
  }

  return reduceRequest({
    ...state,
    related: {
      ...state?.related,
      contracts: {
        ...state?.related?.contracts,
        [action.payload.agreementNo]: action.payload,
      },
    },
  }, action)
}

const addItems = (state, action) => {

  const items = Array.isArray(action.payload)
    ? action.payload
    : [action.payload]

  const itemsToBeAdded = items
    /* .map(node => ({
      ...node,
      id: node.agreementNo || node.id, // because the raw data often doesn't have an id :/
    })) */
    .filter(x => x)

  // Oh no... I actually did this :(
  // I'm so sorry, tight timeline, refactoring and a terrible API
  const groupA = itemsToBeAdded.map(({ id }) => id)
  const groupB = itemsToBeAdded.map(({ agreementNo }) => agreementNo)
  const idsToBeAdded = [...groupA, ...groupB]

  const data = { ...state.data }

  itemsToBeAdded.forEach((node) => {
    if (node.id) {
      data[node.id] = node
    }
    if (node.agreementNo) {
      data[node.agreementNo] = node
    }
  })
  // Again, I'm so sorry for all of the above

  return {
    ...state,
    ids: Array.from(new Set([
      ...state.ids,
      ...idsToBeAdded,
    ])),
    data,
  }
}

const removeItems = (state, action) => {

  const items = Array.isArray(action.payload)
    ? action.payload
    : [action.payload]

  const idsToBeRemoved = items
    .map(node => ({
      ...node,
      id: node.id || node.agreementNo, // because the raw data often doesn't have an id :/
    }))
    .filter(x => x)

  const data = {}

  const ids = state.ids
    .filter(id => ! idsToBeRemoved.includes(id))

  const selectedIds = state.selectedIds
    .filter(id => ! idsToBeRemoved.includes(id))

  Object.keys(state.data).forEach(id => {
    if ( ! idsToBeRemoved.includes(id)) {
      data[id] = state.data[id]
    }
  })

  return {
    ...state,
    ids: Array.from(new Set(ids)),
    selectedIds: Array.from(new Set(selectedIds)),
    data,
  }
}

const selectItems = (state, action) => {
  const ids = Array.isArray(action.payload)
    ? action.payload
    : [action.payload]

  return {
    ...state,
    selected: [
      ...state.selected,
      ...ids,
    ]
  }
}

const deselectItems = (state, action) => {
  const ids = Array.isArray(action.payload)
    ? action.payload
    : [action.payload]

  const selected = state.selected
    .filter(id => ! ids.includes(id))

  return {
    ...state,
    selected,
  }
}

const updateSearchKey = (state, action) => ({
  ...state,
  pagination: {
    ...state.pagination,
    offset: 0,
  },
  searchKey: action.payload,
})

const setPaginationOffset = (state, action) => ({
  ...state,
  pagination: {
    ...state.pagination,
    offset: action.payload,
  }
})

const setPaginationRows = (state, action) => ({
  ...state,
  pagination: {
    offset: 0,
    rows: action.payload,
  },
})

const clearDownloadStatus = (state, action) => {
  state.downloadStatus = null
  return state
}

const requestDownload = (state, action) => {
  state.downloadStatus = 'Preparing report for download...'
  return state
}

const requestDownloadError = (state, action) => {
  console.log('[download error]: ', action.payload)
  state.downloadStatus = 'Error occured, download failed'
  return state
}

const requestDownloadFulfilled = (state, action) => {
  if(action.payload) {
    console.log('requestDownloadFulfilled action', { action, state })
    state.downloadStatus = 'Download completed successfully'
  }
  return state
}

const showFilters = (state, action) => ({
  ...state,
  showFilters: true,
})

const hideFilters = (state, action) => ({
  ...state,
  showFilters: false,
})

const clearFilters = (state, action, initialFilters) => ({
  ...state,
  flags: {},
  columnsSort: defaultState.columnsSort,
  filtered: initialFilters,
  filtering: false,
  searchKey: '',
})

const updateFilters = (state, { payload, options }) => {

  const next = {
    ...state,
    filtered: !!options?.cleanUpdate
      ? { ...payload }
      : { ...state.filtered, ...payload, },
    pagination: {
      ...state.pagination,
      offset: 0,
    },
  }

  console.log('[updateFilters] next.filtered: ', next?.filtered)
  // let filterKey = Object.keys(payload)[0] // The horror
  // console.log('[filterKey]: ', filterKey)
  
  // let array = next.filters[filterKey]
  // // insert filter if not exist in filter object
  // if (array && !array.includes(payload[filterKey])) {
  //   array.push(payload[filterKey])
  // }

  return { ...next, filtering: isFiltered(next) }
}

const isFiltered = (state) => {

  let filtering = false

  Object.keys(state.filtered).forEach(key => {

    const filter = state.filtered[key]

    if (Array.isArray(filter) && filter.length > 0) {
      filtering = true
    }
    else if (filter && ! Array.isArray(filter)) {
      filtering = true
    }
  })

  return filtering
}

const toggleShowColumns = (state, action) => ({
  ...state,
  showColumns: ! state.showColumns,
})

const toggleColumn = (state, action) => {

  const column = action.payload
  const value = ! state.columns[column]

  return {
    ...state,
    columns: {
      ...state.columns,
      [column]: value,
    }
  }
}

const toggleSelectAllColumns = (state, action) => {

  const columns = state.columns
  const selectAll = action.payload

  Object.entries(columns).forEach(([key, value]) => {

    state = {
      ...state,
      columns: {
        ...state.columns,
        [key]: selectAll,
      }
    }
  })

  // console.log('toggleSelectAllColumn ', state)
  return state
}

const toggleColumnSort = (state, action) => {

  const column = action.payload

  const nextDirection = state.columnsSort.direction === 'asc'
    ? 'desc'
    : 'asc'

  const direction = column === state.columnsSort.column
    ? nextDirection
    : state.columnsSort.direction

  return {
    ...state,
    columnsSort: {
      column,
      direction,
    },
    pagination: {
      ...state.pagination,
      offset: 0,
    },
  }
}

const addFleetFilters = (state, action) => ({
  ...state,
  filters: action.payload || {},
})

const populatePreferences = (state, preferences) => {

  if ( ! preferences?.columns
      || ! preferences?.filtered) {
    return state
  }

  return {
    ...state,
    searchKey: '',
    columns: {
      ...state.columns,
      ...preferences.columns,
    },
    filtered: {
      ...state.filtered,
      ...preferences.filtered,
    },
    pagination: {
      ...state.pagination,
      rows: preferences?.pagination?.rows || state.pagination.rows,
    },
  }
}

const requestList = (state, action) => ({
  ...state,
  flags: {
    ...state.flags,
    list: {
      requestId: action.options.requestId || null,
      status: 'loading',
    },
  }
})

const requestListFulfilled = (state, action) => {
  
  const payload = action.payload
  console.log(`[${action.type}]: `, { payload, location: window.location.pathname })
  
  // depreciated after updating global fetch effects to use switch map in src/effects/index.js line 192
  // because switchmap cancells any previous effect of the same action type, the if line below would stop loading request data if the request ids dont match
  // if (state.flags?.list?.requestId !== action.options?.requestId) {  
  //   return state
  // }

  const idKey = state.idKey
  const size = action.response?.meta?.totalRecords || 0

  const items = Array.isArray(payload)
    ? payload
    : [payload]

  const ids = items
    .map(node => node?.[idKey])
    .filter(x => x)

  const data = { ...state.data }

  items.forEach((node) => {
    if (node[idKey]) {
      data[node[idKey]] = node
    }
  })

  return {
    ...state,
    data,
    flags: {
      ...state.flags,
      list: {
        ...state.flags.list,
        status: 'success',
      },
    },
    list: {
      ids,
      size,
    }
  }
}

const requestListError = (state, action) => {
  if (state.flags?.list?.requestId !== action.options?.requestId) {
    return state
  }
  return {
    ...state,
    flags: {
      ...state.flags,
      list: {
        ...state.flags.list,
        status: 'error',
        errorMessage: action.payload
      },
    }
  }
}

const selectRow = (state, { payload }) => ({
  ...state,
  selected: payload
})

// called in dashboard graph 2nd column onwards to differentiate filters from the dashboard and report page 
const setIsDashboardFilter = (state, action) => ({
  ...state,
  isDashboardFilter: action.payload,
})