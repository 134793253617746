import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Doughnut } from 'react-chartjs-2'


const useStyles = makeStyles(theme => ({
  root: {},
}))

const PieChart = ({ data, onClick }) => {

  const classes = useStyles()

  const getGraphData = () => ({
    labels: data.labels,
    datasets: [{
      data: data.values,
      backgroundColor: data.colors,
      hoverBackgroundColor: data.colos
    }]
  })

  const graph = getGraphData()

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      position: 'left',
    },
    layout: {
      padding: 0
    },
    tooltips: {
      enabled: true,
    },
    onClick,
  }

  return (
    <Doughnut
      className={classes.root}
      height={245}
      data={graph}
      options={options} />
  )
}

PieChart.propTypes = {
  data: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default PieChart
