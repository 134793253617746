import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import { Dialog } from 'frame/components'
import { useNavigate } from 'utils'
import { constants } from '../../module'
import Form from './form'


const useStyles = makeStyles(theme => ({
  root: {},
}))

const Item = ({
  id,
  data,
  fleetIds,
  fetchStatus,
  saveStatus,
  userRoles,
  onFetch,
  onUpdate,
  onDelete
}) => {

  const classes = useStyles()
  const navigate = useNavigate()

  const onClose = navigate(constants.PATH_ALL)

  const onSave = (payload) => {
    onUpdate(payload)
  }

  // Same as componentDidMount
  // We either use material ui 'useStyles' or we wrap the component
  // in an HoC and pass the styles down or completely
  // redo the way styles are done if we want to change this.
  useEffect(() => {
    onFetch(id, fleetIds)
  }, [id, fleetIds]) // eslint-disable-line

  const title = data?.booking?.vehicleRegistration
    ? `Vehicle Relocation Booking - ${data.booking.vehicleRegistration}`
    : `Vehicle Relocation Booking`

  const isLoading = fetchStatus === 'processing' && ! data
  const isSaving = saveStatus === 'processing'
  // const hasErrored = fetchStatus === 'error'
  // const savingError = saveStatus === 'error'

  // const loadingErrorMessage = hasErrored
  //   ? 'Whoops, we could not find that relocation booking right now.'
  //   : null

  return (
    <Dialog
      open autoSize
      size="md"
      aria-labelledby="Request Vehicle Order"
      title={title}
      // errorMessage={loadingErrorMessage}
      loading={isLoading || isSaving}
      onClose={onClose}>
      {(data) && (
        <Grid className={classes.root} container>
          <Grid item xs={12}>
            <Form
              data={data}
              disabled={isLoading}
              // error={savingError}
              userRoles={userRoles}
              onClose={onClose}
              onSave={onSave}
              onDelete={onDelete}
            />
          </Grid>
        </Grid>
      )}
    </Dialog>
  )
}

Item.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  data: PropTypes.object,
  fleetIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  fetchStatus: PropTypes.string,
  saveStatus: PropTypes.string,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  onFetch: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

export default Item
