import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, useTheme } from '@material-ui/styles'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { Card, CardContent, Grid, Typography, TextField, Button } from '@material-ui/core'
import { RowValueText, OfflineLookupField, StyledModal, AnimatedErrorCross, AnimatedSuccessTick } from 'frame/components'
import { trimObjectStrings, useDrillDownModalOnClose } from 'utils'
import { lookup } from 'features/drivers/actions'
import { update } from '../../actions'
import moment from 'moment'
import { hasRoles } from 'acl'
import { useEnabledFeatureToggleList } from 'features/settings'
import { useIsAlfaDownDisableFeatures } from 'features/drivers'


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 1.2, 3, 1.2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
  },
  content: {
    marginTop: theme.spacing(3),
  },
  button: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    minWidth: 180,
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
    }
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    }
  }
}))

const ContactDetails = ({ data, flags, errorMessage,userRoles, onLookup, onUpdate }) => {

  const theme = useTheme()
  const classes = useStyles()
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'))
  const featureToggleList = useEnabledFeatureToggleList()
  const userPermissionsFeatureToggle = featureToggleList.includes('UserPermissions')
  const tfp1944FeatureToggle = featureToggleList.includes('TFP1944')
  const disableOutOfHoursFeatureToggle = featureToggleList.includes('OutOfHoursDisabled') // 'ALFA out of hours DISABLE FEATURES' limitations feature is ON
  const isAlfaDownDisableDriverFeatures = useIsAlfaDownDisableFeatures()

  const readOnly = userPermissionsFeatureToggle && hasRoles(['fleetcoordinator'], userRoles)
  const disabled = disableOutOfHoursFeatureToggle ? false : isAlfaDownDisableDriverFeatures //false

  const [originalValues, setOriginalValues] = useState(data)
  const [values, setValues] = useState(data)
  const [saving, setSaving] = useState(false)
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false)

  const onClose = useDrillDownModalOnClose()

  const onLookupChange = (key) => (option) =>
    setValues({ ...values, [key]: option ? option.value : null })
  
  const onInputChange = ({ target: { id, value } }) =>
    setValues({ ...values, [id]: value })

  const onSave = () => {
    console.log('[ContactDetails onSave]', {
      costCentre: values.costCentre,
      controller: values.controller,
      location: values.location,
      vehicleNotes: values.vehicleNotes,
      driverNote: values.driverNote,
      values,
    })
    setSaving(true)
    onUpdate(values)
  }

  useEffect(() => {
    if(saving && (flags.update === 'processed' || flags.update === 'error')) {
      flags.update === 'processed' && setOriginalValues(values)
      setSaving(false)
      setOpenConfirmationModal(true)
    }// eslint-disable-next-line
  }, [flags.update])

  const regularInstalmentLabel = () => data.agreementType
    && (data.agreementType.includes('Operating Lease')
      || data.agreementType.includes('Finance Lease')
      || data.agreementType.includes('Vantage Lease'))
     ? 'Regular Instalment (excl. GST)' : 'Regular Instalment'


  const disabledSave = () => {
    if (tfp1944FeatureToggle)
    {
      return originalValues.costCentre === values.costCentre &&
      originalValues.controller === values.controller &&
      originalValues.location === values.location &&
      originalValues.vehicleNotes === values.vehicleNotes &&
      originalValues.driverNote === values.driverNote
    }

    return originalValues.costCentre === values.costCentre &&
    originalValues.controller === values.controller &&
    originalValues.location === values.location &&
    originalValues.vehicleNotes === values.vehicleNotes
  }

  return (
    <ValidatorForm autoComplete="off" noValidate onSubmit={() => {}}>
      <Card>
        <CardContent className={classes.root}>
          <Typography  gutterBottom variant="h4">
            Contract
          </Typography>
          
          <div className={classes.content}>
            <Grid container spacing={3}>
              {/* Read-Only Fields */}
              {[
                { label: 'Agreement Type', value: data.agreementType },
                { label: 'Term', value: data.term },
                { label: 'Start Date', value: data.startDate ? moment(data.startDate).format('Do MMMM YYYY') : '-' },
                { label: 'Maturity Date', value: data.maturityDate ? moment(data.maturityDate).format('Do MMMM YYYY') : '-' },
                { label: "Contract KM's", value: data.contractKm ? parseInt(data.contractKm, 10).toLocaleString('en-NZ') : '-' },
                { label: regularInstalmentLabel(), value: data.regularInstalment ? `$${data.regularInstalment}` : '-' },
              ]
              .map(({ label, value }, index) => (
                <Grid item xs={12} key={`existing-contract-details-${index}`}>
                  <RowValueText label={label}>
                    <Typography variant="body1">
                      {value || '-'}
                    </Typography>
                  </RowValueText>
                </Grid>
              ))}

              {/* Editable Fields */}
              <Grid item xs={12}>
                <RowValueText label="Cost Centre" responsive={!readOnly}>
                  {readOnly ? (
                    <Typography variant="body1">
                      {data.costCentre || '-'}
                    </Typography>
                  ) : (
                    <OfflineLookupField
                      fullWidth showAllResult allowFreeText
                      size="small"
                      id="costCentre"
                      label="Cost Centre"
                      hideLabelOnMobile={!mobileView}
                      disabled={disabled}
                      customFleetId={values.thirdPartyNumber}
                      lookupPath="drivercostcentre"
                      value={values.costCentre || ''}
                      variant="outlined"
                      inputProps={{ maxLength: 50 }}
                      // validators={['required']}
                      // errorMessages={['This field is required']}
                      onChange={onLookupChange('costCentre')}
                      defaultOptions={values.costCentre ? [{ text: values.costCentre, value: values.costCentre }] : []}
                      onLookup={onLookup}
                      resourceModel="drivers"
                    />
                  )}
                </RowValueText>
              </Grid>

              <Grid item xs={12}>
                <RowValueText label="Controller" responsive={!readOnly}>
                  {readOnly ? (
                    <Typography variant="body1">
                      {data.controller || '-'}
                    </Typography>
                  ) : (
                    <OfflineLookupField
                      fullWidth showAllResult allowFreeText
                      id="controller"
                      size="small"
                      label="Fleet Controller"
                      hideLabelOnMobile={!mobileView}
                      disabled={disabled}
                      customFleetId={values.thirdPartyNumber}
                      lookupPath="driverfleetcontroller"
                      value={values.controller || ''}
                      variant="outlined"
                      inputProps={{ maxLength: 50 }}
                      // validators={['required']}
                      // errorMessages={['This field is required']}
                      onChange={onLookupChange('controller')}
                      defaultOptions={values.controller ? [{ text: values.controller, value: values.controller }] : []}
                      onLookup={onLookup}
                      resourceModel="drivers"
                    />
                  )}
                </RowValueText>
              </Grid>
              
              <Grid item xs={12}>
                <RowValueText label="Location" responsive={!readOnly}>
                  {readOnly ? (
                    <Typography variant="body1">
                      {data.location || '-'}
                    </Typography>
                  ) : (                
                    <OfflineLookupField
                      fullWidth showAllResult
                      id="location"
                      size="small"
                      disabled={disabled}
                      label="Location"
                      hideLabelOnMobile={!mobileView}
                      lookupPath="location"
                      value={values.location || 'Unknown'}
                      variant="outlined"
                      // validators={['required']}
                      // errorMessages={['This field is required']}
                      onChange={onLookupChange('location')}
                      defaultOptions={values.location ? [{ text: values.location, value: values.location }] : []}
                      onLookup={onLookup}
                      resourceModel="drivers"
                    />
                  )}
                </RowValueText>
              </Grid>

              <Grid item xs={12}>
                <RowValueText label="Vehicle Notes" responsive={!readOnly}>
                  {readOnly ? (
                    <Typography variant="body1">
                      {data.vehicleNotes || '-'}
                    </Typography>
                  ) : (
                    <TextField
                      multiline fullWidth
                      rows={3}
                      size="small"
                      id="vehicleNotes"
                      label={mobileView ? 'Vehicle Notes' : ''}
                      className={classes.field}
                      disabled={disabled}
                      value={values.vehicleNotes || ''}
                      inputProps={{ maxLength: 50 }}
                      variant="outlined"
                      onChange={onInputChange}
                    />
                  )}
                </RowValueText>
              </Grid>

              {tfp1944FeatureToggle && (
              <Grid item xs={12}>
                <RowValueText label="Driver Note" responsive={!readOnly}>
                  {readOnly ? (
                    <Typography variant="body1">
                      {data.driverNote || '-'}
                    </Typography>
                  ) : (
                    <TextField
                      fullWidth
                      size="small"
                      id="driverNote"
                      label={mobileView ? 'Driver Note' : ''}
                      className={classes.field}
                      disabled={disabled}
                      value={values.driverNote || ''}
                      inputProps={{ maxLength: 50 }}
                      variant="outlined"
                      onChange={onInputChange}
                    />
                  )}
                </RowValueText>
              </Grid>
              )}
              
            </Grid>
          </div>
        </CardContent>
      </Card>

      <div className={classes.actions}>
        {!readOnly && (
          <Button
            fullWidth={mobileView}
            color="primary"
            className={classes.button}
            disabled={disabled || disabledSave()}
            variant="contained"
            onClick={onSave}>
            Save Changes
          </Button>
        )}
        <Button
          fullWidth={mobileView}
          className={classes.button}
          variant="contained"
          onClick={onClose}>
          Close
        </Button>
      </div>

      {/* Edit contract success/error confirmation messages */}
      <StyledModal
        open={openConfirmationModal}
        onCancel={() => setOpenConfirmationModal(false)}
        animatedIcon={flags.update === 'processed'
          ? <AnimatedSuccessTick size={50} />
          : <AnimatedErrorCross size={50} />}
        options={['noButtons']}>
        {flags.update === 'processed'
          ? 'Contract details updated successfully.'
          : `Contract details update failed. ${errorMessage}`.trim()
        }
      </StyledModal>
    </ValidatorForm>
  )
}

ContactDetails.propTypes = {
  data: PropTypes.object.isRequired,
  flags: PropTypes.object.isRequired,
  errorMessage: PropTypes.string.isRequired,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  // func
  onLookup: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
}

const mapStateToProps = ({ fleet,settings },ownProps) => {

  const agreementNo = ownProps.match.params.id
  let data = fleet.data?.[agreementNo] || null
  return {
    data: trimObjectStrings(data), // Because the API does not clean data for us
    flags: fleet.flags,
    errorMessage: fleet.errorMessage || '',
    userRoles:settings.roles
  }
}

const mapDispatchToProps = (dispatch) => ({
  onLookup: (lookupPath, searchKey, customFleetId) => {
    dispatch(lookup(null, {
      resourceId: lookupPath,
      queryParams: { FleetId: customFleetId, Search: searchKey }
    }))
  },
  onUpdate: (payload) => {
    dispatch(update(payload))
  },
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactDetails))
