import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Divider, Typography } from '@material-ui/core'
import { SingleSelect } from 'frame/components'
import { createOnFilter } from 'filter'

const useStyles = makeStyles(theme => ({
  root: {
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 0),
    cursor: 'pointer',
  },
  content: {},
  formGroup: {
    padding: theme.spacing(2, 0),
  },
}))

const FilterAttributes = ({ filters, filtered, onFilter }) => {

  const classes = useStyles()
  const onSelect = createOnFilter(onFilter)
  
  const hasList = (list) => list && list.length > 0
  const { costCentres, serviceDueDates, agreementTypes, estServiceDueDateStatuses, serviceBookedFlags, fmoTypes, controllers } = filters

  return (
    <div className={classes.root}>
      <div
        className={classes.header}>
        <Typography variant="h5">Filters</Typography>
      </div>
      <Divider />
        <div className={classes.content}>
          {hasList(costCentres) && (
            <div className={classes.formGroup}>
              <SingleSelect
                tight
                id="costCentre"
                label="Cost Centre"
                options={costCentres}
                value={filtered.costCentre}
                onChange={onSelect('costCentre')} />
            </div>
          )}
          {hasList(serviceDueDates) && (
            <div className={classes.formGroup}>
              <SingleSelect
                tight
                id="serviceDueDate"
                label="Estimated next service due"
                options={serviceDueDates}
                value={filtered.serviceDueDate}
                onChange={onSelect('serviceDueDate')} />
            </div>
          )}
          {hasList(agreementTypes) && (
            <div className={classes.formGroup}>
              <SingleSelect
                tight
                id="agreementType"
                label="Agreement Type"
                options={agreementTypes}
                value={filtered.agreementType}
                onChange={onSelect('agreementType')} />
            </div>
          )}
          {hasList(estServiceDueDateStatuses) && (
            <div className={classes.formGroup}>
              <SingleSelect
                tight
                id="estServiceDueDateStatus"
                label="Service Due Date Status"
                options={estServiceDueDateStatuses}
                value={filtered.estServiceDueDateStatus}
                onChange={onSelect('estServiceDueDateStatus')} />
            </div>
          )}   
          {hasList(serviceBookedFlags) && (
            <div className={classes.formGroup}>
              <SingleSelect
                tight
                id="serviceBooked"
                label="Service Booked"
                options={serviceBookedFlags}
                value={filtered.serviceBooked}
                onChange={onSelect('serviceBooked')} />
            </div>
          )}             
          {hasList(fmoTypes) && (
            <div className={classes.formGroup}>
              <SingleSelect
                tight
                id="fmoType"
                label="FMO"
                options={fmoTypes}
                value={filtered.fmoType}
                onChange={onSelect('fmoType')} />
            </div>
          )}
          {hasList(controllers) && (
            <div className={classes.formGroup}>
              <SingleSelect
                tight
                id="controller"
                label="Controller"
                options={controllers}
                value={filtered.controller}
                onChange={onSelect('controller')} />
            </div>
          )}
        </div>
    </div>
  )
}

FilterAttributes.propTypes = {
  filters: PropTypes.object.isRequired,
  filtered: PropTypes.object.isRequired,
  onFilter: PropTypes.func.isRequired,
}

export default FilterAttributes
