import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Divider, Typography } from '@material-ui/core'
import { SingleSelect } from 'frame/components'
import { createOnFilter } from 'filter'


const useStyles = makeStyles(theme => ({
  root: {
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 0),
    cursor: 'pointer',
  },
  content: {},
  formGroup: {
    padding: theme.spacing(2, 0),
  },
}))

const FilterAttributes = ({ filters, filtered, onFilter }) => {

  const classes = useStyles()
  const onSelect = createOnFilter(onFilter)
  const hasList = (list) => list.length > 0
  const { costCentres, makes, models, expectedDeliveryDates, poRaisedDates } = filters
  // const statusOptions = ['Overdue', 'Upcoming', 'Planned']

  return (
    <div className={classes.root}>
      <div
        className={classes.header}>
        <Typography variant="h5">Filters</Typography>
      </div>
      <Divider />
        <div className={classes.content}>
          {hasList(costCentres) && (
            <div className={classes.formGroup}>
              <SingleSelect
                tight
                id="costCentre"
                label="Cost Centre"
                options={costCentres}
                value={filtered.costCentre}
                onChange={onSelect('costCentre')} />
            </div>
          )}
          {hasList(makes) && (
            <div className={classes.formGroup}>
              <SingleSelect
                tight
                id="make"
                label="Make"
                options={makes}
                value={filtered.make}
                onChange={onSelect('make')} />
            </div>
          )}
          {hasList(models) && (
            <div className={classes.formGroup}>
              <SingleSelect
                tight
                id="model"
                label="Model"
                options={models}
                value={filtered.model}
                onChange={onSelect('model')} />
            </div>
          )}
          {hasList(expectedDeliveryDates) && (
            <div className={classes.formGroup}>
              <SingleSelect
                tight
                id="expectedDeliveryDate"
                label="Expected Delivery Date"
                options={expectedDeliveryDates}
                value={filtered.expectedDeliveryDate}
                onChange={onSelect('expectedDeliveryDate')} />
            </div>
          )}
          {hasList(poRaisedDates) && (
            <div className={classes.formGroup}>
              <SingleSelect
                tight
                id="poRaisedDate"
                label="P.O raised"
                options={poRaisedDates}
                value={filtered.poRaisedDate}
                onChange={onSelect('poRaisedDate')} />
            </div>
          )}
        </div>
    </div>
  )
}

FilterAttributes.propTypes = {
  filters: PropTypes.object.isRequired,
  filtered: PropTypes.object.isRequired,
  onFilter: PropTypes.func.isRequired,
}

export default FilterAttributes
