import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { makeStyles, useTheme } from '@material-ui/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { Button, Card, CardContent, Grid, Typography, TextField } from '@material-ui/core'
import { CustomDatePicker, RowValueText, Alert, OfflineLookupField, ScrollToTop } from 'frame/components'
import moment from 'moment'


const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: theme.spacing(3, 1.2, 3, 1.2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
  },
  startDate: {
    margin: 0,
    padding: 0,
    '& > div': {
      margin: 0,
      padding: '6px 8px',
      borderRadius: '4px',
      '&::before': { display: 'none' },
      '&::after': { display: 'none' },
    },
    '& input': { margin: 0, padding: 0, border: 'none' }
  },
  label: {
    paddingTop: theme.spacing(2),
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    }
  },
  button: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    minWidth: 180,
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
    }
  },
}))

/* used in add/edit driver form  */
const ConfirmForm = ({
  values,
  setValues,
  fleetFormState,
  // flags
  disabled,
  selectedVehicle,
  hasVehicleAlready,
  // functions
  onSubmit,
  onBack,
  onClose,
  onLookup,
}) => {
  const form = useRef()
  const classes = useStyles()
  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'))
  
  const [showError, setShowError] = useState(false)

  const onLookupChange = (key) => (option) =>
    setValues({ ...values, [key]: option ? option.value : null })

  const onInputChange = ({ target: { id, name, value } }) =>
    setValues({ ...values, [id || name]: value })

  const onDateChange = (value) => {
    setValues({ ...values, startDate: value })
  }

    
  const onSubmitForm = () =>
    onSubmit({
      ...values,
      effectiveDate: values.startDate || moment(),
    })

  const onSubmitValidation = () => {
    if (form.current && values.agreementNo) {
      form.current.submit()
    } else {
      setShowError(true)
      setTimeout(() => setShowError(false), 3000)
    }
  }

  return (
    <ValidatorForm autoComplete="off" noValidate ref={form} onSubmit={onSubmitForm}>
      <ScrollToTop />
      <Card className={classes.root}>
        <CardContent className={classes.content}>
          
          <Grid container spacing={2}>
            {showError && (
              <Grid item xs={12}>
                <Alert>
                  Error occured. Assigned vehicle failed. Please try again or contact support.
                </Alert>
                <br />
              </Grid>
            )}

            <Grid item xs={12}>
              <Typography gutterBottom variant="h4">
                Change Driver Details
              </Typography>
            </Grid>
            
            <Grid item xs={12}>
              <RowValueText label="Current Driver">
                <Typography variant="body1">
                  {selectedVehicle && selectedVehicle.currentDriver ? selectedVehicle.currentDriver : 'unassigned'}
                </Typography>
              </RowValueText>
            </Grid>

            <Grid item xs={12}>
              <Typography gutterBottom variant="h4">
                Change Driver To
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <RowValueText label="New Driver">
                <Typography variant="body1">
                  {values.driver || '-'}
                </Typography>
              </RowValueText>
            </Grid>
            
            <Grid item xs={12}>
              <RowValueText label="Start Date" labelClassName={classes.label}>
                <CustomDatePicker
                  disableFuture
                  minDate={fleetFormState && fleetFormState?.startDate
                    ? fleetFormState?.startDate
                    : moment().add(-90, 'days').toDate()
                  }
                  variant="outlined"
                  format="Do MMMM YYYY"
                  value={values.startDate}
                  onChange={onDateChange}
                  textFieldClassName={classes.startDate}
                />
              </RowValueText>
            </Grid>
            
            <Grid item xs={12}>
              <Typography gutterBottom variant="h4">
               Vehicle Details
              </Typography>
            </Grid>
            
            <Grid item xs={12}>
              <RowValueText label="Vehicle">
                <Typography variant="body1">
                  {selectedVehicle ? selectedVehicle.vehicle : '-'}
                </Typography>
              </RowValueText>
            </Grid>

            <Grid item xs={12}>
              <RowValueText label="Registration">
                <Typography variant="body1">
                  {selectedVehicle ? selectedVehicle.text : '-'}
                </Typography>
              </RowValueText>
            </Grid>
            
            <Grid item xs={12}>
              <RowValueText label="Colour">
                <Typography variant="body1">
                  {selectedVehicle ? selectedVehicle.colour : '-'}
                </Typography>
              </RowValueText>
            </Grid>

            <Grid item xs={12}>
              <RowValueText label="Cost Centre" responsive>
                <OfflineLookupField
                  fullWidth showAllResult allowFreeText
                  size="small"
                  id="costCentre"
                  label={mobileView ? 'Cost Centre' : ''}
                  disabled={!values.accountName || disabled}
                  customFleetId={values.thirdPartyNumber}
                  lookupPath="drivercostcentre"
                  value={values.costCentre || ''}
                  variant="outlined"
                  // validators={['required']}
                  // errorMessages={['This field is required']}
                  onChange={onLookupChange('costCentre')}
                  defaultOptions={values.costCentre ? [{ text: values.costCentre, value: values.costCentre }] : []}
                  onLookup={onLookup}
                  resourceModel="drivers"
                />
              </RowValueText>
            </Grid>

            <Grid item xs={12}>
              <RowValueText label="Location" responsive>
                <OfflineLookupField
                  fullWidth showAllResult
                  size="small"
                  id="location"
                  label={mobileView ? 'Location' : ''}
                  disabled={!values.accountName || disabled}
                  lookupPath="location"
                  value={values.location || ''}
                  variant="outlined"
                  // validators={['required']}
                  // errorMessages={['This field is required']}
                  onChange={onLookupChange('location')}
                  defaultOptions={values.location ? [{ text: values.location, value: values.location }] : []}
                  onLookup={onLookup}
                  resourceModel="drivers"
                />
              </RowValueText>
            </Grid>

            <Grid item xs={12}>
              <RowValueText label="Fleet Controller" responsive>
                <OfflineLookupField
                  fullWidth showAllResult allowFreeText
                  id="controller"
                  size="small"
                  label={mobileView ? 'Fleet Controller' : ''}
                  disabled={disabled}
                  customFleetId={values.thirdPartyNumber}
                  lookupPath="driverfleetcontroller"
                  value={values.controller || ''}
                  variant="outlined"
                  // validators={['required']}
                  // errorMessages={['This field is required']}
                  onChange={onLookupChange('controller')}
                  defaultOptions={values.controller ? [{ text: values.controller, value: values.controller }] : []}
                  onLookup={onLookup}
                  resourceModel="drivers"
                />
              </RowValueText>
            </Grid>

            <Grid item xs={12}>
              <RowValueText label="Vehicle Notes" responsive>
                <TextField
                  multiline fullWidth
                  rows={3}
                  size="small"
                  id="vehicleNotes"
                  label={mobileView ? 'Vehicle Notes' : ''}
                  className={classes.field}
                  disabled={disabled}
                  value={values.vehicleNotes || ''}
                  inputProps={{ maxLength: 50 }}
                  variant="outlined"
                  onChange={onInputChange}
                />
              </RowValueText>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body1">
                *Please note, there cannot be more than one driver assigned to a vehicle.
                <br /><br />
                You have chosen to change the driver {selectedVehicle?.currentDriver && <>from <strong>{selectedVehicle?.currentDriver}</strong></>}{' to '}
                <strong>{values.driver}</strong>. Would you like to proceed?
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      
      <div className={classes.actions}>
        <Button className={classes.button} color="primary" disabled={disabled} variant="contained" onClick={onSubmitValidation}>
          {`Yes, ${hasVehicleAlready ? 'Change' : 'Assign'} the Driver`}
        </Button>
        <Button className={classes.button} disabled={disabled} variant="contained" onClick={onBack}>
          Back
        </Button>
        <Button className={classes.button} disabled={disabled} variant="contained" onClick={onClose}>
          Cancel
        </Button>
      </div>
    </ValidatorForm>
  )
}

ConfirmForm.propTypes = {
  values: PropTypes.object.isRequired,
  setValues: PropTypes.func.isRequired,
  fleetFormState: PropTypes.object,
  // flags
  disabled: PropTypes.bool,
  selectedVehicle: PropTypes.object,
  hasVehicleAlready: PropTypes.bool,
  // functions
  onSubmit: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onLookup: PropTypes.func.isRequired,
}


const mapStateToProps = ({ fleet }) => ({
  fleetFormState: fleet?.form,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmForm)
