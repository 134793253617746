
export default {
    yearSelected: {
        color: '#4D4F53',
        fontWeight: 'bold',
        fontSize: '20px',
        margin: '0px',
        '&:hover': {
            fontWeight: 'bold',
            fontSize: '20px',
        },
    }
}
    