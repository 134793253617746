import { combineEpics, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { mergeMap, filter } from 'rxjs/operators'
import * as fromFleetTypes from 'features/fleet/actions/types'
import * as fromDriverTypes from 'features/drivers/actions/types'
import { constants } from './module'


export const createEffects = (moduleActions) => {

  // to refresh km report on update/request variation from shared tfs fleet modal component
  const refetchEffect = (action$, state$) =>
    action$.pipe(
      ofType( 
        fromFleetTypes.UPDATE_FULFILLED,
        fromFleetTypes.DELETE_VARIATION_FULFILLED,
        fromDriverTypes.CREATE_FULFILLED,
        fromDriverTypes.UPDATE_FULFILLED,
        fromDriverTypes.DELETE_FULFILLED,
        fromDriverTypes.TOGGLE_POOL_VEHICLE_FULFILLED,
        fromDriverTypes.UNASSIGN_VEHICLE_FULFILLED,
      ),
      filter(() => window.location.pathname.includes(constants.PATH_ALL)), // to trigger on km tracking report page only
      mergeMap(() => of(
        moduleActions.refetch(),
      ))
    )

  return combineEpics(
    refetchEffect,
  )
}
