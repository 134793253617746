import PropTypes from 'prop-types'


export const model = [{
  key: 'name',
  label: 'Name',
  column: {
    visible: true,
    width: 200,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
},{
  key: 'email',
  label: 'Email',
  column: {
    visible: true,
    width: 250,
  },
  prop: {
    type: PropTypes.email,
    value: '',
  }
}, {
  key: 'isExisting',
  label: 'Already in the Portal',
  column: {
    visible: true,
    width: 200,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'lastSentDate',
  label: 'Date Last Sent',
  column: {
    visible: true,
    width: 200,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}]

const config = {
  title: 'Registration Of Interest',
  breadcrumbs: null,
  backgroundImage: 'url("/static/images/backgrounds/bg4.png")',
  
  idKey: 'id',
  api: {
    filter: '/filteroptions/listinvitations',
    resource: '/RegistrationOfInterest/RegistrationOfInterest',
  },
  model,
  headers: [
    'name', 'email', 'isExisting', 'lastSentDate'
  ],
  filters: [],
  noDownload: true,
}


export default config
