import { combineEpics, ofType } from 'redux-observable'
import { of, EMPTY } from 'rxjs'  // eslint-disable-line
import { ajax } from 'rxjs/ajax'
import { map, mergeMap, tap, catchError, filter } from 'rxjs/operators'
import join from 'url-join'
import { getAppConfig } from 'app-config'
import * as fromAuthTypes from 'auth'
import { makeActionRequestEffect } from 'effects'
import * as fromActions from './actions'
import { push } from 'connected-react-router'
import * as fromFeatureToggleActions from 'features/feature-toggle/actions'


export const createEffects = () => {

  const config = getAppConfig()

  const onAuthRequestEffect = (action$, state$) =>
    action$.pipe(
      ofType(fromAuthTypes.AUTHORIZATION_REQUESTED),
      map(action => fromActions.initialize()),
    )

  const onVerifyAccountEffect = (action$, state$) =>
    action$.pipe(
      ofType(fromAuthTypes.AUTHORIZATION_VERIFICATION_REQUESTED),
      mergeMap(
        action => ajax.post(
          join(config.app.api, 'authZero/verify'),
          {
            password: action.payload.password,
            token: action.payload.token
          },
          {
            'Content-Type': 'application/json'
          }
        )
        .pipe(
          mergeMap(({ response }) => {
            const data = (response && response.data) || response

            if(data && data.success) {
              return of(push('/'))
            }

            return of(fromAuthTypes.verifyUserSuccess(data))
          }),
          catchError(error =>
            of(fromAuthTypes.verifyUserFailed((error && error.message) || error))
          )
        )
      )
    )

  const onVerifyDriverAccountEffect = (action$, state$) =>
    action$.pipe(
      ofType(fromAuthTypes.AUTHORIZATION_DRIVER_VERIFICATION_REQUESTED),
      mergeMap(
        action => ajax.post(
          join(config.app.api, 'authZero/verifydriver'),
          {
            password: action.payload.password,
            token: action.payload.token
          },
          {
            'Content-Type': 'application/json'
          }
        )
        .pipe(
          mergeMap(({ response }) => {
            const data = (response && response.data) || response
            return of(fromAuthTypes.verifyDriverSuccess(data))
          }),
          catchError(error =>
            of(fromAuthTypes.verifyDriverFailed((error && error.message) || error))
          )
        )
      )
    )

  const onAuthFailedEffect = (action$, state$) =>
    action$.pipe(
      ofType(fromAuthTypes.AUTHORIZATION_LOGOUT_FULFILLED),
      map(action => fromActions.initialized()),
    )

  const onAuthSuccessEffect = (action$, state$) =>
    action$.pipe(
      ofType(fromAuthTypes.AUTHORIZATION_LOGIN_FULFILLED),
      // tap(action => {
      //   console.log('[onAuthSuccessEffect] payload: ', action.payload)
      // }),
      mergeMap((action) => of(
        fromActions.setRoles(action?.payload || {}),
        fromActions.requestSelectedFleetsPreferences(),
      )),
    )

  /* called after user SelectedFleetsPreferences call is finished  */
  const onLoadRequestSelectedFleetsPreferences = (action$, state$) =>
    action$.pipe(
      ofType(
        fromActions.REQUEST_SELECTED_FLEETS_PREFERENCES_FULFILLED,
        fromActions.REQUEST_SELECTED_FLEETS_PREFERENCES_ERROR
      ),
      tap(action => {
        // console.log('[requestSelectedFleetsPreferences] payload: ', action.payload)        
        console.log('[Build ID]: ', process.env.REACT_APP_BUILD_RELEASE)
      }),
      mergeMap((action) => of(
        fromActions.requestFleets(),
        fromActions.requestPreferences(),
        fromFeatureToggleActions.requestFeatureToggleList(),
      )),
    )

  const onRequestFleetEffect = makeActionRequestEffect({
    type: fromActions.REQUEST_FLEETS,
    url: join(config.app.api, 'lookup/fleetid'),
    onSuccess: fromActions.requestFleetsFulfilled,
    onError: fromActions.requestFleetsError,
  })

  const onAddFleetEffect = (action$, state$) =>
    action$.pipe(
      ofType(fromActions.REQUEST_FLEETS_FULFILLED),
      mergeMap(action => {
        let selectedJson = localStorage.getItem('selected_fleet_ids')
        let selected = JSON.parse(selectedJson)
        let payloadWithSelected = {
          data: action.payload,
          selected
        }
        // console.log('payloadWithSelected', payloadWithSelected)
        return of(
          fromActions.addFleets(payloadWithSelected),
          fromActions.setAccountType(action.payload),
        )
      }),
    )
  

  /*
   * Persists the user selected fleets.
   */
  const onSelectedFleetsPreferencesSave = makeActionRequestEffect({
    type: fromActions.SAVE_SELECTED_FLEETS_PREFERENCES,
    url: join(config.app.api, '/filterpreference/update'),
    verb: 'put',
    onSuccess: fromActions.saveSelectedFleetsPreferencesFulfilled,
    onError: fromActions.saveSelectedFleetsPreferencesError,
  })

  // triggers onFleetSelectedEffect on fromActions.saveSelectedFleetsPreferencesFulfilled
  const onSelectedFleetsPreferencesSaveSuccessEffect = (action$, state$) =>
    action$.pipe(
      ofType(fromActions.SAVE_SELECTED_FLEETS_PREFERENCES_FULFILLED),
      mergeMap(action => {
        const fleetdIds = JSON.parse(action.payload?.filterJson)
        // console.log('[SAVE_SELECTED_FLEETS_PREFERENCES_FULFILLED]', fleetdIds)
        return of(fromActions.selectFleets(fleetdIds))
      })
    )

  // on select fleet from Fleet Selector modal, selected fleets to state, triggers clears filters/search and refetch list data
  const onFleetSelectedEffect = (action$, state$) =>
    action$.pipe(
      ofType(fromActions.SELECT_FLEETS),
      tap(action => {
        // console.log('[onFleetSelectedEffect] selected_fleet_ids: ', JSON.stringify(action.payload))
        localStorage.setItem('selected_fleet_ids', JSON.stringify(action.payload))
      }), 
      mergeMap(action => {
         /*
          set on listable list.js onload useEffect using fromSettings.setCurrentModule action
          see createActions initialization in createListable for how module name is set
         */
        const moduleName =  state$.value.settings?.currentModule
        console.log('[onFleetSelectedEffect] moduleName: ', moduleName)

        const fleetIds = action.payload
        
        /*
          listable actions - is respective on whichever report(module) the user is on at the time of changing fleet
        */      
        const clearFiltersAction = { type: `${moduleName}_clear_filters` } // action to clear report filters
        const resetFbtCustomerViewAction = { type: `fbtSubmissions_reset_fbt_customer_view` } // action to reset fbt report fleet dropdown value
        const refetchAction = { type: `${moduleName}_refetch_resource`, payload: { cleanRefetch: true } } // action to refetch report data

        const isFbtReportPages = window.location.pathname.includes('/fbt')
        const isFbtSummary = window.location.pathname.includes('/fbt/summary')
        const isHomepage = window.location.pathname.includes('/home')

        /*
          refetches latest month/year on fbt reports when user changes fleet on the homepage or fbt report pages
          fbt submission/summary pages - auto redirects to latest fbt month/year after fleet change
          homepage - uses refetched latest fbt month/year for 'my fleet summary' fbt outstanding link
        */
        if(isFbtReportPages || isHomepage) {
          
          const requestFbtSubmissionFiltersAction = { type: `fbtSubmissions_request_filters`, payload: null, options: { queryParams: { FleetId: fleetIds.join(',') } }}
          const requestFbtSubmmaryFiltersAction = { type: `fbtSummary_request_filters`, payload: null, options: { queryParams: { FleetId: fleetIds.join(',') } }}

          const redirectPath = isHomepage ? '/home' : isFbtSummary ? '/fbt/summary' : '/fbt/submissions'
          
          return of(
            fromActions.requestSelectedFleetsPreferences(),
            fromActions.selectedFleetsChanged({ fleetIds }),
            clearFiltersAction,
            resetFbtCustomerViewAction,
            requestFbtSubmissionFiltersAction,
            requestFbtSubmmaryFiltersAction,
            push(redirectPath),
          )
        }

        // fleet changes post logic for other pages apart from homepage and fbt reports
        return moduleName ? of(
          fromActions.requestSelectedFleetsPreferences(),
          fromActions.selectedFleetsChanged({ fleetIds }),
          clearFiltersAction,
          refetchAction,
        ) : of( // for other pages that dont use listable - eg. dashboard, myaccount page
          fromActions.requestSelectedFleetsPreferences(),
          fromActions.selectedFleetsChanged({ fleetIds }),
        )
      }),
    )

  const onRequestPreferencesEffect = makeActionRequestEffect({
    type: fromActions.REQUEST_PREFERENCES,
    url: join(config.app.api, 'filterpreference/list'),
    onSuccess: fromActions.requestPreferencesFulfilled,
    onError: fromActions.requestPreferencesError,
  })

  const onRequestSelectedFleetsPreferencesEffect = makeActionRequestEffect({
    type: fromActions.REQUEST_SELECTED_FLEETS_PREFERENCES,
    url: join(config.app.api, 'filterpreference/list'),
    onSuccess: fromActions.requestSelectedFleetsPreferencesFulfilled,
    onError: fromActions.requestSelectedFleetsPreferencesError,
  })

  const onRouteChangeEffect = (action$, state$) =>
    action$.pipe(
      ofType('@@router/LOCATION_CHANGE'),
      filter(() =>  state$.value.settings?.initialized || false),
      tap(action => {
        // console.log('[onRouteChangeEffect] payload: ', action.payload)
      }),
      mergeMap(() => of(
        fromFeatureToggleActions.requestFeatureToggleList(),
      )),
    )

  // for test purposes
  // const testEffect = (action$, state$) =>
  //   action$.pipe(
  //     ofType(fromActions.SELECT_FLEETS),
  //     tap(action => {  
  //       console.log('[SELECT_FLEETS]', action.payload) // eslint-disable-line
  //     }),
  //     mergeMap(() => EMPTY)
  //   )

  return combineEpics(
    onAuthRequestEffect,
    onVerifyAccountEffect,
    onVerifyDriverAccountEffect,
    onAuthFailedEffect,
    onAuthSuccessEffect,
    onRequestFleetEffect,
    onAddFleetEffect,
    onFleetSelectedEffect,
    onRequestPreferencesEffect,
    onRequestSelectedFleetsPreferencesEffect,
    onLoadRequestSelectedFleetsPreferences,
    onRouteChangeEffect,
    onSelectedFleetsPreferencesSave,
    onSelectedFleetsPreferencesSaveSuccessEffect,
    // testEffect,
  )
}
