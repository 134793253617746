import React, { Fragment } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, useTheme } from '@material-ui/styles'
import { Grid, Card, Typography } from '@material-ui/core'
import moment from 'moment'


const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      marginLeft: props => props.noData ? -20 : 0,
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: props => props.useRedBorder ? 20 : 0,
    }
  },
  card: {
    borderRadius: 0,
    border: props => props.useRedBorder ? '1px solid red' : null,
  },
  container: {
    marginRight: '8px',
    position: 'relative',
    minHeight: 184,
    padding: '13px 10px',
    [theme.breakpoints.down('sm')]: {
      minHeight: 100,
    }
  },
  numberCircle: {
    margin: 2,
    width: '22px',
    height: '1px',
    padding: '3px',
    background: '#FFF',
    border: '2px solid #B1BE26',
    borderRadius: '50%',
    borderColor: '#B1BE26',
    color: '#B1BE26',
    textAlign: 'center',
    fontSize: '12px',
  },
  numberCircleFilled: {
    backgroundColor: '#B1BE26',
    color: '#FFF',
  },
  wofExpiryLabelContainer: {
    position: 'absolute',
    textAlign: 'center',
    top: '30%',
    left: '28%',
  },
  wofExpiryLabel: {
    color: '#0C567B',
    fontWeight: 'bold',
  },
  noData: {
    padding: '0 30px',
    color: theme.palette.error.main,
    fontWeight: 'bold',
    paddingTop: 60,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 30
    },
    [theme.breakpoints.down('xs')]: {
      padding: '15px 0px 0px 0px',
    }
  },
  wofExpired: {
    color: theme.palette.error.main,
  },
  expiredLabel: {
    fontSize: 12,
    color: theme.palette.error.main,
    fontWeight: 'bold',
    position: 'absolute',
    right: 35,
    bottom: -25,
    [theme.breakpoints.down('sm')]: {
      left: 0,
      right: 'auto',
    }
  }
}))

const WofSection = ({ data }) => {

  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'))

  const noData = !data.wofExpiry
  const isExpired = moment(data.wofExpiry).isBefore(moment())
  const classes = useStyles({ useRedBorder: noData || isExpired, noData })
    
  const wofMonth = data.wofExpiry ? Number(moment(data.wofExpiry).format('M')) : 0

  return (
    <Grid container spacing={1} className={classes.root}> 
      <Card className={classes.card}>
        <Grid item xs={12} className={classes.container}>
          {noData ? (
            <Typography variant="h5" className={classes.noData} align="center">
              No Warrant of Fitness {mobileView && <br />}data available
            </Typography>
          ) : (
            <>
            <div className={classes.wofExpiryLabelContainer}>
              <Typography variant="h5" className={classes.wofExpiryLabel}>
                Warrant of Fitness
              </Typography>
              <Typography variant="h1" className={classes.wofExpiryLabel}>
                {data.wofExpiry ? moment(data.wofExpiry).format('YYYY') : <span className={classes.wofExpired}>Expired</span>}
              </Typography>
            </div>

            {/* Veritical Punch Holes 1 - 5 */}
            {Array.from({length: 5}, (_, i) => i + 1).map(value =>
              <Fragment key={`punch-holes-${value}`}>
                <span className={clsx(classes.numberCircle, wofMonth === value ? classes.numberCircleFilled : '')}
                  style={{ padding: '3px 7px' }}>
                  {value}
                </span>
                <div style={{ height: '10px' }}>
                  <br />
                </div>
              </Fragment>
            )}

            {/* Horizontal Punch Holes 6 - 12 */}
            {Array.from({length: 7}, (_, i) => i + 6).map(value =>
              <Fragment key={`punch-holes-${value}`}>
                <span className={clsx(classes.numberCircle, wofMonth === value ? classes.numberCircleFilled : '')}
                  style={{ padding: value > 9 ? '4px 5px' : '3px 7px' }}>
                  {value}
                </span>
              </Fragment>
            )}
            </>
          )}
        </Grid>
      </Card>

      {/* Bottom Left expired message */}
      {isExpired && (
        <Typography variant="h5" className={classes.expiredLabel}>
          Expired {data.wofExpiry && moment(data.wofExpiry).format('DD/MM/YYYY')}
        </Typography>
      )}
    </Grid>
  )
}

WofSection.propTypes = {
  data: PropTypes.object,
}

export default WofSection
