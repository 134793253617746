

import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Card, CardContent } from '@material-ui/core'
import { PageLoader } from 'frame/components'


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 1.2, 3, 1.2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
      minHeight: 'min(80vh, 600px)',
    },
  },
  content: {
    marginTop: theme.spacing(3)
  },
}))

const LoadingView = ({ activeSection }) => {
  const classes = useStyles()

  return (
    <Card>
      <CardContent className={classes.root}>
        <div className={classes.content}>
          <PageLoader minHeight={220}/>
        </div>
      </CardContent>
    </Card>
  )
}

export default LoadingView

