import PropTypes from 'prop-types'


export const model = [
  {
    key: 'agreementNo',
    label: 'Agreement No.',
    column: {
      visible: true,
      width: 120,
    },
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }, {
    key: 'accountName',
    label: 'Fleet',
    column: {
      visible: true,
      width: 70,
    },
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }, {
    key: 'registration',
    label: 'Registration',
    column: {
      visible: true,
      width: 100,
    },
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }, {
    key: 'driverNote',
    label: 'Driver Note',
    column: {
      visible: false,
      width: 100,
    },
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }, {
    key: 'driver',
    label: 'Driver',
    column: {
      visible: true,
      width: 100,
    },
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }
]

const config = {
  title: 'Documents',
  breadcrumbs: null,
  backgroundImage: 'url("/static/images/backgrounds/bg4.png")',
  api: {
    filter: '/filteroptions/documents',
    resource: `/report/documents`,
    download: `/download/documents`,
  },
  model,
  headers: [
    'accountName', 'agreementNo', 'registration', 'assetBranch', 'assetFunction', 'driverNote', 'driver'
  ],
  filters: [],
  noDownload: true,
}


export default config
