import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Grid, Card, CardContent, Typography, Button } from '@material-ui/core'
import { StyledModal } from 'frame/components'
import { useIsAlfaDownDisableFeatures } from 'features/drivers'
import { hasRoles } from 'acl'
import { useEnabledFeatureToggleList } from 'features/settings'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 1.2, 3, 1.2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
  },
  content: { marginTop: theme.spacing(3) },
  logo: {
    width: 414,
    height: 100,
    [theme.breakpoints.down('xs')]: {
      backgroundSize: '250px auto',
    },
    backgroundImage: 'url("/static/images/logo.png")',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  brandColor: {
    color: theme.palette.brand,
  },
  poolVehicle: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      marginTop: theme.spacing(-2),
    }
  },
  roundedButton: {
    borderRadius: '25px',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  }
}))

const PoolVehicleView = ({
  fleetData,
  onCreateNewDriver,
  onAssignExistingDriver,
  // onTogglePoolVehicle,
  onUnassignVehicle,
  userRoles
}) => {

  const classes = useStyles()
  const featureToggleList = useEnabledFeatureToggleList()
  const disableOutOfHoursFeatureToggle = featureToggleList.includes('OutOfHoursDisabled') // 'ALFA out of hours DISABLE FEATURES' limitations feature is ON
  const isAlfaDownDisableDriverFeatures = useIsAlfaDownDisableFeatures()
  const isAlfaDown = disableOutOfHoursFeatureToggle ? false : isAlfaDownDisableDriverFeatures  
  const userPermissionsFeatureToggle = featureToggleList.includes('UserPermissions')
  const disableButtons = userPermissionsFeatureToggle && hasRoles(['fleetcoordinator'], userRoles)
  // modal flags
  const [openMakeUnassignedVehicleToggleModal, setMakeUnassignedVehicleToggleModal] = useState(false)

  const onMakeUnassignedVehicle = () => { // Make unassigned vehicle when vehicle is a pool vehicle so need to call onTogglePoolVehicle
    // onTogglePoolVehicle(fleetData.agreementNo, fleetData.thirdPartyNumber, false) // make fleet poolVehicle null
    onUnassignVehicle(fleetData.agreementNo, fleetData.thirdPartyNumber)
    setMakeUnassignedVehicleToggleModal(false)
  }
  
  return (
    <Card>
      <CardContent className={classes.root}>
        <Grid item xs={12} className={classes.center}>
          <div className={classes.logo}/>
        </Grid>

        <div className={classes.content}>
          <Grid container spacing={3} className={classes.root}>
            <Grid item xs={12}>
              <Typography variant="body1" align="center">
                The vehicle is currently being used as a pool vehicle therefore no driver details are required at this time. Click on one of the options below if you would like to assign a driver to the vehicle or to make this an unassigned non-pool vehicle.
              </Typography>
            </Grid>

            <Grid item xs={12} className={classes.center}>
              <Button
                disabled={isAlfaDown || disableButtons}
                color="primary"
                id="assign-driver-option"
                data-cy="assign-driver-option"
                className={classes.roundedButton}
                variant="contained"
                onClick={onAssignExistingDriver}>
                Assign Existing Driver
              </Button>
            </Grid>
  
            <Grid item xs={12} className={classes.center}>
              <Button
                disabled={isAlfaDown || disableButtons}
                color="primary"
                id="create-new-driver-option"
                data-cy="create-new-driver-option"
                className={classes.roundedButton}
                variant="contained"
                onClick={onCreateNewDriver}>
                Create New Driver
              </Button>
            </Grid>
  
            <Grid item xs={12} className={classes.center}>
              <Button
                disabled={isAlfaDown || disableButtons}
                color="primary"
                id="make-unassigned-vehicle-option"
                data-cy="make-unassigned-vehicle-option"
                className={classes.roundedButton}
                variant="contained"
                onClick={() => setMakeUnassignedVehicleToggleModal(true)}>
                Make Unassigned Vehicle
              </Button>
            </Grid>

            {/* for make unassigned vehicle confirmation */}
            <StyledModal
              open={openMakeUnassignedVehicleToggleModal}
              title={`Pool Vehicle Change`}
              onCancel={() => setMakeUnassignedVehicleToggleModal(false)}
              onConfirm={onMakeUnassignedVehicle}>
              Please confirm that you would like to change this from a pool vehicle to a non-pool vehicle with no specified driver.
            </StyledModal>
          </Grid>
        </div>
      </CardContent>
    </Card>
  )
}

PoolVehicleView.propTypes = {
  fleetData: PropTypes.object.isRequired,
  onCreateNewDriver: PropTypes.func.isRequired,
  onAssignExistingDriver: PropTypes.func.isRequired,
  // onTogglePoolVehicle: PropTypes.func.isRequired,
  onUnassignVehicle: PropTypes.func.isRequired,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default PoolVehicleView


