import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { makeStyles } from '@material-ui/styles'
import { TableCell, TableRow } from '@material-ui/core'
import { getReliefStatusColor } from 'listable'
import { capitalize, createRowSelector } from 'utils'
import { Label, FleetIcon } from 'frame/components'
import RowOptions from './row-options'
import { types } from '../../module'
import clip from 'text-clipper'
import { useEnabledFeatureToggleList } from 'features/settings'


const useStyles = makeStyles(theme => ({
  root: {
    cursor: 'pointer',
    verticalAlign: 'text-top',
  },
  centerCell: {
    display: 'flex',
    justifyContent: 'center',
  },
  label: theme.typography.bodySmall,
  spacer: {
    margin: 0,
    padding: 0,
  },
}))

const Row = ({ item, columns, selected, onView }) => {

  const classes = useStyles()
  const onSafeRowClick = createRowSelector(onView)
  
  const featureToggleList = useEnabledFeatureToggleList()
  const tfp1944FeatureToggle = featureToggleList.includes('TFP1944')

  return (
    <TableRow
      hover
      className={classes.root}
      selected={selected.indexOf(item.id) !== -1}
      onClick={onSafeRowClick}>
      <TableCell className={classes.spacer} />
      {columns.accountName && (
        <TableCell className={classes.label}>
          <FleetIcon fleetName={item.accountName} />
        </TableCell>
      )}
      {columns.agreementNo && (
        <TableCell className={classes.label}>
          {item.agreementNo || '-'}
        </TableCell>
      )}
      {columns.createdAt && (
        <TableCell className={classes.label}>
          {item.createdAt ? moment(item.createdAt).format('DD/MM/YY') : '-'}
        </TableCell>
      )}
      {columns.registration && (
        <TableCell className={classes.label}>
          {item.registration || '-'}
        </TableCell>
      )}
      {columns.driverNote && tfp1944FeatureToggle && (
        <TableCell className={classes.label}>
          {item.driverNote || '-'}
        </TableCell>
      )}
      {columns.driver && (
        <TableCell className={classes.label}>
          {item.driver || '-'}
        </TableCell>
      )}
      {columns.status && (
        <TableCell className={classes.label}>
          {item.status ? (
            <Label
              color={getReliefStatusColor(item.status)}
              variant="outlined">
              {capitalize(item.status)}
            </Label>
          ) : '-'}
        </TableCell>
      )}
      {columns.isFromApp && (
        <TableCell className={classes.label}>
          {item.isFromApp !== null
            ? item.isFromApp ? 'Yes' : 'No'
            : 'No'}
        </TableCell>
      )}
      {columns.vehicleType && (
        <TableCell className={classes.label}>
          {item.vehicleType || '-'}
        </TableCell>
      )}
      {columns.collectionDate && (
        <TableCell className={classes.label}>
          {item.collectionDate ? moment(item.collectionDate).format('DD/MM/YY') : '-'}
        </TableCell>
      )}
      {columns.bookedReturnDate && (
        <TableCell className={classes.label}>
          {item.bookedReturnDate ? moment(item.bookedReturnDate).format('DD/MM/YY') : '-'}
        </TableCell>
      )}
      {columns.pickupAddress && (
        <TableCell className={classes.label}>
          {item.pickupAddress ? clip(item.pickupAddress, 100) : '-'}
        </TableCell>
      )}
      {columns.comments && (
        <TableCell className={classes.label}>
          {item.comments ? clip(item.comments, 100) : '-'}
        </TableCell>
      )}
      <TableCell align="right">
        <RowOptions          
          className="ignore"
          id={item.it}
          onView={onView} />
      </TableCell>
    </TableRow>
  )
}

Row.propTypes = {
  item: types.ModelType.isRequired,
  columns: types.ColumnsType.isRequired,
  selected: types.IDListType.isRequired,
  onView: PropTypes.func.isRequired,
}

export default Row
