import React, { useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import clip from 'text-clipper'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { colors, Avatar, Button, Divider, Menu, MenuItem, Typography, Tooltip } from '@material-ui/core'
import { hasRoles } from 'acl'
import { ADMIN_PATH } from 'features/admin'
import { constants as fromAccount } from 'features/account'
import { useSelectedFleets, useSettingsFleetValues, actions as fromSettings } from 'features/settings'
import { getAppConfig } from 'app-config'
import FleetSelector from './fleet-selector'
import { FleetIcon } from 'frame/components'


const useStyles = makeStyles(theme => ({
  root: {
    width: 360,
    maxWidth: '100%',
  },
  user: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'spaceBetween',
    alignItems: 'center',
    padding: theme.spacing(3, 3),
    minWidth: 260,
  },
  name: {
    maxWidth: '270px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'wrap',
  },
  avatarIcon: {
    position: 'relative',
    paddingTop: 3,
    color: theme.palette.light,
    animation: '0.3s all ease-out',
    '&:hover': {
      color: theme.palette.white,
    },
  },
  userAvatar: {
    width: 46,
    height: 46,
  },
  userInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    paddingLeft: 10,
  },
  menu: {
    padding: theme.spacing(1, 0, 2, 0),
  },
  link: {
    padding: theme.spacing(0, 0, 0, 1),
  },
  logout: {
    marginTop: theme.spacing(5),
  },
  error: {
    color: theme.palette.error.main,
  },
  fleet: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    padding: theme.spacing(2, 3),
    width: '100%',
  },
  fleetList: {
    padding: theme.spacing(1, 0, 1, 0),
  },
  fleetHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  fleetListTitle: {
    '& > span': {
      color: theme.palette.text.secondary,
      fontSize: '12px',
    }
  },
  fleetAvatars: {
    display: 'flex',
    flexDirection: 'row',
    width: '320px',
    flexWrap: 'wrap',
    '& > *': {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  fleetName: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginLeft: 10,
    minHeight: 26,
  },
  changeFleetSelection: {
    padding: theme.spacing(0.4, 1.5),
    fontSize: 12,
    color: theme.palette.dark,
    background: colors.grey[100],
    transform: `translateY(1px)`,
  },
  seeMore: {
    color: theme.palette.text.link,
    textDecoration: 'none',
    cursor: 'pointer'
  },
}))

const AccountPopover = ({
  anchor,
  linker,
  open,
  user,
  userRoles,
  onClose,
  onLogout
}) => {

  const config = getAppConfig()
  const classes = useStyles()
  const dispatch = useDispatch()
  const selectedFleets = useSelectedFleets()
  const options = useSettingsFleetValues()
  
  const [showSelectFleetModal, setShowSelectFleetModal] = useState(false)

  const MAX_FLEET = 27
  const maxSelectedFleets = selectedFleets.length > MAX_FLEET
  const croppedSelectedfleets = maxSelectedFleets ? selectedFleets.slice(0, MAX_FLEET): selectedFleets

  const [seeMore, setSeeMore] = useState(false)

  const onToggleFleetSelector = () => {  
    dispatch(fromSettings.resetFlags()) // clear settings flags
    onClose()
    setShowSelectFleetModal(!showSelectFleetModal)
  }

  const hasFleets = !! options.length

  if ( ! anchor.current) {
    return null
  }

  const displayFleets = (fleets) => fleets.map((node, index) =>
    <FleetIcon key={`fleet-icon-${index}`} fleetName={node.label} />
  )

  return (
    <>
      <Menu
        keepMounted
        id="menu-account"
        anchorEl={anchor.current}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={onClose}
      >
        <div className={classes.root}>
          <div className={classes.user}>
            <Avatar
              alt={user.nickname}
              className={classes.userAvatar}
              src={user.picture}
              to="/"
            />

            <div className={classes.userInfo}>
              <Typography className={classes.name} variant="h4">
                {user.nickname}
              </Typography>
              <Typography variant="body2">{user.email}</Typography>
            </div>

          </div>

          <Divider />

          <div className={classes.fleet}>
            <div className={classes.fleetHeader}>
              <Typography className={classes.fleetListTitle} variant="h6">
                Selected Fleets <span>({selectedFleets.length})</span>
              </Typography>

              <Button data-cy="change" className={classes.changeFleetSelection} onClick={onToggleFleetSelector}>
                Change
              </Button>
            </div>
            
            <div className={classes.fleetList}>
              {hasFleets ? (
                <div className={classes.fleetAvatars}>

                  {displayFleets(seeMore ? selectedFleets : croppedSelectedfleets)}

                  {selectedFleets.length === 1 && (
                    <Typography className={classes.fleetName} variant="body2">
                      {clip(selectedFleets[0].label, 34)}
                      &nbsp;
                      {selectedFleets.length > 1 && (
                        <>
                          + {selectedFleets.length - 1} other{selectedFleets.length > 2 ? 's' : ''}
                        </>
                      )}
                    </Typography>
                  )}
                </div>
              ) : (
                <Typography className={classes.error} variant="body1">
                  There are no fleets associated with this account. Please&nbsp;
                  <a className={classes.error} href={`mailto:${config.email.help}`}>
                    contact support
                  </a>
                  .
                </Typography>
              )}
            </div>

            {maxSelectedFleets && (
              <Typography variant="body2">
                <Tooltip title={!seeMore ? `${selectedFleets.length - MAX_FLEET} more` : 'hide'}>
                  <span className={classes.seeMore} onClick={() => setSeeMore(!seeMore)}>
                    {seeMore ? 'Hide' : 'See More'}
                  </span>
                </Tooltip>
              </Typography>
            )}
            
          </div>
          <Divider />

          <div className={classes.menu}>
            {/* {hasFleets && (
              <MenuItem onClick={onToggleFleetSelector}>
                <span className={classes.link}>Change fleet selection</span>
              </MenuItem>
            )} */}
            <MenuItem data-cy="my-account" onClick={linker(fromAccount.PATH_ALL)}>
              <span className={classes.link}>My account</span>
            </MenuItem>

            {hasRoles(['admin', 'superadmin', 'fleetcontroller'], userRoles) && (
              <MenuItem data-cy="admin" onClick={linker(ADMIN_PATH)}>
                <span className={classes.link}>Admin</span>
              </MenuItem>
            )}

            <MenuItem
              data-cy="logout"
              className={classes.logout}
              onClick={onLogout}>
              <span className={classes.link}>Logout</span>
            </MenuItem>
          
          </div>
        </div>
      </Menu>
      
      {showSelectFleetModal && (
        <FleetSelector onClose={() => setShowSelectFleetModal(false)} />
      )}
    </>
  )
}

AccountPopover.propTypes = {
  anchor: PropTypes.object,
  linker: PropTypes.func.isRequired,
  open: PropTypes.bool,
  user: PropTypes.shape({
    email: PropTypes.string,
    nickname: PropTypes.string,
    picture: PropTypes.string,
  }).isRequired,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClose: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
}

const mapStateToProps = ({ settings }) => ({
  userRoles: settings.roles,
})

export default connect(mapStateToProps)(AccountPopover)
