import * as types from './constants'


const resetFlags = (state, flags) => ({
  ...state,
  flags: {
    ...state.flags,
    fetch: 'none',
    uploadingCsv: 'none',
    sendingInviteEmails: 'none',
    registerInterest: 'none',
    ...flags,
  },
})

const updateFlags = (state, flags) => ({
  ...state,
  flags: { ...state.flags, ...flags }
})

const updateFlagsWithApiResponse = (state, flags, { payload }) => ({
  ...state,
  apiResponse: payload,
  flags: { ...state.flags, ...flags }
})

const reducer = (state, action) => {
  switch (action.type) {
    case types.RESET:
      return resetFlags(state)

    // Upload CSV
    case types.UPLOAD_CSV:
      return updateFlags(state, { uploadingCsv: 'processing' })
    case types.UPLOAD_CSV_ERROR:
      return updateFlagsWithApiResponse(state, { uploadingCsv: 'error' }, action)
    case types.UPLOAD_CSV_FULFILLED:
      return updateFlagsWithApiResponse(state, { uploadingCsv: 'processed' }, action)
    
    // Send Invite Emails
    case types.SEND_INVITE_EMAILS:
      return updateFlags(state, { sendingInviteEmails: 'processing' })
    case types.SEND_INVITE_EMAILS_ERROR:
      return updateFlagsWithApiResponse(state, { sendingInviteEmails: 'error' }, action)
    case types.SEND_INVITE_EMAILS_FULFILLED:
      return updateFlagsWithApiResponse(state, { sendingInviteEmails: 'processed' }, action)
    
      // Register Interest Form Submit Action for registerOfInterest landing page
      case types.REGISTER_INTEREST:
        return updateFlags(state, { registerInterest: 'processing' })
      case types.REGISTER_INTEREST_ERROR:
        return updateFlagsWithApiResponse(state, { registerInterest: 'error' }, action)
      case types.REGISTER_INTEREST_FULFILLED:
        return updateFlagsWithApiResponse(state, { registerInterest: 'processed' }, action)
    
    default: 
      return state
  }
}

export default reducer
