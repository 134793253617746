import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Card, Grid, Typography } from '@material-ui/core'
import moment from 'moment'


const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      marginLeft: -20,
    }
  },
  card: {
    backgroundImage: 'url("/static/images/digital_service_book/rego_background.png")',
    background: 'cover',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('xs')]: {
      marginRight: '15px',
    },
    borderRadius: 0,
    border: props => props.useRedBorder ? '1px solid red' : null,
  },
  container: {
    maxWidth: '338px',
    padding: '30px 25px',
    marginRight: '8px',
    position: 'relative',
    minHeight: 184,
    [theme.breakpoints.down('sm')]: {
      minHeight: 100,
    }
  },
  vehicleRegoLabel: {
    padding: theme.spacing(0, 0, 0.5, 0),
    color: '#999999'
  },
  vehicleRegoValue: {
    padding: theme.spacing(0, 0, 0.5, 0),
    fontWeight: 'bold',
  },
  noData: {
    padding: '0 40px',
    color: theme.palette.error.main,
    fontWeight: 'bold',
    paddingTop: 45,
    [theme.breakpoints.down('sm')]: {
      padding: '10px 0px 0px 0px',
    }
  },
  expiredLabel: {
    fontSize: 12,
    color: theme.palette.error.main,
    fontWeight: 'bold',
    position: 'absolute',
    right: 0,
    bottom: -25,
    [theme.breakpoints.down('sm')]: {
      left: 0,
      right: 'auto'
    }
  }
}))

const RegoSection = ({ data }) => {

  const noData = !data.registrationExpiry
  const isExpired = moment(data.registrationExpiry).isBefore(moment())
  const classes = useStyles({ useRedBorder: noData || isExpired })

  return (
    <Grid container spacing={1} className={classes.root}>
      <Card className={classes.card}>
        <Grid item xs={12} className={classes.container}>
        
          {noData ? (
            <Typography variant="h5" className={classes.noData} align="center">
              No Vehicle Registration data available
            </Typography>
          ) : (
            <Grid container spacing={1}>
              <Grid item sm={5} xs={5}>
                <Typography className={classes.vehicleRegoValue} variant="h1">
                  {data.registrationExpiry ? moment(data.registrationExpiry).format('DD/MM/YYYY') : '-'}
                </Typography>
              </Grid>
              
              <Grid item xs={12}>
                <Typography className={classes.vehicleRegoValue} variant="h5">
                  {`${data.year} ${data.make} ${data.model}`.trim() !== ''
                    ? `${data.year} ${data.make} ${data.model}`.trim(): '-'}
                </Typography>
              </Grid>
              <br />
              
              <Grid item xs={10}>
                <Typography className={classes.vehicleRegoValue} variant="h2">
                  {data.registration || '-'}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.vehicleRegoValue} variant="h2">
                  G
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Card>

      {/* Bottom Left expired message */}
      {isExpired && (
        <Typography variant="h5" className={classes.expiredLabel}>
          Expired {data.registrationExpiry && moment(data.registrationExpiry).format('DD/MM/YYYY')}
        </Typography>
      )}
    </Grid>
  )
}

RegoSection.propTypes = {
  data: PropTypes.object,
}

export default RegoSection
