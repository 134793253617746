import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import moment from 'moment'
import { makeStyles } from '@material-ui/styles'
import { Divider, Typography, Tooltip, IconButton } from '@material-ui/core'
import { Label } from 'frame/components'
import SingleSelectInline from './single-select-inline'
import { hasRoles } from 'acl'
import { capitalize } from 'utils'

import EditIcon from '@material-ui/icons/Edit'
import SaveIcon from '@material-ui/icons/Save'
import ClearIcon from '@material-ui/icons/Clear'


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  info: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    position: 'relative',
    padding: theme.spacing(1),
  },
  label: {
    paddingRight: theme.spacing(1),
    minWidth: '100px',
    fontWeight: '500',
  },
  large: {
    minWidth: '150px',
  },
  options: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    right: 0,
    height: '100%',
  },
  tooltip: {
    marginLeft: theme.spacing(1),
  },
  icon: {
    width: 20,
    height: 20,
  },
  spacer: {
    display: 'flex',
    width: '50px',
    height: '20px',
  },
  editable: {
    flex: 1,
    position: 'relative',
  },
  editableField: {
    position: 'absolute',
    top: '-5px',
    left:'-8px',
    width: '100%',
  },
}))

const SelectableValueText = ({
  id,
  color,
  format,
  label,
  large,
  noNone,
  options,
  outline,
  value,
  onCancel,
  onSave,
  onChange,
  userRoles,
  disabled,
  noLabel,
  toggleEdit, // to toggle edit mode from outside this valueText component
  onEdit // custom function passed to call on editmode select
}) => {

  const classes = useStyles()
  const editable = !disabled && !hasRoles(['fleetcoordinator'], userRoles)
  const [editMode, setEditMode] = useState(false)

  useEffect(() => {
    if(toggleEdit) {
      setEditMode(false)
    }
  }, [toggleEdit])

  const restyle = color
    ? { color }
    : {}

  if (format === 'number' && value) {
    value = Number(value).toLocaleString('en-NZ')
  }
  else if (format === 'currency' && value) {
    value = Number(value).toLocaleString('en-NZ', {
      style: 'currency',
      currency: 'NZD',
    })
  }
  else if (format && value && ! editMode) {
    value = moment(value).format(format)
  }

  const onSaveHandler = () => {
    if (editMode) {
      onSave && onSave(value)
    }
    setEditMode(! editMode)
    onEdit && onEdit() // call custom onEdit() function if onEdit prop exists
  }

  const onClearHandler = () => {
    setEditMode(! editMode)
    onCancel && onCancel()
  }

  const displayValueField = () =>
    outline ? (
      <Label
        color={outline}
        variant="outlined">
        {value ? capitalize(value) : 'N/A'}
      </Label>
    ) : (
      <Typography
        style={restyle}
        variant="body1">
        {value === 'n/a' ? 'N/A' : value ? capitalize(value) : '-'}
      </Typography>
    )

  const displayEditField = () => (
    <div className={classes.editable}>
      <div className={classes.editableField}>
        <SingleSelectInline
          noNone={noNone}
          options={options}
          value={value}
          onChange={onChange} />
      </div>
    </div>
  )

  return (
    <div className={classes.root}>
      <div className={classes.info}>
        {!noLabel && (
          <Typography
            className={clsx(classes.label, large ? classes.large : '')}
            variant="body1">
            {label}
          </Typography>
        )}

        {editMode ? displayEditField() : displayValueField()}

        {editable && (
          <div className={classes.spacer} />
        )}

        <div className={classes.options}>
          {editable && (
            <Tooltip
              className={classes.tooltip}
              title={editMode ? 'Save' : 'Edit'}>
              <IconButton
                size="small"
                onClick={onSaveHandler}>
                {editMode ? <SaveIcon className={classes.icon} /> : <EditIcon className={classes.icon} />}
              </IconButton>
            </Tooltip>
          )}
          {editMode && (
            <Tooltip
              className={classes.tooltip}
              title="Cancel">
              <IconButton
                size="small"
                onClick={onClearHandler}>
                <ClearIcon className={classes.icon} />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
      {!noLabel && <Divider />}
    </div>
  )
}

SelectableValueText.propTypes = {
  id: PropTypes.string,
  color: PropTypes.string,
  format: PropTypes.string,
  label: PropTypes.string.isRequired,
  large: PropTypes.bool,
  noNone: PropTypes.bool,
  options: PropTypes.array.isRequired,
  outline: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  onCancel: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  disabled: PropTypes.bool,
  noLabel: PropTypes.bool,
  toggleEdit: PropTypes.bool,  
  onEdit: PropTypes.func,
}

const mapStateToProps = ({ settings }) => ({
  userRoles: settings.roles,
})

const mapDispatchToProps = {}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectableValueText)
