import { combineEpics, ofType } from 'redux-observable'
import { map, mergeMap } from 'rxjs/operators'
import { of } from 'rxjs'
import { makeActionRequestEffect } from 'effects'
import join from 'url-join'
import { getAppConfig } from 'app-config'
import * as actions from './actions'
import * as types from './constants'


export const createEffects = (moduleActions) => {

  const appConfig = getAppConfig()

  const requestRolesEffect = makeActionRequestEffect({
    type: types.REQUEST_ROLES,
    url: join(appConfig.app.api, '/usermanagement/listroles'),
    onSuccess: actions.requestRolesFulfilled,
    onError: actions.requestRolesError,
  })

  const addRolesEffect = (action$, state$) =>
    action$.pipe(
      ofType(types.REQUEST_ROLES_FULFILLED),
      map(action => actions.addRoles(action.payload)),
    )

  const createUserEffect = makeActionRequestEffect({
    type: types.REQUEST_ADD_USER,
    url: join(appConfig.app.api, '/usermanagement/createuser'),
    verb: 'post',
    onSuccess: actions.requestAddUserFulfilled,
    onError: actions.requestAddUserError,
  })

  const requestUserEffect = makeActionRequestEffect({
    type: types.REQUEST_USER,
    url: join(appConfig.app.api, '/usermanagement/listusers'),
    onSuccess: actions.requestUserFulfilled,
    onError: actions.requestUserError,
  })
  
  const blockUserEffect = makeActionRequestEffect({
    type: types.REQUEST_BLOCK_USER,
    url: join(appConfig.app.api, '/usermanagement/toggleBlockUser'),
    onSuccess: actions.requestBlockUserFulfilled,
    onError: actions.requestBlockUserError,
  })

  const deleteUserEffect = makeActionRequestEffect({
    type: types.REQUEST_REMOVE_USER,
    url: join(appConfig.app.api, '/usermanagement/removeuser'),
    verb: 'delete',
    onSuccess: actions.requestRemoveUserFulfilled,
    onError: actions.requestRemoveUserError,
  })

  const addUserEffect = (action$, state$) =>
    action$.pipe(
      ofType(types.REQUEST_USER_FULFILLED),
      map(action => actions.addUsers(action.payload)),
    )

  const updateUserEffect = makeActionRequestEffect({
    type: types.REQUEST_USER_UPDATE,
    url: join(appConfig.app.api, '/usermanagement/listusers'),
    verb: 'put',
    onSuccess: actions.requestUserUpdateFulfilled,
    onError: actions.requestUserUpdateError,
  })

  const refreshUserEffect = (action$, state$) =>
    action$.pipe(
      ofType(
        types.REQUEST_BLOCK_USER_FULFILLED,
        types.REQUEST_REMOVE_USER_FULFILLED,
        types.REQUEST_USER_UPDATE_FULFILLED,
        types.REQUEST_ADD_USER_FULFILLED
      ),
      mergeMap(() => of(
        moduleActions.refetch(),
        )
      )
    )

  return combineEpics(
    requestRolesEffect,
    addRolesEffect,
    createUserEffect,
    requestUserEffect,
    addUserEffect,
    updateUserEffect,
    refreshUserEffect,
    deleteUserEffect,
    blockUserEffect,
  )
}
