import React, { memo, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { capitalize } from 'utils'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  label: {
    backgroundColor: 'transparent',
  },
  formControl: {
    width: '100%',
  },
  formControlTight: {
    width: '100%',
    borderRadius: 4,
    border: '1px solid #ced4da',
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none !important',
      '& > div': {
        background: theme.palette.background.paper,
      },
    },
    '& label': {
      background: 'none !important', // theme.palette.background.paper,
    },
    '& .MuiInputBase-input': {
      background: 'none !important',
    },
    '& .MuiOutlinedInput-input': {
      padding: theme.spacing(1.35, 2),
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 14px) scale(1)',
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
  },
  break: {
    paddingTop: '2px !important',
    paddingBottom: '2px !important',
    minHeight: '0 !important',
    background: 'transparent !important',
  },
}))

const getStyles = (name, selected, theme) => ({
  fontWeight:
    selected === name === -1
      ? theme.typography.fontWeightRegular
      : theme.typography.fontWeightMedium,
})

const SingleSelect = ({
  id,
  label,
  options = [],
  noNone,
  customNoneLabel,
  tight,
  value = '',
  onChange,
  size,
  hideLabelOnMobile,
  disabled,
  ...rest
}) => {

  const classes = useStyles()
  const theme = useTheme()
  const [selected, setSelected] = useState(value)
  const [labelWidth, setLabelWidth] = useState(0)
  const labelRef = useRef()

  const onChangeSelection = event =>
    onChange(event.target.value)

  useEffect(() => {
    setLabelWidth(hideLabelOnMobile ? 0 : labelRef.current.offsetWidth)
  }, [hideLabelOnMobile])

  useEffect(() => {
    setSelected(value)
  }, [value])

  return (
    <div className={classes.root}>
      <FormControl
        size={size}
        className={clsx(classes.formControl, tight ? classes.formControlTight : '')}
        variant="outlined">
        <InputLabel
          className={classes.label}
          style={{ color: disabled ? '#DDDDDD' : '#000000'}}
          ref={labelRef}
          htmlFor={`select-multiple-${id}`}
          {...rest}>
          {hideLabelOnMobile ? null : label}
        </InputLabel>
        <Select
          disabled={disabled}
          value={selected}
          onChange={onChangeSelection}
          labelWidth={labelWidth}
          inputProps={{
            id,
            name: label,
          }}
          {...rest}>
          { ! noNone && (
            <MenuItem value="">
              <em>{customNoneLabel || 'None'}</em>
            </MenuItem>
          )}
          {options.filter(o => o).map((option, index) => {

            const name = option.label || option
            const code = option.value || option

            if (option.label && ! option.value) {
              return (
                <MenuItem
                  key={index}
                  className={classes.break}
                  disabled
                  value="">
                  <em>{capitalize(name)}</em>
                </MenuItem>
              )
            }

            return (
              <MenuItem
                key={index}
                value={code}
                style={getStyles(code, selected, theme)}>
                {capitalize(name)}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    </div>
  )
}

SingleSelect.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  options: PropTypes.array.isRequired,
  noNone: PropTypes.bool,
  customNoneLabel: PropTypes.string,
  tight: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  size: PropTypes.string,
  hideLabelOnMobile: PropTypes.bool,
  disabled: PropTypes.bool, 
}

export default memo(SingleSelect)
