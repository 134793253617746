
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { TextValidator } from 'react-material-ui-form-validator'
import { useSelectedFleetIds } from 'features/settings'
import { useSelector } from 'react-redux'


// LookupField is a lookup field that fetches options as user types
// used in relief vehicle/ vehicle relocation rego search
const LookupField = ({
  onLookup,
  resourceModel,
  id,
  size,
  defaultOptions = [],
  lookupPath,
  onChange,
  value,
  label,
  disabled,
  showAllResult,
  allowFreeText,
  customFleetId,
  hideLabelOnMobile,
  customOptionsFilter, // to do custom filter to lookup data results from api
  ...props
}) => {

  const fleetIds = useSelectedFleetIds()
  
  const [options, setOptions] = useState(defaultOptions)
  
  const lookupResults = useSelector(state => state[resourceModel]?.lookup
    ? state[resourceModel]?.lookup[lookupPath]
    : [])
  
  const lookupFlag = useSelector(state => state[resourceModel]?.flags?.lookup)
  const isLoading = lookupFlag === 'processing'

  const onFocus = () => {
    const fleetIdParam = customFleetId ? customFleetId : fleetIds.join(',')
    showAllResult && onLookup(lookupPath, '', fleetIdParam)
  }

  const onSelect = (_, input) => {
    if (onChange && input) {
      onChange(input)
    }
  }

  useEffect(() => { // update dropdown options on lookup results value change
    if(lookupResults && lookupResults.length > 0) {
      setOptions(customOptionsFilter ? customOptionsFilter(lookupResults) : lookupResults)
    }// eslint-disable-next-line
  }, [lookupResults])

  useEffect(() => { // update flags
    // console.log('useEffect lookupFlag ', lookupFlag)
  }, [lookupFlag])

  
  const onInputChange = ({ target: { value } }) => {
    // console.log('lookup onInputChange ', value)
    allowFreeText && onChange({ text: value, value })

    const fleetIdParam = customFleetId ? customFleetId : fleetIds.join(',')
    showAllResult || value.length > 0 ? onLookup(lookupPath, value, fleetIdParam) : setOptions([])
  }

  const labelParams = hideLabelOnMobile ? null : { label }

  return (
    <div>
      <Autocomplete
        disableClearable
        id={`auto-suggest-${id}`}
        size={size ? size : 'medium'}
        freeSolo={!!allowFreeText}
        disabled={disabled}
        loading={isLoading}
        options={options}
        value={defaultOptions ? options.find(option => value === option.text || value === option.value) : {}}
        getOptionLabel={option => option.text || ''}
        renderInput={params => (
          <TextValidator
            {...props}
            {...params}
            {...labelParams}
            inputProps={{ ...params.inputProps, maxLength: 50 }}
            value={value || ''}
            onFocus={onFocus}
            onChange={onInputChange} />
        )}
        onChange={onSelect} />
    </div>
  )
}

LookupField.propTypes = {
  onLookup: PropTypes.func.isRequired,
  resourceModel: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  size: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  defaultOptions: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
  lookupPath: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  showAllResult: PropTypes.bool,
  allowFreeText: PropTypes.bool,
  customFleetId: PropTypes.string,
  hideLabelOnMobile: PropTypes.bool,
  // func
  onChange: PropTypes.func,
  customOptionsFilter: PropTypes.func,
}

export default LookupField
