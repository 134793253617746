import React from 'react'
import { constants } from './module'
import { Route, Switch } from 'react-router-dom'
import { PublicPageLayout } from 'frame/components'
import ConfirmationPopups from 'features/fbt-submissions/components/confirmation-popups'

import InitialForm from './initial-form'
import QuestionStep from './question-step'
import Form from './form/index'
import FormFinish from './form-finish'

const FbtWebFormRoutes = () => (
  <PublicPageLayout>
    <Switch>
      <Route path={constants.PATH_ROOT} exact component={InitialForm} />
      <Route path={constants.PATH_QUESTION} exact component={QuestionStep} />
      <Route path={constants.PATH_FORM} component={Form} />
      <Route path={constants.PATH_FORM_FINISH} component={FormFinish} />
    </Switch>

    <ConfirmationPopups fbtWebFormSuccessRedirect={constants.PATH_FORM_FINISH} />
  </PublicPageLayout>
)

export default FbtWebFormRoutes