import { useState } from 'react'


export const useCollapsible = (visible) => {

  const [collapsed, setCollapsed] = useState(visible || false)

  const onToggleCollapsible = () =>
    setCollapsed( ! collapsed)

  return { collapsed, onToggleCollapsible }
}
