import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { useRouter } from 'utils'
import { useDispatch } from 'react-redux'
import { Typography, Table, TableBody, TableCell, TableRow } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {},
  label: {
    paddingLeft: theme.spacing(3),
  },
  value: {
    textAlign: 'right',
    '&:last-child': {
      paddingRight: theme.spacing(3),
    }
  },
  item: {
    '&:hover': {
      cursor: 'pointer',
    },
  }
}))

const getAmount = (amount) => {
  if ( ! amount) {
    return '-'
  }
  const value = parseFloat(amount, 10)
  return value.toLocaleString('en-NZ', {
    style: 'currency',
    currency: 'NZD',
  })
}

const DataTable = ({ data = {} }) => {

  const classes = useStyles()
  const { history } = useRouter()
  const dispatch = useDispatch()
  const path = '/reports/end-of-lease' 

  const onClick = (data, resource) => () => {
    if(isValid(data)) {
      dispatch({
        type: 'reportEndOfLease_update_filters', 
        payload: {
          [resource]: 1
        }
      })
      history.push(`${path}`)
    }
  }

  const isValid = value =>
    value !== '-' && value !== 0
    
  const items = [
    {
      label: 'Vehicles',
      data: data.vehicles || '-',
      resource: 'Vehicles'
    }, {
      label: 'Extension Fee',
      data: getAmount(data.extensionFee),
      resource: 'Extensions'
    }, {
      label: 'Under KM Credit',
      data: getAmount(data.underKmCredit),
      resource: 'UnderKmCredit'
    }, {
      label: 'Refurb',
      data: getAmount(data.refurb),
      resource: 'Refurb'
    }, {
      label: 'Excess KM',
      data: getAmount(data.excessKm),
      resource: 'ExcessKm'
    },
  ]

  const noData = !data || (data && !data.vehicles && !data.extensionFee && !data.underKmCredit && !data.refurb && !data.excessKm) 

  return (
    <>
      {noData ? (
        <Typography variant="body1">
          You have no end of lease data to display for vehicles that have been returned in the past 6 months.
        </Typography>
      ) : (
        <Table>
          <TableBody>
            {items.map(({ label, data, resource }, index) =>
              <TableRow
                hover
                key={index}  
                className={classes.item}
                onClick={onClick(data, resource)}>
                <TableCell className={classes.label}>
                  {label}
                </TableCell>
                <TableCell className={classes.value}>
                  {data}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
    </>
  )
}

DataTable.propTypes = {
  data: PropTypes.any,
}

export default DataTable
