import React from 'react'
import { Redirect } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import { Tabs, Tab, Divider } from '@material-ui/core'
import { Page, PageHeader } from 'frame/components'
import { useRouter } from 'utils'
import { constants } from '../module'
import { ChildRoutes } from '../routes'
import clsx from 'clsx'


const useStyles = makeStyles(theme => ({
  root: {
    minHeight: 'calc(100vh - 74px)',
    position: 'relative',
    '&::before': {
      content: '" "',
      position: 'absolute',
      zIndex: -99,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundImage: 'url("/static/images/backgrounds/bg1.png")',
      backgroundSize: '100vw 100vh',
      backgroundPosition: 'left top',
      backgroundRepeat: 'no-repeat',
      backgroundAttachment: 'fixed',
    },

    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(3, 1),
    '@media(min-width: 400px)': {
      padding: theme.spacing(3, 2),
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
  },
  content: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: theme.breakpoints.values.lg,
  },
  selected: {
    color: theme.palette.brand,
  },
  tab: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 16,
    textTransform: 'none',
  }
}))

const Account = () => {

  const classes = useStyles()

  const { history, match } = useRouter()
  const { tab } = match.params

  const onTabChange = (_, value) =>
    history.push(value)

  const tabs = [
    { value: 'profile', label: 'Profile' },
    { value: 'security', label: 'Security' }
  ]

  const currentTab = tabs.filter(node => node.value === tab)[0]

  if ( ! tab || ! currentTab) {
    return <Redirect to={constants.ROUTE_PROFILE} />
  }

  return (
    <Page
      className={classes.root}
      title={'Account'}>
      <div className={classes.content}>
        <PageHeader title={'Account'} />
        
        <Tabs
          onChange={onTabChange}
          scrollButtons="auto"
          value={tab}
          variant="scrollable">
          {tabs.map((node, index) => (
            <Tab
              key={index}
              className={clsx(classes.tab, tab === node.value ? classes.selected : '')}
              label={node.label}
              value={node.value} />
          ))}
        </Tabs>
        <Divider className={classes.divider} />
        <ChildRoutes />
      </div>
    </Page>
  )
}

export default Account
