

const expired = timestamp => {
  const offset = 1000 * 60 * 2
  const threshold = Date.now() - offset
  return timestamp < threshold
}

/*
 * Update Requests Status
 * Because requests are dispatched through epics via redux observable
 * it is easy to lose track of an individual requests status
 * (you dispatched it without any ties back to it after all).
 * If a request is dispatched with a 'requestId' we can track
 * that request here and provide it's status on the state tree.
 * This can then be used by the caller in conjunction with it's requestId
 * to get the state of each individual request.
 *
 * YUS!!!
 * Now you can show some UX feedback to the user for individual requests.
 *
 * Cleanup
 * We include a timestamp here and do a cleanup of all expired requests
 * so that they don't pollute the global state for too long.
 */
export const reduceRequest = (state, action) => { // TODO: Refactor out, no idea why we need to keep track of requestIds

  const requestId = action?.options?.requestId

  if ( ! requestId) {
    return state
  }

  let status = 'processing'

  if (action.type.includes('fulfilled')) {
    status = 'done'
  }
  else if (action.type.includes('error')) {
    status = 'error'
  }

  const requests = {}

  Object
    .keys(state.requests)
    .map(key => { // eslint-disable-line
      const request = state.requests[key]
      if ( ! expired(request.updatedAt)) {
        requests[key] = request
      }
    })

  return {
    ...state,
    requests: {
      ...requests,
      [requestId]: {
        updatedAt: Date.now(),
        status,
      }
    }
  }
}
