import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { TableCell, TableRow, Typography, Tooltip, IconButton } from '@material-ui/core'
import { getDateColor, getTrackingColor, obfuscateAgreementNumber } from 'listable'
import { Label, FleetIcon } from 'frame/components'
import { createRowSelector, useRouter } from 'utils'
import { types } from '../../module'
import { useEnabledFeatureToggleList } from 'features/settings'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'


const useStyles = makeStyles(theme => ({
  root: {
    cursor: 'pointer',
    verticalAlign: 'text-top',
  },
  centerCell: {
    display: 'flex',
    justifyContent: 'center',
  },
  label: theme.typography.bodySmall,
  spacer: { margin: 0, padding: 0 },
  documentIcon: { color: '#00B6B0' },
}))

const Row = ({ item, columns, onView }) => {

  const classes = useStyles()
  const { history, location } = useRouter()
  const onSafeRowClick = createRowSelector(onView)

  const featureToggleList = useEnabledFeatureToggleList()
  const documentsReportFeatureToggle = featureToggleList.includes('DocumentsViewing')
  const tfp1944FeatureToggle = featureToggleList.includes('TFP1944') // driver note column in report
  const onViewDocumentsReport = () => {
    history.push(`/reports/documents?registration=${item.registration}`, { label: 'KM Tracking',  path: location.pathname })
  }
  
  return (
    <TableRow hover className={classes.root} onClick={onSafeRowClick}>
      <TableCell className={classes.spacer} />
      {columns.accountName && (
        <TableCell className={classes.label}>
          <FleetIcon fleetName={item.accountName}/>
        </TableCell>
      )}
      {columns.agreementNo && (
        <TableCell className={classes.label}>
          {obfuscateAgreementNumber(item.agreementNo || '-', true)}
        </TableCell>
      )}
      {columns.registration && (
        <TableCell className={classes.label}>
          {item.registration || '-'}
        </TableCell>
      )}
      {columns.driverNote && tfp1944FeatureToggle && (
        <TableCell className={classes.label}>
          <Typography color="inherit" variant="body2">
            {item.driverNote || '-'}
          </Typography>
        </TableCell>
      )} 
      {columns.driver && (
        <TableCell className={classes.label}>
          <Typography
            color="inherit"
            variant="body2">
            {item.driver || '-'}
          </Typography>
        </TableCell>
      )}
      {columns.year && (
        <TableCell className={classes.label}>
          {item.year || '-'}
        </TableCell>
      )}
      {columns.make && (
        <TableCell className={classes.label}>
          {item.make || '-'}
        </TableCell>
      )}
      {columns.model && (
        <TableCell className={classes.label}>
          {item.model || '-'}
        </TableCell>
      )}
      {columns.variant && (
        <TableCell className={classes.label}>
          {item.variant || '-'}
        </TableCell>
      )}
      {columns.fuelType && (
        <TableCell className={classes.label}>
          {item.fuelType || '-'}
        </TableCell>
      )}
      {columns.costCentre && (
        <TableCell className={classes.label}>
          {item.costCentre || '-'}
        </TableCell>
      )}
      {columns.lastOdometerReading && (
        <TableCell className={classes.label}>
          {item.lastOdometerReading ? moment(item.lastOdometerReading).format('DD/MM/YY') : '-'}
        </TableCell>
      )}
      {columns.odometer && (
        <TableCell className={classes.label}>
          {item.odometer ? Number(item.odometer).toLocaleString('en-NZ') : '-'}
        </TableCell>
      )}
      {columns.forecastEndKm && (
        <TableCell className={classes.label}>
          {item.forecastEndKm ? Number(item.forecastEndKm).toLocaleString('en-NZ') : '-'}
        </TableCell>
      )}
      {columns.contractEndKm && (
        <TableCell className={classes.label}>
          {item.contractEndKm ? Number(item.contractEndKm).toLocaleString('en-NZ') : '-'}
        </TableCell>
      )}
      {columns.tracking && (
        <TableCell className={classes.label}>
          {item.tracking ? (
            <Label
              color={getTrackingColor(item.tracking)}
              variant="outlined">
              {item.tracking}
            </Label>
          ) : '-'}
        </TableCell>
      )}
      {columns.kmVariation && (
        <TableCell className={classes.label}>
          <div className={classes.centerCell}>
            {item.kmVariation ? (
              <Label
                // {color={getForecastVariationColor(item.forecastEndKm, item.contractEndKm)}
                color={getTrackingColor(item.tracking)}>
                {Number(item.kmVariation).toLocaleString('en-NZ')}
              </Label>
            ) : '-'}
          </div>
        </TableCell>
      )}
      {columns.odometerSource && (
        <TableCell className={classes.label}>
          <div className={classes.centerCell}>
            {item.odometerSource || '-'}
          </div>
        </TableCell>
      )}
      {columns.maturityDate && (
        <TableCell className={classes.label}>
          <div className={classes.centerCell}>
            {item.maturityDate ? (
              <Label
                color={getDateColor(item.maturityDate)}
                variant="outlined">
                {moment(item.maturityDate).format('DD/MM/YY')}
              </Label>
            ) : '-'}
          </div>
        </TableCell>
      )}
      {columns.startDate && (
        <TableCell className={classes.label}>
          <div className={classes.centerCell}>
            {item.startDate ? moment(item.startDate).format('DD/MM/YY') : '-'}
          </div>
        </TableCell>
      )}
      {columns.agreementType && (
        <TableCell className={classes.label}>
          {item.agreementType || '-'}
        </TableCell>
      )}

      {columns.documentsCount && documentsReportFeatureToggle && (
        <TableCell className={classes.label}>
          {( item.documentsCount > 0 ? (            
            <Tooltip title="View documents">
              <IconButton className="ignore" onClick={onViewDocumentsReport}>
                <InsertDriveFileIcon className={classes.documentIcon} />
              </IconButton>
            </Tooltip>
          ) : '-')}
        </TableCell>
      )}

      <TableCell className={classes.spacer} />
      {/* <TableCell align="right" className="ignore">
        <RowOptions id={item.agreementNo} />
      </TableCell> */}
    </TableRow>
  )
}

Row.propTypes = {
  item: types.ModelType.isRequired,
  columns: types.ColumnsType.isRequired,
  onView: PropTypes.func.isRequired,
}

export default Row
