import { createListable } from 'listable'
import options from './config'
import { moduleName } from './actions/types'
import { FBT_PATH } from 'features/fbt'


const listableModule = createListable({
  name: moduleName,
  config: options,
})

export const config = listableModule.options
export const actions = listableModule.actions
export const effects = listableModule.effects
export const reducer = listableModule.reducer
export const types = listableModule.types

export const constants = {
  ...listableModule.constants,
  PATH_ALL: `${FBT_PATH}/summary`,
  ROUTE_ALL: `${FBT_PATH}/summary`,
}
