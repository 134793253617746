/* eslint-disable */
import React, { memo, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import { SelectValidator } from 'react-material-ui-form-validator'


const useStyles = makeStyles(theme => ({
  fieldTight: {
    '& .MuiOutlinedInput-input': {
      padding: theme.spacing(1.6, 2),
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 14px) scale(1)',
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
  },
  break: {
    paddingTop: '2px !important',
    paddingBottom: '2px !important',
    minHeight: '0 !important',
    background: 'transparent !important',
  },
}))

const getStyles = (name, selected, theme) => ({
  fontWeight:
    selected === name === -1
      ? theme.typography.fontWeightRegular
      : theme.typography.fontWeightMedium,
})

const SingleSelect = ({
  id,
  size,
  label,
  options,
  tight,
  value = '',
  onChange,
  disabled,
  numberOnly,
  noNone,
  ...rest
}) => {

  const classes = useStyles()
  const theme = useTheme()
  const [selected, setSelected] = useState(value)

  const onChangeSelection = event =>
    onChange(event.target.value)

  useEffect(() => {
    setSelected(value)
  }, [value])

  return (
    <SelectValidator
      className={clsx(tight ? classes.fieldTight : '')}
      fullWidth
      size={size}
      disabled={disabled}
      label={label}
      variant="outlined"
      value={selected}
      inputProps={{
        id,
        name: label,
      }}
      onChange={onChangeSelection}
      {...rest}>
        
      { ! noNone && (
        <MenuItem value={numberOnly ? 0 : ''}>
          {numberOnly ? 0 : <em>None</em>} 
        </MenuItem>
      )}
      {options.map((option, index) => {

        const name = option.label || option
        const code = option.value || option

        if (option.label && ! option.value) {
          return (
            <MenuItem
              key={index}
              className={classes.break}
              disabled
              value="">
              <em>{name}</em>
            </MenuItem>
          )
        }

        return (
          <MenuItem
            key={index}
            value={code}
            style={getStyles(code, selected, theme)}>
            {name}
          </MenuItem>
        )
      })}
    </SelectValidator>
  )
}


SingleSelect.propTypes = {
  id: PropTypes.string.isRequired,
  size: PropTypes.string,
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  tight: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
  ]),
  validators: PropTypes.arrayOf(PropTypes.string),
  errorMessages: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  numberOnly: PropTypes.bool,
  noNone: PropTypes.bool,
}

export default memo(SingleSelect)
