
export const getAppConfig = () => {

  const location = window.location.href || ''

  if (location.includes('//localhost:')) {
    return getLocalConfig()
  }
  else if (location.includes('toyotafleetapp.dev-sites.co.nz')) {
    return getBornDevConfig()
  }
  else if (location.includes('uat.toyotafleet.app')) {
    return getTFSUatConfig()
  }
  else if (location.includes('demo.toyotafleet.app')) {
    return getTFSDemoConfig()
  }

  return getPRODConfig()
}

const getLocalConfig = () => ({
  env: process.env.REACT_APP_LOCAL_ENV,
  app: {
    name: process.env.REACT_APP_NAME,
    api: process.env.REACT_APP_LOCAL_API,
    store: process.env.REACT_APP_LOCAL_STORE,
    ga: process.env.REACT_APP_LOCAL_GA,
    ga4: process.env.REACT_APP_LOCAL_GA4,
    readonly: {
      start: process.env.REACT_APP_LOCAL_READ_ONLY_START,
      end: process.env.REACT_APP_LOCAL_READ_ONLY_END
    }
  },
  auth: {
    domain: process.env.REACT_APP_LOCAL_AUTH_DOMAIN,
    clientId: process.env.REACT_APP_LOCAL_AUTH_CLIENTID,
    audience: process.env.REACT_APP_LOCAL_AUTH_AUDIENCE,
  },
  build: {
    release: process.env.REACT_APP_BUILD_RELEASE,
  },
  email: process.env.REACT_APP_HELP_EMAIL,
})

const getBornDevConfig = () => ({
  env: process.env.REACT_APP_DEV_ENV,
  app: {
    name: process.env.REACT_APP_NAME,
    api: process.env.REACT_APP_DEV_API,
    store: process.env.REACT_APP_DEV_STORE,
    ga: process.env.REACT_APP_DEV_GA,
    ga4: process.env.REACT_APP_DEV_GA4,
    readonly: {
      start: process.env.REACT_APP_DEV_READ_ONLY_START,
      end: process.env.REACT_APP_DEV_READ_ONLY_END
    }
  },
  auth: {
    domain: process.env.REACT_APP_DEV_AUTH_DOMAIN,
    clientId: process.env.REACT_APP_DEV_AUTH_CLIENTID,
    audience: process.env.REACT_APP_DEV_AUTH_AUDIENCE,
  },
  build: {
    release: process.env.REACT_APP_BUILD_RELEASE,
  },
  email: process.env.REACT_APP_HELP_EMAIL,
})

const getBornuat1Config = () => ({
  env: process.env.REACT_APP_BORNUAT1_ENV,
  app: {
    name: process.env.REACT_APP_NAME,
    api: process.env.REACT_APP_BORNUAT1_API,
    store: process.env.REACT_APP_BORNUAT1_STORE,
    ga: process.env.REACT_APP_BORNUAT1_GA,
    ga4: process.env.REACT_APP_BORNUAT1_GA,
    readonly: {
      start: process.env.REACT_APP_BORNUAT1_READ_ONLY_START,
      end: process.env.REACT_APP_BORNUAT1_READ_ONLY_END
    }
  },
  auth: {
    domain: process.env.REACT_APP_BORNUAT1_AUTH_DOMAIN,
    clientId: process.env.REACT_APP_BORNUAT1_AUTH_CLIENTID,
    audience: process.env.REACT_APP_BORNUAT1_AUTH_AUDIENCE,
  },
  build: {
    release: process.env.REACT_APP_BUILD_RELEASE,
  },
  email: process.env.REACT_APP_HELP_EMAIL,
})

const getTFSUatConfig = () => ({
  env: process.env.REACT_APP_TFSUAT_ENV,
  app: {
    name: process.env.REACT_APP_NAME,
    api: process.env.REACT_APP_TFSUAT_API,
    store: process.env.REACT_APP_TFSUAT_STORE,
    ga: process.env.REACT_APP_TFSUAT_GA,
    ga4: process.env.REACT_APP_TFSUAT_GA4,
    readonly: {
      start: process.env.REACT_APP_TFSUAT_READ_ONLY_START,
      end: process.env.REACT_APP_TFSUAT_READ_ONLY_END
    }
  },
  auth: {
    domain: process.env.REACT_APP_TFSUAT_AUTH_DOMAIN,
    clientId: process.env.REACT_APP_TFSUAT_AUTH_CLIENTID,
    audience: process.env.REACT_APP_TFSUAT_AUTH_AUDIENCE,
  },
  build: {
    release: process.env.REACT_APP_BUILD_RELEASE,
  },
  email: process.env.REACT_APP_HELP_EMAIL,
})

const getTFSDemoConfig = () => ({
  env: process.env.REACT_APP_TFSDEMO_ENV,
  app: {
    name: process.env.REACT_APP_NAME,
    api: process.env.REACT_APP_TFSDEMO_API,
    store: process.env.REACT_APP_TFSDEMO_STORE,
    ga: process.env.REACT_APP_TFSDEMO_GA,
    ga4: process.env.REACT_APP_TFSDEMO_GA4,
    readonly: {
      start: process.env.REACT_APP_TFSDEMO_READ_ONLY_START,
      end: process.env.REACT_APP_TFSDEMO_READ_ONLY_END
    }
  },
  auth: {
    domain: process.env.REACT_APP_TFSDEMO_AUTH_DOMAIN,
    clientId: process.env.REACT_APP_TFSDEMO_AUTH_CLIENTID,
    audience: process.env.REACT_APP_TFSDEMO_AUTH_AUDIENCE,
  },
  build: {
    release: process.env.REACT_APP_BUILD_RELEASE,
  },
  email: process.env.REACT_APP_HELP_EMAIL,
})

const getPRODConfig = () => ({
  env: process.env.REACT_APP_PROD_ENV,
  app: {
    name: process.env.REACT_APP_NAME,
    api: process.env.REACT_APP_PROD_API,
    store: process.env.REACT_APP_PROD_STORE,
    ga: process.env.REACT_APP_PROD_GA,
    ga4: process.env.REACT_APP_PROD_GA4,
    readonly: {
      start: process.env.REACT_APP_PROD_READ_ONLY_START,
      end: process.env.REACT_APP_PROD_READ_ONLY_END
    }
  },
  auth: {
    domain: process.env.REACT_APP_PROD_AUTH_DOMAIN,
    clientId: process.env.REACT_APP_PROD_AUTH_CLIENTID,
    audience: process.env.REACT_APP_PROD_AUTH_AUDIENCE,
  },
  build: {
    release: process.env.REACT_APP_BUILD_RELEASE,
  },
  email: process.env.REACT_APP_HELP_EMAIL,
})