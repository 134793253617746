import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { List, ListItem, ListItemIcon, ListItemText, Typography, Collapse, Hidden, Paper, Divider } from '@material-ui/core'
import { useRouter } from 'utils'
import { NavigationType } from '../types'
import { Link as RouterLink } from 'react-router-dom'

import NavBarAccordionNestedItem from './nav-bar-accordion-nested-Item'

 // Icons
 import ExpandLess from '@material-ui/icons/ExpandLess'
 import ExpandMore from '@material-ui/icons/ExpandMore'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    maxHeight: 'max(100%, 100vh)',
    overflowY: 'auto',
  },
  title: {
    marginLeft: theme.spacing(2),
  },
  divider: { margin: theme.spacing(0, 2) },
  dividerLastChild: { margin: theme.spacing(0, 2, 1, 2) },
  navItem: {
    minHeight: 44, 
    padding: '5px 0 5px 15px',
    '&:hover': { backgroundColor: 'transparent' },
  },
  navItemLabel: {
    margin: '-1px 0',
    fontWeight: 400,
    color: 'rgba(233,236,239,.5)',
    '&:hover': {
      color: '#ced4da',
    },
    '& span, & svg': {
      fontSize: '14px',
    }
  },
  navItemLabelText: {
    // color: '#4D4F53',
    fontSize: '14px',
    letterSpacing: '0',
    lineHeight: '19px',
    '&:hover': { opacity: '0.8' },
  },
  selectedIcon: {
    color: '#ced4da',
    width: '18px',
    height: '18px',
  },
  selected: {
    color: '#ced4da',
  },
  nestedItem: {
    marginLeft: theme.spacing(-2),
    '&:hover': { backgroundColor: 'transparent' },
    '-webkit-font-smoothing': 'antialiased',
    'transition': '.3s'
  },
}))

const NavBarAccordion = ({ title, className, navigation, openMobile, onMobileClose, openMostRecentOnLoad, ...rest }) => {

  const classes = useStyles()
  const { location } = useRouter()

  
  const fbtSubmissionsActiveFilter = useSelector(({ fbtSubmissions }) => fbtSubmissions.filtered)
  const fbtSummaryActiveFilter = useSelector(({ fbtSummary }) => fbtSummary.filtered)

  const isFbtSubmissionPage = window.location.href.includes('/fbt/submissions')
  const isFbtSummaryPage = window.location.href.includes('/fbt/summary')

  const isSelected = (path) => {
    if(isFbtSubmissionPage || isFbtSummaryPage) { // for fbt reports only
      return isFbtSummaryPage
        ? path.includes(fbtSummaryActiveFilter.quarter) && path.includes(fbtSummaryActiveFilter.year)
        : path.includes(fbtSubmissionsActiveFilter.month) && path.includes(fbtSubmissionsActiveFilter.year)
    } else {
      return window.location.href.includes(path)
    }
  }

  useEffect(() => {
    if (openMobile) {
      onMobileClose && onMobileClose()
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  return (
    <Fragment>
      <Hidden xsDown>
        <Paper {...rest} className={clsx(classes.root, className)} elevation={1} square>
          {title && (
            <Typography className={classes.title} variant="overline">
              {title}
            </Typography>
          )}
          <List>
            {navigation.map(({ title, link, Icon, nestedItems, disabled }, index) => (
              nestedItems ? (<div key={`nav-bar-accordion-level-1-item-${index}`}>
                  <Divider className={classes.divider} />
                  <NavBarAccordionNestedItems
                    key={`accordion-navbar-item-${index}`}
                    title={title} // level 1
                    link={link}
                    Icon={Icon}
                    nestedItems={nestedItems}
                    disabled={disabled}
                    isSelected={isSelected}
                    openMostRecentOnLoad={openMostRecentOnLoad}
                  />
              </div>) : (<div key={`nav-bar-accordion-level-1-item-${index}`}>
                {navigation.length - 1 === index && (
                  <Divider className={classes.dividerLastChild} />
                )}
                <ListItem
                  button to={link} key={index} component={RouterLink}
                  className={clsx(classes.navItem, location.pathname === link ? classes.selected : '')}
                >
                  {Icon && (
                    <ListItemIcon>
                      <Icon className={isSelected(link) ? classes.selectedIcon : classes.navIcon} />
                    </ListItemIcon>
                  )}
                  <ListItemText
                    id={`nav-item-${title}`}
                    className={classes.navItemLabel}
                    primary={<Typography type="body1" className={clsx(classes.navItemLabelText, isSelected(link) ? classes.selected : '')}>
                      {title}
                    </Typography>}
                  />
                </ListItem>
              </div>)
            ))}
            <Divider className={classes.divider} />
          </List>
        </Paper>
      </Hidden>
    </Fragment>
  )
}

NavBarAccordion.propTypes = {
  className: PropTypes.string,
  navigation: NavigationType,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  openMostRecentOnLoad: PropTypes.bool,
}

export default NavBarAccordion


 // level 2 Nav items
const NavBarAccordionNestedItems = ({ title, link, Icon, nestedItems, disabled, isSelected, openMostRecentOnLoad }) => {

  const classes = useStyles()
  const { location } = useRouter()

  const [openNested, setOpenNested] = useState(true)

  return (
    <>
      <ListItem button={!disabled} onClick={() => setOpenNested(!openNested)} className={classes.navItem}>
        {Icon && (
          <ListItemIcon>
            <Icon className={classes.navIcon} />
          </ListItemIcon>
        )}
        <ListItemText
          className={classes.navItemLabel}
          primary={<Typography type="body1" className={classes.navItemLabelText} style={{ opacity: disabled ? '0.3' : 1 }}>
            {title}
          </Typography>}
        />
        {nestedItems && nestedItems.length > 0 && (
          <div style={{ marginRight: 20, marginTop: 5 }}>
            {openNested ? <ExpandLess className={classes.navIcon} /> : <ExpandMore className={classes.navIcon} />}
          </div>
        )}
      </ListItem>

      <Collapse in={openNested} timeout="auto" unmountOnExit>
        <List component="div" disablePadding style={{ paddingLeft: 30 }}>
          {nestedItems.map((nestedItem, nestedIndex) => (

            nestedItem?.nestedItems ? (
              <div key={`nav-bar-accordion-level-2-items-${nestedIndex}`}>
                <NavBarAccordionNestedItem
                  index={nestedIndex}
                  title={nestedItem?.title}
                  Icon={nestedItem?.Icon}
                  nestedItems={nestedItem?.nestedItems}
                  isSelected={isSelected}
                  openMostRecentOnLoad={openMostRecentOnLoad}
                />
              </div>
            ) : (
              <ListItem
                key={`nav-bar-accordion-level-2-items-${nestedIndex}`}
                className={clsx(classes.navItem, location.pathname === nestedItem?.link ? classes.selected : '')}
                style={{ marginBottom: nestedItems.length - 1 === nestedIndex ? 15 : 0 }}
                button component={RouterLink} to={nestedItem?.link}
              >
                {Icon && (
                  <ListItemIcon>
                    <Icon className={isSelected(nestedItem?.link) ? classes.selectedIcon : classes.navIcon} />
                  </ListItemIcon>
                )}
                <ListItemText
                  id={`nav-item-${nestedItem?.title}`}
                  className={classes.navItemLabel}
                  primary={<Typography type="body1" className={clsx(classes.navItemLabelText, isSelected(nestedItem?.link) ? classes.selected : '')}>
                    {nestedItem?.title}
                  </Typography>}
                />
              </ListItem>
            )
          ))}
        </List>
      </Collapse>
    </>
  )
}