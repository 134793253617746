import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { TableCell, TableRow } from '@material-ui/core'
import { types } from '../../module'
import DeleteIcon from '@material-ui/icons/Delete'
import moment from 'moment'


const useStyles = makeStyles(theme => ({
  root: {
    // cursor: 'pointer',
    verticalAlign: 'text-top',
  },
  label: theme.typography.bodySmall,
  spacer: {
    margin: 0,
    padding: 0,
  },
  deleteIcon: {
    cursor: 'pointer',
    "&:hover": {
      opacity: 0.5,
    }
  }
}))

const Row = ({ item, columns }) => {

  const classes = useStyles()
  const allowDelete = false

  return (
    <TableRow hover className={classes.root}>
      <TableCell className={classes.spacer} />
      {columns.name && (
        <TableCell className={classes.label}>
          {item.name || '-'}
        </TableCell>
      )}
      
      {columns.email && (
        <TableCell className={classes.label}>
          {item.email || '-'}
        </TableCell>
      )}

      {columns.isExisting && (
        <TableCell className={classes.label}>
          {item.isExisting !== null
            ? item.isExisting ? 'Yes' : 'No'
            : 'No'}
        </TableCell>
      )}

      {columns.lastSentDate && (
        <TableCell className={classes.label}>
          {item.lastSentDate ? moment(item.lastSentDate).format('DD/MM/YYYY hh:mm A') : '-'}
        </TableCell>
      )}      

      {allowDelete && (
        <TableCell className={classes.label}>
          <DeleteIcon className={classes.deleteIcon}/>
        </TableCell>
      )}
      <TableCell className={classes.spacer} />
    </TableRow>
  )
}

Row.propTypes = {
  item: PropTypes.oneOfType([
    PropTypes.object,  
    types.ModelType,
  ]).isRequired,
  columns: types.ColumnsType.isRequired,
}

export default Row
