import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useRouter } from 'utils'


const NODE_ENV = process.env.NODE_ENV
const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID

const Page = ({ title, children, ...rest }) => {

  const router = useRouter()

  useEffect(() => {
    if (NODE_ENV !== 'production') {
      return
    }

    if (window.gtag) {
      window.gtag('config', GA_MEASUREMENT_ID, {
        'page_path': router.location.pathname,
        'page_name': title
      })
    }
  }, [title, router])

  
  const isUAT = window.location.href.includes('toyotafleetapp.bornuat1.co.nz')  || window.location.href.includes('uat.toyotafleet.app')
  const isDev = window.location.href.includes('dev-sites')
  const isLocal = window.location.href.includes('local')
  const isDemo = window.location.href.includes('demo')

  const environmentTag = isLocal || isDev || isUAT || isDemo
    ? `[${isLocal ? 'LOCAL' : isDev ? 'DEV' : isUAT ? 'UAT' : isDemo ? 'DEMO' : ''}] `
    : '' 

  return (
    <div {...rest}>
      <Helmet>
        <title>{environmentTag}Toyota Fleet Portal - {title}</title>
      </Helmet>
      {children}
    </div>
  )
}

Page.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string
}

export default Page
