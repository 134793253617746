import { useEffect, useState, useMemo } from 'react'

/**
* useMeasure React custom hook - to get element dimensions
* Usage:
*    const [ref, {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
    }] = useMeasure()
*/

export const useMeasure = () => {
  const [element, ref] = useState()
  const [rect, setRect] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  })

  const observer = useMemo(() => new (window).ResizeObserver((entries) => {
    if (entries[0]) {
      const { x, y, width, height, top, left, bottom, right } = entries[0].contentRect
      setRect({ x, y, width, height, top, left, bottom, right })
    } 
  }), [])

  useEffect(() => {
    if (!element) return
    observer.observe(element)
    return () => {
      observer.disconnect()
    };
  }, [element]) // eslint-disable-line

  return [ref, rect]
}