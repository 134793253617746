import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'


const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.text.link,
  },
}))

const TextLink = ({ children, className, to, ...props }) => {

  const classes = useStyles()

  return (
    <RouterLink
      className={clsx(classes.root, className)}
      to={to}
      {...props}>
      {children}
    </RouterLink>
  )
}

TextLink.propTypes = {
  children: PropTypes.string,
  className: PropTypes.string,
  to: PropTypes.string.isRequired,
}

export default TextLink
