

export const serializePreferences = (state) => {

  const preferences = {}

  Object.keys(state).forEach((reducerName) => {

    const reducerState = state[reducerName]

    if (reducerState?.columns
        && reducerState?.filtered
        && reducerState?.pagination?.rows) {

      preferences[reducerName] = {
        columns: reducerState.columns,
        filtered: reducerState.filtered,
        pagination: {
          offset: 0,
          rows: reducerState.pagination.rows,
        }
      }
    }
  })

  return JSON.stringify(preferences)
}

export const deserializePreferences = (raw) => {
  try {
    return JSON.parse(raw)
  }
  catch (err) {
    console.log('Error parsing user preferences:', err.message)
    return {}
  }
}
