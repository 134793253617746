import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, useTheme } from '@material-ui/styles'
import { Alert, SingleSelect, SearchDropdown, EmailTextField, RowValueText } from 'frame/components'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { types } from '../../module'
import { Button, Card, CardContent, Grid, Typography } from '@material-ui/core'
import { useSelectedFleets, useSelectedFleetIds } from 'features/settings'
import { lookupRequest } from 'utils'


const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3),
  },
  error: {
    marginBottom: theme.spacing(4),
  },
  content: {
    padding: theme.spacing(3, 1.2, 3, 1.2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
  },
  fieldHeader: {
    marginTop: theme.spacing(5),
  },
  fieldGroup: {
    margin: theme.spacing(3, 0, 2, 0),
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    }
  },
  button: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    minWidth: 180,
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
    }
  },
}))

const Form = ({ error, values, setValues, disabled, onClose, onSubmit, setShowAssignForm }) => {

  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'))
  const classes = useStyles()
  const form = useRef()
  const userSelectedFleets = useSelectedFleets()
  const fleetIds = useSelectedFleetIds()

  const [isCreateAndAssign, setIsCreateAndAssign] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [invalidFleetError, setShowInvalidFleetError] = useState(false)
  const [loaded, setLoaded] = useState(false)


  const fleetOptions = userSelectedFleets.map(item => ({
    label: item.label,
    value: item.label,
  }))
  
  const onFleetChange = (fleetName) => {
    const fleet = userSelectedFleets.filter(f => f.label === fleetName)[0]
    const thirdPartyNumber = fleet ? fleet.value : null
    // console.log('[onFleetChange] ', { fleetName, thirdPartyNumber })
    setValues({
      ...values,
      thirdPartyNumber: thirdPartyNumber,
      accountName: fleetName,
    })
  }

  const onInputChange = ({ target: { id, name, value } }) =>
    setValues({ ...values, [id || name]: value })

  const onSelectChange = (key) => (value) =>
    setValues({ ...values, [key]: value })

  const onSubmitForm = () =>
    isCreateAndAssign
      ? validAccountName && setShowAssignForm(true)
      : validAccountName && onSubmit({ ...values, driver: values.driver.trim() })

  const onCreate = () => {
    if(form.current) {
      setSubmitting(true)
      form.current.submit()
    }
  }

  const onCreateAndAssignVehicle = () => {
    if (form.current) {
      validAccountName && setIsCreateAndAssign(true)
      setSubmitting(true)
      form.current.submit()  // using onSubmitForm to proceed only when mandatory fields are filled
    }
  }
  
  useEffect(() => {
    ValidatorForm.addValidationRule('isNotSpaces', (value) => {
      let cleanedValue = value.replace(/ /g, '')
      return cleanedValue && cleanedValue.length > 0
    })
    return () => {
      ValidatorForm.removeValidationRule('isNotSpaces')
    }
  }, [values.driver])
  
  const validAccountName = values.accountName && values.accountName.length > 0
  
  useEffect(() => {
    loaded && setShowInvalidFleetError(!validAccountName)
    setLoaded(true)// eslint-disable-next-line 
  }, [validAccountName])

  useEffect(() => {
    if(!validAccountName && submitting) {
      setSubmitting(false)
      setShowInvalidFleetError(true)
    }// eslint-disable-next-line 
  }, [submitting])
        
  useEffect(() => {
    async function fetchAndsetThirdPartyNumber() {
      if(userSelectedFleets.length === 1) {
        const data = await lookupRequest(fleetIds, '/lookup/fleetId', userSelectedFleets[0].label)
        console.log('[Add Driver on load useEffect] ', data)
        data && setValues({
          ...values,
          thirdPartyNumber: data.value || '-',
        })
      }
    }
    fetchAndsetThirdPartyNumber() // eslint-disable-next-line
  }, [])

  return (
    <ValidatorForm autoComplete="off" noValidate ref={form} onSubmit={onSubmitForm}>
      <Card>
        <CardContent className={classes.content}>
          {error && (
            <Alert className={classes.error}>
              Something went wrong whilst trying to save this driver.
            </Alert>
          )}

          <Typography gutterBottom variant="h4">
            New Driver Details
          </Typography>

          <div className={classes.fieldGroup}>
            <Grid container spacing={2}>             

              <Grid item xs={12}>
                <RowValueText label="Fleet" responsive>
                  {userSelectedFleets.length === 1 && values.accountName ? (  // disable required if user has only 1 fleet assigned
                    <TextValidator
                      size="small"
                      id="disabledFleet"
                      disabled={true}
                      fullWidth
                      label={mobileView ? 'Fleet' : ''}
                      InputLabelProps={{ shrink: mobileView }} // to show label only on mobile
                      value={values.accountName}
                      variant="outlined"
                    />
                  ) : (
                    <SearchDropdown
                      id="fleet"
                      size="small"
                      label={'Fleet'}
                      hideLabelOnMobile={!mobileView} // to show label only on mobile
                      showError={invalidFleetError}
                      disabled={disabled}
                      options={fleetOptions}
                      value={values.accountName || ''}
                      onChange={onFleetChange}
                    />
                  )}
                </RowValueText>
              </Grid>

              <Grid item xs={12}>
                <RowValueText label="Name" responsive>
                  <TextValidator
                    fullWidth
                    size="small"
                    id="driver"
                    label={mobileView ? 'Name' : ''}
                    InputLabelProps={{ shrink: mobileView }} // to show label only on mobile
                    className={classes.field}
                    disabled={disabled}
                    value={values.driver || ''}
                    inputProps={{ maxLength: 50 }}
                    variant="outlined"
                    validators={['required', 'isNotSpaces']}
                    errorMessages={['This field is required', 'Please enter a valid name']}
                    onChange={onInputChange} />
                </RowValueText>
              </Grid>

              <Grid item xs={12}>
                <RowValueText label="Email" responsive>
                  <EmailTextField
                    fullWidth
                    size="small"
                    id="email"
                    label={mobileView ? 'Email' : ''}
                    InputLabelProps={{ shrink: mobileView }} // to show label only on mobile
                    disabled={disabled}
                    inputProps={{ maxLength: 50 }}
                    value={values.email || ''}
                    variant="outlined"
                    onChange={onSelectChange('email')}
                  />
                </RowValueText>
              </Grid>

              <Grid item xs={12}>
                <RowValueText label="Mobile" responsive>
                  <TextValidator
                    fullWidth
                    id="mobile"
                    size="small"
                    label={mobileView ? 'Mobile' : ''}
                    InputLabelProps={{ shrink: mobileView }} // to show label only on mobile
                    className={classes.field}
                    disabled={disabled}
                    value={values.mobile || ''}
                    inputProps={{ maxLength: 50 }}
                    validators={['isNumber']}
                    errorMessages={['A valid phone number is required']}
                    variant="outlined"
                    onChange={onInputChange}
                  />
                </RowValueText>
              </Grid>

              <Grid item xs={12}>
                <RowValueText label="Notification Method" responsive>
                  <SingleSelect
                    noNone
                    size="small"
                    id="driverNotification"
                    disabled={disabled}
                    label="Notification Method"
                    hideLabelOnMobile={!mobileView} // to show label only on mobile
                    options={['Email', 'Text', 'In App', 'N/A']}
                    value={values.driverNotification}
                    onChange={onSelectChange('driverNotification')}
                  />
                </RowValueText>
              </Grid>

            </Grid>
          </div>

        </CardContent>
      </Card>

      <div className={classes.actions}>
        <Button className={classes.button} color="primary" disabled={disabled} variant="contained" onClick={onCreate}>
          Create
        </Button>
        <Button className={classes.button} disabled={disabled} variant="contained" onClick={onCreateAndAssignVehicle}> 
          Create and Assign Vehicle
        </Button>
        <Button className={classes.button} disabled={disabled} variant="contained" onClick={onClose}>
          Cancel
        </Button>
      </div>
    </ValidatorForm>
  )
}

Form.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  values: PropTypes.oneOfType([
    PropTypes.object,
    types.ModelType,
  ]).isRequired,
  setValues: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  setShowAssignForm: PropTypes.func,
}

export default Form
