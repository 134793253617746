import { combineEpics } from 'redux-observable'
import { createListable } from 'listable'
import { ADMIN_PATH } from 'features/admin'
import options from './config'
import * as fromActions from './actions'
import * as fromConstants from './constants'
import { createEffects } from './effects'
import customReducer from './reducer'


const listableModule = createListable({
  name: fromConstants.MODULE_NAME,
  config: options,
  customActions: fromActions,
  customConstants: fromConstants,
  customReducer,
})

export const config = listableModule.options
export const actions = listableModule.actions
export const reducer = listableModule.reducer
export const types = listableModule.types

export const effects = combineEpics(
  createEffects(listableModule.actions),
  listableModule.effects,
)

export const constants = {
  ...listableModule.constants,
  PATH_ALL: `${ADMIN_PATH}/feature-toggles`,
  // PATH_ADD: `${ADMIN_PATH}/users/add`,
  // PATH_SINGLE: `${ADMIN_PATH}/users`,
  ROUTE_ALL: `${ADMIN_PATH}/feature-toggles`,
  // ROUTE_ADD: `${ADMIN_PATH}/users/add`,
  // ROUTE_SINGLE: `${ADMIN_PATH}/users/:id`,
}

