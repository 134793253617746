import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import { Layout } from 'frame'
import { Page, PageHeader } from 'frame/components'
import { GraphEndOfLease } from 'features/graph-end-of-lease'
import { GraphKmTracking } from 'features/graph-km-tracking'
import { GraphMaturing } from 'features/graph-maturing'
import { GraphOnOrder } from 'features/graph-on-order'
import { GraphServiceExpiry } from 'features/graph-service-expiry'
import { GraphWofExpiry } from 'features/graph-wof-expiry'
import { GraphVehicleOnExtension } from 'features/graph-vehicle-on-extension'


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  container: {
    position: 'relative',
    '& > *': {
      height: '100%'
    }
  },
}))

const Dashboard = () => {

  const classes = useStyles()

  return (
    <Layout backgroundImage="url('/static/images/backgrounds/bg1.png')">
      <Page className={classes.root} title="Dashboard">
        <PageHeader title="Dashboard Overview" />
        
        <Grid className={classes.container} container spacing={3}>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <GraphMaturing />
          </Grid>
          
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <GraphOnOrder />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={4}>
            <GraphWofExpiry />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={4}>
            <GraphEndOfLease />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={4}>
            <GraphKmTracking />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={4}>
            <GraphServiceExpiry />
          </Grid>
          
          <Grid item xs={12}>
            <GraphVehicleOnExtension />
          </Grid>
        </Grid>
      </Page>
    </Layout>
  )
}

export default Dashboard
