import * as types from './constants'
import * as R from 'ramda'


const reducer = (state, action) => {
  switch (action.type) {
    // case types.REQUEST_FEATURE_TOGGLE_LIST:
    // case types.REQUEST_FEATURE_TOGGLE_LIST_ERROR:
    case types.REQUEST_FEATURE_TOGGLE_LIST_FULFILLED:
      return requestFeatureToggleListSucceeded(R.clone(state), action.payload)
    case types.ENABLE_FEATURE_FULFILLED:
    case types.DISABLE_FEATURE_FULFILLED:
      return toggleFeatureTogglesSucceeded(R.clone(state), action.payload)

    case types.ENABLE_FEATURE:
      return onEnableFeatureToggle(R.clone(state), action.options)
    default:
      return state
  }
}

export default reducer

const requestFeatureToggleListSucceeded = (state, payload) => {
  let data = {}
  payload && payload.forEach((node) => {
    data = { ...data, [node.id]: node }
  })

  state.data = data

  state.enabledFeatures = payload
    ? payload.map(node => node.active && node.feature).filter(x => x)
    : []

  return state
}

const toggleFeatureTogglesSucceeded = (state, payload) => {
  let data = {}
  payload && payload.forEach((node) => {
    data = { ...data, [node.id]: node }
  })
  // console.log('[enabledFeatureTogglesSucceeded]: ', { payload, data })
  state.data = data

  state.enabledFeatures = payload
    ? payload.map(node => node.active && node.feature).filter(x => x)
    : []

  return state
}

// to immediately update the FBTNotify UI on enabling any of the FBTNotify toggles
const onEnableFeatureToggle = (state, options) => {
  
  const enabledFeature = options?.queryParams?.feature
  if(enabledFeature.includes('FBTNotify')) {
    state.activeFBTNotifyToggle = enabledFeature
  }
  return state
}