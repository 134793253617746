import React, { useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { editDraft, deleteDraft, newDraft, editSave, addFbtSubmission } from '../actions'
import { constants } from '../module'
import { useNavigate, useRouter, useFbtWebForm } from 'utils'
import { Button, Grid, Card, CardContent, Typography } from '@material-ui/core'
import { StyledModal, ScrollToTop } from 'frame/components'
import { useProfile } from 'features/account'
import moment from 'moment'

// components
import FbtSubmissionsTable from './fbt-submissions-table'
import { useEnabledFeatureToggleList } from 'features/settings'
import { hasRoles } from 'acl'


const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3),
  },
  error: {
    marginBottom: theme.spacing(4),
  },
  content: {
    padding: theme.spacing(3, 1.2, 3, 1.2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
      minHeight: 'min(60vh, 600px)',
    },
  },
  fieldHeader: {
    marginTop: theme.spacing(5),
  },
  fieldGroup: {
    margin: theme.spacing(3, 0),
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    }
  },
  button: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    minWidth: 180,
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
    }
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  roundedButton: {
    borderRadius: '25px',
  },
  contentContainer: {
    paddingTop: '0px',
    paddingBottom: theme.spacing(3),
  },
}))

// used in add new fbt driver record modal, edit fbt row entry, fbt web form
const AddEditSubmission = ({
  id,
  data,
  flags,
  drafts,
  newSubmissionDetails,
  filtered,
  fbtWebForm,
  userRoles,
  // func
  onEditDraft,
  onDeleteDraft,
  onNewDraft,
  onEditSave,
  onAddFbtSubmission,
}) => {

  const profile = useProfile()
  const classes = useStyles()
  
  const navigate = useNavigate()
  const { location, history } = useRouter()
  const { onSubmitFbtWebForm } = useFbtWebForm()
  
  const isAddForm = window.location.pathname.includes('/add/')
  const isWebForm = window.location.pathname.includes('fbt-web-form')

  const featureToggleList = useEnabledFeatureToggleList()
  const userPermissionsFeatureToggle = featureToggleList.includes('UserPermissions')
  const showButtons = isWebForm 
    ? true // never hide on web form (!)
    : userPermissionsFeatureToggle ? userPermissionsFeatureToggle && hasRoles(['superadmin','fleetcontroller'], userRoles) : true

  // model states
  const [showConfirmSubmitModal, setShowConfirmSubmitModal] = useState(false)
  const [openUnsavedChangesModal, setOpenUnsavedChangesModal] = useState(false)
  
  const disabledSave = isAddForm || isWebForm
    ? !drafts || drafts.length === 0
    : flags.fetch === 'processing' || !flags?.unsavedChanges

  const onClose = () => {
    const isFiltered = filtered?.year && filtered?.month
    navigate(`${constants.PATH_ALL}${isFiltered ? `?year=${filtered?.year}&month=${filtered?.month}`: ''}`)()
  }

  const onAdd = () => {
    onNewDraft()
    navigate(isWebForm ? `/fbt-web-form/form/add-submission` : `${constants.PATH_ALL}/${id}${constants.PATH_ADD_SUBMISSION}`)()
  }

  const onSave = (deleteLastingEntry = false) => {
    const entries = deleteLastingEntry ? [] : drafts.map(item => ({
      // remove uuids that are used for FE CRUD purposes only - api will assign new ids for new entries automatically
      id: Number.isInteger(item?.id) ? item?.id : 0,
      daysOfMonth: item?.days.join(','),
      reason: item?.reason
    }))

    const month = filtered?.month ? moment().month(filtered?.month).format('M') : moment().format('M')
    const year = filtered?.year || moment().format('YYYY')

    if(isAddForm) {
      const payload = {
        driverName: newSubmissionDetails?.driverName,
        costCentre: newSubmissionDetails?.costCentre,
        thirdPartyNumber: newSubmissionDetails?.thirdPartyNumber,
        availableForPvtUseAllMonth: false,
        entries, month, year
      }
      console.log('[onAddFbtSubmission]: ', payload)
      onAddFbtSubmission(payload)

    } else if (isWebForm) {
      const payload = {
        email: fbtWebForm?.email,
        token: fbtWebForm?.token,
        availableForPvtUseAllMonth: false,
        entries
      }
      console.log('[onSubmitFbtWebForm]: ', payload)
      onSubmitFbtWebForm(payload)
      
    } else {
      console.log('[onEditSave]: ', { ...data, entries })
      onEditSave(data?.originalId, { ...data, entries, month, year })
    }
    setShowConfirmSubmitModal(false)
  }

  const onEditHandler = (selectedSubmission) => {
    onEditDraft(selectedSubmission)
    history.push(location.pathname.replace('view-submissions', 'edit-submission'))
  }
  
  return (
    <>
      <ScrollToTop />
      <Card elevation={isWebForm ? 0 : 1}>
        <CardContent className={classes.content}>
          <Grid container spacing={3} className={classes.contentContainer}>
 
            <Grid item xs={12}>
              <Grid alignItems="flex-end" container justify="space-between" spacing={3}>
                <Grid item>
                  <Typography variant="h4">
                    Submissions
                  </Typography>
                </Grid>

                <Grid item>
                  {showButtons && (
                    <Button color="primary" variant="contained" id="add-fbt" className={classes.button} onClick={onAdd}>
                      Add
                    </Button>
                   )}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <FbtSubmissionsTable
                data={drafts}
                loading={flags.fetch === 'processing'}
                onEdit={onEditHandler}
                onDelete={onDeleteDraft}
                onSave={isAddForm || isWebForm ? null : onSave}
                userRoles={userRoles}
              />
            </Grid>
          </Grid>

        </CardContent>
      </Card>

      <div className={classes.actions}>
        {showButtons && 
          <Button
            className={classes.button}
            color="primary"
            variant="contained"
            disabled={disabledSave}
            onClick={() => setShowConfirmSubmitModal(true)}
            >
            {isAddForm || isWebForm ? 'Submit' : 'Save'}
          </Button>
        }
        {isWebForm ? (
          <Button
            className={classes.button}
            variant="contained"
            onClick={navigate(`/fbt-web-form/availability`)}
          >
            Back
          </Button>
        ) : (
          <Button
            className={classes.button}
            variant="contained"
            onClick={() => flags?.unsavedChanges ? setOpenUnsavedChangesModal(true) : onClose()}
          >
            Close
          </Button>
        )}
      </div>

      {/* Confirmation Modal */}
      <StyledModal
        open={showConfirmSubmitModal}
        title={`Confirm Submission`}
        onCancel={() => setShowConfirmSubmitModal(false)}
        onCancelLabel="Go Back"
        onConfirm={onSave}>
        {isWebForm
          ? `Please confirm to submit your FBT submission`
          : isAddForm
            ? `Please confirm you have completed ${newSubmissionDetails?.driverName}'s FBT submission`
            : `Please confirm to save your changes to ${data?.driverName}'s FBT submission`
        }
      </StyledModal>

      {/* unsave changes prompt */}
      <StyledModal
        open={openUnsavedChangesModal}
        title={`Confirm Close`}
        onCancel={() => setOpenUnsavedChangesModal(false)}
        onConfirm={onClose}>
        Any changes will not be saved. Are you sure you want to continue?
      </StyledModal>
    </>
  )
}

AddEditSubmission.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  data: PropTypes.object,
  flags: PropTypes.object,
  drafts: PropTypes.array,
  newSubmissionDetails: PropTypes.object,
  filtered: PropTypes.object,
  fbtWebForm: PropTypes.object,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  // func
  onNewDraft: PropTypes.func.isRequired,
  onEditDraft: PropTypes.func.isRequired,
  onDeleteDraft: PropTypes.func.isRequired,
  onEditSave: PropTypes.func.isRequired,
  onAddFbtSubmission: PropTypes.func.isRequired,
}


const mapStateToProps = ({ fbtSubmissions,settings }, ownProps) => ({
  id: ownProps.match.params?.id,
  data: fbtSubmissions?.fbtDetails, //fbtSubmissions?.data[ownProps.match.params?.id],
  flags: {
    ...fbtSubmissions.flags,
    unsavedChanges: JSON.stringify(fbtSubmissions?.originalDrafts) !== JSON.stringify(fbtSubmissions?.drafts),
  },
  drafts: fbtSubmissions?.drafts,
  newSubmissionDetails: fbtSubmissions?.newSubmissionDetails,
  filtered: fbtSubmissions?.filtered,
  fbtWebForm: fbtSubmissions?.fbtWebForm,
  userRoles: settings.roles,
})

const mapDispatchToProps = (dispatch) => ({
  onNewDraft: () => {
    dispatch(newDraft())
  },
  onEditDraft: (payload) => {
    dispatch(editDraft(payload))
  },
  onDeleteDraft: (payload) => {
    dispatch(deleteDraft(payload))
  },
  onEditSave: (id, payload) => {  // for edit form save button
    dispatch(editSave(payload, {
      resourceId: id
    }))
  },
  onAddFbtSubmission: (payload) => {
    dispatch(addFbtSubmission(payload))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(AddEditSubmission)
