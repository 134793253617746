import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { TableCell, TableRow } from '@material-ui/core'
import { types } from '../../module'
import { toCurrency } from 'utils'
import moment from 'moment'


const useStyles = makeStyles(theme => ({
  root: { verticalAlign: 'text-top' },
  label: theme.typography.bodySmall,
  spacer: { margin: 0, padding: 0 },
}))

const Row = ({ item, columns }) => {

  const classes = useStyles()

  return (
    <TableRow hover className={classes.root}>
      <TableCell className={classes.spacer} />

      {columns.startDate && (
        <TableCell className={classes.label}>
          {item.startDate ? moment(item.startDate).format('DD/MM/YY') : '-'}
        </TableCell>
      )}
      {columns.endDate && (
        <TableCell className={classes.label}>
          {item.endDate ? moment(item.endDate).format('DD/MM/YY') : '-'}
        </TableCell>
      )}
      {columns.vehicleRegistration && (
        <TableCell className={classes.label}>
          {item.vehicleRegistration || '-'}
        </TableCell>
      )}      
      {columns.vehicleYear && (
        <TableCell className={classes.label}>
          {item.vehicleYear || '-'}
        </TableCell>
      )}
      {columns.makeModel && (
        <TableCell className={classes.label}>
          {item.makeModel || '-'}          
        </TableCell>
      )}
      {columns.costPrice && (
        <TableCell className={classes.label}>
          {item.costPrice ? toCurrency(item.costPrice) : '-'}
        </TableCell>
      )}      
      {columns.totalDaysVehicleAvailable && (
        <TableCell className={classes.label}>
          {item.totalDaysVehicleAvailable === null ? '-' : item.totalDaysVehicleAvailable}
        </TableCell>
      )}
      {columns.daysAvailableForPrivateUse && (
        <TableCell className={classes.label}>
          {item.daysAvailableForPrivateUse === null ? '-' : item.daysAvailableForPrivateUse}
        </TableCell>
      )}
      {columns.exemptDays && (
        <TableCell className={classes.label}>
          {item.exemptDays === null ? '-' : item.exemptDays}
        </TableCell>
      )}
      {columns.fbtValueOfBenefit && (
        <TableCell className={classes.label}>
          {item.fbtValueOfBenefit ? toCurrency(item.fbtValueOfBenefit) : '-'}
        </TableCell>
      )}
      {columns.gstPortion && (
        <TableCell className={classes.label}>
          {item.gstPortion ? toCurrency(item.gstPortion) : '-'}
        </TableCell>
      )}
      {columns.fbtPayableExclGst && (
        <TableCell className={classes.label}>
          {item.fbtPayableExclGst ? toCurrency(item.fbtPayableExclGst) : '-'}
        </TableCell>
      )}
      {columns.costCentre && (
        <TableCell className={classes.label}>
          {item.costCentre || '-'}
        </TableCell>
      )}
      {columns.driverName && (
        <TableCell className={classes.label}>
          {item.driverName || '-'}
        </TableCell>
      )}
      {columns.driverEmail && (
        <TableCell className={classes.label}>
          {item.driverEmail || '-'}
        </TableCell>
      )}
      {columns.driverMobile && (
        <TableCell className={classes.label}>
          {item.driverMobile || '-'}
        </TableCell>
      )}
      {/* {columns.quarter && (
        <TableCell className={classes.label}>
          {item.quarter || '-'}
        </TableCell>
      )} */}
      <TableCell className={classes.spacer} />
    </TableRow>
  )
}

Row.propTypes = {
  item: types.ModelType.isRequired,
  columns: types.ColumnsType.isRequired,
}

export default Row
