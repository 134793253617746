import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, useTheme } from '@material-ui/styles'
import { Typography, Grid, Button } from '@material-ui/core'
import CountDown from './countdown'
import moment from 'moment'
import RegistrationModal from './registration-modal'
import ReactGA from 'react-ga'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'
import ModalVideo from 'react-modal-video'
import 'react-modal-video/scss/modal-video.scss'


const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    minHeight: '100vh',
    maxHeight: 937,
    backgroundImage: 'url("/static/images/marketing/desktop_background.png")',
    backgroundSize: 'cover',
    backgroundPosition: 'center top',
    backgroundRepeat: 'no-repeat',
    overflow: 'hidden',
    padding: theme.spacing(40, 0, 40, 20), 
    [theme.breakpoints.down('md')]: { padding: theme.spacing(20, 0) },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(12, 0, 30, 0),
      backgroundPosition: '51% 100%',
      backgroundColor: '#002849',
      backgroundImage: 'none',
    }
  },
  logo: {
    width: 400,
    height: 'auto',
    position: 'absolute',
    left: 30,
    top: 50,
    [theme.breakpoints.up('lg')]: { left: 150 },
    [theme.breakpoints.down('lg')]: { left: 150 },
    [theme.breakpoints.down('md')]: { left: 30, top: 20 },
    [theme.breakpoints.down('sm')]: {
      width: 200,
      top: 20,
      left: '50%',
      transform: 'translate(-50%, 0)',
    }
  },
  content: {
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(0, -5, 0, 5),
    },
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0, 2),
      textAlign: 'center',
    }
  },
  heading: {
    color: '#DDDDDD',
    fontSize: '60px',
    fontFamily: 'ProximaNova-Bold',
    lineHeight: '73px',
    [theme.breakpoints.down('md')]: { fontSize: '42px' },
    [theme.breakpoints.down('sm')]: {
      fontSize: '42px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '32px',
      lineHeight: '40px',
    }
  }, 
  subHeading: {
    fontSize: '28px',
    fontFamily: 'ProximaNova-Regular',
    lineHeight: '40px',
    color: '#98B6BE',
    marginTop: theme.spacing(5),
    [theme.breakpoints.down('md')]: { fontSize: '20px' },
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
      lineHeight: '20px',
      marginTop: theme.spacing(2),
      textAlign: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
      lineHeight: '20px',
      marginTop: theme.spacing(2),
      textAlign: 'center',
    }
  },
  registerInterestButton: {
    margin: theme.spacing(6, 0, 2, 0),
    fontFamily: 'ProximaNova-SemiBold',
    width: '380px',
    height: '48px',
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(2, 0),
      width: '100%',
    }
  },
  videoButtonContainer: {
    display: 'flex',
    flexFlow: 'row wrap',
    marginTop: theme.spacing(2),
    [theme.breakpoints.down(960)]: {
      position: 'relative',
      left: '2%',
    },
    [theme.breakpoints.down(875)]: {
      position: 'relative',
      left: '20%',
    },
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(1, 0),
      position: 'relative',
      left: '10%',
    }
  },
  videoItem: {
    display: 'flex',
    marginTop: theme.spacing(2),
  },
  videoThumbnail: {
    cursor: 'pointer',
    display: 'block',
    width: 'auto',
    height: '60px',
    [theme.breakpoints.down('xs')]: {
      height: '40px',
    },
  },
  playVideoButton: {
    color: '#FFF',
    textTransform: 'none',
    fontSize: '18px',
    fontFamily: 'ProximaNova-Regular',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
  },
  playVideoIcon: {
    color: '#FFF',
    width: 25,
    height: 25,
  },
  countdownContainer: {
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.spacing(-10),
      marginTop: theme.spacing(-8),
    },
    [theme.breakpoints.down('lg')]: {
      marginLeft: theme.spacing(-10),
      marginTop: theme.spacing(-6),
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(0),
      marginTop: theme.spacing(-2),
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
      width: '100%',
      height: '200px',
      borderRadius: '100% 100% 0 0',
      backgroundColor: '#001324',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(2),
    },
  },
  countdown: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(3),
      position: 'absolute',
      left: '50%',
      transform: 'translate(-50%, 0)',
    },
  },
  countdownText: {
    paddingTop: 20,
    textAlign: 'center',
    color: '#DDDDDD',
    fontSize: '13px',
    fontFamily: 'ProximaNova-Regular',
    letterSpacing: '0.37px',
    [theme.breakpoints.up('md')]: {
      textAlign: 'center',
      paddingLeft: 0,
    },
    [theme.breakpoints.down('md')]: {
      textAlign: 'left',
      paddingLeft: theme.spacing(10),
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      paddingLeft: 0,
      marginTop: theme.spacing(16),
    },
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      paddingLeft: 0,
      marginTop: theme.spacing(10),
    }, 
  },
  carImageContainer: {
    backgroundColor: 'transparent',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: '#001324',
      height: 500,
      justifyContent: 'center',
    }
  },
  carImage: {
    height: 'auto',
    backgroundColor: 'transparent',
    position: 'relative',
    left: '50%',
    transform: 'translate(-50%, 0)',
    [theme.breakpoints.up('lg')]: {
      marginTop: props => props.countDownExpired ? -50 : 0,
      width: props => props.countDownExpired ? '700px' : '500px',
    },
    [theme.breakpoints.down('lg')]: {
      width: props => props.countDownExpired ? '600px' : '400px',
    },
    [theme.breakpoints.down('md')]: {
      width: props => props.countDownExpired ? '400px' : '300px',
    },
    [theme.breakpoints.down('sm')]: { width: '300px' },
  },
}))

const portalYoutubeId = '9L9AATQ7deQ'
const myDriveAppYoutubeId = 'wh6imXKgnDU'

const countDownExpiryTimestamp = '2021-06-01 12:00'

const RegistrationOfInterestPage = ({ showForm }) => {

  const countDownExpired = moment() >= moment(countDownExpiryTimestamp, 'YYYY-MM-DD H:mm')

  const theme = useTheme()
  const classes = useStyles({ countDownExpired })
  const mdAndAbove = useMediaQuery(theme.breakpoints.up('md'))
  
  const [showVideoId, setShowVideoId] = useState(null)
  const [showRegistrationModal, setShowRegistrationModal] = useState(false)
  const [submittedRegistration, setSubmittedRegistration] = useState(false)
  const [showCountDown, setShowCountDown] = useState(true)

  useEffect(() => {
    ReactGA.event({ category: 'RegistrationOfInterestPage', action: 'View Page', label: 'Registration Of Interest Page' })
  }, [])

  return (
    <div className={classes.root}>
      <img className={classes.logo} src="/static/images/logo-light.png" alt="logo" />      
      
      <Grid container>   
        <Grid lg={7} md={7} sm={12} xs={12} item className={classes.content}>          
          
          <Typography className={classes.heading} variant="h1">
            A NEW ERA OF{mdAndAbove ? <br /> : ' '}FLEET MANAGEMENT
          </Typography>
          <Typography className={classes.subHeading} variant="h3">
            Enjoy the benefits and efficiencies<br />of managing your fleet online
          </Typography>

          {!submittedRegistration && showForm && (
            <Button
              variant="contained"
              color="primary"
              className={classes.registerInterestButton}
              onClick={() => setShowRegistrationModal(true)}
            >
              REGISTER YOUR INTEREST
            </Button>
          )}

          <div className={classes.videoButtonContainer}>
            <div className={classes.videoItem}>
              <img
                className={classes.videoThumbnail}
                src="/static/images/marketing/video-thumbnail-portal.jpg" // get youtube thumbnail https://img.youtube.com/vi/${youtubeId}/0.jpg
                alt="Video Thumbnail"
                onClick={() => setShowVideoId(portalYoutubeId)}
              />
              
              <Button
                size="small"
                className={classes.playVideoButton}
                startIcon={<PlayCircleOutlineIcon className={classes.playVideoIcon} />}
                onClick={() => setShowVideoId(portalYoutubeId)}
              >
                View ToyotaFleet Portal Video
              </Button>              
            </div>

            <div className={classes.videoItem}>
              <img
                className={classes.videoThumbnail}
                src="/static/images/marketing/video-thumbnail-app.jpg" // get youtube thumbnail https://img.youtube.com/vi/${youtubeId}/0.jpg
                alt="Video Thumbnail"
                onClick={() => setShowVideoId(myDriveAppYoutubeId)}
              />
              
              <Button
                size="small"
                className={classes.playVideoButton}
                startIcon={<PlayCircleOutlineIcon className={classes.playVideoIcon} />}
                onClick={() => setShowVideoId(myDriveAppYoutubeId)}
              >
                View Toyota MyDrive Video
              </Button>              
            </div>

          </div>

        </Grid>

        <Grid lg={5} md={5} sm={12} xs={12} item className={classes.countdownContainer}>
          {!countDownExpired && showCountDown && (
            <div className={classes.countdown}>
              <CountDown expiryTimestamp={moment(countDownExpiryTimestamp, 'YYYY-MM-DD H:mm')} onExpire={() => setShowCountDown(false)}/>
            </div>
           )}

          <Typography variant="body2" className={classes.countdownText}>
            {!countDownExpired && showCountDown && <span style={{ opacity: 0.5, paddingRight: 5 }}>Countdown ends at</span>}
            {!countDownExpired && showCountDown && <span style={{ color: '#FFF' }}>June 1st, 2021 12 PM</span>}
          </Typography>
          
          <div className={classes.carImageContainer}>
            <img className={classes.carImage} src="/static/images/marketing/car.png" alt="car"/>
          </div>
        </Grid>
      </Grid>

      {/* Registration Modal */}      
      {showRegistrationModal && showForm && (
        <RegistrationModal
          open={showRegistrationModal}
          onClose={() => setShowRegistrationModal(false)}
          onHideRegisterButton={() => setSubmittedRegistration(true)}
        />
      )}

      {/* Video Modal https://www.npmjs.com/package/react-modal-video */}
      {!!showVideoId && (
        <ModalVideo
          // autoplay
          controls={0}
          showinfo={0}
          channel="youtube"
          isOpen={!!showVideoId}
          videoId={showVideoId}
          onClose={() => setShowVideoId(null)}
        />
      )}
    </div>
  )
}

RegistrationOfInterestPage.propTypes = {
  showForm: PropTypes.bool,
}

export default RegistrationOfInterestPage