import { combineEpics, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { debounceTime, map, mergeMap, switchMap } from 'rxjs/operators'
import * as fromAuthTypes from 'auth'
import { makeActionRequestEffect, makeDownloadRequestEffect } from 'effects'
import join from 'url-join'
import { getAppConfig } from 'app-config'
import { serializePreferences } from '../utils/preferences'
import { makeListRequest } from '../utils/makeListRequest'
import * as fromFeatureToggleActions from 'features/feature-toggle/actions'


export const createEffects = (moduleName, moduleTypes, moduleActions, config) => {

  const appConfig = getAppConfig()

  /*
   * Reset and removes data from redux upon user logout.
   */
  const resetEffect = action$ =>
    action$.pipe(
      ofType(fromAuthTypes.AUTHORIZATION_LOGOUT_FULFILLED),
      map(action => moduleActions.resetDefaults()),
    )

  /*
   * Changes the currently available filters.abs
   */
  const changeFiltersEffect = (action$, state$) =>
    action$.pipe(
      ofType(moduleTypes.CHANGE_FILTERS),
      debounceTime(200),
      map(action => moduleActions.updateFilters(action.payload)),
    )

  /*
   * Changes the search key with a little debounce and then
  * calls a corresponding action.
  */
  const changeSearchKeyEffect = (action$, state$) =>
    action$.pipe(
      ofType(moduleTypes.CHANGE_SEARCH_KEY),
      debounceTime(200),
      switchMap(action => of(moduleActions.updateSearchKey(action.payload))),
    )

  /*
   * Requests the available filters for a module from the API.
   */
  const requestFiltersEffect = makeActionRequestEffect({
    type: moduleTypes.REQUEST_FILTERS,
    url: join(appConfig.app.api, config?.api?.filter || ''),
    onSuccess: moduleActions.requestFiltersFulfilled,
    onError: moduleActions.requestFiltersError,
  })

  /*
   * Add the fetched filters to the module state once successfully retrieved.
   */
  const addFleetFiltersEffect = (action$, state$) =>
    action$.pipe(
      ofType(moduleTypes.REQUEST_FILTERS_FULFILLED),
      map(action => moduleActions.addFleetFilters(action.payload)),
    )

  /*
   * Fetches a single resource (row) making use of a utility method that
   * handles authentication and callback management.
   */
  const requestResourceEffect = makeActionRequestEffect({
    type: moduleTypes.REQUEST_RESOURCE,
    url: join(appConfig.app.api, config?.api?.resource || ''),
    onSuccess: moduleActions.requestResourceFulfilled,
    onError: moduleActions.requestResourceError,
    // debug: true,
    // proxy: true,
  })

  /*
   * Creates a new single resource (row) making use of a utility method that
   * handles authentication and callback management.
   */
  const createResourceEffect = makeActionRequestEffect({
    type: moduleTypes.CREATE_RESOURCE,
    url: join(appConfig.app.api, config?.api?.resource || ''),
    verb: 'post',
    onSuccess: moduleActions.createResourceFulfilled,
    onError: moduleActions.createResourceError,
    // debug: true,
    // proxy: true,
  })

  /*
   * Updates a single resource (row) making use of a utility method that
   * handles authentication and callback management.
   */
  const updateResourceEffect = makeActionRequestEffect({
    type: moduleTypes.UPDATE_RESOURCE,
    url: join(appConfig.app.api, config?.api?.resource || ''),
    verb: 'put',
    onSuccess: moduleActions.updateResourceFulfilled,
    onError: moduleActions.updateResourceError,
    // debug: true,
    // proxy: true,
  })

  /*
   * On Create or Update
   * Clear filters and request resources again.
   * The filters must be cleared as the API is using
   * these as cache keys and cannot calculate all possible variations,
   * so we reset it to the simplist form as it can determine those.
   */
  const onSaveOrUpdateEffect = (action$, state$) =>
    action$.pipe(
      ofType(
        moduleTypes.CREATE_RESOURCE_FULFILLED,
        moduleTypes.UPDATE_RESOURCE_FULFILLED
      ),
      mergeMap(() => of(
        moduleActions.refetch(),
      )),
    )

  /*
   * Adds a single or multiple items to the data object within the reducer,
   * typically used when a single item is fetched.
   */
  const addItemsEffect = (action$, state$) =>
    action$.pipe(
      ofType(
        moduleTypes.REQUEST_RESOURCE_FULFILLED,
        moduleTypes.CREATE_RESOURCE_FULFILLED,
        moduleTypes.UPDATE_RESOURCE_FULFILLED
      ),
      map(action => moduleActions.addItems(action.payload)),
    )

  /*
   * Looks up a contract as it corresponds to another resource.
   */
  const onLookupContractEffect = makeActionRequestEffect({
    type: moduleTypes.LOOKUP_RELATED_CONTRACT,
    url: join(appConfig.app.api, '/contract/contract'),
    onSuccess: moduleActions.lookupContractFulfilled,
    onError: moduleActions.lookupContractError,
    // debug: true,
  })

  /*
   * Downloads a report based on the current user settings as a csv file.abs
   */
  const downloadReportEffect = (action$, state$) =>
    action$.pipe(
      ofType(moduleTypes.REQUEST_DOWNLOAD),
      ...makeDownloadRequestEffect({
        state$,
        moduleName,
        url: join(appConfig.app.api, config?.api?.download || ''),
        onSuccess: moduleActions.onDownloadFulfilled,
        onError: moduleActions.onDownloadError,
      }),
    )

  /*
   * When filters, columns or pagingation changes we save these as
   * user preferences. These get loaded upon page refresh or login
   * so that they're available as a consistant experience.
   */
  const onPreferencesChange = (action$, state$) => // CF disabled. See below
    action$.pipe(
      ofType(
        moduleTypes.TOGGLE_COLUMN,
        moduleTypes.TOGGLE_SELECT_ALL_COLUMNS,
        moduleTypes.CLEAR_FILTERS,
        moduleTypes.UPDATE_FILTERS,
        moduleTypes.SET_PAGINATION_ROWS,
      ), 
      mergeMap(action => of(
          // Trigger get latest enabled toggle feature list on request filters
          fromFeatureToggleActions.requestFeatureToggleList(),
          moduleActions.savePreferences(serializePreferences(state$.value)),
        )
      ),
    )

  /*
   * Persists the user perferences triggered by onPreferencesChange above
   */
  const onPreferencesSave = makeActionRequestEffect({
    type: moduleTypes.SAVE_PREFERENCES,
    url: join(appConfig.app.api, '/filterpreference/update'),
    verb: 'put',
    onSuccess: moduleActions.savePreferencesFulfilled,
    onError: moduleActions.savePreferencesError,
  })

  /*
   * This effect is responsible for coordinating when to fetch the main
   * resource list. It creates the appropriate request options, including the
   * queryParams, from the module's state.
   * As such, it gets called whenever a corresponding filter, column
   *  or pagination option changes.
   */
  const onUpdateList = (action$, state$) =>
    action$.pipe(
      ofType(
        moduleTypes.FETCH_LIST,
        moduleTypes.UPDATE_FILTERS,
        moduleTypes.CLEAR_FILTERS,
        moduleTypes.TOGGLE_COLUMN_SORT,
        moduleTypes.SET_PAGINATION_OFFSET,
        moduleTypes.SET_PAGINATION_ROWS,
        moduleTypes.UPDATE_SEARCH_KEY,
        moduleTypes.REFETCH_RESOURCE,
      ),
      // filter(() => // for testing only
      //   moduleName === 'fleet' ? window.location.pathname === '/fleet'
      //     : moduleName === 'drivers' ? window.location.pathname === '/drivers'
      //     : moduleName === 'reportUpcomingMaturities' ? window.location.pathname === '/reports/maturities'
      //     : moduleName === 'reportVehiclesOnOrder' ? window.location.pathname === '/vehicles-on-order'
      //     : moduleName === 'reportVehicleServicing' ? window.location.pathname === '/vehicle-servicing'
      //     : moduleName === 'reportKmTracking' ? window.location.pathname === '/km-tracking'
      //     : moduleName === 'reportWofExpiry' ? window.location.pathname === '/wof-registration'
      //     : moduleName === 'reportExtensionRentals' ? window.location.pathname === '/extension-rentals'
      //     : moduleName === 'reportEndOfLease' ? window.location.pathname === '/end-of-lease'
      //     : moduleName === 'reportIncident' ? window.location.pathname === '/incident-management'
      //     : moduleName === 'requestReliefVehicles' ? window.location.pathname === '/relief-vehicles'
      //     : moduleName === 'requestVehicleRelocations' ? window.location.pathname === '/vehicle-relocations'
      //     : moduleName === 'requestContractVariations' ? window.location.pathname === '/contract-variations'
      //     : moduleName.includes('fbt') ? window.location.pathname.includes('fbt')
      //     : moduleName.includes('admin') ? window.location.pathname.includes('admin')
      //       : false
      // ),
      switchMap(action => {
        const state = state$.value[moduleName]
        const fleetIds = state$.value.settings.fleet.selected

        let options = makeListRequest(state, fleetIds)
        // console.log('[onUpdateList]', { moduleName, type: action.type })
        return of(
          moduleActions.requestList({}, options)
        )
      }),
    )

  /*
  * Requests the list with appropriate query params from the API
  */
  const onRequestList = makeActionRequestEffect({
    type: moduleTypes.REQUEST_LIST,
    url: join(appConfig.app.api, config?.api?.resource || ''),
    onSuccess: moduleActions.requestListFulfilled,
    onError: moduleActions.requestListError,
  })

  
  // refetch filters after refetch to get latest filters when records are updated to new statuses
  const onRefetch = (action$, state$) =>
    action$.pipe(
      ofType(
        moduleTypes.REFETCH_RESOURCE,
      ), 
      mergeMap(() => {
        const fleetId = state$.value.settings?.fleet?.selected || []
        const queryParams = { FleetId: fleetId }

        return of(
          moduleActions.requestFilters(null, { queryParams })
        )
      })
    )
    
  // for debugging on route change
  // const onRouteChange = (action$, state$) =>
  //   action$.pipe(
  //     ofType('@@router/LOCATION_CHANGE'),
  //     tap(action => {  
  //       console.log('@@router/LOCATION_CHANGE', action.payload) // eslint-disable-line
  //     }),
  //     mergeMap(() => EMPTY)
  //   )

  return combineEpics(
    resetEffect,
    changeFiltersEffect,
    changeSearchKeyEffect,
    requestFiltersEffect,
    addFleetFiltersEffect,
    requestResourceEffect,
    createResourceEffect,
    updateResourceEffect,
    addItemsEffect,
    onSaveOrUpdateEffect,
    onLookupContractEffect,
    downloadReportEffect,
    onPreferencesChange,
    onPreferencesSave,
    onUpdateList,
    onRequestList,
    onRefetch,
    // onRouteChange,
  )
}
