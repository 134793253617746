import React, { Fragment, useContext } from "react"
import { Grid, TextField, Typography } from '@material-ui/core'
import { FormCta } from '../form-cta'
import { NewVehicleSummaryContext } from "../../new"
import { FormContext } from "../../form"
import { DriverDetailsContext } from "."

export const ViewAssignDriver = ({ children, onChange }) => {
  const parentContext = useContext(NewVehicleSummaryContext)
  const driverDetailsContext = useContext(DriverDetailsContext);
  const formContext = useContext(FormContext)

  const { searchName } = formContext
  const { setView } = driverDetailsContext

  return (
    <Fragment>
      <Grid item xs={12}>
        <Typography gutterBottom variant="h4">
          Search for an existing driver
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          id='vehicle-summary-driver-search'
          label='Enter Driver Name'
          fullWidth
          variant="outlined"
          value={searchName}
          onChange={(e) => onChange(e.target.value)}
        />
      </Grid>

      <Grid item xs={12}>
        {children}
      </Grid>

      <Fragment>
        <Grid item xs={12}>
          <FormCta
            onProceed={() => setView("additional_details")}
            onBack={() => parentContext.setActiveTab("lease")}
          />
        </Grid>
      </Fragment>
    </Fragment>
  )
}