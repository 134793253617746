import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Snackbar, IconButton } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import CloseIcon from '@material-ui/icons/Close'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    marginTop: '1px',
    height: '10px'
  }
}))

const SnackbarPopup = ({ message, coloured = false, success = false, error = false }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(true)

  const handleClose = (reason) => {
    // clear download status messages
    dispatch({ type: 'request_download_clear_status' })

    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }

  return (
    <>
      {coloured && (success || error) ? (
        <Snackbar
          open={open}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          key="top,center"
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} variant="filled" severity={success ? 'success' :  'error'} >
            <p className={classes.content}>
              {message}
            </p>
          </Alert>
        </Snackbar>
      ) : (
        // Plain snackbar, black background
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={open}
          // autoHideDuration={3000}
          onClose={handleClose}
          message={message}
          action={
            <React.Fragment>
              <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      )}
    </>
  )
}

SnackbarPopup.propTypes = {
  message: PropTypes.string.isRequired,
  coloured: PropTypes.bool,
  success: PropTypes.bool,
  error: PropTypes.bool,
}

export default SnackbarPopup