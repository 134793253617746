import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Item from './item'
import { fetchIncidentDetails, saveIncidentNotes } from '../../actions'


const mapStateToProps = ({ reportIncident }, ownProps) => ({
  id: ownProps.match.params.id,
  data: reportIncident.form,
  flags: reportIncident.flags,
  errorMessage: reportIncident.errorMessage,
})

const mapDispatchToProps = (dispatch) => ({
  onFetchIncidentDetails: (incidentId) => {
    dispatch(fetchIncidentDetails(null, {
      queryParams: { id: incidentId }
    }))
  },
  onSaveIncidentNotes: (incidentId, payload) => {
    dispatch(saveIncidentNotes(payload, {
      resourceId: incidentId
    }))
  },
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Item))
