import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { Add, Edit } from './components'
import { constants } from './module'

export const ChildRoutes = () => (
  <Switch>
    <Route path={constants.ROUTE_ADD} exact component={Add} />
    <Route path={constants.ROUTE_SINGLE} component={Edit} />
  </Switch>
)