import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Alert, SingleSelectValidator, ScrollToButton, StyledModal, AnimatedErrorCross } from 'frame/components'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { getRole } from 'listable'
import { Button, Card, CardContent, Grid, Typography, Checkbox, FormControlLabel } from '@material-ui/core'
import { useSettingsFleetValues } from 'features/settings'


const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3),
  },
  alert: {
    marginBottom: theme.spacing(4),
  },
  content: {
    padding: theme.spacing(3, 1.2, 3, 1.2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
  },
  fieldHeader: {
    marginTop: theme.spacing(5),
  },
  fieldGroup: {
    margin: theme.spacing(3, 0, 2, 0),
  },
  fieldGroupOptions: {
    margin: theme.spacing(3, 2, 0, 2),
  },
  actions: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    }
  },
  button: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    minWidth: 180,
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
    }
  },
  errorText: {
    color: theme.palette.error.main,
    position: 'absolute',
    zIndex: 99
  },
}))

const Form = ({ flags, errorMessage, roles, onSubmit, onClose }) => {

  // refs
  const form = useRef()
  const saveButtonRef = useRef()

  const classes = useStyles()
  const fleets = useSettingsFleetValues()

  // states
  const [values, setValues] = useState({})
  const [fleetIds, setFleetIds] = useState([])
 
  const [repeat, setRepeat] = useState(false)
  const [disableFields, setDisableFields] = useState(false)

  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [showRequiredFleetsError, setShowRequiredFleetsError] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)

  // fleet is required except for SuperAdmin Role
  const isValidFleetSelction = (values?.role && values?.role?.name === 'SuperAdmin') || fleetIds.length > 0
  const isLoading = flags?.create === 'processing'

  const isSelected = fleetId => fleetIds.filter(node => node.id === fleetId).length > 0

  const onInputChange = ({ target: { id, name, value } }) =>
    setValues({
      ...values,
      [id || name]: value,
    })

  const onSelectRoleChange = value => {
    let role = roles.filter(node => node.id === value)[0]
    setValues({ ...values, role })
  }

  const onSelect = (fleetId) => () => {
    if (isSelected(fleetId)) {
      const next = fleetIds.filter(node => node.id !== fleetId)
      setFleetIds(next)
      return
    }

    let fleet = fleets.filter(node => node.value === fleetId)[0]
    setFleetIds([
      ...fleetIds,
      { id: fleet.value, name: fleet.label }
    ])
  }

  const onSubmitForm = () => {
    if(isValidFleetSelction) {
      setDisableFields(true)
      onSubmit({
        ...values,
        nickname: values.nickname.trim(),
        fleets: fleetIds,
      })
    }
  }
  const onSubmitOnceForm = () => {
    if (form.current) {
      setRepeat(false)
      form.current.submit()
      !isValidFleetSelction && setShowRequiredFleetsError(true)
    }
  }

  const onSubmitRepeatForm = () => {
    if (form.current) {
      setRepeat(true)
      form.current.submit()
      !isValidFleetSelction && setShowRequiredFleetsError(true)
    }
  }

  const roleOptions = roles && roles.map(node => {
    return {
      label: getRole(node.name),
      value: node.id,
    }
  })

  useEffect(() => { // triggered on create user action success
    if (flags?.create === 'processed' && repeat) { // for save and add another user
      setDisableFields(false)
      setFleetIds([])
      setValues({})
      setShowSuccessMessage(true)      
      setTimeout(() => setShowSuccessMessage(false), 3000) // clear after 3 seconds
    }
    else if (flags?.create === 'processed') { // for save and close
      onClose()
    }
    flags?.create === 'error' && setDisableFields(false)
  }, [flags?.create]) // eslint-disable-line

  useEffect(() => {
    isValidFleetSelction && setShowRequiredFleetsError(false)
  }, [isValidFleetSelction])  

  useEffect(() => {
    ValidatorForm.addValidationRule('isValidEmail', (value) => {

      if(value === '') return true
      
      let domain = value.split(/@/)[1]
      let ext = domain && domain.split(/\./)[1]
      let isValidDomain = ext && ext.length > 1 // to avoid single character email extension 
      let isEmailFormat = /^[^\s@]{2,}@[^\s@]{2,}\.[^\s@]{2,}$/.test(value)

      return isEmailFormat && isValidDomain
    })
    return () => {
      ValidatorForm.removeValidationRule('isValidEmail')
    }
  }, [values.email])

  useEffect(() => {
    if(errorMessage && errorMessage != ""){
      setShowErrorModal(true);
    }
  }, [errorMessage])

  return (
    <ValidatorForm autoComplete="off" noValidate ref={form} onSubmit={onSubmitForm}>
      <Card>
        <CardContent className={classes.content}>
          {showSuccessMessage && (
            <Alert className={classes.alert} isSuccess>
              User created successfully
            </Alert>
          )}

          <Typography gutterBottom variant="h4">
            Details
          </Typography>
          <div className={classes.fieldGroup}>
            <Grid container spacing={4}>
              <Grid item sm={6} xs={12}>
                <TextValidator
                  id="nickname"
                  className={classes.field}
                  disabled={isLoading || disableFields}
                  fullWidth
                  label="Name"
                  value={values.nickname || ''}
                  inputProps={{ maxLength: 50 }}
                  variant="outlined"
                  validators={['required']}
                  errorMessages={['This field is required']}
                  onChange={onInputChange} />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextValidator
                  id="email"
                  className={classes.field}
                  disabled={isLoading || disableFields}
                  fullWidth
                  label="Email"
                  value={values.email || ''}
                  variant="outlined"
                  validators={['required', 'isValidEmail']}
                  errorMessages={['This field is required', 'Must be a valid email']}
                  onChange={onInputChange} />
              </Grid>
            </Grid>
          </div>
          <div className={classes.fieldHeader}>
            <Typography gutterBottom variant="h4">
              Fleets
            </Typography>
            <Typography variant="body2">
              Please select and assign at least one fleet to this user.
            </Typography>
          </div>
          <div className={classes.fieldGroupOptions}>
            <Grid container spacing={2}>
              <style scoped>
                {`
                  #checkbox-form-control > .MuiCheckbox-root {
                    color: ${showRequiredFleetsError ? '#e53935' : '#546E7A'};
                  }
                `}
              </style>
              {fleets.map((node, index) => (
                <Grid key={index} item sm={6} xs={12}>
                  <FormControlLabel
                    id="checkbox-form-control"
                    control={
                      <Checkbox
                        disabled={isLoading || disableFields}
                        checked={isSelected(node.value)}
                        onChange={onSelect(node.value)}
                        value={node.value}
                        color="default" />
                    }
                    label={node.label} />
                </Grid>
              ))}
            </Grid>
            {showRequiredFleetsError && (
              <p className={classes.errorText}>*Please select atleast 1 fleet</p>
            )}
          </div>

          <div className={classes.fieldHeader}>
            <Typography gutterBottom variant="h4">
              Role
            </Typography>
            <Typography variant="body2">
              Please select the role this user will have for all the selected fleets.
            </Typography>
          </div>
          <div className={classes.fieldGroup}>
            <Grid container spacing={4}>
              <Grid item sm={6} xs={12}>
                <SingleSelectValidator
                  id={`roleId`}
                  disabled={isLoading || disableFields}
                  label="Role"
                  options={roleOptions || []}
                  value={(values.role && values.role.id) || ''}
                  validators={['required']}
                  errorMessages={['This field is required']}
                  onChange={onSelectRoleChange} />
              </Grid>
            </Grid>
          </div>
        </CardContent>
        
        <ScrollToButton scrollToRef={saveButtonRef} />
      </Card>
      
      {/* Button Area */}
      <div className={classes.actions}>
        <div ref={saveButtonRef} />
        <Button className={classes.button} color="primary" disabled={isLoading} variant="contained" onClick={onSubmitOnceForm}>
          Save Changes
        </Button>
        <Button className={classes.button} disabled={isLoading} variant="contained" onClick={onSubmitRepeatForm}>
          Save and Add another User
        </Button>
        <Button className={classes.button} disabled={isLoading} variant="contained" onClick={onClose}>
          Cancel
        </Button>
      </div>

      {
        showErrorModal && (
          <StyledModal
            open={showErrorModal}
            onCancel={() => {setShowErrorModal(false)}}
            options={['noButtons']}
            animatedIcon={<div>
              {<AnimatedErrorCross size={50} />}
            </div>}>
            {errorMessage}
          </StyledModal>
        )
      }
    </ValidatorForm>
  )
}

Form.propTypes = {
  flags: PropTypes.object,
  errorMessage: PropTypes.string,  
  roles: PropTypes.array.isRequired,
  // func
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default Form
