import React from 'react'
import { useDispatch } from 'react-redux'
import { DashboardGraph } from 'graph'
import DataTable from './data-table'
import { useGraphFetch, constants } from '../module'
import { useSelectedFleetIds } from 'features/settings'
import uuid from 'uuid/v4'


const Graph = () => {

  const dispatch = useDispatch()
  const selectedFleetIds = useSelectedFleetIds()

  const { loading, error, data } = useGraphFetch()
  const path = '/reports/end-of-lease'

  const onRefresh = () => {
    dispatch({
      type: constants.REQUEST_DATA,
      payload: null,
      options: { queryParams: { FleetId: selectedFleetIds.join() }, requestId: uuid() }
    })
  }

  return (
    <DashboardGraph
      title="End of Lease"
      subtitle="(last 6 months)"
      to={path}
      loading={loading}
      error={error}
      onRefresh={onRefresh}
      data={data}>
      {data && (
        <DataTable data={data} />
      )}
    </DashboardGraph>
  )
}

export default Graph
