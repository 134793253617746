import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Page } from 'frame/components'


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  results: {
    marginTop: theme.spacing(3)
  },
}))

const PageNotFound = ({ title }) => {

  const classes = useStyles()

  return (
    <Page
      className={classes.root}
      title={title}>
      <h1>{title}</h1>
    </Page>
  )
}

PageNotFound.propTypes = {
  title: PropTypes.string.isRequired,
}

export default PageNotFound
