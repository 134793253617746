import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { TextValidator } from 'react-material-ui-form-validator'
import { Grid, Table, TableBody, TableCell, TableHead, TableRow, Radio, Typography } from '@material-ui/core'
import { PageLoader } from 'frame/components'
import { useSelectedFleetIds } from 'features/settings'
import { useSelector } from 'react-redux'


const useStyles = makeStyles(theme => ({
  tableRow: {
    '&:hover': {
      backgroundColor: '#eeeeee !important',
      cursor: 'pointer',
    }
  },
  greyOut: {
    color: '#BDBDBD !important'
  }
}))

const AssignFleetLookupField = ({
  id,
  resourceModel,
  lookupPath,
  customFleetId,
  currentDriverUniqueId,
  searchHistory,
  // func
  setSearchHistory,
  setSelectedVehicle,
  onLookup,
  onChange,
}) => {

  const classes = useStyles()
  const fleetIds = useSelectedFleetIds()

  const [selectFleet, setSelectFleet] = useState(null)
  const [searchKey, setSearchKey] = useState(searchHistory ? searchHistory.input : '')

  const lookupResults = useSelector(state => state[resourceModel]?.lookup && state[resourceModel]?.lookup[lookupPath]
    ? state[resourceModel]?.lookup[lookupPath]
    : [])
  
  const lookupFlag = useSelector(state => state[resourceModel]?.flags?.lookup)
  const isLoading = lookupFlag === 'processing'
  
  
  useEffect(() => {
    // load selected vehicle on load from history if exists
    searchHistory && setSelectFleet(searchHistory.selected)
  }, []) // eslint-disable-line 

  useEffect(() => { // update dropdown options on lookup results value change
    if(searchKey && searchKey.length > 0 && lookupResults && lookupResults.length > 0) {
      // save search key for re-rendering search when form is load from back btn from confirm from
      setSearchHistory({ ...searchHistory, input: searchKey, options: lookupResults })
    } // eslint-disable-next-line
  }, [searchKey])

  const onInputChange = ({ target }) => {
    const input = target.value || ''
    setSearchKey(input)
    console.log('lookup onInputChange ', input)
    
    if (input.length > 0) {
      const fleetIdParam = customFleetId ? customFleetId : fleetIds.join(',')
      onLookup(lookupPath, input.trim(), fleetIdParam, 10)
    } else {
      onChange(null)
      setSelectFleet(null)
      setSearchHistory && setSearchHistory(null)
    }
  }

  const onSelectFleet = (rowData) => () => {
    setSelectFleet(rowData.value)
    setSearchHistory && setSearchHistory({ ...searchHistory, selected: rowData.value }) // rowData.value = store agreementNo
    onChange(rowData)
    console.log('[onSelectFleet]: ', rowData)
    setSelectedVehicle && setSelectedVehicle(rowData)
  }

  const isCurrentDriver = (oldDriver, newDriver) => oldDriver === newDriver
  
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextValidator
          id={id || 'lookup-search-vehicle'}
          fullWidth
          label="Search Vehicle"
          name="searchVehicle"
          type="text"
          value={searchKey}
          variant="outlined"
          onChange={onInputChange}
          onKeyPress={(ev) => {
            ev.key === 'Enter' && ev.preventDefault()
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Table className={classes.table} size="small">
          {searchKey && searchKey.length > 0 && lookupResults && lookupResults.length > 0 && !isLoading && (
            <TableHead>
              <TableRow>
                <TableCell>
                </TableCell>
                <TableCell>
                  Registration
                </TableCell>
                <TableCell>
                  Vehicle
                </TableCell>
                <TableCell>
                  Current Driver
                </TableCell>
              </TableRow>
            </TableHead>
          )}

          <TableBody>
            {searchKey && searchKey.length > 0 && lookupResults && lookupResults.length > 0 && !isLoading && lookupResults.map((rowData, index) => {
              return (
                <TableRow className={classes.tableRow}
                  key={`${index}-${rowData.value}`}
                  onClick={isCurrentDriver(rowData.uniqueId, currentDriverUniqueId) ? null : onSelectFleet(rowData)}
                >
                  <TableCell style={{ maxWidth: '20px' }}>
                    <Radio
                      disabled={isCurrentDriver(rowData.uniqueId, currentDriverUniqueId)}
                      style={{ marginLeft: '-10px' }}
                      size="small"
                      color="default"
                      checked={selectFleet === rowData.value}
                      name="radio-button-demo"
                      inputProps={{ 'aria-label': `radio-btn-${index}-${rowData.value}` }}
                    />
                  </TableCell>
                  <TableCell className={isCurrentDriver(rowData.uniqueId, currentDriverUniqueId) ? classes.greyOut : ''}>
                    {rowData.text || '-'} {/* Registration No. */}
                  </TableCell>
                  <TableCell className={isCurrentDriver(rowData.uniqueId, currentDriverUniqueId) ? classes.greyOut : ''}>
                    {rowData.vehicle || '-'}
                  </TableCell>
                  <TableCell className={isCurrentDriver(rowData.uniqueId, currentDriverUniqueId) ? classes.greyOut : ''}>
                    {rowData.currentDriver || '-'}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
          
        {isLoading && (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <PageLoader minHeight={180} />
          </div>
        )}

        {lookupResults && lookupResults.length === 0 && !isLoading && searchKey && searchKey.length > 0 && (
          <Typography variant="body1">
            No vehicle found.
          </Typography>
        )}
      </Grid>
        
    </Grid>
  )
}

AssignFleetLookupField.propTypes = {
  id: PropTypes.string.isRequired,
  resourceModel: PropTypes.string,
  lookupPath: PropTypes.string,
  customFleetId: PropTypes.string,
  currentDriverUniqueId: PropTypes.string,
  searchHistory: PropTypes.object,
  // func
  setSearchHistory: PropTypes.func,
  setSelectedVehicle: PropTypes.func,
  onLookup: PropTypes.func,
  onChange: PropTypes.func,
}

export default AssignFleetLookupField
