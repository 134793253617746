import { createGraph, barGraphFormatter } from 'graph'


const graphModule = createGraph({
  name: 'graphMaturing',
  api: 'dashboard/maturing',
  formatter: barGraphFormatter,
})

export const actions = graphModule.actions
export const constants = graphModule.constants
export const effects = graphModule.effects
export const reducer = graphModule.reducer

export const useGraphFetch = graphModule.hooks.useGraphFetch
