import { deserializePreferences } from 'listable'
import { getAppConfig } from 'app-config'


const appConfig = getAppConfig()

export const INITALIZE = 'settings_initialize'
export const INITALIZED = 'settings_initialized'
export const RESET = 'settings_reset'

export const ADD_FLEETS = 'settings_fleets_add'
export const REMOVE_FLEETS = 'settings_fleets_remove'

export const REQUEST_FLEETS = 'settings_fleets_request'
export const REQUEST_FLEETS_FULFILLED = 'settings_fleets_request_fulfilled'
export const REQUEST_FLEETS_ERROR = 'settings_fleets_request_error'

export const SELECT_FLEETS = 'settings_fleets_select'
export const SELECTED_FLEETS_CHANGED = 'settings_fleets_selected_changed'

export const SET_ACCOUNT_TYPE = 'settings_set_account_type'
export const SET_ROLES = 'settings_set_roles'

export const REQUEST_PREFERENCES = 'settings_request_preferences'
export const REQUEST_PREFERENCES_FULFILLED = 'settings_request_preferences_fulfilled'
export const REQUEST_PREFERENCES_ERROR = 'settings_request_preferences_error'

export const REQUEST_SELECTED_FLEETS_PREFERENCES = 'settings_request_selected_fleets_preferences'
export const REQUEST_SELECTED_FLEETS_PREFERENCES_FULFILLED = 'settings_request_selected_fleets_preferences_fulfilled'
export const REQUEST_SELECTED_FLEETS_PREFERENCES_ERROR = 'settings_request_selected_fleets_preferences_error'

export const REQUEST_ENABLED_FEATURE_TOGGLES = 'settings_request_enabled_feature_toggles'
export const REQUEST_ENABLED_FEATURE_TOGGLES_FULFILLED = 'settings_request_enabled_feature_toggles_fulfilled'
export const REQUEST_ENABLED_FEATURE_TOGGLES_ERROR = 'settings_request_enabled_feature_toggles_error'

export const SAVE_SELECTED_FLEETS_PREFERENCES = 'selected_fleets_save_preferences'
export const SAVE_SELECTED_FLEETS_PREFERENCES_FULFILLED = 'selected_fleets_save_preferences_fulfilled'
export const SAVE_SELECTED_FLEETS_PREFERENCES_ERROR = 'selected_fleets_save_preferences_error'

export const SET_CURRENT_MODULE = 'set_current_module'
export const RESET_FLAGS = 'reset_flags'

export const initialize = () => ({
  type: INITALIZE,
})

export const initialized = () => ({
  type: INITALIZED,
})

export const reset = () => ({
  type: RESET,
})

export const addFleets = payload => ({
  type: ADD_FLEETS,
  payload,
})

export const removeFleets = payload => ({
  type: REMOVE_FLEETS,
  payload,
})

export const requestFleets = (payload, requestId) => ({
  type: REQUEST_FLEETS,
  payload,
  requestId,
})

export const requestFleetsFulfilled = (payload, requestId) => ({
  type: REQUEST_FLEETS_FULFILLED,
  payload,
  requestId,
})

export const requestFleetsError = (payload, requestId) => ({
  type: REQUEST_FLEETS_ERROR,
  payload,
  requestId,
})

export const selectFleets = (payload) => ({
  type: SELECT_FLEETS,
  payload,
})

export const selectedFleetsChanged = (payload) => ({
  type: SELECTED_FLEETS_CHANGED,
  payload,
})

export const setAccountType = payload => ({
  type: SET_ACCOUNT_TYPE,
  payload,
})

export const setRoles = (payload) => ({
  type: SET_ROLES,
  payload,
})

export const requestPreferences = () => ({
  type: REQUEST_PREFERENCES,
  payload: {},
  options: {
    queryParams: {
      type: `all${appConfig.build.release}`,
    },
  },
})

export const requestPreferencesFulfilled = (payload) => ({
  type: REQUEST_PREFERENCES_FULFILLED,
  payload: deserializePreferences(payload[0].filterJson),
})

export const requestPreferencesError = (payload) => ({
  type: REQUEST_PREFERENCES_ERROR,
  payload,
})

export const requestSelectedFleetsPreferences = () => ({
  type: REQUEST_SELECTED_FLEETS_PREFERENCES,
  payload: {},
  options: {
    queryParams: {
      type: `selected_fleet_ids`,
    },
  },
})

export const requestSelectedFleetsPreferencesFulfilled = (payload) => ({
  type: REQUEST_SELECTED_FLEETS_PREFERENCES_FULFILLED,
  payload: deserializePreferences(payload[0].filterJson),
})

export const requestSelectedFleetsPreferencesError = (payload) => ({
  type: REQUEST_SELECTED_FLEETS_PREFERENCES_ERROR,
  payload,
})

export const requestEnabledFeatureToggles = () => ({
  type: REQUEST_ENABLED_FEATURE_TOGGLES,
})

export const requestEnabledFeatureTogglesFulfilled = (payload) => ({
  type: REQUEST_ENABLED_FEATURE_TOGGLES_FULFILLED,
  payload,
})

export const requestEnabledFeatureTogglesError = (payload) => ({
  type: REQUEST_ENABLED_FEATURE_TOGGLES_ERROR,
  payload,
})

/*
  for saving the user selected fleetIds to api on fleet change in Fleet Selector modal to persist fleet ids
*/
export const saveSelectedFleetsPreferences = (filterType, preferences) => ({
  type: SAVE_SELECTED_FLEETS_PREFERENCES,
  payload: {
    filterType: filterType,
    filterJson: preferences,
  },
})

export const saveSelectedFleetsPreferencesFulfilled = (payload) => ({
  type: SAVE_SELECTED_FLEETS_PREFERENCES_FULFILLED,
  payload,
})

export const saveSelectedFleetsPreferencesError = (payload) => ({
  type: SAVE_SELECTED_FLEETS_PREFERENCES_ERROR,
  payload,
})

export const setCurrentModule = (payload) => ({
  type: SET_CURRENT_MODULE,
  payload,
})

export const resetFlags = () => ({
  type: RESET_FLAGS,
})