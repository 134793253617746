import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useRouter } from 'utils'
import { Grid } from '@material-ui/core'
import { Dialog } from 'frame/components'
import { requestAddUser } from '../../actions'
import { useProfile } from 'features/account'
import Form from './form'
import FormCleaner from './form-cleaner'


const Item = ({ flags, roles, errorMessage, onCreateUser }) => {

  const profile = useProfile()
  const { history } = useRouter()

  const onClose = () => history.goBack()

  const onSubmit = (payload) => {
    console.log('[onSave]', JSON.stringify(payload, null, 2)) // eslint-disable-line
    onCreateUser(payload)
  }

  const showCleanerForm = profile?.email === 'tomasz@borndigital.co.nz'
  const AddUserForm = showCleanerForm ? FormCleaner : Form

  return (
    <Dialog
      open autoSize
      aria-labelledby="User"
      title="Add new user"
      loading={flags?.create === 'processing'}
      onClose={onClose}>
      <Grid container>
        <Grid item xs={12}>
          <AddUserForm
            flags={flags}
            errorMessage={errorMessage}
            roles={roles}
            onClose={onClose}
            onSubmit={onSubmit} />
        </Grid>
      </Grid>
    </Dialog>
  )
}

Item.propTypes = {
  flags: PropTypes.object,
  roles: PropTypes.array,
  errorMessage: PropTypes.string,
  onCreateUser: PropTypes.func.isRequired,
}

const mapStateToProps = ({ adminInvitations }) => ({
  roles: adminInvitations.roles,
  errorMessage: adminInvitations.errorMessage,
  flags: adminInvitations.flags,
})

const mapDispatchToProps = (dispatch) => ({
  onCreateUser: (payload) => {
    dispatch(requestAddUser(payload))
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Item)
