import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Checkbox, Typography, FormControlLabel, Button } from '@material-ui/core'
import { sortAlphaNumeric } from 'filter'
import { useEnabledFeatureToggleList } from 'features/settings'


const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    padding: theme.spacing(3),
    background: theme.palette.background.block,
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      justifyContent: 'flex-start',
    },
  },
  title: {
    paddingBottom: theme.spacing(1),
  },
  columns: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingTop: theme.spacing(1),
  },
  column: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 160,
  },
  formControlLabel: {
    userSelect: 'none'
  },
  selectAllBtn: {
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(1),
      width: '100%',
    },
  },
}))

const Columns = ({ config, columns, onToggleColumn, onSelectAllColumns }) => {

  const classes = useStyles()
  const featureToggleList = useEnabledFeatureToggleList()
  const documentsReportFeatureToggle = featureToggleList.includes('DocumentsViewing')
  const tfp1839FeatureToggle = featureToggleList.includes('TFP1839')
  const tfp1929FeatureToggle = featureToggleList.includes('TFP1929')
  const tfp1838FeatureToggle = featureToggleList.includes('TFP1838')
  const tfp1845FeatureToggle = featureToggleList.includes('TFP1845')
  const tfp1944FeatureToggle = featureToggleList.includes('TFP1944')
  const tfp1935FeatureToggle = featureToggleList.includes('TFP1935')
  const tfp1971FeatureToggle = featureToggleList.includes('TFP1971')
  const tfp1978FeatureToggle = featureToggleList.includes('TFP1978')
  const tfp2005FeatureToggle = featureToggleList.includes('TFP2005')
  const isSelectedAll = Object.entries(columns)
    .filter(([key, value]) => !value && config.headers.map(x => x.key).includes(key)).length === 0
    
  const [selectAll, setSelectAll] = useState(isSelectedAll || false)

  const onToggle = (column) => () =>
    onToggleColumn(column)

  const list = useMemo(() => {
    return [...config.model].sort((left, right) =>
      sortAlphaNumeric(
        left.label,
        right.label,
      ))
  }, [config.model])

  const onSelectAll = () => {
    setSelectAll(!selectAll)
    onSelectAllColumns(!selectAll)
  }

  const isDriverPage = window.location.pathname.includes('/drivers')
  const isFbtPages = window.location.pathname.includes('/fbt')
  const isVehicleServicingReport = window.location.pathname.includes('/reports/vehicle-servicing')
  const isMyFleetPage = window.location.pathname.includes('/fleet')
  const isWofReport = window.location.pathname.includes('/reports/wof-registration')
  const isExtensionRentalsReport = window.location.pathname.includes('/reports/extension-rentals')
  const isMaturities = window.location.pathname.includes('/reports/maturities')

  return (
      <>
        <div className={classes.root}>
          
          <div className={classes.container}>
            <div>
            <Typography className={classes.title} color="inherit" variant="h5">
              Columns
            </Typography>

            <Typography variant="body2">
              Select the columns to show and hide relevant information.
            </Typography>
          </div>

            <Button
              id={selectAll  ? 'unselect-btn' : 'select-btn'}
              variant="contained"
              className={classes.selectAllBtn}
              onClick={() => onSelectAll()}>
              {selectAll  ? 'Unselect All' : 'Select All'}
            </Button>
        </div>

        <div className={classes.columns}>
          {list.map((node) => {

            // hide id, checkbox and invalid columns
            
            // ! ! ! DEV NOTE ! ! !
            // this also needs to be edited in page-list-table-header.js

            if (node.key === 'id' || node.key === 'checkbox' || !node.column || node.label === ''
              // hide agreementNo column only on driver report page
              || (isDriverPage && node.key === 'agreementNo')
              // hide accountName column heading on fbt report pages
              || (isFbtPages && node.key === 'accountName')
              // hide document count column when documentsReportFeatureToggle is off
              || (!documentsReportFeatureToggle && node.key === 'documentsCount')
              // hide controller column when tfp1839FeatureToggle is off, and page is Vehicle Service
              || (!tfp1839FeatureToggle && node.key === 'controller' && isVehicleServicingReport)
              // hide vehicle notes column when tfp1929FeatureToggle is off, and page is MyFleet
              || (!tfp1929FeatureToggle && node.key === 'vehicleNotes' && isMyFleetPage)
              // hide contact email column when tfp1838FeatureToggle is off
              || (!tfp1838FeatureToggle && node.key === 'contactEmail')
              // hide contract end km column when tfp1845FeatureToggle is off, and page is MyFleet
              || (!tfp1845FeatureToggle && node.key === 'contractEndKm' && isMyFleetPage)
              // hide driver note column when tfp1944FeatureToggle is off
              || (!tfp1944FeatureToggle && node.key === 'driverNote')
              // hide controller column when tfp1935FeatureToggle is off, and page is WoF
              || (!tfp1935FeatureToggle && node.key === 'controller' && isWofReport)
              // hide contact email column when tfp1838FeatureToggle is off
              || (!tfp1838FeatureToggle && node.key === 'contactEmail')
              // hide original maturity date column when tfp1971FeatureToggle is off
              || (isMaturities && !tfp1971FeatureToggle && node.key === 'originalMaturityDate')
              // hide original maturity date column when tfp2005FeatureToggle is off
              || ((isVehicleServicingReport || isMyFleetPage) && !tfp2005FeatureToggle && node.key === 'originalMaturityDate')
              // hide original maturity date column when tfp2005FeatureToggle is off
              || (isVehicleServicingReport && !tfp2005FeatureToggle && node.key === 'maturityDate')
              // hide cost centre column when tfp1978FeatureToggle if off, and page is Extension Rentals
              || (!tfp1978FeatureToggle && node.key === 'costCentre' && isExtensionRentalsReport)
              ) {
                return null
              }
            return (
              <div
                key={node.key}
                className={classes.column}>
                <FormControlLabel
                  className={classes.formControlLabel}
                  control={
                    <Checkbox
                      checked={columns[node.key]}
                      color="default"
                      onChange={onToggle(node.key)}
                      value={columns[node.key]} />
                  }
                  label={node.label} />
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

Columns.propTypes = {
  config: PropTypes.object.isRequired,
  columns: PropTypes.object.isRequired,
  onToggleColumn: PropTypes.func.isRequired,
  onSelectAllColumns: PropTypes.func.isRequired,
}

export default Columns
