import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clip from 'text-clipper'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    width: 'inherit',
    paddingRight: theme.spacing(2),
    display: 'inline-block',
  },
  text: {
    textAlign: 'justify',
  },
  link: {
    color: theme.palette.text.link,
    textDecoration: 'none',
  },
}))

const TextClip = ({ text, numberOfCharacters }) => {
  const classes = useStyles()
  const [seeMore, setSeeMore] = useState(false)

  const showSeeMore = text && text.length > numberOfCharacters

  /* eslint-disable */
  return (
    <span className={classes.root}>
      {text ? 
        <>
          <span className={classes.text}>{seeMore ? text : clip(text, numberOfCharacters)}</span>
          <br />
          {showSeeMore && <a href="#" className={classes.link} onClick={() => setSeeMore(!seeMore)}>
            {seeMore ? 'Show less' : 'Show More'}
          </a>}
        </>
        : '-'}
    </span>
  )
}
/* eslint-enable */

TextClip.propTypes = {
  text: PropTypes.string,
  numberOfCharacters: PropTypes.number.isRequired,
}

export default TextClip
