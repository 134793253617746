import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import { reducer as adminUsers } from 'features/admin-users'
import { reducer as adminInvitations } from 'features/admin-invitations'
import { reducer as marketingEmails } from 'features/admin-registration-of-interest'

import { reducer as account } from 'features/account'
import { reducer as drivers } from 'features/drivers'
import { reducer as fleet } from 'features/fleet'
import { reducer as settings } from 'features/settings'

import { reducer as homepage } from 'features/homepage'

import { reducer as graphEndOfLease } from 'features/graph-end-of-lease'
import { reducer as graphKmTracking } from 'features/graph-km-tracking'
import { reducer as graphMaturing } from 'features/graph-maturing'
import { reducer as graphOnOrder } from 'features/graph-on-order'
import { reducer as graphServiceExpiry } from 'features/graph-service-expiry'
import { reducer as graphWofExpiry } from 'features/graph-wof-expiry'
import { reducer as graphVehicleOnExtension } from 'features/graph-vehicle-on-extension'

import { reducer as reportEndOfLease } from 'features/report-end-of-lease'
import { reducer as reportExtensionRentals } from 'features/report-extension-rentals'
import { reducer as reportKmTracking } from 'features/report-km-tracking'
import { reducer as reportUpcomingMaturities } from 'features/report-upcoming-maturities'
import { reducer as reportVehicleServicing } from 'features/report-vehicle-servicing'
import { reducer as reportVehiclesOnOrder } from 'features/report-vehicles-on-order'
import { reducer as reportWofRego } from 'features/report-wof-rego'
import { reducer as reportIncident } from 'features/report-incident-management'
import { reducer as reportDocuments } from 'features/report-documents'

import { reducer as fbtSubmissions } from 'features/fbt-submissions'
import { reducer as fbtSummary } from 'features/fbt-summary'

import { reducer as requestReliefVehicles } from 'features/request-relief-vehicles'
import { reducer as requestVehicleRelocations } from 'features/request-vehicle-relocations'
import { reducer as requestContractVariations } from 'features/request-contract-variations'
import { reducer as requestVehicleSummary } from 'features/request-vehicle-summary'
import { reducer as featureToggles } from 'features/feature-toggle'
import { reducer as myDriveAppFeatureToggleSettings } from 'features/feature-toggle-settings'

export const makeRootReducer = (history) => {
  return combineReducers({
    router: connectRouter(history),
    adminUsers: adminUsers,
    adminInvitations: adminInvitations,
    registrationOfInterest: marketingEmails,

    account: account,
    drivers: drivers,
    fleet: fleet,
    settings: settings,
    featureToggles: featureToggles,
    myDriveAppFeatureToggleSettings: myDriveAppFeatureToggleSettings,

    // graphs - for dashboard page
    graphEndOfLease: graphEndOfLease,
    graphKmTracking: graphKmTracking,
    graphMaturing: graphMaturing,
    graphOnOrder: graphOnOrder,
    graphServiceExpiry: graphServiceExpiry,
    graphWofExpiry: graphWofExpiry,
    graphVehicleOnExtension: graphVehicleOnExtension,

    // homepage
    homepage: homepage,

    // reports
    reportEndOfLease: reportEndOfLease,
    reportExtensionRentals: reportExtensionRentals,
    reportKmTracking: reportKmTracking,
    reportUpcomingMaturities: reportUpcomingMaturities,
    reportVehicleServicing: reportVehicleServicing,
    reportVehiclesOnOrder: reportVehiclesOnOrder,
    reportWofRego: reportWofRego,
    reportIncident: reportIncident,
    reportDocuments: reportDocuments,

    // fbt
    fbtSubmissions: fbtSubmissions,
    fbtSummary: fbtSummary,

    // actions
    requestReliefVehicles: requestReliefVehicles,
    requestVehicleRelocations: requestVehicleRelocations,
    requestContractVariations: requestContractVariations,
    requestVehicleSummary: requestVehicleSummary,
  })
}

export default makeRootReducer
