import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { Button, Card, CardContent, Grid, Typography } from '@material-ui/core'
import { Alert, ScrollToTop } from 'frame/components'
import AssignFleetLookupField from './assign-fleet-lookup-field'


const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: theme.spacing(3, 1.2, 3, 1.2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    }
  },
  button: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    minWidth: 180,
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
    }
  },
}))

/* used in add/edit driver form  */
const AssignForm = ({
  values,
  setValues,
  disabled,  
  selectedVehicle,
  searchHistory,
  setSearchHistory,
  originalValues,
  // func
  onBack,
  onLookup,
  onProceed,
  onFetchContract,
  setSelectedVehicle,
}) => {

  const form = useRef()
  const classes = useStyles()
  
  const [showError, setShowError] = useState(false)

  const onFleetLookupChange = (option) =>
    option && setValues({
      ...values,
      agreementNo: option.value || null,
      registration: option.text || null,
      costCentre: option.costCentre || null,
      location: option.location || null,
      controller: option.controller || null,
      vehicleNotes: option.vehicleNotes || null,
    })

  const onProceedHandler = () => {
    if (form.current && selectedVehicle) {
      // to get start date for startDate field min date value
      if(onFetchContract && values?.agreementNo && values?.thirdPartyNumber) {
        onFetchContract(values.agreementNo, values?.thirdPartyNumber)
      }
      form.current.submit()
    } else {
      setShowError(true)
      setTimeout(() => setShowError(false), 3000)
    }
  }

  const onBackHandler = () => {    
    originalValues && setValues({ // reset values if originalValues props exists - for edit driver form only
      ...values,
      agreementNo: originalValues.agreementNo || null,
      registration: originalValues.registration || null,
      costCentre: originalValues.costCentre || null,
      location: originalValues.location || null,
      controller: originalValues.controller || null,
      vehicleNotes: originalValues.vehicleNotes || null,
    })
    onBack()
  }

  return (
    <ValidatorForm autoComplete="off" noValidate ref={form} onSubmit={onProceed}>
      <ScrollToTop />
      <Card className={classes.root}>
        <CardContent className={classes.content}>
          
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography gutterBottom variant="h4">
                Search for a Vehicle
              </Typography>
            </Grid>
            
            {showError && (
              <Grid item xs={12}>
                <Alert>
                  Please select a vehicle to proceed.
                </Alert>
              </Grid>
            )}

            <Grid item xs={12}>
              <AssignFleetLookupField
                id="registration"
                resourceModel="drivers"
                lookupPath="driverAgreement"
                customFleetId={values.thirdPartyNumber}
                currentDriverUniqueId={values.uniqueId}
                searchHistory={searchHistory}
                // func
                setSearchHistory={setSearchHistory}
                setSelectedVehicle={setSelectedVehicle}
                onLookup={onLookup}
                onChange={onFleetLookupChange}
              />
            </Grid>

          </Grid>
        </CardContent>
      </Card>

      <div className={classes.actions}>
        <Button className={classes.button} color="primary" disabled={disabled} variant="contained" onClick={onProceedHandler}>
          Proceed
        </Button>
        <Button className={classes.button} disabled={disabled} variant="contained" onClick={onBackHandler}>
          Back
        </Button>
      </div>
    </ValidatorForm>
  )
}

AssignForm.propTypes = {
  disabled: PropTypes.bool,
  values: PropTypes.object.isRequired,
  setValues: PropTypes.func.isRequired,
  setSelectedVehicle: PropTypes.func.isRequired,
  searchHistory: PropTypes.object,
  setSearchHistory: PropTypes.func,
  originalValues: PropTypes.object,
  // func
  onBack: PropTypes.func.isRequired,
  onLookup: PropTypes.func.isRequired,
  onProceed: PropTypes.func.isRequired,
  onFetchContract: PropTypes.func.isRequired,
}

export default AssignForm
