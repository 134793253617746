import React, { useState } from 'react'
import PropTypes from 'prop-types'
import VehicleFormWraper from './form-wrapper'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { RowValueText, CustomDatePicker, StyledModal, AnimatedSuccessTick, AnimatedErrorCross } from 'frame/components'
import { TextValidator } from 'react-material-ui-form-validator'
import { connect } from 'react-redux'
import moment from 'moment'
import { submitKmReading, reset } from 'features/fleet/actions'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4, 0, 2, 0),
  },
  label: {
    paddingTop: theme.spacing(3),
  },
  dateField: {
    margin: 0,
    padding: 0,
    '& > div': {
      margin: 0,
      padding: '11px 8px',
      borderRadius: '4px',
      '&::before': { display: 'none' },
      '&::after': { display: 'none' },
    },
    '& input': { margin: 0, padding: '0px 6px', border: 'none' }
  },
}))

const AddKmReadingForm = ({ flags, apiResponse, onReset, onBack, fleetData, isMiles, onAddKmReading, errorMessage }) => {
  console.log("MILES", isMiles);
  const classes = useStyles()
  const [values, setValues] = useState({
    completedDate: moment(),
    odometer: ''
  })
  const onInputChange = ({ target: { id, name, value } }) =>
    setValues({ ...values, [id || name]: value })
  
  const onSelectChange = (id) => (value) =>
    setValues({ ...values, [id]: value })

  const onSave = () => {
    const payload = {
      thirdPartyNumber: fleetData?.thirdPartyNumber,
      agreementNumber: fleetData?.agreementNo,
      vehicleRegistration: fleetData?.registration,
      completedDate: values.completedDate,
      odometer: +values.odometer,
    }
    console.log('[onKmReading] payload: ', payload)
    onAddKmReading(payload)
  }

  return (
    <>
       <VehicleFormWraper
        title="Add new KM Reading"
        subtitle="Add new details of an odometer reading"
        isLoading={flags.submitKmReading === 'processing'}
        onCancel={onBack}
        onCancelLabel='Cancel'
        onSubmit={onSave}
        onSubmitLabel='Save'
      >
        <Grid container spacing={2} className={classes.root}>
          
          <Grid item xs={12}>
            <RowValueText label="Date" labelClassName={classes.label} responsive>
              
              <CustomDatePicker
                fullWidth tight
                id="completedDate"
                variant="outlined"
                format="Do MMMM YYYY"
                value={values.completedDate}
                onChange={onSelectChange('completedDate')}
                maxDate={moment().toString()}
                textFieldClassName={classes.dateField}
                placeholder="Select a date"
                disabled={isMiles}
              />
            </RowValueText>
          </Grid>
          <Grid item xs={12}>
            <RowValueText label="Odometer Reading" responsive>
              <TextValidator
                id="odometer"
                size="small"
                fullWidth
                placeholder="Enter Odometer Reading"
                variant="outlined"
                value={values.odometer}
                inputProps={{ maxLength: 50 }}
                validators={['required', 'isNumber']}
                errorMessages={['This field is required', 'A valid odometer reading is required']}
                onChange={onInputChange}
              />
            </RowValueText>
          </Grid>
        </Grid>
      </VehicleFormWraper>

      {/* Success/Error modal */}
      <StyledModal
        open={flags.submitKmReading === 'processed' || flags.submitKmReading === 'error'}
        onCancel={() => {
          flags.submitKmReading === 'processed' && onBack()
          onReset()
        }}
        options={['noButtons']}
        animatedIcon={<div>
          {flags.submitKmReading === 'processed'
            ? <AnimatedSuccessTick size={50} />
            : <AnimatedErrorCross size={50} />}
        </div>}>
          {apiResponse}
      </StyledModal>
    </>
  )}

  AddKmReadingForm.propTypes = {
    flags: PropTypes.object.isRequired,
    fleetData: PropTypes.object.isRequired,
    errorMessage: PropTypes.string.isRequired,
    apiResponse: PropTypes.string.isRequired,
    // func
    onAddKmReading: PropTypes.func.isRequired,
    onReset: PropTypes.func.isRequired,
    onBack: PropTypes.func,
  }
  
  const mapStateToProps = ({ fleet }) => ({
    flags: fleet.flags,
    errorMessage: fleet.errorMessage || '',
    apiResponse: fleet.apiResponse || '',
  })
  
  const mapDispatchToProps = (dispatch) => ({
    onAddKmReading: (payload) => {
      dispatch(submitKmReading(payload))
    },
    onReset: () => {
      dispatch(reset())
    },
  })

  export default connect(mapStateToProps,mapDispatchToProps)(AddKmReadingForm)