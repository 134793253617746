import React, { useCallback, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Listable } from 'listable'
import { Typography } from '@material-ui/core'
import Row from './row'
// import Filters from '../filters'
import { actions, config } from '../../module'
import { StyledModal, AnimatedSuccessTick, AnimatedErrorCross } from 'frame/components'
import { useUploadCsvRequest } from 'utils'

// disclaimerMessage popup
const title = (
  <Typography variant="h3">Process to send an email</Typography>
)
const message = (
  <p>
    <strong>Step 1 - upload recipients</strong><br /><br />
    Upload a CSV with your users name, email address and if they are in the portal or not.<br /><br />
    <a href="/static/files/email-invite-template.csv" download>Refer to this sample CSV.</a> Mark if they are already in the portal = 1 or not = 0.<br /><br />
    Please note users could receive an email more than once, if uploaded more than once via a CSV. The table will show duplicates in that instance.<br /><br />
    <strong>Step 2 - send email with variations</strong><br /><br />
    When you click the Send Emails button, any users that have a blank 'Date Last Sent' line will be sent 1 of the following emails:<br /><br />
    Already in the portal = Yes<br /><br />
    Fleet Portal Launch Communication – Registered Fleet Portal Users<br /><br />
    Already in the portal = No<br /><br />
    Fleet Portal Launch Communication – Wider customer group<br /><br />
    <a href="/static/files/Fleet Portal Launch Communication.docx" download>Email body can be reviewed here.</a><br /><br />
    Emails will be sent from fleet.operations@tfs.co.nz
  </p>
)

const List = ({ model, flags, apiResponse, onReset, onSendEmails }) => {

  const xlsxUploaderRef = useRef()
  const { onUploadCsv } = useUploadCsvRequest()

  // confirmation modals
  const [showSendEmailConfirmationModal, setShowSendEmailConfirmationModal] = useState(false)
  const [showSendEmailConfirmation, setShowSendEmailConfirmation] = useState(false)
  const [showUploadCSVConfirmation, setShowUploadCSVConfirmation] = useState(false)
  const [isErrored, setIsErrored] = useState(false)

  const renderItem = useCallback((item, index, itemProps) => (
    <Row key={index} item={item} {...itemProps}/>
  ), [])

  // Prevents re-rendering if ids or data updated - no longer needed
  const { ids, filters, ...rest } = model
  
  useEffect(() => {
    onReset()
  }, [onReset])
  
  useEffect(() => {
    if(flags.uploadingCsv === 'processed') {
      setShowUploadCSVConfirmation(true)
    }
    if(flags.sendingInviteEmails === 'processed') {
      setShowSendEmailConfirmation(true)
    }
    if(flags.sendingInviteEmails === 'error' || flags.uploadingCsv === 'error') {
      setIsErrored(true)
    }
    // eslint-disable-next-line
  }, [flags.sendingInviteEmails, flags.uploadingCsv])

  const onUpload = (event) => {
    onUploadCsv(event.target.files[0])
    xlsxUploaderRef.current.value = null
  }

  const onConfirmSendEmails = () => {
    onSendEmails()
  }

  const outputApiResponse = (message) => (
    <>
      {message}<br/>
      {apiResponse?.totalRecords ? <>{`Total Records: ${apiResponse.totalRecords}`}<br/></> : ''}
      {apiResponse?.processed ? <>{`Processsed: ${apiResponse.processed}`}<br/></> : ''}
      {apiResponse?.failedRecords ? (
        <>
          Failed Records:{' '}
          {apiResponse.failedRecords.length > 0
            ? apiResponse.failedRecords.map((email, index) => index === apiResponse.failedRecords.length ? email : `${email}`)
            : 0
          }
          <br/><br/>
        </>
      ) : ''}
    </>
  )

  return (
    <>
      <Listable
        actions={actions}
        config={{ ...config,          
          disclaimerMessage: {
            linkLabel: 'Not sure whats this report is for? Learn more here',
            title,
            message
          },
        }}
        model={rest}
        renderItem={renderItem}
        primaryButton={{
          label: 'Upload CSV',
          action: () => xlsxUploaderRef.current.click(),
        }}
        secondaryButton={{
          label: 'Send Emails',
          action: () => setShowSendEmailConfirmationModal(true),
          color: 'primary',
          // hide: !driverAppOnboardingToggle,
          disable: model?.list?.ids.length === 0,
        }}
      />    
      <input
        id="xlsxUploader"
        type="file"
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        onChange={onUpload}
        ref={xlsxUploaderRef}
        style={{ display: 'none' }}
      />
    
      
      {/* Send Email Confirmation Button */}
      <StyledModal
        open={showSendEmailConfirmationModal}
        title={`Confirm Send Emails`}
        onCancel={() => setShowSendEmailConfirmationModal(false)}
        onConfirm={onConfirmSendEmails}
        flag={flags.sendingInviteEmails}
      >
        {`Please confirm to send emails`}
      </StyledModal>

      
      {/* Send Email Confirmation */}
      {showSendEmailConfirmation && (
        <StyledModal
          open={showSendEmailConfirmation || isErrored}
          onCancel={() => {
            setShowSendEmailConfirmation(false)
            setIsErrored(false)
            onReset()
          }}
          options={['noButtons']}
          animatedIcon={<div>
            {isErrored
              ? <AnimatedErrorCross size={50} />
              : <AnimatedSuccessTick size={50} />}
            </div>}>
          {isErrored
            ? `Errored occured. Error: ${apiResponse ? JSON.stringify(apiResponse) : ''}`.trim()
            : outputApiResponse('Emails have been sent successfully')
          }
        </StyledModal>
      )}
      
      {/* Upload Confirmation */}
      {showUploadCSVConfirmation && (
        <StyledModal
          open={showUploadCSVConfirmation || isErrored}
          onCancel={() => {
            setShowUploadCSVConfirmation(false)
            setIsErrored(false)
            onReset()
          }}
          options={['noButtons']}
          animatedIcon={<div>
            {isErrored
              ? <AnimatedErrorCross size={50} />
              : <AnimatedSuccessTick size={50} />}
            </div>}>
          {isErrored
            ? `Errored occured. Error: ${apiResponse ? JSON.stringify(apiResponse) : ''}`.trim()
            : outputApiResponse('Your CSV file has been successfully uploaded')
          }
        </StyledModal>
      )}
    </>
  )
}

List.propTypes = {
  model: PropTypes.object.isRequired,
  flags: PropTypes.object.isRequired,
  apiResponse: PropTypes.string,
  onReset: PropTypes.func.isRequired,
  onUploadCsv: PropTypes.func.isRequired,
  onSendEmails: PropTypes.func.isRequired,
}

const mapStateToProps = ({ registrationOfInterest }) => ({
  model: registrationOfInterest,
  flags: registrationOfInterest.flags,
  apiResponse: registrationOfInterest.apiResponse,
})

const mapDispatchToProps = (dispatch) => ({
  onReset: () => {
    dispatch(actions.reset())
  },
  onUploadCsv: (file) => {
    dispatch(actions.uploadCsv(file))
  },
  onSendEmails: () => [
    dispatch(actions.sendInviteEmails())
  ],
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(List)
