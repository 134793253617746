import { colors } from '@material-ui/core'


const white = '#FFFFFF'
const black = '#000000'

const brandRed = '#EB0A1E'
const brandGrey = '#CBCDC9'
const brandDark = '#4D4F53'

export default {
  black,
  white,
  brand: brandRed,
  brandDarker: '#cc0718',
  dark: brandDark,
  light: brandGrey,
  primary: {
    contrastText: white,
    dark: '#323437',
    main: brandDark,
    light: brandGrey
  },
  secondary: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue['A400'],
    light: colors.blue['A400']
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: brandRed,
    light: colors.red[100]
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[400],
    light: colors.green[100]
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    tertiary: colors.grey[400],
    link: colors.blue[600]
  },
  link: colors.blue[800],
  icon: colors.blueGrey[600],
  background: {
    default: '#F4F6F8',
    paper: white,
    block: '#fafafa',
  },
  divider: colors.grey[200],
}
