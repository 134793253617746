import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Divider, Typography } from '@material-ui/core'
import { SingleSelect } from 'frame/components'
import { createOnFilter } from 'filter'


const useStyles = makeStyles(theme => ({
  root: {
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 0),
    cursor: 'pointer',
  },
  content: {},
  formGroup: {
    padding: theme.spacing(2, 0),
  },
}))

const FilterAttributes = ({ filters, filtered, onFilter }) => {

  const classes = useStyles()
  const onSelect = createOnFilter(onFilter)
  const hasList = (list) => list.length > 0
  const { fleets, costCentres, locations, fleetControllers, statuses } = filters

  return (
    <div className={classes.root}>
      <div
        className={classes.header}>
        <Typography variant="h5">Filters</Typography>
      </div>
      <Divider />
        <div className={classes.content}>                   
        {hasList(fleets) && (
            <div className={classes.formGroup}>
              <SingleSelect
                id="fleet"
                label="Fleet"
                options={fleets}
                value={filtered.fleet}
                onChange={onSelect('fleet')} />
            </div>
          )}
          {hasList(costCentres) && (
            <div className={classes.formGroup}>
              <SingleSelect
                id="costCentre"
                label="Cost Centre"
                options={costCentres}
                value={filtered.costCentre}
                onChange={onSelect('costCentre')} />
            </div>
          )}
          {hasList(locations) && (
            <div className={classes.formGroup}>
              <SingleSelect
                id="location"
                label="Location"
                options={locations}
                value={filtered.location}
                onChange={onSelect('location')} />
            </div>
          )}
          {hasList(fleetControllers) && (
            <div className={classes.formGroup}>
              <SingleSelect
                id="controller"
                label="Fleet Controller"
                options={fleetControllers}
                value={filtered.controller}
                onChange={onSelect('controller')} />
            </div>
          )}
          {hasList(statuses) && (
            <div className={classes.formGroup}>
              <SingleSelect
                id="status"
                label="Status"
                options={statuses}
                value={filtered.status}
                onChange={onSelect('status')} />
            </div>
          )}             
        </div>
    </div>
  )
}

FilterAttributes.propTypes = {
  filters: PropTypes.object.isRequired,
  filtered: PropTypes.object.isRequired,
  onFilter: PropTypes.func.isRequired,
}

export default FilterAttributes
