
export const moduleName = 'reportVehicleServicing'

export const RESET = `${moduleName}_reset`

export const FETCH_SERVICE_BOOKING = `${moduleName}_fetch_service_booking`
export const FETCH_SERVICE_BOOKING_ERROR = `${moduleName}_fetch_service_booking_error`
export const FETCH_SERVICE_BOOKING_FULFILLED = `${moduleName}_fetch_service_booking_fulfilled`

export const ADD_SERVICE_BOOKING = `${moduleName}_add_service_booking`
export const ADD_SERVICE_BOOKING_ERROR = `${moduleName}_add_service_booking_error`
export const ADD_SERVICE_BOOKING_FULFILLED = `${moduleName}_add_service_booking_fulfilled`

export const CANCEL_SERVICE_BOOKING = `${moduleName}_cancel_service_booking`
export const CANCEL_SERVICE_BOOKING_ERROR = `${moduleName}_cancel_service_booking_error`
export const CANCEL_SERVICE_BOOKING_FULFILLED = `${moduleName}_cancel_service_booking_fulfilled`

export const SUBMIT_SERVICE_RECORD = `${moduleName}_submit_service_record`
export const SUBMIT_SERVICE_RECORD_ERROR = `${moduleName}_submit_service_record_error`
export const SUBMIT_SERVICE_RECORD_FULFILLED = `${moduleName}_submit_service_record_fulfilled`

export const COMPLETE_SERVICE_BOOKING = `${moduleName}_complete_service_booking`
export const COMPLETE_SERVICE_BOOKING_ERROR = `${moduleName}_complete_service_booking_error`
export const COMPLETE_SERVICE_BOOKING_FULFILLED = `${moduleName}_complete_service_booking_fulfilled`
