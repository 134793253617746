import PropTypes from 'prop-types'


export const model = [{
  key: 'agreementNo',
  label: 'Agreement No.',
  column: {
    visible: false,
    width: 115,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'costCentre',
  label: 'Cost Centre',
  column: {
    visible: true,
    width: 120,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'currentTerm',
  label: 'Current Term',
  column: {
    visible: false,
    width: 110,
  },
  prop: {
    type: PropTypes.number,
    value: '',
  }
}, {
  key: 'driverNote',
  label: 'Driver Note',
  column: {
    visible: false,
    width: 190,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'driver',
  label: 'Driver',
  column: {
    visible: true,
    width: 190,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'fuelType',
  label: 'Fuel Type',
  column: {
    visible: false,
    width: 100,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'make',
  label: 'Make',
  column: {
    visible: false,
    width: 100,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'maturityDate',
  label: `Maturity Date`,
  column: {
    visible: true,
    width: 110,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
},  {
  key: 'originalMaturityDate',
  label: `Original Maturity Date`,
  column: {
    visible: false,
    width: 110,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
},{
  key: 'model',
  label: `Model`,
  column: {
    visible: false,
    width: 100,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'monthsOnVerbal',
  label: `Months On Verbal`,
  column: {
    visible: true,
    width: 150,
  },
  prop: {
    type: PropTypes.number,
  }
}, {
  key: 'registration',
  label: 'Registration',
  column: {
    visible: true,
    width: 110,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'status',
  label: 'Status',
  column: {
    visible: false,
    width: 160,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'term',
  label: 'Term',
  column: {
    visible: true,
    width: 60,
  },
  prop: {
    type: PropTypes.number,
    value: '',
  }
}, {
  key: 'variant',
  label: 'Variant',
  column: {
    visible: false,
    width: 220,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'year',
  label: 'Year',
  column: {
    visible: false,
    width: 80,
  },
  prop: {
    type: PropTypes.number,
    value: '',
  }
}, {
  key: 'accountName',
  label: 'Fleet',
  column: {
    visible: true,
    width: 80,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'documentsCount',
  label: 'Documents',
  column: {
    visible: false,
    width: 100,
  },
  prop: {
    type: PropTypes.number,
    value: null,
  }
}]

const config = {
  title: 'Upcoming Maturities',
  breadcrumbs: null,
  backgroundImage: 'url("/static/images/backgrounds/bg4.png")',
  api: {
    filter: '/filteroptions/upcomingmaturity',
    resource: `/report/upcomingmaturity`,
    download: `/download/upcomingmaturity`,
  },
  model,
  headers: [
    'accountName', 'agreementNo', 'registration', 'year', 'make', 'model', 'variant', 'fuelType', 'driverNote',
    'driver', 'costCentre', 'maturityDate', 'originalMaturityDate', 'term', 'currentTerm', 'monthsOnVerbal', 'status', 'documentsCount',
  ],
  filters: [{
    key: 'costCentre',
    label: 'Cost Centre',
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }, {
    key: 'maturityExpiry',
    label: 'Maturity Expiry',
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }],
}


export default config
