import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'


const logoVariants = {
  default: 'default',
  light: 'light',
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: 207,
    height: 50,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  defaultLogo: {
    backgroundImage: 'url("/static/images/logo.png")',
  },
  lightLogo: {
    backgroundImage: 'url("/static/images/logo-light.png")',
  },
}))

const Brand = ({ className, variant, ...props }) => {

  const classes = useStyles()

  const variantClassName = variant === logoVariants.light ? classes.lightLogo : classes.defaultLogo

  return (
    <div
      className={clsx(classes.root, variantClassName, className)}
      {...props} />
  )
}

Brand.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(['default', 'leftDark', 'marketingLogo']),
}

export default Brand
