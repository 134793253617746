

export const createStateModel = (config) => {

  const customState = config.state || {}
  const item = createItemDefaults(config)

  const state = {
    ...customState,
    api: config.api || null,
    columns: createColumnDefaults(config),
    filters: createFilterDefaults(config),
    filtered: createFilteredDefaults(config),
    idKey: config.idKey || 'agreementNo',
  }

  return { item, state }
}

const createItemDefaults = (options) => {
  const result = {}
  options.model.forEach(node => {
    if (node.prop && node.prop.value !== undefined) {
      result[node.key] = node.prop.value
    }
  })
  return result
}

const createColumnDefaults = (options) => {
  const result = {}
  options.model.forEach(node => {
    if (node.column) {
      result[node.key] = node.column.visible || false
    }
  })
  return result
}

const createFilterDefaults = (options) => {
  const result = {}
  options.filters.forEach(node => {
    result[node.key] = []
  })
  return result
}

const createFilteredDefaults = (options) => {
  const result = {}
  options.filters.forEach(node => {
    if (node.prop && node.prop.value !== undefined) {
      result[node.key] = node.prop.value
    }
  })
  return result
}
