import { combineEpics } from 'redux-observable'
import { makeActionRequestEffect } from 'effects'
import { getAppConfig } from 'app-config'
import join from 'url-join'
import * as fromModuleTypes from './actions/types'


export const createEffects = (moduleActions) => {

  const appConfig = getAppConfig()

  const fetchIncidentAttachmentsEffect = makeActionRequestEffect({
    type: fromModuleTypes.FETCH_INCIDENT_DETAILS,
    url: join(appConfig.app.api, 'report/incidentmanagementdetails'),
    onSuccess: moduleActions.fetchIncidentDetailsFulfilled,
    onError: moduleActions.fetchIncidentDetailsError,
  })

  const saveIncidentNotesEffect = makeActionRequestEffect({
    type: fromModuleTypes.SAVE_INCIDENT_NOTES,
    url: join(appConfig.app.api, 'report/incidentManagement'),
    verb: 'put',
    onSuccess: moduleActions.saveIncidentNotesFulfilled,
    onError: moduleActions.saveIncidentNotesError,
  })

  return combineEpics(
    fetchIncidentAttachmentsEffect,
    saveIncidentNotesEffect,
  )
}
