import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { TableCell, TableRow } from '@material-ui/core'
import { types } from '../../module'
import { AntSwitch, FleetIcon } from 'frame/components'

const useStyles = makeStyles(theme => ({
  root: {
    verticalAlign: 'text-top',
  },
  label: theme.typography.bodySmall,
  spacer: {
    margin: 0,
    padding: 0,
  },
  deleteIcon: {
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.5,
    }
  },
  fleetIcon: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px'
  },
  fleetName:{
    width: '180px'
  }
}))

const Row = ({ item, columns, toggleHandler }) => {

  const classes = useStyles()

  return (
    <TableRow hover className={classes.root}>
      <TableCell className={classes.spacer} />

      {columns.fleet && (
        <TableCell className={classes.label}>
          <div className={`${classes.fleetIcon} ${classes.fleetName}`}>
            <FleetIcon key={`fleet-icon-${item.accountName}`} fleetName={item.accountName} />
            {item.accountName || '-'}
          </div>
        </TableCell>
      )}

      {columns.driverAssignment && (
        <TableCell className={classes.label}>
          <AntSwitch
            style={{ marginLeft: 60 }}
            value={item.active}
            onChange={() => toggleHandler(item)}
            name="featureSettingSwitch"
          />
        </TableCell>
      )}

      <TableCell className={classes.spacer} />
    </TableRow>
  )
}

Row.propTypes = {
  item: PropTypes.oneOfType([
    PropTypes.object,
    types.ModelType,
  ]).isRequired,
  columns: types.ColumnsType.isRequired,
  toggleHandler: PropTypes.func
}

export default Row
