import moment from 'moment'
import { useEnabledFeatureToggleList } from 'features/settings'
import { getAppConfig } from 'app-config'
export * from './module'
export { ModuleRoutes } from './routes'
export { AssignFleetLookupField } from './components'



export const useIsAlfaDown = () => { // time to start showing warning message - only used in top-bar.js for banner under navbar
  // default hours
  const config = getAppConfig()
  const showWarningMessageStartTime = moment(config.app.readonly.start, 'H:mm').subtract(10, 'minutes'); // after yesterday's 5:50 pm

  const showWarningMessageEndTime = moment(config.app.readonly.end, 'H:mm') // before today's 6:00 am

  // custom hours
  const useCustomHours = false // set true to enabled custom hours
  const CustomShowWarningMessageStartTime = '9:50' // after 9:50 am
  const CustomShowWarningMessageEndTime = '11:00' // before 11:00 am

  const showWarningMessage = useCustomHours ? (
    moment().isAfter(moment(CustomShowWarningMessageStartTime, 'H:mm')) || moment().isBefore(moment(CustomShowWarningMessageEndTime, 'H:mm'))
  ) : (
    // today is after 5:50 pm or today is before 6:00 am 
    moment().isAfter(showWarningMessageStartTime) || moment().isBefore(showWarningMessageEndTime)
  )
  // console.log('useIsAlfaDown: ', showWarningMessage)
  return showWarningMessage
}

export const useIsAlfaDownDisableFeatures = () => { // time to disable driver features // time to disable driver features
  const config = getAppConfig()
  const featureToggleList = useEnabledFeatureToggleList()
  const isReadonlyFeatureToggleEnabled = featureToggleList.includes('TFP2063')
  // default hours
  const downTimeStart = moment(config.app.readonly.start, 'H:mm') // after 6:00 pm
  const downTimeEnd = moment(config.app.readonly.end, 'H:mm') // before 6:00 am

  // custom hours
  const useCustomHours = false // set true to enabled custom hours
  const CustomDownTimeStart = '10:00' // after 10:00 am
  const CustomDownTimeEnd = '11:00' // before 11:00 am

  const disableDriverFeatures = useCustomHours ? (
    moment().isAfter(moment(CustomDownTimeStart, 'H:mm')) || moment().isBefore(moment(CustomDownTimeEnd, 'H:mm'))
  ) : (
    // today is after 6:00 pm or today is before 6:00 am
    moment().isAfter(downTimeStart) || moment().isBefore(downTimeEnd)
  )
  // console.log('useIsAlfaDownDisableDriverFeatures: ', disableDriverFeatures)
  return disableDriverFeatures || isReadonlyFeatureToggleEnabled
}