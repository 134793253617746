export const isMiles = (sourceSystem) => {
    if (sourceSystem == null) {
        return false;
    } else {
        let system = sourceSystem.toLowerCase();
        return system === "miles" || system === "both";
    }
}
export const isAlfa = (sourceSystem) => {
    if (sourceSystem == null) {
        return false;
    } else {
        let system = sourceSystem.toLowerCase();
        return system === "alfa" || system === "both";
    }
}
export const isSourceSystemLoaded = (sourceSystem) => {
    return sourceSystem != null;
}
  
  