import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { makeStyles } from '@material-ui/styles'
import { colors, TableCell, TableRow, Typography, Tooltip, IconButton } from '@material-ui/core'
import { getDateColor, /*getKmUseRateColor,*/ getWoFStatusColor, obfuscateAgreementNumber } from 'listable'
import { createRowSelector, useRouter } from 'utils'
import { Label, FleetIcon } from 'frame/components'
import RowActions from './row-options'
import { types } from '../../module'
import { useEnabledFeatureToggleList } from 'features/settings'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'


const useStyles = makeStyles(theme => ({
  root: {
    cursor: 'pointer',
    verticalAlign: 'text-top',
  },
  centerCell: {
    display: 'flex',
    justifyContent: 'center',
  },
  spacer: {
    margin: 0,
    padding: 0,
  },
  link: {
    color: theme.palette.text.link,
    textDecoration: 'none',
    cursor: 'pointer'
  },
  modalContent: {
    marginLeft: theme.spacing(-3),
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(0, 3),
      width: '500px',
    },
  },
  label: theme.typography.bodySmall,
  documentIcon: { color: '#00B6B0' },
  unReliableTooltip: {
    backgroundColor: '#FFF',
    color: theme.palette.brand,
    filter: 'drop-shadow(0px 1.5px 1.5px gray)'
  }
}))

const Row = ({ account, item, columns, onView }) => {
  const classes = useStyles()
  const { history, location } = useRouter()
  //const [viewAccessories, setViewAccessories] = useState(false)
  const onSafeRowClick = createRowSelector(onView)
  //const isPremium = account === 'premium'

  // const onViewAccessories = () =>
  //   isLengthyAccessories && setViewAccessories(true)

  // const isLengthyAccessories = item.accessories.length > 30
  // const accessories = item.accessories && item.accessories.split(', ')

  const featureToggleList = useEnabledFeatureToggleList()
  const tfp1599FeatureToggle = featureToggleList.includes('TFP1599')
  const documentsReportFeatureToggle = featureToggleList.includes('DocumentsViewing')
  const tfp1929FeatureToggle = featureToggleList.includes('TFP1929')
  const tfp1845FeatureToggle = featureToggleList.includes('TFP1845')
  const tfp1944FeatureToggle = featureToggleList.includes('TFP1944')
  const tfp2005FeatureToggle = featureToggleList.includes('TFP2005')


  const onViewDocumentsReport = () => {
    history.push(`/reports/documents?registration=${item.registration}`, { label: 'Fleet',  path: location.pathname })
  }

  return (
    <TableRow
      hover
      className={classes.root}
      // onClick={columns.accessories && viewAccessories ? false : onSafeRowClick}>
      onClick={onSafeRowClick}>
      <TableCell className={classes.spacer} />
      {columns.accountName && (
        <TableCell className={classes.label}>          
          <FleetIcon fleetName={item.accountName} />
        </TableCell>
      )}
      {columns.agreementNo && (
        <TableCell className={classes.label}>
          {obfuscateAgreementNumber(item.agreementNo || '-', true)}
        </TableCell>
      )}
      {columns.agreementType && (
        <TableCell className={classes.label}>
          {item.agreementType || '-'}
        </TableCell>
      )}
      {columns.registration && (
        <TableCell className={classes.label}>
          {item.registration || '-'}
        </TableCell>
      )}
      {columns.assetBranch && (
        <TableCell className={classes.label}>
            {item.assetBranch || '-'}
        </TableCell>
      )}
      {columns.assetFunction && (
        <TableCell className={classes.label}>
          {item.assetFunction || '-'}
        </TableCell>
      )}              
      {columns.driverNote && tfp1944FeatureToggle && (
        <TableCell className={classes.label}>
          <Typography color="inherit" variant="body2">
            {item.driverNote || '-'}
          </Typography>
        </TableCell>
      )}      
      {columns.driver && (
        <TableCell className={classes.label}>
          <Typography color="inherit" variant="body2">
            {item.driver || '-'}
          </Typography>
        </TableCell>
      )}
      {columns.year && (
        <TableCell className={classes.label}>
          {item.year || '-'}
        </TableCell>
      )}
      {columns.make && (
        <TableCell className={classes.label}>
          {item.make || '-'}
        </TableCell>
      )}
      {columns.model && (
        <TableCell className={classes.label}>
          {item.model || '-'}
        </TableCell>
      )}
      {columns.colour && (
        <TableCell className={classes.label}>
          {item.colour || '-'}
        </TableCell>
      )}
      {columns.noOfTyres && (
        <TableCell className={classes.label}>
          <div className={classes.centerCell}>
            {item.noOfTyres || '-'}
          </div>
        </TableCell>
      )}
      {columns.tyresRemaining && (
        <TableCell className={classes.label}>
          <div className={classes.centerCell}>
            {tfp1599FeatureToggle ? item.tyresRemaining : '-' || '-'}
          </div>
        </TableCell>
      )}      
      {columns.costCentre && (
        <TableCell className={classes.label}>
          {item.costCentre || '-'}
        </TableCell>
      )}
      {columns.startDate && (
        <TableCell className={classes.label}>
          <div className={classes.centerCell}>
            {item.startDate ? moment(item.startDate).format('DD/MM/YY') : '-'}
          </div>
        </TableCell>
      )}
      {columns.maturityDate && (
        <TableCell className={classes.label}>
          <div className={classes.centerCell}>
            {item.maturityDate ? (
              <Label
                color={getDateColor(item.maturityDate)}
                variant="outlined">
                {moment(item.maturityDate).format('DD/MM/YY')}
              </Label>
            ) : '-'}
          </div>
        </TableCell>
      )}
      {columns.originalMaturityDate && tfp2005FeatureToggle && (
        <TableCell className={classes.label}>
          <div className={classes.centerCell}>
            {item.originalMaturityDate ? moment(item.originalMaturityDate).format('DD/MM/YY') : '-'}
          </div>
        </TableCell>
      )}
      {columns.term && (
        <TableCell className={classes.label}>
          <div className={classes.centerCell}>
            {item.term || '-'}
          </div>
        </TableCell>
      )}
      {columns.variationsCount && (
        <TableCell className={classes.label}>
          <div className={classes.centerCell}>
          {item.variationsCount > 0 ? (
              <Label
                color={colors.green[600]}
                variant="contained">
                {item.variationsCount}
              </Label>
            ) : '-'}
          </div>
        </TableCell>
      )}
      {columns.contractKm && (
        <TableCell className={classes.label}>
          {item.contractKm ? parseInt(item.contractKm, 10).toLocaleString('en-NZ') : '-'}
        </TableCell>
      )}
      {columns.kmUseRate && (
        <TableCell className={classes.label}>
          <div className={classes.centerCell}>
            {item.kmUseRate ? parseInt(item.kmUseRate, 10).toLocaleString('en-NZ') : '-'}
          </div>
        </TableCell>
      )}
      {columns.contractEndKm && tfp1845FeatureToggle && (
        <TableCell className={classes.label}>
          {item.contractEndKm ? parseInt(item.contractEndKm, 10).toLocaleString('en-NZ') : '-'}
        </TableCell>
      )}      
      {columns.location && (
        <TableCell className={classes.label}>
          {item.location || '-'}
        </TableCell>
      )}
      {columns.vehicleNotes && tfp1929FeatureToggle && (
        <TableCell className={classes.label}>
          {item.vehicleNotes || '-'}
        </TableCell>
      )}
      {columns.controller && (
        <TableCell className={classes.label}>
          {item.controller ? (
            <Typography color="inherit" variant="body2">
              {item.controller}
            </Typography>
          ) : '-'}
        </TableCell>
      )}    
      {columns.lastOdoReading && (
        <TableCell className={classes.label}>
          <div className={classes.centerCell}>
            {item.lastOdoReading ? parseInt(item.lastOdoReading, 10).toLocaleString('en-NZ') : '-'}
          </div>
        </TableCell>
      )}        
      {columns.lastKmReading && (
        <TableCell className={classes.label}>
          <div className={classes.centerCell}>
            {item.lastKmReading ? moment(item.lastKmReading).format('DD/MM/YY') : '-'}
          </div>
        </TableCell>
      )}      
      {columns.budgetedKmYear && (
        <TableCell className={classes.label}>
          {item.budgetedKmYear ? parseInt(item.budgetedKmYear, 10).toLocaleString('en-NZ') : '-'}
        </TableCell>
      )}
      {columns.wofExpiry && (
        <TableCell className={classes.label}>
          <div className={classes.centerCell}>
            {item.wofExpiry ? (
              <Label
                color={getWoFStatusColor(item.wofStatus)}
                variant="outlined">
                {moment(item.wofExpiry).format('DD/MM/YY')}
              </Label>
            ) : '-'}
          </div>
        </TableCell>
      )}
      {columns.wofStatus && (
        <TableCell className={classes.label}>
          <div className={classes.centerCell}>
            {item.wofExpiry ? (
              <Label
                color={getWoFStatusColor(item.wofStatus)}
                variant="outlined">
                {item.wofStatus}
              </Label>
            ) : '-'}
          </div>
        </TableCell>
      )}
      {columns.registrationExpiry && (
        <TableCell className={classes.label}>
          <div className={classes.centerCell}>
            {item.registrationExpiry ? (
              <Label
                color={getWoFStatusColor(item.registrationStatus)}
                variant="outlined">
                {moment(item.registrationExpiry).format('DD/MM/YY')}
              </Label>
            ) : '-'}
          </div>
        </TableCell>
      )}
      {columns.registrationStatus && (
        <TableCell className={classes.label}>
          <div className={classes.centerCell}>
            {item.registrationExpiry ? (
              <Label
                color={getWoFStatusColor(item.registrationStatus)}
                variant="outlined">
                {item.registrationStatus}
              </Label>
            ) : '-'}
          </div>
        </TableCell>
      )}
      {columns.regularInstalment && (
        <TableCell className={classes.label}>
          <div className={classes.centerCell}>
            {item.regularInstalment ? item.regularInstalment.toLocaleString('en-NZ', {
              style: 'currency',
              currency: 'NZD',
            }) : '-'}
          </div>
        </TableCell>
      )}
      {columns.documentsCount && documentsReportFeatureToggle && (
        <TableCell className={classes.label}>
          {( item.documentsCount > 0 ? (            
            <Tooltip title="View documents">
              <IconButton className="ignore" onClick={onViewDocumentsReport}>
                <InsertDriveFileIcon className={classes.documentIcon} />
              </IconButton>
            </Tooltip>
          ) : '-')}
        </TableCell>
      )}

      <TableCell align="right">
        <RowActions className="ignore" item={item} onView={onView} />
      </TableCell>
    </TableRow>
  )
}

Row.propTypes = {
  account: PropTypes.string,
  item: types.ModelType.isRequired,
  columns: types.ColumnsType.isRequired,
  onView: PropTypes.func.isRequired,
}

export default Row
