import { reduceRequest } from 'listable'
import fromConstants from './constants'
import * as R from 'ramda'
import * as fromAuth from 'auth'


const initialState = {
  profile: {
    email: '',
    nickname: '',
    picture: '',
  },
  requests: {},
  responseMessage: '',
  verification: {
    responseMessage: '',
  }
}

const reducer = (state = initialState, action) => {

  switch (action.type) {
    case fromConstants.RESET:
      return { ...initialState }
    
    case fromConstants.SET_PROFILE:
      return setProfile(state, action)
    
    case fromConstants.UPDATE_PROFILE:
      return updateProfile(state, action)
    
    case fromConstants.PUT_PASSWORD:
    case fromConstants.PUT_PASSWORD_FULFILLED:
    case fromConstants.PUT_PROFILE:
    case fromConstants.PUT_PROFILE_FULFILLED:
      return reduceRequest(state, action)
    
    case fromConstants.PUT_PASSWORD_ERROR:
    case fromConstants.PUT_PROFILE_ERROR: {
      const next = onError(R.clone(state), action)
      return reduceRequest(next, action)
    }
    
    case fromAuth.AUTHORIZATION_VERIFICATION_FULFILLED: // Portal Invite Verification
    case fromAuth.AUTHORIZATION_VERIFICATION_FAILED:
    case fromAuth.AUTHORIZATION_DRIVER_VERIFICATION_FULFILLED: // Driver App Invite Verification
      return setVerificaionResponseMessage(R.clone(state), action)
    
    case fromAuth.AUTHORIZATION_VERIFICATION_CLEAR_MESSAGE:
    case fromAuth.AUTHORIZATION_DRIVER_VERIFICATION_CLEAR_MESSAGE:
      return clearVerificationMessage(R.clone(state), action)
    
    default:
      return state
  }
}

export default reducer

const setVerificaionResponseMessage = (state, { payload }) => {
  if(payload && payload.success) {
    state.verification = {
      success: true,
      responseMessage: ''
    }
    return state
  }
  console.log('payload ', payload)
  state.verification = {
    success: false,
    responseMessage: payload.message
  }
  return state
}

const onError = (state, { payload }) => {
  const error = payload
  state.errorMessage = typeof error === 'string' ? payload : ''
  return state
}

const setProfile = (state, action) => {

  let role = action.payload[Object.keys(action.payload)[0]]
  let fleets = action.payload[Object.keys(action.payload)[1]]

  return {
    ...state,
    profile: {
      ...action.payload,
      role,
      fleets,
    }
  }
}

const updateProfile = (state, action) => ({
  ...state,
  profile: {
    ...state.profile,
    email: action?.payload?.email || state?.profile?.email || '',
    nickname: action?.payload?.nickname || state?.profile?.nickname || ''
  }
})

const clearVerificationMessage = (state, action) => {
  state.responseMessage = ''
  if (state.verification) {
    state.verification.responseMessage = ''
    state.verification.success = false
  }
  return state
}
