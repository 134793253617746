import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, useTheme } from '@material-ui/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { Button, Card, CardContent, Grid, Typography } from '@material-ui/core'
import { RowValueText, OfflineLookupField, StyledModal } from 'frame/components'
import { useNavigate } from 'utils'
import { constants } from '../../module'
import moment from 'moment'


const useStyles = makeStyles(theme => ({
  content: {
    minHeight: 200,
    padding: theme.spacing(3, 1.2, 3, 1.2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
  },
  question: {
    marginTop: theme.spacing(2),
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    }
  },
  button: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    minWidth: 180,
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
    }
  },
}))

const Form = ({ values, filtered, setValues, onClose, onProceed, onLookup, onSave }) => {

  const theme = useTheme()
  const classes = useStyles()
  const navigate = useNavigate()
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'))

  const [showQuestion, setShowQuestion] = useState(false)
  const [existingSubmissionId, setExistingSubmissionId] = useState(null)
  const [showConfirmSubmitModal, setShowConfirmSubmitModal] = useState(false)

  const onDriverNameChange = (option) => {
    console.log('[onDriverNameChange]: ', option)
    setValues({ ...values, driverName: option.text, costCentre: option?.costCentre })
    setExistingSubmissionId(option?.existingSubmissionId)
  }

  const onInputChange = (key) => (value) =>
    setValues({ ...values, [key]: value?.target?.value || value?.value || value || null })

  const onAddFbtSubmission = () => { // for all days of the month
    const payload = {
      ...values,
      availableForPvtUseAllMonth: true,
      entries: [],
    }
    console.log('[onAddFbtSubmission]: ', payload)
    onSave(payload)
    onClose()
  }

  useEffect(() => {    
    setShowQuestion(!!values?.driverName)
  }, [values?.driverName]) // eslint-disable-line

  const onNavigateToEdit = navigate(constants.PATH_SINGLE, existingSubmissionId, `view-submissions`)

  return (
    <ValidatorForm autoComplete="off" noValidate onSubmit={() => {}}>
      <Card>
        <CardContent className={classes.content}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h4">
                Add a new driver record
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <RowValueText label="Driver" responsive>
                {/* <LookupField
                  fullWidth
                  size="small"
                  id="driverName"
                  label="Enter Driver Name"
                  lookupPath="drivernamefbt"
                  value={values?.driverName}
                  variant="outlined"
                  validators={['required']}
                  errorMessages={['This field is required']}
                  onChange={onDriverNameChange}
                  onLookup={onLookup}
                  resourceModel="fbtSubmissions"
                  customFleetId={values?.thirdPartyNumber}
                  // customOptionsFilter={(data) => data.filter(x => !x.text.includes('Pool Vehicle -'))}
                /> */}
                <OfflineLookupField
                  fullWidth showAllResult
                  size="small"
                  id="driverName"
                  label={mobileView ? 'Enter Driver Name' : ''}
                  customFleetId={values?.thirdPartyNumber}
                  lookupPath="drivernamefbt"
                  value={values?.driverName || ''}
                  variant="outlined"
                  onChange={onDriverNameChange}
                  refetchOnChange={values?.thirdPartyNumber}
                  onLookup={onLookup(
                    filtered?.year ? filtered?.year : moment().format('YYYY'),
                    filtered?.month ? moment().month(filtered?.month).format('M') : moment().subtract(1, 'months').format('M')
                  )}
                  resourceModel="fbtSubmissions"
                />
              </RowValueText>
            </Grid>
            
            <Grid item xs={12}>
              <RowValueText label="Cost Centre" responsive={!!values?.driverName}>
                {values?.driverName ? (
                  <OfflineLookupField
                    fullWidth showAllResult allowFreeText
                    size="small"
                    id="costCentre"
                    label={mobileView ? 'Cost Centre' : ''}
                    customFleetId={values?.thirdPartyNumber}
                    lookupPath="drivercostcentre"
                    value={values?.costCentre || ''}
                    variant="outlined"
                    onChange={onInputChange('costCentre')}
                    defaultOptions={values?.costCentre ? [{ text: values.costCentre, value: values.costCentre }] : []}
                    onLookup={onLookup(null, null)}
                    resourceModel="fbtSubmissions"
                    refetchOnChange={values?.driverName}
                    resetDefaultValueOnChange={values?.driverName}
                  />
                ) : (
                  <Typography variant="body1">
                    -
                  </Typography>
                )}
              </RowValueText>
            </Grid>
          </Grid>

        </CardContent>
      </Card>
      
      {showQuestion && !existingSubmissionId && (
        <>
          <Typography variant="h6" align="center" className={classes.question}>
            Was {values?.driverName}'s vehicle available for private use for
            <br/><strong>all days of last month?</strong>
          </Typography>

          <div className={classes.actions}>
            <Button className={classes.button} variant="contained" onClick={onProceed}>
              No
            </Button>
            <Button className={classes.button} color="primary" variant="contained" onClick={() => setShowConfirmSubmitModal(true)}>
              Yes
            </Button>
          </div>
        </>
      )}

      {!!existingSubmissionId && (
        <>
          <Typography variant="h6" align="center" className={classes.question}>
            {values?.driverName} already has a submission
          </Typography>

          <div className={classes.actions}>
            <Button className={classes.button} color="primary" variant="contained" onClick={onNavigateToEdit}>
              Click Here To Edit Submission
            </Button>
          </div>
        </>
      )}
      
      {/* for save confirmation */}
      <StyledModal
        open={showConfirmSubmitModal}
        title={`Confirm Submission`}
        onCancel={() => setShowConfirmSubmitModal(false)}
        onCancelLabel="Go Back"
        onConfirm={() => {
          setShowConfirmSubmitModal(false)
          onAddFbtSubmission()
        }}>
        {`Please confirm you have completed ${values?.driverName}'s submission and their vehicle was available for private use for all days of last month.`}
      </StyledModal>
      
    </ValidatorForm>
  )
}

Form.propTypes = {
  values: PropTypes.object,
  filtered: PropTypes.object,
  // func
  setValues: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onProceed: PropTypes.func.isRequired,
  onLookup: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
}

export default Form
