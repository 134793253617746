import React from 'react'
import moment from 'moment'
import { makeStyles } from '@material-ui/styles'
import { TableCell, TableRow, Typography, Tooltip, IconButton } from '@material-ui/core'
import { Label, FleetIcon } from 'frame/components'
import { getWoFStatusColor, obfuscateAgreementNumber } from 'listable'
import { types } from '../../module'
import { useRouter } from 'utils'
import { useEnabledFeatureToggleList } from 'features/settings'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'


const useStyles = makeStyles(theme => ({
  root: {
    verticalAlign: 'text-top',
  },
  centerCell: {
    display: 'flex',
    justifyContent: 'center',
  },
  label: theme.typography.bodySmall,
  spacer: {
    margin: 0,
    padding: 0,
  },
  documentIcon: { color: '#00B6B0' },
}))

const Row = ({ item, columns }) => {

  const classes = useStyles()
  const { history, location } = useRouter()

  const featureToggleList = useEnabledFeatureToggleList()
  const documentsReportFeatureToggle = featureToggleList.includes('DocumentsViewing')
  const tfp1944FeatureToggle = featureToggleList.includes('TFP1944')
  const tfp1935FeatureToggle = featureToggleList.includes('TFP1935')

  const onViewDocumentsReport = () => {
    history.push(`/reports/documents?registration=${item.registration}`, { label: 'WoF & Registration',  path: location.pathname })
  }

  return (
    <TableRow
      hover
      className={classes.root}>
      <TableCell className={classes.spacer} />
      {columns.accountName && (
        <TableCell className={classes.label}>
          <FleetIcon fleetName={item.accountName} />
        </TableCell>
      )}
      {columns.agreementNo && (
        <TableCell className={classes.label}>
          {obfuscateAgreementNumber(item.agreementNo || '-', true)}
        </TableCell>
      )}
      {columns.registration && (
        <TableCell className={classes.label}>
          {item.registration || '-'}
        </TableCell>
      )}
      {columns.driverNote && tfp1944FeatureToggle && (
        <TableCell className={classes.label}>
          <Typography color="inherit" variant="body2">
            {item.driverNote || '-'}
          </Typography>
        </TableCell>
      )}      
      {columns.driver && (
        <TableCell className={classes.label}>
          <Typography
            color="inherit"
            variant="body2">
            {item.driver || '-'}
          </Typography>
        </TableCell>
      )}
      {columns.year && (
        <TableCell className={classes.label}>
          {item.year || '-'}
        </TableCell>
      )}
      {columns.make && (
        <TableCell className={classes.label}>
          {item.make || '-'}
        </TableCell>
      )}
      {columns.model && (
        <TableCell className={classes.label}>
          {item.model || '-'}
        </TableCell>
      )}
      {columns.variant && (
        <TableCell className={classes.label}>
          {item.variant || '-'}
        </TableCell>
      )}
      {columns.fuelType && (
        <TableCell className={classes.label}>
          {item.fuelType || '-'}
        </TableCell>
      )}
      {columns.costCentre && (
        <TableCell className={classes.label}>
          {item.costCentre || '-'}
        </TableCell>
      )}
      {columns.controller && tfp1935FeatureToggle && (
        <TableCell className={classes.label}>
          {item.controller || '-'}
        </TableCell>
      )}      
      {columns.wofExpiry && (
        <TableCell className={classes.label}>
          <div className={classes.centerCell}>
            {item.wofExpiry ? (
              <Label
                color={getWoFStatusColor(item.wofStatus)}
                variant="outlined">
                {moment(item.wofExpiry).format('DD/MM/YY')}
              </Label>
            ) : '-'}
          </div>
        </TableCell>
      )}
      {columns.wofStatus && (
        <TableCell className={classes.label}>
          {(item.wofExpiry && item.wofStatus) ? (
            <Label
              // color={getDateColor(item.wofExpiry)}
              color={getWoFStatusColor(item.wofStatus)}
              variant="outlined">
              {item.wofStatus}
            </Label>
          ) : '-'}
        </TableCell>
      )}
      {columns.registrationExpiry && (
        <TableCell className={classes.label}>
          <div className={classes.centerCell}>
            {item.registrationExpiry ? (
              <Label
                color={getWoFStatusColor(item.registrationStatus)}
                variant="outlined">
                {moment(item.registrationExpiry).format('DD/MM/YY')}
              </Label>
            ) : '-'}
          </div>
        </TableCell>
      )}
      {columns.registrationStatus && (
        <TableCell className={classes.label}>
          {(item.registrationStatus) ? (
            <Label
              color={getWoFStatusColor(item.registrationStatus)}
              variant="outlined">
              {item.registrationStatus}
            </Label>
          ) : '-'}
        </TableCell>
      )}
      {columns.agreementType && (
        <TableCell className={classes.label}>
          {item.agreementType || '-'}
        </TableCell>
      )}      
      {columns.documentsCount && documentsReportFeatureToggle && (
        <TableCell className={classes.label}>
          {( item.documentsCount > 0 ? (            
            <Tooltip title="View documents">
              <IconButton className="ignore" onClick={onViewDocumentsReport}>
                <InsertDriveFileIcon className={classes.documentIcon} />
              </IconButton>
            </Tooltip>
          ) : '-')}
        </TableCell>
      )}
      <TableCell className={classes.spacer} />
    </TableRow>
  )
}

Row.propTypes = {
  item: types.ModelType.isRequired,
  columns: types.ColumnsType.isRequired,
}

export default Row
