import { connect } from 'react-redux'
import New from './new'
import { create, fetchContract, lookup, reset } from '../../actions'


const mapStateToProps = ({ settings, requestReliefVehicles }) => ({
  fleetIds: settings.fleet.selected,
  relatedContract: requestReliefVehicles.related,
  fetchContractStatus: requestReliefVehicles.flags?.fetchContract || 'none',
  saveStatus: requestReliefVehicles.flags?.create || 'none',
  errorMessage: requestReliefVehicles.errorMessage,
  userRoles: settings.roles,
})

const mapDispatchToProps = (dispatch) => ({
  onFetchContract: (agreementNo, fleetIds) => {
    const FleetId = fleetIds.join(',')
    dispatch(fetchContract(null, {
      resourceId: agreementNo,
      queryParams: { FleetId }
    }))
  },
  onSave: (payload) => {
    dispatch(create(payload))
  },
  onLookup: (lookupPath, searchKey, customFleetId) => {
    dispatch(lookup(null, {
      resourceId: lookupPath,
      queryParams: { FleetId: customFleetId, Search: searchKey }
    }))
  },
  onReset: () => {
    dispatch(reset())
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(New)
