import { combineEpics } from 'redux-observable'
import { getAppConfig } from 'app-config'
import { makeActionRequestEffect } from 'effects'
import join from 'url-join'
import * as constants from './constants'
import * as actions from './actions'
import { mergeMap } from 'rxjs/operators';

export const createEffects = (moduleActions) => {

  const appConfig = getAppConfig()

  const enableMyDriveAppToggle = makeActionRequestEffect({
    type: constants.REQUEST_MYDRIVEAPP_TOGGLE,
    url: join(appConfig.app.api, '/featuresmydriveapp/toggle'),
    verb: 'put',
    onSuccess: actions.requestToggleFeatureFulfilled,
    onError: actions.requestToggleFeatureError,
    mapOperator: mergeMap
  })

  return combineEpics(
    enableMyDriveAppToggle,
  )
}
