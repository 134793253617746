import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { IconButton, Toolbar } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import Brand from '../brand'
import MobileNavigation from './mobile-navigation'
import { Ribbon } from 'frame/components'


const useStyles = makeStyles(theme => ({
  root: { flexGrow: 1 },
  inner: {
    flex: 1,
    minHeight: 74,
    background: 'linear-gradient(0.25turn, #022946, #01B6B1)', //theme.palette.dark,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  brand: { display: 'flex' },
  navList: { display: 'flex', paddingTop: 4 },
  filler: { flex: 1 },
  icon: {
    '& svg': { fill: theme.palette.light },
  },
}))

  const TopBarMobile = ({ showAlfaOutageMessage, showIsPortalReadOnlyMessage }) => {

  const classes = useStyles()
  const [open, setOpen] = useState(false)

  return (
    <div className={classes.root}>
      <Toolbar className={classes.inner} position="static">
        <RouterLink className={classes.brand} to="/">
          <Brand variant="light" />
        </RouterLink>
        <div className={classes.filler} />
        <div className={classes.navList}>
          <IconButton
            color="inherit"
            className={classes.icon}
            onClick={() => setOpen(true)}>
            <MenuIcon />
          </IconButton>
        </div>
      </Toolbar>
      
      <MobileNavigation open={open} onClose={() => setOpen(false)} />

      {
      showIsPortalReadOnlyMessage ? (
        <Ribbon text="READ-ONLY: Please note, the Fleet Portal is in read-only mode between the hours of 6pm and 6am." />
      ) :
      showAlfaOutageMessage && (
        <Ribbon text="READ-ONLY: Please note, the ability to add/edit driver accounts and vehicle assignment is unavailable between the hours of 6pm and 6am."/>
      ) 
      }
    </div>
  )
}

TopBarMobile.propTypes = {
  showAlfaOutageMessage: PropTypes.bool,
  showIsPortalReadOnlyMessage: PropTypes.bool,
}

export default TopBarMobile
