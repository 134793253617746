import MaturitiesIcon from '@material-ui/icons/InsertInvitation'
import ServiceIcon from '@material-ui/icons/Build'
import KMVariationsIcon from '@material-ui/icons/SettingsEthernet'
import OnOrderIcon from '@material-ui/icons/LocalShipping'
import WoFIcon from '@material-ui/icons/ConfirmationNumber'
import InertiaResultsIcon from '@material-ui/icons/Equalizer'
import LeaseEndIcon from '@material-ui/icons/Schedule'
import { ReactComponent as IncidentIcon } from 'assets/icons/incident-menu-icon.svg'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'

import { constants as fromEndOfLease } from 'features/report-end-of-lease'
import { constants as fromExtensionRentals } from 'features/report-extension-rentals'
import { constants as fromKmTracking } from 'features/report-km-tracking'
import { constants as fromUpcomingMaturities } from 'features/report-upcoming-maturities'
import { constants as fromVehicleServicing } from 'features/report-vehicle-servicing'
import { constants as fromVehiclesOnOrder } from 'features/report-vehicles-on-order'
import { constants as fromWofRego } from 'features/report-wof-rego'
import { constants as fromIncidentManagement } from 'features/report-incident-management'
import { constants as fromDocuments } from 'features/report-documents'
import { useEnabledFeatureToggleList } from 'features/settings'


const Navigation = () => {
  
  const featureToggleList = useEnabledFeatureToggleList()
  const documentsReportFeatureToggle = featureToggleList.includes('DocumentsViewing')

  const reportsNav = [
    {
      title: 'Reports',
      pages: [
      {
        title: 'Upcoming Maturities',
        href: fromUpcomingMaturities.PATH_ALL,
        icon: MaturitiesIcon,
        visible: true
      }, {
        title: 'Vehicles On-Order',
        href: fromVehiclesOnOrder.PATH_ALL,
        icon: OnOrderIcon,
        visible: true
      }, {
        title: 'Vehicle Servicing',
        href: fromVehicleServicing.PATH_ALL,
        icon: ServiceIcon,
        visible: true
      }, {
        title: 'KM Tracking',
        href: fromKmTracking.PATH_ALL,
        icon: KMVariationsIcon,
        visible: true
      }, {
        title: 'WoF & Registration',
        href: fromWofRego.PATH_ALL,
        icon: WoFIcon,
        visible: true
      }, {
        title: 'Extension Rentals',
        href: fromExtensionRentals.PATH_ALL,
        icon: InertiaResultsIcon,
        visible: true
      }, {
        title: 'End of Lease',
        href: fromEndOfLease.PATH_ALL,
        icon: LeaseEndIcon,
        visible: true
      }, {
        title: 'Incident Management',
        href: fromIncidentManagement.PATH_ALL,
        icon: IncidentIcon,
        visible: true
      }, {
        title: 'Documents',
        href: fromDocuments.PATH_ALL,
        icon: InsertDriveFileIcon,
        visible: documentsReportFeatureToggle
      }].filter(x => x.visible)
    },
  ]
  return reportsNav
}

export default Navigation
