import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, useTheme } from '@material-ui/styles'
import { Button } from '@material-ui/core'
import { useDrillDownModalOnClose, useNavigate } from 'utils'
import { useEnabledFeatureToggleList, useSelectedPremiumFleets } from 'features/settings'
import { useLatestFbtSubmission } from 'features/fbt/navigation-config'
import { fetchRelatedFbt } from '../../../actions'

// views
import FbtTable from './fbt-table'
import { FbtRoiForm } from 'features/fbt'


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 1.2, 3, 1.2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
  },
  heading: {
    fontWeight: 'bold',
    color: '#7099DB',
  },
  content: {
    marginTop: theme.spacing(3),
  },
  button: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    minWidth: 180,
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
    }
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    }
  }
}))

const FBT = ({ data, relatedFbt, onFetchRelatedFbt }) => {

  const theme = useTheme()
  const classes = useStyles()
  const navigate = useNavigate()
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'))
  const fbtFleets = useSelectedPremiumFleets()

  const latestFbtSubmission = useLatestFbtSubmission()
  const onClose = useDrillDownModalOnClose()

  const featureToggleList = useEnabledFeatureToggleList()
  const fbtFeatureToggle = featureToggleList.includes('FBT')

  const isFBTCustomer = fbtFeatureToggle && fbtFleets.filter(item => item.value === data.thirdPartyNumber).length > 0

  const onEdit = navigate(latestFbtSubmission?.link)
  
  useEffect(() => {
    onFetchRelatedFbt(data.registration)
  }, []) // eslint-disable-line

  if(!fbtFeatureToggle) return null
  
  return (
    <>
      {isFBTCustomer ? (
        <FbtTable data={relatedFbt} />
      ) : (
        <FbtRoiForm />
      )}
      
      <div className={classes.actions}>
        {isFBTCustomer && (
          <Button fullWidth={mobileView} color="primary" className={classes.button} variant="contained" onClick={onEdit}>
            Edit
          </Button>
        )}
        <Button fullWidth={mobileView} className={classes.button} variant="contained" onClick={onClose}>
          Close
        </Button>
      </div>
    </>
  )
}

FBT.propTypes = {
  data: PropTypes.object.isRequired,
  relatedFbt: PropTypes.object,
  onFetchRelatedFbt: PropTypes.func.isRequired,
}

const mapStateToProps = ({ fleet }, ownProps) => ({
  data: fleet.data?.[ownProps.match.params.id],
  relatedFbt: fleet.relatedFbt,
})

const mapDispatchToProps = (dispatch) => ({  
  onFetchRelatedFbt: (rego) => {
    dispatch(fetchRelatedFbt(null, { resourceId: rego }))
  },
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(FBT))