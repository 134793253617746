import * as moduleTypes from './types'


// get contract details on edit contract modal open
export const fetch = (payload, options) => ({
  type: moduleTypes.FETCH,
  payload,
  options,
})

export const fetchError = (payload, options) => ({
  type: moduleTypes.FETCH_ERROR,
  payload,
  options,
})

export const fetchFulfilled = (payload, options) => ({
  type: moduleTypes.FETCH_FULFILLED,
  payload,
  options,
})

// fetch service history records - drill down vehicle tab
export const fetchServiceHistory = (payload, options) => ({
  type: moduleTypes.FETCH_SERVICE_HISTORY,
  payload,
  options,
})

export const fetchServiceHistoryError = (payload, options) => ({
  type: moduleTypes.FETCH_SERVICE_HISTORY_ERROR,
  payload,
  options,
})

export const fetchServiceHistoryFulfilled = (payload, options) => ({
  type: moduleTypes.FETCH_SERVICE_HISTORY_FULFILLED,
  payload,
  options,
})

// for drilldown fbt tab table
export const fetchRelatedFbt = (payload, options) => ({
  type: moduleTypes.FETCH_RELATED_FBT,
  payload,
  options,
})

export const fetchRelatedFbtError = (payload, options) => ({
  type: moduleTypes.FETCH_RELATED_FBT_ERROR,
  payload,
  options,
})

export const fetchRelatedFbtFulfilled = (payload, options) => ({
  type: moduleTypes.FETCH_RELATED_FBT_FULFILLED,
  payload,
  options,
})

// for edit contract/variations - drill down contract tab
export const update = (payload, options) => ({
  type: moduleTypes.UPDATE,
  payload,
  options,
})
export const updateVariations = (payload, options) => ({
  type: moduleTypes.UPDATE_VARIATIONS,
  payload,
  options,
})

export const updateError = (payload, options) => ({
  type: moduleTypes.UPDATE_ERROR,
  payload,
  options,
})

export const updateFulfilled = (payload, options) => ({
  type: moduleTypes.UPDATE_FULFILLED,
  payload,
  options,
})

export const updateServiceHistoryReliableFlag = (payload, options) => ({
  type: moduleTypes.UPDATE_SERVICE_HISTORY_RELIABLE_FLAG,
  payload,
  options,
})

export const updateServiceHistoryReliableFlagError = (payload, options) => ({
  type: moduleTypes.UPDATE_SERVICE_HISTORY_RELIABLE_FLAG_ERROR,
  payload,
  options,
})

export const updateServiceHistoryReliableFlagFulfilled = (payload, options) => ({
  type: moduleTypes.UPDATE_SERVICE_HISTORY_RELIABLE_FLAG_FULFILLED,
  payload,
  options,
})

// for delete variation - drill down variations tab
export const deleteVariation = (payload, options) => ({
  type: moduleTypes.DELETE_VARIATION,
  payload,
  options,
})

export const deleteVariationError = (payload, options) => ({
  type: moduleTypes.DELETE_VARIATION_ERROR,
  payload,
  options,
})

export const deleteVariationFulfilled = (payload, options) => ({
  type: moduleTypes.DELETE_VARIATION_FULFILLED,
  payload,
  options,
})


export const onChange = (payload) => ({
  type: moduleTypes.ON_CHANGE,
  payload
})

// for lookup field
export const lookup = (payload, options) => ({
  type: moduleTypes.LOOKUP,
  payload,
  options,
})

export const lookupError = (payload, options) => ({
  type: moduleTypes.LOOKUP_ERROR,
  payload,
  options,
})

export const lookupFulfilled = (payload, options) => ({
  type: moduleTypes.LOOKUP_FULFILLED,
  payload,
  options,
})

export const resetLookups = () => ({
  type: moduleTypes.RESET_LOOKUPS,
})

export const reset = () => ({
  type: moduleTypes.RESET,
})

// Submit km reading 
export const submitKmReading = (payload) => ({
  type: moduleTypes.SUBMIT_KM_READING,
  payload,
})

export const submitKmReadingError = (payload) => ({
  type: moduleTypes.SUBMIT_KM_READING_ERROR,
  payload,
})

export const submitKmReadingFulfilled = (payload, options) => ({
  type: moduleTypes.SUBMIT_KM_READING_FULFILLED,
  payload,
  options,
})

export const downloadVehicleHistory = (payload, options) => ({
  type: moduleTypes.DOWNLOAD_VEHICLE_HISTORY,
  payload,
  options,
})

export const downloadVehicleHistoryError = (payload, options) => ({
  type: moduleTypes.DOWNLOAD_VEHICLE_HISTORY_ERROR,
  payload,
  options,
})

export const downloadVehicleHistoryFulfilled = (payload, options) => ({
  type: moduleTypes.DOWNLOAD_VEHICLE_HISTORY_FULFILLED,
  payload,
  options,
})