import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { TableCell, TableRow, Typography } from '@material-ui/core'
import { Label, FleetIcon } from 'frame/components'
import { getDateColor } from 'listable'
import { toCurrency } from 'utils'
import { types } from '../../module'
import { useEnabledFeatureToggleList } from 'features/settings'


const useStyles = makeStyles(theme => ({
  root: {
    verticalAlign: 'text-top',
  },
  label: theme.typography.bodySmall,
  spacer: {
    margin: 0,
    padding: 0,
  },
}))

const Row = ({ account, item, columns }) => {

  const classes = useStyles()
  //const isPremium = account === 'premium'
  const featureToggleList = useEnabledFeatureToggleList()
  const tfp1944FeatureToggle = featureToggleList.includes('TFP1944')

  return (
    <TableRow
      hover
      className={classes.root}>
      <TableCell className={classes.spacer} />
      {columns.accountName && (
        <TableCell className={classes.label}>
          <FleetIcon fleetName={item.accountName} />
        </TableCell>
      )}
      {columns.agreementNo && (
        <TableCell className={classes.label}>
          {item.agreementNo || '-'}
        </TableCell>
      )}
      {columns.registration && (
        <TableCell className={classes.label}>
          {item.registration || '-'}
        </TableCell>
      )}
      {columns.driverNote && tfp1944FeatureToggle && (
        <TableCell className={classes.label}>
          <Typography color="inherit" variant="body2">
            {item.driverNote || '-'}
          </Typography>
        </TableCell>
      )}      
      {columns.driver && (
        <TableCell className={classes.label}>
          <Typography
            color="inherit"
            variant="body2">
            {item.driver || '-'}
          </Typography>
        </TableCell>
      )}
      {columns.year && (
        <TableCell className={classes.label}>
          {item.year || '-'}
        </TableCell>
      )}
      {columns.make && (
        <TableCell className={classes.label}>
          {item.make || '-'}
        </TableCell>
      )}
      {columns.model && (
        <TableCell className={classes.label}>
          {item.model || '-'}
        </TableCell>
      )}
      {columns.variant && (
        <TableCell className={classes.label}>
          {item.variant || '-'}
        </TableCell>
      )}
      {columns.fuelType && (
        <TableCell className={classes.label}>
          {item.fuelType || '-'}
        </TableCell>
      )}
      {columns.costCentre && (
        <TableCell className={classes.label}>
          {item.costCentre || '-'}
        </TableCell>
      )}
      {columns.extensions && (
        <TableCell className={classes.label}>
          {item.extensions ? toCurrency(item.extensions) : '-'}
        </TableCell>
      )}
      {columns.refurbCosts && (
        <TableCell className={classes.label}>
          {item.refurbCosts ? toCurrency(item.refurbCosts) : '-'}
        </TableCell>
      )}
      {columns.excessKmCost && (
        <TableCell className={classes.label}>
          {item.excessKmCost ? toCurrency(item.excessKmCost) : '-'}
        </TableCell>
      )}
      {columns.underKmCredit && (
        <TableCell className={classes.label}>
          {item.underKmCredit ? toCurrency(item.underKmCredit) : '-'}
        </TableCell>
      )}
      {columns.total && (
        <TableCell className={classes.label}>
          {item.total ? toCurrency(item.total) : '-'}
        </TableCell>
      )}
      {columns.returnDate && (
        <TableCell className={classes.label}>
          {item.returnDate ? (
            <Label
              color={getDateColor(item.returnDate)}
              variant="outlined">
              {moment(item.returnDate).format('DD/MM/YY')}
            </Label>
          ) : '-'}
        </TableCell>
      )}
      {/* {(isPremium && columns.disposalDate) && (
        <TableCell className={classes.label}>
          {item.disposalDate ? (
            <Label
              color={getDateColor(item.disposalDate)}
              variant="outlined">
              {moment(item.disposalDate).format('DD/MM/YY')}
            </Label>
          ) : '-'}
        </TableCell>
      )} */}
      <TableCell className={classes.spacer} />
    </TableRow>
  )
}

Row.propTypes = {
  account: PropTypes.string,
  item: types.ModelType.isRequired,
  columns: types.ColumnsType.isRequired,
}

export default Row
