import React, { Fragment, useContext } from 'react'
import { FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@material-ui/core'
import { TextValidator } from 'react-material-ui-form-validator'
import { SingleSelect, RowValueText } from 'frame/components'

import { useStyles } from "./styles"
import { FormContext } from '../form'
import { FormCta } from './form-cta'
import { NewVehicleSummaryContext } from '../new'

const FormVehicleInformation = () => {
  const classes = useStyles()

  const formContext = useContext(FormContext)
  const parentContext = useContext(NewVehicleSummaryContext)
  const {
    values,
    onSelectChange,
    onInputChange,
  } = formContext
  const {
    setActiveTab
  } = parentContext

  return (
    <>
      <Fragment>
        <Grid item xs={12}>
          <RowValueText label="Transmission" responsive>
            <SingleSelect
              noNone
              id="transmission"
              options={['Option 1', 'Option 2', 'Option 3']}
              value={values.transmission ?? ""}
              onChange={onSelectChange('transmission')}
              size="small"
            />
          </RowValueText>
        </Grid>
      </Fragment>

      <Fragment>
        <Grid item xs={12}>
          <RowValueText label="Colour" responsive>
            <TextValidator
              id="colour"
              className={classes.field}
              fullWidth
              value={values.colour || ''}
              inputProps={{ maxLength: 50 }}
              variant="outlined"
              validators={['required']}
              errorMessages={['This field is required']}
              onChange={onInputChange}
              size="small"
            />
          </RowValueText>
        </Grid>
      </Fragment>

      <Fragment>
        <Grid item xs={12}>
          <RowValueText label="Signwriting" responsive>
            <RadioGroup
              row
              name="signwriting"
              value={values.signwriting}
              onChange={onInputChange}
            >
              <FormControlLabel value="no" control={<Radio color="default" />} label="No" />
              <FormControlLabel value="yes" control={<Radio color="default" />} label="Yes" />
            </RadioGroup>
          </RowValueText>
        </Grid>
      </Fragment>

      <Fragment>
        <Grid item xs={12}>
          <Typography gutterBottom variant="h4">
            Accessory Information
          </Typography>
        </Grid>
        <Fragment>
          <Grid item xs={12}>
            <RowValueText label="Canopy (incl. dust seal)" responsive>
              <RadioGroup
                row
                name="canopy"
                value={values.canopy}
                onChange={onInputChange}
              >
                <FormControlLabel value="no" control={<Radio color="default" />} label="No" />
                <FormControlLabel value="yes" control={<Radio color="default" />} label="Yes" />
                <FormControlLabel value="n/a" control={<Radio color="default" />} label="N/A" />
              </RadioGroup>
            </RowValueText>
          </Grid>
          <Grid item xs={12}>
            <RowValueText label="Seat Covers" responsive>
              <RadioGroup
                row
                name="seatCover"
                value={values.seatCover}
                onChange={onInputChange}
              >
                <FormControlLabel value="no" control={<Radio color="default" />} label="No" />
                <FormControlLabel value="yes" control={<Radio color="default" />} label="Yes" />
                <FormControlLabel value="n/a" control={<Radio color="default" />} label="N/A" />
              </RadioGroup>
            </RowValueText>
          </Grid>
        </Fragment>

        <Fragment>
          <Grid item xs={12}>
            <RowValueText label="Towbar" responsive>
              <RadioGroup
                row
                name="towbar"
                value={values.towbar}
                onChange={onInputChange}
              >
                <FormControlLabel value="no" control={<Radio color="default" />} label="No" />
                <FormControlLabel value="yes" control={<Radio color="default" />} label="Yes" />
                <FormControlLabel value="n/a" control={<Radio color="default" />} label="N/A" />
              </RadioGroup>
            </RowValueText>
          </Grid>
          <Grid item xs={12}>
            <RowValueText label="Seat required (Van only)" responsive>
              <RadioGroup
                row
                name="seatRequiredVan"
                value={values.seatRequiredVan}
                onChange={onInputChange}
              >
                <FormControlLabel value="no" control={<Radio color="default" />} label="No" />
                <FormControlLabel value="yes" control={<Radio color="default" />} label="Yes" />
                <FormControlLabel value="n/a" control={<Radio color="default" />} label="N/A" />
              </RadioGroup>
            </RowValueText>
          </Grid>
        </Fragment>

        <Fragment>
          <Grid item xs={12}>
            <RowValueText label="Roof Rack" responsive>
              <RadioGroup
                row
                name="roofRack"
                value={values.roofRack}
                onChange={onInputChange}
              >
                <FormControlLabel value="no" control={<Radio color="default" />} label="No" />
                <FormControlLabel value="yes" control={<Radio color="default" />} label="Yes" />
              </RadioGroup>
            </RowValueText>
          </Grid>
          <Grid item sm={6} xs={12} />
        </Fragment>
      </Fragment>
    
      <Fragment>
        <Grid item xs={12}>
          <Typography gutterBottom variant="h4">
            Accessory Information (Ute only)
          </Typography>
        </Grid>
        <Fragment>
          <Grid item xs={12}>
            <RowValueText label="Flat deck" responsive>
              <RadioGroup
                row
                name="flatDeck"
                value={values.flatDeck}
                onChange={onInputChange}
              >
                <FormControlLabel value="no" control={<Radio color="default" />} label="No" />
                <FormControlLabel value="yes" control={<Radio color="default" />} label="Yes" />
              </RadioGroup>
            </RowValueText>
          </Grid>
          <Grid item xs={12}>
            <RowValueText label="Back Flip cover" responsive>
              <RadioGroup
                row
                name="backFlipCover"
                value={values.backFlipCover}
                onChange={onInputChange}
              >
                <FormControlLabel value="no" control={<Radio color="default" />} label="No" />
                <FormControlLabel value="yes" control={<Radio color="default" />} label="Yes" />
              </RadioGroup>
            </RowValueText>
          </Grid>
        </Fragment>

        <Fragment>
          <Grid item xs={12}>
            <RowValueText label="Weld Deck" responsive>
              <RadioGroup
                row
                name="weldDeck"
                value={values.weldDeck}
                onChange={onInputChange}
              >
                <FormControlLabel value="no" control={<Radio color="default" />} label="No" />
                <FormControlLabel value="yes" control={<Radio color="default" />} label="Yes" />
              </RadioGroup>
            </RowValueText>
          </Grid>
          <Grid item xs={12}>
            <RowValueText label="Nudge Bar" responsive>
              <RadioGroup
                row
                name="nudgeBar"
                value={values.nudgeBar}
                onChange={onInputChange}
              >
                <FormControlLabel value="no" control={<Radio color="default" />} label="No" />
                <FormControlLabel value="yes" control={<Radio color="default" />} label="Yes" />
              </RadioGroup>
            </RowValueText>
          </Grid>
        </Fragment>

        <Fragment>
          <Grid item xs={12}>
            <RowValueText label="Deck Liner" responsive>
              <RadioGroup
                row
                name="deckLiner"
                value={values.roofRack}
                onChange={onInputChange}
              >
                <FormControlLabel value="no" control={<Radio color="default" />} label="No" />
                <FormControlLabel value="yes" control={<Radio color="default" />} label="Yes" />
              </RadioGroup>
            </RowValueText>
          </Grid>
          <Grid item xs={12} />
        </Fragment>

        <Fragment>
          <Grid item xs={12}>
            <RowValueText label="Other Accessories" responsive>
              <TextField
                id="otherAccessories"
                fullWidth
                value={values.otherAccessories || ''}
                variant="outlined"
                onChange={onInputChange}
                multiline
                maxRows={6}
              />
            </RowValueText>
          </Grid>
          <Grid item xs={12} />
        </Fragment>

        <Fragment>
          <Grid item xs={12}>
            <FormCta
              onProceed={() => setActiveTab("compliance_telematics")}
              onBack={() => setActiveTab("driver_details")}
            />
          </Grid>
        </Fragment>
      </Fragment>
    </>
  )
}

export { FormVehicleInformation }