import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { Card, CardContent, Grid, Table, TableBody, Typography, Collapse } from '@material-ui/core'

import PageListHeader from './page-list-header'
// import PageListLoading from './page-list-loading'
import PageListLoadingSkeleton from './page-list-loading-skeleton'
import PageListTableHeader from './page-list-table-header'
import PageListFooter from './page-list-table-footer'
import { Modal } from 'frame/components'
import useSyncScroll from 'use-sync-scroll-hook'
import { useScroll } from 'utils'


const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(10),
    [theme.breakpoints.down('md')]: {
      marginBottom: props => props.useDocumentPanel ? theme.spacing(3) : theme.spacing(10),
    }
  },
  content: {
    flex: 1,
    padding: 0,
    overflowX: 'hidden',
  },
  tableContainer: {
    overflowX: 'scroll',
    transform: 'scaleX(-1) rotate(180deg)',
  },
  table: {
    transform: 'scaleX(-1) rotate(180deg)'
  },
  tableBody: {
  },
  empty: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(3),
    minHeight: 240,
    textAlign: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: 'flex-end'
  },
  link: {
    color: theme.palette.text.link,
    textDecoration: 'none',
    cursor: 'pointer'
  },
  stickyHeader: {
    position: 'fixed',
    overflowX: 'auto',
    zIndex: 999,
    top: -100, // for slide in effect
    left: 0,
    right: 0,
    transition: 'top 0.2s ease', // for slide in effect
    backgroundColor: '#F4F6F8',
  },
  leftSideBarPadding: {
    marginLeft: theme.spacing(35),
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(13),
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(0),
    },
    [theme.breakpoints.down(388)]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(0),
    }
  },
  fbtLeftSideBarPadding: {
    marginLeft: theme.spacing(35),
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(0),
    },
    [theme.breakpoints.down(388)]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(0),
    }
  },
  fullWidthPadding: {
    margin: theme.spacing(0, 3),
    '@media screen and (max-width: 600px)': {
      margin: theme.spacing(0, 2),
    },
    '@media screen and (max-width: 388px)': {
      margin: theme.spacing(0, 1),
    }
  },
  noRightRoundedBorder: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  }
}))

const PageList = ({
  actions,
  banner,
  className,
  error,
  errorMessage,
  disclaimerMessage,
  noRecordsMessage,
  loading,
  title,
  headers,
  listable,
  children,
  hideFilterLabel,
  useDocumentPanel,

  // table pagination stuff
  offset,
  rows,
  total,
  onChangePaginationOffset,
  onChangePaginationRows,
  customComponent,
  // column sorting/toggling stuff
  noColumnSort,
  columnsSort,
  selected,
  filtering,
  showColumns,
  onToggleColumnsSort, // table column sort
  onCheckAll, // used in driver report only
  noPagination, // to hide pagination controls
  noShowingResults, // to hide 'Showing x results' and disclaimer container altogether
  ...rest
}) => {
  // eslint-disable-next-line
  const { scrollY } = useScroll()
  const classes = useStyles({ useDocumentPanel })
  const stickyHeaderRef = useRef()
  const tableRef = useRef()
 
  useSyncScroll([stickyHeaderRef, tableRef]) // syncs sticky header and table horizontal scroll

  const [open, setOpen] = useState(false) // for disclaimerMessage modal

  const onClose = () => setOpen(false) // for disclaimerMessage modal

  const empty = listable.length <= 0
  const safeOffset = total <= 0 ? 0 : offset
  
  const onChangePage = (event, nextPage) => {
    onCheckAll(false)
    window.scrollTo({ top: 0 })
    onChangePaginationOffset(nextPage)
  }

  const onChangeRowsPerPage = event => {
    window.scrollTo({ top: 0 })
    onChangePaginationRows(event.target.value)
  }

  const noRecordsError = errorMessage === 'No records found'

  const showStickyHeader = showColumns ? window.scrollX > 610 : window.scrollY > 380
  
  const isFbtReport = window.location.pathname.includes('fbt')
  const hasLeftSideBar = window.location.pathname.includes('report') || window.location.pathname.includes('admin')
  // console.log('window scrollY: ', { inView, showStickyHeader, scrollY })

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      {noShowingResults && !disclaimerMessage
        ? '' 
        : (<Typography color="textSecondary" gutterBottom variant="body2">
            {noShowingResults 
              ? '' 
              : noPagination
                ? `Showing ${total} results`
                : `Page ${offset + 1} of${' '} ${total ? Math.ceil(total / rows) : 1}, showing ${listable.length} of ${total} results`
            }        

            {disclaimerMessage ? (
              <>{'. '}
                <span className={classes.link} onClick={() => setOpen(true)}>
                  {disclaimerMessage?.linkLabel ? disclaimerMessage?.linkLabel : 'View disclaimer for this report'}
                </span>{'.'}
              </>
            ) : '.'}
          </Typography>
          )}

      {disclaimerMessage && (
        <Modal
          open={open}
          title={disclaimerMessage.title}
          onClose={onClose}
          children={disclaimerMessage.message}
        />
      )}

      <Grid item xs={12}>
        <Card className={useDocumentPanel ? classes.noRightRoundedBorder : ''}>
          <PageListHeader
            actions={actions}
            filtering={hideFilterLabel ? false : filtering}
            title={title}
            customComponent={customComponent}
          />
          <CardContent className={classes.content}>
            <Collapse in={showColumns}>
              {banner}
            </Collapse>
                
            {/* Sticky Header */}
            <div
              ref={stickyHeaderRef}
              className={clsx(
                classes.stickyHeader,
                isFbtReport ? classes.fbtLeftSideBarPadding : hasLeftSideBar ? classes.leftSideBarPadding : classes.fullWidthPadding
              )}
              style={{ top: showStickyHeader ? 0 : -100 }}// slide in effect
            >
              <Table stickyHeader>
                <PageListTableHeader
                  noColumnSort={noColumnSort}
                  headers={headers}
                  selected={selected}
                  columnsSort={columnsSort}
                  onToggleColumnsSort={onToggleColumnsSort}
                  onCheckAll={onCheckAll}
                />
              </Table>
            </div>

            <div className={classes.tableContainer} ref={tableRef}>
              <Table className={classes.table}>
                <PageListTableHeader
                  noColumnSort={noColumnSort}
                  headers={headers}
                  selected={selected}
                  columnsSort={columnsSort}
                  onToggleColumnsSort={onToggleColumnsSort}
                  onCheckAll={onCheckAll}
                />
                <TableBody className={classes.tableBody}>
                  {loading ? (
                    <PageListLoadingSkeleton headers={headers.filter(x => x.visible)} />
                  ) : children}
                </TableBody>
              </Table>
            </div>

            { ! loading && ! error && empty && (
              <Typography className={classes.empty} variant="h6">
                {noRecordsMessage || 'No records found'}
              </Typography>
            )}

            { error && ! loading && empty && !noRecordsError && (
              <Typography className={classes.empty} variant="h6">
                Something went wrong requesting these records.
                <br />
                Please refresh to try again.
              </Typography>
            )}
            
            { noRecordsError && ! loading && empty && (
              <Typography className={classes.empty} variant="h6">
                {errorMessage}
              </Typography>
            )}
            
            {/* {loading && (
              <PageListLoading headers={headers} />
            )} */}
            <br/>
          </CardContent>
          
          {!noPagination && (
            <PageListFooter
              offset={safeOffset}
              rows={rows}
              total={total}
              onChangePaginationOffset={onChangePage}
              onChangePaginationRows={onChangeRowsPerPage}
            />
          )}
        </Card>
      </Grid>
    </div>
  )
}

const Header = PropTypes.shape({
  center: PropTypes.bool,
  label: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  sortKey: PropTypes.string,
  width: PropTypes.number,
})

PageList.propTypes = {
  customComponent: PropTypes.any,
  actions: PropTypes.any,
  banner: PropTypes.element,
  className: PropTypes.string,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  disclaimerMessage: PropTypes.object,
  noRecordsMessage: PropTypes.string,
  loading: PropTypes.bool,
  title: PropTypes.string.isRequired,
  headers: PropTypes.arrayOf(
    PropTypes.oneOfType([ PropTypes.string, Header ])
  ).isRequired,
  listable: PropTypes.array.isRequired,
  children: PropTypes.any,
  hideFilterLabel: PropTypes.bool,
  useDocumentPanel: PropTypes.bool,
  
  // table pagination stuff
  offset: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  rows: PropTypes.number.isRequired,
  onChangePaginationOffset: PropTypes.func.isRequired,
  onChangePaginationRows: PropTypes.func.isRequired,

  // column sorting/toggling stuff
  columnsSort: PropTypes.shape({
    column: PropTypes.string,
    direction: PropTypes.string,
  }),
  selected: PropTypes.array,
  filtering: PropTypes.bool,
  showColumns: PropTypes.bool,
  onToggleColumnsSort: PropTypes.func.isRequired,
  onCheckAll: PropTypes.func.isRequired,
  noPagination: PropTypes.bool,
}

export default PageList
