import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Skeleton } from '@material-ui/lab'
import { TableRow, TableCell } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  left: {
    margin: 0,
    padding: 0,
    width: theme.spacing(1),
    minWidth: theme.spacing(1),
    maxWidth: theme.spacing(1),
  },
  spacer: {
    minWidth: props => props.isAdminUsersTable ? 120 : 60,
    flex: 1,
    margin: 0,
    padding: 0,
  },
}))

const PageListLoading = ({ headers }) => {

  const classes = useStyles()
 
  const isDriverPage = window.location.pathname.includes('/drivers')

  const createWidthStyles = (width) => ({
    width: `${width}px`,
    minWidth: `${width}px`,
    maxWidth: `${width}px`,
  })

  return (
    <>
      {Array.from(Array(5).keys()).map((_, rowIndex) => (
        <TableRow key={`skeleton-loading-row-${rowIndex}`}>
          <TableCell className={classes.left} />
          {headers.map((header, cellIndex) => {

            if((isDriverPage && header.key === 'agreementNo'))
              return null

            return (
              <TableCell key={`skeleton-loading-cell-${cellIndex}`} style={createWidthStyles(header.width >= 120 ? 120 : header.width)}>
                {header.key === 'checkbox' ? (
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Skeleton animation="wave" variant="circle" width={30} height={30} style={{ marginRight: 10 }} />
                    <Skeleton animation="wave" variant="circle" width={30} height={30} />
                  </div>
                ) : header.key === 'accountName' ? (
                  <Skeleton animation="wave" variant="circle" width={30} height={30} />
                ): (
                  <Skeleton animation="wave" variant="rect" height={15} />
                )}
              </TableCell>
            )
          })}
          {!isDriverPage && (
            <TableCell className={classes.spacer} />
          )}


        </TableRow>
      ))}
    </>
  )
}

export default PageListLoading
