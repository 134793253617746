import moment from 'moment'
import { colors } from '@material-ui/core'
import { isExpired, isUpcoming, isLastMonth, isThisOrNextMonth } from 'filter'


export { reduceRequest } from './reduceRequest'
export { deserializePreferences } from './preferences'


export const calculateKmUseRate = (item) => {
  /*
    Formula: ( (A / (B - C)) * (D - C) ) / E ) * 100

    Key:
      A - Latest odometer reading
      B - Date of latest odometer reading
      C - Start date of agreement
      D - Maturity date of agreement
      E - Contract KM’s

    Overview:
    B - C and D - C will need to be calculated in days.
    A would need to be calculated in Kilometres.

    This will give the projected KM’s for the whole contract (F),
    then (F/E)*100 displayed as a whole number (as a percentage).
  */

  if ( ! item.lastKmReading
      && ! item.lastOdoReading
      && ! item.startDate
      && ! item.maturityDate
      && ! item.contractKm) {
    return null
  }

  const a = parseFloat(item.lastKmReading, 10) || 0
  const latestReadingDate = moment(item.lastOdoReading)
  const startDate = moment(item.startDate)
  const maturityDate = moment(item.maturityDate)
  const e = parseFloat(item.contractKm, 10)

  // B - C
  if (startDate.isAfter(latestReadingDate)
     || startDate.isAfter(maturityDate)
     || Number.isNaN(a)
     || Number.isNaN(e)) {
    // Unknown:
    // Either the odometer was read before contract start date
    // or the maturity date is before the start date
    // or last km reading/contract KMs is not a number
    return null
  }

  const bc = latestReadingDate.diff(startDate, 'days')
  const dc = maturityDate.diff(startDate, 'days')
  const f = (a / bc) * dc
  const rate = Math.round((f / e) * 100) / 100

  // console.log(' ')
  // console.log('[bc]', bc)
  // console.log('[dc]', dc)
  // console.log('[f]', f)
  // console.log('[rage]', rate)
  return rate

}

// Date Color logic 
// Date - red = before this month, orange = this and next month, green = 2+ months or further away
export const getDateColorByMonth = (d) => {
  if (isLastMonth(d)) {
    return colors.red[600]
  }
  else if (isThisOrNextMonth(d)) {
    return colors.orange[600]
  }
  return colors.green[600]
}

// Color Coding :  
// WoF Expiry - red = before this month, orange = this and next month, green = 2+ months or further away 
// WoF Status - red = overdue, overdue – lease matured, orange = due and due soon, green = valid 
// Registration Expiry - red = before this month, orange = this and next month, green = 2+ months or further away 
// Registration Status - red = overdue, overdue – lease matured, orange = due and due soon, green = valid 

// Date - red = before today, orange = after today and next month, green = 2+ months or further away
export const getDateColor = (d) => {
  if (isExpired(d)) {
    return colors.red[600]
  }
  else if (isUpcoming(d)) {
    return colors.orange[600]
  }
  return colors.green[600]
}
// todo: remove, as all statuses should be coming as 'raw' data from db / api
export const getDateStatus = (d) => {
  if (isExpired(d)) {
    return 'Overdue'
  }
  else if (isUpcoming(d)) {
    return 'Due soon'
  }
  return 'Valid'
}

export const getWoFStatusColor = (status) => {
  const value = String(status).toLowerCase()
  if (value.includes('overdue')) {
    return colors.red[600]
  }
  else if (value.includes('due')) {
    return colors.orange[600]
  }
  return colors.green[600]
}

export const getForecastVariationColor = (current, allowed) => {
  const percent = current / allowed
  if (percent > 1) {
    return colors.red[600]
  }
  else if (percent >= 0.8) {
    return colors.orange[600]
  }
  return colors.green[600]
}

export const getKmUseRateColor = (p) => {
  if (p > 100) {
    return colors.red[600]
  }
  if (p > 75) {
    return colors.orange[600]
  }
  return colors.green[600]
}

export const getStatusColor = (status) => {
  const value = String(status).toLowerCase()

  if (value === 'rejected'
    || value === 'matured'
    || value === 'cancelled') {
    return colors.red[600]
  }
  else if (value === 'pending') {
    return colors.orange[600]
  }
  else if (value === 'scheduled'
    || value === 'live'
    || value === 'completed') {
    return colors.green[600]
  }
  else if (value === 'requested') {
    return colors.blue[600]
  }
  return colors.grey[600]
}

export const getReliefStatusColor = (status) => {
  const value = String(status).toLowerCase()

  if (value === 'cancelled') {
    return colors.red[600]
  }
  else if (value === 'pending' 
    || value === 'sent to tfs'
    || value === 'requested') {
    return colors.orange[600]
  }
  else if (value === 'completed') {
    return colors.green[600]
  }
  else if (value === 'driver requested')
  {
    return colors.blue[600]
  }
  return colors.grey[600]
}

export const getTrackingColor = (tracking) => {
  const value = String(tracking).toLowerCase()

  if (value === 'over') {
    return colors.red[600]
  }
  else if (value === 'on track') {
    return colors.green[600]
  }
  else if (value === 'under') {
    return colors.orange[600]
  }
  return colors.grey[600]
}

export const getTrackingText = (value) => {

  // if (value === 'Within Buffer') {
  //   return 'Within Buffer' // 'On Track'
  // }

  return value
}

export const getYesNoColor = (yesNo) => {
  const value = String(yesNo).toLowerCase()

  if (value === 'y') {
    return colors.green[600]
  }
  else if (value === 'n') {
    return colors.red[600]
  }

  return colors.grey[600]
}

export const getRole = (role) => {
  switch(String(role).toLowerCase()) {
    case 'superadmin':
      return 'Super Admin'
    case 'fleetcontroller':
      return 'Fleet Controller'
    case 'fleetcoordinator':
      return 'Fleet Coordinator'
    default:
      return role
  }
}

export const getDriverStatusColor = (status) => {
  const value = String(status).toLowerCase()

  if (value === 'terminated' || value === 'expired') {
    return colors.red[600]
  }
  else if (value === 'unassigned' || value === 'unverified' || value === 'invited') {
    return colors.orange[600]
  }
  return colors.green[600]
}

// for non toyota agreements, we do not have an actual agreement number
// and agreements will have a 'made up' agreement number, which we do not want to openly show
// so instead, we will present as 'static' text
export const obfuscateAgreementNumber = (agreementNumber, obfuscate) => {
  if (!obfuscate) return agreementNumber

  const value = String(agreementNumber).toLowerCase()
  if (value.indexOf('unf') > -1) {
    return 'Non-TFM'
  }
  
  return agreementNumber
}

export const fbtMonthName = (month) => {
  if (month > 0 && month < 13) {
    return moment('01-' + month +'-2021', 'DD-MM-YYYY').format('MMMM');
  }

  return '-'
}

//for user/user invitation tables
export const isUserIdAuth0 = (userId) => {
  return String(userId).substring(0, 5) === 'auth0'
}

export const getUserStatusColor = (hasAccount, blocked) => {
  if (!hasAccount) return colors.orange[600];
  if (hasAccount && !blocked) return colors.green[600];
  if (hasAccount && blocked) return colors.red[600];
  return colors.blue[600]; // should never get here
}

export const getUserInvitedColor = (hasAccount, invitationVerified, invitationExpired) => {
  if (hasAccount) return colors.green[600];
  if (!hasAccount && invitationVerified) return colors.green[600];
  if (!hasAccount && !invitationVerified && invitationExpired ) return colors.red[600];
  if (!hasAccount && !invitationVerified && !invitationExpired) return colors.orange[600];
  return colors.blue[600]; // should never get here
}