import * as types from './types'


export const reset = () => ({ type: types.RESET })

// check if account has app users
export const checkHaveAppUsers = (payload, options) => ({ type: types.CHECK_HAVE_APP_USERS, payload, options })
export const checkHaveAppUsersError = (payload, options) => ({ type: types.CHECK_HAVE_APP_USERS_ERROR, payload, options })
export const checkHaveAppUsersFulfilled = (payload, options) => ({ type: types.CHECK_HAVE_APP_USERS_FULFILLED, payload, options })

// My Fleet Summary - Service Overdue
export const fetchMyFleetSummaryServiceOverdue = (payload, options) => ({ type: types.FETCH_SUMMARY_SERVICE_OVERDUE, payload, options })
export const fetchMyFleetSummaryServiceOverdueError = (payload, options) => ({ type: types.FETCH_SUMMARY_SERVICE_OVERDUE_ERROR, payload, options })
export const fetchMyFleetSummaryServiceOverdueFulfilled = (payload, options) => ({ type: types.FETCH_SUMMARY_SERVICE_OVERDUE_FULFILLED, payload, options })

// My Fleet Summary - Wof Overdue
export const fetchMyFleetSummaryWofOverdue = (payload, options) => ({ type: types.FETCH_SUMMARY_WOF_OVERDUE, payload, options })
export const fetchMyFleetSummaryWofOverdueError = (payload, options) => ({ type: types.FETCH_SUMMARY_WOF_OVERDUE_ERROR, payload, options })
export const fetchMyFleetSummaryWofOverdueFulfilled = (payload, options) => ({ type: types.FETCH_SUMMARY_WOF_OVERDUE_FULFILLED, payload, options })

// My Fleet Summary - Km Tracking
export const fetchMyFleetSummaryKmTracking = (payload, options) => ({ type: types.FETCH_SUMMARY_KM_TRACKING, payload, options })
export const fetchMyFleetSummaryKmTrackingError = (payload, options) => ({ type: types.FETCH_SUMMARY_KM_TRACKING_ERROR, payload, options })
export const fetchMyFleetSummaryKmTrackingFulfilled = (payload, options) => ({ type: types.FETCH_SUMMARY_KM_TRACKING_FULFILLED, payload, options })

// My Fleet Summary - Rego Overdue
export const fetchMyFleetSummaryRegoOverdue = (payload, options) => ({ type: types.FETCH_SUMMARY_REGO_OVERDUE, payload, options })
export const fetchMyFleetSummaryRegoOverdueError = (payload, options) => ({ type: types.FETCH_SUMMARY_REGO_OVERDUE_ERROR, payload, options })
export const fetchMyFleetSummaryRegoOverdueFulfilled = (payload, options) => ({ type: types.FETCH_SUMMARY_REGO_OVERDUE_FULFILLED, payload, options })

// My Fleet Summary - FBT Outstanding
export const fetchMyFleetSummaryFbtOutstanding = (payload, options) => ({ type: types.FETCH_SUMMARY_FBT_OUTSTANDING, payload, options })
export const fetchMyFleetSummaryFbtOutstandingError = (payload, options) => ({ type: types.FETCH_SUMMARY_FBT_OUTSTANDING_ERROR, payload, options })
export const fetchMyFleetSummaryFbtOutstandingFulfilled = (payload, options) => ({ type: types.FETCH_SUMMARY_FBT_OUTSTANDING_FULFILLED, payload, options })


// fetch notifications list
export const fetchNotifications = (payload, options) => ({ type: types.FETCH_NOTIFICATIONS, payload, options })
export const fetchNotificationsError = (payload, options) => ({ type: types.FETCH_NOTIFICATIONS_ERROR, payload, options })
export const fetchNotificationsFulfilled = (payload, options) => ({ type: types.FETCH_NOTIFICATIONS_FULFILLED, payload, options })

// fetch status updates list
export const fetchStatusUpdates = (payload, options) => ({ type: types.FETCH_STATUS_UPDATES, payload, options })
export const fetchStatusUpdatesError = (payload, options) => ({ type: types.FETCH_STATUS_UPDATES_ERROR, payload, options })
export const fetchStatusUpdatesFulfilled = (payload, options) => ({ type: types.FETCH_STATUS_UPDATES_FULFILLED, payload, options })

// fetch industry news list
export const fetchIndustryNews = (payload, options) => ({ type: types.FETCH_INDUSTRY_NEWS, payload, options })
export const fetchIndustryNewsError = (payload, options) => ({ type: types.FETCH_INDUSTRY_NEWS_ERROR, payload, options })
export const fetchIndustryNewsFulfilled = (payload, options) => ({ type: types.FETCH_INDUSTRY_NEWS_FULFILLED, payload, options })

// fetch download guides
export const fetchDownloadGuides = (payload, options) => ({ type: types.FETCH_DOWNLOAD_GUIDES, payload, options })
export const fetchDownloadGuidesError = (payload, options) => ({ type: types.FETCH_DOWNLOAD_GUIDES_ERROR, payload, options })
export const fetchDownloadGuidesFulfilled = (payload, options) => ({ type: types.FETCH_DOWNLOAD_GUIDES_FULFILLED, payload, options })

// fetch contact details
export const fetchContactDetails = (payload, options) => ({ type: types.FETCH_CONTACT_DETAILS, payload, options })
export const fetchContactDetailsError = (payload, options) => ({ type: types.FETCH_CONTACT_DETAILS_ERROR, payload, options })
export const fetchContactDetailsFulfilled = (payload, options) => ({ type: types.FETCH_CONTACT_DETAILS_FULFILLED, payload, options })

// fetch footer ticker
export const fetchFooterTicker = (payload, options) => ({ type: types.FETCH_FOOTER_TICKER, payload, options })
export const fetchFooterTickerError = (payload, options) => ({ type: types.FETCH_FOOTER_TICKER_ERROR, payload, options })
export const fetchFooterTickerFulfilled = (payload, options) => ({ type: types.FETCH_FOOTER_TICKER_FULFILLED, payload, options })
