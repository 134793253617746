import { createBrowserHistory } from 'history'
import { applyMiddleware, compose, createStore } from 'redux'
import { createEpicMiddleware } from 'redux-observable'
import { routerMiddleware } from 'connected-react-router'
import { makeRootReducer } from './reducers'
import reduxReset from 'redux-reset'


export const history = createBrowserHistory()
export const epicMiddleware = createEpicMiddleware()

export const configureStore = (initialState = {}) => {
  const enhancers = [reduxReset()]
  const middleware = [routerMiddleware(history), epicMiddleware]
  const composeEnhancers = (window && window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__']) || compose

  const rootReducer = makeRootReducer(history)

  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middleware), ...enhancers),
  )

  return { store }
}