import { createListable } from 'listable'
import { combineEpics } from 'redux-observable'
import { createEffects } from './effects'
import options from './config'
import { moduleName } from './actions/types'
import * as customActions from './actions'
import customReducer from './reducer'
import { REPORTS_PATH } from 'features/report'


const listableModule = createListable({
  name: moduleName,
  config: options,
  customActions,
  customReducer,
})

export const config = listableModule.options
export const actions = listableModule.actions

export const effects = combineEpics(
  createEffects(listableModule.actions),
  listableModule.effects,
)

export const reducer = listableModule.reducer
export const types = listableModule.types

export const constants = {
  ...listableModule.constants,
  PATH_ALL: `${REPORTS_PATH}/documents`,
  PATH_SINGLE: `${REPORTS_PATH}/documents`,
  ROUTE_ALL: `${REPORTS_PATH}/documents`,
  ROUTE_SINGLE: `${REPORTS_PATH}/documents/:id`,
}
