import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { Button, Card, CardActions, CardContent, Grid, Typography } from '@material-ui/core'
import { Alert } from 'frame/components'
import moment from 'moment'
import { lookup } from '../../../actions'
import DriverLookupField from './driver-lookup-field'


const useStyles = makeStyles(theme => ({
  content: {
    padding: theme.spacing(3, 1.2, 3, 1.2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
  },
  actions: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      }
    },
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      minWidth: 140,
      '& > *': {
        margin: theme.spacing(0, 1),
      },
    },
  },
}))

const ChangeDriverForm = ({
  values,
  onChange,
  disabled,
  onProceed,
  onCreateNewDriver,
  onBack,
  searchHistory,
  setSearchHistory,
  onLookup,
  originalValues,
  fleetData,
}) => {
  
  const form = useRef()
  const classes = useStyles()
  
  const [showError, setShowError] = useState(null)
  const [selectedDriver, setSelectedDriver] = useState(null)

  const onFleetLookupChange = (option) => {
    setShowError(null)
    option && console.log('[ChangeDriverForm - onFleetLookupChange]', option)
    setSelectedDriver(option && option.value ? option.value : null)

    option && onChange({
      ...values,
      uniqueId: option.value || null,
      driver: option.text,
      email: option.email ? option.email.trim() : null,
      mobile: option.mobile ? option.mobile.trim() : null,

      // overwrite values in confirm step on select different driver
      effectiveDate: option.effectiveDate || fleetData.effectiveDate || moment(),
      costCentre: option.costCentre || fleetData.costCentre,
      location: option.location || fleetData.location,
      controller: option.controller || fleetData.controller,
      vehicleNotes: option.vehicleNotes|| fleetData.vehicleNotes,
    })
  }

  const onProceedValidation = () => {
    if (form.current && selectedDriver) {
      !values.email && setShowError('Driver email is required to proceed.')
      form.current.submit()
    } else {
      setShowError('Please select a driver to proceed.')
      setTimeout(() => setShowError(null), 3000)
    }
  }

  const onCreateNew = () => {
    setSearchHistory(null)
    onCreateNewDriver()
  }

  const onBackHandler = () => {
    setSearchHistory(null) 
    onChange({ // reset values
      ...values,
      uniqueId: originalValues.uniqueId || null,
      driver: originalValues.driver || null,
      email: originalValues.email || null,
      mobile: originalValues.mobile || null,
    })
    onBack()
  }

  const onEmailChange = (value) => {
    setSearchHistory({ ...searchHistory, email: value })
    onChange({ ...values, email: value })
  }

  return (
    <ValidatorForm autoComplete="off" noValidate ref={form} onSubmit={onProceed}>
      <Card>
        <CardContent className={classes.content}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography gutterBottom variant="h4">
                Search for an existing driver
              </Typography>
            </Grid>
            
            {showError && !searchHistory.email && (
              <Grid item xs={12}>
                <Alert>
                  {showError}
                </Alert>
              </Grid>
            )}

            <Grid item xs={12}>
              <DriverLookupField
                fullWidth
                id="driver"
                variant="outlined"
                customFleetId={values.thirdPartyNumber}
                disabled={disabled}
                label="Enter Driver Name"
                lookupPath="drivername"
                resourceModel="fleet"
                onLookup={onLookup}
                onChange={onFleetLookupChange}
                currentDriver={selectedDriver}
                searchHistory={searchHistory}
                setSearchHistory={setSearchHistory}
                originalValues={originalValues}
                values={values}
                onEmailChange={onEmailChange}
              />
            </Grid>

          </Grid>
        </CardContent>

        <CardActions className={classes.actions}>
          <Button
            color="primary"
            style={{ marginLeft: 5 }}
            disabled={disabled}
            variant="contained"
            onClick={onProceedValidation}>
            Proceed
          </Button>

          <Button
            disabled={disabled}
            variant="contained"
            onClick={onCreateNew}>
            Create New Driver
          </Button>

          <Button
            disabled={disabled}
            variant="contained"
            onClick={onBackHandler}>
            Back
          </Button>
        </CardActions>
      </Card>
    </ValidatorForm>
  )
}

ChangeDriverForm.propTypes = {
  values: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  originalValues: PropTypes.object,
  fleetData: PropTypes.object,
  searchHistory: PropTypes.object,
  setSearchHistory: PropTypes.func,
  // func
  onLookup: PropTypes.func.isRequired,
  onProceed: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onCreateNewDriver: PropTypes.func.isRequired,
}

const mapStateToProps = () => ({})

const mapDispatchToProps = (dispatch) => ({
  onLookup: (lookupPath, searchKey, customFleetId, size = 100) => {
    dispatch(lookup(null, {
      resourceId: lookupPath,
      queryParams: { FleetId: customFleetId, Search: searchKey, Size: size }
    }))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(ChangeDriverForm)