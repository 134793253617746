

let auth

export const getAuthClient = () =>
  auth

export const setAuthClient = (nextAuth) => {
  auth = nextAuth
  return auth
}
