import { AUTH_LOGOUT_PATH, AUTH_VERIFICATION_PATH } from './types'


export * from './types'

export {
  Login as LoginRoute,
  Logout as LogoutRoute,
  Verification as VerificationRoute,
  VerificationDriver as VerificationDriverRoute
} from './components'

export const constants = {
  LOGOUT_PATH: AUTH_LOGOUT_PATH,
  VERIFICATION_PAH: AUTH_VERIFICATION_PATH
}
