import React, { createContext, useContext, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import { useDebounce } from "utils/debounce"
import { lookupRequest } from 'utils'
import { useSelectedFleetIds } from 'features/settings'
import { ViewDefault } from './view-default'
import { ViewNewDriver } from './view-new-driver'
import { ViewAssignDriver } from './view-assign-driver'
import { ViewFinal } from './view-final'
import { FormContext } from '../../form'

export const DriverDetailsContext = createContext({})

const FormDriverDetails = ({
  values,
  onSelectChange,
  onInputChange,
  children,
  canProceed,
  selectedDriver
}) => {
  const formContext = useContext(FormContext)
  const fleetIds = useSelectedFleetIds()

  const [sectionView, setView] = useState("default")

  const { searchName, setSearchName, setResults } = formContext

  const getDrivers = async (term) => {
    const drivers = await lookupRequest(fleetIds, '/lookup/drivername', term, true)
    setResults(drivers)
    return drivers
  }

  const handleChange = () => {
    if (searchName.length > 2) {
      getDrivers(searchName)
    }
  };

  const debouncedOnChange = useDebounce(handleChange)

  const providerValues = useMemo(() => {
    return {
      sectionView,
      setView,
    }
  }, [])

  return (
    <DriverDetailsContext.Provider value={providerValues}>
      <>
        {sectionView === "default" ? <ViewDefault setView={setView} /> : null}
        {sectionView === "additional_details" ?
          <ViewFinal
            values={values}
            onSelectChange={onSelectChange}
            onInputChange={onInputChange}
            selectedDriver={selectedDriver}
          /> : null}

        {sectionView === "assign_driver" ? (
          <ViewAssignDriver
            onChange={(value) => {
              debouncedOnChange();
              setSearchName(value);
            }}
          >
            {children}
          </ViewAssignDriver>
        ) : null}

        {sectionView === "new_driver" ?
          <ViewNewDriver /> : null
        }
      </>
    </DriverDetailsContext.Provider>
  )
}

FormDriverDetails.propTypes = {
  values: PropTypes.object.isRequired,
  onSelectChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
}

export { FormDriverDetails }
