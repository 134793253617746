import * as moduleTypes from './types'

// Reset module state
export const reset = () => ({
  type: moduleTypes.RESET,
})

// Reset flags
export const resetFlags = () => ({
  type: moduleTypes.RESET_FLAGS,
})

// for add fbt submission form
export const addFbtSubmission = (payload, options) => ({
  type: moduleTypes.ADD_FBT_SUBMISSIONS,
  payload,
  options,
})

export const addFbtSubmissionError = (payload, options) => ({
  type: moduleTypes.ADD_FBT_SUBMISSIONS_ERROR,
  payload,
  options,
})

export const addFbtSubmissionFulfilled = (payload, options) => ({
  type: moduleTypes.ADD_FBT_SUBMISSIONS_FULFILLED,
  payload,
  options,
})

// for view submissions tab - on save
export const editSave = (payload, options) => ({
  type: moduleTypes.EDIT_FBT_SUBMISSION_SAVE,
  payload,
  options,
})

export const editSaveError = (payload, options) => ({
  type: moduleTypes.EDIT_FBT_SUBMISSION_SAVE_ERROR,
  payload,
  options,
})

export const editSaveFulfilled = (payload, options) => ({
  type: moduleTypes.EDIT_FBT_SUBMISSION_SAVE_FULFILLED,
  payload,
  options,
})

// for add fbt form driver name lookup field
export const lookup = (payload, options) => ({
  type: moduleTypes.LOOKUP,
  payload,
  options,
})

export const lookupError = (payload, options) => ({
  type: moduleTypes.LOOKUP_ERROR,
  payload,
  options,
})

export const lookupFulfilled = (payload, options) => ({
  type: moduleTypes.LOOKUP_FULFILLED,
  payload,
  options,
})

// for fetching fbt details on edit fbt modal open
export const fetch = (payload, options) => ({
  type: moduleTypes.FETCH,
  payload,
  options,
})

export const fetchError = (payload, options) => ({
  type: moduleTypes.FETCH_ERROR,
  payload,
  options,
})

export const fetchFulfilled = (payload, options) => ({
  type: moduleTypes.FETCH_FULFILLED,
  payload,
  options,
})

// for toggling fbt customer view
export const setFbtCustomerView = (payload) => ({ 
  type: moduleTypes.SET_FBT_CUSTOMER_VIEW,
  payload,
})

// for reseting  fbt customer view on fleet change
export const resetFbtCustomerView = () => ({ 
  type: moduleTypes.RESET_FBT_CUSTOMER_VIEW,
})

// for add fbt form
export const addDraft = (payload) => ({ 
  type: moduleTypes.ADD,
  payload,
})

// for edit fbt form - save button
export const editSaveDraft = (payload) => ({ 
  type: moduleTypes.EDIT_SAVE,
  payload,
})

// for view submissions tab - edit row button
export const editDraft = (payload) => ({ 
  type: moduleTypes.EDIT_DRAFT,
  payload,
})

// for view submissions tab - delete row button
export const deleteDraft = (payload) => ({ 
  type: moduleTypes.DELETE_DRAFT,
  payload,
})

// for view submissions tab - on add 
export const newDraft = () => ({ 
  type: moduleTypes.NEW_DRAFT,
})

// for view submissions tab - on add 
export const setNewSubmissionDetails = (payload) => ({ 
  type: moduleTypes.SET_NEW_SUBMISSION_DETAILS,
  payload,
})


// for non-fbt customers - ROI form
export const registerInterest = (payload, options) => ({
  type: moduleTypes.REGISTER_INTEREST,
  payload,
  options,
})

export const registerInterestError = (payload, options) => ({
  type: moduleTypes.REGISTER_INTEREST_ERROR,
  payload,
  options,
})

export const registerInterestFulfilled = (payload, options) => ({
  type: moduleTypes.REGISTER_INTEREST_FULFILLED,
  payload,
  options,
})

// for fbt web form
export const fbtWebFormVerifyToken = (payload, options) => ({
  type: moduleTypes.FBT_WEBFORM_VERIFY_TOKEN_VALUE,
  payload,
  options,
})

export const fbtWebFormVerifyTokenError = (payload, options) => ({
  type: moduleTypes.FBT_WEBFORM_VERIFY_TOKEN_VALUE_ERROR,
  payload,
  options,
})

export const fbtWebFormVerifyTokenFulfilled = (payload, options) => ({
  type: moduleTypes.FBT_WEBFORM_VERIFY_TOKEN_VALUE_FULFILLED,
  payload,
  options,
})

export const fbtWebFormVerifyEmail = (payload, options) => ({
  type: moduleTypes.FBT_WEBFORM_VERIFY_EMAIL,
  payload,
  options,
})

export const fbtWebFormVerifyEmailError = (payload, options) => ({
  type: moduleTypes.FBT_WEBFORM_VERIFY_EMAIL_ERROR,
  payload,
  options,
})

export const fbtWebFormVerifyEmailFulfilled = (payload, options) => ({
  type: moduleTypes.FBT_WEBFORM_VERIFY_EMAIL_FULFILLED,
  payload,
  options,
})

export const fbtWebFormSubmit = (payload, options) => ({
  type: moduleTypes.FBT_WEBFORM_SUBMIT,
  payload,
  options,
})

export const fbtWebFormSubmitError = (payload, options) => ({
  type: moduleTypes.FBT_WEBFORM_SUBMIT_ERROR,
  payload,
  options,
})

export const fbtWebFormSubmitFulfilled = (payload, options) => ({
  type: moduleTypes.FBT_WEBFORM_SUBMIT_FULFILLED,
  payload,
  options,
})

export const fbtWebFormSetValues = (payload) => ({
  type: moduleTypes.FBT_WEBFORM_SET_VALUES,
  payload,
})