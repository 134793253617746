import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, useTheme } from '@material-ui/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { RowValueText } from 'frame/components'
import moment from 'moment'
import { Button, Card, CardContent, Grid, Typography, TextField } from '@material-ui/core'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { LightboxGallery, StyledModal, AnimatedSuccessTick, AnimatedErrorCross } from 'frame/components'
import { useIsAlfaDownDisableFeatures } from 'features/drivers'
import { useEnabledFeatureToggleList } from 'features/settings'

const useStyles = makeStyles(theme => ({
  content: {
    padding: theme.spacing(3, 1.2, 3, 1.2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
  },
  fieldGroup: {
    margin: theme.spacing(3, 0, 2, 0),
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    }
  },
  button: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    minWidth: 180,
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
    }
  },

}))

const Form = ({ data, flags, errorMessage, onClose, onSaveIncidentNotes }) => {

  const form = useRef()
  const theme = useTheme()
  const classes = useStyles()
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'))
  
  const featureToggleList = useEnabledFeatureToggleList()
  const disableOutOfHoursFeatureToggle = featureToggleList.includes('OutOfHoursDisabled') // 'ALFA out of hours DISABLE FEATURES' limitations feature is ON
  const isAlfaDownDisableDriverFeatures = useIsAlfaDownDisableFeatures()
  const isAlfaDown = disableOutOfHoursFeatureToggle ? false : isAlfaDownDisableDriverFeatures
  
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [values, setValues] = useState({
    id: data.id,
    thirdPartyNumber: data.thirdPartyNumber,
    notes: data?.notes || ''
  })
  const readOnly = isAlfaDown
  const disableSave = readOnly || `${data?.notes || ''}`.trim() === values.notes

  const onSubmitForm = () => {
    onSaveIncidentNotes(data.id, values);
  } 
  const onSave = () => form.current && form.current.submit()

  const onInputChange = ({ target: { id, name, value } }) => {
    setValues({
      id: data.id,
      thirdPartyNumber: data.thirdPartyNumber,
      notes: value
    })
  }
  
  const displayVehicle = (data) => {
    let vehicle = `${data.year || ''} ${data.make || ''}`.trim()
    vehicle = `${vehicle} ${data.model || ''}`.trim()
    vehicle = `${vehicle} ${data.variant || ''}`.trim()
    vehicle = vehicle.replace('(Unknown)', '').trim()
    return vehicle !== '' ? vehicle : '-'
  }

  useEffect(() => {
    if(flags.saveNotes === 'error' || flags.saveNotes === 'processed') {
      setShowConfirmation(true)
    }
  }, [flags.saveNotes])
  useEffect(() => {
    setValues({
      id: data.id,
      thirdPartyNumber: data.thirdPartyNumber,
      notes: values.notes || data.notes
    })
  }, [data])
  
  return (
    <ValidatorForm autoComplete="off" noValidate ref={form} onSubmit={onSubmitForm}>
      <Card>
        <CardContent className={classes.content}>
          <Typography gutterBottom variant="h4">
            Incident Details
          </Typography>

          <div className={classes.fieldGroup}>
            <Grid container spacing={2}>

              <Grid item xs={12} style={{ marginTop: '10px'}}>
                <RowValueText label="Driver">
                  <Typography variant="body1">
                    {data.driver || '-'}
                  </Typography>
                </RowValueText>
              </Grid>

              <Grid item xs={12} style={{ marginTop: '10px'}}>
                <RowValueText label="Incident Type">
                  <Typography variant="body1">
                    {data.incidentType || '-'}
                  </Typography>
                </RowValueText>
              </Grid>

              <Grid item xs={12} style={{ marginTop: '10px'}}>
                <RowValueText label="Incident Date">
                  <Typography variant="body1">
                    {data.incidentDate ? moment(data.incidentDate).format('Do MMMM YYYY') : '-'}
                  </Typography>
                </RowValueText>
              </Grid>

              <Grid item xs={12} style={{ marginTop: '10px'}}>
                <RowValueText label="Incident Time">
                  <Typography variant="body1">
                    {data.incidentDate ? moment(data.incidentDate).format('h:mm A') : '-'}
                  </Typography>
                </RowValueText>
              </Grid>

              <Grid item xs={12} style={{ marginTop: '10px'}}>
                <RowValueText label="Description">           
                    <Typography variant="body1">
                      {data.description || '-'}
                    </Typography>
                </RowValueText>
              </Grid>

              <Grid item xs={12} style={{ marginTop: '10px'}}>
                <RowValueText label="Cost Centre">           
                    <Typography variant="body1">
                      {data.costCentre || '-'}
                    </Typography>
                </RowValueText>
              </Grid>

              <Grid item xs={12} style={{ marginTop: '10px'}}>
                <RowValueText label="Vehicle">           
                    <Typography variant="body1">
                      {displayVehicle(data)}
                    </Typography>
                </RowValueText>
              </Grid>

              <Grid item xs={12}>
                <RowValueText label="Files">
                  <LightboxGallery
                    attachments={data.attachments}
                    attachmentsCount={data.attachmentsCount}
                    isLoading={flags.fetchDetails === 'processing'}
                  />
                </RowValueText>
              </Grid>
              
              <Grid item xs={12}>
                {readOnly ? (
                  <RowValueText label="Notes">           
                    <Typography variant="body1">
                      {values.notes || ''}
                    </Typography>
                  </RowValueText>
                ) : (
                  <RowValueText label="Add Notes" responsive>
                    <TextField
                      multiline fullWidth
                      rows={3}
                      size="small"
                      id="notes"
                      label={mobileView ? 'Add Notes' : ''}
                      // disabled={disabled}
                      value={values.notes || ''}
                      inputProps={{ maxLength: 50 }}
                      variant="outlined"
                      onChange={onInputChange}
                    />
                  </RowValueText>
                )}
              </Grid>
            </Grid>
          </div>

          
        </CardContent>
      </Card>
      <div className={classes.actions}>
        <Button
          className={classes.button}
          color="primary"
          disabled={disableSave}
          variant="contained"
          onClick={onSave}>
          Save Changes
        </Button>

        <Button
          className={classes.button}
          color="secondary"
          variant="contained"
          onClick={onClose}>
          Close
        </Button>
      </div>
      
      {/* Save Confirmation Button */}
      <StyledModal
        open={showConfirmation}
        onCancel={() => {
          setShowConfirmation(false)
        }}
        options={['noButtons']}
        animatedIcon={<div>
          {showConfirmation && flags.saveNotes === 'processed'
            ? <AnimatedSuccessTick size={50} />
            : <AnimatedErrorCross size={50} />}
          </div>}>
        {flags.saveNotes === 'processed'
          ? 'Incidents notes saved successfully'
          : `Save incident notes failed. Error: ${errorMessage}`
        }
      </StyledModal>
    </ValidatorForm>
  )
}

Form.propTypes = {
  data: PropTypes.object,
  flags: PropTypes.object,
  errorMessage: PropTypes.string,
  // func
  onClose: PropTypes.func.isRequired,
  onSaveIncidentNotes: PropTypes.func.isRequired,
}

export default Form
