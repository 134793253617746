import React from 'react'
import { constants } from '../module'
import { Route, Switch } from 'react-router-dom'
import AddEditSubmission from 'features/fbt-submissions/components/add-edit-submission'
import ViewSubmissions from 'features/fbt-submissions/components/view-submissions'

const FbtWebFormRoutes = () => (
  <Switch>
    <Route path={constants.ROUTE_ADD_SUBMISSION} exact component={AddEditSubmission} />
    <Route path={constants.ROUTE_EDIT_SUBMISSION} exact component={AddEditSubmission} />
    <Route path={constants.ROUTE_VIEW_SUBMISSIONS} exact component={ViewSubmissions} />
  </Switch>
)

export default FbtWebFormRoutes