import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigate, useRouter } from 'utils'
import { Grid } from '@material-ui/core'
import { Dialog } from 'frame/components'
import { useSettingsFleetValues } from 'features/settings'
import { constants } from '../../module'
import { decodeUserId } from '../../utils'
import { connect } from 'react-redux'
import { onChangeUser, requestUser, requestUserUpdate, requestRemoveUser, requestBlockUser } from '../../actions'
import { useProfile } from 'features/account'
import Form from './form' 
import FormCleaner from './form-cleaner'
import { StyledModal, AnimatedErrorCross } from 'frame/components'


const Item = ({ roles, onFetch, onChange, onUpdate, onDelete, onBlock, editUserData, userRoles, currentUser, flags }) => {

  const profile = useProfile()
  const fleets = useSettingsFleetValues()
  const navigate = useNavigate()
  const { match } = useRouter()
  const { id } = match.params
  const userId = decodeUserId(id)
  const [loaded, setLoaded] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)

  // fetch flags
  const isFetching = flags.fetch && flags.fetch === 'processing'
  const FetchingErrored = flags.fetch && flags.fetch === 'error'
  // const isDoneFetching = flags.fetch && flags.fetch === 'processed'

  // update flags
  const isUpdating = flags.update && flags.update === 'processing'
  const UpdatingErrored = flags.update && flags.update === 'error'
  const UpdatingErroredMessage = flags.update && flags.update === 'error' ? flags.message : "No error"
  const isDoneUpdating = flags.update && flags.update === 'processed'

  const onClose = navigate(constants.PATH_ALL)

  useEffect(() => { // fetchs user by id on load
    if(!loaded) {
      onFetch(userId)
      setLoaded(true)
    } // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if(UpdatingErrored){
      setShowErrorModal(true)
    }
  }, [UpdatingErrored])

  useEffect(() => { // closes edit form after done updating
    if (loaded && isDoneUpdating) {
      onClose()
    }// eslint-disable-next-line
  }, [isDoneUpdating, onClose])

  const onModalClose = () => {
    setShowErrorModal(false);
    onFetch(userId)
    setLoaded(true)
  }

  const errorMessage = FetchingErrored
    ? `We were unable to find the user you were looking for.`
    : null

  const onSubmit = (payload) => {
    console.log('[onSave]', JSON.stringify(payload, null, 2)) // eslint-disable-line
    onUpdate(payload)
  }

  const showCleanerForm = profile?.email === 'tomasz@borndigital.co.nz'
  const EditUserForm = showCleanerForm ? FormCleaner : Form

  return (
    <Dialog
      open minHeight={770}
      aria-labelledby="Edit User"
      title="Edit user"
      errorMessage={errorMessage}
      loading={isFetching || isUpdating}
      onClose={onClose}>
      {editUserData && (
        <Grid container>
          <Grid item xs={12}>
            <EditUserForm
              currentUser={currentUser}
              error={UpdatingErrored}
              errorMessage={UpdatingErroredMessage}
              data={editUserData}
              disabled={isUpdating}
              fleets={fleets}
              roles={roles}
              userRoles={userRoles}
              onCancel={onClose}
              onChange={onChange}
              onDelete={onDelete}
              onBlock={onBlock}
              onSubmit={onSubmit} />
          </Grid>
        </Grid>
      )}
      {
        console.log(UpdatingErroredMessage)
      }
      {
        showErrorModal && (
          <StyledModal
            open={showErrorModal}
            onCancel={() => {onModalClose()}}
            options={['noButtons']}
            animatedIcon={<div>
              {<AnimatedErrorCross size={50} />}
            </div>}>
            {UpdatingErroredMessage}
          </StyledModal>
        )
      }


    </Dialog>
  )
}

Item.propTypes = { 
  roles: PropTypes.array.isRequired,
  onFetch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onBlock: PropTypes.func.isRequired,
  editUserData: PropTypes.object.isRequired,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentUser: PropTypes.object.isRequired,
  flags: PropTypes.object,
}
 
const mapStateToProps = ({ adminUsers, settings, account }) => ({
  roles: adminUsers.roles,
  editUserData: adminUsers.editUserData,
  userRoles: settings.roles,
  currentUser: account.profile,
  flags: adminUsers.flags,
})

const mapDispatchToProps = (dispatch) => ({
  onFetch: (userId) => {
    dispatch(requestUser(null, {
      resourceId: userId
    }))
  },
  onChange: (payload) => {
    dispatch(onChangeUser(payload))
  },
  onUpdate: (payload) => {
    dispatch(requestUserUpdate(payload, {
      resourceId: payload.id
    }))
  },
  onDelete: (userId) => {
    dispatch(requestRemoveUser(null, {
      resourceId: userId
    }))
  },
  onBlock: (userId) => {
    dispatch(requestBlockUser(null, {
      resourceId: userId
    }))
  },
})
 
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Item)
