import PropTypes from 'prop-types'


export const model = [
  {
    key: 'accountName',
    label: 'Fleet',
    column: {
      visible: true,
      width: 80,
    },
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }, {
  key: 'agreementNo',
  label: 'Agreement No.',
  column: {
    visible: false,
    width: 115,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, 
{
  key: 'ordNumber',
  label: 'Variation No.',
  column: {
    visible: false,
    width: 115,
  },
  prop: {
    type: PropTypes.number,
    value: '',
  }
}, {
  key: 'registration',
  label: 'Registration',
  column: {
    visible: false,
    width: 90,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'driverNote',
  label: 'Driver Note',
  column: {
    visible: false,
    width: 190,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'driver',
  label: 'Driver',
  column: {
    visible: true,
    width: 190,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
},
{
  key: 'currentMaturityDate',
  label: 'Current Maturity Date',
  column: {
    visible: true,
    width: 150,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  } 
}, 
{
  key: 'maturityDate',
  label: 'Requested Maturity Date',
  column: {
    visible: true,
    width: 120,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  } 
}, 
{
  key: 'currentNoOfTyres',
  label: 'Current Tyres',
  column: {
    visible: true,
    width: 120,
  },
  prop: {
    type: PropTypes.number,
    value: '',
  }
},
{
  key: 'noOfTyres',
  label: 'Requested No. of Tyres',
  column: {
    visible: true,
    width: 105,
  },
  prop: {
    type: PropTypes.number,
    value: '',
  }
},
 
{
  key: 'currentContractKm',
  label: 'Current Contract KM',
  column: {
    visible: false,
    width: 100,
  },
  prop: {
    type: PropTypes.number,
    value: '',
  }
},
{
  key: 'contractKm',
  label: 'Requested Contract KM',
  column: {
    visible: false,
    width: 100,
  },
  prop: {
    type: PropTypes.number,
    value: '',
  }
},
 {
  key: 'status',
  label: 'Variation Status',
  column: {
    visible: true,
    width: 140,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, 
{
  key: 'createdAt',
  label: 'Requested Date',
  column: {
    visible: true,
    width: 140,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}
]

const config = {
  title: 'Contract Variations Summary',
  breadcrumbs: null,
  backgroundImage: 'url("/static/images/backgrounds/bg4.png")',
  idKey: 'id',
  api: {
    filter: '/filteroptions/variation',
    resource: `/contractvariation/variation`,
    download: `/download/variation`,
  },
  model,
  headers: [
    'accountName', 'agreementNo', 'ordNumber', 'registration', 
    'assetBranch', 'assetFunction', 'driverNote', 'driver', 
    'currentMaturityDate', 'maturityDate', 
    'currentNoOfTyres', 'noOfTyres', 
    'currentContractKm', 'contractKm', 
    'status', 'createdAt', 
  ],
  filters: [
  //   {
  //   key: 'costCentre',
  //   label: 'Cost Centre',
  //   prop: {
  //     type: PropTypes.string,
  //     value: '',
  //   }
  // }, {
  //   key: 'make',
  //   label: 'Make',
  //   prop: {
  //     type: PropTypes.string,
  //     value: '',
  //   }
  // }, {
  //   key: 'model',
  //   label: 'Model',
  //   prop: {
  //     type: PropTypes.string,
  //     value: '',
  //   }
  // }, {
  //   key: 'maturityExpiry',
  //   label: 'Maturity Expiry Month',
  //   prop: {
  //     type: PropTypes.string,
  //     value: '',
  //   }
  // }, {
  //   key: 'wofExpiry',
  //   label: 'WoF Expiry Month',
  //   prop: {
  //     type: PropTypes.string,
  //     value: '',
  //   }
  // }, {
  //   key: 'registrationExpiry',
  //   label: 'Registration Expiry Month',
  //   prop: {
  //     type: PropTypes.string,
  //     value: '',
  //   }
  // }
  ],
}


export default config
