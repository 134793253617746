
export const moduleName = `drivers`

export const RESET = `${moduleName}_reset`
export const CREATE = `${moduleName}_create`
export const CREATE_ERROR = `${moduleName}_create_error`
export const CREATE_FULFILLED = `${moduleName}_create_fulfilled`

export const FETCH = `${moduleName}_fetch`
export const FETCH_ERROR = `${moduleName}_fetch_error`
export const FETCH_FULFILLED = `${moduleName}_fetch_fulfilled`

export const FETCH_BY_AGREEMENT = `${moduleName}_fetch_by_agreement`
export const FETCH_BY_AGREEMENT_ERROR = `${moduleName}_fetch_by_agreement_error`
export const FETCH_BY_AGREEMENT_FULFILLED = `${moduleName}_fetch_by_agreement_fulfilled`

export const FETCH_CORRESPONDING_CONTRACT = `${moduleName}_fetch_corresponding_contract`
export const FETCH_CORRESPONDING_CONTRACT_ERROR = `${moduleName}_fetch_corresponding_contract_error`
export const FETCH_CORRESPONDING_CONTRACT_FULFILLED = `${moduleName}_fetch_corresponding_contract_fulfilled`

export const FETCH_CONTRACT = `${moduleName}_fetch_contract`
export const FETCH_CONTRACT_ERROR = `${moduleName}_fetch_contract_error`
export const FETCH_CONTRACT_FULFILLED = `${moduleName}_fetch_contract_fulfilled`

export const UPDATE = `${moduleName}_update`
export const UPDATE_ERROR = `${moduleName}_update_error`
export const UPDATE_FULFILLED = `${moduleName}_update_fulfilled`

export const DELETE = `${moduleName}_delete`
export const DELETE_ERROR = `${moduleName}_delete_error`
export const DELETE_FULFILLED = `${moduleName}_delete_fulfilled`

export const ON_CHANGE = `${moduleName}_on_change`

export const LOOKUP = `${moduleName}_lookup`
export const LOOKUP_ERROR = `${moduleName}_lookup_error`
export const LOOKUP_FULFILLED = `${moduleName}_lookup_fulfilled`


export const TOGGLE_POOL_VEHICLE = `${moduleName}_toggle_pool_vehicle`
export const TOGGLE_POOL_VEHICLE_ERROR = `${moduleName}_toggle_pool_vehicle_error`
export const TOGGLE_POOL_VEHICLE_FULFILLED = `${moduleName}_toggle_pool_vehicle_fulfilled`

export const UNASSIGN_VEHICLE = `${moduleName}_unassign_vehicle`
export const UNASSIGN_VEHICLE_ERROR = `${moduleName}_unassign_vehicle_error`
export const UNASSIGN_VEHICLE_FULFILLED = `${moduleName}_unassign_vehicle_fulfilled`

export const SAVE_TEMP = `${moduleName}_save_temporary`

export const INVITE_DRIVER = `${moduleName}_invite_driver`
export const INVITE_DRIVER_ERROR = `${moduleName}_invite_driver_error`
export const INVITE_DRIVER_FULFILLED = `${moduleName}_invite_driver_fulfilled`