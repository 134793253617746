import React, { Fragment, useContext } from 'react'
import { FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import { TextValidator } from 'react-material-ui-form-validator'
import { SingleSelect, CustomDatePicker, RowValueText } from 'frame/components'

import { useStyles } from "./styles"
import { FormContext } from '../form'
import { NewVehicleSummaryContext } from '../new'
import { FormCta } from './form-cta'
import { windowWhen } from 'rxjs/operators'

const FormComplianceTelematics = () => {
  const classes = useStyles()

  const formContext = useContext(FormContext)
  const parentContext = useContext(NewVehicleSummaryContext)
  const {
    values,
    onSelectChange,
    onInputChange,
  } = formContext
  const {
    setActiveTab
  } = parentContext

  return (
    <>
      <Fragment>
        <Grid item xs={12}>
          <Typography gutterBottom variant="h4">
            Compliance and Telematics
          </Typography>
        </Grid>
        
        <Grid item xs={12}>
          <RowValueText label="Compliance" responsive>
            <RadioGroup
              row
              name="compliance"
              value={values.compliance}
              onChange={onInputChange}
            >
              <FormControlLabel value="cof" control={<Radio color="default" />} label="COF" />
              <FormControlLabel value="wof" control={<Radio color="default" />} label="WOF" />
            </RadioGroup>
          </RowValueText>
        </Grid>

        <Grid item xs={12}>
          <RowValueText label="Eroad" responsive>
            <RadioGroup
              row
              name="eroad"
              value={values.eroad}
              onChange={onInputChange}
            >
              <FormControlLabel value="no" control={<Radio color="default" />} label="No" />
              <FormControlLabel value="yes" control={<Radio color="default" />} label="Yes" />
              <FormControlLabel value="n/a" control={<Radio color="default" />} label="N/A" />
            </RadioGroup>
          </RowValueText>
        </Grid>
      </Fragment>

      <Fragment>
        <Grid item xs={12}>
          <Typography gutterBottom variant="h4">
            Approval Section
          </Typography>
        </Grid>
        <Fragment>
          <Grid item xs={12}>
            <RowValueText label="Driver's Manager" responsive>
              <TextValidator
                id="driversManager"
                className={classes.field}
                fullWidth
                value={values.driversManager || ''}
                inputProps={{ maxLength: 50 }}
                variant="outlined"
                validators={['required']}
                errorMessages={['This field is required']}
                onChange={onInputChange}
                size="small"
              />
            </RowValueText>
          </Grid>
          <Grid item xs={12}>
            <RowValueText label="Signature" responsive>
              <TextValidator
                id="signature"
                className={classes.field}
                fullWidth
                value={values.signature || ''}
                inputProps={{ maxLength: 50 }}
                variant="outlined"
                validators={['required']}
                errorMessages={['This field is required']}
                onChange={onInputChange}
                size="small"
              />
            </RowValueText>
          </Grid>
          <Grid item xs={12}>
            <RowValueText label="Date" responsive>
              <TextValidator
                id="date"
                className={classes.field}
                fullWidth
                value={values.date || ''}
                inputProps={{ maxLength: 50 }}
                variant="outlined"
                validators={['required']}
                errorMessages={['This field is required']}
                onChange={onInputChange}
                size="small"
              />
            </RowValueText>
          </Grid>
        </Fragment>
      </Fragment>
    
      <Fragment>
        <Grid item xs={12}>
          <FormCta
            onSubmit={() => window.alert("Submitted")}
            onBack={() => setActiveTab("vehicle_information")}
          />
        </Grid>
      </Fragment>
    </>
  )
}

export { FormComplianceTelematics }