import * as types from './constants'

export const requestToggleFeature = (payload, options) => ({
  type: types.REQUEST_MYDRIVEAPP_TOGGLE,
  payload,
  options,
})

export const requestToggleFeatureError = (payload, options) => ({
  type: types.REQUEST_MYDRIVEAPP_TOGGLE_ERROR,
  payload,
  options,
})

export const requestToggleFeatureFulfilled = (payload, options) => ({
  type: types.REQUEST_MYDRIVEAPP_TOGGLE_FULFILLED,
  payload,
  options,
})

export const requestViewAll = (payload, options) => ({
  type: types.REQUEST_MYDRIVEAPP_VIEWALL,
  payload,
  options,
})
export const toggleConfirmationModal = (payload, options) => ({
  type: types.TOGGLE_CONFIRMATION_MODAL,
  payload,
  options,
})
