import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Button, Card, CardContent, Grid, Typography, Tooltip } from '@material-ui/core'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { Alert } from 'frame/components'
import { useProfileUpdater } from '../../hooks'


const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    minHeight: 200,
  },
  error: {
    marginBottom: theme.spacing(4),
  },
  fieldHeader: {
    marginTop: theme.spacing(5),
  },
  fieldGroup: {
    margin: theme.spacing(3, 0, 2, 0),
  },
  fieldItem: {
    padding: theme.spacing(0.4, 2),
    ...theme.typography.body1,
  },
  errorText: {
    color: theme.palette.error.main,
    position: 'absolute',
    zIndex: 99
  },
  link: {
    color: theme.palette.text.link,
    textDecoration: 'none',
    cursor: 'pointer'
  },
  fleetList: {    
    columns: 2,
    marginLeft: theme.spacing(-2),
    [theme.breakpoints.down('xs')]: {
      columns: 1,
    }
  },
  actions: {
    marginTop: theme.spacing(1),
    display: 'block',
  },
  button: {
    marginTop: theme.spacing(1),
    minWidth: 180,
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
    }
  },
}))

const MAX_FLEET = 20

const Details = ({ fleets, profile, errorMessage }) => {

  const classes = useStyles()
  const { loading, save, status } = useProfileUpdater()

  const [values, setValues] = useState({
    nickname: profile.nickname,
    email: profile.email,
  })

  const [disableSave, setDisableSave] = useState(false)
  const [showAllFleets, setShowAllFleets] = useState(false)

  const maxSelectedFleets = fleets.length > MAX_FLEET
  const croppedSelectedfleets = maxSelectedFleets ? fleets.slice(0, MAX_FLEET): fleets

  const displayFleets = (fleets) => fleets.map((fleet, index) =>
    <li key={`fleet-${index}`} className={classes.fieldItem}>
      {fleet.label}
    </li>
  )

  const nicknameIsBlank = values.nickname && values.nickname.trim() === ''
  const unEdited = values.nickname === profile.nickname && values.email === profile.email

  // disables save button if fields are invalid
  useEffect(() => {    
    setDisableSave(nicknameIsBlank || unEdited)
  }, [nicknameIsBlank, unEdited, setDisableSave])


  const onChange = event => {
    event.persist()

    setValues({
      ...values,
      [event.target.name]:
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value
    })
  }

  const onSubmit = () => {
    // clean white spaces
    setValues({
      ...values,
      nickname: values.nickname.trim()
    })
    
    console.log('[onSubmit]', JSON.stringify(values, null, 2))
    save(values)
  }

  return (
    <ValidatorForm autoComplete="off" noValidate onSubmit={onSubmit}>
      <Card className={classes.root}>
          <CardContent className={classes.content}>

            {status === 'error' && (
              <Alert className={classes.error}>
                {errorMessage || 'Something went wrong while trying to update your profile.'}
              </Alert>
            )}

            <Typography gutterBottom variant="h4">
              Profile
            </Typography>

            <div className={classes.fieldGroup}>
              <Grid container spacing={4}>
                <Grid item md={6} xs={12}>
                  <TextValidator
                    disabled={loading}
                    fullWidth
                    // helperText="Please add your first and last name"
                    label="Name"
                    name="nickname"
                    required
                    value={values.nickname}
                    inputProps={{ maxLength: 50 }}
                    variant="outlined"
                    validators={['required']}
                    errorMessages={['This field is required']}
                    onChange={onChange}
                  />
                  
                  {nicknameIsBlank && (
                    <Typography variant="body1" className={classes.errorText}>
                      Nickname cannot be white spaces
                    </Typography>
                  )}
                </Grid>

                <Grid item md={6} xs={12}>
                  <TextValidator
                    disabled={loading}
                    fullWidth
                    label="Email"
                    name="email"
                    required
                    value={values.email}
                    variant="outlined"
                    validators={['required', 'isEmail']}
                    errorMessages={['This field is required', 'Must be a valid email']}
                    onChange={onChange} />
                </Grid>

              </Grid>
            </div>

            <div className={classes.actions}>
              <Button
                className={classes.button}
                color="primary"
                disabled={loading || disableSave}
                type="submit"
                variant="contained">
                Save Changes
              </Button>
            </div>

            <div className={classes.fieldHeader}>
              <Typography gutterBottom variant="h4">
                Fleets
              </Typography>
            </div>

            <ul className={classes.fleetList}>
              {displayFleets(showAllFleets ? fleets : croppedSelectedfleets)}
            </ul>

            {maxSelectedFleets && (
              <Typography variant="body2">
                <Tooltip title={!showAllFleets ? `${fleets.length - MAX_FLEET} more` : 'hide'}>
                  <span className={classes.link} onClick={() => setShowAllFleets(!showAllFleets)}>
                    {showAllFleets ? 'Hide' : 'Show All'}
                  </span>
                </Tooltip>
              </Typography>
            )}

          </CardContent>
      </Card>
    </ValidatorForm>
  )
}

Details.propTypes = {
  fleets: PropTypes.array.isRequired,
  profile: PropTypes.object.isRequired,
  errorMessage: PropTypes.string,
}
export default Details
