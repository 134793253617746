import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import Details from './details'
import Profile from './profile'


const useStyles = makeStyles(() => ({
  root: {}
}))

const Form = ({ fleets, profile, errorMessage }) => {

  const classes = useStyles()

  return (
    <Grid className={classes.root} container spacing={3}>

      <Grid item lg={4} md={4} xl={3} xs={12}>
        <Profile profile={profile} />
      </Grid>

      <Grid item lg={8} md={8} xl={9} xs={12}>
        <Details
          fleets={fleets}
          profile={profile}
          errorMessage={errorMessage} />
      </Grid>
      
    </Grid>
  )
}

Form.propTypes = {
  fleets: PropTypes.array.isRequired,
  profile: PropTypes.object.isRequired,
  errorMessage: PropTypes.string,
}

export default Form
