import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Document, Page, pdfjs } from 'react-pdf'

const useStyles = makeStyles(theme => ({
  root: {
    '& .react-pdf__Page__canvas': {
      margin: '0 auto',
      width: 'auto !important',
      height: '150px !important',
    }
  },
}))

const PdfPreview = ({ file }) => {

  const classes = useStyles()

  pdfjs.GlobalWorkerOptions.workerSrc =  `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
  const [pageNumber, setPageNumber] = useState(1)
  
  const onDocumentLoadSuccess = ({ numPages }) => {
    setPageNumber(1)
  }

  return (
    <Document file={file} onLoadSuccess={onDocumentLoadSuccess} className={classes.root}>
      <Page pageNumber={pageNumber} />
    </Document>
  )
}

PdfPreview.propTypes = {
  file: PropTypes.string,
}

export default PdfPreview