
const ACCOUNT_PATH = '/account'

export const constants = {
  PATH_ALL: `${ACCOUNT_PATH}`,
  PATH_SECURITY: `${ACCOUNT_PATH}/security`,
  PATH_PROFILE: `${ACCOUNT_PATH}/profile`,

  ROUTE_ALL: `${ACCOUNT_PATH}/:tab?`,
  ROUTE_SECURITY: `${ACCOUNT_PATH}/security`,
  ROUTE_PROFILE: `${ACCOUNT_PATH}/profile`,

  RESET: `account_reset`,
  SET_PROFILE: `account_set_profile`,

  PUT_PASSWORD: `account_put_password`,
  PUT_PASSWORD_FULFILLED: `account_put_password_fulfilled`,
  PUT_PASSWORD_ERROR: `account_put_password_error`,

  PUT_PROFILE: `account_put_profile`,
  PUT_PROFILE_FULFILLED: `account_put_profile_fulfilled`,
  PUT_PROFILE_ERROR: `account_put_profile_error`,

  UPDATE_PROFILE: `account_update_profile`,
}

export default constants
