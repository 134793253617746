import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, useTheme } from '@material-ui/styles'
import { Grid, Button } from '@material-ui/core'
import { useNavigate } from 'utils'
import { StyledModal, AnimatedSuccessTick, AnimatedErrorCross } from 'frame/components'
import AddBookingDetailsForm from './add-booking-details-form'
import CompleteServiceBookingForm from './complete-service-booking-form'
import AddServiceRecordForm from './add-service-record-form'
import { fetchServiceBooking, reset } from 'features/report-vehicle-servicing/actions'
import ServiceBookingFormWarper from './form-wrapper'
import moment from 'moment'
import { constants } from '../../../module'
import { useEnabledFeatureToggleList } from 'features/settings'
import { hasRoles } from 'acl'
import { useIsAlfaDownDisableFeatures } from 'features/drivers'

const viewConstants = {
  SERVICE_BOOKING_MENU: 'MENU', // default
  ADD_BOOKING_DETAILS_FORM: 'ADD_BOOKING_DETAILS_FORM',
  ADD_SERVICE_RECORD_FORM: 'ADD_SERVICE_RECORD_FORM',
  VIEW_SERVICE_BOOKING: 'VIEW_SERVICE_BOOKING',
}

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(1),
  },
  roundedButton: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    borderRadius: '25px',
    width: 280,
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
    }
  },
  error: {
    marginTop: theme.spacing(2)
  },
  titleStyle: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(0),
    }
  }
}))

const ServiceBookings = ({ id, flags, apiResponse, driverData, fleetData, serviceBookingData, onReset, onFetchServiceBooking,userRoles }) => {

  const theme = useTheme()
  const classes = useStyles()
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'))
  const navigate = useNavigate()

  const featureToggleList = useEnabledFeatureToggleList()
  const userPermissionsFeatureToggle = featureToggleList.includes('UserPermissions')
  const disableOutOfHoursFeatureToggle = featureToggleList.includes('OutOfHoursDisabled') // 'ALFA out of hours DISABLE FEATURES' limitations feature is ON
  const isAlfaDownDisableDriverFeatures = useIsAlfaDownDisableFeatures()
  const disabled = disableOutOfHoursFeatureToggle ? false : isAlfaDownDisableDriverFeatures //false
  
  const disableButtons = disabled ? true : (userPermissionsFeatureToggle && hasRoles(['fleetcoordinator'], userRoles))

  const [activeView, setActiveView] = useState(viewConstants.SERVICE_BOOKING_MENU)
  
  useEffect(() => { // on load
    onReset() // reset flags
    onFetchServiceBooking(id) // fetch service booking data
  }, []) // eslint-disable-line
  
  useEffect(() => { // refetches service booking on add/cancel/complete service booking
    if(flags?.completeServiceBooking === 'processed' || flags?.cancelServiceBooking === 'processed' || flags?.addServiceBooking === 'processed') {
      onFetchServiceBooking(id)
    }
    if(flags?.cancelServiceBooking === 'processed' || flags?.completeServiceBooking === 'processed') {
      setActiveView(viewConstants.SERVICE_BOOKING_MENU)
    }
    if (window.location.pathname.includes(constants.PATH_SERVICE_BOOKINGS_ADD_SERVICE_RECORD)){
      setActiveView(viewConstants.ADD_SERVICE_RECORD_FORM)
    }
  }, [flags?.completeServiceBooking, flags?.cancelServiceBooking, flags?.addServiceBooking]) // eslint-disable-line
  
  // service booking exists - in the future
  const serviceBookingExistsInTheFuture = serviceBookingData && serviceBookingData.bookedDate && moment(serviceBookingData.bookedDate).isAfter(moment())
  
  if(serviceBookingExistsInTheFuture) { // is in the future
    return (
      <AddBookingDetailsForm
        readOnly
        fleetData={fleetData}
        serviceBookingData={serviceBookingData}
      />
    )
  }

  // service booking exists - today or past
  if(serviceBookingData && serviceBookingData.bookedDate
    && (moment(serviceBookingData.bookedDate).format('DD/MM/YYYY') === moment().format('DD/MM/YYYY') // is today
        || moment(serviceBookingData.bookedDate).isBefore(moment()))) {  // is in the past
    return (
      <CompleteServiceBookingForm
        readOnly={disableButtons}
        fleetData={fleetData}
        serviceBookingData={serviceBookingData}
      />
    )
  }
  /* 
  */

  return (
    <>    
      {activeView === viewConstants.ADD_BOOKING_DETAILS_FORM ? (
        <AddBookingDetailsForm        
          fleetData={fleetData}
          onBack={() => {
            setActiveView(viewConstants.SERVICE_BOOKING_MENU)
          }}
        />
      ) : activeView === viewConstants.ADD_SERVICE_RECORD_FORM ? (
        <AddServiceRecordForm
          fleetData={fleetData}
          onBack={() => {
            setActiveView(viewConstants.SERVICE_BOOKING_MENU)
            navigate(`${constants.PATH_ALL}/${id}${constants.PATH_SERVICE_BOOKINGS}`)()
          }}
        />
      ) : (
        // service booking tab default view
        <ServiceBookingFormWarper
          centered
          title="Service Bookings"
          titleStyle={classes.titleStyle}
          subtitle="Add booking details or confirm a completed booking"
        >
          <Grid container spacing={1} className={classes.container}>

            <Grid item xs={12} align="center">
              <Button
                fullWidth={mobileView}
                color="primary"
                className={classes.roundedButton}
                disabled={disableButtons}
                variant="contained"
                onClick={() => setActiveView(viewConstants.ADD_BOOKING_DETAILS_FORM)}>
                Add Booking Details
              </Button>
            </Grid>

            <Grid item xs={12} align="center">
              <Button
                fullWidth={mobileView}
                color="primary"
                className={classes.roundedButton}
                disabled={disableButtons}
                variant="contained"
                onClick={() => {
                  onReset()
                  setActiveView(viewConstants.ADD_SERVICE_RECORD_FORM)
                }}>
                Add Service Record
              </Button>
            </Grid>
          </Grid>
        </ServiceBookingFormWarper>
      )}

      {/* Success/Error modal */}
      <StyledModal
        open={flags.cancelServiceBooking === 'processed' || flags.cancelServiceBooking === 'error' || flags?.completeServiceBooking === 'processed'
          || (serviceBookingExistsInTheFuture && (flags.addServiceBooking === 'processed' || flags.addServiceBooking === 'error'))}
        onCancel={() => onReset()}
        options={['noButtons']}
        animatedIcon={<>
          {flags.cancelServiceBooking === 'processed' || flags.addServiceBooking === 'processed' || flags?.completeServiceBooking === 'processed'
            ? <AnimatedSuccessTick size={50} /> : <AnimatedErrorCross size={50} />}
        </>}
      >
        {apiResponse}
      </StyledModal>
    </>
  )
}

ServiceBookings.propTypes = {
  id: PropTypes.string.isRequired,
  flags: PropTypes.object.isRequired,
  apiResponse: PropTypes.string.isRequired,
  driverData: PropTypes.object.isRequired,
  fleetData: PropTypes.object.isRequired,
  serviceBookingData: PropTypes.object.isRequired,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,

  // func
  onReset: PropTypes.func.isRequired,
  onFetchServiceBooking: PropTypes.func.isRequired,
}

const mapStateToProps = ({ reportVehicleServicing, fleet,settings }, ownProps) => ({
  id: ownProps.match.params.id,
  flags: reportVehicleServicing.flags,
  apiResponse: reportVehicleServicing.apiResponse || '',
  driverData: fleet.related,
  fleetData: fleet.data?.[ownProps.match.params.id],
  serviceBookingData: reportVehicleServicing?.relatedServiceBooking || {},
  userRoles: settings.roles,

})

const mapDispatchToProps = (dispatch) => ({
  onFetchServiceBooking: (agreementNo) => {
    dispatch(fetchServiceBooking(null, { resourceId: agreementNo }))
  },
  onReset: () => {
    dispatch(reset())
  }
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceBookings))
