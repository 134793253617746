import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { trimObjectStrings } from 'utils'
import Item from './item'
import { fetch, update, remove } from '../../actions'


const mapStateToProps = ({ requestReliefVehicles, settings }, ownProps) => {
  const id = ownProps.match.params?.id
  const dataBooking = requestReliefVehicles.data?.[id] || null
  const dataContract = requestReliefVehicles.related?.[dataBooking?.agreementNo] || null
  const fetchStatus = requestReliefVehicles.flags?.fetch || 'none'
  const saveStatus = requestReliefVehicles.flags?.update || 'none'
  const errorMessage = requestReliefVehicles.errorMessage
  const fleetIds = settings.fleet.selected
  const userRoles = settings.roles

  let data = null

  if (dataBooking && dataContract) {
    data = {}
    data.booking = trimObjectStrings(dataBooking) // Because the API does not clean data for us
    data.contract = trimObjectStrings(dataContract) // Because the API does not clean data for us
  }

  return {
    id,
    data,
    fleetIds,
    fetchStatus,
    saveStatus,
    userRoles,
    errorMessage,
  }
}

const mapDispatchToProps = (dispatch) => ({
  onFetch: (id, fleetIds) => {
    const FleetId = fleetIds.join(',')
    dispatch(fetch(null, {
      resourceId: id,
      queryParams: { FleetId }
    }))
  },
  onUpdate: (payload) => {
    dispatch(update(payload, {
      resourceId: payload.id,
    }))
  },
  onDelete: (id, fleetId) => {
    dispatch(remove(null, {
      resourceId: id, 
      queryParams: { fleetId }
    }))
  },
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Item))
