import * as moduleTypes from './types'


export const fetch = (payload, options) => ({
  type: moduleTypes.FETCH,
  payload,
  options,
})

export const fetchError = (payload, options) => ({
  type: moduleTypes.FETCH_ERROR,
  payload,
  options,
})

export const fetchFulfilled = (payload, options) => ({
  type: moduleTypes.FETCH_FULFILLED,
  payload,
  options,
})

// for lookup field
export const lookup = (payload, options) => ({
  type: moduleTypes.LOOKUP,
  payload,
  options,
})

export const lookupError = (payload) => ({
  type: moduleTypes.LOOKUP_ERROR,
  payload,
})

export const lookupFulfilled = (payload) => ({
  type: moduleTypes.LOOKUP_FULFILLED,
  payload,
})

export const resetLookups = () => ({
  type: moduleTypes.RESET_LOOKUPS,
})

export const reset = () => ({
  type: moduleTypes.RESET,
})
