
export const moduleName = `fbtSubmissions`

export const RESET = `${moduleName}_reset`
export const RESET_FLAGS = `${moduleName}_reset_flags`

// Add Fbt Submit
export const ADD_FBT_SUBMISSIONS = `${moduleName}_add_fbt_submissions`
export const ADD_FBT_SUBMISSIONS_ERROR = `${moduleName}_add_fbt_submissions_error`
export const ADD_FBT_SUBMISSIONS_FULFILLED = `${moduleName}_add_fbt_submissions_fulfilled`

// Edit Fbt Submit
export const EDIT_FBT_SUBMISSION_SAVE = `${moduleName}_edit_fbt_submission_save`
export const EDIT_FBT_SUBMISSION_SAVE_ERROR = `${moduleName}_edit_fbt_submission_save_error`
export const EDIT_FBT_SUBMISSION_SAVE_FULFILLED = `${moduleName}_edit_fbt_submission_save_fulfilled`

export const LOOKUP = `${moduleName}_lookup`
export const LOOKUP_ERROR = `${moduleName}_lookup_error`
export const LOOKUP_FULFILLED = `${moduleName}_lookup_fulfilled`

// for edit fbt submission
export const FETCH = `${moduleName}_fetch`
export const FETCH_ERROR = `${moduleName}_fetch_error`
export const FETCH_FULFILLED = `${moduleName}_fetch_fulfilled`

export const SET_FBT_CUSTOMER_VIEW = `${moduleName}_set_fbt_customer_view`
export const RESET_FBT_CUSTOMER_VIEW = `${moduleName}_reset_fbt_customer_view`

// for add/edit fbt form
export const ADD = `${moduleName}_add`
export const EDIT_SAVE = `${moduleName}_edit_save`
export const EDIT_DRAFT = `${moduleName}_edit_Draft`
export const DELETE_DRAFT = `${moduleName}_delete_draft`

export const NEW_DRAFT = `${moduleName}_new_Draft`

// for temp storing new fbt submission driver lookup details when switching to add days view
export const SET_NEW_SUBMISSION_DETAILS = `${moduleName}_set_new_submission_details`

export const fbtReasonOptions = [
  'Livestock Rep Monday to Friday IRD Exemption',
  'Vehicle parked 24 hours over-night on business',
  'Vehicle parked 24 hours over-night on repair',
  'Vehicle parked over-night on company premises',
  'Other'
]

// for non-fbt customers - ROI form
export const REGISTER_INTEREST = `${moduleName}_register_interest`
export const REGISTER_INTEREST_ERROR = `${moduleName}_register_interest_error`
export const REGISTER_INTEREST_FULFILLED = `${moduleName}_register_interest_fulfilled`

// for fbt web form
export const FBT_WEBFORM_VERIFY_TOKEN_VALUE = `${moduleName}_fbt_webform_verify_token_value`
export const FBT_WEBFORM_VERIFY_TOKEN_VALUE_ERROR = `${moduleName}_fbt_webform_verify_token_value_error`
export const FBT_WEBFORM_VERIFY_TOKEN_VALUE_FULFILLED = `${moduleName}_fbt_webform_verify_token_value_fulfilled`

export const FBT_WEBFORM_VERIFY_EMAIL = `${moduleName}_fbt_webform_verify_email`
export const FBT_WEBFORM_VERIFY_EMAIL_ERROR = `${moduleName}_fbt_webform_verify_email_error`
export const FBT_WEBFORM_VERIFY_EMAIL_FULFILLED = `${moduleName}_fbt_webform_verify_email_fulfilled`

export const FBT_WEBFORM_SUBMIT = `${moduleName}_fbt_webform_submit`
export const FBT_WEBFORM_SUBMIT_ERROR = `${moduleName}_fbt_webform_submit_error`
export const FBT_WEBFORM_SUBMIT_FULFILLED = `${moduleName}_fbt_webform_submit_fulfilled`

export const FBT_WEBFORM_SET_VALUES = `${moduleName}_fbt_webform_set_values`
