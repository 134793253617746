import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Listable } from 'listable'
import Row from './row'
// import Filters from '../filters'
import { moduleName } from '../../actions/types'
import { actions, config } from '../../module'
import { useRouter } from 'utils'
import qs from 'query-string'


const List = ({ documentId, model, backPath, onSearch }) => {

  const { location } = useRouter()
  const parsed = qs.parse(location.search)
  const [documentPreviewUri, setDocumentPreviewUri] = useState()
  const [expandedRowKey, setExpandedRowKey] = useState() // use agreementNo as key

  const onExpandRow = (key) => () => setExpandedRowKey(key)

  const renderItem = useCallback((item, index, itemProps) => {

    return (
      <Row
        key={index}
        item={item}
        openedDocumentId={documentId}
        expanded={expandedRowKey === item.agreementNo}
        onExpandRow={onExpandRow(item.agreementNo)}
        {...itemProps}
      />
    ) // eslint-disable-next-line
  }, [documentId, expandedRowKey])

  // Prevents re-rendering if ids or data updated - no longer needed
  const { ids, filters, ...rest } = model

  useEffect(() => {
    if(parsed.registration) {
      onSearch(parsed.registration)
      const expandedDocumentRow = Object.entries(model.data)
        .map(([key, value]) => value)
        .find(item => JSON.stringify(item.registration).includes(parsed.registration))
      expandedDocumentRow && setExpandedRowKey(expandedDocumentRow.agreementNo)
    }
  }, [parsed.registration, onSearch]) // eslint-disable-line


  useEffect(() => {
    const [item] = Object.entries(model.data).filter(([key, value]) => value.registration === parsed.registration)
    if (item) {
      const [modelData] = item
      setExpandedRowKey(() => modelData)
    }

  },[model.data, parsed.registration])


  useEffect(() => {
    if(documentId) { // to load report with corresponding row expanded
      const expandedDocumentRow = Object.entries(model.data)
        .map(([key, value]) => value)
        .find(item => JSON.stringify(item.documentsList).includes(documentId))

      expandedDocumentRow && setExpandedRowKey(expandedDocumentRow.agreementNo)
      const openedDocument = expandedDocumentRow ? expandedDocumentRow.documentsList.find(f => f.id == documentId) : null // eslint-disable-line
      openedDocument && setDocumentPreviewUri(openedDocument?.documentUri)
    }
  }, [documentId]) // eslint-disable-line

  return (
    <Listable
      documentPreviewUri={!!documentId ? documentPreviewUri : null}
      actions={actions}
      config={{ ...config, breadcrumbs: backPath }}
      // filters={Filters}
      model={rest}
      renderItem={renderItem}
    />
  )
}

List.propTypes = {
  documentId: PropTypes.string,
  model: PropTypes.object.isRequired,
  backPath: PropTypes.object,
  // func
  onSearch: PropTypes.func.isRequired,
}

const mapStateToProps = ({ reportDocuments }, ownProps) => {
  return {
    documentId: ownProps.match.params?.id,
    model: reportDocuments,
    backPath: ownProps.location.state,
  }
}

const mapDispatchToProps = (dispatch) => ({
  onSearch: (payload) => {
    dispatch({ type: `${moduleName}_change_search_key`, payload })
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(List)
