import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'
import { useAuth } from './AuthProvider'


const AuthRoute = ({ component: Component, path, ...rest }) => {

  const {
    loading,
    isAuthenticated,
    loginWithRedirect
  } = useAuth()

  useEffect(() => {
    if (loading || isAuthenticated) {
      return
    }

    const authenticate = async () => {
      await loginWithRedirect({
        appState: { targetUrl: path }
      })
    }

    authenticate()

  }, [loading, isAuthenticated, loginWithRedirect, path])

  const render = props =>
    isAuthenticated === true
      ? <Component {...props} />
      : null

  return (
    <Route path={path} render={render} {...rest} />
  )
}

AuthRoute.propTypes = {
  component: PropTypes.any,
  path: PropTypes.string,
}

export default AuthRoute
