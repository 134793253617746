import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Button, Card, CardContent, Grid, Typography } from '@material-ui/core'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { StyledModal, AnimatedSuccessTick, AnimatedErrorCross } from 'frame/components'
import { useProfileUpdater } from '../../hooks'
import PasswordIndicator from './passwordIndicator'


const useStyles = makeStyles(theme => ({
  fieldGroup: {
    margin: theme.spacing(3, 0, 2, 0),
  },
  actions: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    }
  },
  button: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    minWidth: 180,
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
    }
  },
}))

const initialState = {
  password: '',
  confirmPassword: '',
}

const Form = () => {

  const classes = useStyles()
  const { loading, save, status, errorMessage } = useProfileUpdater()

  const [showModal, setShowModal] = useState(false)
  const [values, setValues] = useState(initialState)

  const hasNumber = (value) =>  /(.*\d.*)/.test(value)
  const hasLowercase = (value) => /(.*[a-z].*)/.test(value)
  const hasUppercase = (value) => /(.*[A-Z].*)/.test(value)
  const hasSymbol = (value) => /[-!$%^&@#*()_+|~=`{}[\]:";'<>?,./]/.test(value)

  useEffect(() => {

    ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
      return value !== values.password ? false : true
    })

    ValidatorForm.addValidationRule('hasNumber', (value) => hasNumber(value))
    ValidatorForm.addValidationRule('hasLowercase', (value) => hasLowercase(value))
    ValidatorForm.addValidationRule('hasUppercase', (value) => hasUppercase(value))
    ValidatorForm.addValidationRule('hasSymbol', (value) => hasSymbol(value))

    return () => {
      ValidatorForm.removeValidationRule('isPasswordMatch')
      ValidatorForm.removeValidationRule('hasNumber')
      ValidatorForm.removeValidationRule('hasLowercase')
      ValidatorForm.removeValidationRule('hasUppercase')
      ValidatorForm.removeValidationRule('hasSymbol')
    }

  }, [values.password])

  useEffect(() => {
    setShowModal(status === 'error' || status === 'done')
  }, [status])

  const onChange = event => {
    event.persist()
    setValues({ ...values, [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value })
  }

  const onSubmit = () => {
    // console.log('[onSubmit]', JSON.stringify(values, null, 2))
    save(values)
  }

  return (
    <ValidatorForm autoComplete="off" noValidate onSubmit={onSubmit}>
      <Card>
        <CardContent>

          <Typography gutterBottom variant="h4">
            Change Password
          </Typography>

          <Typography variant="body2">
            Password must be at least 8 characters in length containing both lowercase and uppercase letters, numbers and special characters.
          </Typography>

          <div className={classes.fieldGroup}>

            <Grid container spacing={3}>

              <Grid item md={6} xs={12}>
                <TextValidator
                  disabled={loading}
                  fullWidth
                  onCopy={e => e.preventDefault()}
                  label="New Password"
                  name="password"
                  type="password"
                  value={values.password}
                  variant="outlined"
                  validators={['required', 'minStringLength:8', 'hasNumber', 'hasLowercase', 'hasUppercase', 'hasSymbol']}
                  errorMessages={['This field is required', 'Passwords must be at least 8 characters', 'Passwords must contain atleast 1 number',
                    'Passwords must contain atleast 1 lowercase character', 'Passwords must contain atleast 1 uppercase character', 'Passwords must contain atleast 1 symbol']}
                  onChange={onChange} />
              </Grid>
              
              <Grid item md={6} xs={12}>
                <TextValidator
                  disabled={loading}
                  fullWidth
                  onCopy={e => e.preventDefault()}
                  label="Confirm New Password"
                  name="confirmPassword"
                  type="password"
                  value={values.confirmPassword}
                  variant="outlined"
                  validators={['isPasswordMatch', 'required']}
                  errorMessages={['Password mismatch', 'This field is required']}
                  onChange={onChange} />
              </Grid>

              <Grid item md={6} xs={12}>
                <Typography variant="body2">Password Policy:</Typography>
                <PasswordIndicator
                  passwordMatch={values.password !== '' && values.password === values.confirmPassword}
                  meetsPasswordLength={values.password.length > 7}
                  hasNumber={hasNumber(values.password)}
                  hasLowercase={hasLowercase(values.password)}
                  hasUppercase={hasUppercase(values.password)}
                  hasSymbol={hasSymbol(values.password)}
                />
              </Grid>
            </Grid>
          </div>
        </CardContent>
      </Card>

      <div className={classes.actions}>
        <Button className={classes.button} color="primary" disabled={loading} type="submit" variant="contained">
          Save New Password
        </Button>
      </div>
      
      {/* Success/Error modal */}
      <StyledModal
        open={showModal}
        onCancel={() => setShowModal(false)}
        options={['noButtons']}
        animatedIcon={status === 'done' ? <AnimatedSuccessTick size={50} /> : <AnimatedErrorCross size={50} />}
      >
        {status === 'done'
          ? 'Password updated successfully'
          : `Something went wrong while trying to change your password. ${errorMessage ? `Error: ${errorMessage}` : ''}`.trim()
        }
      </StyledModal>
    </ValidatorForm>
  )
}

export default Form
