import palette from '../palette'
import typography from '../typography'


export default {
  root: {
    ...typography.body1,
    padding: '14px 15px',
    borderBottom: `1px solid ${palette.divider}`
  }
}
