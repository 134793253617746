import React, { useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'
import { makeStyles } from '@material-ui/styles'
import { getStatusColor } from 'listable'
import { Label, StyledModal } from 'frame/components'
import { hasRoles } from 'acl'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Tooltip,
  IconButton,
} from '@material-ui/core'

import DeleteIcon from '@material-ui/icons/Delete'
import { deleteVariation } from 'features/fleet/actions'
import { useSettingsFleetValues } from 'features/settings'


const useStyles = makeStyles(theme => ({
  fieldGroup: {
    margin: theme.spacing(3, 0, 2, 0),
  },
  tableCell: {
    verticalAlign: 'top',
  }
}))

const VariationsList = ({ variations, userRoles, onDeleteVariation }) => {

  const classes = useStyles()
  const fleets = useSettingsFleetValues()
  const [deleteValue, setDeleteValue] = useState(null)

  const deleteHandler = () => {
    if(deleteValue) {
      const fleetId = fleets.filter(node => node.label === deleteValue.accountName)[0].value
      fleetId && onDeleteVariation(deleteValue.id, fleetId)
      setDeleteValue(null)
    }
  }

  const allowedRole = hasRoles(['superadmin'], userRoles)
  // if  super admin? and if status=cancelled, and createdAt + 14 days > now || status=requested allow delete ? 
  const allowDelete = ({ createdAt, status }) => 
    allowedRole && 
    ((moment(createdAt).add(14, 'days').toDate() > moment() && status.toLowerCase() === 'cancelled') ||
    status.toLowerCase() === 'requested') 

  return (
    <div>
      {variations && variations.length > 0 &&
        <div className={classes.fieldGroup}>
          <Table className={classes.table} aria-label="variation requests">
            <TableHead>
              <TableRow>
                <TableCell width="25%" />
                {variations.map((_, index) => {

                  const width = index < variations.length - 1
                    ? '25%'
                    : ''

                  return (
                    <TableCell key={index} width={width}>
                      Variation {index + 1}
                    </TableCell>
                  )
                })}
              </TableRow>
            </TableHead>
            <TableBody>
            <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.tableCell}>
                  <Typography
                    variant="h6">
                    Status
                  </Typography>
                </TableCell>
                {variations.map(({ status }, index) => (
                  <TableCell key={index} className={classes.tableCell}>
                    {status && (
                      <Label
                        color={getStatusColor(status)}
                        variant="outlined">
                        {status || 'n/a'}
                      </Label>
                    )}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.tableCell}>
                  <Typography
                    variant="h6">
                    Agreement Number
                  </Typography>
                </TableCell>
                {variations.map(({ agreementNo }, index) => (
                  <TableCell key={index} className={classes.tableCell}>
                    {agreementNo || '-'}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.tableCell}>
                  <Typography
                    variant="h6">
                    Fleet Name
                  </Typography>
                </TableCell>
                {variations.map(({ accountName }, index) => (
                  <TableCell key={index} className={classes.tableCell}>
                    {accountName || '-'}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.tableCell}>
                  <Typography
                    variant="h6">
                    Maturity Date
                  </Typography>
                </TableCell>
                {variations.map(({ maturityDate }, index) => (
                  <TableCell key={index} className={classes.tableCell}>
                    {maturityDate ? (
                      moment(maturityDate).format('MMMM YYYY')
                    ) : '-'}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.tableCell}>
                  <Typography
                    variant="h6">
                    Contract Km
                  </Typography>
                </TableCell>
                {variations.map(({ contractKm }, index) => (
                  <TableCell key={index} className={classes.tableCell}>
                    {contractKm ? contractKm.toLocaleString('en-NZ') : '-'}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.tableCell}>
                  <Typography
                    variant="h6">
                    Number Of Tyres
                  </Typography>
                </TableCell>
                {variations.map(({ noOfTyres }, index) => (
                  <TableCell key={index} className={classes.tableCell}>
                    {noOfTyres || '-'}
                  </TableCell>
                ))}
              </TableRow>

              {allowedRole && (
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    className={classes.tableCell}>
                    <Typography
                      variant="h6">
                      Action
                    </Typography>
                  </TableCell>
                  {variations.map((variation, index) => (
                    <TableCell key={index} className={classes.tableCell}>
                      {allowDelete(variation) && (
                        <Tooltip
                          className={classes.tooltip}
                          title="Delete">
                          <IconButton
                            size="small"
                            onClick={() => setDeleteValue(variation)}>
                            <DeleteIcon className={classes.icon} />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      }
      <StyledModal
        open={deleteValue}
        title={`Delete Variation`}
        onCancel={() => setDeleteValue(null)}
        onConfirm={deleteHandler}>
        Please confirm that you wish to delete this variation request.
      </StyledModal>
    </div>
  )
}

VariationsList.propTypes = {
  variations: PropTypes.array,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  onDeleteVariation: PropTypes.func.isRequired,
  onFetch: PropTypes.func.isRequired,
}

const mapStateToProps = ({ settings }) => ({
  userRoles: settings.roles,
})

const mapDispatchToProps = (dispatch) => ({
  onDeleteVariation: (id, fleetId) => {
    dispatch(deleteVariation(null, {
      resourceId: id,
      queryParams: { fleetId }
    }))
  },
})
 
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VariationsList)

