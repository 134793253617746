import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Paper, TableContainer, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: 600,
    [theme.breakpoints.down('sm')]: {
      height: '100%',
    },
  },
  container: {
  },
  heading: {
    color: '#546E7A',
    fontsize: '10px',
    letterSpacing: '-0.04px',
    lineHeight: '16px',
    textAlign: 'center',
  },
  cell: {
    verticalAlign: 'top',
  },
  tinyCell: { maxWidth: 100 },
  largeCell: { maxWidth: 150 },
}))

const FbtTable = ({ data }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Paper className={classes.container}>
        <TableContainer>
          <Table stickyHeader aria-label="service history table">

            <TableHead>
              <TableRow>
                {[
                  { label: 'Month', className: classes.tinyCell },
                  { label: 'Year', className: classes.tinyCell },
                  { label: 'Driver Name', className: classes.tinyCell },
                  { label: 'Cost Centre', className: classes.tinyCell },
                  { label: 'Total Days Vehicle Available', className: classes.tinyCell },
                  { label: 'Exempt Days', className: classes.tinyCell },
                  { label: 'Days Available For Pvt Use', className: classes.tinyCell },
                  { label: 'FBT Exempt Notes', className: classes.largeCell }
                ].map((item, index) => (
                  <TableCell key={`table-heading-${index}`} align="center" className={clsx(classes.heading, item?.className)}>
                    <strong>{item?.label}</strong>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {data && data.length > 0 ? data.map((item, rowIndex) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={`table-row-${rowIndex}`}>
                    <TableCell key={`table-serviceDate-column-${rowIndex}`} align="center" className={classes.cell}>
                      {item?.month || '-'}
                    </TableCell>
                    <TableCell key={`table-serviceDate-column-${rowIndex}`} align="center" className={classes.cell}>
                      {item?.year || '-'}
                    </TableCell>
                    <TableCell key={`table-serviceOdo-column-${rowIndex}`} align="center" className={classes.cell}>
                      {item?.driverName || '-'}
                    </TableCell>
                    <TableCell key={`table-serviceType-column-${rowIndex}`} align="center" className={classes.cell}>
                      {item?.costCentre || '-'}
                    </TableCell>
                    <TableCell key={`table-dealership-column-${rowIndex}`} align="center" className={clsx(classes.cell, classes.tinyCell)}>
                      {item?.totalDaysVehicleAvailable || '-'}
                    </TableCell>
                    <TableCell key={`table-dealership-column-${rowIndex}`} align="center" className={clsx(classes.cell, classes.tinyCell)}>
                      {item?.exemptDays || '-'}
                    </TableCell>
                    <TableCell key={`table-dealership-column-${rowIndex}`} align="center" className={clsx(classes.cell, classes.tinyCell)}>
                      {item?.daysAvailableForPvtUse || '-'}
                    </TableCell>
                    <TableCell key={`table-dealership-column-${rowIndex}`} align="center" className={classes.cell}>
                      {item?.exemptNotes || '-'}
                    </TableCell>
                  </TableRow>
              )) : (
                <TableRow>
                  <TableCell align="center" colSpan={4}>
                    No vehicle history available.
                  </TableCell>
                </TableRow>
              )}
              
            </TableBody>

          </Table>
        </TableContainer>
      </Paper>
    </div>
  )
}

FbtTable.propTypes = {
  data: PropTypes.array,
}

export default FbtTable