import React from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, useTheme } from '@material-ui/styles'
import { Grid, Typography, Card, CardContent, Button } from '@material-ui/core'
import { constants as fromDrivers } from 'features/drivers'
import { useNavigate } from 'utils'


const useStyles = makeStyles(theme => ({
  root: {
    height: '460px',
    [theme.breakpoints.down('sm')]: { height: '100%' },
    backgroundColor: '#002849',
    backgroundSize: '250px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '-50px -30px',
    backgroundImage: 'url("/static/images/marketing/car.png")',
  },
  heading: {
    color: '#FFF',
    fontSize: '16px',
    fontWeight: 'bold',
    textAlign: 'left',
    [theme.breakpoints.down(1600)]: { marginTop: '30px', textAlign: 'center' },
    [theme.breakpoints.down('sm')]: { textAlign: 'left' },
    [theme.breakpoints.down('xs')]: { textAlign: 'center' },
  },
  text: {
    color: '#FFF',
    fontSize: '12px',
  },
  imageContainer: {
    textAlign: 'center',
  },
  promoImage: {
    marginTop: '30px',
    width: '100%',
    height: 'auto',
    [theme.breakpoints.down(1600)]: { marginTop: '80px', },
    [theme.breakpoints.down('sm')]: { width: 'auto', height: '250px' },
    [theme.breakpoints.down('xs')]: { width: 'auto', height: '300px' },
  },
  actions: {
    display: 'block',
    marginTop: theme.spacing(1),
  },
  button: {
    minWidth: 180,
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('xs')]: { minWidth: '100%' }
  },
}))

const PromoCard = () => {

  const theme = useTheme()
  const classes = useStyles()
  const tightView = useMediaQuery(theme.breakpoints.down(1600))
  const navigate = useNavigate()

  const onInviteDriver = navigate(fromDrivers.PATH_ALL)
  
  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container spacing={3}>

          <Grid item xs={12} sm={4} md={3} lg={tightView ? 3 : 6} className={classes.imageContainer}>
            <img className={classes.promoImage} src="/static/images/marketing/dashboard-app-promo.png" alt=""/>
          </Grid>

          <Grid item xs={12} sm={8} md={9} lg={tightView ? 9 : 6}>
            <Typography className={classes.heading}>
              Toyota MyDrive
            </Typography>
            <br />
            <Typography className={classes.text}>
              The MyDrive app provides drivers with a holistic view of their vehicle for the duration of its lifecycle. Whether you are looking to keep up to date with general health and safety obligations, manage incidents or simply update vehicle mileage, the interactive dashboards and in-life notifications will ensure important events are always visible to you. 
              <br /><br />
              Direct integration with the ToyotaFleet Portal ensures that all updates recorded in app are transferred and stored in your ToyotaFleet Portal account, helping you stay on top of managing your fleet.
            </Typography>

            <div className={classes.actions}>
              <Button fullWidth className={classes.button} color="primary" variant="contained" onClick={onInviteDriver}>
                Invite Driver
              </Button>
            </div>
          </Grid>
          
        </Grid>
      </CardContent>
    </Card>
  )
}

export default PromoCard
