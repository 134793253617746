import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { MobileDatePicker } from '@material-ui/pickers'
import { TextValidator } from 'react-material-ui-form-validator'
// import TextField from '@material-ui/core/TextField'
import { ValidatorComponent } from 'react-material-ui-form-validator'


class CustomDatePicker extends ValidatorComponent {

  render() {
    const {
      fullWidth,
      variant,
      value,
      format,
      onChange,
      disabledPastDates,
      minDate,
      maxDate,
      textFieldClassName,
      // for TextValidator validation
      validators,
      errorMessagesvalidators, 
      requiredError,
      helperText,
      validatorListener,
      ...rest
    } = this.props

    const { isValid } = this.state

    return (
      <>
        <style scoped>
          {`
            .MuiPickersDay-dayWithMargin.Mui-disabled {
              color: rgba(0, 0, 0, 0.26);
            }
            .MuiPickersDatePickerToolbar-root {
              padding-top: 10px;
            }
            .MuiGrid-root.MuiPickersToolbar-dateTitleContainer.MuiGrid-container.MuiGrid-align-items-xs-flex-end > h4 {
              color: #FFFFFF;
            }
            .MuiPickersDatePickerToolbar-penIcon {
              display: none;
            }
        `}
        </style>
        <MobileDatePicker
          {...rest}
          value={value}
          onChange={onChange}
          inputFormat={format || "Do MMMM YYYY"}
          disablePast={disabledPastDates}
          minDate={minDate && moment(minDate)}
          maxDate={maxDate && moment(maxDate)}
          renderInput={(props) =>
            <TextValidator
              {...props}
              fullWidth={fullWidth}
              variant={variant}
              className={textFieldClassName || ''}
              error={!isValid}
              helperText={(!isValid && this.getErrorMessage()) || helperText}
            />
          }
        />
      </>
    )
  }
}

CustomDatePicker.propTypes = {
  fullWidth: PropTypes.bool,
  variant: PropTypes.string,
  value: PropTypes.string,
  format: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabledPastDates: PropTypes.bool,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  textFieldClassName: PropTypes.string,
  helperText: PropTypes.string,
  validators: PropTypes.array,
  errorMessages: PropTypes.array,
}

export default CustomDatePicker
