import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import Autocomplete from '@material-ui/lab/Autocomplete'
// import { TextField } from 'react-material-ui-form-validator'
import { TextField } from '@material-ui/core'


const useStyles = makeStyles(theme => ({
  inputRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: props => props.showError ? theme.palette.error.main : '#C3C3C3',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: props => props.showError ? theme.palette.error.main : '#767676',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: props => props.showError ? theme.palette.error.main : '#767676',
    },
  },
  errorText: {
    marginLeft: '14px',
    marginRight: '14px',
    color: theme.palette.error.main,
    margin: 0,
    fontSize: '11px',
    marginTop: '3px',
    textAlign: 'left',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    fontWeight: 400,
    lineHeight: '13px',
    letterSpacing: '0.33px',
  }
}))

const SearchDropdown = ({ id, options = [], disabled, label, value, onChange, showError, allowFreeText, size, hideLabelOnMobile, props }) => {

  const classes = useStyles({ showError })

  const [textFieldValue, setTextFieldValue] = useState('')

  const onSelect = useCallback((_, input) => {
    if (onChange && input) {
      onChange(input.value || input || null)
    } else {
      onChange('')
    }
  }, [onChange])

  const onTextFieldChange = ({ target: { value } }) => {
    setTextFieldValue(value)
    allowFreeText && onChange(value)
  }

  const labelParams = hideLabelOnMobile ? null : { label }

  return (
    <div>
      <Autocomplete
        id={id || 'search_dropdown'}
        size={size}
        freeSolo={!!allowFreeText}
        options={options}
        disabled={disabled}
        onChange={onSelect}
        getOptionLabel={option => option && (option.label || option)}
        value={(options && options.find(item => item.value === value || item === value)) || ''}
        renderInput={params => (
          <TextField
            className={classes.inputRoot}
            error={showError}
            {...props}
            {...params}
            {...labelParams}
            fullWidth
            inputProps={{ ...params.inputProps, maxLength: 48 }}
            value={textFieldValue}
            onChange={onTextFieldChange}
            variant="outlined"
          />
        )}
      />

      {showError && (
        <span className={classes.errorText}>
          This field is required
        </span>
      )}
    </div>
  )
}

SearchDropdown.propTypes = {
  id: PropTypes.string,
  size: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  label: PropTypes.string,
  showError: PropTypes.bool,
  allowFreeText: PropTypes.bool,
  hideLabelOnMobile: PropTypes.bool,
}

export default SearchDropdown
