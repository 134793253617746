import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Divider, Typography } from '@material-ui/core'
import { SingleSelect } from 'frame/components'
import { createOnFilter } from 'filter'


const useStyles = makeStyles(theme => ({
  root: {
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 0),
    cursor: 'pointer',
  },
  content: {},
  formGroup: {
    padding: theme.spacing(2, 0),
  },
}))

const FilterAttributes = ({ filters, filtered, onFilter }) => {

  const classes = useStyles()
  const onSelect = createOnFilter(onFilter)
  const hasList = (list) => list.length > 0
  const { costCentres, maturityExpiry, fmoTypes } = filters

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h5">Filters</Typography>
      </div>
      <Divider />
      <div className={classes.content}>
        {hasList(costCentres) && (
          <div className={classes.formGroup}>
            <SingleSelect
              tight
              id="costCentre"
              label="Cost Centre"
              options={costCentres}
              value={filtered.costCentre}
              onChange={onSelect('costCentre')} />
          </div>
        )}
        {hasList(maturityExpiry) && (
          <div className={classes.formGroup}>
            <SingleSelect
              id="maturityExpiry"
              label="Maturity Date"
              options={maturityExpiry}
              tight
              value={filtered.maturityExpiry}
              onChange={onSelect('maturityExpiry')} />
          </div>
        )}
          {hasList(fmoTypes) && (
            <div className={classes.formGroup}>
              <SingleSelect
                id="fmoType"
                label="FMO"
                options={fmoTypes}
                tight
                value={filtered.fmoType}
                onChange={onSelect('fmoType')} />
            </div>
          )}        
      </div>
    </div>
  )
}

FilterAttributes.propTypes = {
  filters: PropTypes.object.isRequired,
  filtered: PropTypes.object.isRequired,
  onFilter: PropTypes.func.isRequired,
}

export default FilterAttributes
