import { combineEpics, ofType } from 'redux-observable'
import { mergeMap, catchError, /* flatMap,*/ delay } from 'rxjs/operators'
import { ajax } from 'rxjs/ajax'
import { of } from 'rxjs'
import { makeActionRequestEffect } from 'effects'
import join from 'url-join'
import { getAppConfig } from 'app-config'
import * as actions from './actions'
import * as types from './constants'
// import { getAuthClient } from 'auth'


export const createEffects = (moduleActions) => {

  const appConfig = getAppConfig()

  const sendInviteEmailsEffect = makeActionRequestEffect({
    verb: 'post',
    type: types.SEND_INVITE_EMAILS,
    url: join(appConfig.app.api, '/registrationOfInterest/sendInvites'),
    onSuccess: actions.sendInviteEmailsFulfilled,
    onError: actions.sendInviteEmailsError,
  })

  const refreshUserEffect = (action$, state$) =>
    action$.pipe(
      ofType(
        types.UPLOAD_CSV_FULFILLED,
        types.SEND_INVITE_EMAILS_FULFILLED,
        types.SEND_INVITE_EMAILS_ERROR,
      ),
      delay(1000),
      mergeMap(() => of(
          moduleActions.refetch(),
        )
      )
    )

    const registerInterestEffect = (action$, state$) =>  
      action$.pipe(
        ofType(types.REGISTER_INTEREST),
        mergeMap(({ payload }) =>
          ajax({
            method: 'POST',
            url: join(appConfig.app.api, '/portalRegistration/register'),
            body: payload,
            headers: { 'Content-Type': 'application/json; charset=UTF-8' }
          }).pipe(
            mergeMap(
              ajaxResponse => {
                console.log('[registerInterestEffect]: ', ajaxResponse)
                return of(actions.registerInterestFulfilled())
              }
            ),
            catchError(error =>{
              console.log('[registerInterestEffect]: ', error)
              return of(actions.registerInterestError(error?.response?.data || error?.response || error))
            })
          )
        )
      )

  // TODO: work out why formData is not attaching to request
  // const uploadCsvEffect = (action$, state$) =>  
  //     action$.pipe(
  //       ofType(types.UPLOAD_CSV),
  //       flatMap(createAuthHeaderRequest(action$.payload)),
  //       mergeMap(({ payload, headers }) => {

  //           console.log('[uploadCsvEffect]: ', {
  //             headers,
  //             payload
  //           })
  //           const formData = new FormData()
  //           payload && formData.append("csvFile", payload)
  //           console.log('[formData]: ', formData.get('csvFile'))

  //           return ajax({
  //             method: 'POST',
  //             url: join(appConfig.app.api, '/registrationOfInterest/uploadCsv'),
  //             body: formData,
  //             headers: headers
  //           }).pipe(
  //             mergeMap(
  //               ajaxResponse => {
  //                 console.log('[uploadCsvFulfilled]: ', ajaxResponse)
  //                 return of(actions.uploadCsvFulfilled())
  //               }
  //             ),
  //             catchError(error =>{
  //               console.log('[sendInviteEmailsError]: ', error)
  //               return of(actions.sendInviteEmailsError(error?.response?.data || error?.response || error))
  //             })
  //           )
  //         }
  //       )
  //     )

  return combineEpics(
    // uploadCsvEffect,
    sendInviteEmailsEffect,
    registerInterestEffect,
    refreshUserEffect,
  )
}


// const createAuthHeaderRequest = () => async (payload) => {
//   const authClient = getAuthClient()
//   const token = await authClient.getTokenSilently()

//   return {
//     payload,
//     headers: {
//       Authorization: `Bearer ${token}`,
//       'Content-Type': 'multipart/form-data; application/json',
//     },
//   }
// }