import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { Paper, Input, Tooltip, IconButton } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import CloseIcon from '@material-ui/icons/Close'


const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  search: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    height: 42,
    padding: theme.spacing(0, 2),
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: theme.palette.icon
  },
  clearIcon: {
    color: theme.palette.icon,
    "&:hover": {
      backgroundColor: '#eeeeee !important',
      cursor: 'pointer',
    }
  },
  searchInput: {
    flex: 1
  },
  searchButton: {
    marginLeft: theme.spacing(2)
  },
  active: {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.palette.text.link,
  },
}))

const Search = ({ className, searchKey, onSearch, ...rest }) => {

  const classes = useStyles()
  const field = useRef()

  const len = searchKey ? searchKey.length : 0
  const [searching, setSearching] = useState(len)
  const [value, setValue] = useState(searchKey)

  const onFocus = () =>
    field.current && field.current.focus()

  const onChange = useCallback(({ target }) => {
    const value = target?.value || ''
    setValue(value)
    onSearch(value.trim())
    setSearching(value.length)
  }, [onSearch, setSearching])

  useEffect(() => {
    setValue(len === 0 ? '' : searchKey)
    // eslint-disable-next-line
  }, [searchKey])

  const onClear = () => {
    setValue('')
    onSearch('')
  }

  return (
    <div {...rest} className={clsx(classes.root, className)} onClick={onFocus}>
      <Paper className={clsx(classes.search, searching ? classes.active : '')} elevation={1}>
        <SearchIcon className={classes.searchIcon} />
        <Input
          className={classes.searchInput}
          disableUnderline
          value={value}
          inputRef={field}
          placeholder="Search"
          onChange={onChange}
        />
        {value.length > 0 && (
          <Tooltip className={classes.icon} title="clear search">
            <IconButton className={classes.clearIcon} size="small" onClick={onClear}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        )}
      </Paper>
    </div>
  )
}

Search.propTypes = {
  className: PropTypes.string,
  searchKey: PropTypes.string,
  onSearch: PropTypes.func
}

export default Search
