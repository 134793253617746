export const FBT_WEB_FORM_PATH = '/fbt-web-form'

export const constants = {
  PATH_ROOT: FBT_WEB_FORM_PATH,
  PATH_QUESTION: `${FBT_WEB_FORM_PATH}/availability`,
  PATH_FORM: `${FBT_WEB_FORM_PATH}/form`,
  PATH_FORM_FINISH: `${FBT_WEB_FORM_PATH}/finish`,

  PATH_ADD_SUBMISSION: `/add-submission`,
  PATH_EDIT_SUBMISSION: `/edit-submission`,
  PATH_VIEW_SUBMISSIONS: `/view-submissions`,
  
  ROUTE_ADD_SUBMISSION: `${FBT_WEB_FORM_PATH}/form/add-submission`,
  ROUTE_EDIT_SUBMISSION: `${FBT_WEB_FORM_PATH}/form/edit-submission`,
  ROUTE_VIEW_SUBMISSIONS: `${FBT_WEB_FORM_PATH}/form/view-submissions`,
}
