import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Listable } from 'listable'
import { useNavigate } from 'utils'
import Row from './row'
import Filters from '../filters'
import { ChildRoutes } from '../../routes'
import { actions, config, constants } from '../../module'


const List = ({ model }) => {

  const linker = useNavigate()

  const renderItem = useCallback((item, index, itemProps) => {

    const isNonTfmVehicle = item.agreementNo.includes('UNF')
    const onView = linker(constants.PATH_DRILLDOWN, item.agreementNo, isNonTfmVehicle ? 'contract-details' : 'service-bookings')
    
    return (
      <Row key={index} item={item} onView={onView} {...itemProps} />
    )
  }, [linker])

  // Prevents re-rendering if ids or data updated - no longer needed
  const { ids, filters, ...rest } = model
  
  return (
    <Listable
      actions={actions}
      config={config}
      filters={Filters}
      model={rest}
      renderItem={renderItem}
      routes={ChildRoutes}
    />
  )
}

List.propTypes = {
  model: PropTypes.object.isRequired,
}

const mapStateToProps = ({ reportVehicleServicing }) => ({
  model: reportVehicleServicing,
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(List)
