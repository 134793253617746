import { createListable } from 'listable'
import { combineEpics } from 'redux-observable'
import options from './config'
import { createEffects } from './effects'
import * as customActions from './actions'
import { moduleName } from './actions/types'
import customReducer from './reducer'


const listableModule = createListable({
  name: moduleName,
  config: options,
  customActions,
  customReducer,
})

export const config = listableModule.options
export const actions = listableModule.actions

export const effects = combineEpics(
  createEffects(listableModule.actions),
  listableModule.effects,
)

export const hooks = listableModule.hooks
export const reducer = listableModule.reducer
export const types = listableModule.types

export const useFetch = listableModule.hooks.useFetch // TODO - remove

export const constants = {
  ...listableModule.constants,
  PATH_ALL: `/fleet`,
  ROUTE_ALL: `/fleet`,

  PATH_DRILLDOWN: `/fleet`,
  ROUTE_DRILLDOWN: `/fleet/:id`,

  PATH_CONTACT: `/contract-details`,
  PATH_DRIVER: `/driver-details`,
  PATH_VEHICLE: `/service-history`,
  PATH_VARIATIONS: `/variations`,
  PATH_SERVICE_BOOKINGS: `/service-bookings`,
  PATH_SERVICE_BOOKINGS_ADD_SERVICE_RECORD: `/service-bookings/add-service-record`,
  PATH_FBT: `/fbt`,
  
  ROUTE_CONTACT: `/fleet/:id/contract-details`,
  ROUTE_DRIVER: `/fleet/:id/driver-details`,
  ROUTE_VEHICLE: `/fleet/:id/service-history`,
  ROUTE_VARIATIONS: `/fleet/:id/variations`,
  ROUTE_SERVICE_BOOKINGS: `/fleet/:id/service-bookings`,
  ROUTE_FBT: `/fleet/:id/fbt`,
}

