import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { makeStyles, useTheme } from '@material-ui/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Grid, Typography } from '@material-ui/core'
import { RowValueText, CustomDatePicker, StyledModal, AnimatedSuccessTick, AnimatedErrorCross } from 'frame/components'
import { TextValidator } from 'react-material-ui-form-validator'
import ServiceBookingFormWarper from './form-wrapper'
import { completeServiceBooking, cancelServiceBooking, reset } from 'features/report-vehicle-servicing/actions'
import moment from 'moment'
import { useEnabledFeatureToggleList } from 'features/settings'
import { hasRoles } from 'acl'
import { useIsAlfaDownDisableFeatures } from 'features/drivers'


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4, 0, 2, 0),
  },
  label: {
    paddingTop: theme.spacing(3),
  },
  dateField: {
    margin: 0,
    padding: 0,
    '& > div': {
      margin: 0,
      padding: '11px 8px',
      borderRadius: '4px',
      '&::before': { display: 'none' },
      '&::after': { display: 'none' },
    },
    '& input': { margin: 0, padding: 0, border: 'none' }
  },
}))

const CompleteServiceBookingForm = ({
  flags,
  readOnly,
  fleetData,
  serviceBookingData,
  apiResponse,
  // func
  onCompleteServiceBooking,
  onCancelServiceBooking,
  onReset,
  userRoles
}) => {

  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'))
  const classes = useStyles()

  const [showDisclaimer, setShowDisclaimer] = useState(false)
  const [showConfirmCancelModal, setShowConfirmCancelModal] = useState(false)

  const featureToggleList = useEnabledFeatureToggleList()
  const userPermissionsFeatureToggle = featureToggleList.includes('UserPermissions')
  const disableButtons = userPermissionsFeatureToggle && hasRoles(['fleetcoordinator'], userRoles)
  
  const disableOutOfHoursFeatureToggle = featureToggleList.includes('OutOfHoursDisabled') // 'ALFA out of hours DISABLE FEATURES' limitations feature is ON
  const isAlfaDownDisableDriverFeatures = useIsAlfaDownDisableFeatures()
  const isAlfaDown = disableOutOfHoursFeatureToggle ? true : isAlfaDownDisableDriverFeatures

  const [values, setValues] = useState({
    completedDate: serviceBookingData?.bookedDate ? moment(serviceBookingData?.bookedDate) : moment(),
    completedLocation: serviceBookingData?.bookedLocation ? serviceBookingData?.bookedLocation : '',
    odometer: ''
  })

  const onInputChange = ({ target: { id, name, value } }) =>
    setValues({ ...values, [id || name]: value })
  
  const onSelectChange = (id) => (value) =>
    setValues({ ...values, [id]: value })
  
  const onMarkAsComplete = () => {
    setShowDisclaimer(false)
    const payload = {
      agreementNumber: fleetData.agreementNo,
      vehicleRegistration: fleetData.registration,
      completedDate: values.completedDate,
      completedLocation: values.completedLocation,
      odometer: +values.odometer,
    }
    console.log('[onAddServiceBooking] payload: ', payload)
    onCompleteServiceBooking(payload)
  }

  const onCancel = () => onCancelServiceBooking({ agreementNumber: fleetData?.agreementNo })

  return (
    <>
      <ServiceBookingFormWarper
        title="Service Bookings"
        subtitle="Cancel or confirm you have completed your service"
        disabled={disableButtons || isAlfaDown || flags.completeServiceBooking === 'processing' || flags.cancelServiceBooking === 'processing'}
        onSubmit={() => setShowDisclaimer(true)}
        onSubmitLabel="Mark as Completed"
        onCancel={() => setShowConfirmCancelModal(true)}
        onCancelLabel="Cancel Service Booking"
      >
        <Grid container spacing={2} className={classes.root}>
          
          <Grid item xs={12}>
            <RowValueText label="Service Date" labelClassName={!readOnly ? classes.label : ''} responsive={!readOnly}>
              {readOnly ? (
                <Typography variant="body1">
                  {values.completedDate ? moment(values.completedDate).format('DD/MM/YYYY') : '-'}
                </Typography>
              ) : (
              <CustomDatePicker
                fullWidth tight
                id="completedDate"
                variant="outlined"
                format="Do MMMM YYYY"
                label={mobileView ? 'Service Date' : ''}
                value={values.completedDate}
                maxDate={moment()}
                onChange={onSelectChange('completedDate')}
                textFieldClassName={classes.dateField}
              />
            )}
            </RowValueText>
          </Grid>

          <Grid item xs={12}>
            <RowValueText label="Service Dealership" responsive={!readOnly}>
              {readOnly ? (
                <Typography variant="body1">
                  {values.completedLocation || '-'}
                </Typography>
              ) : (
                <TextValidator
                  id="completedLocation"
                  size="small"
                  // disabled={disabled}
                  fullWidth
                  label={mobileView ? 'Service Dealership' : ''}
                  InputLabelProps={{ shrink: mobileView }} // to show label only on mobile
                  placeholder="Service Dealership"
                  value={values.completedLocation || ''}
                  variant="outlined"
                  inputProps={{ maxLength: 50 }}
                  validators={['required']}
                  errorMessages={['This field is required']}
                  onChange={onInputChange}
                />
              )}
            </RowValueText>
          </Grid>

          <Grid item xs={12}>
            <RowValueText label="Odometer Reading (km)" responsive={!readOnly}>
              {readOnly ? (
                <Typography variant="body1">
                  {values.odometer || '-'}
                </Typography>
              ) : (
                <TextValidator
                  id="odometer"
                  size="small"
                  // disabled={disabled}
                  fullWidth
                  label={mobileView ? 'Odometer Reading' : ''}
                  InputLabelProps={{ shrink: mobileView }} // to show label only on mobile
                  placeholder="Enter Odometer Reading"
                  value={values.odometer || ''}
                  variant="outlined"
                  inputProps={{ maxLength: 50 }}
                  validators={['required', 'isNumber']}
                  errorMessages={['This field is required', 'A valid odometer reading is required']}
                  onChange={onInputChange}
                />
              )}
            </RowValueText>
          </Grid>
        </Grid>
      </ServiceBookingFormWarper>
      
      {/* Mark as Complete - Disclaimer Modal */}
      {showDisclaimer && (
        <StyledModal
          flag={flags.completeServiceBooking}
          open={showDisclaimer}
          title="DISCLAIMER"
          titleVariant="h6"
          onConfirm={onMarkAsComplete}
          onCancel={() => setShowDisclaimer(false)}
          options={['boldHeading']}
        >
          <Typography variant="body1">
            I confirm the Service Record data being entered is true and correct and it can be relied upon by both my organisation and Toyota Finance New Zealand (TFNZ). TFNZ accepts no liability for any errors or omissions.
          </Typography>
        </StyledModal>
      )}
      
      {/* for cancel confirmation */}
        <StyledModal
          flag={flags.cancelServiceBooking}
          open={showConfirmCancelModal}
          title={`Service Booking`}
          onCancel={() => setShowConfirmCancelModal(false)}
          onConfirm={onCancel}>
          By clicking on confirm you are indicating that the service booking you have recorded for this vehicle is not proceeding.
          <br/><br/>
          NOTE: cancelling your service booking will only update the vehicle record within the Portal. You may need to contact the servicing dealership to confirm the cancellation.
        
        </StyledModal>

      
      {/* Success/Error modal */}
      <StyledModal
        open={flags.addServiceBooking === 'processed'
          || flags.addServiceBooking === 'error' 
          || flags.completeServiceBooking === 'error'
          || flags.cancelServiceBooking === 'error'
        }
        onCancel={() => onReset()}
        options={['noButtons']}
        animatedIcon={<div>
          {flags.addServiceBooking === 'processed'
            ? <AnimatedSuccessTick size={50} />
            : <AnimatedErrorCross size={50} />}
          </div>}>
          {apiResponse}
      </StyledModal>
    </>
  )
}

CompleteServiceBookingForm.propTypes = {
  readOnly: PropTypes.bool,
  flags: PropTypes.object.isRequired,
  fleetData: PropTypes.object.isRequired,
  apiResponse: PropTypes.string.isRequired,
  // func
  onCompleteServiceBooking: PropTypes.func.isRequired,
  onCancelServiceBooking: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
}

const mapStateToProps = ({ reportVehicleServicing,settings }) => ({
  flags: reportVehicleServicing.flags,
  apiResponse: reportVehicleServicing.apiResponse || '',
  userRoles: settings.roles,
})

const mapDispatchToProps = (dispatch) => ({
  onCompleteServiceBooking: (payload) => {
    dispatch(completeServiceBooking(payload))
  },
  onCancelServiceBooking: (payload) => {
    dispatch(cancelServiceBooking(payload))
  },
  onReset: () => {
    dispatch(reset())
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(CompleteServiceBookingForm)