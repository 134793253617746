import PropTypes from 'prop-types'

export const model = [{
  key: 'id',
  label: 'Id',
  column: {
    visible: false,
    width: 200,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'fleet',
  label: 'Fleet',
  column: {
    visible: true,
    width: 160
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'driverAssignment',
  label: 'Driver Assignment',
  column: {
    visible: true,
    width: 320,
    showToggle: true,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}
]

const config = {
  title: 'Settings',
  subtitle: 'Toyota MyDrive',
  subTitleStyles: { margin: '20px 0 -30px 0' },
  breadcrumbs: null,
  backgroundImage: 'url("/static/images/backgrounds/bg4.png")',
  idKey: 'id',
  api: {
    filter: '/',
    resource: `/featuresmydriveapp/featuretoggle`,
  },
  model,
  headers: [
    'fleet', 'driverAssignment'
  ],
  filters: [],
  noPagination: true,
  noColumnSort: true,
  noDownload: true,
  noShowingResults: true,
  showSearchOnTableHeader: true,
}


export default config
