import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Typography, Card, CardContent } from '@material-ui/core'
import DownloadCarousel from './download-carousel'


const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    minHeight: '317px',
    [theme.breakpoints.down('md')]: { minHeight: '360px' },
    [theme.breakpoints.down('sm')]: { minHeight: '100%' },
  },
  title: {
    color: '#263238',
    fontWeight: 'bold',
    fontSize: '18px',
  },
}))

const Downloads = () => {

  const classes = useStyles()

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography variant="h4" className={classes.title}>
          Downloads
        </Typography>
      
        <DownloadCarousel />
      </CardContent>
    </Card>
  )
}

export default Downloads
