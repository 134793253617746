import React from 'react'
import { Route, Switch } from 'react-router-dom'

import Driver from './driver'
import Contact from './contact'
import Vehicle from './vehicle'
import Variations from './variations'
import ServiceBookings from './service-bookings'
import FBT from './fbt'

import { constants } from '../../module'
import { constants as fromRequestVariationSummary } from 'features/request-contract-variations/module'

 // not sure why these are not working
// import { constants as fromKmTracking } from 'features/report-km-tracking'
// import { constants as fromServiceTracking } from 'features/report-vehicle-servicing'


const DrillDownRoutes = () => {

  const isServiceTrackingPage = window.location.pathname.includes('/reports/vehicle-servicing')
  const isKMTrackingPage = window.location.pathname.includes('/reports/km-tracking')
  const isRequestVariationSummaryPage = window.location.pathname.includes('/contract-variations')
  const isTFSFleetPage = window.location.pathname.includes('/fleet') 

  const fromKmTracking = {
    ROUTE_CONTACT: `/reports/km-tracking/:id/contract-details`,
    ROUTE_DRIVER: `/reports/km-tracking/:id/driver-details`,
    ROUTE_VEHICLE: `/reports/km-tracking/:id/service-history`,
    ROUTE_VARIATIONS: `/reports/km-tracking/:id/variations`,
    ROUTE_SERVICE_BOOKINGS: `/reports/km-tracking/:id/service-bookings`,
    ROUTE_FBT: `/reports/km-tracking/:id/fbt`,
  }

  const fromServiceTracking = {
    ROUTE_CONTACT: `/reports/vehicle-servicing/:id/contract-details`,
    ROUTE_DRIVER: `/reports/vehicle-servicing/:id/driver-details`,
    ROUTE_VEHICLE: `/reports/vehicle-servicing/:id/service-history`,
    ROUTE_VARIATIONS: `/reports/vehicle-servicing/:id/variations`,
    ROUTE_SERVICE_BOOKINGS: `/reports/vehicle-servicing/:id/service-bookings`,
    ROUTE_FBT: `/reports/vehicle-servicing/:id/fbt`,
  }
  
  return (
    <Switch>
      {isRequestVariationSummaryPage && (<>
        <Route exact path={fromRequestVariationSummary.ROUTE_CONTACT} component={Contact} />
        <Route exact path={fromRequestVariationSummary.ROUTE_DRIVER} component={Driver} />
        <Route exact path={fromRequestVariationSummary.ROUTE_VEHICLE} component={Vehicle} />
        <Route exact path={fromRequestVariationSummary.ROUTE_VARIATIONS} component={Variations} />
        <Route includes path={fromRequestVariationSummary.ROUTE_SERVICE_BOOKINGS} component={ServiceBookings} />
        <Route exact path={fromRequestVariationSummary.ROUTE_FBT} component={FBT} />
      </>)}      

      {isServiceTrackingPage && (<>
        <Route exact path={fromServiceTracking.ROUTE_CONTACT} component={Contact} />
        <Route exact path={fromServiceTracking.ROUTE_DRIVER} component={Driver} />
        <Route exact path={fromServiceTracking.ROUTE_VEHICLE} component={Vehicle} />
        <Route exact path={fromServiceTracking.ROUTE_VARIATIONS} component={Variations} />
        <Route includes path={fromServiceTracking.ROUTE_SERVICE_BOOKINGS} component={ServiceBookings} />
        <Route exact path={fromServiceTracking.ROUTE_FBT} component={FBT} />
      </>)}      

      {isKMTrackingPage && (<>
        <Route exact path={fromKmTracking.ROUTE_CONTACT} component={Contact} />
        <Route exact path={fromKmTracking.ROUTE_DRIVER} component={Driver} />
        <Route exact path={fromKmTracking.ROUTE_VEHICLE} component={Vehicle} />
        <Route exact path={fromKmTracking.ROUTE_VARIATIONS} component={Variations} />
        <Route includes path={fromKmTracking.ROUTE_SERVICE_BOOKINGS} component={ServiceBookings} />
        <Route exact path={fromKmTracking.ROUTE_FBT} component={FBT} />
      </>)}      

      {isTFSFleetPage && (<>
        <Route exact path={constants.ROUTE_CONTACT} component={Contact} />
        <Route exact path={constants.ROUTE_DRIVER} component={Driver} />
        <Route exact path={constants.ROUTE_VEHICLE} component={Vehicle} />
        <Route exact path={constants.ROUTE_VARIATIONS} component={Variations} />
        <Route includes path={constants.ROUTE_SERVICE_BOOKINGS} component={ServiceBookings} />
        <Route exact path={constants.ROUTE_FBT} component={FBT} />
      </>)}
    </Switch>
  )
}

export default DrillDownRoutes