import PropTypes from 'prop-types'


export const model = [{
  key: 'id',
  label: 'ID',
  prop: {
    type: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]).isRequired,
    value: '',
  }
}, {
  key: 'costCentre',
  label: `Cost Centre`,
  column: {
    visible: true,
    width: 110,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
},
{
  key: 'contactEmail',
  label: `Contact Email`,
  column: {
    visible: false,
    width: 110,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
},
{
  key: 'destination',
  label: 'Destination Address',
  column: {
    visible: true,
    width: 160,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'effectiveDate',
  label: 'Effective Date',
  column: {
    visible: true,
    width: 110,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'driverName',
  label: 'Name',
  column: {
    visible: true,
    width: 160,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'driverMobile',
  label: 'Mobile',
  column: {
    visible: false,
    width: 110,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'pickup',
  label: 'Pickup Address',
  column: {
    visible: true,
    width: 160,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'referenceId',
  label: 'Reference No.',
  column: {
    visible: true,
    width: 110,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'vehicleRegistration',
  label: 'Registration',
  column: {
    visible: true,
    width: 110,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'status',
  label: 'Status',
  column: {
    visible: true,
    width: 120,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'accountName',
  label: 'Fleet',
  column: {
    visible: true,
    width: 80,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}]

export const formTabs = [
  {
    value: "lease",
    label: "Lease Information"
  },
  {
    value: "driver_details",
    label: "Driver Details"
  },
  {
    value: "vehicle_information",
    label: "Vehicle Information",
  },
  {
    value: "compliance_telematics",
    label: "Compliance and Telematics",
  }
]

const data = {
  title: 'Vehicle Request Summary',
  breadcrumbs: null,
  backgroundImage: 'url("/static/images/backgrounds/bg4.png")',
  idKey: 'id',
  api: {
    filter: '/filteroptions/vehiclerelocation',
    resource: `/vehiclerelocation/vehiclerelocation`,
    download: `/download/vehiclerelocation`,
  },
  model,
  headers: [
    'accountName', 'referenceId', 'vehicleRegistration', 'driverName', 'driverMobile', 'contactEmail', 'costCentre',
    'pickup', 'destination', 'effectiveDate', 'status',
  ],
  filters: [{
    key: 'costCentre',
    label: 'Cost Centre',
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }, {
    key: 'effectiveDate',
    label: 'Effective Date',
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }],
}


export default data
