import { reduceRequest } from 'listable'
import * as types from './constants'
import * as R from 'ramda'


const resetFlags = (state, flags) => ({
  ...state,
  editUserData: null,
  errorMessage: null,
  flags: {
    ...state.flags,
    fetch: 'none',
    create: 'none',
    update: 'none',
    delete: 'none',
    ...flags,
  },
})

const updateFlags = (state, flags) => ({
  ...state,
  flags: {
    ...state.flags,
    ...flags,
  }
})

const reducer = (state, action) => {
  switch (action.type) {
    case types.RESET:
      return resetFlags(state) 

    // TODO: refactor out use of reduceRequest and unused actions
    case types.REQUEST_REMOVE_USER:
    case types.REQUEST_REMOVE_USER_ERROR:
    case types.REQUEST_REMOVE_USER_FULFILLED:
    case types.REQUEST_ROLES:
    case types.REQUEST_ROLES_ERROR:
    case types.REQUEST_ROLES_FULFILLED:
    case types.REQUEST_ASSIGN_ROLE:
    case types.REQUEST_ASSIGN_ROLE_ERROR:
    case types.REQUEST_ASSIGN_ROLE_FULFILLED:
    case types.REQUEST_UNASSIGN_ROLE:
    case types.REQUEST_UNASSIGN_ROLE_ERROR:
    case types.REQUEST_UNASSIGN_ROLE_FULFILLED:
    case types.REQUEST_ASSIGN_FLEET:
    case types.REQUEST_ASSIGN_FLEET_ERROR:
    case types.REQUEST_ASSIGN_FLEET_FULFILLED:
    case types.REQUEST_UNASSIGN_FLEET:
    case types.REQUEST_UNASSIGN_FLEET_ERROR:
    case types.REQUEST_UNASSIGN_FLEET_FULFILLED:
      return reduceRequest(state, action)
    
    case types.ADD_ROLES:
      return addRoles(state, action)
    case types.ADD_USERS:
      return addUsers(state, action) // for on edit user
    case types.ON_CHANGE_USER:
      return onChangeUser(R.clone(state), action) 
    
    // Fetch User
    case types.REQUEST_USER:
      return resetFlags(state, { fetch: 'processing' })
    case types.REQUEST_USER_ERROR:
      return resetFlags(state, { fetch: 'error' })
    case types.REQUEST_USER_FULFILLED:
      return updateFlags(state, { fetch: 'processed' })

    // Add User
    case types.REQUEST_ADD_USER:
      return resetFlags(state, { create: 'processing' })
    case types.REQUEST_ADD_USER_ERROR:
      return addUserError(R.clone(state), action)
    case types.REQUEST_ADD_USER_FULFILLED:
      return updateFlags(state, { create: 'processed' })

    // Update User
    case types.REQUEST_USER_UPDATE:
      return resetFlags(state, {
        update: 'processing',
      })
    case types.REQUEST_USER_UPDATE_ERROR:
      return updateFlags(state, {
        update: 'error', message: action.payload
      })
    case types.REQUEST_USER_UPDATE_FULFILLED:
      return updateFlags(state, {
        update: 'processed',
      })
    default:
      return state
  }
}

export default reducer 

const onChangeUser = (state, action) => {
  state.editUserData = action.payload
  return state
}

const addRoles = (state, action) => {

  const sanitizeRoleName = (value = '') =>
    value
      .match(/[A-Z][a-z]+/g)
      .join(' ')

  const filters = action.payload
    .map(node => ({
      label: sanitizeRoleName(node.name),
      value: node.id,
    }))

  return {
    ...state,
    filters: {
      ...state.filters,
      roles: filters,
    },
    roles: action.payload,
  }
}

const addUsers = (state, action) => {
  return {
    ...state,
    editUserData: action.payload,
  }
}

const addUserError = (state, action) => {
  state.errorMessage = action.payload
  return updateFlags(state, {
    create: 'error',
  })
}
