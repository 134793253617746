import React, { useState } from 'react'
import { connect } from 'react-redux'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Button, Drawer, List, ListItem, Tooltip, Typography } from '@material-ui/core'
import { useIsWhiteListed } from 'utils'
import { push } from 'connected-react-router'
import { useDispatch } from 'react-redux'
import { useSelectedFleets, useSettingsFleetValues, useSelectedPremiumFleets } from 'features/settings'
import { useLatestFbtSubmission, useLatestFbtSummary } from 'features/fbt/navigation-config'
import Brand from '../brand'
import FleetSelector from './fleet-selector'
import { FleetIcon } from 'frame/components'

import { hasRoles } from 'acl'
import { ADMIN_PATH } from 'features/admin'
import { FEATURE_TOGGLE_PATH } from 'features/feature-toggle'

import { constants as fromAccount } from 'features/account'
import { constants as fromAuth } from 'features/auth'

import { constants as fromSummaryDashboard } from 'features/homepage'
import { constants as fromDashboard } from 'features/dashboard'
import { constants as fromDrivers } from 'features/drivers'
import { constants as fromFleet } from 'features/fleet'

// reports
import { constants as fromReportEndOfLease } from 'features/report-end-of-lease'
import { constants as fromReportIncidentManagement } from 'features/report-incident-management'
import { constants as fromReportExtensionRentalsResults } from 'features/report-extension-rentals'
import { constants as fromReportKmTracking } from 'features/report-km-tracking'
import { constants as fromReportUpcomingMaturities } from 'features/report-upcoming-maturities'
import { constants as fromReportVehicleServicing } from 'features/report-vehicle-servicing'
import { constants as fromReportVehiclesOnOrder } from 'features/report-vehicles-on-order'
import { constants as fromReportWofRego } from 'features/report-wof-rego'
import { constants as fromDocuments } from 'features/report-documents'

// fbt
// import { constants as fromFbtSubmissions } from 'features/fbt-submissions'
// import { constants as fromFbtSummary } from 'features/fbt-summary'

// actions
import { constants as fromRequestReliefVehicles } from 'features/request-relief-vehicles'
import { constants as fromRequestVehicleRelocations } from 'features/request-vehicle-relocations'
import { constants as fromRequestContractVariations } from 'features/request-contract-variations'
import { constants as fromRequestVehicleSummary } from 'features/request-vehicle-summary'

import { useEnabledFeatureToggleList } from 'features/settings'


const useStyles = makeStyles(theme => ({
  drawer: {
    background: 'linear-gradient(0.25turn, #022946, #01B6B1)', // theme.palette.dark,
  },
  root: {
    width: '100%',
    height: '100%',
    // background: theme.palette.dark,
  },
  brand: { padding: 20, width: '100%' },
  listIndent: { margin: 0, padding: 0 },
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    minWidth: 300,
  },
  mainLink: {
    color: theme.palette.light,
    padding: '10px 16px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    borderRadius: 0,
  },
  subLink: {
    color: theme.palette.light,
    padding: '10px 26px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    borderRadius: 0,
  },
  linkSelected: {
    color: theme.palette.white,
    '& > span': {
      color: theme.palette.white,
      borderColor: theme.palette.white,
      borderBottom: '2px solid',
      width: 'auto'
    },
  },
  fleets: {
    display: 'flex',
    flexDirection: 'row',
    width: '320px',
    flexWrap: 'wrap',
    '& > *': {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  link: {
    color: theme.palette.light, //theme.palette.text.link,
    textDecoration: 'none',
    cursor: 'pointer',
    marginRight: theme.spacing(2),
  },
}))

const MobileNavigation = ({ open, onClose, userRoles }) => {
    
  const selectedFbtFleets = useSelectedPremiumFleets()
  const featureToggleList = useEnabledFeatureToggleList()
  const fbtFeatureToggle = featureToggleList.includes('FBT')
  const documentsReportFeatureToggle = featureToggleList.includes('DocumentsViewing')
  const noFbtFleetSelected = selectedFbtFleets.length === 0

  const classes = useStyles()

  const isWhiteListedUser = useIsWhiteListed()
  const dispatch = useDispatch()

  const latestFbtSubmission = useLatestFbtSubmission()
  const latestFbtSummary = useLatestFbtSummary()

  const showFeatureToggle = isWhiteListedUser

  const [selectFleet, setSelectFleet] = useState(false)

  const onToggleFleetSelector = () =>
    setSelectFleet(!selectFleet)

  const selectedFleets = useSelectedFleets()
  const options = useSettingsFleetValues()
  const hasFleets = !!options.length

  const MAX_FLEET = 16
  const maxSelectedFleets = selectedFleets.length > MAX_FLEET
  const croppedSelectedfleets = maxSelectedFleets ? selectedFleets.slice(0, MAX_FLEET) : selectedFleets

  const [seeMore, setSeeMore] = useState(false)

  const contains = (value) => window.location.href.includes(value)
  const isValidFbtLink = (path) => path.includes('month') || path.includes('quarter')

  const onLinkTo = (path) => () => {
    onClose()
    dispatch(push(path))
  }

  const displayFleets = (fleets) => fleets.map((node, index) =>
    <FleetIcon key={`fleet-icon-${index}`} fleetName={node.label} />
  )

  const drawerMenu = [
    {
      label: 'Home',
      path: fromSummaryDashboard.PATH_ALL,
      style: classes.mainLink,
      visible: true,
    },
    {
      label: 'Dashboard',
      path: fromDashboard.PATH_ALL,
      style: classes.mainLink,
      visible: true,
    },
    {
      label: 'Reports',
      path: fromReportUpcomingMaturities.PATH_ALL,
      style: classes.mainLink,
      visible: true,
      subMenu: [        
        {
          label: 'Maturities',
          path: fromReportUpcomingMaturities.PATH_ALL,
          style: classes.subLink,
          visible: true,
        },
        {
          label: 'Vehicles on Order',
          path: fromReportVehiclesOnOrder.PATH_ALL,
          style: classes.subLink,
          visible: true,
        },
        {
          label: 'Vehicle Servicing',
          path: fromReportVehicleServicing.PATH_ALL,
          style: classes.subLink,
          visible: true,
        },
        {
          label: 'KM Tracking',
          path: fromReportKmTracking.PATH_ALL,
          style: classes.subLink,
          visible: true,
        },
        {
          label: 'WoF Registration',
          path: fromReportWofRego.PATH_ALL,
          style: classes.subLink,
          visible: true,
        },
        {
          label: 'Extension Rentals',
          path: fromReportExtensionRentalsResults.PATH_ALL,
          style: classes.subLink,
          visible: true,
        },
        {
          label: 'End of Lease',
          path: fromReportEndOfLease.PATH_ALL,
          style: classes.subLink,
          visible: true,
        },
        {
          label: 'Incident Management',
          path: fromReportIncidentManagement.PATH_ALL,
          style: classes.subLink,
          visible: true,
        },
        {
          label: 'Documents',
          path: fromDocuments.PATH_ALL,
          style: classes.subLink,
          visible: documentsReportFeatureToggle,
        },
      ]
    },
    {
      label: 'Fleet',
      path: fromFleet.PATH_ALL,
      style: classes.mainLink,
      visible: true,
    },
    {
      label: 'Drivers',
      path: fromDrivers.PATH_ALL,
      style: classes.mainLink,
      visible: true,
    },
    {
      label: 'FBT',
      path: latestFbtSubmission.link,
      style: classes.mainLink,
      visible: fbtFeatureToggle && noFbtFleetSelected, // show FBT link only when no fbt fleet is selected
    },
    {
      label: 'FBT Submissions',
      path: latestFbtSubmission.link,
      style: classes.mainLink,
      visible: fbtFeatureToggle && isValidFbtLink(latestFbtSubmission.link) && !noFbtFleetSelected,
    },
    {
      label: 'FBT Summary',
      path: latestFbtSummary.link,
      style: classes.mainLink,
      visible: fbtFeatureToggle && isValidFbtLink(latestFbtSummary.link) && !noFbtFleetSelected,
    },
    {
      label: 'Relief Vehicle Booking Summary',
      path: fromRequestReliefVehicles.PATH_ALL,
      style: classes.mainLink,
      visible: true,
    },
    {
      label: 'Vehicle Relocations Summary',
      path: fromRequestVehicleRelocations.PATH_ALL,
      style: classes.mainLink,
      visible: true,
    },
    {
      label: 'Contract Variations Summary',
      path: fromRequestContractVariations.PATH_ALL,
      style: classes.mainLink,
      visible: true,
    },
    {
      label: 'Vehicle Summary',
      path: fromRequestVehicleSummary.PATH_ALL,
      style: classes.mainLink,
      visible: true,
    },
    {
      label: 'Account',
      path: fromAccount.PATH_ALL,
      style: classes.mainLink,
      visible: true,
    },
    {
      label: 'Admin',
      path: ADMIN_PATH,
      style: classes.mainLink,
      visible: hasRoles(['admin', 'superadmin', 'fleetcontroller'], userRoles),
    },
    {
      label: 'Feature Toggle',
      path: FEATURE_TOGGLE_PATH,
      style: classes.mainLink,
      visible: showFeatureToggle,
    },
    {
      label: 'Logout',
      path: fromAuth.LOGOUT_PATH,
      style: classes.mainLink,
      visible: true,
    },
  ].filter(x => x.visible)

  return (
    <Drawer
      classes={{ paper: classes.drawer }}
      anchor="left"
      open={open}
      variant="temporary"
      onClose={onClose}>
      <div className={classes.root}>
        <div className={classes.brand}>
          <Brand variant="light" />
        </div>

        <List>
          <ListItem className={classes.fleets}>
            {hasFleets && displayFleets(seeMore ? selectedFleets : croppedSelectedfleets)}
          </ListItem>

          <ListItem>
            {maxSelectedFleets && (
              <Typography variant="body2">
                <Tooltip title={!seeMore ? `${selectedFleets.length - MAX_FLEET} more` : 'hide'}>
                  <span className={classes.link} onClick={() => setSeeMore(!seeMore)}>
                    {seeMore ? 'Hide' : 'See More'}
                  </span>
                </Tooltip>
              </Typography>
            )}
            <span className={classes.link} onClick={() => onToggleFleetSelector()}>
              Change
            </span>
          </ListItem>
            
          {drawerMenu.map(({ label, path, style, visible, subMenu }, index) => visible && label && path && !subMenu ? (
              <ListItem className={classes.item} disableGutters key={`main-menu-item-${index}`}>
                <Button
                  data-cy={`${label.replace(' ', '_').toLowerCase()}`}
                  className={clsx(style, contains(path) ? classes.linkSelected : '')}
                  onClick={onLinkTo(path)}>
                  {label}
                </Button>
              </ListItem>

            ) : (
              <div key={`sub-menu-${index}`}>
                <ListItem className={classes.item} disableGutters>
                  <Button
                    data-cy={`${label.replace(' ', '_').toLowerCase()}`}
                    className={style}
                    onClick={onLinkTo(path)}>
                    {label}
                  </Button>
                </ListItem>

                {/* render sub menu */}
                <ListItem className={classes.item} disableGutters>
                  <List className={classes.listIndent}>

                    {subMenu.map(({ label, path, style, visible }, index) => visible && label && path && (
                      <ListItem className={classes.item} disableGutters key={`sub-menu-item-${index}`}>
                        <Button
                          data-cy={`${label.replace(' ', '_').toLowerCase()}`}
                          className={clsx(style, contains(path) ? classes.linkSelected : '')}
                          onClick={onLinkTo(path)}>
                          {label}
                        </Button>
                      </ListItem>
                    ))}

                  </List>
                </ListItem>
              </div>
            )
          )}
        </List>
      </div>

      {selectFleet && (
        <FleetSelector onClose={onToggleFleetSelector} />
      )}
    </Drawer>
  )
}

MobileNavigation.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
}

const mapStateToProps = ({ settings }) => ({
  userRoles: settings.roles,
})

export default connect(mapStateToProps)(MobileNavigation)