import { combineEpics, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { mergeMap, catchError, switchMap } from 'rxjs/operators'
import { makeActionRequestEffect } from 'effects'
import { getAppConfig } from 'app-config'
import { ajax } from 'rxjs/ajax'
import join from 'url-join'
import * as fromModuleTypes from './actions/types'


export const createEffects = (moduleActions) => {

  const appConfig = getAppConfig()

  const addFbtSubmissionEffect = makeActionRequestEffect({
    type: fromModuleTypes.ADD_FBT_SUBMISSIONS,
    verb: 'post',
    url: join(appConfig.app.api, 'fbt/fbtsubmission'),
    onSuccess: moduleActions.addFbtSubmissionFulfilled,
    onError: moduleActions.addFbtSubmissionError,
  })

  const editSaveFbtSubmissionEffect = makeActionRequestEffect({
    type: fromModuleTypes.EDIT_FBT_SUBMISSION_SAVE,
    verb: 'put',
    url: join(appConfig.app.api, 'fbt/fbtsubmission'),
    onSuccess: moduleActions.editSaveFulfilled,
    onError: moduleActions.editSaveError,
  })

  const lookupEffect = makeActionRequestEffect({
    type: fromModuleTypes.LOOKUP,
    url: join(appConfig.app.api, `lookup`),
    onSuccess: moduleActions.lookupFulfilled,
    onError: moduleActions.lookupError,
  })
  
  const fetchEffect = makeActionRequestEffect({
    type: fromModuleTypes.FETCH,
    url: join(appConfig.app.api, 'fbt/fbtsubmission'),
    onSuccess: moduleActions.fetchFulfilled,
    onError: moduleActions.fetchError,
  })

  const registerInterestEffect = makeActionRequestEffect({
    type: fromModuleTypes.REGISTER_INTEREST,
    url: join(appConfig.app.api, 'fbt/registrationOfInterest'),
    verb: 'post',
    onSuccess: moduleActions.registerInterestFulfilled,
    onError: moduleActions.registerInterestError,
  })

  const refetchEffect = (action$, state$) =>
    action$.pipe(
      ofType(fromModuleTypes.ADD_FBT_SUBMISSIONS_FULFILLED),
      mergeMap(() =>
        of(moduleActions.refetch())
      )
    )
  
  const fbtWebFormVerifyTokenEffect = (action$, state$) =>
    action$.pipe(
      ofType(fromModuleTypes.FBT_WEBFORM_VERIFY_TOKEN_VALUE),
      switchMap(
        ({ payload }) => ajax.get(join(appConfig.app.api, `fbt/webformverify?token=${encodeURIComponent(payload)}`))
        .pipe(
          mergeMap(({ response }) => of(
            response?.success === false || response?.data === null || response === null
              ? moduleActions.fbtWebFormVerifyTokenError(response?.apiResponse?.message || response?.message || response)
              : moduleActions.fbtWebFormVerifyTokenFulfilled(response?.message || response)
          )),
          catchError(error => of(
            moduleActions.fbtWebFormVerifyTokenError(error?.apiResponse?.message || error?.message || error)
          ))
        )
      )
    )  


  return combineEpics(
    addFbtSubmissionEffect,
    editSaveFbtSubmissionEffect,
    lookupEffect,
    fetchEffect,
    registerInterestEffect,
    refetchEffect,
    fbtWebFormVerifyTokenEffect,
  )
}