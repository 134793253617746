import { combineEpics } from 'redux-observable'

import { effects as fbtSubmissionsEffects } from 'features/fbt-submissions'
import { effects as fbtSummaryEffects } from 'features/fbt-summary'

const fbtEffects = combineEpics(
  fbtSubmissionsEffects,
  fbtSummaryEffects,
)

export default fbtEffects
