import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { makeStyles } from '@material-ui/styles'
import { TableCell, TableRow } from '@material-ui/core'
import { getStatusColor } from 'listable'
import { createRowSelector } from 'utils'
import { Label, FleetIcon } from 'frame/components'
import Options from './row-options'
import { types } from '../../module'
import { useEnabledFeatureToggleList } from 'features/settings';


const useStyles = makeStyles(theme => ({
  root: {
    cursor: 'pointer',
    verticalAlign: 'text-top',
  },
  centerCell: {
    display: 'flex',
    justifyContent: 'center',
  },
  label: theme.typography.bodySmall,
  spacer: {
    margin: 0,
    padding: 0,
  },
}))

const Row = ({ item, columns, selected, onSelect, onView }) => {

  const featureToggleList = useEnabledFeatureToggleList()
  const tfp1838FeatureToggle = featureToggleList.includes('TFP1838')

  const classes = useStyles()

  const onSafeRowClick = createRowSelector(onView)

  return (
    <TableRow
      hover
      className={classes.root}
      selected={selected.indexOf(item.id) !== -1}
      onClick={onSafeRowClick}>
      <TableCell className={classes.spacer} />
      {columns.accountName && (
        <TableCell className={classes.label}>
          <FleetIcon fleetName={item.accountName} />
        </TableCell>
      )}
      {columns.referenceId && (
        <TableCell className={classes.label}>
          {item.referenceId || '-'}
        </TableCell>
      )}
      {columns.vehicleRegistration && (
        <TableCell className={classes.label}>
          {item.vehicleRegistration || '-'}
        </TableCell>
      )}
      {columns.driverName && (
        <TableCell className={classes.label}>
          {item.driverName || '-'}
        </TableCell>
      )}
      {columns.driverMobile && (
        <TableCell className={classes.label}>
          {item.driverMobile || '-'}
        </TableCell>
        // <TableCell className={classes.label}>
        //   {item.driverMobile ? (
        //     <a className="ignore" href={`tel:${item.driverMobile}`}>
        //       {item.driverMobile}
        //     </a>
        //   ) : (
        //     '-'
        //   )}
        // </TableCell>
      )}

      {tfp1838FeatureToggle && columns.contactEmail && (
        <TableCell className={classes.label}>
          {item.contactEmail || '-'}
        </TableCell>
      )}

      {columns.costCentre && (
        <TableCell className={classes.label}>
          {item.costCentre || '-'}
        </TableCell>
      )}
      {columns.pickup && (
        <TableCell className={classes.label}>
          {item.pickup || '-'}
        </TableCell>
      )}
      {columns.destination && (
        <TableCell className={classes.label}>
          {item.destination || '-'}
        </TableCell>
      )}
      {columns.effectiveDate && (
        <TableCell className={classes.label}>
          {item.effectiveDate ? moment(item.effectiveDate).format('DD/MM/YY') : '-'}
        </TableCell>
      )}
      {columns.status && (
        <TableCell className={classes.label}>
          {item.status ? (
            <Label
              color={getStatusColor(item.status)}
              variant="outlined">
              {item.status}
            </Label>
          ) : '-'}
        </TableCell>
      )}
      <TableCell align="right">
        <Options
          className="ignore"
          id={item.id}
          onView={onView} />
      </TableCell>
    </TableRow>
  )
}

Row.propTypes = {
  item: types.ModelType.isRequired,
  columns: types.ColumnsType.isRequired,
  selected: types.IDListType.isRequired,
  onSelect: PropTypes.func,
  onView: PropTypes.func.isRequired,
}

export default Row
