import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { ReleaseRedirect } from 'features/release'
import NavBar from '../components/nav-bar'
import NavBarAccordion from '../components/nav-bar-accordion'
import TopBar from '../components/top-bar'
import { NavigationType } from '../types'
import clsx from 'clsx'


const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    height: '100%',
    width: '100%',
    overflowX: 'hidden', // hide horizontal scrollbar
  },
  sideNav: {
    display: 'grid',
    gridGap: 0,
    width: '100%',
    minHeight: '100%',
  },
  sideNavWithAccordion: {
    gridTemplateColumns: '0 auto',
    [theme.breakpoints.up(959)]: { gridTemplateColumns: '256px auto' },
  },
  sideNavWithIcons: {
    gridTemplateColumns: '80px auto',
    [theme.breakpoints.up('lg')]: { gridTemplateColumns: '256px auto' },
  },
  sidepanel: {
    borderRight: '1px solid rgba(63,63,68,0.15)',
    boxShadow: 'none',
    zIndex: 3,
    minHeight: 'calc(100vh - 74px)',
  },
  contentpanelWithIcons: {
    flex: 1,
    width: '100vw',
    [theme.breakpoints.up('sm')]: { width: 'calc(100vw - 80px)' },
    [theme.breakpoints.up('lg')]: { width: 'calc(100vw - 256px)' },
  },
  contentpanelWithAccordion: {
    flex: 1,
    width: '100vw',
    [theme.breakpoints.up(959)]: { width: 'calc(100vw - 256px)' },
    [theme.breakpoints.up('lg')]: { width: 'calc(100vw - 256px)' },
  },
  content: { flex: 1 },
  footer: {},
  backgroundImageLayout: {
    minHeight: '100vh',
    position: 'relative',
    '&::before': {
      content: '" "',
      position: 'absolute',
      zIndex: -99,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundImage: props => props.backgroundImage ? props.backgroundImage : '',
      backgroundSize: '100vw 100vh',
      backgroundPosition: 'left top',
      backgroundRepeat: 'no-repeat',
      backgroundAttachment: 'fixed',
    },
  },
}))

const Frame = ({ children, navigation, accordionNavigation, backgroundImage }) => {

  const classes = useStyles({ backgroundImage })
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false)

  const onNavBarMobileOpen = () =>
    setOpenNavBarMobile(true)

  const onNavBarMobileClose = () =>
    setOpenNavBarMobile(false)

  return (
    <ReleaseRedirect>
      <div className={clsx(classes.root, backgroundImage ? classes.backgroundImageLayout : '')}>
        <TopBar onOpenNavBarMobile={onNavBarMobileOpen} />
        {accordionNavigation ? (
          <div className={clsx(classes.sideNav, classes.sideNavWithAccordion)}>
            <NavBarAccordion
              openMostRecentOnLoad
              title="FBT"
              className={classes.sidepanel}
              navigation={accordionNavigation}
              onMobileClose={onNavBarMobileClose}
              openMobile={openNavBarMobile} />
            <main className={classes.contentpanelWithAccordion}>
              {children}
            </main>
          </div>
        ) : navigation ? (
          <div className={clsx(classes.sideNav, classes.sideNavWithIcons)}>
            <NavBar
              className={classes.sidepanel}
              navigation={navigation}
              onMobileClose={onNavBarMobileClose}
              openMobile={openNavBarMobile} />
            <main className={classes.contentpanelWithIcons}>
              {children}
            </main>
          </div>
        ) : (
          <main className={classes.content}>
            {children}
          </main>
        )}
        <footer className={classes.footer} />
      </div>
    </ReleaseRedirect>
  )
}

Frame.propTypes = {
  backgroundImage: PropTypes.string,
  children: PropTypes.node.isRequired,
  navigation: NavigationType,
}

export default Frame
