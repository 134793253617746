import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Card, CardContent, Grid, Typography, colors } from '@material-ui/core'
import { VariationRequestsTable, RowValueText, PageLoader, Label } from 'frame/components'
import { trimObjectStrings } from 'utils'
import moment from 'moment'
import clsx from 'clsx'
import { fetch, update, deleteVariation, reset, updateVariations } from '../../actions'
import { useSelectedFleetIds } from 'features/settings'


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 1.2, 3, 1.2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
      minHeight: 'min(80vh, 600px)',
    },
  },
  heading: {
    fontWeight: 'bold',
    color: '#7099DB',
  },
  padding: {
    margin: theme.spacing(3, 0, 1, 0),
  },
  content: {
    marginTop: theme.spacing(3),
  },
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
  },
  expired: {
    position: 'relative',
    top: -5,
    margin: theme.spacing(0, 0, 0, 1),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(1, 0, 0, 1),
    },
  },
}))

const Variations = ({ data, flags, errorMessage, userRoles, onFetch, onUpdateVariations, onDeleteVariation, onReset }) => {

  const classes = useStyles()
  const fleetIds = useSelectedFleetIds()

  const [loading, setLoading] = useState(false)
  const [updatingStatus, setUpdatingStatus] = useState(false)

  useEffect(() => {
    setLoading(true)
    onReset()
    onFetch(data.agreementNo, fleetIds)
    console.log('Variations tab onload data: ', { variations: data.variations, data }) // for testing
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if(flags.deleteVariation === 'processed') {
      onFetch(data.agreementNo, fleetIds)
    }// eslint-disable-next-line
  }, [flags.deleteVariation])
  
  useEffect(() => {
    if(loading && flags.fetch === 'processed') {
      setLoading(false)
    }// eslint-disable-next-line
  }, [flags.fetch])

  const maturityDateExpired = !!data.maturityDate && moment(data.maturityDate) < moment()
  
  return (
    <Card>
      <CardContent className={classes.root}>
        <Typography  gutterBottom variant="h4">
          Variations
        </Typography>

        <Typography  gutterBottom variant="h5" className={classes.heading}>
          Existing Contract
        </Typography>

        <div className={classes.content}>
          <Grid container spacing={3}>
            {[
              { label: 'Agreement Type', value: data.agreementType },
              { label: 'Term', value: data.term },
              { label: "Contract KM's", value: data.contractKm ? parseInt(data.contractKm, 10).toLocaleString('en-NZ') : '-' },
              { label: 'Maturity Date', value: data.maturityDate ? moment(data.maturityDate).format('Do MMMM YYYY') : '-' },
              { label: 'Tyres', value: data.noOfTyres },
            ]
            .map(({ label, value }, index) => (
              <Grid item xs={12} key={`existing-contract-details-${index}`}>
                <RowValueText label={label}>
                  <Typography variant="body1">
                    {value || '-'}
                  
                    {label === 'Maturity Date' && maturityDateExpired && (
                      <Label color={colors.red[600]} variant="outlined" className={classes.expired}>
                        Expired
                      </Label>
                    )}
                  </Typography>
                </RowValueText>
              </Grid>
            ))}

          </Grid>
        </div>

        <Typography  gutterBottom variant="h5" className={clsx(classes.heading, classes.padding)}>
          Variation Requests (Request up to 3 variation quotes)
        </Typography>
        {loading || flags.update === 'processing' || flags.deleteVariation === 'processing' ? (
          <PageLoader minHeight={180} />
        ) : (
          <VariationRequestsTable
            readOnly={maturityDateExpired}
            data={data}
            flags={{ ...flags, updatingStatus }}
            errorMessage={errorMessage} 
            userRoles={userRoles}
            onUpdate={onUpdateVariations}
            onDeleteVariation={onDeleteVariation}
            setUpdatingStatus={setUpdatingStatus}
          />
        )}
        
      </CardContent>
    </Card>
  )
}

Variations.propTypes = {
  data: PropTypes.object.isRequired,
  flags: PropTypes.object.isRequired,
  errorMessage: PropTypes.string.isRequired,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  // func
  onFetch: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onUpdateVariations: PropTypes.func.isRequired,
  onDeleteVariation: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
}

const mapStateToProps = ({ fleet, settings }, ownProps) => ({
  data: trimObjectStrings(fleet.data?.[ownProps.match.params.id]), // Because the API does not clean data for us
  flags: fleet.flags,
  errorMessage: fleet.errorMessage || '',
  userRoles: settings.roles,
})

const mapDispatchToProps = (dispatch) => ({
  onFetch: (agreementNo, fleetId) => {
    dispatch(fetch(null, {
      resourceId: agreementNo,
      queryParams: { fleetId }
    }))
  },
  onUpdate: (payload) => {
    dispatch(update(payload))
  },
  onUpdateVariations: (payload) => {
    dispatch(updateVariations(payload))
  },
  onDeleteVariation: (id, fleetId) => {
    dispatch(deleteVariation(null, {
      resourceId: id,
      queryParams: { fleetId }
    }))
  },
  onReset: () => {
    dispatch(reset())
  },
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Variations))