import * as types from './actions/types'

const initialState = {
  myFleetSummary: {
    serviceOverdue: 0,
    wofOverdue: 0,
    kmTrackingOver: 0,
    registrationOverdue: 0,
    fbtOutstanding: 0
  },
  mynotifications: [],
  statusUpdates: [],
  industryNews: [],
  downloadGuides: [],
  contactDetails: null,
  footerTicker: [],
  flags: null,
}

const updateFlags = (state, flags) => ({
  ...state,
  flags: { ...state.flags, ...flags }
})

const onError = (state, error, flags) => {
  state.errorMessage = typeof error === 'string' ? error : ''
  return updateFlags(state, flags)
}

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.RESET:
      return initialState

    // Check Have App Users
    case types.CHECK_HAVE_APP_USERS:
      return updateFlags(state, { checkHaveAppUsers: 'processing' })
    case types.CHECK_HAVE_APP_USERS_ERROR:
      return onError(state, payload, { checkHaveAppUsers: 'error' })
    case types.CHECK_HAVE_APP_USERS_FULFILLED:
      return updateFlags({ ...state, haveAppUsers: payload }, { checkHaveAppUsers: 'processed' })

    // My Fleet Summary - Service Overdue
    case types.FETCH_SUMMARY_SERVICE_OVERDUE:
      return updateFlags(state, { fetchMyFleetSummaryServiceOverdue: 'processing' })
    case types.FETCH_SUMMARY_SERVICE_OVERDUE_ERROR:
      return onError(state, payload, { fetchMyFleetSummaryServiceOverdue: 'error' })
    case types.FETCH_SUMMARY_SERVICE_OVERDUE_FULFILLED:
      return updateFlags(
        { ...state, myFleetSummary: { ...state.myFleetSummary, serviceOverdue: payload?.count || 0 } },
        { fetchMyFleetSummaryServiceOverdue: 'processed' }
      )
      
    // My Fleet Summary - Wof Overdue
    case types.FETCH_SUMMARY_WOF_OVERDUE:
      return updateFlags(state, { fetchMyFleetSummaryWofOverdue: 'processing' })
    case types.FETCH_SUMMARY_WOF_OVERDUE_ERROR:
      return onError(state, payload, { fetchMyFleetSummaryWofOverdue: 'error' })
    case types.FETCH_SUMMARY_WOF_OVERDUE_FULFILLED:
      return updateFlags(
        { ...state, myFleetSummary: { ...state.myFleetSummary, wofOverdue: payload?.count || 0 } },
        { fetchMyFleetSummaryWofOverdue: 'processed' }
      )
    
    // My Fleet Summary - Km Tracking
    case types.FETCH_SUMMARY_KM_TRACKING:
      return updateFlags(state, { fetchMyFleetSummaryKmTracking: 'processing' })
    case types.FETCH_SUMMARY_KM_TRACKING_ERROR:
      return onError(state, payload, { fetchMyFleetSummaryKmTracking: 'error' })
    case types.FETCH_SUMMARY_KM_TRACKING_FULFILLED:
      return updateFlags(
        { ...state, myFleetSummary: { ...state.myFleetSummary, kmTrackingOver: payload?.count || 0 } },
        { fetchMyFleetSummaryKmTracking: 'processed' }
      )
    
    // My Fleet Summary - Rego Overdue
    case types.FETCH_SUMMARY_REGO_OVERDUE:
      return updateFlags(state, { fetchMyFleetSummaryRegoOverdue: 'processing' })
    case types.FETCH_SUMMARY_REGO_OVERDUE_ERROR:
      return onError(state, payload, { fetchMyFleetSummaryRegoOverdue: 'error' })
    case types.FETCH_SUMMARY_REGO_OVERDUE_FULFILLED:
      return updateFlags(
        { ...state, myFleetSummary: { ...state.myFleetSummary, registrationOverdue: payload?.count || 0 } },
        { fetchMyFleetSummaryRegoOverdue: 'processed' }
      )

    // My Fleet Summary - FBT Outstanding
    case types.FETCH_SUMMARY_FBT_OUTSTANDING:
      return updateFlags(state, { fetchMyFleetSummaryFBTOutstanding: 'processing' })
    case types.FETCH_SUMMARY_FBT_OUTSTANDING_ERROR:
      return onError(state, payload, { fetchMyFleetSummaryFBTOutstanding: 'error' })
    case types.FETCH_SUMMARY_FBT_OUTSTANDING_FULFILLED:
      return updateFlags(
        { ...state, myFleetSummary: { ...state.myFleetSummary, fbtOutstanding: payload?.count || 0 } },
        { fetchMyFleetSummaryFBTOutstanding: 'processed' }
      )

    // My Notifications
    case types.FETCH_NOTIFICATIONS:
      return updateFlags(state, { fetchNotifications: 'processing' })
    case types.FETCH_NOTIFICATIONS_ERROR:
      return onError(state, payload, { fetchNotifications: 'error' })
    case types.FETCH_NOTIFICATIONS_FULFILLED:
      return updateFlags({ ...state, myNotifications: payload }, { fetchNotifications: 'processed' })
    
    // Status Updates
    case types.FETCH_STATUS_UPDATES:
      return updateFlags(state, { fetchStatusUpdates: 'processing' })
    case types.FETCH_STATUS_UPDATES_ERROR:
      return onError(state, payload, { fetchStatusUpdates: 'error' })
    case types.FETCH_STATUS_UPDATES_FULFILLED:
      return updateFlags({ ...state, statusUpdates: payload }, { fetchStatusUpdates: 'processed' })
    
    // Industry News
    case types.FETCH_INDUSTRY_NEWS:
      return updateFlags(state, { fetchIndustryNews: 'processing' })
    case types.FETCH_INDUSTRY_NEWS_ERROR:
      return onError(state, payload, { fetchIndustryNews: 'error' })
    case types.FETCH_INDUSTRY_NEWS_FULFILLED:
      return updateFlags({ ...state, industryNews: payload }, { fetchIndustryNews: 'processed' })

    // Download Guides
    case types.FETCH_DOWNLOAD_GUIDES:
      return updateFlags(state, { fetchDownloadGuides: 'processing' })
    case types.FETCH_DOWNLOAD_GUIDES_ERROR:
      return onError(state, payload, { fetchDownloadGuides: 'error' })
    case types.FETCH_DOWNLOAD_GUIDES_FULFILLED:
      return updateFlags({ ...state, downloadGuides: payload }, { fetchDownloadGuides: 'processed' })

    // Contact Details
    case types.FETCH_CONTACT_DETAILS:
      return updateFlags(state, { fetchContactDetails: 'processing' })
    case types.FETCH_CONTACT_DETAILS_ERROR:
      return onError(state, payload, { fetchContactDetails: 'error' })
    case types.FETCH_CONTACT_DETAILS_FULFILLED:
      return updateFlags({ ...state, contactDetails: payload }, { fetchContactDetails: 'processed' })

    // Footer Ticker
    case types.FETCH_FOOTER_TICKER:
      return updateFlags(state, { fetchFooterTicker: 'processing' })
    case types.FETCH_FOOTER_TICKER_ERROR:
      return onError(state, payload, { fetchFooterTicker: 'error' })
    case types.FETCH_FOOTER_TICKER_FULFILLED:
      return updateFlags({ ...state, footerTicker: payload }, { fetchFooterTicker: 'processed' })      
    
    default:
      return state
  }
}

export default reducer 