import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Listable } from 'listable'
import Row from './row'
import Filters from '../filters'

import {
  actions,
  config
} from '../../module'


const List = ({ account, model }) => {

  const renderItem = useCallback((item, index, itemProps) => {
    return (
      <Row
        key={index}
        account={account}
        item={item}
        {...itemProps}/>
    )
  }, [account])

  // Prevents re-rendering if ids or data updated - no longer needed
  const { ids, filters, ...rest } = model

  return (
    <Listable
      actions={actions}
      config={config}
      filters={Filters}
      model={rest}
      renderItem={renderItem}
    />
  )
}

List.propTypes = {
  account: PropTypes.string,
  model: PropTypes.object.isRequired,
}

const mapStateToProps = ({ reportEndOfLease, settings }) => ({
  account: settings?.account,
  model: reportEndOfLease,
})

const mapDispatchToProps = {}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(List)
