import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { Typography } from '@material-ui/core'
import MoreIcon from '@material-ui/icons/ArrowForwardIos'


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    color: theme.palette.text.primary,
    textDecoration: 'none',
    animation: '0.3s all ease-out',
    '&:hover': {
      color: theme.palette.brand,
      animation: '0.3s all ease-out',
    }
  },
  icon: {
    ...theme.typography.body2,
    margin: theme.spacing(0, 0.6),
    height: 20,
  },
}))

const DashboardGraphTitle = ({ className, title, subtitle, to = '/' }) => {

  const classes = useStyles()

  return (
    <Link
      className={clsx(classes.root, className)}
      to={to}>
      {title}
      <MoreIcon className={classes.icon} />
      {subtitle && (
        <Typography variant="body2">{subtitle}</Typography>
      )}
    </Link>
  )
}

DashboardGraphTitle.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  to: PropTypes.string,
}

export default DashboardGraphTitle
