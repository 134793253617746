import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { fetchIndustryNews } from '../actions'
import { Grid, Typography, Card, CardContent, Divider } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import clip from 'text-clipper'

const useStyles = makeStyles(theme => ({
  root: {
    height: '457px',
    maxHeight: '457px',
    [theme.breakpoints.down(1500)]: {
      height: '100%',
      maxHeight: '100%',
    }
  },
  title: {
    color: '#263238',
    fontWeight: 'bold',
    fontSize: '18px',
    paddingBottom: theme.spacing(1.5),
  },
  content: { paddingTop: theme.spacing(2) },
  article: {
    cursor: 'pointer',
    padding: theme.spacing(0, 1),
  },
  articleImageContainer: {
    textAlign: 'left',
    [theme.breakpoints.down(1500)]: { textAlign: 'center' },
  },
  articleContent: {
    paddingLeft: 0,
    [theme.breakpoints.down(1500)]: { paddingLeft: theme.spacing(1) },
    [theme.breakpoints.down('xs')]: { paddingLeft: 0 }
  },
  articleTitle: {
    fontWeight: 'bold',
    paddingBottom: 10,
    '&:hover': { textDecoration: 'underline' },
  },
  articleImage: {
    width: '100px',
    height: 'auto',
    backgroundPositionX: 'top left',
    [theme.breakpoints.down(1550)]: {
      width: '80px',
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(1),
      width: '100%',
    },
  },
  articleImageFullSize: {
    width: 'auto',
    height: '400px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  anchor: { textDecoration: 'none' },
  link: {
    color: '#7099DB',
    textDecoration: 'none',
    fontSize: '12px',
    lineHeight: '14px',
    cursor: 'pointer',
    '&:hover': { textDecoration: 'underline' },
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(1),
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: { display: 'block' }
  },
  button: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    minWidth: 180,
    [theme.breakpoints.down('xs')]: { minWidth: '100%' }
  },
  modalCard: {
    padding: theme.spacing(3, 1.2),
    [theme.breakpoints.up('sm')]: { padding: theme.spacing(3) },
  },
  spacing: { margin: theme.spacing(1, 0) },
  noRecordsContainer: {
    height: '368px',
    maxHeight: '368px',
    overflowX: 'hidden',
    [theme.breakpoints.down('sm')]: { height: '100%' },
  },
  noRecords: {
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    [theme.breakpoints.down('sm')]: { marginTop: theme.spacing(4) },
  },
}))


const NewsLoadingSkeleton = () => {
  const classes = useStyles()
  return (
    <Grid container className={classes.article}>
      <Grid item xs={12} sm={2} md={3} className={classes.articleImageContainer}>
        <Skeleton animation="wave" variant="rect" height={85} className={classes.articleImage} />
      </Grid>
      <Grid item xs={12} sm={10} md={9}>
        <div className={classes.articleContent}>
          <Skeleton animation="wave" variant="rect" height={15} width={180} />
          <Skeleton animation="wave" variant="rect" height={15} className={classes.spacing} />
          <Skeleton animation="wave" variant="rect" height={15} className={classes.spacing} />
          <Skeleton animation="wave" variant="rect" height={15} width={100} />
        </div>
      </Grid>

    </Grid>
  )
}

const IndustryNews = ({ flags, industryNews, onFetchIndustryNews }) => {

  const classes = useStyles()

  const [delayLoading, setDelayLoading] = useState(true)
  const isLoading = flags?.fetchIndustryNews === 'processing'

  useEffect(() => {
    onFetchIndustryNews()
    setTimeout(() => { setDelayLoading(false) }, 1500)
  }, [onFetchIndustryNews, setDelayLoading])

  return (
    <>
      <Card className={classes.root}>
        <CardContent>
          <Typography variant="h4" className={classes.title}>
            Industry News
          </Typography>
          <Divider />

          {delayLoading || isLoading ? (
            Array.from(Array(3).keys()).map((_, index) => (
              <div className={classes.content} key={`industry-news-skeleton-${index}`}>
                <NewsLoadingSkeleton />
              </div>
            ))
          ) : industryNews.length > 0 ? (
            industryNews.map(({ title, teaser, imageUri, uri }, index) => (
              <div className={classes.content} key={`industry-news-${index}`}>
                <a className={classes.anchor} href={uri} target="_blank" rel="noopener noreferrer">
                  <Grid container className={classes.article}>
                    
                    <Grid item xs={12} sm={2} md={3} className={classes.articleImageContainer}>
                      <img className={classes.articleImage} src={imageUri} alt={`Article ${index}`} />
                    </Grid>

                    <Grid item xs={12} sm={10} md={9}>
                      <div className={classes.articleContent}>
                        <Typography variant="h5" className={classes.articleTitle}>
                          {title}
                        </Typography>
                        <Typography variant="body1" style={{ userSelect: 'none' }}>
                          {clip(teaser, 100)}
                          <br />
                          <span className={classes.link}>
                            Read More
                          </span>
                        </Typography>
                      </div>
                    </Grid>

                  </Grid>
                </a>
              </div>
          ))) : (
            <div className={classes.noRecordsContainer}>
              <Typography variant="body1" align="center" className={classes.noRecords}>
                No industry news
              </Typography>
            </div>
          )}
        </CardContent>
      </Card>
    </>
  )
}

IndustryNews.propTypes = {
  flags: PropTypes.object,
  industryNews: PropTypes.array,
  onFetchIndustryNews: PropTypes.func.isRequired,
}

const mapStateToProps = ({ homepage }) => ({
  flags: homepage?.flags,
  industryNews: homepage?.industryNews || [],
})

const mapDispatchToProps = (dispatch) => ({
  onFetchIndustryNews: () => dispatch(fetchIndustryNews()),
})

export default connect(mapStateToProps, mapDispatchToProps)(IndustryNews)
