import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { makeStyles, useTheme } from '@material-ui/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { Button, Card, CardActions, CardContent, Grid, Typography, TextField } from '@material-ui/core'
import { CustomDatePicker, RowValueText, Alert, OfflineLookupField, ScrollToTop } from 'frame/components'
import { lookup } from 'features/drivers/actions'
import moment from 'moment'


const useStyles = makeStyles(theme => ({
  content: {
    padding: theme.spacing(3, 1.2, 3, 1.2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
  },
  actions: {
    display: 'flex',
    alignItems: 'right',
    justifyContent: 'right',
    paddingBottom: theme.spacing(3),
    '& > *': {
      width: '100%',
    },
    '& > * + *': {
      marginLeft: 0,
      marginTop: 8,
    },
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      '& > *': {
        width: 'auto',
      },
      '& > * + *': {
        marginLeft: 8,
        marginTop: 0,
      },
    },
  },
  startDate: {
    margin: 0,
    padding: 0,
    '& > div': {
      margin: 0,
      padding: '6px 8px',
      borderRadius: '4px',
      border: '1px solid rgba(0, 0, 0, 0.15)',
      '&::before': {
        display: 'none',
      },
      '&::after': {
        display: 'none',
      },
    },
    '& input': {
      margin: 0,
      padding: 0,
      border: 'none',
    }
  },
  label: {
    paddingTop: theme.spacing(2),
  },
}))

const ConfirmDriverForm = ({
  values,
  disabled,
  fleetData,
  // functions
  onChange,
  onSubmit,
  onBack,
  onClose,
  onLookup,
}) => {

  const form = useRef()
  const classes = useStyles()

  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'))
  
  const [showError, setShowError] = useState(false)

  const onLookupChange = (key) => (option) =>
    onChange({ ...values, [key]: option ? option.value : null })

  const onInputChange = ({ target: { id, name, value } }) =>
    onChange({ ...values, [id || name]: value })

  const onDateChange = (value) =>
    onChange({ ...values, effectiveDate: value })
  useEffect(() => {
    onDateChange(moment());
  }, [])
  const onSubmitForm = () => onSubmit(values)
  
  const onSubmitValidation = () => {
    console.log('[confirm form]: ', values)
    if (form.current) {
      form.current.submit()
    } else {
      setShowError(true)
      setTimeout(() => setShowError(false), 3000)
    }
  }

  const displayVehicle = (data) => {
    let vehicle = `${data.year || ''} ${data.make || ''}`.trim()
    vehicle = `${vehicle} ${data.model || ''}`.trim()
    vehicle = `${vehicle} ${data.variant || ''}`.trim()
    return vehicle.replace('(Unknown)', '').trim()
  }

  return (
    <ValidatorForm autoComplete="off" noValidate ref={form} onSubmit={onSubmitForm}>
      <ScrollToTop />
      <Card>
        <CardContent className={classes.content}>
          <Grid container spacing={2}>
            
            {showError && (
              <Grid item xs={12}>
                <Alert>
                  Error occured. Assigned vehicle failed. Please try again or contact support.
                </Alert>
                <br />
              </Grid>
            )}

            {fleetData && fleetData.driver && (
              <>
                <Grid item xs={12}>
                  <Typography gutterBottom variant="h4">
                    Change Driver Details
                  </Typography>
                </Grid>
                
                <Grid item xs={12}>
                  <RowValueText label="Current Driver">
                    <Typography variant="body1">
                      {fleetData.driver}
                    </Typography>
                  </RowValueText>
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              <Typography gutterBottom variant="h4">
                Change Driver To
              </Typography>
            </Grid>
            
            <Grid item xs={12}>
              <RowValueText label="New Driver">
                <Typography variant="body1">
                  {values && values.driver ? values.driver : 'unassigned'}
                </Typography>
              </RowValueText>
            </Grid>

            <Grid item xs={12}>
              <RowValueText label="Start Date" labelClassName={classes.label}>
                <CustomDatePicker
                  disableFuture
                  minDate={values?.startDate || moment().add(-90, 'days').toDate()}
                  variant="outlined"
                  format="Do MMMM YYYY"
                  value={values.effectiveDate}
                  onChange={onDateChange}
                  textFieldClassName={classes.startDate}
                />
              </RowValueText>
            </Grid>

            <Grid item xs={12}>
              <Typography gutterBottom variant="h4">
                Vehicle Details
              </Typography>
            </Grid>
            
            <Grid item xs={12}>
              <RowValueText label="Vehicle">
                <Typography variant="body1">
                  {fleetData ? displayVehicle(fleetData) : '-'}
                </Typography>
              </RowValueText>
            </Grid>

            <Grid item xs={12}>
              <RowValueText label="Registration">
                <Typography variant="body1">
                  {fleetData && fleetData.registration ? fleetData.registration : '-'}
                </Typography>
              </RowValueText>
            </Grid>
            
            <Grid item xs={12}>
              <RowValueText label="Colour">
                <Typography variant="body1">
                  {fleetData && fleetData.colour ? fleetData.colour : '-'}
                </Typography>
              </RowValueText>
            </Grid>
            
            <Grid item xs={12}>
              <RowValueText label="Cost Centre" responsive>
                <OfflineLookupField
                  fullWidth showAllResult allowFreeText
                  size="small"
                  id="costCentre"
                  label={mobileView ? 'Cost Centre' : ''}
                  disabled={!values.accountName || disabled}
                  customFleetId={values.thirdPartyNumber}
                  lookupPath="drivercostcentre"
                  value={values.costCentre || ''}
                  variant="outlined"
                  // validators={['required']}
                  // errorMessages={['This field is required']}
                  onChange={onLookupChange('costCentre')}
                  defaultOptions={values.costCentre ? [{ text: values.costCentre, value: values.costCentre }] : []}
                  onLookup={onLookup}
                  resourceModel="drivers"
                />
              </RowValueText>
            </Grid>

            <Grid item xs={12}>
              <RowValueText label="Location" responsive>
                <OfflineLookupField
                  fullWidth showAllResult
                  size="small"
                  id="location"
                  label={mobileView ? 'Location' : ''}
                  disabled={!values.accountName || disabled}
                  lookupPath="location"
                  value={values.location || ''}
                  variant="outlined"
                  // validators={['required']}
                  // errorMessages={['This field is required']}
                  onChange={onLookupChange('location')}
                  defaultOptions={values.location ? [{ text: values.location, value: values.location }] : []}
                  onLookup={onLookup}
                  resourceModel="drivers"
                />
              </RowValueText>
            </Grid>

            <Grid item xs={12}>
              <RowValueText label="Fleet Controller" responsive>
                <OfflineLookupField
                  fullWidth showAllResult allowFreeText
                  id="controller"
                  size="small"
                  label={mobileView ? 'Fleet Controller' : ''}
                  disabled={disabled}
                  customFleetId={values.thirdPartyNumber}
                  lookupPath="driverfleetcontroller"
                  value={values.controller || ''}
                  variant="outlined"
                  // validators={['required']}
                  // errorMessages={['This field is required']}
                  onChange={onLookupChange('controller')}
                  defaultOptions={values.controller ? [{ text: values.controller, value: values.controller }] : []}
                  onLookup={onLookup}
                  resourceModel="drivers"
                />
              </RowValueText>
            </Grid>

            <Grid item xs={12}>
              <RowValueText label="Vehicle Notes" responsive>
                <TextField
                  multiline fullWidth
                  rows={3}
                  size="small"
                  id="vehicleNotes"
                  label={mobileView ? 'Vehicle Notes' : ''}
                  className={classes.field}
                  disabled={disabled}
                  value={values.vehicleNotes || ''}
                  inputProps={{ maxLength: 50 }}
                  variant="outlined"
                  onChange={onInputChange}
                />
              </RowValueText>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body1">
                *Please note, there cannot be more than one driver assigned to a vehicle.
                <br /><br />
                You have chosen to change the driver {fleetData?.driver && <>from <strong>{fleetData.driver}</strong></>}{' to '}
                <strong>{values.driver || 'unassigned'}</strong>. Would you like to proceed?
              </Typography>
            </Grid>

          </Grid>
        </CardContent>

        <CardActions className={classes.actions}>
          <Button color="primary" disabled={disabled} variant="contained" onClick={onSubmitValidation}>
            {`Yes, ${fleetData && fleetData.driver ? 'Change' : 'Assign'} the Driver`}
          </Button>
          <Button disabled={disabled} variant="contained" onClick={onBack}>
            Back
          </Button>
          <Button disabled={disabled} variant="contained" onClick={onClose}>
            Cancel
          </Button>
        </CardActions>
      </Card>
      
    </ValidatorForm>
  )
}

ConfirmDriverForm.propTypes = {
  values: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  fleetData: PropTypes.object,
  // functions
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onLookup: PropTypes.func.isRequired,
}

const mapStateToProps = () => ({})

const mapDispatchToProps = (dispatch) => ({
  onLookup: (lookupPath, searchKey, customFleetId) => {
    dispatch(lookup(null, {
      resourceId: lookupPath,
      queryParams: { FleetId: customFleetId, Search: searchKey }
    }))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmDriverForm)
