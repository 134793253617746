import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Listable } from 'listable'
import Row from './row'
import { makeStyles } from '@material-ui/styles'
import { TableCell, TableRow } from '@material-ui/core'
import { actions, config } from '../../module'
import { FEATURES } from '../../constants';
import { StyledModal } from 'frame/components'

const useStyles = makeStyles(theme => ({
  root: {
    cursor: 'pointer',
    verticalAlign: 'text-top',
  },
  viewAllRow: {
    ...theme.typography.bodySmall,
    color: '#0e364f',
    cursor: 'pointer',
    padding: theme.spacing(2, 8),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 3),
    }
  },
}))

const List = ({ model, onViewAll, onToggle, onToggleAll, onToggleConfirmationModal }) => {
  const classes = useStyles()

  // Prevents re-rendering if ids or data updated - no longer needed
  const { ids, filters, viewAll, showtoggleAllConfirmationModal, ...rest } = model
  const renderItem = (item, index, itemProps) => {

    const defaultShown = 25
    const list = viewAll ? item.activeByFleet : item.activeByFleet.slice(0, defaultShown)
    const { feature } = item

    return (
      list.map((fleet) => {
        return (<Row key={fleet.fleet} toggleHandler={onToggle} item={{ ...fleet, feature }} {...itemProps}/>)
      })
    )
  }

  const renderViewAllRow = (data) => {
    const [myDriveAppFeature] = data.filter(x => x.feature === FEATURES.DRIVER_ASSIGNMENT)
    if (myDriveAppFeature && myDriveAppFeature.activeByFleet.length <= 25)
    {
      return <></>
    }

    return (
      <TableRow hover className={classes.root}>
        <TableCell className={classes.viewAllRow} colSpan={5} onClick={() => onViewAll(!viewAll)}>
          {viewAll ? 'Hide' : 'View All'}
        </TableCell>
      </TableRow>
    )
  }

  const displayTable = () => {
    return (<Listable
      actions={actions}
      config={{
        ...config,
        model: config.model.map(item => item.label === FEATURES.DRIVER_ASSIGNMENT
          ? {
              ...item, column: { ...item.column, ...driverAssignmentColumnHeaderInjection }
            }
          : item)
      }}
      model={rest}
      renderViewAllRow={renderViewAllRow}
      renderItem={renderItem}
    />)
  }

  const driverAssignmentFeatureRow = Object.entries(rest.data)
    .map(([key, value]) => value)
    .find(item => item.feature === FEATURES.DRIVER_ASSIGNMENT)

  const driverAssignmentColumnHeaderInjection = {
    // adds tooltip
    toolTip: {
      title: driverAssignmentFeatureRow?.feature || '',
      description: driverAssignmentFeatureRow?.description || ''
    },
    // toggle function and value for driver assignment toggle all switch component
    toggleAction: onToggleConfirmationModal,
    toggleValue: driverAssignmentFeatureRow?.activeAll || false,
  }
 
  return (
    <>
      {displayTable && displayTable()}
      {/* Toggle All Confirmation Modal */}
      <StyledModal
        open={showtoggleAllConfirmationModal}
        title={`Feature Toggle`}
        onCancel={() => {
          onToggleConfirmationModal(false) // close modal
        }}
        onConfirm={() => {
          onToggleConfirmationModal(false) // close modal
          onToggleAll(FEATURES.DRIVER_ASSIGNMENT, !driverAssignmentFeatureRow.activeAll) // make request to toggle all
        }}>
        Please note: this will toggle the <strong>{driverAssignmentColumnHeaderInjection.toolTip.title}</strong> feature
        <br/>
        for <strong>all fleets</strong> you have access to.
        <br />
      </StyledModal>
    </>
  )
}

List.propTypes = {
  model: PropTypes.object.isRequired,
  onViewAll: PropTypes.func,
  onToggle: PropTypes.func,
  onToggleAll: PropTypes.func,
  onToggleConfirmationModal: PropTypes.func,
}

const mapStateToProps = ({ myDriveAppFeatureToggleSettings }) => ({
  model: myDriveAppFeatureToggleSettings,
})

const mapDispatchToProps = (dispatch) => ({
  onToggle: (payload) => dispatch(actions.requestToggleFeature({ ...payload, enable: !payload.active, 'feature': payload.feature })),
  onToggleAll: (feature, enable) => {
    dispatch(actions.requestToggleFeature({ enable, feature }))
  },
  onViewAll: (payload) => dispatch(actions.requestViewAll({ isViewedAll: payload })),
  onToggleConfirmationModal: (payload) => dispatch(actions.toggleConfirmationModal(payload)),
})


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(List)
