import React, { createContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Box, Button, Grid } from '@material-ui/core'
import { Dialog } from 'frame/components'
import { useNavigate } from 'utils'
import { constants } from '../../module'
import Form from './form'
import FormTabs from './tabs'

import { formTabs } from "../../config"

const useStyles = makeStyles(theme => ({
  root: {},
}))

export const NewVehicleSummaryContext = createContext({})

const New = ({
  relatedContract,
  fleetIds,
  fetchContractStatus,
  saveStatus,
  onFetchContract,
  onSave,
  onLookup,
  onReset,
  errorMessage,
  userRoles
}) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const [saved, setSaved] = useState(false)

  const [activeTab, setActiveTab] = useState(formTabs?.[0]?.value ?? "")

  const onClose = navigate(constants.PATH_ALL)
  const onCloseHandler = () => {
    onReset()
    onClose()
  }

  const onSubmit = (payload) => {
    console.log(payload)
  }

  useEffect(() => {
    if (saved && saveStatus === 'processed') {
      onClose()
    }
  }, [saveStatus]) // eslint-disable-line

  const isSaving = saveStatus === 'processing'
  const savingError = saveStatus === 'error'

  return (
    <Dialog
      open
      autoSize
      size="md"
      aria-labelledby="Request Vehicle Order"
      breadcrumbs=""
      title={`Request Vehicle Order`}
      loading={isSaving}
      onClose={onCloseHandler}>
      <div className={classes.root}>
        <FormTabs value={activeTab} setTab={setActiveTab} />

        <Grid container>
          <Grid item md={12} xs={12}>
            <NewVehicleSummaryContext.Provider value={{
              activeTab,
              setActiveTab
            }}>
              <Form
                relatedContract={relatedContract}
                disabled={isSaving}
                fleetIds={fleetIds}
                error={savingError}
                fetchContractStatus={fetchContractStatus}
                onClose={onCloseHandler}
                onFetchContract={onFetchContract}
                onSubmit={onSubmit}
                onLookup={onLookup}
                errorMessage={errorMessage}
                userRoles={userRoles}
              />
            </NewVehicleSummaryContext.Provider>
          </Grid>
        </Grid>

        <Box display='flex' justifyContent='center' style={{ marginTop: 16, width: '100%' }}>
          <Button
            color="inherit"
            type="button"
            onClick={onClose}
            size="medium"
            variant="contained"
          >
            Close
          </Button>
        </Box>
      </div>
    </Dialog>
  )
}

New.propTypes = {
  relatedContract: PropTypes.object.isRequired,
  fleetIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  fetchContractStatus: PropTypes.string,
  saveStatus: PropTypes.string,
  onFetchContract: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onLookup: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default New

