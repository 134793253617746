import React from 'react'
import PropTypes from 'prop-types'
import { useTimer } from 'react-timer-hook'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    width: '800px',
    textAlign: 'center',
    [theme.breakpoints.down('lg')]: { width: '600px' },
    [theme.breakpoints.down('md')]: { width: '400px' },
    [theme.breakpoints.down('xs')]: { width: '300px' },
  },
  value: {
    marginBottom: 40,
    color: '#DDDDDD',
    fontSize: '100px',
    fontFamily: 'ProximaNova-Bold',
    [theme.breakpoints.down('lg')]: { marginBottom: 20, fontSize: '48px' },
    [theme.breakpoints.down('xs')]: { fontSize: '20px', marginBottom: 5  }
  },
  text: {
    marginTop: -40,
    color: '#DDDDDD',
    fontSize: '18px',
    fontFamily: 'ProximaNova-Regular',
    letterSpacing: '0.46px',
    [theme.breakpoints.down('lg')]: { fontSize: '16px' },
    [theme.breakpoints.down('xs')]: { fontSize: '14px' }
  },
  box: {
    borderRight: 'solid 2px rgb(221, 221, 221, 0.5)',
    [theme.breakpoints.down('sm')]: {
      borderRight: 'solid 1px rgb(221, 221, 221, 0.5)',
    }
  },
}))

const Countdown = ({ expiryTimestamp, onExpire }) => {

  const classes = useStyles()

  const {
    seconds,
    minutes,
    hours,
    days,
    // isRunning,
    // start,
    // pause,
    // resume,
    // restart,
  } = useTimer({
    autoStart: true,
    expiryTimestamp,
    onExpire: () => {
      console.warn('onExpire called')
      onExpire()
    }
  });

  return (
    <Grid container className={classes.root}>
      <Grid item xs={3}>
        <div className={classes.box}>
          <p className={classes.value}>{days}</p>
          <span className={classes.text}>Days</span>
        </div>
      </Grid>
      <Grid item xs={3}>
        <div className={classes.box}>
          <p className={classes.value}>{hours}</p>
          <span className={classes.text}>Hours</span>
        </div>
      </Grid>
      <Grid item xs={3}>
        <div className={classes.box}>
          <p className={classes.value}>{minutes}</p>
          <span className={classes.text}>Minutes</span>
        </div>
      </Grid>
      <Grid item xs={3}>
        <div>
          <p className={classes.value}>{seconds}</p>
          <span className={classes.text}>Seconds</span>
        </div>
      </Grid>
    </Grid>
  )
}

Countdown.propTypes = {
  expiryTimestamp: PropTypes.any,
  onExpire: PropTypes.func,
}

export default Countdown;