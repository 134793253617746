import React, { createContext, useContext, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { Card, CardContent, Grid } from '@material-ui/core'
import { FormDriverDetails } from './view-form/form-driver-details'
import { FormLeaseInformation } from './view-form/form-lease-information'
import { FormVehicleInformation } from './view-form/form-vehicle-information'
import { FormComplianceTelematics } from './view-form/form-compliance-telematics'
import { DriverResults } from './view-form/form-driver-details/results'
import { NewVehicleSummaryContext } from './new'

export const FormContext = createContext({})

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3),
  },
  error: {
    marginBottom: theme.spacing(4),
  },
  content: {
    padding: theme.spacing(3, 1.2, 3, 1.2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
  },
  fieldHeader: {
    marginTop: theme.spacing(5),
  },
  fieldGroup: {
    margin: theme.spacing(3, 0, 2, 0),
  },
  actions: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    }
  },
  button: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    minWidth: 180,
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
    }
  },
  info: {
    ...theme.typography.body2,
  },
  disabled: {
    color: theme.palette.text.tertiary,
  },
}))

const Form = ({
  onSubmit,
}) => {
  const parentContext = useContext(NewVehicleSummaryContext);

  const classes = useStyles()

  const [values, setValues] = useState({
    replacement: ''
  })

  const [searchName, setSearchName] = useState('');
  const [results, setResults] = useState(null);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const {
    activeTab
  } = parentContext

  const onInputChange = ({ target: { id, name, value } }) => {
    setValues({ ...values, [id || name]: value })
  }

  const onSelectChange = (id) => (value) => {
    setValues({ ...values, [id]: value })
  }

  const onSubmitForm = () => {
    onSubmit(values)
  }

  const memoizedValues = useMemo(() => {
    return {
      values,
      results,
      searchName,
      selectedDriver,
      onSelectChange,
      onInputChange,
      setSearchName,
      setResults,
      setSelectedDriver
    }
  }, [
    values,
    results,
    searchName,
    selectedDriver,
  ])

  return (
    <FormContext.Provider value={memoizedValues}>
      <ValidatorForm
        autoComplete="off"
        noValidate
        onSubmit={onSubmitForm}>
        <Card>
          <CardContent className={classes.content}>
            <div>
              <Grid container spacing={4}>
                {activeTab === "lease" &&
                  <FormLeaseInformation />}

                {activeTab === "vehicle_information" && 
                  <FormVehicleInformation />}

                {activeTab === "driver_details" &&
                  <FormDriverDetails>
                    <DriverResults />
                  </FormDriverDetails>}

                {activeTab === "compliance_telematics" &&
                  <FormComplianceTelematics />
                }
              </Grid>
            </div>
          </CardContent>
        </Card>
      </ValidatorForm>
    </FormContext.Provider>
  )
}

export default Form
