import PropTypes from 'prop-types'


export const model = [
{
  key: 'agreementNo',
  label: 'Agreement No.',
  column: {
    visible: false,
    width: 115,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, 
{
  key: 'controller',
  label: 'Fleet Controller',
  column: {
    visible: true,
    width: 200,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'costCentre',
  label: 'Cost Centre',
  column: {
    visible: true,
    width: 120,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'accountName',
  label: 'Fleet',
  column: {
    visible: true,
    width: 80,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'driver',
  label: 'Name',
  column: {
    visible: true,
    width: 200,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'email',
  label: 'Email',
  column: {
    visible: false,
    width: 250,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'location',
  label: 'Location',
  column: {
    visible: true,
    width: 220,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'mobile',
  label: 'Phone',
  column: {
    visible: false,
    width: 150,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'registration',
  label: 'Registration',
  column: {
    visible: true,
    width: 110,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'vehicleNotes',
  label: 'Vehicle Notes',
  column: {
    visible: false,
    width: 260,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'driverNotification',
  label: 'Driver Notification',
  column: {
    visible: false,
    width: 120,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'status',
  label: 'Status',
  column: {
    visible: false,
    width: 120,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'activated',
  label: 'App Status',
  column: {
    visible: false,
    width: 120,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'invited',
  label: 'Invitation',
  column: {
    visible: false,
    width: 100,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'lastLogin',
  label: 'Last Login',
  column: {
    visible: false,
    width: 200,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'checkbox',
  label: '',
  column: {
    visible: true,
    width: 20,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}]

const data = {
  title: 'Driver List',
  breadcrumbs: null,
  backgroundImage: 'url("/static/images/backgrounds/bg2.png")',

  idKey: 'uniqueId',
  api: {
    filter: '/filteroptions/driver',
    resource: `/driver/driver`,
    download: `/download/driver`,
  },
  model,
  headers: [
    'accountName', 'driver', 'email', 'mobile', 'costCentre',
    'registration', 'location', 'controller', 'vehicleNotes', 
    'driverNotification', 'status', 'activated', 'invited', 'lastLogin', 'checkbox'
  ],
  filters: [{
    key: 'costCentre',
    label: 'Cost Centre',
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }, {
    key: 'controller',
    label: 'Fleet Controller',
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }, {
    key: 'location',
    label: 'Locations',
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }],
}


export default data
