import { useRouter } from './useRouter'

export const useNavigate = () => {

  const { history } = useRouter()

  return (...paths) => () => {
    const path = paths
      .join('/')
      .replace('//', '/')
    history.push(path)
  }
}

// for drill down model on close in feature/fleet/components
export const useDrillDownModalOnClose = () => {

  const { location } = useRouter()
  const navigate = useNavigate()
  
  const isServiceTrackingPage = location.pathname.includes('/reports/vehicle-servicing')
  const isKMTrackingPage = location.pathname.includes('/reports/km-tracking')
  const isRequestVariationSummaryPage = location.pathname.includes('/contract-variations')

  return navigate(
    isServiceTrackingPage
      ? '/reports/vehicle-servicing'
      : isKMTrackingPage
        ? '/reports/km-tracking'
        : isRequestVariationSummaryPage
          ? '/contract-variations' : '/fleet'
  )
}
