import PropTypes from 'prop-types'


export const NavigationType = PropTypes.arrayOf(PropTypes.shape({
  title: PropTypes.string.isRequired,
  pages: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
    icon: PropTypes.elementType.isRequired,
    children: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
      icon: PropTypes.element.isRequired,
    }))
  }))
}))
