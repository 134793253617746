import React, { Fragment, useContext } from "react"
import { Box, Button, Grid, Typography } from '@material-ui/core'

import { useStyles } from "../styles"
import { NewVehicleSummaryContext } from "../../new"
import { FormCta } from "../form-cta"

export const ViewDefault = ({ setView }) => {
  const classes = useStyles()
  const parentContext = useContext(NewVehicleSummaryContext);

  return (
    <Fragment> 
      <Grid item xs={12}>
        <Typography gutterBottom variant="h4">
          Driver Details
        </Typography>
      </Grid> 
      <Grid item xs={12}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="100%"
        >
          <Box mb={2}>
            <Button
              color="primary"
              id="assign-driver-option"
              data-cy="assign-driver-option"
              className={classes.roundedButton}
              variant="contained"
              onClick={() => setView("assign_driver")}
            >
              Assign Existing Driver
            </Button>
          </Box>
          <Box>
            <Button
              color="primary"
              id="create-new-driver-option"
              data-cy="create-new-driver-option"
              className={classes.roundedButton}
              variant="contained"
              onClick={() => setView('new_driver')}
            >
              Create New Driver
            </Button>
          </Box>
        </Box>
      </Grid>

      <Fragment>
        <Grid item xs={12}>
          <FormCta onBack={() => parentContext.setActiveTab("lease")} />
        </Grid>
      </Fragment>
    </Fragment>
  )
}