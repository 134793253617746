import * as moduleTypes from './types'


export const reset = () => ({
  type: moduleTypes.RESET,
})

export const create = (payload, options) => ({
  type: moduleTypes.CREATE,
  payload,
  options,
})

export const createError = (payload, options) => ({
  type: moduleTypes.CREATE_ERROR,
  payload,
  options,
})

export const createFulfilled = (payload, options) => ({
  type: moduleTypes.CREATE_FULFILLED,
  payload,
  options,
})

export const fetch = (payload, options) => ({
  type: moduleTypes.FETCH,
  payload,
  options,
})

export const fetchError = (payload, options) => ({
  type: moduleTypes.FETCH_ERROR,
  payload,
  options,
})

export const fetchFulfilled = (payload, options) => ({
  type: moduleTypes.FETCH_FULFILLED,
  payload,
  options,
})

export const fetchByAgreement = (payload, options) => ({
  type: moduleTypes.FETCH_BY_AGREEMENT,
  payload,
  options,
})

export const fetchByAgreementError = (payload, options) => ({
  type: moduleTypes.FETCH_BY_AGREEMENT_ERROR,
  payload,
  options,
})

export const fetchByAgreementFulfilled = (payload, options) => ({
  type: moduleTypes.FETCH_BY_AGREEMENT_FULFILLED,
  payload,
  options,
})

export const fetchCorrespondingContract = (payload, options) => ({
  type: moduleTypes.FETCH_CORRESPONDING_CONTRACT,
  payload,
  options,
})

export const fetchCorrespondingContractError = (payload, options) => ({
  type: moduleTypes.FETCH_CORRESPONDING_CONTRACT_ERROR,
  payload,
  options,
})

export const fetchCorrespondingContractFulfilled = (payload, options) => ({
  type: moduleTypes.FETCH_CORRESPONDING_CONTRACT_FULFILLED,
  payload,
  options,
})

export const fetchContract = (payload, options) => ({
  type: moduleTypes.FETCH_CONTRACT,
  payload,
  options,
})

export const fetchContractError = (payload, options) => ({
  type: moduleTypes.FETCH_CONTRACT_ERROR,
  payload,
  options,
})

export const fetchContractFulfilled = (payload, options) => ({
  type: moduleTypes.FETCH_CONTRACT_FULFILLED,
  payload,
  options,
})

export const update = (payload, options) => ({
  type: moduleTypes.UPDATE,
  payload,
  options,
})

export const updateError = (payload, options) => ({
  type: moduleTypes.UPDATE_ERROR,
  payload,
  options,
})

export const updateFulfilled = (payload, options) => ({
  type: moduleTypes.UPDATE_FULFILLED,
  payload,
  options,
})

export const remove = (payload, options) => ({
  type: moduleTypes.DELETE,
  payload,
  options,
})

export const removeError = (payload, options) => ({
  type: moduleTypes.DELETE_ERROR,
  payload,
  options,
})

export const removeFulfilled = (payload, options) => ({
  type: moduleTypes.DELETE_FULFILLED,
  payload,
  options,
})

export const edit = (payload) => ({
  type: moduleTypes.ON_CHANGE,
  payload,
})

// for lookup field
export const lookup = (payload, options) => ({
  type: moduleTypes.LOOKUP,
  payload,
  options,
})

export const lookupError = (payload, options) => ({
  type: moduleTypes.LOOKUP_ERROR,
  payload,
  options,
})

export const lookupFulfilled = (payload, options) => ({
  type: moduleTypes.LOOKUP_FULFILLED,
  payload,
  options,
}) 

// for pool vehicle toggle
export const togglePoolVehicle = (payload, options) => ({
  type: moduleTypes.TOGGLE_POOL_VEHICLE,
  payload,
  options,
})

export const togglePoolVehicleError = (payload) => ({
  type: moduleTypes.TOGGLE_POOL_VEHICLE_ERROR,
  payload,
})

export const togglePoolVehicleFulfilled = (payload) => ({
  type: moduleTypes.TOGGLE_POOL_VEHICLE_FULFILLED,
  payload,
})

// for unassign vehicle
export const unassignVehicle = (payload, options) => ({
  type: moduleTypes.UNASSIGN_VEHICLE,
  payload,
  options,
})

export const unassignVehicleError = (payload) => ({
  type: moduleTypes.UNASSIGN_VEHICLE_ERROR,
  payload,
})

export const unassignVehicleFulfilled = (payload) => ({
  type: moduleTypes.UNASSIGN_VEHICLE_FULFILLED,
  payload,
})

// for driver app invite
export const inviteDriver = (payload, options) => ({
  type: moduleTypes.INVITE_DRIVER,
  payload,
  options,
})

export const inviteDriverError = (payload) => ({
  type: moduleTypes.INVITE_DRIVER_ERROR,
  payload,
})

export const inviteDriverFulfilled = (payload) => ({
  type: moduleTypes.INVITE_DRIVER_FULFILLED,
  payload,
})

export const saveTemporary = (payload) => ({
  type: moduleTypes.SAVE_TEMP,
  payload,
})