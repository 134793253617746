import React from 'react'
import PropTypes from 'prop-types'
// import { useRouter } from 'utils'
import { getAppConfig } from 'app-config'


const Release = ({ children }) => {

  // const { history } = useRouter()
  const config = getAppConfig()
  const key = `${config.app.store}/release`
  const currentRelease = localStorage.getItem(key)

  if ( ! currentRelease || currentRelease < config.build.release) {
    localStorage.setItem(key, config.build.release)
    // history.push('/logout')
    return null
  }

  return (
    <>
      {children}
    </>
  )
}

Release.propTypes = {
  children: PropTypes.element,
}

export default Release
