import React from 'react'
import PropTypes from 'prop-types'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, useTheme } from '@material-ui/styles'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { Card, CardContent, Typography, Button } from '@material-ui/core'
import { useDrillDownModalOnClose } from 'utils'


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 1.2, 3, 1.2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
      minHeight: 400,
    },
  },
  button: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    minWidth: 180,
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
    }
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    }
  }
}))

const VehicleFormWraper = ({
  title,
  titleStyle,
  subtitle,
  children,
  isLoading,
  onSubmit,
  onSubmitLabel,
  onCancel,
  onCancelLabel,
  onBack,
  centered,
}) => {

  const theme = useTheme()
  const classes = useStyles()
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'))
  const onClose = useDrillDownModalOnClose()

  return (
    <ValidatorForm autoComplete="off" noValidate onSubmit={onSubmit ? onSubmit : () => {}}>
      <Card>
        <CardContent className={classes.root}>
          <Typography  gutterBottom variant="h4" align={centered ? 'center' : 'left'} className={titleStyle}>
            {title}
          </Typography>
          <Typography variant="body1" align={centered ? 'center' : 'left'}>
            {subtitle}
          </Typography>
          
          {children}

        </CardContent>
      </Card>
      
      <div className={classes.actions}>
        {onSubmit && (
          <Button fullWidth={mobileView} color="primary" className={classes.button} variant="contained" type="submit" disabled={isLoading}>
            {onSubmitLabel ? onSubmitLabel : 'Save'}
          </Button>
        )}
        {onCancel && (
          <Button fullWidth={mobileView} className={classes.button} variant="contained" onClick={onCancel} disabled={isLoading}>
            {onCancelLabel ? onCancelLabel : 'Cancel'}
          </Button>
        )}
        <Button fullWidth={mobileView} className={classes.button} variant="contained" onClick={onClose} disabled={isLoading}>
          Close
        </Button>
      </div>
    </ValidatorForm>
  )
}

VehicleFormWraper.propTypes = {
  title: PropTypes.string,
  titleStyle: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.any,
  isLoading: PropTypes.bool,
  centered: PropTypes.bool,

  onSubmit: PropTypes.func,
  onSubmitLabel: PropTypes.string,

  onCancel: PropTypes.func,
  onCancelLabel: PropTypes.string,

  onBack: PropTypes.func,
}

export default VehicleFormWraper
