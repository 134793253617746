import PropTypes from 'prop-types'


export const model = [{
  key: 'agreementNo',
  label: 'Agreement No.',
  column: {
    visible: false,
    width: 115,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'costCentre',
  label: 'Cost Centre',
  column: {
    visible: true,
    width: 120,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'controller',
  label: 'Controller',
  column: {
    visible: false,
    width: 120,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'driverNote',
  label: 'Driver Note',
  column: {
    visible: false,
    width: 190,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'driver',
  label: 'Driver',
  column: {
    visible: true,
    width: 190,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'fuelType',
  label: 'Fuel Type',
  column: {
    visible: false,
    width: 90,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'make',
  label: 'Make',
  column: {
    visible: false,
    width: 90,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'model',
  label: `Model`,
  column: {
    visible: false,
    width: 90,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'registration',
  label: 'Registration',
  column: {
    visible: true,
    width: 110,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'registrationStatus',
  label: 'Registration Status',
  column: {
    visible: false,
    width: 140,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'registrationExpiry',
  label: 'Registration Expiry',
  column: {
    visible: true,
    width: 140,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'variant',
  label: 'Variant',
  column: {
    visible: false,
    width: 200,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'wofExpiry',
  label: `WoF Expiry`,
  column: {
    visible: true,
    width: 100,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'wofStatus',
  label: `WoF Status`,
  column: {
    visible: false,
    width: 200,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'year',
  label: 'Year',
  column: {
    visible: false,
    width: 90,
  },
  prop: {
    type: PropTypes.number,
    value: '',
  }
}, {
  key: 'accountName',
  label: 'Fleet',
  column: {
    visible: true,
    width: 80,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'agreementType',
  label: 'Agreement Type',
  column: {
    visible: true,
    width: 115,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'documentsCount',
  label: 'Documents',
  column: {
    visible: false,
    width: 100,
  },
  prop: {
    type: PropTypes.number,
    value: '',
  }
}]

const config = {
  title: 'WoF & Registration',
  breadcrumbs: null,
  backgroundImage: 'url("/static/images/backgrounds/bg4.png")',
  api: {
    filter: '/filteroptions/wofregistration',
    resource: `/report/wofregistration`,
    download: `/download/wofregistration`,
  },
  model,
  headers: [
    'accountName', 'agreementNo', 'registration', 'assetBranch', 'assetFunction', 'driverNote', 'driver',
    'year', 'make', 'model', 'variant',
    'fuelType', 'costCentre', 'controller', 'wofExpiry', 'wofStatus',
    'registrationExpiry', 'registrationStatus', 'agreementType', 'documentsCount'
  ],
  filters: [{
    key: 'costCentre',
    label: 'Cost Centre',
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }, {
    key: 'wofStatus',
    label: 'WoF Status',
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }, {
    key: 'registrationStatus',
    label: 'Registration Status',
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }, {
    key: 'agreementType',
    label: 'Agreement Type',
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }],
}


export default config
