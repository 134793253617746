import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import uuid from 'uuid/v4'
import * as fromActions from './actions'


const useAccountUpdater = (action) => {

  const dispatch = useDispatch()
  const [requestId, setRequestId] = useState(null)

  
  const request = useSelector(({ account }) => account?.requests?.[requestId])
  const errorMessage = useSelector(({ account }) => account?.errorMessage)

  const save = (payload) => {
    const nextRequestId = uuid()
    setRequestId(nextRequestId)
    dispatch(action(payload, {
      requestId: nextRequestId,
    }))
  }

  const error = request?.status === 'error'
  const loading = request?.status === 'processing'
  const status = request?.status || ''

  return { error, loading, save, status, errorMessage }
}

export const useProfileUpdater = () => useAccountUpdater(fromActions.putProfile)

export const usePasswordUpdater = () => useAccountUpdater(fromActions.putPassword)

export const useProfile = () => useSelector(state => state?.account?.profile)
