import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, useTheme } from '@material-ui/styles'
import { Grid, Card, CardContent, Button, Typography } from '@material-ui/core'
import { fbtWebFormVerifyToken, reset, fbtWebFormSetValues } from 'features/fbt-submissions/actions'
import { PageLoader, Alert } from 'frame/components'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { connect } from 'react-redux'
import { useNavigate, useRouter, useFbtWebForm } from 'utils'
import { constants } from './module'
import ReactGA from 'react-ga'
import qs from 'query-string'

import SubmissionAlreadyCompleted from './submission-already-completed'


const useStyles = makeStyles(theme => ({
  heading: { fontWeight: 'bold' },
  card: {
    margin: theme.spacing(5, 0),
    paddingTop: theme.spacing(5),
    paddingBottom: '300px',
    width: 960,
    [theme.breakpoints.down(960)]: {
      width: '100%',
      margin: '20px 10px 0 10px'
    },
  },
  field: { minWidth: 400 },
  center: {
    display:'flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'left',
      display: 'block',
    }
  },
  button: {
    borderRadius: 25,
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    minWidth: 180,
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
    }
  },
}))

const FBTWebForm = ({ flags, fbtWebForm, errorMessage, onReset, onVerifyToken, onSetFbtWebFormValues }) => {

  const form = useRef()
  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'))

  const classes = useStyles()
  const navigate = useNavigate()
  const { location } = useRouter()
  const { onVerifyEmail } = useFbtWebForm()

  const parsed = qs.parse(location.search)
  
  const [values, setValues] = useState({ email: '', token: '' })

  // flags
  const [loading, setLoading] = useState(true)
  const [invalidToken, setInvalidToken] = useState(true)

  useEffect(() => { // on load
    if(!fbtWebForm?.token) {
      onReset() // reset any previous states
      ReactGA.event({ category: 'FBTWebForm', action: 'View Page', label: 'FBT Web Form Page' })
      if(parsed?.token) {
        onVerifyToken(parsed?.token)
      } else {
        setLoading(false)
        setInvalidToken(true)
      }
    }
  }, []) // eslint-disable-line 
  
  useEffect(() => {
    // triggered once onVerifyToken is processed
    if (flags?.verifyToken === 'processed' || flags?.verifyToken === 'error') {
      flags?.verifyToken === 'processed' && setValues({ ...values, token: parsed?.token }) // append token to state
      setTimeout(() => { setLoading(false) }, 1500) // for delay loading
      setInvalidToken(flags?.verifyToken === 'error')
    }

    // redirect to next step after validating email
    if(flags?.verifyEmail === 'processed') {
      onSetFbtWebFormValues(values) // sets values to redux state
      navigate(constants.PATH_QUESTION)() // redirect to question page
    }
  }, [flags?.verifyToken, flags?.verifyEmail]) // eslint-disable-line
  
  useEffect(() => { // email input field validation
    ValidatorForm.addValidationRule('isValidEmail', (value) => {
      if(!value || value === '') return true
      let domain = value.split(/@/)[1]
      let ext = domain && domain.split(/\./)[1]
      let isValidDomain = ext && ext.length > 1 // to avoid single character email extension 
      let isEmailFormat = /^[^\s@]{2,}@[^\s@]{2,}\.[^\s@]{2,}$/.test(value)
      return isEmailFormat && isValidDomain
    })
    return () => {
      ValidatorForm.removeValidationRule('isValidEmail')
    } // eslint-disable-next-line
  }, [values?.email])

  // validates form before calling onSubmitDriverEmail
  const onVerify = () => form.current && form.current.submit() 

  // call api to vadidates email
  const onSubmitDriverEmail = () => onVerifyEmail({ 
    email: values.email,
    token: values.token,
  })

  return (
    <Card className={classes.card}>
      <CardContent>
        {loading ? (
          <PageLoader text="Verifying token..." />

        ) : invalidToken && errorMessage && errorMessage.includes('completed') ? (
          <div className={classes.center}>         
            <SubmissionAlreadyCompleted errorMessage={errorMessage} />
          </div>

        ) : invalidToken ? (
          <Alert severity="error">
            Invalid token. This FBT Submissions link is invalid or has been expired
          </Alert>
          
        ) : ( // step 1 - enter driver email
          <ValidatorForm autoComplete="off" noValidate ref={form} onSubmit={onSubmitDriverEmail}>
            <Grid container spacing={3}>
              <Grid xs={12} item>
                <Typography gutterBottom variant="h4" align="center" className={classes.heading}>
                  Fringe Benefit Tax
                </Typography>
                <Typography gutterBottom variant="body1" align="center">
                  Please enter your email
                </Typography>
              </Grid>

              <Grid xs={12} item className={classes.center}>
                <TextValidator
                  id="email"
                  label="Email"
                  className={classes.field}
                  fullWidth={mobileView}
                  inputProps={{ maxLength: 50 }}
                  value={values?.email}
                  onChange={({ target: { value } }) => setValues({ ...values, email: value })}
                  variant="outlined"
                  validators={['required', 'isValidEmail']}
                  errorMessages={['This field is required', 'Must be a valid email']}
                />
              </Grid>

              <Grid xs={12} item className={classes.buttons}>
                <Button color="primary" variant="contained" className={classes.button} onClick={onVerify}>
                  Verify
                </Button>
              </Grid>
            </Grid>
          </ValidatorForm>
        )}
      </CardContent>
    </Card>
  )
}

FBTWebForm.propTypes = {
  flags: PropTypes.object,
  fbtWebForm: PropTypes.object,
  errorMessage: PropTypes.string,
  // func
  onVerifyToken: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onSetFbtWebFormValues: PropTypes.func.isRequired,
}

const mapStateToProps = ({ fbtSubmissions }) => ({
  flags: fbtSubmissions.flags,
  fbtWebForm: fbtSubmissions.fbtWebForm,
  errorMessage: fbtSubmissions.errorMessage,
})

const mapDispatchToProps = (dispatch) => ({
  onVerifyToken: (token) => {
    dispatch(fbtWebFormVerifyToken(token))
  },
  onReset: () => {
    dispatch(reset())
  },
  onSetFbtWebFormValues: (payload) => {
    dispatch(fbtWebFormSetValues(payload))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(FBTWebForm)