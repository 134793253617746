import PropTypes from 'prop-types'


export const model = [
  {
    key: 'id',
    label: 'ID',
    column: {
      visible: false,
      width: 80,
    },
    prop: {
      type: PropTypes.number,
      value: null,
    }
  }, {
    key: 'startDate',
    label: 'Start Date',
    column: {
      visible: true,
      width: 90,
    },
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }, {
    key: 'endDate',
    label: 'End Date',
    column: {
      visible: true,
      width: 90,
    },
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }, {
    key: 'vehicleRegistration',
    label: 'Registration',
    column: {
      visible: true,
      width: 80,
    },
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }, {
    key: 'vehicleYear',
    label: 'Vehicle Year',
    column: {
      visible: true,
      width: 80,
    },
    prop: {
      type: PropTypes.number,
      value: null,
    }
  }, {
    key: 'makeModel',
    label: 'Make and Model',
    column: {
      visible: true,
      width: 150,
    },
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }, {
    key: 'costPrice',
    label: 'Cost Price',
    column: {
      visible: true,
      width: 100,
    },
    prop: {
      type: PropTypes.number,
      value: null,
    }
  }, {
    key: 'totalDaysVehicleAvailable',
    label: `Total Days Vehicle Available`,
    column: {
      visible: true,
      width: 120,
    },
    prop: {
      type: PropTypes.number,
      value: null,
    }
  }, {
    key: 'daysAvailableForPrivateUse',
    label: 'Days Available For Private Use',
    column: {
      visible: true,
      width: 120,
    },
    prop: {
      type: PropTypes.number,
      value: null,
    }
  }, {
    key: 'exemptDays',
    label: 'Exempt Days',
    column: {
      visible: true,
      width: 100,
    },
    prop: {
      type: PropTypes.number,
      value: null,
    }
  }, {
    key: 'fbtValueOfBenefit',
    label: 'FBT Value of Benefit',
    column: {
      visible: true,
      width: 100,
    },
    prop: {
      type: PropTypes.number,
      value: null,
    }
  }, {
    key: 'gstPortion',
    label: 'GST Portion FBT Value of Benefit',
    column: {
      visible: true,
      width: 200,
    },
    prop: {
      type: PropTypes.number,
      value: null,
    }
  }, {
    key: 'fbtPayableExclGst',
    label: 'FBT Payable (excl GST)',
    column: {
      visible: true,
      width: 120,
    },
    prop: {
      type: PropTypes.number,
      value: null,
    }
  }, {
    key: 'costCentre',
    label: 'Cost Centre',
    column: {
      visible: true,
      width: 80,
    },
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }, {
    key: 'driverName',
    label: 'Driver Name',
    column: {
      visible: true,
      width: 120,
    },
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }, {
    key: 'driverEmail',
    label: 'Email',
    column: {
      visible: true,
      width: 120,
    },
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }, {
    key: 'driverMobile',
    label: 'Mobile',
    column: {
      visible: true,
      width: 80,
    },
    prop: {
      type: PropTypes.string,
      value: null,
    }
  },
  // {
  //   key: 'quarter',
  //   label: 'Quarter',
  //   column: {
  //     visible: false,
  //     width: 80,
  //   },
  //   prop: {
  //     type: PropTypes.number,
  //     value: '',
  //   }
  // }
]

const config = {
  idKey: 'id',
  title: 'FBT Summary',
  // subtitle: 'Intro copy goes here. Lorem ipsum dolor sit amet.',
  backgroundImage: 'url("/static/images/backgrounds/bg4.png")',
  disclaimerMessage: {
    title: 'Disclaimer',
    message: 'The data in this report has been created from data collected from your drivers. The indicative FBT values are based on the ‘Cost Price’ valuation and not the ‘tax value’ methodology.  Whilst due care has been taken, Toyota Finance New Zealand Ltd (TFNZ) is not responsible for any errors or omissions, or for the results obtained from the use of this information. It is strongly advised independent tax/legal advice is sought.',
  },
  api: {
    filter: '/filteroptions/fbtsummary',
    resource: `/fbt/fbtsummary`,
    download: `/download/fbtsummary`,
  },
  model,
  headers: [
    'startDate', 'endDate', 'vehicleRegistration', 'vehicleYear', 'makeModel', 'costPrice', 
    'totalDaysVehicleAvailable', 'daysAvailableForPrivateUse', 'exemptDays', 
    'fbtValueOfBenefit', 'gstPortion', 'fbtPayableExclGst',
    'costCentre', 'driverName', 'driverEmail', 'driverMobile',
    // 'quarter'
  ],
  filters: [],
  noColumnSort: true,
  disabledClearFilter: true,
  delayLoading: 2000, // delay report to load after x seconds
}


export default config
