import PropTypes from 'prop-types'


export const model = [{
  key: 'email',
  label: 'Email',
  column: {
    visible: true,
    width: 200,
  },
  prop: {
    type: PropTypes.email,
    value: '',
  }
}, {
  key: 'nickname',
  label: 'Name',
  column: {
    visible: true,
    width: 200,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'role',
  label: 'Role',
  column: {
    visible: true,
    width: 200,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'fleets',
  label: 'Profile',
  column: {
    visible: true,
    width: 200,
  },
  prop: {
    type: PropTypes.array,
  }
}, {
  key: 'blocked',
  label: 'Status',
  column: {
    visible: true,
    width: 200,
  },
  prop: {
    type: PropTypes.string,
  }
},
//  {
//   key: 'invitationVerified',
//   label: 'Invitation',
//   column: {
//     visible: true,
//     width: 200,
//   },
//   prop: {
//     type: PropTypes.string,
//   }
// }, 
{
  key: 'lastLogin',
  label: 'Last Login',
  column: {
    visible: true,
    width: 200,
  },
  prop: {
    type: PropTypes.string,
  }
}]

const config = {
  title: 'Users',
  breadcrumbs: null,
  backgroundImage: 'url("/static/images/backgrounds/bg4.png")',
  
  disclaimerMessage: {
    title: 'Registered Users',
    message: "Please note, only users who have been 'invited' and completed the registration/sign up process are listed here. If you are looking for 'invited' users who are yet to complete the 'registration', please see 'User Invitations'.",
  },

  idKey: 'id',
  api: {
    filter: '/filteroptions/listusers',
    resource: `/usermanagement/listusers`,
  },
  model,
  headers: [
    'nickname', 'email', 'role', 'fleets', 'blocked', 
    //'invitationVerified', 
    'lastLogin'
  ],
  filters: [{
    key: 'role',
    label: 'Role',
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }, {
    key: 'fleets',
    label: 'Fleet',
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }, {
    key: 'status',
    label: 'Status',
    prop: {
      type: PropTypes.string,
      value: '',
    }
  },
  //  {
  //   key: 'verifyStatus',
  //   label: 'Invitation',
  //   prop: {
  //     type: PropTypes.string,
  //     value: '',
  //   }
  // }
  ],
  noDownload: true,
}


export default config
