import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Label, FleetIcon, StyledModal } from 'frame/components'
import { TableCell, TableRow, Button } from '@material-ui/core'
import { createRowSelector, useIsWhiteListed } from 'utils'
import { isUserIdAuth0, getRole, getUserStatusColor } from 'listable'
import { types } from '../../module'
import { hasRoles } from 'acl'
import { getUserStatus } from '../../utils'
import moment from 'moment'


const useStyles = makeStyles(theme => ({
  root: {
    cursor: 'pointer',
    verticalAlign: 'text-top',
  },
  label: theme.typography.bodySmall,
  spacer: {
    margin: 0,
    padding: 0,
  },
  avatars: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    maxWidth: 200,
  },
}))

const Row = ({ item, columns, onView, userRoles, onDelete }) => {

  const classes = useStyles()
  const isWhiteListedUser = useIsWhiteListed()
  const onSafeRowClick = createRowSelector(onView)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)

  return (
    <TableRow
      hover
      className={classes.root}
      onClick={item.invitationVerified || isUserIdAuth0(item.id) ? onSafeRowClick : undefined}>
      <TableCell className={classes.spacer} />
      {columns.nickname && (
        <TableCell className={classes.label}>
          {item.nickname || '-'}
        </TableCell>
      )}
      {columns.email && (
        <TableCell className={classes.label}>
          {item.email || '-'}
        </TableCell>
      )}
      {columns.role && (
        <TableCell className={classes.label}>
          {item.role ? getRole(item.role.name) : '-'}
        </TableCell>
      )}
      {columns.fleets && (
        <TableCell className={classes.label}>
          {item.fleets && item.fleets.length > 0 ? (
            <div className={classes.avatars}>
              {item.fleets.map((node, index) =>
                <FleetIcon key={index} fleetName={node.name} />
              )}
            </div>
          ) : (
            item.role && item.hasAccount && item.role.name.toLowerCase() === 'superadmin' ?  'Unrestricted Access' : ''
          )
        }
        </TableCell>
      )}
      {columns.blocked && (
        <TableCell className={classes.label}>
          <Label
            color={getUserStatusColor(item.hasAccount, item.blocked)}
            variant="outlined">
             {getUserStatus(item)}
          </Label>
        </TableCell>
      )}
      {columns.lastLogin && (
        <TableCell className={classes.label}>
          {item.lastLogin ? moment(item.lastLogin).format('DD/MM/YYYY hh:mm A') : '-'}
        </TableCell>
      )}
      <TableCell className={classes.label}>
        {(!item.hasAccount || item.blocked) && hasRoles(['superadmin', 'fleetcontroller'], userRoles) && isWhiteListedUser &&
          <Button
            className="ignore"
            color="primary"
            variant="contained"
            onClick={() => setOpenDeleteModal(true)}>
            Delete
          </Button>
        }
      </TableCell>
      <TableCell className={classes.spacer} />
      
      {/* for deleting user */}
      <StyledModal
        open={openDeleteModal}
        title={`Confirm Delete User`}
        onCancel={() => setOpenDeleteModal(false)}
        onConfirm={() => onDelete(item.id)}>
        {`Please confirm to delete user ${item.nickname}.`}
      </StyledModal>
    </TableRow>
  )
}

Row.propTypes = {
  item: PropTypes.oneOfType([
    PropTypes.object,
    types.ModelType,
  ]).isRequired,
  columns: types.ColumnsType.isRequired,
  onView: PropTypes.func.isRequired,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  onDelete: PropTypes.func.isRequired,
}

export default Row
