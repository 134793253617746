

export const createConstants = (moduleName) => ({

  RESET_DEFAULTS: `${moduleName}_reset_defaults`,

  ADD_DATA: `${moduleName}_add_data`,
  REMOVE_DATA: `${moduleName}_remove_data`,

  REQUEST_DATA: `${moduleName}_request_data`,
  REQUEST_DATA_FULFILLED: `${moduleName}_request_data_fulfilled`,
  REQUEST_DATA_ERROR: `${moduleName}_request_data_error`,

})
