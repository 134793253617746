export const getReliefVehicleTypes = (tfp1865FeatureToggle) => {
    let vehicleTypes = [
        'Hatchback',
        'Sedan',
        'Station Wagon',
        'RV/SUV',
        'Ute',
        'Van',
        'Other',
    ]
    if (tfp1865FeatureToggle) {
        const removedTfp1865vehicleTpes = ['Station Wagon', 'Sedan']
        vehicleTypes = vehicleTypes.filter(x => !removedTfp1865vehicleTpes.includes(x))
    }
    return vehicleTypes;
}
  
  