import PropTypes from 'prop-types'


export const model = [{
  key: 'agreementNo',
  label: 'Agreement No.',
  column: {
    visible: false,
    width: 115,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'costCentre',
  label: 'Cost Centre',
  column: {
    visible: true,
    width: 120,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'controller',
  label: 'Controller',
  column: {
    visible: false,
    width: 120,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'dateOfLastKmReading',
  label: 'Date of Last KM Reading',
  column: {
    visible: false,
    width: 170,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'dateOfLastService',
  label: `Date of Last Service`,
  column: {
    visible: true,
    width: 140,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'driverNote',
  label: 'Driver Note',
  column: {
    visible: false,
    width: 190,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'driver',
  label: 'Driver',
  column: {
    visible: true,
    width: 190,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'estNextServiceDueDate',
  label: 'Est Next Service Due Date',
  column: {
    visible: true,
    width: 200,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'estServiceDateLogic',
  label: 'Est Service Date Logic',
  column: {
    visible: false,
    width: 170,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'fuelType',
  label: 'Fuel Type',
  column: {
    visible: false,
    width: 100,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'make',
  label: 'Make',
  column: {
    visible: false,
    width: 90,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'model',
  label: `Model`,
  column: {
    visible: false,
    width: 130,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'odometer',
  label: 'Odometer',
  column: {
    visible: false,
    width: 100,
  },
  prop: {
    type: PropTypes.number,
    value: '',
  }
}, {
  key: 'registration',
  label: 'Registration',
  column: {
    visible: true,
    width: 110,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'serviceOdometer',
  label: `Service Odometer`,
  column: {
    visible: true,
    width: 130,
  },
  prop: {
    type: PropTypes.number,
    value: '',
  }
}, {
  key: 'sourceOfKmReading',
  label: 'Source of KM Reading',
  column: {
    visible: false,
    width: 160,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'startDate',
  label: 'Start Date',
  column: {
    visible: false,
    width: 90,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'variant',
  label: 'Variant',
  column: {
    visible: false,
    width: 250,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'year',
  label: 'Year',
  column: {
    visible: false,
    width: 80,
  },
  prop: {
    type: PropTypes.number,
    value: '',
  }
}, {
  key: 'accountName',
  label: 'Fleet',
  column: {
    visible: true,
    width: 80,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'maturityDate',
  label: 'Maturity Date',
  column: {
    visible: true,
    width: 80,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'originalMaturityDate',
  label: 'Original Maturity Date',
  column: {
    visible: true,
    width: 80,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'agreementType',
  label: 'Agreement Type',
  column: {
    visible: true,
    width: 115,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, { ///// ---
  key: 'serviceBooked',
  label: 'Service Booked',
  column: {
    visible: false,
    width: 115,
  },
  prop: {
    type: PropTypes.bool,
    value: '',
  }
}, {
  key: 'serviceStatus',
  label: 'Servicing Booking Status',
  column: {
    visible: false,
    width: 115,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'bookedDate',
  label: 'Booked Date',
  column: {
    visible: false,
    width: 115,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'bookedLocation',
  label: 'Booked Dealership',
  column: {
    visible: false,
    width: 115,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'completedLocation',
  label: 'Service Dealership',
  column: {
    visible: false,
    width: 115,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  prop: {
    type: PropTypes.number,
    value: '',
  },
  }, {
  key: 'documentsCount',
  label: 'Documents',
  column: {
    visible: false,
    width: 100,
  }
}]

const config = {
  title: 'Vehicle Servicing',
  breadcrumbs: null,
  backgroundImage: 'url("/static/images/backgrounds/bg4.png")',
  
  disclaimerMessage: {
    title: null,
    message: 'Please note there are possible implications to your obligations both under your finance contract/lease and the Health and Safety at Work Act 2015 if the servicing requirements are not completed when due.',
  },

  api: {
    filter: '/filteroptions/vehicleservicing',
    resource: `/report/vehicleservicing`,
    download: `/download/vehicleservicing`,
  },
  model,
  headers: [
    'accountName', 'agreementNo', 'registration', 'assetBranch', 'assetFunction', 'driverNote', 'driver', 'year', 'make', 'model', 'variant', 
    'fuelType', 'costCentre', 'controller', 'startDate', 'dateOfLastService',
    'serviceOdometer', 'dateOfLastKmReading', 'sourceOfKmReading',
    'odometer', 'estNextServiceDueDate', 'estServiceDateLogic', 'maturityDate', 'originalMaturityDate', 'agreementType',
    'serviceBooked', 'serviceStatus', 'bookedDate', 'bookedLocation', 'completedLocation', 'documentsCount',
    
  ],
  filters: [{
    key: 'costCentre',
    label: 'Cost Centre',
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }, {
    key: 'serviceDueDate',
    label: 'Est Next Service Due Date Month',
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }, {
    key: 'maturityDate',
    label: 'Maturity Date',
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }, {
    key: 'originalMaturityDate',
    label: 'Original Maturity Date',
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }, {
    key: 'agreementType',
    label: 'Agreement Type',
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }, {
    key: 'serviceBooked',
    label: 'Service Booked',
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }, {
    key: 'controller',
    label: 'Controller',
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }],
}


export default config
