import { connect } from 'react-redux'
import Add from './add'
import { create, fetchContract, lookup } from '../../actions'


const mapStateToProps = ({ drivers }) => ({
  saveStatus: drivers.flags?.create || 'none',
})

const mapDispatchToProps = (dispatch) => ({
  onFetchContract: (agreementNo, fleetIds) => {
    const FleetId = fleetIds.join(',')
    dispatch(fetchContract(null, {
      resourceId: agreementNo,
      queryParams: { FleetId }
    }))
  },
  onSave: (payload) => {
    dispatch(create(payload))
  },
  onLookup: (lookupPath, searchKey, customFleetId, size = 100) => {
    dispatch(lookup(null, {
      resourceId: lookupPath,
      queryParams: { FleetId: customFleetId, Search: searchKey, size }
    }))
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Add)
