import React, { Fragment } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid, Typography, Divider } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'


const useStyles = makeStyles(theme => ({
  list: {
    height: '368px',
    maxHeight: '368px',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: { height: '100%' },
  },
  listItem: {
    cursor: 'pointer',
    paddingTop: theme.spacing(1.5),
  },
  icon: {
    width: 40,
    height: 40,
    display: 'inline-block',
    verticalAlign: 'text-top',
    color: '#00b6b0',
  },
  spacing: { margin: theme.spacing(1, 0) }
}))


export const ListItem = () => {  
  const classes = useStyles()
  return (
    <Grid container spacing={2} className={classes.listItem}>              
      <Grid item xs={3} sm={2}>
        <Typography align="center">
          <Skeleton animation="wave" variant="circle" className={classes.icon} />
        </Typography>
      </Grid>
      <Grid item xs={9} sm={10}>
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <Skeleton animation="wave" variant="rect" height={15} width={75} style={{ marginRight: 20 }} />
          <Skeleton animation="wave" variant="rect" height={15} width={120}/>
        </div>
        <Skeleton animation="wave" variant="rect" height={15} className={classes.spacing} />
        <Skeleton animation="wave" variant="rect" height={15} width={150} />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  )
}


const ListItemLoadingSkeleton = () => {

  const classes = useStyles()

  return (
    <div className={classes.list}>            
      {Array.from(Array(4).keys()).map((_, index) => (
        <Fragment key={`mynotification-skeleton-${index}`} >
          <ListItem />
        </Fragment>
      ))}
    </div>
  )
}

export default ListItemLoadingSkeleton