import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { trimObjectStrings } from 'utils'
import Edit from './edit'
import { fetch, update, edit, remove, lookup, saveTemporary } from '../../actions'
import { fetch as fetchContract } from 'features/fleet/actions'


const mapStateToProps = ({ drivers, settings }, ownProps) => ({
  id: ownProps.match.params?.id,
  data: trimObjectStrings(drivers.data[ownProps.match.params?.id]),
  flags: drivers.flags,
  fleetIds: settings.fleet.selected,
  apiResponse: drivers.apiResponse,
  userRoles: settings.roles
})

const mapDispatchToProps = (dispatch) => ({
  onFetch: (agreementNo, fleetIds) => {
    const FleetId = fleetIds.join(',')
    dispatch(fetch(null, {
      resourceId: agreementNo,
      queryParams: { FleetId }
    }))
  },
  onFetchContract: (agreementNo, fleetId) => {
    dispatch(fetchContract(null, {
      resourceId: agreementNo,
      queryParams: { fleetId }
    }))
  },
  onUpdate: (uniqueId, payload) => {
    // resourceId is appended to url, see makeRequestEffect, getUrl function
    dispatch(update(payload, {
      resourceId: uniqueId,
    }))
  },
  onChange: (payload) => {
    dispatch(edit(payload))
  },
  onRemove: (uniqueId) => {
    dispatch(remove(null, { resourceId: uniqueId }))
  },
  onLookup: (lookupPath, searchKey, customFleetId, size = 100) => {
    dispatch(lookup(null, {
      resourceId: lookupPath,
      queryParams: { FleetId: customFleetId, Search: searchKey, size }
    }))
  },
  onSaveTemporary: (payload) => {
    dispatch(saveTemporary(payload))
  } 
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Edit))
