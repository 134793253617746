import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Card, CardContent, Grid, Typography } from '@material-ui/core'
import NotFoundImage from 'frame/assets/404.png'


const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'center',
    padding: theme.spacing(3, 1.2, 3, 1.2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
  },
  content: {
    height: '200px',
    marginTop: theme.spacing(3),
  },
  graphic: {
    justifySelf: 'center',
    width: '200px',
    height: 'auto',
  },
}))

const NoDataView = ({ errorMessage, agreementNo }) => {

  const classes = useStyles()

  return (
      <Card>
        <CardContent className={classes.root}>
          <Typography  gutterBottom variant="h4">
            Error
          </Typography>
          
          <div className={classes.content}>
            <Grid container spacing={3}>
                <Grid item xs={12}>                  
                  <img className={classes.graphic} src={NotFoundImage} alt="" />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1">
                    {errorMessage
                      ? `Error: ${errorMessage} for vehicle registration ${agreementNo}`
                      : agreementNo
                        ? `No data for vehicle registration ${agreementNo}`
                        : 'No Data'}
                  </Typography>
                </Grid>
            </Grid>
          </div>
        </CardContent>
      </Card>
  )
}

NoDataView.propTypes = {  
  errorMessage: PropTypes.string,
  agreementNo: PropTypes.string,
}


export default NoDataView
