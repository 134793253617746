import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import Form from './form'

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(3, 0),
  },
}))

const Password = () => {

  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Form />
    </div>
  )
}

Password.propTypes = {
  onSave: PropTypes.func.isRequired,
}

export default Password
