import React, { useRef, useState } from 'react'
import { NavLink, Link as RouterLink } from 'react-router-dom'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Avatar, Button, Divider, IconButton, Toolbar, Tooltip } from '@material-ui/core'

import { useSelectedFleets, useSettingsFleetValues, useSelectedPremiumFleets } from 'features/settings'
import { getInitials, useRouter } from 'utils'
import { useProfile } from 'features/account'
import { IEWarningPopup, FleetIcon, Ribbon, Brand } from 'frame/components'

// popover menu components
import FBTPopover from './fbt-popover'
import ActionsPopover from './actions-popover'
import AccountPopover from './account-popover'
// import NotificationsPopover from './notifications-popover'

import ReportsPopover from './reports-popover'
import RequestsPopover from './requests-popover'

// paths
import { HOMEPAGE_PATH } from 'features/homepage'
import { DASHBOARD_PATH } from 'features/dashboard'
import { constants as fromDrivers } from 'features/drivers'
import { constants as fromFleet } from 'features/fleet'

// icons
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import AccountIcon from '@material-ui/icons/AccountCircle'

import { useEnabledFeatureToggleList } from 'features/settings'
import { hasRoles } from 'acl'


const useStyles = makeStyles(theme => ({
  root: { flexGrow: 1 },
  inner: {
    flex: 1,
    minHeight: 74,
    background: 'linear-gradient(0.25turn, #022946, #01B6B1)', //theme.palette.dark,
  },
  menuButton: { marginRight: theme.spacing(2) },
  brand: { display: 'flex' },
  navList: { display: 'flex', paddingTop: 4 },
  filler: { flex: 1 },
  divider: {
    margin: theme.spacing(0, 2),
    width: 1,
    height: 34,
    opacity: 0.3,
    background: theme.palette.light,
  },
  user: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'spaceBetween',
    alignItems: 'center',
    padding: theme.spacing(2),
    minWidth: 260,
  },
  avatar: {
    width: 26,
    height: 26,
    color: theme.palette.dark,
    fontSize: 12,
    background: theme.palette.light,
  },
  menuIcon: {
    color: theme.palette.light,
    animation: '0.3s all ease-out',
    '&:hover': { color: theme.palette.white },
  },
  menuPlusIcon: {
    color: theme.palette.light,
    transform: 'translateX(-5px)',
    animation: '0.3s all ease-out',
    '&:hover': { color: theme.palette.white },
  },
  menuDropIcon: {
    position: 'absolute',
    color: theme.palette.light,
    fontSize: 18,
    transform: 'translateX(10px)',
    animation: '0.3s all ease-out',
    '&:hover': { color: theme.palette.white },
  },
  menuBadge: { backgroundColor: theme.palette.brand },
  userInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    paddingLeft: 10,
  },
  navLink: {
    display: 'flex',
    whiteSpace: 'nowrap', // so that 'TFS Fleet' always stays in the same line on navbar
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    margin: '0 5px',
    padding: '10px 5px',
    // minWidth: '64px',
    color: theme.palette.light,
    fontSize: '14px',
    fontWeight: 600,
    // letterSpacing: '1px',
    textDecoration: 'none',
    textTransform: 'uppercase',
    transition: 'all 0.2s ease',
    '&:hover': { color: theme.palette.white, backgroundColor: 'transparent' },
    '& > span': { color: theme.palette.light, animation: '0.3s all ease-out' },
    '&:hover > span': { color: theme.palette.white },
  },
  navLinkSelected: {
    color: theme.palette.white,
    '& > span': { color: theme.palette.white },
    '&:after': {
      content: '" "',
      position: 'absolute',
      bottom: '10px',
      width: 'calc(100% - 10px)',
      height: '2px',
      background: theme.palette.white,
    }
  },
  navLinkSelectedSmall: {
    color: theme.palette.white,
    '& > span': { color: theme.palette.white },
    '&:after': {
      content: '" "',
      position: 'absolute',
      bottom: '10px',
      width: '100%',
      maxWidth: '30px',
      height: '2px',
      background: theme.palette.white,
    }
  },
  fleets: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: { display: 'none' }
  },
  fleetAvatar: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  moreIcon: {
    color: theme.palette.light,
    marginTop: theme.spacing(1)
  },
  envLabel: {
    color: theme.palette.light,
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(-3),
  }
}))

const TopBarDesktop = ({ showAlfaOutageMessage, userRoles, showIsPortalReadOnlyMessage }) => {

  const selectedFbtFleets = useSelectedPremiumFleets()
  const featureToggleList = useEnabledFeatureToggleList()
  const fbtFeatureToggle = featureToggleList.includes('FBT')
  const userPermissionsFeatureToggle = featureToggleList.includes('UserPermissions')
  const showButtons = userPermissionsFeatureToggle ? hasRoles(['superadmin', 'fleetcontroller'], userRoles) : true
  const SustainabilityFeatureToggle = false

  // refs for popover menus
  const fbtAnchor = useRef()
  const actionsAnchor = useRef()
  const accountAnchor = useRef()
  const reportsAnchor = useRef()
  const requestsAnchor = useRef()
  // const notificationsAnchor = useRef()
  // const notificationList = []

  const classes = useStyles()
  const profile = useProfile()
  const { history, location } = useRouter()
  const selectedFleets = useSelectedFleets()
  const options = useSettingsFleetValues()

  const hasFleets = !!options.length
  const noFbtFleetSelected = selectedFbtFleets.length === 0

  const [menu, setMenu] = useState()

  const onOpenMenu = (value) => () => setMenu(value)
  const onCloseMenu = () => setMenu()
  const isOpen = (value) => value === menu

  const onLogout = () => {
    onCloseMenu()
    localStorage.clear()
    localStorage.setItem('FORCE_LOGOUT_ALL_SESSIONS', true) // to logout other tab sessions if any
    history.push('/logout')
  }

  const createLinkTo = (to) => () => {
    onCloseMenu()
    history.push(to)
  }

  const contains = (value) => location.pathname.includes(value)

  // for fleet icons displayed on nav bar
  const MAX_FLEET = 10
  const maxSelectedFleets = selectedFleets.length > MAX_FLEET
  const fleets = maxSelectedFleets ? selectedFleets.slice(0, MAX_FLEET) : selectedFleets

  const isUAT = window.location.href.includes('toyotafleetapp.bornuat1.co.nz') || window.location.href.includes('uat.toyotafleet.app')
  const isDev = window.location.href.includes('dev-sites')
  const isLocal = window.location.href.includes('local')
  const isDemo = window.location.href.includes('demo')
  const isIEBrowser = /*@cc_on!@*/false || !!document.documentMode

  const isRootPage = window.location.pathname === '/'

  return (
    <div className={classes.root}>
      <Toolbar className={classes.inner} position="static">
        <RouterLink className={classes.brand} to="/">
          <Brand variant="light" />
        </RouterLink>
        {isUAT && <p className={classes.envLabel}>(UAT Site)</p>}
        {isDev && <p className={classes.envLabel}>(Dev Site)</p>}
        {isLocal && <p className={classes.envLabel}>(Local Site)</p>}
        {isDemo && <p className={classes.envLabel}>(Demo Site)</p>}
        {isIEBrowser && <IEWarningPopup />}

        <div className={classes.filler} />
        <div className={classes.navList}>

          {hasFleets && (
            <div className={classes.fleets}>

              {fleets.map((node, index) =>
                <span className={classes.fleetAvatar} key={`navbar-fleet-icon-${index}`}>
                  <FleetIcon fleetName={node.label} />
                </span>
              )}

              {maxSelectedFleets &&
                <Tooltip title={`${selectedFleets.length - MAX_FLEET} more`}>
                  <MoreHorizIcon className={classes.moreIcon} />
                </Tooltip>
              }
              <Divider
                className={classes.divider}
                orientation="vertical" />
            </div>
          )}

          <NavLink
            data-cy="home"
            to={HOMEPAGE_PATH}
            color="inherit"
            className={clsx(
              classes.navLink,
              isRootPage || contains('/summary-dashboard') ? classes.navLinkSelected : ''
            )}
            activeClassName={classes.navLinkSelected}>
            Home
          </NavLink>

          <NavLink
            data-cy="dashboard"
            to={DASHBOARD_PATH}
            color="inherit"
            className={clsx(
              classes.navLink,
              contains('/dashboard') ? classes.navLinkSelected : ''
            )}
            activeClassName={classes.navLinkSelected}>
            Dashboard
          </NavLink>

          <Button
            data-cy="reports"
            aria-label="reports"
            aria-controls="menu-reports"
            aria-haspopup="true"
            color="inherit"
            className={clsx(classes.navLink, contains('reports') ? classes.navLinkSelected : '')}
            ref={reportsAnchor}
            onClick={onOpenMenu('reports')}>
            Reports
          </Button>

          <NavLink
            data-cy="tfs-fleet"
            to={fromFleet.PATH_ALL}
            color="inherit"
            className={classes.navLink}
            activeClassName={classes.navLinkSelected}>MyFleet
          </NavLink>

          <NavLink
            data-cy="drivers"
            to={fromDrivers.PATH_ALL}
            color="inherit"
            className={classes.navLink}
            activeClassName={classes.navLinkSelected}>
            Drivers
          </NavLink>

          <Button
            aria-label="requests"
            aria-controls="menu-requests"
            aria-haspopup="true"
            color="inherit"
            className={clsx(
              classes.navLink,
              contains('relief-vehicles') || contains('vehicle-relocations') ? classes.navLinkSelected : '',
            )}
            ref={requestsAnchor}
            onClick={onOpenMenu('requests')}>
            Requests Summary
          </Button>

          {fbtFeatureToggle && (
            <Button
              aria-label="fbt reports"
              aria-controls="fbt-reports"
              aria-haspopup="true"
              color="inherit"
              className={clsx(classes.navLink, contains('fbt') ? classes.navLinkSelectedSmall : '')}
              ref={fbtAnchor}
              onClick={() => noFbtFleetSelected ? history.push('/fbt/submission') : onOpenMenu('fbt')()}>
              FBT
            </Button>
          )}

          {SustainabilityFeatureToggle && (
            <NavLink
              data-cy="sustainability"
              to={'/sustainability'}
              color="inherit"
              className={classes.navLink}
              activeClassName={classes.navLinkSelected}>
              Sustainability
            </NavLink>
          )}

          <Divider className={classes.divider} orientation="vertical" />
          {showButtons && (
            <Button
              aria-label="quick actions"
              aria-controls="menu-actions"
              aria-haspopup="true"
              color="inherit"
              className={classes.navLink}
              ref={actionsAnchor}
              onClick={onOpenMenu('actions')}>
              Requests
            </Button>
          )}
        </div>

        {/* <Divider className={classes.divider} orientation="vertical" />

        <IconButton
          aria-label="quick links"
          aria-controls="menu-actions"
          aria-haspopup="true"
          color="inherit"
          ref={actionsAnchor}
          onClick={onOpenMenu('actions')}>
          <AddIcon className={classes.menuPlusIcon} />
          <ArrowDownIcon className={classes.menuDropIcon} />
        </IconButton> */}

        {/* <IconButton
          aria-label="notifications"
          aria-controls="menu-notifications"
          aria-haspopup="true"
          className={classes.menuIcon}
          color="inherit"
          ref={notificationsAnchor}
          onClick={onOpenMenu('notifications')}>
          <Badge
            badgeContent={notificationList.length}
            classes={{ badge: classes.menuBadge }}
            variant="dot">
            <NotificationsIcon />
          </Badge>
        </IconButton> */}

        <IconButton
          data-cy="avatar"
          aria-label="account of current user"
          aria-controls="menu-account"
          aria-haspopup="true"
          color="inherit"
          ref={accountAnchor}
          onClick={onOpenMenu('account')}>
          {(profile && profile.nickname) ? (
            <Avatar
              data-cy="avatar-icon"
              className={classes.avatar}
              src={profile.picture}>
              {getInitials(profile.nickname)}
            </Avatar>
          ) : (
            <AccountIcon className={classes.menuIcon} />
          )}
        </IconButton>

        {/* Popover Menus */}
        <ReportsPopover
          anchor={reportsAnchor}
          open={isOpen('reports')}
          linker={createLinkTo}
          onClose={onCloseMenu}
        />
        <RequestsPopover
          anchor={requestsAnchor}
          open={isOpen('requests')}
          linker={createLinkTo}
          onClose={onCloseMenu}
        />

        <AccountPopover
          anchor={accountAnchor}
          open={isOpen('account')}
          user={profile}
          linker={createLinkTo}
          onClose={onCloseMenu}
          onLogout={onLogout}
        />

        <FBTPopover
          anchor={fbtAnchor}
          open={isOpen('fbt')}
          linker={createLinkTo}
          onClose={onCloseMenu}
        />

        <ActionsPopover
          anchor={actionsAnchor}
          open={isOpen('actions')}
          linker={createLinkTo}
          onClose={onCloseMenu}
        />

        {/* <NotificationsPopover
          anchor={notificationsAnchor}
          notifications={notificationList}
          open={isOpen('notifications')}
          linker={createLinkTo}
          onClose={onCloseMenu} /> */}
      </Toolbar>

      {showIsPortalReadOnlyMessage ? (
        <Ribbon text="PORTAL IS DOWN FOR MAINTENANCE: Please note, the Fleet Portal is in read-only mode" />
      ) :
        showAlfaOutageMessage && (
          <Ribbon text="READ-ONLY: Please note, the Fleet Portal is in read-only mode between the hours of 6pm and 6am." />
        )
      }
    </div>
  )
}

TopBarDesktop.propTypes = {
  showAlfaOutageMessage: PropTypes.bool,
  showIsPortalReadOnlyMessage: PropTypes.bool,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default TopBarDesktop
