import React, { useEffect, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import { useAuth, AuthRoute } from 'auth'
import { PageLoader, Page404 } from 'frame/components'
import { useSettings } from 'features/settings'
import { Security } from 'features/account/components'
import { Layout } from 'frame'
import { connect } from 'react-redux'
import uuid from 'uuid/v4'
import { getEnabledFeatureToggleListRequest, useRouter } from 'utils'

import { makeStyles } from '@material-ui/styles'
import { Typography } from '@material-ui/core'

import {
  // AUTH_LOGIN_PATH,
  AUTH_LOGOUT_PATH,
  AUTH_VERIFICATION_PATH,
  AUTH_DRIVER_VERIFICATION_PATH,
  // LoginRoute,
  LogoutRoute,
  VerificationRoute,
  VerificationDriverRoute
} from 'features/auth'

import { ADMIN_PATH, AdminRoute } from 'features/admin'

import { HOMEPAGE_PATH, HomePageRoutes } from 'features/homepage'
import { DASHBOARD_PATH, DashboardRoutes } from 'features/dashboard'

import { constants as fromAccount, ModuleRoutes as AccountRoute } from 'features/account'
import { constants as fromDrivers, ModuleRoutes as DriverRoute } from 'features/drivers'
import { constants as fromFleet, ModuleRoutes as FleetRoute } from 'features/fleet'

import { REPORTS_PATH, ReportsRoute } from 'features/report'
import { FBT_PATH, FBTRoutes } from 'features/fbt'
import { FBT_WEB_FORM_PATH, ModuleRoutes as FbtWebFormRoutes } from 'features/fbt-web-form'

import { constants as fromRequestReliefVehicles, ModuleRoutes as ReliefVehicleRoute } from 'features/request-relief-vehicles'
import { constants as fromRequestVehicleRelocations, ModuleRoutes as RequestVehicleRelocationRoute } from 'features/request-vehicle-relocations'
import { constants as fromRequestContractVariations, ModuleRoutes as RequestContractVariationRoute } from 'features/request-contract-variations'
import { constants as fromRequestVehicleSummary, ModuleRoutes as RequestVehicleSummary } from 'features/request-vehicle-summary'

import { MARKETING_PAGE_PATH, REGISTER_INTEREST_PAGE_PATH, MarketingPage } from 'features/marketing-page'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    position: 'relative',
    marginTop: 40,
    padding: '20px',
    '@media(min-width: 400px)': {
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 100,
    },
  },
  lists: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingTop: '20px',
    width: '100%',
    maxWidth: '400px',
    '@media(min-width: 400px)': {
      flexDirection: 'row',
      justifyContent: 'space-between',
      maxWidth: '360px',
    },
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    margin: 0,
    width: '100%',
    '@media(min-width: 400px)': {
      width: 'auto',
      minWidth: '30%',
    },
  },
  graphic: {
    display: 'flex',
    width: '100%',
    maxWidth: '740px',
  },
  title: {
    marginTop: '40px',
    marginBottom: '15px',
    color: theme.palette.text.primary,
    fontSize: 20,
    fontStyle: 'italic',
    '@media(min-width: 400px)': {
      fontSize: 40,
      textAlign: 'center',
    },
  },
  subtitle: {
    fontSize: '14px',
    '@media(min-width: 400px)': {
      fontSize: '18px',
      textAlign: 'center',
    },
  },
  link: {
    padding: theme.spacing(0.2, 0),
    ...theme.typography.body1,
    color: theme.palette.text.link,
    fontSize: '14px',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
    '@media(min-width: 400px)': {
      padding: theme.spacing(0.4, 0),
      fontSize: '16px',
    },
  },
}))

const ChangePassword = () => {
  return (<Layout><Security /></Layout>)
}

const Routes = ({ fleetsReceived, initialized }) => {

  const classes = useStyles()  
  const { history } = useRouter()
  
  const forceLogoutTrigger = localStorage.getItem('FORCE_LOGOUT_ALL_SESSIONS')

  const [fetchingFeatureToggles, setFetchingFeatureToggles] = useState(false)
  const [showFbtWebForm, setFbtWebForm] = useState(false)

  useEffect(() => {
    if(forceLogoutTrigger) {
      console.log('[forceLogout]:', forceLogoutTrigger)
      // localStorage.clear()
      const isLocal = window.location.href.includes('local')
      !isLocal && history.push('/logout')
    }// eslint-disable-next-line
  }, [forceLogoutTrigger])
  
  useEffect(() => {
    // fetch enabled feature toggles on page load to handle unauthenticated webform access while respecting FbtWebForm feature toggle
    const fetchFeatureToggles = async () => {
      setFetchingFeatureToggles(true)
      const features = await getEnabledFeatureToggleListRequest()
      const FbtWebFormFeatureEnabled = features && features.filter(f => f.feature === 'FbtWebForm')
      FbtWebFormFeatureEnabled && setFbtWebForm(FbtWebFormFeatureEnabled.length > 0)
      setFetchingFeatureToggles(false)
    }
    fetchFeatureToggles()
  }, [])

  const { loading: authLoading } = useAuth()
  const { loading: accountLoading } = useSettings()

  if (authLoading || accountLoading || fetchingFeatureToggles) {
    return <PageLoader />
  }

  if (!fleetsReceived && initialized) {

    let reloadLink = `?reload=${uuid()}`
    return <Layout>
      <Switch>
        <Route path={AUTH_LOGOUT_PATH} component={LogoutRoute} />
      </Switch>
      <div className={classes.root}>
        <div className={classes.content}>
          <Typography
            gutterBottom
            className={classes.title}
            variant="h1">
            Application Error
        </Typography>
          <Typography
            gutterBottom
            className={classes.subtitle}
            variant="body1">
            Apologies, we were unable to connect, <a href={reloadLink} title="Retry">click here to retry</a>.
        </Typography>
          <Typography
            gutterBottom
            className={classes.subtitle}
            variant="body1">
            Alternatively, please <a href='/logout' title="Logout">logout</a> and sign-in again.
        </Typography>
          <Typography
            gutterBottom
            variant="body1">
            If the problem persists make sure your internet connection is active, or contact <a href="mailto:fleet.operations@tfs.co.nz">fleet.operations@tfs.co.nz</a>
          </Typography>
        </div>
      </div>
    </Layout>
  }

  return (
    <Switch>
      {/* Authentication Pages */}
      {/* <Route path={AUTH_LOGIN_PATH} component={LoginRoute} /> */}
      <Route path={AUTH_LOGOUT_PATH} component={LogoutRoute} />
      <Route path={AUTH_VERIFICATION_PATH} component={VerificationRoute} />
      <Route path={AUTH_DRIVER_VERIFICATION_PATH} component={VerificationDriverRoute} />
      
      {/* Public Pages */}
      <Route path={MARKETING_PAGE_PATH} component={() => <MarketingPage />} />
      <Route path={REGISTER_INTEREST_PAGE_PATH} component={() => <MarketingPage showForm />} />
      
      {showFbtWebForm ? (
        <Route path={FBT_WEB_FORM_PATH} component={FbtWebFormRoutes} />
      ) : (
        <Route path={FBT_WEB_FORM_PATH} component={() => <Page404 publicPage />} />
      )}

      {/* Portal Pages */}
      <AuthRoute path={fromAccount.PATH_ALL} component={AccountRoute} />
      <AuthRoute path='/changepassword' component={ChangePassword} />
      <AuthRoute path={fromFleet.PATH_ALL} component={FleetRoute} />
      <AuthRoute path={fromDrivers.PATH_ALL} component={DriverRoute} />
      <AuthRoute path={fromRequestReliefVehicles.PATH_ALL} component={ReliefVehicleRoute} />
      <AuthRoute path={fromRequestVehicleRelocations.PATH_ALL} component={RequestVehicleRelocationRoute} />
      <AuthRoute path={fromRequestContractVariations.PATH_ALL} component={RequestContractVariationRoute} />
      <AuthRoute path={fromRequestVehicleSummary.PATH_ALL} component={RequestVehicleSummary} />
      <AuthRoute path={REPORTS_PATH} component={ReportsRoute} />
      <AuthRoute path={FBT_PATH} component={FBTRoutes} />
      <AuthRoute path={HOMEPAGE_PATH} component={HomePageRoutes} />
      <AuthRoute path={DASHBOARD_PATH} component={DashboardRoutes} />
      <AuthRoute path={ADMIN_PATH} component={AdminRoute} />
      <AuthRoute path={'/'} exact component={HomePageRoutes} />
      <AuthRoute component={Page404} />
    </Switch>
  )
}

const mapStateToProps = ({ settings }) => ({
  fleetsReceived: settings.fleetsReceived,
  initialized: settings.initialized,
})

const mapDispatchToProps = {}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Routes)