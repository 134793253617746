import moment from 'moment'
import { combineEpics } from 'redux-observable'
import { createListable } from 'listable'
import options from './config'
import { createEffects } from './effects'
import * as customActions from './actions'
import { moduleName } from './actions/types'
import customReducer from './reducer'


const listableModule = createListable({
  name: moduleName,
  config: options,
  customActions,
  customReducer,
})

export const config = listableModule.options
export const actions = listableModule.actions

export const effects = combineEpics(
  createEffects(listableModule.actions),
  listableModule.effects,
)

export const name = listableModule.name
export const reducer = listableModule.reducer
export const types = listableModule.types

export const constants = {
  ...listableModule.constants,
  PATH_ALL: `/contract-variations`,
  ROUTE_ALL: `/contract-variations`,

  PATH_DRILLDOWN: `/contract-variations`,
  ROUTE_DRILLDOWN: `/contract-variations/:id/`,

  ROUTE_CONTACT: `/contract-variations/:id/contract-details`,
  ROUTE_DRIVER: `/contract-variations/:id/driver-details`,
  ROUTE_VEHICLE: `/contract-variations/:id/service-history`,
  ROUTE_VARIATIONS: `/contract-variations/:id/variations`,
  ROUTE_SERVICE_BOOKINGS: `/contract-variations/:id/service-bookings`,
  ROUTE_FBT: `/contract-variations/:id/fbt`,
}


export const createDefaultModel = () => ({
  effectiveDate: moment().toISOString(),
  status: 'requested',
})
