import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Menu, MenuItem } from '@material-ui/core'

import { constants as fromReportEndOfLease } from 'features/report-end-of-lease'
import { constants as fromReportExtensionRentals } from 'features/report-extension-rentals'
import { constants as fromReportKmTracking } from 'features/report-km-tracking'
import { constants as fromReportMaturities } from 'features/report-upcoming-maturities'
import { constants as fromReportServicing } from 'features/report-vehicle-servicing'
import { constants as fromReportVehiclesOnOrder } from 'features/report-vehicles-on-order'
import { constants as fromReportWofRego } from 'features/report-wof-rego'
import { constants as fromReportIncidentManagement} from 'features/report-incident-management'
import { constants as fromDocuments } from 'features/report-documents'
import { useEnabledFeatureToggleList } from 'features/settings'


const useStyles = makeStyles(theme => ({
  root: {
    width: 250,
    maxWidth: '100%',
  },
}))

const ActionsPopover = ({ anchor, linker, open, onClose }) => {
  const featureToggleList = useEnabledFeatureToggleList()
  const documentsReportFeatureToggle = featureToggleList.includes('DocumentsViewing')

  const classes = useStyles()

  if ( ! anchor.current) {
    return null
  }

  return (
    <Menu
      keepMounted
      id="menu-reports"
      anchorEl={anchor.current}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open}
      onClose={onClose}>
      <div className={classes.root}>
        <MenuItem onClick={linker(fromReportMaturities.PATH_ALL)}>
          Upcoming Maturities
        </MenuItem>
        <MenuItem onClick={linker(fromReportVehiclesOnOrder.PATH_ALL)}>
          Vehicles On-Order
        </MenuItem>
        <MenuItem onClick={linker(fromReportServicing.PATH_ALL)}>
          Vehicle Servicing
        </MenuItem>
        <MenuItem onClick={linker(fromReportKmTracking.PATH_ALL)}>
          KM Tracking
        </MenuItem>
        <MenuItem onClick={linker(fromReportWofRego.PATH_ALL)}>
          WoF & Registration
        </MenuItem>
        <MenuItem onClick={linker(fromReportExtensionRentals.PATH_ALL)}>
          Extension Rentals
        </MenuItem>
        <MenuItem onClick={linker(fromReportEndOfLease.PATH_ALL)}>
          End of Lease
        </MenuItem>
        <MenuItem onClick={linker(fromReportIncidentManagement.PATH_ALL)}>
          Incident Management
        </MenuItem>
        {documentsReportFeatureToggle && (
          <MenuItem onClick={linker(fromDocuments.PATH_ALL)}>
            Documents
          </MenuItem>
        )}
      </div>
    </Menu>
  )
}

ActionsPopover.propTypes = {
  anchor: PropTypes.object,
  linker: PropTypes.func.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
}

export default ActionsPopover
