import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import { Grid, Card, CardContent, Button, Typography } from '@material-ui/core'
import { Alert, StyledModal } from 'frame/components'
import { useNavigate, useFbtWebForm } from 'utils'
import { constants } from './module'


const useStyles = makeStyles(theme => ({
  heading: { fontWeight: 'bold' },
  card: {
    margin: theme.spacing(5, 0),
    paddingTop: theme.spacing(5),
    paddingBottom: '300px',
    width: 960,
    [theme.breakpoints.down(960)]: {
      width: '100%',
      margin: '20px 10px 0 10px'
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'left',
      display: 'block',
    }
  },
  button: {
    borderRadius: 25,
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    minWidth: 180,
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
    }
  },
}))

const FBTWebForm = () => {

  const classes = useStyles()
  const navigate = useNavigate()
  const { onSubmitFbtWebForm } = useFbtWebForm()
  const fbtWebForm = useSelector(state => state?.fbtSubmissions?.fbtWebForm)

  const [showConfirmSubmitModal, setShowConfirmationSubmitModal] = useState(false)

  const onProceed = navigate(constants.ROUTE_ADD_SUBMISSION)// show calender view

  const onSubmit = () => {
    const payload = {
      token: fbtWebForm?.token,
      email: fbtWebForm?.email,
      availableForPvtUseAllMonth: true,
      entries: [],
    }
    console.log('[onAddFbtSubmission]: ', payload)
    onSubmitFbtWebForm(payload) // for all days of the month
    setShowConfirmationSubmitModal(false)
  }

  return (
    <>
      <Card className={classes.card}>
        <CardContent>
          {!fbtWebForm?.token ? ( // to safeguard this view if no token is set in state
          <Alert severity="error">
            Invalid token. This FBT Submissions link is invalid or has been expired
          </Alert>
          ) : (
            <Grid container spacing={3}>
              <Grid xs={12} item>
                <Typography gutterBottom variant="h4" align="center" className={classes.heading}>
                  Fringe Benefit Tax
                </Typography>
                <Typography gutterBottom variant="body1" align="center">
                  Was your vehicle available for private use for <strong>all days of last</strong> month?
                </Typography>
              </Grid>

              <Grid xs={12} item className={classes.buttons}>
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.button}
                  onClick={() => setShowConfirmationSubmitModal(true)}>
                  Yes
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.button}
                  onClick={onProceed}>
                  No
                </Button>
              </Grid>
            </Grid>
          )}
        </CardContent>
      </Card>

      {/* Fbt Submit for all days - Confirmations Modal */}
      <StyledModal
        open={showConfirmSubmitModal}
        title={``}
        onCancelLabel="Go Back"
        onCancel={() => setShowConfirmationSubmitModal(false)}
        onConfirmLabel="Submit"
        onConfirm={onSubmit}>
        Please confirm you have completed your submission and your vehicle was available for private use for all days of last month. 
        <br /><br />
        Once you click Submit, you cannot return to this form or view your submission again.
      </StyledModal>
    </>
  )
}

export default FBTWebForm