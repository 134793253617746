import { of } from 'rxjs'
import { ajax } from 'rxjs/ajax'
import { mergeMap, switchMap, catchError, flatMap, retry } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import { getAuthClient } from 'auth'
import Cookies from 'js-cookie'

/*
 * Get Auth Headers
 * Gets the authentication bearer token from the Auth0 client
 * which is needed for authorised requests.
 * It also ensures the action payload is passed along to
 * subsequent calls.
 */
const createAuthHeaderRequest = (debug) => async (action) => {

  const authClient = getAuthClient()

  let accessToken
  let auth0Cypress = Cookies.get('auth0.cypress')
  if (auth0Cypress && auth0Cypress === 'true') {
    accessToken = Cookies.get('auth0.cypress.token')
  } else {
    accessToken = await authClient.getTokenSilently()
  }

  if (debug) {
    const authClientAvailable = authClient
      ? 'available'
      : 'unavailable'
    console.log('[debug - auth client]', authClientAvailable) // eslint-disable-line
    console.log('[debug - auth token]', accessToken) // eslint-disable-line
  }

  return {
    payload: action?.payload || null,
    options:  action?.options || {},
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json; charset=UTF-8',
    }
  }
}

const defaultSuccessHandler = (payload = {}, options) => ({
  type: 'request_success',
  payload,
  options,
})

const defaultErrorHandler = (payload = {}, options) => ({
  type: 'request_error',
  payload,
  options,
})

const sanitizePayload = (payload) =>
  payload

/*
 * Get URL
 * Gets the url to send the request to and if needed uses a proxy
 * to get around CORS issues (used when developing locally).
 * If an ID is present in the payload it will append it to the url
 * which matches the API design for this project.
 */
const getUrl = (url, payload, options, proxy) => {

  let resourceId = (options?.resourceId || options?.resourceId === 0)
    ? `/${options?.resourceId}`
    : ''

  let queryParams = ''

  if (options?.queryParams) {
    const params = Object.keys(options.queryParams)
      .map(key => {
        const value = options.queryParams[key]
        return `${key}=${encodeURIComponent(value)}`
      })
      .join('&')
    queryParams = `?${params}`
  }

  return proxy
    ? `http://localhost:4040/proxy/${url}${resourceId}${queryParams}`
    : `${url}${resourceId}${queryParams}`
}

/*
 * Creates a Request effect for Redux Observable
 *
 * First it will get the auth headers required for the API.
 * Then it will calculate the final url and make the request
 * based upon the verb provided i.e. get, post, put, delete etc.
 * the default being 'get'.
 * It can take a success and error action creator to be dispatched
 * accordingly. If provided it will also dispatch the 'requestId'
 * with the subsequent payloads which can be useful for tracking
 * individual requests and updating the corresponding UI.
 *
 * If you are having issue pass in 'debug: true' or 'debugAuth: true'
 * to get access to additional logging during development.
 */
export const makeRequestEffect = ({
  url,
  headers: defaultHeaders = {},
  onSuccess = defaultSuccessHandler,
  onError = defaultErrorHandler,
  debug,
  debugAuth,
  proxy,
  verb = 'get',
  state, // equalivant to state$
  mapOperator = mergeMap,
}) => ([
  flatMap(createAuthHeaderRequest(debugAuth)),
  mapOperator(
    ({ headers, payload, options }) =>
      ajax({
        url: getUrl(url, payload, options, proxy),
        method: verb,
        headers: {
          ...defaultHeaders,
          ...headers,
        },
        body: sanitizePayload(payload || {}),
        timeout: 3 * 60 * 1000, // 3 minute
      })
        .pipe(
          mapOperator(({ response }) => {

            if (debug) {
              console.log('[debug (api response)]', JSON.stringify(response, null, 2)) // eslint-disable-line
            }

            // Advanced error handling where we inspect the response to determine
            // if there was a data related API error.
            if (response?.apiResponse?.success === false || response?.success === false
                || response?.data === null) {
              return of(onError(response?.apiResponse?.message || response?.message || 'An API data related error occured.', options))
            }

            const data = (response && response.data) || response

            if (typeof onSuccess === 'string') {
              return of({
                type: onSuccess,
                payload: data,
                options,
                response,
              })
            }
            return of(onSuccess(data, options, response))
          }),
          catchError(error => {
            const err = (error && error.message) || error

            if (debug) {
              console.error('[debug (api error)]', err) // eslint-disable-line
            }

            // rollbar logs
            if(window.location.host === 'toyotafleet.app') { // if prod env
              console.log('')
              const userEmail = state?.value?.account?.profile?.email
              const selectedFleets = state?.value?.settings?.fleet?.selected
              window.onerror(`[makeRequestEffect error] ${JSON.stringify({ userEmail, selectedFleets, url, payload, error: err })}`)
            }
            return of(onError(err, options))
          })
        ),
  ),
])

/*
 * Creates a Redux Observable Epic
 * that runs a ajax request and maps the response
 * to appropriate handlers.
 */
export const makeActionRequestEffect = ({ type, mapOperator, ...rest }) =>
  (action$, state$) =>
    action$.pipe(
      ofType(type),
      ...makeRequestEffect({
        ...rest,
        state: state$,
        /*
          use switch map (unless specified) for all effects for cancelling any previous effect of the same type that are still in progress,
          mergeMap will stack all effects and lets them all finish
        */
        mapOperator: mapOperator || switchMap // type.includes('lookup') ? switchMap : mergeMap
      }),
    )


export const makeDownloadRequestEffect = ({
  state$,
  moduleName,
  url,
  headers: defaultHeaders = {},
  onSuccess = defaultSuccessHandler,
  onError = defaultErrorHandler,
  debugAuth,
  verb = 'get',
}) => ([
  flatMap(createAuthHeaderRequest(debugAuth)),
  mergeMap(
    ({ headers, payload }) => {

      console.log('makeDownloadRequestEffect headers', headers)

      headers = {
        ...headers,
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'Accept': 'application/json, text/plain, */*',
        // responseType: 'blob'
      }

      console.log('makeDownloadRequestEffect headers2', headers)

      const { columns, selected, fleetId, title } = payload
      let params = '?'
      let moduleState = state$.value[moduleName]

      let columnData = Object.entries(columns)
      let filteredEntries = Object.entries(moduleState.filtered)

      /* eslint-disable */
      if(selected)
        columnData = columnData.filter(([key, value]) => value)

      for (let [key, value] of columnData) {
        params += `columns=${key}&`
      }

      console.log('moduleState', moduleState)
      console.log('filteredEntries', filteredEntries)
      if (selected) {
        for (let [key, value] of filteredEntries) {
          if (value && (value.length > 0 || value === 1)) {

            switch(key) {
              case 'matured':
              case 'expired':
              case 'overdue':
                params += `${key}=${encodeURIComponent(value)}&`
                break;
              default:
                params += `selected${key}=${encodeURIComponent(value)}&`
                break;
            }
          }
        }

        let searchKey = moduleState.searchKey
        if(searchKey && searchKey.length > 0) {
          params += `search=${encodeURIComponent(searchKey)}&`
        }
        
        /*
          To differentiate current month filter from dashboard and report page
          dashboard - current month filter requires records from today onwards
          report page - current month filter requires all records from that month
        */
        if(moduleState.isDashboardFilter) {
          params += `showTodayOnwards=true&`
        }
      }

      /* eslint-enable */

      params += `fleetId=${fleetId}`

      console.log('params', params)

      console.log('makeDownloadRequestEffect defaultHeaders', defaultHeaders)

      var requestUrl = `${url}${params}`

      console.log('makeDownloadRequestEffect requestUrl', requestUrl)

      // window.open(requestUrl, '_blank')

      //return EMPTY//of(push(url))
      return ajax({
        url: requestUrl,
        method: verb,
        headers: {
          ...defaultHeaders,
          ...headers,
        },
        responseType: 'blob',
        // timeout: 10 * 1000,
      })
        .pipe(
          mergeMap((result) => handleDownloadRequest(result,onSuccess,title)),
          retry(1),
          catchError(error => {            
            const err = (error && error.message) || error

            // rollbar logs
            if(window.location.host === 'toyotafleet.app') { // if prod env
              const userEmail = state$.value?.account?.profile?.email
              const selectedFleets = state$.value?.settings?.fleet?.selected
              window.onerror(`[makeRequestEffect error] ${JSON.stringify({ userEmail, selectedFleets, url, payload, error: err })}`)
            }
            return of(onError(err))
          })
        )
    }
  ),
])

export const makeSimpleDownloadRequestEffect = ({
  state$,
  url,
  headers: defaultHeaders = {},
  onSuccess = defaultSuccessHandler,
  onError = defaultErrorHandler,
  proxy,
  debugAuth,
  verb = 'get',
}) => ([
  flatMap(createAuthHeaderRequest(debugAuth)),
  mergeMap(
    ({ headers, payload, options }) =>{
      headers = {
        ...headers,
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'Accept': 'application/json, text/plain, */*',
      }
      var requestUrl = getUrl(url, payload, options, proxy)
      const title = options.title

      return ajax({
        url: requestUrl,
        method: verb,
        headers: {
          ...defaultHeaders,
          ...headers,
        },
        responseType: 'blob',
      })
        .pipe(
          mergeMap((result) => handleDownloadRequest(result,onSuccess,title)),
          retry(1),
          catchError(error => {            
            const err = (error && error.message) || error
            if(window.location.host === 'toyotafleet.app') { // if prod env
              const userEmail = state$.value?.account?.profile?.email
              const selectedFleets = state$.value?.settings?.fleet?.selected
              window.onerror(`[makeRequestEffect error] ${JSON.stringify({ userEmail, selectedFleets, url, payload, error: err })}`)
            }
            return of(onError(err))
          })
        )
    }
  ),
])

export const handleDownloadRequest = (result, onSuccess, title) => {
  let { response } = result
  const data = (response && response.data) || response
  let exportedFilenmae = title + '.xlsx' || 'export.xlsx'
  let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })// 'text/csv;charset=utf-8;' })
  if (navigator.msSaveBlob) {
    // For IE 10+
    navigator.msSaveBlob(blob, exportedFilenmae)
  } else {
    // force download link
    let link = document.createElement('a')
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      let url = URL.createObjectURL(blob)
      link.setAttribute('href', url)
      link.setAttribute('download', exportedFilenmae)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
  return of(onSuccess({ data, title }))
}

