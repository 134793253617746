import moment from 'moment'

export const isOutOfSync = (record) => {

  let inSync = false

  switch(record.wofStatus) {
    case 'Overdue':
      if(moment(record.wofExpiry).isBefore(moment().format('YYYY-MM-DD'), 'day')) {

        inSync = true
      }
      break;
    case 'Due':
      if(moment(record.wofExpiry).isSame(moment().format('YYYY-MM-DD'), 'day')
          || (moment(record.wofExpiry).isAfter(moment().format('YYYY-MM-DD'), 'day')
              && moment(record.wofExpiry).isBefore(moment().add(1, 'months'), 'day'))) {

        inSync = true
      }
      break;
    case 'Due Soon':
      if(moment(record.wofExpiry).isSame(moment().add(1, 'months'), 'day')
          || (moment(record.wofExpiry) > moment().add(1, 'months')
              && moment(record.wofExpiry) < moment().add(2, 'months'), 'day')) {

        inSync = true
      }
      break;
    case 'Valid':
      if(moment(record.wofExpiry).isSame(moment().format('YYYY-MM-DD').add(2, 'months'), 'day')
          || moment(record.wofExpiry) > moment().add(2, 'months')) {

        inSync = true
      }
      break;
    default:
      console.log(`weird record: `, record)
      break;
  }
  return inSync
}