import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { TableCell, TableRow, Typography, Checkbox } from '@material-ui/core'
import { createRowSelector } from 'utils'
import RowActions from './row-options'
import { types } from '../../module'
import { capitalize } from 'utils'
import { FleetIcon, Label } from 'frame/components'
import { getDriverStatusColor } from 'listable'
import { useEnabledFeatureToggleList } from 'features/settings'
import { useIsAlfaDownDisableFeatures } from 'features/drivers'
import moment from 'moment'


const useStyles = makeStyles(theme => ({
  root: {
    cursor: 'pointer',
    verticalAlign: 'text-top',
  },
  centerCell: {
    display: 'flex',
    justifyContent: 'center',
  },
  label: theme.typography.bodySmall,
  cell: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  spacer: {
    margin: 0,
    padding: 0,
  },
}))

// columns and selected props is passed from src\listable\components\list.js line 197
const Row = ({ item, columns, selected, onView, onTerminate, onSelectRow,userRoles }) => {

  const classes = useStyles()

  const featureToggleList = useEnabledFeatureToggleList()
  const disableOutOfHoursFeatureToggle = featureToggleList.includes('OutOfHoursDisabled') // 'ALFA out of hours DISABLE FEATURES' limitations feature is ON
  const isAlfaDownDisableDriverFeatures = useIsAlfaDownDisableFeatures()
  const isAlfaDown = disableOutOfHoursFeatureToggle ? false : isAlfaDownDisableDriverFeatures

  const [checkboxValue, setCheckboxValue] = useState(false)

  const onSafeRowClick = createRowSelector(onView)

  const isTerminated = item.terminated

  const onSelect = (uniqueId) => ({ target: { checked } }) => {
    setCheckboxValue(checked)
    let updatedChecked = selected
    if(checked) { // add to checked list state
      updatedChecked.push(uniqueId)
      onSelectRow(updatedChecked)
    } else { // removed from checked list state
      updatedChecked = updatedChecked && updatedChecked.filter(id => id !== uniqueId)
      onSelectRow(updatedChecked)
    }
  }

  useEffect(() => {
    setCheckboxValue(selected && selected.includes(item.uniqueId))
    // eslint-disable-next-line
  }, [selected])

  const disabledInvite = item.status === 'Terminated' || item.activated === 'Verified'

  return (
    <TableRow hover className={classes.root} onClick={onSafeRowClick}>
      <TableCell className={classes.spacer} />

      {columns.accountName && (
        <TableCell className={classes.label}>
          <FleetIcon fleetName={item.accountName} />
        </TableCell>
      )}

      {/* {columns.agreementNo && (
        <TableCell className={classes.label}>
          {item.agreementNo || '-'}
        </TableCell>
      )} */}

      {columns.driver && (
        <TableCell className={classes.label}>
          <Typography color="inherit" variant="body2" className={classes.cell} style={{ maxWidth: 170 }}>{/* 30 less than column size */}
            {item.driver || '-'}
          </Typography>
        </TableCell>
      )}

      {columns.email && (
        <TableCell className={classes.label}>
          <Typography color="inherit" variant="body2" className={classes.cell} style={{ maxWidth: 190 }}>
            {item.email || '-'}
            {/* {item.email ? (
              <a className="ignore" href={`mailto:${item.email}`}>
                {item.email}
              </a>
            ) : (
              '-'
            )} */}
          </Typography>
        </TableCell>
      )}

      {columns.mobile && (
        <TableCell className={classes.label}>
          <Typography color="inherit" variant="body2" className={classes.cell} style={{ maxWidth: 120 }}>
            {item.mobile || '-'}
          </Typography>
        </TableCell>
      )}
      {columns.costCentre && (
        <TableCell className={classes.label}>
          <Typography color="inherit" variant="body2" className={classes.cell} style={{ maxWidth: 90 }}>
            {!isTerminated && item.costCentre ? item.costCentre : '-'}
          </Typography>
        </TableCell>
      )}

      {columns.registration && (
        <TableCell className={classes.label}>
          {item.registration || '-'}
        </TableCell>
      )}
      {columns.location && (
        <TableCell className={classes.label}>
          {item.location || 'Unknown'}
        </TableCell>
      )}

      {columns.controller && (
        <TableCell className={classes.label}>
          <Typography color="inherit" variant="body2" className={classes.cell} style={{ maxWidth: 170 }}>
            {!isTerminated && item.controller ? item.controller : '-'}
          </Typography>
        </TableCell>
      )}

      {columns.vehicleNotes && (
        <TableCell className={classes.label}>
          <Typography color="inherit" variant="body2" className={classes.cell} style={{ maxWidth: 230 }}>
            {!isTerminated && item.vehicleNotes ? item.vehicleNotes : '-'}
          </Typography>
        </TableCell>
      )}

      {columns.driverNotification && (
        <TableCell className={classes.label}>
          {item.driverNotification && item.driverNotification === 'n/a'
            ? 'N/A'
            : item.driverNotification ? capitalize(item.driverNotification) : '-'}
        </TableCell>
      )}

      {columns.status && (
        <TableCell className={classes.label}>
          <div className={classes.centerCell}>
            <Label
              color={getDriverStatusColor(item.status)}
              variant="outlined">
              {item.status || '-'}
            </Label>
          </div>
        </TableCell>
      )}

      {/* activated values: None, Unverified, Verified */}
      {columns.activated && (
        <TableCell className={classes.label}>
          <div className={classes.centerCell}>
            <Label
              color={getDriverStatusColor(item.activated)}
              variant="outlined">
              {item.activated || '-'}
            </Label>
          </div>
        </TableCell>
      )}
      
      {/* invited values: None, Expired, Invited */}
      {columns.invited && (
        <TableCell className={classes.label}>
          <div className={classes.centerCell}>
            <Label
              color={getDriverStatusColor(item.invited)}
              variant="outlined">
              {item.invited || '-'}
            </Label>
          </div>
        </TableCell>
      )}

      {columns.lastLogin && (
        <TableCell className={classes.label}>
          {item.lastLogin ? moment(item.lastLogin).format('Do MMMM YYYY h:mm A') : 'never'}
        </TableCell>
      )}
      
      <TableCell align="right" style={{ minWidth: 110 }}>
        <Checkbox
          className="ignore"
          color="default"
          checked={checkboxValue}
          onChange={onSelect(item.uniqueId)}
          disabled={disabledInvite}
        />

        <RowActions
          className="ignore"
          onView={onView}
          onTerminate={item.status !== 'Terminated' && !isAlfaDown ? onTerminate : null}
          driverName={item.driver}
          userRoles={userRoles}
        />
      </TableCell>
    </TableRow>
  )
}

Row.propTypes = {
  item: types.ModelType.isRequired,
  columns: types.ColumnsType.isRequired,
  selected: PropTypes.array.isRequired,
  onView: PropTypes.func.isRequired,
  onTerminate: PropTypes.func.isRequired,
  onSelectRow: PropTypes.func.isRequired,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,

}

export default Row
