import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, useTheme } from '@material-ui/styles'
import PropTypes from 'prop-types'
import { TextField, Grid } from '@material-ui/core'
import { MobileDateRangePicker } from '@material-ui/pickers'


const useStyles = makeStyles(theme => ({
  dateInputFields: {
    margin: 0,
    padding: 0,
    '& > div': {
      margin: 0,
      padding: '10px',
      borderRadius: '4px',
      '&::before': {
        display: 'none',
      },
      '&::after': {
        display: 'none',
      },
    },
    '& input': {
      margin: 0,
      padding: 0,
      border: 'none',
      color: 'green',
    },
  },
  startDate: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: props => props.showStartDateRequiredError ? theme.palette.error.main : '#C3C3C3',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: props => props.showStartDateRequiredError ? theme.palette.error.main : '#C3C3C3',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: props => props.showStartDateRequiredError ? theme.palette.error.main : '#C3C3C3',
    },
    [theme.breakpoints.up('xs')]: {
      paddingLeft: '3px',
      marginLeft: '-2px',
    }
  },
  endDate: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: props => props.showEndDateRequiredError ? theme.palette.error.main : '#C3C3C3',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: props => props.showEndDateRequiredError ? theme.palette.error.main : '#C3C3C3',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: props => props.showEndDateRequiredError ? theme.palette.error.main : '#C3C3C3',
    },
    paddingLeft: '3px',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '-2px',
    }
  },
  errorText: {
    color: '#e53935',
    fontSize: '11px',
    marginTop: '3px',
    marginLeft: '15px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    lineHeight: '13px',
    letterSpacing: '0.33px',
  },
}))

// use in add/edit relief vehicle form
const CustomDateRangePicker = ({
  disabled,
  tight,
  format,
  disabledPastDates,
  disableFutureDates,
  values,
  startMinDate,
  startDateInputSize,
  endMaxDate,
  endDateInputSize,
  onDateRangeChange,
  showStartDateRequiredError,
  showEndDateRequiredError,
  placeholderText,
  allowSameDateSelection,
}) => {

  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'))

  const classes = useStyles({ showStartDateRequiredError, showEndDateRequiredError })
  const [value, setValue] = useState(values)
  const [showIsSameError, setIsSameError] = useState(false)
  
  const onChange = (value) => {
    // console.log('DateRangePicker onChange ', { value, start: moment(value[0]).format('DD/MM/YY'), end: moment(value[1]).format('DD/MM/YY') })
    setValue(value)
    const startDate = value[0] && value[0] !== 'Invalid date' ? value[0] : null
    const endDate = value[1] && value[1] !== 'Invalid date' ? value[1] : null
    onDateRangeChange(startDate, endDate)
  }

  const startDateInputStyle = startDateInputSize ? { maxWidth: startDateInputSize } : null
  const endDateInputStyle = endDateInputSize ? { maxWidth: endDateInputSize } : null

  const onError = (value) => {
    console.log('onError', value)
    if(!allowSameDateSelection && value && (value[0] === 'invalidRange' || value[1] === 'invalidRange')) {
      setIsSameError(true)
    } else {      
      setIsSameError(null)
    }
  }

  useEffect(() => {
    let startDatePlaceholder = document.getElementById('startDate')
    let endDatePlaceholder = document.getElementById('endDate')

    if(startDatePlaceholder && endDatePlaceholder && placeholderText) {
      startDatePlaceholder.placeholder = placeholderText
      endDatePlaceholder.placeholder = placeholderText
    }// eslint-disable-next-line
  }, [])

  return (
    <>
      <style scoped>
        {`
          .MuiPickersDateRangeDay-notSelectedDate.MuiPickersDateRangeDay-dayOutsideRangeInterval.Mui-disabled {
            color: rgba(0, 0, 0, 0.26);
          }
          .MuiPickersDateRangePickerToolbarProps-dateTextContainer > h5 {
            color: #FFFFFF;
          }
          .MuiTypography-root.MuiPickersToolbarText-root.MuiTypography-root.MuiTypography-h5 {
            color: #FFFFFF;
          }
          #startDate-label, #endDate-label {
            margin-top: ${tight && !mobileView ? '-10px' : '0'};
          }
          #startDate-helper-text, #endDate-helper-text {
            display: none;
          }
          .MuiPickersDateRangePickerToolbarProps-penIcon.MuiIconButton-colorInherit {
            display: none;
          }
          #startDate {
            color: ${disabled ? '#aaaaaa' : (showIsSameError && !allowSameDateSelection) || showStartDateRequiredError ? '#e53935' : '#263238'};
          }
          #endDate {
            color: ${disabled ? '#aaaaaa' : (showIsSameError && !allowSameDateSelection) || showEndDateRequiredError ? '#e53935' : '#263238'};
          }
          ${allowSameDateSelection && `input[id=startDate] + fieldset {
            border-color: ${showStartDateRequiredError ? theme.palette.error.main : '#C3C3C3 !important;'}
          }`}
          ${allowSameDateSelection && `input[id=endDate] + fieldset {
            border-color: ${showEndDateRequiredError ? theme.palette.error.main : '#C3C3C3 !important;'}
          }`}
        `}
      </style>
      <MobileDateRangePicker
        disabled={disabled}
        allowSameDateSelection={allowSameDateSelection}
        calendars={1}
        startText={tight && !mobileView ? '' : 'Start Date'}
        endText={tight && !mobileView ? '' : 'End Date'}
        inputFormat={format || "Do MMMM YYYY"}
        disablePast={disabledPastDates}
        disableFuture={disableFutureDates}
        minDate={startMinDate && startMinDate}
        maxDate={endMaxDate && endMaxDate}
        value={value}
        onChange={onChange}
        onError={onError}
        renderInput={(startProps, endProps) => (
          <Grid container spacing={tight ? 1 : 3} className={classes.inputArea}>
            <Grid item sm={6} xs={12}>
              <TextField
                fullWidth
                id="startDate"
                className={clsx(classes.startDate, tight ? classes.dateInputFields : '')}
                style={{ ...startDateInputStyle }}
                InputLabelProps={{ style: { // for placeholder color
                  color: disabled ? '#aaaaaa' : showStartDateRequiredError ? '#e53935' : '#263238'
                } }}
                {...startProps}
              />
              {showIsSameError && !allowSameDateSelection && (
                <p className={classes.errorText}>Start date cannot be same as end date</p>
              )}
              {!showIsSameError && showStartDateRequiredError && (
                <p className={classes.errorText}>This field is required</p>
              )}
            </Grid>

            {/* {!hideTo ? (
              <Grid item sm={2} xs={12}>
                <DateRangeDelimiter style={{ marginTop: tight ? '5px': '15px'}}> to </DateRangeDelimiter>
              </Grid>
            ) : <span style={{ minWidth: 35 }} />} */}

            <Grid item sm={6} xs={12}>
              <TextField
                fullWidth
                id="endDate"
                className={clsx(classes.endDate, tight ? classes.dateInputFields : '')}
                style={{ ...endDateInputStyle }}
                InputLabelProps={{ style: { // for placeholder color
                  color: disabled ? '#aaaaaa' : showEndDateRequiredError ? '#e53935' : '#263238'
                } }}
                {...endProps}
              />
              {showIsSameError && !allowSameDateSelection && (
                <p className={classes.errorText}>End date cannot be same as start date</p>
              )}
              {!showIsSameError && showEndDateRequiredError && (
                <p className={classes.errorText}>This field is required</p>
              )}
            </Grid>
            

          </Grid>
        )}
      />
    </>
  )
}

CustomDateRangePicker.propTypes = {
  disabled: PropTypes.bool,
  tight: PropTypes.bool,
  format: PropTypes.string,
  disabledPastDates: PropTypes.bool,
  disableFutureDates: PropTypes.bool,
  values: PropTypes.array,
  startMinDate: PropTypes.any,
  startDateInputSize: PropTypes.number,
  endMaxDate: PropTypes.any,
  endDateInputSize: PropTypes.number,
  onDateRangeChange: PropTypes.func,
  showStartRequiredError: PropTypes.bool,
  showEndDateRequiredError: PropTypes.bool,
  placeholderText: PropTypes.string,
  allowSameDateSelection: PropTypes.bool,
}

export default CustomDateRangePicker