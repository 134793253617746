import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Listable } from 'listable'
import Row from './row'
import { actions, config } from '../../module'
import { useIsWhiteListed } from 'utils'
// import { useProfile } from 'features/account'


const List = ({ model, onEnable, onDisable }) => {
  
  // const profile = useProfile()
  const isWhiteListedUser = useIsWhiteListed()

  const renderItem = useCallback((item, index, itemProps) => {
    return (
      <Row key={index} item={item} onEnable={onEnable} onDisable={onDisable} {...itemProps} />
    )// eslint-disable-next-line 
  }, [onEnable, onDisable])

  // Prevents re-rendering if ids or data updated - no longer needed
  const { ids, filters, ...rest } = model
  
  if(!isWhiteListedUser) {
    return null
  }

  return (
    <Listable
      actions={actions}
      config={config}
      model={rest}
      renderItem={renderItem}
      title="Feature Toggle"
    />
  )
}

List.propTypes = {
  model: PropTypes.object.isRequired,
  onEnable:PropTypes.func.isRequired,
  onDisable:PropTypes.func.isRequired,
}

const mapStateToProps = ({ featureToggles }) => ({
  model: featureToggles
})

const mapDispatchToProps = (dispatch) => ({
  onEnable: (payload) =>
    dispatch(actions.enableFeature(null, {
      queryParams: { feature: payload }
    })),
  onDisable: (payload) =>
    dispatch(actions.disableFeature(null, {
      queryParams: { feature: payload }
    })),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(List)
