import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { makeStyles } from '@material-ui/styles'
import { /*colors,*/ TableCell, TableRow, Typography } from '@material-ui/core'
import { getDateColor, getStatusColor } from 'listable'
import { capitalize, createRowSelector } from 'utils'
import { Label, FleetIcon } from 'frame/components'
import RowActions from './row-options'
import { types } from '../../module'
import { useEnabledFeatureToggleList } from 'features/settings'


const useStyles = makeStyles(theme => ({
  root: {
    cursor: 'pointer',
    verticalAlign: 'text-top',
  },
  centerCell: {
    display: 'flex',
    justifyContent: 'center',
  },
  label: theme.typography.bodySmall,
  spacer: {
    margin: 0,
    padding: 0,
  },
}))

const Row = ({ item, columns, onView }) => {

  const classes = useStyles()
  const onSafeRowClick = createRowSelector(onView)

  const featureToggleList = useEnabledFeatureToggleList()
  const tfp1944FeatureToggle = featureToggleList.includes('TFP1944')  

  return (
    <TableRow hover className={classes.root} onClick={onSafeRowClick}>
      <TableCell className={classes.spacer} />
      {columns.accountName && (
        <TableCell className={classes.label}>
          <FleetIcon fleetName={item.accountName} />
        </TableCell>
      )}
      {columns.agreementNo && (
        <TableCell className={classes.label}>
          {item.agreementNo || '-'}
        </TableCell>
      )}
      {columns.ordNumber && (
        <TableCell className={classes.label}>
          {item.ordNumber || '-'}
        </TableCell>
      )}
      {columns.registration && (
        <TableCell className={classes.label}>
          {item.registration || '-'}
        </TableCell>
      )}
      {columns.driverNote && tfp1944FeatureToggle && (
        <TableCell className={classes.label}>
          <Typography color="inherit" variant="body2">
            {item.driverNote || '-'}
          </Typography>
        </TableCell>
      )}      
      {columns.driver && (
        <TableCell className={classes.label}>
          <Typography color="inherit" variant="body2">
            {item.driver || '-'}
          </Typography>
        </TableCell>
      )}
      {columns.currentMaturityDate && (
        <TableCell className={classes.label}>
          <div className={classes.centerCell}>
            {item.currentMaturityDate ? (
              <Label
                color={getDateColor(item.currentMaturityDate)}
                variant="outlined">
                {moment(item.currentMaturityDate).format('DD/MM/YY')}
              </Label>
            ) : '-'}
          </div>
        </TableCell>
      )}
      {columns.maturityDate && (
        <TableCell className={classes.label}>
          <div className={classes.centerCell}>
            {item.maturityDate ? (
              <Label
                color={getDateColor(item.maturityDate)}
                variant="outlined">
                {moment(item.maturityDate).format('MMM YYYY')}
              </Label>
            ) : '-'}
          </div>
        </TableCell>
      )}
      {columns.currentNoOfTyres && (
        <TableCell className={classes.label}>
          <div className={classes.centerCell}>
            {item.currentNoOfTyres || '-'}
          </div>
        </TableCell>
      )}      
      {columns.noOfTyres && (
        <TableCell className={classes.label}>
          <div className={classes.centerCell}>
            {item.noOfTyres || '-'}
          </div>
        </TableCell>
      )}      
            {columns.currentContractKm && (
        <TableCell className={classes.label}>
          {item.currentContractKm ? parseInt(item.currentContractKm, 10).toLocaleString('en-NZ') : '-'}
        </TableCell>
      )}
      {columns.contractKm && (
        <TableCell className={classes.label}>
          {item.contractKm ? parseInt(item.contractKm, 10).toLocaleString('en-NZ') : '-'}
        </TableCell>
      )}
      {columns.status && (
        <TableCell className={classes.label}>
          <div className={classes.centerCell}>
          {item.status ? (
            <Label
              color={getStatusColor(item.status)}
              variant="outlined">
              {capitalize(item.status)}
            </Label>
          ) : '-'}
          </div>
        </TableCell>
      )}
      {columns.createdAt && (
        <TableCell className={classes.label}>
          <div className={classes.centerCell}>
            {item.createdAt ? (moment(item.createdAt).format('DD/MM/YY')) : '-'}
          </div>
        </TableCell>
      )}
      <TableCell align="right">
        <RowActions className="ignore" id={item.id} onView={onView} />
      </TableCell>
    </TableRow>
  )
}

Row.propTypes = {
  account: PropTypes.string,
  item: types.ModelType.isRequired,
  columns: types.ColumnsType.isRequired,
  onView: PropTypes.func.isRequired,
}

export default Row
