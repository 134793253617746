import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useDispatch } from 'react-redux'
import { Typography, Button, Grid } from '@material-ui/core'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { registerInterest } from 'features/fbt-submissions/actions'


const useStyles = makeStyles(theme => ({
  root: { padding: theme.spacing(4, 0), },
  form: { maxWidth: 510, },
  text: {
    maxWidth: 510,
    color: '#FFF',
    textAlign: 'center',
    padding: theme.spacing(2, 4, 0, 4),
    [theme.breakpoints.down('md')]: { padding: theme.spacing(2, 3, 0, 3) },
    [theme.breakpoints.down('sm')]: { padding: theme.spacing(2, 1, 0, 1) },
  },
  heading: { color: '#FFF' },
  actions: { padding: theme.spacing(2, 0) },
  center: { textAlign: 'center', display: 'flex', justifyContent: 'center' },
  button: {
    maxWidth: 250,
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
  },
  carImage: {
    height: 350,
    width: 'auto',
    [theme.breakpoints.down('md')]: { height: 225 },
    [theme.breakpoints.down('sm')]: { height: 200 },
    [theme.breakpoints.down('xs')]: { height: 180 },
  },
  field: {
    '& label.Mui-focused': { color: '#FFF' },
    '& .MuiInput-underline:after': { borderBottomColor: '#FFF' },
    '& .MuiOutlinedInput-root': {
      '& fieldset': { borderColor: '#FFF' },
      '&:hover fieldset': { borderColor: '#FFF' },
      '&.Mui-focused fieldset': { borderColor: '#FFF' },
    },
  },
  input: { color: '#FFF' }
}))

const PromotionForm = () => {

  const classes = useStyles()
  const dispatch = useDispatch()

  const [values, setValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    organisation: ''
  })

  const onInputChange = ({ target: { name, value } }) =>
    setValues({ ...values, [name]: value, })

  const onRegisterInterest = () => {
    console.log('[onRegisterInterest]', values)
    dispatch(registerInterest(values))
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} md={6}>
        <div className={classes.center}>
          <Typography variant="body1" className={classes.text}>
            You are not currently subscribed to TFM's FBT reporting.<br />
            If you would like to learn more about how you can get your organisation registered for this service, sign up now.
          </Typography>
        </div>
        <div className={classes.center}>
          <img className={classes.carImage} src="/static/images/marketing/car.png" alt="car"/>
        </div>
      </Grid>

      <Grid item xs={12} sm={6} md={6} className={classes.center}>
        <ValidatorForm autoComplete="off" noValidate onSubmit={onRegisterInterest} className={classes.form}>
          <Typography variant="h4" className={classes.heading}>
            Register Your Interest
          </Typography>
          {[
            {
              label: 'FIRST NAME',
              key: 'firstName',
              validators: ['required'],
              error: ['This field is required'],
              maxLength: 50,
            },
            {
              label: 'LAST NAME',
              key: 'lastName',
              validators: ['required'],
              error: ['This field is required'],
              maxLength: 50,
            },
            {
              label: 'EMAIL',
              key: 'email', validators: ['required', 'isEmail'],
              error: ['This field is required', 'Must be a valid email'],
              maxLength: 100,
            },
            {
              label: 'MOBILE',
              key: 'mobile',
              validators: ['required', 'isNumber'],
              error: ['This field is required', 'Must be a valid mobile number'],
              maxLength: 50,
            },
            {
              label: 'ORGANISATION',
              key: 'organisation',
              validators: ['required'],
              error: ['This field is required'],
              maxLength: 60,
            },
          ].map(({ label, key, validators, error, maxLength }) => (
            <TextValidator
              key={`${key}-field`}
              InputProps={{ className: classes.input }}
              inputProps={{ maxLength }}
              InputLabelProps={{ style: { color: '#FFF' } }}
              style={{ marginTop: 20 }}
              className={classes.field}
              fullWidth
              size="small"
              label={label}
              name={key}
              required
              value={values?.[key] || ''}
              variant="outlined"
              validators={validators}
              errorMessages={error}
              onChange={onInputChange}
            />
          ))}  

          <div className={classes.actions}>
            <Button
              fullWidth
              type="submit"
              color="primary"
              variant="contained"
              className={classes.button}
            >
              REGISTER YOUR INTEREST
            </Button>
          </div>
        </ValidatorForm>
      </Grid>
    </Grid>
  )
}

export default PromotionForm