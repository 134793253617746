import { createListable } from 'listable'
import { combineEpics } from 'redux-observable'
import { createEffects } from './effects'
import { REPORTS_PATH } from 'features/report'
import options from './config'


const listableModule = createListable({
  name: 'reportKmTracking',
  config: options,
})

export const config = listableModule.options
export const actions = listableModule.actions

export const effects = combineEpics(
  createEffects(listableModule.actions),
  listableModule.effects,
)

export const reducer = listableModule.reducer
export const types = listableModule.types

export const constants = {
  ...listableModule.constants,
  PATH_ALL: `${REPORTS_PATH}/km-tracking`,  
  ROUTE_ALL: `${REPORTS_PATH}/km-tracking`,

  PATH_DRILLDOWN: `${REPORTS_PATH}/km-tracking`,
  ROUTE_DRILLDOWN: `${REPORTS_PATH}/km-tracking/:id/`,

  ROUTE_CONTACT: `${REPORTS_PATH}/km-tracking/:id/contract-details`,
  ROUTE_DRIVER: `${REPORTS_PATH}/km-tracking/:id/driver-details`,
  ROUTE_VEHICLE: `${REPORTS_PATH}/km-tracking/:id/service-history`,
  ROUTE_VARIATIONS: `${REPORTS_PATH}/km-tracking/:id/variations`,
  ROUTE_SERVICE_BOOKINGS: `${REPORTS_PATH}/km-tracking/:id/service-bookings`,
  ROUTE_FBT: `${REPORTS_PATH}/km-tracking/:id/fbt`,
}
