import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { makeStyles, useTheme } from '@material-ui/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Grid, Typography, Card, CardContent, Divider } from '@material-ui/core'
import ListItemLoadingSkeleton from './list-item-skeleton'
import { fetchNotifications } from '../actions'
import { useNavigate } from 'utils'
import moment from 'moment'
import clsx from 'clsx'

import InfoIcon from '@material-ui/icons/Info'
import { notificationTypes, notificationEnums } from '../actions/types'
import InfiniteScroll from './infinite-scroll'


const useStyles = makeStyles(theme => ({
  title: {
    color: '#263238',
    fontWeight: 'bold',
    fontSize: '18px',
    paddingBottom: theme.spacing(1.5),
  },
  list: {
    height: '368px',
    maxHeight: '368px',
    overflowX: 'hidden',
    [theme.breakpoints.down('sm')]: { height: '100%' },
  },
  scroll: {
    overflowY: 'scroll',
    '&::-webkit-scrollbar': { backgroundColor: '#fff', width: '0.5em' },
    '&::-webkit-scrollbar-track': { '-webkit-box-shadow': '#fff' },
    '&::-webkit-scrollbar-track:hover': { '-webkit-box-shadow': '#f4f4f4' },
    '&::-webkit-scrollbar-thumb': { backgroundColor: '#babac0', borderRadius: '0.5em', outline: '5px solid #fff' },
    '&::-webkit-scrollbar-thumb:hover': { backgroundColor: '#a0a0a5', outline: '4px solid #f4f4f4' },
  },
  listItem: { paddingTop: theme.spacing(1.5) },
  clickable: { cursor: 'pointer' },
  icon: {
    width: 40,
    height: 40,
    display: 'inline-block',
    verticalAlign: 'text-top',
    color: '#00b6b0',
  },
  bold: { fontWeight: 'bold' },
  date: {
    paddingRight: 40,
    [theme.breakpoints.down('lg')]: {
      paddingRight: 20,
    }
  },
  noRecords: {
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    [theme.breakpoints.down('sm')]: { marginTop: theme.spacing(4) },
  },
}))


const MyNotifications = ({ flags, myNotifications, onFetchNotifications }) => {

  const theme = useTheme()
  const classes = useStyles()
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'))
  const navigate = useNavigate()

  const [delayLoading, setDelayLoading] = useState(true)
  const isLoading = flags?.fetchNotifications === 'processing' 

  useEffect(() => {
    onFetchNotifications()
    setTimeout(() => { setDelayLoading(false) }, 1500)
  }, [onFetchNotifications])
  
  const renderListData = (data) => data.map(({ recordId, createdAt, thirdPartyNumber, activityType, activityTitle, activityMessage }, index) => {
    
    const Icon = notificationTypes[activityType].Icon || InfoIcon
    const uri = notificationTypes[activityType]?.uri || ''
    const link = activityType === notificationEnums.SERVICE_RECORD ? uri.replace('{id}', thirdPartyNumber) : uri.replace('{id}', recordId)
  
    return (
      <Grid
        container
        spacing={2}
        key={`mynotification-item-${index}`}
        className={clsx(classes.listItem, uri ? classes.clickable : '')}
        onClick={navigate(link)}
      >
        
        <Grid item xs={3} sm={2}>
          <Typography align="center">
            <Icon className={classes.icon} />
          </Typography>
        </Grid>

        <Grid item xs={9} sm={10}>
          <Typography variant="h6" className={classes.bold}>
            <span className={classes.date}>
              {moment(createdAt).format('DD/MM/YYYY')}
            </span>
            {mobileView && <br />}
            {activityTitle}
          </Typography>
          {uri && activityMessage.includes('Click here')
            ? (
              <>
                {activityMessage.split('Click here')[0]}
                <Link to={link}>Click here</Link>
                {activityMessage.split('Click here')[1]}
              </>
            ) : activityMessage
          }
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>
  
      </Grid>
    )
  })

  return (
    <Card>
      <CardContent>
        <Typography variant="h4" className={classes.title}>
          My Notifications
        </Typography>
        <Divider />

        {delayLoading || isLoading ? (
          <ListItemLoadingSkeleton />
        ) : (
          <div className={clsx(classes.list, myNotifications.length > 4 ? classes.scroll : '')}>
            {myNotifications.length > 0 ? (              
              <InfiniteScroll
                data={myNotifications}
                renderListData={renderListData}
              />
            ) : (
              <div className={classes.noRecords}>
                <Typography variant="body1" align="center">
                  No notifications
                </Typography>
              </div>
            )}
          </div>
        )}        
      </CardContent>
    </Card>
  )
}

MyNotifications.propTypes = {
  flags: PropTypes.object,
  myNotifications: PropTypes.array.isRequired,
  onFetchNotifications: PropTypes.func.isRequired,
}

const mapStateToProps = ({ homepage }) => ({
  flags: homepage?.flags,
  myNotifications: homepage?.myNotifications || [],
})

const mapDispatchToProps = (dispatch) => ({
  onFetchNotifications: () => dispatch(fetchNotifications()),
})

export default connect(mapStateToProps, mapDispatchToProps)(MyNotifications)