import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid, Card, CardContent, Typography } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { CustomTabs, Alert } from 'frame/components'
import { constants } from '../module'
import { useRouter } from 'utils'
import AddEditFbtRoutes from './routes'


const useStyles = makeStyles(theme => ({
  heading: { fontWeight: 'bold' },
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  card: {
    margin: theme.spacing(5, 0),
    paddingTop: theme.spacing(5),
    paddingBottom: '300px',
    width: 960,
    [theme.breakpoints.down(960)]: {
      width: '100%',
      margin: '20px 10px 0 10px'
    },
  },
}))

const Form = () => {

  const classes = useStyles()
  const { match } = useRouter()

  const fbtWebForm = useSelector(state => state?.fbtSubmissions?.fbtWebForm)

  const tabsConfig = [
    {
      label: 'Add/Edit Submission',
      path: `${match.url}${constants.PATH_ADD_SUBMISSION}`,
      visible: true,
    }, {
      label: 'View Submissions',
      path: `${match.url}${constants.PATH_VIEW_SUBMISSIONS}`,
      visible: true,
    },
  ]

  return (
    <Card className={classes.card}>
      <CardContent>
        {!fbtWebForm?.token ? ( // to safeguard this view if no token is set in state
        <Alert severity="error">
          Invalid token. This FBT Submissions link is invalid or has been expired
        </Alert>
        ) : (
          <Grid container spacing={3}>
            <Grid xs={12} item>
              <Typography gutterBottom variant="h4" align="center" className={classes.heading}>
                Fringe Benefit Tax
              </Typography>
            </Grid>

            <Grid xs={12} item className={classes.center}>
              <CustomTabs tabsConfig={tabsConfig} />
            </Grid>
            <Grid xs={12} item>
              <AddEditFbtRoutes />
            </Grid>

          </Grid>
        )}
      </CardContent>
    </Card>
  )
}

export default Form