import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import {
  Menu,
  MenuItem
} from '@material-ui/core'

import { constants as fromRequestReliefVehicles } from 'features/request-relief-vehicles'
import { constants as fromRequestVehicleRelocations } from 'features/request-vehicle-relocations'
import { constants as fromRequestContractVariations } from 'features/request-contract-variations'
import { constants as fromRequestVehicleSummary } from 'features/request-vehicle-summary'

import { useEnabledFeatureToggleList } from 'features/settings'


const useStyles = makeStyles(theme => ({
  root: {
    width: 250,
    maxWidth: '100%',
  },
}))

const ActionsPopover = ({
  anchor,
  linker,
  open,
  onClose
}) => {

  const classes = useStyles()
  
  const featureToggleList = useEnabledFeatureToggleList()
  const vehicleSummary = featureToggleList.includes('Vehicle request form and report (TFP-2162)');

  if ( ! anchor.current) {
    return null
  }

  return (
    <Menu
      id="menu-reports"
      anchorEl={anchor.current}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open}
      onClose={onClose}>
      <div className={classes.root}>
        <MenuItem
          onClick={linker(fromRequestReliefVehicles.PATH_ALL)}>
          Relief Vehicle Booking Summary
        </MenuItem>
        <MenuItem
          onClick={linker(fromRequestVehicleRelocations.PATH_ALL)}>
          Vehicle Relocations Summary
        </MenuItem>
        <MenuItem
          onClick={linker(fromRequestContractVariations.PATH_ALL)}>
          Contract Variations Summary
        </MenuItem>
        {
          vehicleSummary &&
          <MenuItem
          onClick={linker(fromRequestVehicleSummary.PATH_ALL)}>
          Vehicle Summary
          </MenuItem>
        }
      </div>
    </Menu>
  )
}

ActionsPopover.propTypes = {
  anchor: PropTypes.object,
  linker: PropTypes.func.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
}

export default ActionsPopover
