import React, { memo } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { Grid, Button } from '@material-ui/core'
import FilterListIcon from '@material-ui/icons/FilterList'
import ColumnIcon from '@material-ui/icons/ViewColumn'
import Search from './search'


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  },
  search: {
    flexGrow: 1,
    maxWidth: 480,
    flexBasis: 480
  },
  button: {
    margin: '0 10px 0 0',
    [theme.breakpoints.up('md')]: {
      margin: '0 0 0 10px',
    },
  },
  filterIcon: {
    marginRight: theme.spacing(1)
  },
}))

const SearchBar = ({
  className,
  searchKey,
  onSearch,
  onShowFilters,
  onToggleShowColumns,
  ...rest
}) => {

  const classes = useStyles()

  return (
    <Grid {...rest} className={clsx(classes.root, className)} container spacing={3}>
      
      <Grid item md={6}>
        <Search className={classes.search} searchKey={searchKey} onSearch={onSearch} />
      </Grid>

      <Grid item>
        {!!onToggleShowColumns && (
          <Button
            className={classes.button}
            color="primary"
            onClick={onToggleShowColumns}
            size="small"
            variant="outlined">
            <ColumnIcon className={classes.filterIcon} /> Show columns
          </Button>
        )}
        {!!onShowFilters && (
          <Button
            className={classes.button}
            color="primary"
            onClick={onShowFilters}
            size="small"
            variant="outlined">
            <FilterListIcon className={classes.filterIcon} /> Show filters
          </Button>
        )}
      </Grid>
    </Grid>
  )
}

SearchBar.propTypes = {
  className: PropTypes.string,
  searchKey: PropTypes.string,
  onSearch: PropTypes.func,
  onShowFilters: PropTypes.func,
  onToggleShowColumns: PropTypes.func,
}

export default memo(SearchBar)
