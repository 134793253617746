import { createListable } from 'listable'
import { combineEpics } from 'redux-observable'
import { createEffects } from './effects'
import options from './config'
import { moduleName } from './actions/types'
import * as customActions from './actions'
import customReducer from './reducer'
import { FBT_PATH } from 'features/fbt'


const listableModule = createListable({
  name: moduleName,
  config: options,
  customActions,
  customReducer,
})

export const config = listableModule.options
export const actions = listableModule.actions

export const effects = combineEpics(
  createEffects(listableModule.actions),
  listableModule.effects,
)

export const reducer = listableModule.reducer
export const types = listableModule.types

export const constants = {
  ...listableModule.constants,
  PATH_ALL: `${FBT_PATH}/submissions`,  
  PATH_ADD: `${FBT_PATH}/submissions/add`,
  PATH_SINGLE: `${FBT_PATH}/submissions`,
  
  PATH_ADD_SUBMISSION: `/add-submission`,
  PATH_EDIT_SUBMISSION: `/edit-submission`,
  PATH_VIEW_SUBMISSIONS: `/view-submissions`,

  ROUTE_ALL: `${FBT_PATH}`,
  ROUTE_ADD: `${FBT_PATH}/submissions/add`,
  ROUTE_SINGLE: `${FBT_PATH}/submissions/:id`,

  ROUTE_ADD_SUBMISSION: `${FBT_PATH}/submissions/:id/add-submission`,
  ROUTE_EDIT_SUBMISSION: `${FBT_PATH}/submissions/:id/edit-submission`,
  ROUTE_VIEW_SUBMISSIONS: `${FBT_PATH}/submissions/:id/view-submissions`,
}
