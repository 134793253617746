import { combineEpics, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { map, mergeMap } from 'rxjs/operators'
import { makeActionRequestEffect } from 'effects'
import { getAppConfig } from 'app-config'
import join from 'url-join'
import * as fromModuleTypes from './actions/types'


export const createEffects = (moduleActions) => {

  const appConfig = getAppConfig()

  const createEffect = makeActionRequestEffect({
    type: fromModuleTypes.CREATE,
    url: join(appConfig.app.api, 'driver/driver'),
    verb: 'post',
    onSuccess: moduleActions.createFulfilled,
    onError: moduleActions.createError,
  })
  
  const fetchByAgreementEffect = makeActionRequestEffect({
    type: fromModuleTypes.FETCH_BY_AGREEMENT,
    url: join(appConfig.app.api, 'driver/driverByAgreement'),
    onSuccess: moduleActions.fetchByAgreementFulfilled,
    onError: moduleActions.fetchByAgreementError,
  })

  const fetchEffect = makeActionRequestEffect({
    type: fromModuleTypes.FETCH,
    url: join(appConfig.app.api, 'driver/driver'),
    onSuccess: moduleActions.fetchFulfilled,
    onError: moduleActions.fetchError,
  })

  const onFetchedEffect = (action$, state$) =>
    action$.pipe(
      ofType(fromModuleTypes.FETCH_FULFILLED),
      map(({ payload }) => {
        const FleetId = payload.thirdPartyNumber
        const options = {
          resourceId: payload.agreementNo,
          queryParams: { FleetId }
        }
        return moduleActions.fetchCorrespondingContract({}, options)
      })
    )

  const fetchCorrespondingContractEffect = makeActionRequestEffect({
    type: fromModuleTypes.FETCH_CORRESPONDING_CONTRACT,
    url: join(appConfig.app.api, 'contract/contract'),
    onSuccess: moduleActions.fetchCorrespondingContractFulfilled,
    onError: moduleActions.fetchCorrespondingContractError,
  })

  const updateEffect = makeActionRequestEffect({
    type: fromModuleTypes.UPDATE,
    url: join(appConfig.app.api, 'driver/driver'),
    verb: 'put',
    onSuccess: moduleActions.updateFulfilled,
    onError: moduleActions.updateError,
  })

  const fetchContractEffect = makeActionRequestEffect({
    type: fromModuleTypes.FETCH_CONTRACT,
    url: join(appConfig.app.api, 'contract/contract'),
    onSuccess: moduleActions.fetchContractFulfilled,
    onError: moduleActions.fetchContractError,
  })

  const refetchEffect = (action$, state$) =>
    action$.pipe(
      ofType(
        fromModuleTypes.CREATE_FULFILLED,
        fromModuleTypes.UPDATE_FULFILLED,
        fromModuleTypes.DELETE_FULFILLED,
        fromModuleTypes.INVITE_DRIVER_FULFILLED,
      ),
      mergeMap(() =>
        of(
          moduleActions.refetch(),
        )
      )
    )

  const deleteEffect = makeActionRequestEffect({
    type: fromModuleTypes.DELETE,
    url: join(appConfig.app.api, `driver/terminate`),
    verb: 'delete',
    onSuccess: moduleActions.removeFulfilled,
    onError: moduleActions.removeError,
  })

  const lookupEffect = makeActionRequestEffect({
    type: fromModuleTypes.LOOKUP,
    url: join(appConfig.app.api, `lookup`),
    onSuccess: moduleActions.lookupFulfilled,
    onError: moduleActions.lookupError,
    mapOperator: mergeMap, // use mergeMap top avoid lookup requests getting cancelled on edit driver modal load, triggers 3 lookups at once
  })

  // for tfs fleet modal - driver tab
  const togglePoolVehicleEffect = makeActionRequestEffect({
    type: fromModuleTypes.TOGGLE_POOL_VEHICLE,
    url: join(appConfig.app.api, 'driver/togglevehiclepool'),
    onSuccess: moduleActions.togglePoolVehicleFulfilled,
    onError: moduleActions.togglePoolVehicleError,
  })

  const unassignVehicleEffect = makeActionRequestEffect({
    type: fromModuleTypes.UNASSIGN_VEHICLE,
    url: join(appConfig.app.api, 'driver/unassign'),
    onSuccess: moduleActions.unassignVehicleFulfilled,
    onError: moduleActions.unassignVehicleError,
  })

  // for driver app invite
  const inviteDriverEffect = makeActionRequestEffect({
    type: fromModuleTypes.INVITE_DRIVER,
    url: join(appConfig.app.api, 'driver/invite'),
    verb: 'post',
    onSuccess: moduleActions.inviteDriverFulfilled,
    onError: moduleActions.inviteDriverError,
  })

  return combineEpics(
    createEffect,
    fetchEffect,
    fetchByAgreementEffect,
    fetchCorrespondingContractEffect,
    fetchContractEffect,
    updateEffect,
    refetchEffect,
    onFetchedEffect,
    deleteEffect,
    lookupEffect,
    togglePoolVehicleEffect,
    unassignVehicleEffect,
    inviteDriverEffect,
  )
}
