import React, { Fragment, useContext } from 'react'
import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import { TextValidator } from 'react-material-ui-form-validator'
import { SingleSelect, CustomDatePicker } from 'frame/components'

import { useStyles } from "./styles"
import { FormCta } from './form-cta'
import { NewVehicleSummaryContext } from '../new'
import { FormContext } from '../form'

const FormLeaseInformation = () => {
  const parentContext = useContext(NewVehicleSummaryContext);
  const formContext = useContext(FormContext);
  const classes = useStyles()

  const {
    values,
    onInputChange,
    onSelectChange,
  } = formContext

  return (
    <>
      <Fragment>
        <Grid item sm={6} xs={12}>
          <SingleSelect
            noNone
            id="replacement"
            label="Replacement"
            options={['Option 1', 'Option 2', 'Option 3']}
            value={values.replacement ?? ""}
            onChange={onSelectChange('replacement')}
          />
        </Grid>
        <Grid item sm={6} />
      </Fragment>

      <Fragment>
        <Grid item sm={6} xs={12}>
          <SingleSelect
            noNone
            id="registrationOfReplacedVehicle"
            label="Registration of replaced vehicle"
            options={['Option 1', 'Option 2', 'Option 3']}
            value={values.registrationOfReplacedVehicle ?? ""}
            onChange={onSelectChange('registrationOfReplacedVehicle')}
          />
        </Grid>
        <Grid item sm={6} />
      </Fragment>

      <Fragment>
        <Grid item sm={6} xs={12}>
          <TextValidator
            id="vehicleModel"
            className={classes.field}
            fullWidth
            label="Vehicle Model"
            value={values.vehicleModel || ''}
            inputProps={{ maxLength: 50 }}
            variant="outlined"
            validators={['required']}
            errorMessages={['This field is required']}
            onChange={onInputChange}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <SingleSelect
            noNone
            id="drivetrain"
            label="Drivetrain"
            options={['Option 1', 'Option 2', 'Option 3']}
            value={values.drivetrain ?? ""}
            onChange={onSelectChange('drivetrain')}
          />
        </Grid>
      </Fragment>

      <Fragment>
        <Grid item sm={6} xs={12}>
          <TextValidator
            id="leaseTerm"
            className={classes.field}
            fullWidth
            label="Lease Term"
            value={values.leaseTerm || ''}
            inputProps={{ maxLength: 50 }}
            variant="outlined"
            validators={['required']}
            errorMessages={['This field is required']}
            onChange={onInputChange}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextValidator
            id="leaseKms"
            className={classes.field}
            fullWidth
            label="Lease KMs"
            value={values.leaseKms || ''}
            inputProps={{ maxLength: 50 }}
            variant="outlined"
            validators={['required']}
            errorMessages={['This field is required']}
            onChange={onInputChange}
          />
        </Grid>
      </Fragment>

      <Fragment>
        <Grid item sm={6} xs={12}>
          <SingleSelect
            noNone
            id="typeOfLease"
            label="Type of Lease"
            options={['Option 1', 'Option 2', 'Option 3']}
            value={values.typeOfLease ?? ""}
            onChange={onSelectChange('typeOfLease')}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextValidator
            id="replacementTyres"
            className={classes.field}
            fullWidth
            label="Replacement Tyres"
            value={values.replacementTyres || ''}
            inputProps={{ maxLength: 50 }}
            variant="outlined"
            validators={['required']}
            errorMessages={['This field is required']}
            onChange={onInputChange}
          />
        </Grid>
      </Fragment>

      <Fragment>
        <Grid item sm={6} xs={12}>
          <CustomDatePicker
            fullWidth
            id="dateRequired"
            variant="outlined"
            format="Do MMMM YYYY"
            label="Date required"
            value={values.dateRequired || null}
            onChange={onSelectChange('dateRequired')}
            textFieldClassName={classes.field}
          />
        </Grid>
        <Grid item sm={6} />
      </Fragment>

      <Fragment>
        <Grid item xs={12}>
          <FormCta onProceed={() => parentContext.setActiveTab("driver_details")} />
        </Grid>
      </Fragment>
    </>
  )
}

FormLeaseInformation.propTypes = {
  values: PropTypes.object.isRequired,
  onSelectChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
}

export { FormLeaseInformation }