import React, { useState, useRef, useEffect } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, useTheme } from '@material-ui/styles'
import { Card, CardContent, Button, Grid, Typography } from '@material-ui/core'
import { hasRoles } from 'acl'
import { ValueText, StyledModal, DateRangePicker, RowValueText, SingleSelectValidator, Label, Alert } from 'frame/components'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { getStatusColor } from 'listable'
import { capitalize } from 'utils'
import { useEnabledFeatureToggleList } from 'features/settings'
import { getReliefVehicleTypes } from 'utils/reliefVehicleTypes'


const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3),
  },
  fieldHeader: {
    marginTop: theme.spacing(5),
  },
  fieldGroup: {
    margin: theme.spacing(3, 0, 2, 0),
  },
  actions: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    }
  },
  button: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    minWidth: 180,
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
    }
  },
  error: {
    marginBottom: theme.spacing(4),
  },
  label: {
    marginTop: theme.spacing(1),
  }
}))

const Form = ({ data, userRoles, onClose, onSave, onDelete, disabled, error, errorMessage }) => {

  const form = useRef()
  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'))
  const featureToggleList = useEnabledFeatureToggleList()
  const userPermissionsFeatureToggle = featureToggleList.includes('UserPermissions')

  const classes = useStyles()
  const { booking, contract } = data
  const [values, setValues] = useState(booking)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  
  const [showStartDateError, setShowStartDateError] = useState(false)
  const [showEndDateError, setShowEndDateError] = useState(false)

  const isChanged = JSON.stringify(booking) !== JSON.stringify(values)

  const onDeleteHandler = () => {
    onDelete(booking.id, contract.thirdPartyNumber)
    onClose()
  }

  const tfp1865FeatureToggle = featureToggleList.includes('TFP1865');
  let vehicleTypes = getReliefVehicleTypes(tfp1865FeatureToggle);
  const onSelectChange = (key) => (value) =>
    setValues({
      ...values,
      [key]: value,
    })

  const onInputChange = (key) => ({ target: { value } }) =>
    setValues({
      ...values,
      [key]: value,
    })

  const onDateRangeChange = (startDate, endDate) => {
    setShowStartDateError(false)
    setShowEndDateError(false)
    setValues({
      ...values,
      collectionDate: startDate,
      bookedReturnDate: endDate,
    })
  }
  // if lock all from data if userPermissionsFeatureToggle is true and dont have permissions'
  const isEditable = values.status && (`${values.status}`.toLowerCase() !== 'cancelled' && `${values.status}`.toLowerCase() !== 'compconsted')
  const editable = userPermissionsFeatureToggle ? hasRoles(['admin', 'superadmin', 'fleetcontroller'] ,userRoles) && isEditable : isEditable

  const getMaxReturnDate = () => {
    if (contract.maturityDate) {
      return moment(contract.maturityDate)
    }
    return null
  }

  const getStatusOptions = (status) => {
    const recordStatus = String(status).toLowerCase()

    // if record is in status 'driver requested'
    if (recordStatus === 'driver requested') { 
      if (hasRoles(['superadmin'], userRoles)) {
        return [{
          value: 'driver requested',
          label: 'Driver Requested',
        }, {
          value: 'sent to tfs',
          label: 'Sent to TFS',
        }, {
          value: 'approved',
          label: 'Approved',
        }, {
          value: 'cancelled',
          label: 'Cancelled',
        }, {
          value: 'completed',
          label: 'Completed',
        }]
      }

      // default to minimum
      return [{
        value: 'driver requested',
        label: 'Driver Requested',
      }, {
        value: 'sent to tfs',
        label: 'Sent to TFS',
      }]
    }
    // else ensure status 'driver requested' is not available
    else
    
    if (hasRoles(['superadmin'], userRoles)) {
      return [{
        value: 'sent to tfs',
        label: 'Sent to TFS',
      }, {
        value: 'approved',
        label: 'Approved',
      }, {
        value: 'cancelled',
        label: 'Cancelled',
      }, {
        value: 'completed',
        label: 'Completed',
      }]
    }

    // default to minimum
    return [{
      value: 'sent to tfs',
      label: 'Sent to TFS',
    }]
  }

  const onSubmitValidation = () => {
    if(form.current) {
      values.collectionDate === null && setShowStartDateError(true)
      values.bookedReturnDate === null && setShowEndDateError(true)
      form.current.submit()
    }
  }

  const onSubmitForm = () => {
    if(values.collectionDate && values.bookedReturnDate) {
      onSave({
        ...values,
        id: values.id || 0,
      }) 
    }
  }
  
  useEffect(() => {
    ValidatorForm.addValidationRule('isNotSpaces', (value) => {
      let cleanedValue = value.replace(/ /g, '')
      return cleanedValue && cleanedValue.length > 0
    })
    return () => {
      ValidatorForm.removeValidationRule('isNotSpaces')
    }
  }, [values.driver])

  return (
    <ValidatorForm autoComplete="off" noValidate ref={form} onSubmit={onSubmitForm}>
      <Card>
        <CardContent>
          {error && (
            <Alert className={classes.error}>
              {errorMessage || 'Something went wrong while trying to update this relief vehicle booking.'}
            </Alert>
          )}
          
          <Typography gutterBottom variant="h4">
            Relief Request Details
          </Typography>

          <div className={classes.fieldGroup}>
            <Grid container spacing={2}>

              <Grid item xs={12}>
                <RowValueText label="Status" responsive labelClassName={classes.label}>
                  {hasRoles(['admin', 'superadmin', 'fleetcontroller'], userRoles) && getStatusOptions(booking.status).length >= 2 ? (
                    <SingleSelectValidator
                      noNone
                      id="status"
                      size="small"
                      variant="outlined"
                      disabled={disabled}
                      label={mobileView ? 'Status' : ''}
                      InputLabelProps={{ shrink: mobileView }} // to show label only on mobile
                      options={getStatusOptions(booking.status)}
                      value={String(values.status).toLowerCase() || ''}
                      validators={['required']}
                      errorMessages={['This field is required']}
                      onChange={onSelectChange('status')}
                    />
                  ) : (                    
                    <Typography variant="body1" className={classes.label}>
                      {values.status ? (
                        <Label color={getStatusColor(values.status)} variant="outlined">
                          {capitalize(values.status)}
                        </Label>
                      ) : '-'}
                    </Typography>
                  )}
                </RowValueText>
              </Grid>
              
              <Grid item xs={12}>
                <RowValueText label="Vehicle Type" responsive={editable} labelClassName={classes.label}>
                  { editable ? (
                    <SingleSelectValidator
                      fullWidth noNone
                      id="vehicleType"
                      size="small"
                      variant="outlined"
                      disabled={disabled}
                      label={mobileView ? 'Vehicle Type' : ''}
                      InputLabelProps={{ shrink: mobileView }} // to show label only on mobile
                      options={vehicleTypes}
                      value={values.vehicleType || ''}
                      validators={['required']}
                      errorMessages={['This field is required']}
                      onChange={onSelectChange('vehicleType')}
                    />
                  ) : (                    
                    <Typography variant="body1" className={classes.label}>
                      {values.vehicleType || '-'}
                    </Typography>
                  )}
                </RowValueText>
              </Grid>

              <Grid item xs={12}>
                <RowValueText label="Start/End Dates" responsive={editable} labelClassName={classes.label}>
                  {editable ? (
                    <DateRangePicker
                      tight allowSameDateSelection
                      disabled={disabled}
                      format="Do MMMM YYYY"
                      values={[values.collectionDate, values.bookedReturnDate]}
                      startMinDate={values.collectionDate && moment(values.collectionDate) < moment() ? moment(values.collectionDate) : moment()}
                      endMaxDate={getMaxReturnDate()}
                      onDateRangeChange={onDateRangeChange}
                      showStartDateRequiredError={showStartDateError}
                      showEndDateRequiredError={showEndDateError}
                      placeholderText="DD/MM/YYYY"
                    />
                  ) : (                    
                    <Typography variant="body1" className={classes.label}>
                      {`${moment(values.collectionDate).format('Do MMMM YYYY')} to ${moment(values.bookedReturnDate).format('Do MMMM YYYY')} `}
                    </Typography>
                  )}
                </RowValueText>
              </Grid>
              
              <Grid item xs={12}>
                  <RowValueText label="Pickup Address" responsive={editable} labelClassName={classes.label}>
                    {editable ? (
                      <TextValidator
                        id="pickupAddress"
                        size="small"
                        disabled={disabled}
                        fullWidth
                        label={mobileView ? 'Pickup Address' : ''}
                        InputLabelProps={{ shrink: mobileView }} // to show label only on mobile
                        value={values.pickupAddress || ''}
                        variant="outlined"
                        inputProps={{ maxLength: 50 }}
                        validators={['required']}
                        errorMessages={['This field is required']}
                        onChange={onInputChange('pickupAddress')}
                      />
                    ) : (                    
                      <Typography variant="body1" className={classes.label}>
                        {values.pickupAddress || '-'}
                      </Typography>
                    )}
                  </RowValueText>
              </Grid>
              
              <Grid item xs={12}>
                <RowValueText label="Comments" responsive={editable} labelClassName={classes.label}>
                  {editable ? (
                    <TextValidator
                      id="comments"
                      size="small"
                      disabled={disabled}
                      fullWidth
                      label={mobileView ? 'Comments' : ''}
                      placeholder="Please include contact name, email address and mobile number"
                      InputLabelProps={{ shrink: mobileView }} // to show label only on mobile
                      value={values.comments || ''}
                      variant="outlined"
                      inputProps={{ maxLength: 50 }}
                      onChange={onInputChange('comments')}
                    />
                  ) : (                    
                    <Typography variant="body1" className={classes.label}>
                      {values.comments || '-'}
                    </Typography>
                  )}
                </RowValueText>
              </Grid>
            </Grid>
          </div>

          <div className={classes.fieldHeader}>
            <Typography gutterBottom variant="h4">
              Vehicle
            </Typography>
          </div>

          <div className={classes.fieldGroup}>
            <Grid container spacing={1}>
              <Grid item sm={6} xs={12}>
                <ValueText
                  large
                  label="Driver"
                  value={contract.poolVehicle
                    ? `Pool Vehicle${contract.registration ? ` - ${contract.registration}` : ''}`
                    : contract.driver || '-'
                  } />
              </Grid>

              <Grid item sm={6} xs={12}>
                <ValueText
                  large
                  label="Location"
                  value={contract.location} />
              </Grid>

              <Grid item sm={6} xs={12}>
                <ValueText
                  large
                  label="Registration"
                  value={contract.registration} />
              </Grid>

              <Grid item sm={6} xs={12}>
                <ValueText
                  large
                  label="Cost Centre"
                  value={contract.costCentre} />
              </Grid>

              <Grid item sm={6} xs={12}>
                <ValueText
                  large
                  label="Make"
                  value={contract.make} />
              </Grid>

              <Grid item sm={6} xs={12}>
                <ValueText
                  large
                  label="Model"
                  value={contract.model} />
              </Grid>

              <Grid item sm={6} xs={12}>
                <ValueText
                  large
                  label="Year"
                  value={contract.year} />
              </Grid>

              <Grid item sm={6} xs={12}>
                <ValueText
                  large
                  label="Colour"
                  value={contract.colour} />
              </Grid>

              <Grid item sm={12} xs={12}>
                <ValueText
                  large
                  label="Accessories"
                  value={contract.accessories} />
              </Grid>

            </Grid>
          </div>
        </CardContent>

        <StyledModal
          open={openDeleteModal}
          title="Cancel Relief Vehicle Request"
          onCancel={() => setOpenDeleteModal(false)}
          onConfirm={onDeleteHandler}>
            Please confirm that you wish to cancel this relief vehicle request.
        </StyledModal>
      </Card>

      <div className={classes.actions}>
        {hasRoles(['superadmin', 'fleetcontroller'], userRoles)
          && (booking.status !== values.status || editable) && (
          <Button
            className={classes.button}
            color="primary"
            variant="contained"
            disabled={disabled || !isChanged}
            onClick={onSubmitValidation}>
            Save Changes
          </Button>
        )}

        {hasRoles(['superadmin', 'fleetcontroller'], userRoles) && editable && (
          <Button
            className={classes.button}
            variant="contained"
            onClick={() => setOpenDeleteModal(true)}>
            Cancel Request
          </Button>
        )}

        <Button className={classes.button} variant="contained" onClick={onClose}>
          Close
        </Button>
      </div>
    </ValidatorForm>
  )
}

Form.propTypes = {
  data: PropTypes.object.isRequired,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  errorMessage: PropTypes.string,
  error: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

export default Form
