import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Layout } from 'frame'
import Navigation from './navigation-config'
import { REPORTS_PATH } from './types'

import { constants as fromEndOfLease, ModuleRoutes as EndOfLeaseRoute } from 'features/report-end-of-lease'
import { constants as fromExtensionRentals, ModuleRoutes as ExtensionRentalsRoute  } from 'features/report-extension-rentals'
import { constants as fromKmTracking, ModuleRoutes as KmTrackingRoute } from 'features/report-km-tracking'
import { constants as fromUpcomingMaturities, ModuleRoutes as UpcomingMaturitiesRoute } from 'features/report-upcoming-maturities'
import { constants as fromVehicleServicing, ModuleRoutes as VehicleServicingRoute } from 'features/report-vehicle-servicing'
import { constants as fromVehiclesOnOrder, ModuleRoutes as VehiclesOnOrderRoute } from 'features/report-vehicles-on-order'
import { constants as fromWofRego, ModuleRoutes as WofRegoRoute } from 'features/report-wof-rego'
import { constants as fromIncidentManagment, ModuleRoutes as IncidentManagmentRoute } from 'features/report-incident-management'
import { constants as fromDocuments, ModuleRoutes as DocumentsRoute } from 'features/report-documents'

const ReportsRoute = () => (
  <Layout navigation={Navigation()}>
    <Switch>
      <Route path={fromEndOfLease.PATH_ALL} component={EndOfLeaseRoute} />
      <Route path={fromExtensionRentals.PATH_ALL} component={ExtensionRentalsRoute} />
      <Route path={fromKmTracking.PATH_ALL} component={KmTrackingRoute} />
      <Route path={fromUpcomingMaturities.PATH_ALL} component={UpcomingMaturitiesRoute} />
      <Route path={fromVehicleServicing.PATH_ALL} component={VehicleServicingRoute} />
      <Route path={fromVehiclesOnOrder.PATH_ALL} component={VehiclesOnOrderRoute} />
      <Route path={fromWofRego.PATH_ALL} component={WofRegoRoute} />
      <Route path={fromIncidentManagment.ROUTE_ALL} component={IncidentManagmentRoute} />
      <Route path={fromDocuments.ROUTE_ALL} component={DocumentsRoute} />

      <Redirect from={REPORTS_PATH} to={fromUpcomingMaturities.PATH_ALL} />
    </Switch>
  </Layout>
)

export default ReportsRoute
