import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { makeStyles, useTheme } from '@material-ui/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { Button, Card, CardContent, Grid, Typography, TextField } from '@material-ui/core'
import { CustomDatePicker, RowValueText, Alert, OfflineLookupField, ScrollToTop } from 'frame/components'
import { lookup } from 'features/drivers/actions'

import { fetch, update, deleteVariation, reset } from '../../../actions'
import moment from 'moment'


const useStyles = makeStyles(theme => ({
    root: {},
    content: {
      padding: theme.spacing(3, 1.2, 3, 1.2),
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(3),
      },
    },
    startDate: {
      margin: 0,
      padding: 0,
      '& > div': {
        margin: 0,
        padding: '6px 8px',
        borderRadius: '4px',
        '&::before': { display: 'none' },
        '&::after': { display: 'none' },
      },
      '& input': { margin: 0, padding: 0, border: 'none' }
    },
    invalidDate: {
      border: "1px solid #ff0000",
      borderRadius: "4px"
    },
    label: {
      paddingTop: theme.spacing(2),
    },
    actions: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down('xs')]: {
        display: 'block',
      }
    },
    button: {
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(2),
      minWidth: 180,
      [theme.breakpoints.down('xs')]: {
        minWidth: '100%',
      }
    },
}))

const PoolConfirmForm = ({
  values,
  disabled,
  fleetData,
  // functions
  onChange,
  onUpdate,
  onSubmit,
  onBack,
  onClose,
  onLookup,
  onTogglePoolVehicle
}) => {
    const form = useRef()
    const classes = useStyles()
    const theme = useTheme()
    const mobileView = useMediaQuery(theme.breakpoints.down('xs'))
    
    const [showError, setShowError] = useState(false);
    const [hasVehicleAlready, setHasVehicleAlready] = useState(null);

    
    const onLookupChange = (key) => (option) =>
      onChange({ ...values, [key]: option ? option.value : null });

    const onInputChange = ({ target: { id, name, value } }) =>
      onChange({ ...values, [id || name]: value });

    const onDateChange = (value) => {
      onChange({ ...values, effectiveDate: value });
    }

    const onSubmitForm = () => {
      if (values.effectiveDate) {
        onUpdate(values);
        onTogglePoolVehicle(values.agreementNo, values.thirdPartyNumber, values.effectiveDate, true);
        onClose();
      } else {
        setShowError("true")
        setTimeout(() => setShowError(false), 3000)
      }
    }
    const onSubmitValidation = () => {
      console.log('[confirm form]: ', values)
      if (form.current) {
        form.current.submit()
      } else {
        setShowError(true)
        setTimeout(() => setShowError(false), 3000)
      }
    };
    const displayVehicle = (data) => {
      let vehicle = `${data.year || ''} ${data.make || ''}`.trim()
      vehicle = `${vehicle} ${data.model || ''}`.trim()
      vehicle = `${vehicle} ${data.variant || ''}`.trim()
      return vehicle.replace('(Unknown)', '').trim()
    };

    return (
        <ValidatorForm autoComplete="off" ref={form} onSubmit={onSubmitForm}>
      <ScrollToTop />
      <Card className={classes.root}>
        <CardContent className={classes.content}>
          
          <Grid container spacing={2}>
            {showError && (
              <Grid item xs={12}>
                <Alert>
                  Driver update failed. Start Date is a required field.
                </Alert>
                <br />
              </Grid>
            )}

            <Grid item xs={12}>
              <Typography gutterBottom variant="h4">
                Change Driver To
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <RowValueText label="New Driver">
                <Typography variant="body1">
                  POOL - {values.registration}
                </Typography>
              </RowValueText>
            </Grid>
            
            <Grid item xs={12}>
              <RowValueText label="Start Date" labelClassName={classes.label}>
                <CustomDatePicker
                  disableFuture
                  minDate={values?.startDate || moment().add(-1, 'years').toDate()}
                  variant="outlined"
                  format="Do MMMM YYYY"
                  value={values.effectiveDate}
                  onChange={onDateChange}
                  textFieldClassName={`${classes.startDate} ${values.effectiveDate == null ? classes.invalidDate : ''}`}
                />
              </RowValueText>
            </Grid>
            
            <Grid item xs={12}>
              <Typography gutterBottom variant="h4">
               Vehicle Details
              </Typography>
            </Grid>
            
            <Grid item xs={12}>
              <RowValueText label="Vehicle">
                <Typography variant="body1">
                  {fleetData ? displayVehicle(fleetData) : '-'}
                </Typography>
              </RowValueText>
            </Grid>

            <Grid item xs={12}>
              <RowValueText label="Registration">
                <Typography variant="body1">
                {fleetData && fleetData.registration ? fleetData.registration : '-'}
                </Typography>
              </RowValueText>
            </Grid>
            
            <Grid item xs={12}>
              <RowValueText label="Colour">
                <Typography variant="body1">
                  {fleetData && fleetData.colour ? fleetData.colour : '-'}
                </Typography>
              </RowValueText>
            </Grid>

            <Grid item xs={12}>
              <RowValueText label="Cost Centre" responsive>
                <OfflineLookupField
                  fullWidth showAllResult allowFreeText
                  size="small"
                  id="costCentre"
                  label={mobileView ? 'Cost Centre' : ''}
                  disabled={!values.accountName || disabled}
                  customFleetId={values.thirdPartyNumber}
                  lookupPath="drivercostcentre"
                  value={values.costCentre || ''}
                  variant="outlined"
                  // validators={['required']}
                  // errorMessages={['This field is required']}
                  onChange={onLookupChange('costCentre')}
                  defaultOptions={values.costCentre ? [{ text: values.costCentre, value: values.costCentre }] : []}
                  onLookup={onLookup}
                  resourceModel="drivers"
                />
              </RowValueText>
            </Grid>

            <Grid item xs={12}>
              <RowValueText label="Location" responsive>
                <OfflineLookupField
                  fullWidth showAllResult
                  size="small"
                  id="location"
                  label={mobileView ? 'Location' : ''}
                  disabled={!values.accountName || disabled}
                  lookupPath="location"
                  value={values.location || ''}
                  variant="outlined"
                  // validators={['required']}
                  // errorMessages={['This field is required']}
                  onChange={onLookupChange('location')}
                  defaultOptions={values.location ? [{ text: values.location, value: values.location }] : []}
                  onLookup={onLookup}
                  resourceModel="drivers"
                />
              </RowValueText>
            </Grid>

            <Grid item xs={12}>
              <RowValueText label="Fleet Controller" responsive>
                <OfflineLookupField
                  fullWidth showAllResult allowFreeText
                  id="controller"
                  size="small"
                  label={mobileView ? 'Fleet Controller' : ''}
                  disabled={disabled}
                  customFleetId={values.thirdPartyNumber}
                  lookupPath="driverfleetcontroller"
                  value={values.controller || ''}
                  variant="outlined"
                  // validators={['required']}
                  // errorMessages={['This field is required']}
                  onChange={onLookupChange('controller')}
                  defaultOptions={values.controller ? [{ text: values.controller, value: values.controller }] : []}
                  onLookup={onLookup}
                  resourceModel="drivers"
                />
              </RowValueText>
            </Grid>

            <Grid item xs={12}>
              <RowValueText label="Vehicle Notes" responsive>
                <TextField
                  multiline fullWidth
                  rows={3}
                  size="small"
                  id="vehicleNotes"
                  label={mobileView ? 'Vehicle Notes' : ''}
                  className={classes.field}
                  disabled={disabled}
                  value={values.vehicleNotes || ''}
                  inputProps={{ maxLength: 50 }}
                  variant="outlined"
                  onChange={onInputChange}
                />
              </RowValueText>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body1">
                You have chosen to change the driver {values?.driver && <>from <strong>{values?.driver}</strong></>}{' to a '}
                <strong>Pool vehicle</strong>. Would you like to proceed?
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      
      <div className={classes.actions}>
        <Button className={classes.button} color="primary" disabled={disabled || values.effectiveDate == null} variant="contained" onClick={onSubmitValidation}>
          {`Yes, designate this as a pool vehicle`}
        </Button>
        <Button className={classes.button} disabled={disabled} variant="contained" onClick={onClose}>
          Back
        </Button>
        <Button className={classes.button} disabled={disabled} variant="contained" onClick={onClose}>
          Cancel
        </Button>
      </div>
    </ValidatorForm>
    )
}

const mapStateToProps = () => ({})

const mapDispatchToProps = (dispatch) => ({
  onLookup: (lookupPath, searchKey, customFleetId) => {
    dispatch(lookup(null, {
      resourceId: lookupPath,
      queryParams: { FleetId: customFleetId, Search: searchKey }
    }))
  },
  onUpdate: (payload) => {
    dispatch(update(payload))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(PoolConfirmForm)
