import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { MobileDatePicker } from '@material-ui/pickers'
import TextField from '@material-ui/core/TextField'


const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
  },
  errorText: {
    position: 'absolute',
    top: 40,
    left: 0,
    marginLeft: '14px',
    marginRight: '14px',
    color: theme.palette.error.main,
    fontSize: '11px',
    marginTop: '3px',
    textAlign: 'left',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    fontWeight: 400,
    lineHeight: '13px',
    letterSpacing: '0.33px',
  }
}))

const CustomDatePicker = ({
  fullWidth,
  variant,
  value,
  format,
  onChange,
  disabledPastDates,
  disableFuture,
  minDate,
  maxDate,
  textFieldClassName,
  helperText,
  ...rest
}) => {
  const classes = useStyles()

  return (
    <>
      <style scoped>
        {`
          .MuiPickersDay-dayWithMargin.Mui-disabled, .MuiPickersYear-yearButton.Mui-disabled {
            color: rgba(0, 0, 0, 0.26);
          }
          .MuiPickersDatePickerToolbar-root {
            padding-top: 10px;
          }
          .MuiGrid-root.MuiPickersToolbar-dateTitleContainer.MuiGrid-container.MuiGrid-align-items-xs-flex-end > h4 {
            color: #FFFFFF;
          }
          .MuiPickersDatePickerToolbar-penIcon {
            display: none;
          },
      `}
      </style>
      <MobileDatePicker
        {...rest}
        value={value}
        onChange={onChange}
        inputFormat={format || "Do MMMM YYYY"}
        disablePast={disabledPastDates}
        disableFuture={disableFuture}
        minDate={minDate && moment(minDate)}
        maxDate={maxDate && moment(maxDate)}
        defaultValue={null}
        renderInput={(props) => (
          <div className={classes.container}>
            <TextField
              {...props}  
              fullWidth={fullWidth}
              variant={variant}
              className={textFieldClassName || ''}
              helperText={helperText}
            />

            {props.error && (
              <span className={classes.errorText}>
                Invalid variation date
              </span>
            )}
          </div>
        )}
      />
    </>
  )
}


CustomDatePicker.propTypes = {
  fullWidth: PropTypes.bool,
  variant: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,
  format: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabledPastDates: PropTypes.bool,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  textFieldClassName: PropTypes.string,
  helperText: PropTypes.string,
}

export default CustomDatePicker
