
export const moduleName = 'fleet'

export const FETCH = `${moduleName}_fetch_contract`
export const FETCH_ERROR = `${moduleName}_fetch_contract_error`
export const FETCH_FULFILLED = `${moduleName}_fetch_contract_fulfilled`

export const FETCH_SERVICE_HISTORY = `${moduleName}_fetch_service_history`
export const FETCH_SERVICE_HISTORY_ERROR = `${moduleName}_fetch_service_history_error`
export const FETCH_SERVICE_HISTORY_FULFILLED = `${moduleName}_fetch_service_history_fulfilled`

export const UPDATE_SERVICE_HISTORY_RELIABLE_FLAG = `${moduleName}_update_service_history_reliable_flag`
export const UPDATE_SERVICE_HISTORY_RELIABLE_FLAG_ERROR = `${moduleName}_update_service_history_reliable_flag_error`
export const UPDATE_SERVICE_HISTORY_RELIABLE_FLAG_FULFILLED = `${moduleName}_update_service_history_reliable_flag_fulfilled`

export const FETCH_RELATED_FBT = `${moduleName}_fetch_related_fbt`
export const FETCH_RELATED_FBT_ERROR = `${moduleName}_fetch_related_fbt_error`
export const FETCH_RELATED_FBT_FULFILLED = `${moduleName}_fetch_related_fbt_fulfilled`

export const UPDATE = `${moduleName}_update_contract`
export const UPDATE_ERROR = `${moduleName}_update_contract_error`
export const UPDATE_FULFILLED = `${moduleName}_update_contract_fulfilled`

export const UPDATE_VARIATIONS = `${moduleName}_update_contract_variations`

export const DELETE_VARIATION = `${moduleName}_delete_variation`
export const DELETE_VARIATION_ERROR = `${moduleName}_delete_variation_error`
export const DELETE_VARIATION_FULFILLED = `${moduleName}_delete_variation_fulfilled`

export const ON_CHANGE = `${moduleName}_on_change`

export const LOOKUP = `${moduleName}_lookup`
export const LOOKUP_ERROR = `${moduleName}_lookup_error`
export const LOOKUP_FULFILLED = `${moduleName}_lookup_fulfilled`

export const RESET_LOOKUPS = `${moduleName}_reset_lookups`
export const RESET = `${moduleName}_reset`

export const SUBMIT_KM_READING = `${moduleName}_submit_km_reading`
export const SUBMIT_KM_READING_ERROR = `${moduleName}_submit_km_reading_error`
export const SUBMIT_KM_READING_FULFILLED = `${moduleName}_submit_km_reading_fulfilled`

export const DOWNLOAD_VEHICLE_HISTORY = `${moduleName}_download_vehicle_history`
export const DOWNLOAD_VEHICLE_HISTORY_ERROR = `${moduleName}_download_vehicle_history_error`
export const DOWNLOAD_VEHICLE_HISTORY_FULFILLED = `${moduleName}_download_vehicle_history_fulfilled`