import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { fetch, reset } from '../../actions'
import { fetchByAgreement as fetchDriver, reset as resetDriverFlags } from 'features/drivers/actions'
import DrillDownModal from './drill-down-modal'


const mapStateToProps = ({ drivers, fleet, reportVehicleServicing, settings }, ownProps) => ({
  agreementNo: ownProps.match.params.id,
  data: fleet.data?.[ownProps.match.params.id],
  fleetIds: settings.fleet.selected,
  fleetFlags: fleet.flags,
  driverFlags: drivers.flags,
  serviceBookingFlags: reportVehicleServicing.flags,
  errorMessage: fleet.errorMessage,
})

const mapDispatchToProps = (dispatch) => ({
  onFetch: (agreementNo, fleetIds) => {
    const FleetId = fleetIds.join(',')
    dispatch(fetch(null, {
      resourceId: agreementNo,
      queryParams: { FleetId }
    }))
  },
  onFetchDriver: (agreementNo, fleetIds) => {
    const FleetId = fleetIds.join(',')
    dispatch(fetchDriver(null, {
      resourceId: agreementNo,
      queryParams: { FleetId }
    }))
  },
  onReset: () => {
    dispatch(reset())
  },  
  onResetDriverFlags: () => {
    dispatch(resetDriverFlags())
  },
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(DrillDownModal))
