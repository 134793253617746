import React, { Fragment, useContext } from 'react'
import { Table, TableBody, TableCell, TableHead, TableRow, Radio, Typography } from '@material-ui/core'

import { useStyles } from "../styles"
import { FormContext } from '../../form'

const DriverResults = () => {
  const classes = useStyles()
  const formContext = useContext(FormContext)
  const {
    results,
    searchName,
    selectedDriver,
    setSelectedDriver,
  } = formContext

  return (
    <Fragment>
      {searchName && results && (
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>
                Driver
              </TableCell>
              <TableCell>
                Email
              </TableCell>
              <TableCell>
                Status
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {results.map((rowData, index) => (
              <TableRow
                key={`${index}-${rowData.value}`}
                onClick={() => setSelectedDriver(rowData)}
                className={classes.tableRow}
              >
                <TableCell style={{ minWidth: '20px' }}>
                  <Radio
                    style={{ marginLeft: '-10px' }}
                    size="small"
                    color="default"
                    checked={selectedDriver?.value === rowData.value}
                    name="select-driver-radio-button"
                    inputProps={{ 'aria-label': `radio-btn-${index}-${rowData.value}` }}
                  />
                </TableCell>

                <TableCell style={{ minWidth: '200px' }}>
                  {rowData.text || '-'} {/* Driver Name */}
                </TableCell>

                <TableCell style={{ minWidth: '200px' }}>
                  {rowData.email || '-'} {/* Driver Name */}
                </TableCell>

                <TableCell>
                  {rowData.status || '-'}
                </TableCell>

              </TableRow>
              )
            )}
          </TableBody>
        </Table>
      )}
      
      {/* {isLoading && (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <PageLoader />
        </div>
      )} */}

      {results && results.length === 0 && (
        <Typography variant="body1">
          No driver found.
        </Typography>
      )}
    </Fragment>
  )
}

export { DriverResults }