import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Card, CardContent, Grid } from '@material-ui/core'
import Form from './promotion-form'
import { useSelector } from 'react-redux'
import FormComplete from './promotional-form-complete'
import { PageLoader, StyledModal, AnimatedErrorCross } from 'frame/components'


const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#002849',
    height: 'calc(100vh - 224px)',
    [theme.breakpoints.down('sm')]: { height: '100%' },
  },
  logo: {
    width: 400,
    height: 'auto',
    [theme.breakpoints.up('sm')]: { padding: '3rem 0' },
    [theme.breakpoints.down('sm')]: { width: 350 },
    [theme.breakpoints.down('xs')]: { width: 250 },
  },
  center: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  loader: {
    height: 490,
  },
}))

const PromotionBanner = () => {

  const classes = useStyles()

  const [showError, setShowError] = useState(false)

  const flags = useSelector(state => state?.fbtSubmissions?.flags)
  const errorMessage = useSelector(state => state?.fbtSubmissions?.errorMessage)

  useEffect(() => {
    flags.registerInterest === 'error' && setShowError(true)
  }, [flags.registerInterest])
  
  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.center}>
            <img className={classes.logo} src="/static/images/logo-light.png" alt="logo" /> 
          </Grid>

          {flags?.registerInterest === 'processed' ? (
            <Grid item xs={12}>
              <FormComplete />
            </Grid>
          ) : flags?.registerInterest === 'processing' ? (    
              <Grid item xs={12} className={classes.loader}>
                <PageLoader color={'#FFF'} />
              </Grid>
          ) : (
            <Form />
          )}
        </Grid>
      </CardContent>

      {/* Error Message Popup */}
      <StyledModal
        open={showError}
        onCancel={() => setShowError(false)}
        options={['noButtons']}
        animatedIcon={<AnimatedErrorCross size={50} />}>
        {`Register Interest Failed.${errorMessage ? ` Error: ${errorMessage}` : ''}`}
      </StyledModal>
    </Card>
  )
}

export default PromotionBanner