import PropTypes from 'prop-types'


export const model = [{
  key: 'agreementNo',
  label: 'Agreement No.',
  column: {
    visible: false,
    width: 115,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'contractEndKm',
  label: 'Contract End KM',
  column: {
    visible: false,
    width: 135,
  },
  prop: {
    type: PropTypes.number,
    value: '',
  }
}, {
  key: 'costCentre',
  label: 'Cost Centre',
  column: {
    visible: true,
    width: 120,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'driverNote',
  label: 'Driver Note',
  column: {
    visible: false,
    width: 190,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'driver',
  label: 'Driver',
  column: {
    visible: true,
    width: 190,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'forecastEndKm',
  label: `Forecast End KM's`,
  column: {
    visible: true,
    width: 140,
  },
  prop: {
    type: PropTypes.number,
    value: '',
  }
}, {
  key: 'fuelType',
  label: `Fuel Type`,
  column: {
    visible: false,
    width: 90,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'kmVariation',
  label: `Forecast KM Allowance`,
  column: {
    visible: true,
    width: 160,
  },
  prop: {
    type: PropTypes.number,
    value: '',
  }
}, {
  key: 'lastOdometerReading',
  label: 'Last KM Reading',
  column: {
    visible: false,
    width: 140,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'make',
  label: 'Make',
  column: {
    visible: false,
    width: 100,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'maturityDate',
  label: 'Maturity Date',
  column: {
    visible: true,
    width: 110,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'model',
  label: `Model`,
  column: {
    visible: false,
    width: 130,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'odometer',
  label: 'Odometer',
  column: {
    visible: true,
    width: 120,
  },
  prop: {
    type: PropTypes.number,
    value: '',
  }
}, {
  key: 'odometerSource',
  label: 'Odometer Source',
  column: {
    visible: false,
    width: 130,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'registration',
  label: 'Registration',
  column: {
    visible: true,
    width: 110,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'startDate',
  label: 'Start Date',
  column: {
    visible: false,
    width: 90,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'tracking',
  label: 'Tracking',
  column: {
    visible: true,
    width: 180,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'variant',
  label: 'Variant',
  column: {
    visible: false,
    width: 250,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'year',
  label: 'Year',
  column: {
    visible: false,
    width: 80,
  },
  prop: {
    type: PropTypes.number,
    value: '',
  }
}, {
  key: 'accountName',
  label: 'Fleet',
  column: {
    visible: true,
    width: 80,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'agreementType',
  label: 'Agreement Type',
  column: {
    visible: true,
    width: 115,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, {
  key: 'documentsCount',
  label: 'Documents',
  column: {
    visible: false,
    width: 100,
  },
  prop: {
    type: PropTypes.number,
    value: null,
  }
}]

const data = {
  title: 'KM Tracking',
  breadcrumbs: null,
  backgroundImage: 'url("/static/images/backgrounds/bg4.png")',
  api: {
    filter: '/filteroptions/kmtracking',
    resource: `/report/kmtracking`,
    download: `/download/kmtracking`,
  },
  model,
  headers: [
    'accountName','agreementNo', 'registration', 'assetBranch', 'assetFunction', 'driverNote', 'driver', 'year', 'make', 'model', 'variant', 
    'fuelType', 'costCentre', 'lastOdometerReading', 'odometer', 'forecastEndKm',
    'contractEndKm', 'tracking', 'kmVariation',
    'odometerSource', 'maturityDate', 'startDate', 'agreementType','documentsCount'
  ],
  filters: [{
    key: 'costCentre',
    label: 'Cost Centre',
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }, {
    key: 'tracking',
    label: 'Tracking',
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }, {
    key: 'agreementType',
    label: 'Agreement Type',
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }],
}


export default data
