import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { makeStyles } from '@material-ui/styles'
import { TableCell, TableRow, Tooltip, IconButton } from '@material-ui/core'
import RowActions from './row-options'
import { createRowSelector, useRouter } from 'utils'
import { FleetIcon } from 'frame/components'
import { types } from '../../module'
import clip from 'text-clipper'
import { useEnabledFeatureToggleList } from 'features/settings'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'

const useStyles = makeStyles(theme => ({
  root: {
    cursor: 'pointer',
    verticalAlign: 'text-top',
  },
  label: theme.typography.bodySmall,
  spacer: {
    margin: 0,
    padding: 0,
  },
  documentIcon: { color: '#00B6B0' },
}))

const Row = ({ item, columns, onView }) => {

  const classes = useStyles()
  const { history, location } = useRouter()

  const featureToggleList = useEnabledFeatureToggleList()
  const onSafeRowClick = createRowSelector(onView)
  const documentsReportFeatureToggle = featureToggleList.includes('DocumentsViewing')
  const tfp1944FeatureToggle = featureToggleList.includes('TFP1944')

  const onViewDocumentsReport = () => {
    history.push(`/reports/documents?registration=${item.registration}`, { label: 'Incident Management',  path: location.pathname })
  }


  return (
    <TableRow hover className={classes.root} onClick={onSafeRowClick}>
      <TableCell className={classes.spacer} />
      
      {columns.accountName && (
        <TableCell className={classes.label}>
          <FleetIcon fleetName={item.accountName} />
        </TableCell>
      )}
      {columns.driverNote && tfp1944FeatureToggle && (
        <TableCell className={classes.label}>
          {item.driverNote || '-'}
        </TableCell>
      )}      
      {columns.driver && (
        <TableCell className={classes.label}>
          {item.driver || '-'}
        </TableCell>
      )}
      {columns.costCentre && (
        <TableCell className={classes.label}>
          {item.costCentre || '-'}
        </TableCell>
      )}      
      {columns.incidentType && (
        <TableCell className={classes.label}>
          {item.incidentType || '-'}
        </TableCell>
      )}
      {columns.incidentDate && (
        <TableCell className={classes.label}>
          {item.incidentDate ? moment(item.incidentDate).format('Do MMMM YYYY h:mm A') : '-'}
        </TableCell>
      )}
      {columns.description && (
        <TableCell className={classes.label}>
          {item.description
            ? clip(item.description, 100)
            : '-'
          }
        </TableCell>
      )}
      {columns.registration && (
        <TableCell className={classes.label}>
          {item.registration || '-'}
        </TableCell>
      )}
      {columns.year && (
        <TableCell className={classes.label}>
          {item.year || '-'}
        </TableCell>
      )}
      {columns.make && (
        <TableCell className={classes.label}>
          {item.make || '-'}
        </TableCell>
      )}
      {columns.model && (
        <TableCell className={classes.label}>
          {item.model || '-'}
        </TableCell>
      )}
      {columns.variant && (
        <TableCell className={classes.label}>
          {item.variant || '-'}
        </TableCell>
      )}
      {columns.attachmentsCount && (
        <TableCell className={classes.label}>
          {item.attachmentsCount || '-'}
        </TableCell>
      )}
      {columns.documentsCount && documentsReportFeatureToggle && (
        <TableCell className={classes.label}>
          {( item.documentsCount > 0 ? (            
            <Tooltip title="View documents">
              <IconButton className="ignore" onClick={onViewDocumentsReport}>
                <InsertDriveFileIcon className={classes.documentIcon} />
              </IconButton>
            </Tooltip>
          ) : '-')}
        </TableCell>
      )}
      {columns.notes && (
        <TableCell className={classes.label}>
          {item.notes || '-'}
        </TableCell>
      )}
      <TableCell align="right">
        <RowActions          
          className="ignore"
          id={item.id}
          onView={onView}
        />
      </TableCell>
    </TableRow>
  )
}

Row.propTypes = {
  item: types.ModelType.isRequired,
  columns: types.ColumnsType.isRequired,
  onView: PropTypes.func.isRequired,
}

export default Row
