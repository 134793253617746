export const AUTHORIZATION_REQUESTED = 'AUTHORIZATION_REQUESTED'
export const AUTHORIZATION_LOGIN_FULFILLED = 'AUTHORIZATION_LOGIN_FULFILLED'
export const AUTHORIZATION_LOGOUT_FULFILLED = 'AUTHORIZATION_LOGOUT_FULFILLED'
// user invite verification
export const AUTHORIZATION_VERIFICATION_REQUESTED = 'AUTHORIZATION_VERIFY_REQUESTED'
export const AUTHORIZATION_VERIFICATION_FULFILLED = 'AUTHORIZATION_VERIFICATION_FULFILLED'
export const AUTHORIZATION_VERIFICATION_FAILED = 'AUTHORIZATION_VERIFICATION_FAILED'
export const AUTHORIZATION_VERIFICATION_CLEAR_MESSAGE = 'AUTHORIZATION_VERIFICATION_CLEAR_MESSAGE'
// driver invite verification
export const AUTHORIZATION_DRIVER_VERIFICATION_REQUESTED = 'AUTHORIZATION_DRIVER_VERIFY_REQUESTED'
export const AUTHORIZATION_DRIVER_VERIFICATION_FULFILLED = 'AUTHORIZATION_DRIVER_VERIFY_FULFILLED'
export const AUTHORIZATION_DRIVER_VERIFICATION_FAILED = 'AUTHORIZATION_DRIVER_VERIFY_FAILED'
export const AUTHORIZATION_DRIVER_VERIFICATION_CLEAR_MESSAGE = 'AUTHORIZATION_DRIVER_VERIFY_CLEAR_MESSAGE'


export const authRequested = () => ({
  type: AUTHORIZATION_REQUESTED,
})

export const loggedIn = (payload) => ({
  type: AUTHORIZATION_LOGIN_FULFILLED,
  payload,
})

export const loggedOut = () => ({
  type: AUTHORIZATION_LOGOUT_FULFILLED,
})

export const verifyUser = (payload) => ({
  type: AUTHORIZATION_VERIFICATION_REQUESTED,
  payload,
})

export const verifyUserSuccess = (payload) => ({
  type: AUTHORIZATION_VERIFICATION_FULFILLED,
  payload
})

export const verifyUserFailed = (payload) => ({
  type: AUTHORIZATION_VERIFICATION_FAILED,
  payload
})

export const verifyUserClearMessage = () => ({
  type: AUTHORIZATION_VERIFICATION_CLEAR_MESSAGE,
})
// driver
export const verifyDriver = (payload) => ({
  type: AUTHORIZATION_DRIVER_VERIFICATION_REQUESTED,
  payload,
})

export const verifyDriverSuccess = (payload) => ({
  type: AUTHORIZATION_DRIVER_VERIFICATION_FULFILLED,
  payload
})

export const verifyDriverFailed = (payload) => ({
  type: AUTHORIZATION_DRIVER_VERIFICATION_FAILED,
  payload
})

export const verifyDriverClearMessage = () => ({
  type: AUTHORIZATION_DRIVER_VERIFICATION_CLEAR_MESSAGE,
})