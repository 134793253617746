import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import CloseIcon from '@material-ui/icons/Close'
import { colors, Typography, Button, Dialog, DialogTitle, DialogActions, DialogContent } from '@material-ui/core'


const useStyles = makeStyles(theme => ({
  root: {},
  title: {
    marginTop: theme.spacing(1)
  },
  close: {
    display: 'flex',
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2.4),
    margin: 0,
    padding: 0,
    width: 30,
    height: 30,
    background: 'transparent',
    borderRadius: 15,
    border: 'none',
    transition: '0.3s all ease-out',
    cursor: 'pointer',
    '&:hover': {
      background: colors.grey[100],
      border: 'none',
      outline: 'none',
    },
    '&:focus': {
      border: 'none',
      outline: 'none',
    }
  },
  icon: {
    margin: 0,
    width: 30,
    height: 30,
    color: colors.grey[600],
  },
  actions: {
    padding: '20px'
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

const SimpleModal = ({
  title, // optional
  children,
  open,
  onAction,
  onActionLabel,
  onClose,
  onCloseLabel,
  noButtons,
  variant = 'contained',
  options,
}) => {
  const classes = useStyles()

  const boldHeading = options && options.includes('boldHeading')
  const fullWidthButtons = options && options.includes('fullWidthButtons')

  const actionButtons = () => (
    <>
      {onAction && onActionLabel && (
        <Button
          style={{ marginBottom: fullWidthButtons ? 10 : 0 }}
          onClick={onAction}
          color="primary"
          fullWidth={fullWidthButtons}
          variant={variant}
          autoFocus={onAction && onActionLabel}>
          {onActionLabel}
        </Button>
      )}
      
      <Button
        onClick={onClose}
        color={onAction && onActionLabel ? 'secondary' : 'primary'}
        fullWidth={fullWidthButtons}
        variant={variant}
        autoFocus={!onAction || !onActionLabel}>
        {onCloseLabel || 'Close'}
      </Button>        
    </>
  )

  return (
    <Dialog open={open} onClose={onClose}>
      {title ? (
        <DialogTitle>
          <Typography className={classes.title} variant="h5">
            {boldHeading ? <strong>{title}</strong> : title}
          </Typography>
        </DialogTitle>
      ) : (
        <div style={{ height: 40 }}/>
      )}

      <button
        className={classes.close}
        onClick={onClose}>
        <CloseIcon className={classes.icon} />
      </button>

      <DialogContent>
        {children}
      </DialogContent>

      {!noButtons && (
        <div className={classes.actions}>
          {fullWidthButtons
            ? actionButtons()
            : (
              <DialogActions className={classes.center}>
                {actionButtons()}
              </DialogActions>
            )
          }
        </div>
      )}
    </Dialog>
  )
}

SimpleModal.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  onAction: PropTypes.func,  
  onActionLabel: PropTypes.string,
  onConfirm: PropTypes.func,
  onConfirmLabel: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onCloseLabel: PropTypes.string,
  noButtons: PropTypes.bool,
  fullWidthButtons: PropTypes.bool,
  variant: PropTypes.string,
  options: PropTypes.array,
}

export default SimpleModal