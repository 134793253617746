import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import uuid from 'uuid/v4'
import { useFleetId } from 'features/settings'


export const createHooks = ({ actions, constants, name }) => {

  const useGraphFetch = () => {

    const dispatch = useDispatch()

    /*
     * Request ID
     * The Request ID is used to track
     * the state of this request in the global store.
     * That is it makes it possible to know if this single
     * request is in process, completed or has errored.
     * */
    const [requestId, setRequestId] = useState()

    /*
     * General State
     * Used for determining the request state.
     */
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)

    /*
     * TODO :: Refactor :: Remove
     * Remove this, we should not need a fleet id in conjunction
     * with a resource id to lookup a resource. The client has
     * also asked than in the future multiple fleets be merged in
     * which case we may not know ahead of time the corresponding
     * fleet id for a resource.
     */
    const FleetId = useFleetId()
    const queryParams = { FleetId }

    /*
     * Data
     * The data is selected from the corresponding list reducer.
     * If there is data already in the store it will be returned
     * immediately, regardless if the request is processing.
     */
    const data = useSelector(state => state[name]?.data)

    /*
     * Request State
     * The request state is returned from the reducer once available.
     * It has a 'status' property indicating either:
     * 'loading', 'done', 'error'
     */
    const request = useSelector(state => state[name]?.requests[requestId])

    /*
     * Request Status
     * This effect is run everytime the 'request' property
     * is updated in the reducer. It sets the corresponding
     * state for the call that is returned to the caller.
     */
    useEffect(() => {
      if (request?.status === 'done') {
        setLoading(false)
        setError(false)
      }
      else if (request?.status === 'error') {
        setLoading(false)
        setError(true)
      }
    }, [request, setLoading])

    useEffect(() => {
      if (FleetId) {

        const newRequestId = uuid()
        const options = {
          queryParams,
          requestId: newRequestId
        }

        setRequestId(newRequestId)
        setLoading(true)
        setError(false)
        setTimeout(() => {
          dispatch(actions.requestData(null, options))
        }, 1000)
      }
    }, [FleetId]) // eslint-disable-line

    return { data, error, loading }
  }

  return {
    useGraphFetch,
  }
}
