import * as fromActions from './actions/types'
import * as R from 'ramda'
import * as fromFleetActions from 'features/fleet/actions/types'


const reducer = (state, action) => {
  switch (action.type) {
    case fromFleetActions.RESET:
    case fromActions.RESET:
      return resetFlags(state) // resets all flags on driver page load
    // Create Driver
    case fromActions.CREATE:
      return addDriver(state, action)
    case fromActions.CREATE_ERROR: {
      state.apiResponse = action.payload || 'Error occured, Driver creation failed.'
      return updateFlags(state, { create: 'error' })
    }
    case fromActions.CREATE_FULFILLED:
      return createdDriver(state, action, { create: 'processed' })
    
    // Fetch Driver List Data
    case fromActions.FETCH:
      return updateFlags(state, { fetch: 'processing' })
    case fromActions.FETCH_ERROR:
      return updateFlags(state, { fetch: 'error' })
    case fromActions.FETCH_FULFILLED:
      return addedDriver(state, action, { fetch: 'processed' })
    case fromActions.FETCH_BY_AGREEMENT:
      return updateFlags(state, { fetch: 'processing' })
    case fromActions.FETCH_BY_AGREEMENT_ERROR:
      return updateFlags(state, { fetch: 'error' })
    case fromActions.FETCH_BY_AGREEMENT_FULFILLED:
      return handleFetchDriverByAgreementResponse(state, action, { fetch: 'processed' })

    // Fetch Corresponding Driver Data
    /* case fromActions.FETCH_CORRESPONDING_CONTRACT:
      return resetFlags(state, { fetchContract: 'processing' }) */
    case fromActions.FETCH_CORRESPONDING_CONTRACT_ERROR:
      return updateFlags(state, { fetch: 'error' })
    case fromActions.FETCH_CORRESPONDING_CONTRACT_FULFILLED:
      return addRelatedResource(state, action, { fetch: 'processed' })

    // Fetch Conrtact Data
    case fromActions.FETCH_CONTRACT:
      return resetFlags(state, { fetchContract: 'processing' })
    case fromActions.FETCH_CONTRACT_ERROR:
      return updateFlags(state, { fetchContract: 'error' })
    case fromActions.FETCH_CONTRACT_FULFILLED:
      return addRelatedResource(state, action)

    // Update Driver
    case fromActions.UPDATE: 
      return updateDriver(state, action)
    case fromActions.UPDATE_ERROR: {
      state.apiResponse = action.payload || 'Error occured, Driver update failed.'
      return updateFlags(state, { update: 'error' })
    }
    case fromActions.UPDATE_FULFILLED:
      return updatedDriver(state, action)

    case fromActions.ON_CHANGE:
      return onChange(R.clone(state), action)

    // Terminate Driver
    case fromActions.DELETE:
      return resetFlags(state, { delete: 'processing' })
    case fromActions.DELETE_ERROR: {
      state.apiResponse = action.payload || 'Error occured, Driver termination failed.'
      return updateFlags(state, { delete: 'error' })
    }
    case fromActions.DELETE_FULFILLED:
      return deletedDriver(state, action)

    // Lookup
    case fromActions.LOOKUP:
      return onLookup(state, action)
    case fromActions.LOOKUP_ERROR:
      return onLookupError(state, action)
    case fromActions.LOOKUP_FULFILLED:
      return onLookupSuccess(state, action)
     
    // Toggle Pool Vehicle
    case fromActions.TOGGLE_POOL_VEHICLE:
      return onTogglePoolVehicle(state, action)
    case fromActions.TOGGLE_POOL_VEHICLE_ERROR:
      return onTogglePoolVehicleError(state, action)
    case fromActions.TOGGLE_POOL_VEHICLE_FULFILLED:
      return onTogglePoolVehicleSuccess(state, action)

    // Unassign Vehicle
    case fromActions.UNASSIGN_VEHICLE:
      return onUnassignVehicle(state, action)
    case fromActions.UNASSIGN_VEHICLE_ERROR:
      return onUnassignVehicleError(state, action)
    case fromActions.UNASSIGN_VEHICLE_FULFILLED:
      return onUnassignVehicleSuccess(state, action)

    // Invite Driver
    case fromActions.INVITE_DRIVER:
      return updateFlags(state, { inviteDriver: 'processing' })
    case fromActions.INVITE_DRIVER_ERROR:
      return updateFlags(state, { inviteDriver: 'error' })
    case fromActions.INVITE_DRIVER_FULFILLED:
      return updateFlags(state, { inviteDriver: 'processed' })
    
    case fromActions.SAVE_TEMP:
      return saveTemporary(R.clone(state), action)
    default:
      return state
  }
}

const addDriver = (state, { payload }) => {
  
  return resetFlags(state, {
    create: 'processing',
    isAssigned: payload?.agreementNo ? true : false
  })
}

const updateDriver = (state, { payload }) => {

  const preDriverData = Object.entries(state.data)
    .map(([key, value], index) => value.uniqueId === payload.uniqueId && value)
    .filter(x => x)[0]

  // console.log('[updateDriver]: ', { preDriverData, payload })

  return resetFlags(state, {
    update: 'processing',  // set isAssigned flag only when agreementNo has changed to show confirmation modal
    isAssigned: payload?.agreementNo && preDriverData && payload?.agreementNo !== preDriverData.agreementNo ? true : false
  })
}

const deletedDriver = (state, { payload }) => {
  console.log('[deletedDriver] ', payload)
  const isSuccess = payload.apiResponse?.success || false
  state.apiResponse = isSuccess
    ? 'Driver terminated successfully'
    : payload.apiResponse?.message || 'Error occured, Driver termination failed.'

  // state.data[payload.uniqueId].status = 'Terminated'
  console.log('state.data[payload.uniqueId]', { data: state.data, payload })

  return updateFlags(state, {
    delete: isSuccess ? 'processed' : 'error'
  })
}

const onChange = (state, action) => {
  // console.log('[state]', state)
  // console.log('[payload]', action.payload)
  const id = action.payload.uniqueId
  state.data[id] = action.payload
  // console.log('[state.data[id]]', state.data[id])
  return state
}

const resetFlags = (state, flags) => ({
  ...state,
  selected: [],
  apiResponse: null,
  flags: {
    ...state.flags,
    create: 'none',
    isAssigned: false,
    fetch: 'none',
    fetchContract: 'none',
    update: 'none',
    delete: 'none',
    togglePoolVehicle: 'none',
    unassignVehicle: 'none',
    ...flags,
  },
  lookup: {},
})

const updateFlags = (state, flags) => ({
  ...state,
  flags: {
    ...state.flags,
    ...flags,
  }
})

const createdDriver = (state, { payload }, flags) => {
  
  const isAssigned = payload?.agreementNo ? true : false

  const apiResponse = isAssigned
    ? 'Your driver has been created and assigned successfully.'
    : 'Your driver has been created successfully.'
  
  return {
    ...state,
    flags: {
      ...state.flags,
      ...flags,
      isAssigned,
    },
    apiResponse,
  }
}

const addedDriver = (state, { payload }, flags) => {
  const isSuccess = payload.data?.apiResponse?.success || false
  const apiResponse = isSuccess
    ? 'Driver created succcessfully'
    : payload.data?.apiResponse?.message || 'Error occured, Driver fetch failed.'
     
  // clean data
  const driverData = {
    ...payload,
    mobile: payload.mobile ? payload.mobile.replace(/ /g, '') : null
  }
  
  return {
    ...state,
    data: {
      ...state.data,
      [payload.uniqueId]: driverData,
    },
    flags: {
      ...state.flags,
      ...flags,
    },
    apiResponse,
  }
}


const handleFetchDriverByAgreementResponse = (state, { payload }, flags) => {
  // console.log('[handleFetchDriverByAgreementResponse] ', payload )
  
  return {
    ...state,
    data: {
      ...state.data,
      [payload.uniqueId]: payload,
    },
    flags: {
      ...state.flags,
      ...flags,
    },
  }
}

const addRelatedResource = (state, { payload }) => ({
  ...state,
  flags: {
    ...state.flags,
    fetchContract: 'processed',
  },
  related: {
    ...state.related,
    [payload.agreementNo]: payload,
  },
})

const updatedDriver = (state, { payload }) => {

  // console.log('updatedDriver temp data: ', state.temp)
  // get old driver name from temp storage and remove
  const oldVehicleDetails = state.temp
  state.temp = null

  const newDriver = payload.driver || ''
  // const oldDriver = oldVehicleDetails && oldVehicleDetails.currentDriver
  const isPoolVehicle = oldVehicleDetails && oldVehicleDetails.isPoolVehicle

  const apiResponse = isPoolVehicle
    ? `${newDriver} has been assigned successfully to this vehicle.`
    : `${newDriver} has been assigned successfully to this vehicle.`

  const next = { ...state, apiResponse }

  const id = payload.uniqueId
  const prevState = next?.data?.[id]
  const nextState = payload

  if (prevState) {
    next.data[id] = {
      ...prevState,
      ...nextState,
    }
  }

  return updateFlags(next, { update: 'processed' })
}


const onLookup = (state, { payload }) => {
  return updateFlags(state, { lookup: 'processing' })
}

const onLookupError = (state, { payload }) => {
  console.log('[onLookupError]: ', payload)
  return updateFlags(state, { lookup: 'error' })
}

const onLookupSuccess = (state, { payload, options }) => {

  console.log('[onLookupSuccess]: ', { payload, options })
  let lookupOptions = []

  if(payload && payload.length > 0) {
    const cleanData = [...(new Set(payload))]
      .reduce((result, element) => {
        const normalize = x => typeof x === 'string' ? x.toLowerCase() : x  
        if (result.every(otherElement => normalize(otherElement.value) !== normalize(element.value)))
          result.push(element)
  
        return result
      }, [])
  
    lookupOptions = cleanData
  }

  state.lookup = { ...state.lookup, [options.resourceId]: lookupOptions }
  return updateFlags(state, { lookup: 'processed' })
}
 
const onTogglePoolVehicle = (state, { payload, options }) => {

  // is making a pool vehicle an unassigned vehicle
  // because it uses the togglePoolVehicle endpoint to make pool vehicle as an unassign vehicle 
  if(options?.resourceId && options?.resourceId.includes('false')) {
    console.log('[making a pool vehicle an unassigned vehicle]: ', options)
    return resetFlags(state, { togglePoolVehicle: 'processing', unassignVehicleFromPoolVehicle: true })
  }
  return resetFlags(state, { togglePoolVehicle: 'processing' })
}
const onTogglePoolVehicleError = (state, { payload }) => {
  state.apiResponse = `Toggle pool vehicle failed. Error: ${payload}`
  return updateFlags(state, { togglePoolVehicle: 'error' })
}
const onTogglePoolVehicleSuccess = (state, { payload }) => {
  state.apiResponse = "You have successfully updated this vehicle record."
  return updateFlags(state, { togglePoolVehicle: 'processed' })
}
 
const onUnassignVehicle = (state, { payload }) => {
  return resetFlags(state, { unassignVehicle: 'processing' })
}
const onUnassignVehicleError = (state, { payload }) => {
  state.apiResponse = `Unassign vehicle failed. Error: ${payload}`
  return updateFlags(state, { unassignVehicle: 'error' })
}
const onUnassignVehicleSuccess = (state, { payload }) => {
  state.apiResponse = "You have successfully updated this vehicle record."
  return updateFlags(state, { unassignVehicle: 'processed' })
}

const saveTemporary = (state, { payload }) => {
  // console.log('saveTemporary: ', payload)
  state.temp = payload
  return state
}


export default reducer
