
export const MODULE_NAME = `adminInvitations`

export const RESET = `${MODULE_NAME}_reset`

export const REQUEST_ADD_USER = `${MODULE_NAME}_request_add_user`
export const REQUEST_ADD_USER_ERROR = `${MODULE_NAME}_request_add_user_error`
export const REQUEST_ADD_USER_FULFILLED = `${MODULE_NAME}_request_add_user_fulfilled`

export const REQUEST_BLOCK_USER = `${MODULE_NAME}_request_block_user`
export const REQUEST_BLOCK_USER_ERROR = `${MODULE_NAME}_request_block_user_error`
export const REQUEST_BLOCK_USER_FULFILLED = `${MODULE_NAME}_request_block_user_fulfilled`

export const REQUEST_REMOVE_USER = `${MODULE_NAME}_request_remove_user`
export const REQUEST_REMOVE_USER_ERROR = `${MODULE_NAME}_request_remove_user_error`
export const REQUEST_REMOVE_USER_FULFILLED = `${MODULE_NAME}_request_remove_user_fulfilled`

export const REQUEST_USER_INVITATIONS = `${MODULE_NAME}_request_user_invitations`
export const REQUEST_USER_INVITATIONS_ERROR = `${MODULE_NAME}_request_user_invitations_error`
export const REQUEST_USER_INVITATIONS_FULFILLED = `${MODULE_NAME}_request_user_invitations_fulfilled`

export const REQUEST_USER_UPDATE = `${MODULE_NAME}_request_user_update`
export const REQUEST_USER_UPDATE_ERROR = `${MODULE_NAME}_request_user_update_error`
export const REQUEST_USER_UPDATE_FULFILLED = `${MODULE_NAME}_request_user_update_fulfilled`

export const REQUEST_ROLES = `${MODULE_NAME}_request_roles`
export const REQUEST_ROLES_ERROR = `${MODULE_NAME}_request_roles_error`
export const REQUEST_ROLES_FULFILLED = `${MODULE_NAME}_request_roles_fulfilled`

export const REQUEST_ASSIGN_ROLE = `${MODULE_NAME}_request_assign_roles`
export const REQUEST_ASSIGN_ROLE_ERROR = `${MODULE_NAME}_request_assign_roles_error`
export const REQUEST_ASSIGN_ROLE_FULFILLED = `${MODULE_NAME}_request_assign_roles_fulfilled`

export const REQUEST_UNASSIGN_ROLE = `${MODULE_NAME}_request_unassign_roles`
export const REQUEST_UNASSIGN_ROLE_ERROR = `${MODULE_NAME}_request_unassign_roles_error`
export const REQUEST_UNASSIGN_ROLE_FULFILLED = `${MODULE_NAME}_request_unassign_roles_fulfilled`

export const REQUEST_ASSIGN_FLEET = `${MODULE_NAME}_request_assign_fleet`
export const REQUEST_ASSIGN_FLEET_ERROR = `${MODULE_NAME}_request_assign_fleet_error`
export const REQUEST_ASSIGN_FLEET_FULFILLED = `${MODULE_NAME}_request_assign_fleet_fulfilled`

export const REQUEST_UNASSIGN_FLEET = `${MODULE_NAME}_request_unassign_fleet`
export const REQUEST_UNASSIGN_FLEET_ERROR = `${MODULE_NAME}_request_unassign_fleet_error`
export const REQUEST_UNASSIGN_FLEET_FULFILLED = `${MODULE_NAME}_request_unassign_fleet_fulfilled`

export const ADD_ROLES = `${MODULE_NAME}_add_roles`
export const REMOVE_ROLES = `${MODULE_NAME}_remove_roles`

export const ADD_USERS = `${MODULE_NAME}_add_users`
export const REMOVE_USERS = `${MODULE_NAME}_remove_users`
export const ON_CHANGE_USER = `${MODULE_NAME}_on_change_users`