import React, { memo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    textAlign: 'left',
    margin: 0,
    padding: 0,
    width: '100%',
    borderRadius: 4,
    border: '1px solid #ced4da',
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none !important',
      '& > div': {
        background: theme.palette.background.paper,
      },
    },
    '& label': {
      background: theme.palette.background.paper,
    },
    '& .MuiInputBase-input': {
      margin: 0,
      padding: '6px 8px !important',
      background: 'none !important',
    },
    '& .MuiOutlinedInput-input': {
      padding: theme.spacing(1.35),
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 14px) scale(1)',
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
  },
  break: {
    paddingTop: '2px !important',
    paddingBottom: '2px !important',
    minHeight: '0 !important',
    background: 'transparent !important',
  },
}))

const getStyles = (name, selected, theme) => ({
  fontWeight:
    selected === name === -1
      ? theme.typography.fontWeightRegular
      : theme.typography.fontWeightMedium,
})

const SingleSelectInline = ({
  noNone,
  options = [],
  value = '',
  onChange,
  ...rest
}) => {

  const classes = useStyles()
  const theme = useTheme()
  const [selected, setSelected] = useState(value)

  const onChangeSelection = event =>
    onChange(event.target.value)

  useEffect(() => {
    setSelected(value)
  }, [value])

  return (
    <div className={classes.root}>
      <FormControl
        className={classes.formControl}
        variant="outlined">
        <Select
          value={selected}
          onChange={onChangeSelection}
          {...rest}>
          { ! noNone && (
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
          )}
          {options.map((option, index) => {

            const name = option.label || option
            const code = option.value || option

            if (option.label && ! option.value) {
              return (
                <MenuItem
                  key={index}
                  className={classes.break}
                  disabled
                  value="">
                  <em>{name}</em>
                </MenuItem>
              )
            }

            return (
              <MenuItem
                key={index}
                value={code}
                style={getStyles(code, selected, theme)}>
                {name}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    </div>
  )
}

SingleSelectInline.propTypes = {
  noNone: PropTypes.bool,
  options: PropTypes.array.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
}

export default memo(SingleSelectInline)
