import React, { Fragment, useContext } from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/styles'
import { SingleSelect, EmailTextField, RowValueText } from 'frame/components'
import { TextValidator } from 'react-material-ui-form-validator'
import {  Grid, Typography } from '@material-ui/core'
import { FormContext } from '../../form'
import { FormCta } from '../form-cta'
import { DriverDetailsContext } from '.'


export const ViewNewDriver = () => {
  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'))
  const formContext = useContext(FormContext)
  const driverDetailsContext = useContext(DriverDetailsContext)
  const {
    values,
    onSelectChange,
    onInputChange,
  } = formContext
  const {
    setView
  } = driverDetailsContext

  return (
    <Fragment> 
      <Grid item xs={12}>
        <Typography gutterBottom variant="h4">
          New Driver Details
        </Typography>
      </Grid>            
      <Grid item xs={12}>
        <RowValueText label="Name" responsive>
          <TextValidator
            fullWidth
            size="small"
            id="driver"
            label={mobileView ? 'Name' : ''}
            InputLabelProps={{ shrink: mobileView }}
            value={values.driver || ''}
            inputProps={{ maxLength: 50 }}
            variant="outlined"
            validators={['required', 'isNotSpaces']}
            errorMessages={['This field is required', 'Please enter a valid name']}
            onChange={onInputChange}
          />
        </RowValueText>
      </Grid>

      <Grid item xs={12}>
        <RowValueText label="Email" responsive>
          <EmailTextField
            fullWidth
            size="small"
            id="email"
            label={mobileView ? 'Email' : ''}
            InputLabelProps={{ shrink: mobileView }}
            inputProps={{ maxLength: 50 }}
            value={values.email || ''}
            variant="outlined"
            onChange={onSelectChange('email')}
          />
        </RowValueText>
      </Grid>

      <Grid item xs={12}>
        <RowValueText label="Mobile" responsive>
          <TextValidator
            fullWidth
            id="mobile"
            size="small"
            label={mobileView ? 'Mobile' : ''}
            InputLabelProps={{ shrink: mobileView }} // to show label only on mobile
            value={values.mobile || ''}
            inputProps={{ maxLength: 50 }}
            validators={['isNumber']}
            errorMessages={['A valid phone number is required']}
            variant="outlined"
            onChange={onInputChange}
          />
        </RowValueText>
      </Grid>

      <Grid item xs={12}>
        <RowValueText label="Notification Method" responsive>
          <SingleSelect
            noNone
            size="small"
            id="driverNotification"
            label="Notification Method"
            hideLabelOnMobile={!mobileView} // to show label only on mobile
            options={['Email', 'Text', 'In App', 'N/A']}
            value={values.driverNotification}
            onChange={onSelectChange('driverNotification')}
          />
        </RowValueText>
      </Grid>

      <Fragment>
        <Grid item xs={12}>
          <FormCta
            onProceed={() => setView("additional_details")}
            onBack={() => setView("default")}
          />
        </Grid>
      </Fragment>
    </Fragment>
  )
}