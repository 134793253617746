import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { getRole } from 'listable'

import {
  Card,
  CardContent,
  CardActions,
  Avatar,
  Typography
} from '@material-ui/core'


const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlgin: 'center'
  },
  name: {
    marginTop: theme.spacing(1),
    maxWidth: '280px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'wrap',
  },
  avatar: {
    height: 100,
    width: 100
  },
  removeBotton: {
    width: '100%'
  }
}))

const ProfileDetails = ({ profile }) => {

  const classes = useStyles()

  // const onChangePicture = () => {
  //   const win = window.open('https://en.gravatar.com/', '_blank')
  //   win.focus()
  // }

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <Avatar className={classes.avatar} src={profile.picture} />

        <Typography className={classes.name} gutterBottom variant="h3" align="center">
          {profile.nickname}
        </Typography>
        <Typography color="textSecondary" variant="body1">
          {profile.role && getRole(profile.role)}
        </Typography>
        {/* <Typography
          color="textSecondary"
          variant="body1">
          {profile.state}, {profile.country}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body2">
          {profile.timezone}
        </Typography> */}
      </CardContent>
      <CardActions>
        {/* <Button
          className={classes.removeBotton}
          variant="text"
          onClick={onChangePicture}>
          Change picture
        </Button> */}
      </CardActions>
    </Card>
  )
}

ProfileDetails.propTypes = {
  profile: PropTypes.object.isRequired
}

export default ProfileDetails
