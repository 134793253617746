import { combineEpics } from 'redux-observable'
import { effects as endOfLeaseEffects } from 'features/report-end-of-lease'
import { effects as extensionRentals } from 'features/report-extension-rentals'
import { effects as kmTracking } from 'features/report-km-tracking'
import { effects as upcomingMaturities } from 'features/report-upcoming-maturities'
import { effects as vehicleServicing } from 'features/report-vehicle-servicing'
import { effects as vehiclesOnOrderEffects } from 'features/report-vehicles-on-order'
import { effects as vehiclesWofRegoEffects } from 'features/report-wof-rego'
import { effects as incidentManagementEffects } from 'features/report-incident-management'
import { effects as documentsEffects } from 'features/report-documents'



const reportEffects = combineEpics(
  endOfLeaseEffects,
  extensionRentals,
  kmTracking,
  upcomingMaturities,
  vehicleServicing,
  vehiclesOnOrderEffects,
  vehiclesWofRegoEffects,
  incidentManagementEffects,
  documentsEffects,
)

export default reportEffects
