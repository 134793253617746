import { DASHBOARD_PATH } from './actions/types'


export const constants = {
  PATH_ALL: DASHBOARD_PATH,
}

export {
  DASHBOARD_PATH
} from './actions/types'

export {
  Dashboard as DashboardRoutes
} from './components'
